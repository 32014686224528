import { atom, SetterOrUpdater } from 'recoil';

export interface ConfettiType {
  active: boolean;
}

export const confettiState = atom<ConfettiType>({
  key: 'confettiState',
  default: {
    active: false,
  },
});

export const setActiveConfetti = (setConfetti: SetterOrUpdater<ConfettiType>, active: boolean) => {
  setConfetti(prev => ({
    ...prev,
    active,
  }));
};
