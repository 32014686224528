import { BookPrintUI, BookType } from './BookPrint.Presenter';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { useMemo, useCallback, useState, useEffect, useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from 'recoil/model/user';
import { fetchGetApi, fetchPostApi } from 'utils/api';
import { BOOK_SORTING_DROPDOWN, DIST_BOOK_REGEX, STUDENT_GRADE_LIST_DROPDOWN } from 'utils/constants';
import {
  ModalStudySettingDataType,
  closeModalStudySetting,
  modalStudySettingData,
  openModalStudySetting,
} from 'recoil/common/modalStudySetting';
import { ModalContext } from 'provider/ModalProvider';
import { isMobileValidate } from 'utils/tools';

export interface BookListObjType {
  mh: OriginalBookDataType[] | null;
  ce: OriginalBookDataType[] | null;
  tc: OriginalBookDataType[] | null;
  textBook: OriginalBookDataType[] | null;
  mockTest: OriginalBookDataType[] | null;
  homeMade: (CustomBookDataType | FolderDataType)[] | null;
}

interface IBook {
  id: number;
  units: number[];
}

export function BookPrintContainer() {
  const userStateData = useRecoilValue<UserType>(userState);
  const setToastBar = useSetRecoilState(toastBarState);

  const { customer_id } = userStateData;
  const { modal_confirm, modal_alert } = useContext(ModalContext);

  const [tabValue, setTabValue] = useState('');
  const [bookListObj, setBookListObj] = useState<BookListObjType>({
    ce: null,
    mh: null,
    tc: null,
    textBook: null,
    mockTest: null,
    homeMade: null,
  });
  const [selectedBook, setSelectedBook] = useState<BookType>({
    bookId: 0,
    name: '',
  });
  const [units, setUnits] = useState<{ [key: string]: UnitDataType[] }>({});
  const [bookCategoryId, setBookCategoryId] = useState(0);
  const [sortGrade, setSortGrade] = useState('');
  const [sortingMethod, setSortingMethod] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [printBookList, setPrintBookList] = useState<PrintBookListType>({});
  const [printTestList, setPrintTestList] = useState<TestPrintWordType[]>([]);
  const [printBooksView, setPrintBooksView] = useState<{
    [key: string]: {
      id: number;
      bookName: string;
      category: number;
      units: { [key: string]: { unitId: number; unitName: string } };
    };
  }>({});
  const [selectedFolderId, setSelectedFolderId] = useState(0);
  const setModalStudySetting = useSetRecoilState<ModalStudySettingDataType>(modalStudySettingData);

  const [mode, setMode] = useState('list');
  const [type, setType] = useState(0);
  const [printType, setPrintType] = useState(-1);
  const [wordCount, setWordCount] = useState(0);
  const [remainWordCount, setRemainWordCount] = useState(0);
  const [testPrintType, setTestPrintType] = useState(0);
  const [testSetting, setTestSetting] = useState<TestPrintSettingType>({
    stom: {
      check: true,
      multi: 0,
      short: 0,
    },
    mtos: {
      check: true,
      multi: 0,
      short: 0,
    },
  });
  const [testWordList, setTestWordList] = useState<TestPrintWordType[]>([]);
  const [example, setExample] = useState<TestExampleType>({});
  const [openTooltip, setOpenTooltip] = useState([false, false]);

  const [searchBarKey, setSearchBarKey] = useState(0);

  const [lastClickUnit, setLastClickUnit] = useState<{
    checked: boolean;
    bookId: number;
    unitId: number;
  } | null>(null);

  // 코스북 선택 모달 띄우기 위해
  const [selectedCourse, setSelectedCourse] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  // tabList
  const [tabList, setTabList] = useState<CategoryTabItemType[]>([]);

  // tab contents api 요청 (GET)
  const getTabListUri = useMemo(() => {
    return `/origin_books/categories/${selectedCourse}`;
  }, [selectedCourse]);

  const getTabList = async () => {
    const res: GetTabListResType = await fetchGetApi(getTabListUri);
    if (res.result && res.data.length > 0) {
      const new_tab_id = res.data[0].id;
      const new_tab_value = res.data[0].name;

      onClickTab(new_tab_value, new_tab_id);

      setTabValue(new_tab_value);
      setTabList(res.data);
    }
  };

  const bookList = useMemo(() => {
    if (bookCategoryId == -1) {
      if (selectedFolderId > 0 && bookListObj.homeMade) {
        let return_book_list: CustomBookDataType[] = [];
        const selected_folder = bookListObj.homeMade.find(book => book.id === selectedFolderId) as FolderDataType;
        if (bookListObj.homeMade) {
          if (selected_folder.books) return_book_list = selected_folder.books.map(book => ({ ...book }));
        }

        // 필터링
        if (sortGrade && sortGrade !== 'all') {
          return_book_list = return_book_list.filter(book => book.grade === sortGrade);
        }

        // 정렬
        if (sortingMethod) {
          if (sortingMethod === 'abc') {
            return_book_list.sort((a, b) => a.name.localeCompare(b.name));
          } else if (sortingMethod === 'created') {
            return_book_list.sort((a, b) => new Date(a.postdate).getTime() - new Date(b.postdate).getTime());
          }
        }

        // 검색 기능
        if (typeof searchKeyword === 'string' && searchKeyword) {
          return_book_list = return_book_list.filter(book => book.name.includes(searchKeyword));
          return_book_list = return_book_list.map(item => {
            if (item.name.includes(searchKeyword)) {
              item.name = item.name.replace(
                searchKeyword,
                `<span style="background-color: #fffa8b;">${searchKeyword}</span>`,
              );
              return item;
            } else {
              return item;
            }
          });
        }

        const is_back = {
          type: 'folder',
          isBack: true,
          postdate: '',
          name: selected_folder.name,
          id: 0,
        } as FolderDataType;

        return [is_back, ...return_book_list];
      } else {
        let return_book_list: (CustomBookDataType | FolderDataType)[] = [];
        if (bookListObj.homeMade) {
          return_book_list = bookListObj.homeMade.map(book => ({ ...book }));
        }

        // 필터링
        if (sortGrade && sortGrade !== 'all') {
          return_book_list = return_book_list.filter(book => {
            if (book.type === 'folder') {
              // book이 폴더인 경우
              book = book as FolderDataType;
              const booksInFolder = book.books || [];
              const filteredBooks = booksInFolder.filter(item => item.grade === sortGrade);
              return filteredBooks.length > 0;
            } else {
              // book이 책인 경우
              book = book as CustomBookDataType;
              return book.grade === sortGrade;
            }
          });
        }

        // 정렬
        if (sortingMethod) {
          const folders = return_book_list.filter(book => book.type === 'folder') as FolderDataType[];
          const customBooks = return_book_list.filter(book => book.type !== 'folder') as CustomBookDataType[];

          if (sortingMethod === 'abc') {
            customBooks.sort((a, b) => a.name.localeCompare(b.name));
          } else if (sortingMethod === 'created') {
            customBooks.sort((a, b) => new Date(a.postdate).getTime() - new Date(b.postdate).getTime());
          }
          return_book_list = [...folders, ...customBooks];
        }

        // 검색 기능
        if (typeof searchKeyword === 'string' && searchKeyword) {
          return_book_list = return_book_list.filter(book => {
            if (book.type === 'folder') {
              // book이 폴더인 경우
              book = book as FolderDataType;
              const booksInFolder = book.books || [];
              const filteredBooks = booksInFolder.filter(item => item.name.includes(searchKeyword));
              return filteredBooks.length > 0;
            } else {
              // book이 책인 경우
              book = book as CustomBookDataType;
              return book.name.includes(searchKeyword);
            }
          });
          return_book_list = return_book_list.map(item => {
            if (item.name.includes(searchKeyword)) {
              item.name = item.name.replace(
                searchKeyword,
                `<span style="background-color: #fffa8b;">${searchKeyword}</span>`,
              );
              return item;
            } else {
              return item;
            }
          });
        }

        return return_book_list;
      }
    } else {
      let return_book_list: OriginalBookDataType[] = [];
      if (tabValue.includes('유아 / 초등') && bookListObj.ce) {
        return_book_list = bookListObj.ce.map(book => ({ ...book }));
      } else if (tabValue.includes('중등 / 고등') && bookListObj.mh) {
        return_book_list = bookListObj.mh.map(book => ({ ...book }));
      } else if (tabValue.includes('토익 / 공무원') && bookListObj.tc) {
        return_book_list = bookListObj.tc.map(book => ({ ...book }));
      } else if (tabValue.includes('교과서') && bookListObj.textBook) {
        return_book_list = bookListObj.textBook.map(book => ({ ...book }));
      } else {
        return_book_list = bookListObj.mockTest ? bookListObj.mockTest.map(book => ({ ...book })) : [];
      }

      // 필터링
      if (sortGrade && sortGrade !== 'all') {
        return_book_list = return_book_list.filter(book => book.grade === sortGrade);
      }

      // 정렬
      if (sortingMethod) {
        if (sortingMethod === 'abc') {
          return_book_list.sort((a, b) => a.name.localeCompare(b.name));
        } else if (sortingMethod === 'created') {
          return_book_list.sort((a, b) => new Date(a.postdate).getTime() - new Date(b.postdate).getTime());
        }
      }

      // 검색 기능
      if (typeof searchKeyword === 'string' && searchKeyword) {
        return_book_list = return_book_list.filter(book => book.name.includes(searchKeyword));
        return_book_list = return_book_list.map(item => {
          if (item.name.includes(searchKeyword)) {
            item.name = item.name.replace(
              searchKeyword,
              `<span style="background-color: #fffa8b;">${searchKeyword}</span>`,
            );
            return item;
          } else {
            return item;
          }
        });
      }

      return return_book_list;
    }
  }, [bookListObj, bookCategoryId, sortGrade, sortingMethod, searchKeyword, isSearch, selectedFolderId, tabValue]);

  // 교재 api 요청 로직
  const getBookListsUrl = useMemo(() => {
    let base_url = `/origin_books/categories`;
    if (bookCategoryId === -1) {
      base_url = `/customers/${customer_id}/books?type=print`;
    } else {
      base_url += `/${bookCategoryId}`;
    }

    return base_url;
  }, [bookCategoryId]);

  const clickCourseSetting = useCallback((idx: number) => {
    if (idx == 0) {
      setSelectedCourse('course');
    } else {
      setSelectedCourse('school');
    }
    closeModalStudySetting({ setModalStudySetting });
    openModalStudySetting({ setModalStudySetting }, 'printType', async (idx: number) => {
      setPrintType(idx);
      closeModalStudySetting({ setModalStudySetting });
    });
  }, []);

  useEffect(() => {
    // 카테고리 갈아 끼워주는 로직
    if (isMobileValidate()) {
      openToastBar({ setToastBar }, '단어 시험지 출력은 PC에서만 가능합니다.', 'red', 2000);
    }
    openModalStudySetting({ setModalStudySetting }, 'courseType_2', clickCourseSetting);
    getBookListsApi();
  }, []);

  const resetBookPrintState = () => {
    setBookListObj({
      ce: [],
      mh: [],
      tc: [],
      textBook: [],
      mockTest: [],
      homeMade: [],
    });
    setPrintBooksView({});
    setSelectedCourse('');
    setBookCategoryId(0);
    setPrintType(-1);
    setUnits({});
    setSelectedBook({
      bookId: 0,
      name: '',
    });
  };

  const onClickStudyContents = useCallback(() => {
    modal_confirm.openModalConfirm(
      'normal_reverse',
      'move_page',
      () => {
        return;
      },
      () => {
        resetBookPrintState();
        openModalStudySetting({ setModalStudySetting }, 'courseType_2', clickCourseSetting);
      },
      '선택한 항목은 삭제됩니다.\r\n',
    );
  }, [clickCourseSetting]);

  const getBookListsApi = async () => {
    const res: GetOriginBookListResType | GetCustomBookListResType = await fetchGetApi(getBookListsUrl);
    setIsLoading(false);
    if (bookCategoryId === -1) {
      // 만든교재
      const result = res as GetCustomBookListResType;
      const new_book_list: (FolderDataType | CustomBookDataType)[] = [];
      result.data.folders.forEach(folder => {
        const folder_book_list = folder.books?.map(book => {
          const match = book.name.match(DIST_BOOK_REGEX);
          if (match) {
            const pattern_text = match[0];
            const bookName = book.name.replace(
              DIST_BOOK_REGEX,
              `<span style="color: rgb(210, 210, 210)">${pattern_text}</span>`,
            );

            return {
              ...book,
              name: bookName,
            };
          } else {
            return {
              ...book,
            };
          }
        });
        const folder_obj = {
          ...folder,
        };
        if (folder_book_list && folder_book_list?.length > 0) {
          folder_obj['books'] = folder_book_list;
        }
        new_book_list.push({ ...folder_obj, type: 'folder' });
      });
      result.data.books.forEach(book => {
        const match = book.name.match(DIST_BOOK_REGEX);
        if (match) {
          const pattern_text = match[0];
          const bookName = book.name.replace(
            DIST_BOOK_REGEX,
            `<span style="color: rgb(210, 210, 210)">${pattern_text}</span>`,
          );

          new_book_list.push({ ...book, name: bookName });
        } else {
          new_book_list.push({ ...book });
        }
      });
      setBookListObj(prev => ({ ...prev, homeMade: new_book_list }));
    } else {
      // 오래보카, 교과서, 듣기/모의고사
      const result = res as GetOriginBookListResType;
      const new_book_list = result.data.map(book => {
        let bookName = book.name;
        if (tabValue.includes('교과서')) {
          const pattern =
            /((YBM|교학사|금성|능률|다락원|대교|동아|미래엔|비상|지학사|천재)\([^)]+\))( 실용| 독작| 회화)? 채택학교 모음/g;

          const match = book.name.match(pattern);

          if (match) {
            const pattern_text = match[0];
            bookName = book.name.replace(
              pattern,
              `<span style="color: rgb(210, 210, 210)">&nbsp;${pattern_text}</span>`,
            );
          }
        }
        return {
          ...book,
          name: bookName,
          active: false,
        };
      });
      if (tabValue.includes('유아 / 초등')) {
        setBookListObj(prev => ({ ...prev, ce: new_book_list }));
      } else if (tabValue.includes('중등 / 고등')) {
        setBookListObj(prev => ({ ...prev, mh: new_book_list }));
      } else if (tabValue.includes('토익 / 공무원')) {
        setBookListObj(prev => ({ ...prev, tc: new_book_list }));
      } else if (tabValue.includes('교과서')) {
        setBookListObj(prev => ({ ...prev, textBook: new_book_list }));
      } else if (tabValue.includes('수능/모의고사')) {
        setBookListObj(prev => ({ ...prev, mockTest: new_book_list }));
      }
    }

    if (isSearch && searchKeyword === '') {
      setIsSearch(false);
    }
  };

  useEffect(() => {
    if (printType == 1) {
      if (Object.keys(printBooksView).length > 0) {
        fetchTestWords('count');
      } else {
        setTestSetting({
          stom: {
            check: true,
            multi: 0,
            short: 0,
          },
          mtos: {
            check: true,
            multi: 0,
            short: 0,
          },
        });
      }
    }
  }, [printBooksView, printType]);

  useEffect(() => {
    if (bookCategoryId !== 0) {
      setIsLoading(true);
      getBookListsApi();
    }
  }, [bookCategoryId]);

  useEffect(() => {
    if (selectedBook.bookId != 0 && !units[selectedBook.bookId]) {
      getUnitsApi();
    }
  }, [selectedBook.bookId, units]);

  useEffect(() => {
    if (selectedCourse != '' && printType != -1) {
      getTabList();
    }
  }, [selectedCourse, printType]);

  // 유닛 불러오는 api 세팅
  const getUnitsUrl = useMemo(() => {
    return `/customers/${customer_id}/books/${selectedBook.bookId}/units`;
  }, [selectedBook]);

  const getUnitsApi = async () => {
    const res: GetUnitListResType = await fetchGetApi(getUnitsUrl);
    if (res.result) {
      setUnits(prev => ({
        ...prev,
        [selectedBook.bookId]: res.data.map(unit => ({ ...unit, checked: false })),
      }));
    }
  };

  const onClickBook = useCallback((book: OriginalBookDataType | CustomBookDataType | FolderDataType) => {
    if (book.type == 'trial') {
      modal_alert.openModalAlert('info_trial_books');
      return;
    }
    setLastClickUnit(null);
    setSelectedBook(prev => {
      if (prev.bookId == book.id) {
        return {
          bookId: 0,
          name: '',
        };
      } else {
        return { bookId: book.id, name: book.name };
      }
    });
  }, []);

  const onClickBookCheck = useCallback(
    async (
      e: React.ChangeEvent<HTMLInputElement>,
      book: OriginalBookDataType | CustomBookDataType | FolderDataType,
    ) => {
      e.stopPropagation();

      if (book.type == 'trial') {
        modal_alert.openModalAlert('info_trial_books');
        return;
      }

      if (bookCategoryId == -1) {
        let book_list: (CustomBookDataType | FolderDataType)[] = bookListObj.homeMade ?? [];

        if (selectedFolderId > 0) {
          const folder = book_list.find(book => book.id === selectedFolderId) as FolderDataType;
          if (folder.type == 'folder' && folder.books) {
            folder.books = folder.books.map(item =>
              item.id === book.id ? { ...item, checked: book.checked ? false : true } : item,
            );

            book_list.map(book => (book.id === selectedFolderId ? folder : book));
          }
        } else {
          book_list = book_list.map(item =>
            item.id === book.id ? { ...item, checked: book.checked ? false : true } : item,
          );
        }

        let unit_data = units[book.id];
        if (!unit_data) {
          const getUnitsUrl = `/customers/${customer_id}/books/${book.id}/units`;
          try {
            const get_unit = await fetchGetApi(getUnitsUrl);
            const res: GetUnitListResType = await get_unit();
            unit_data = res.data;
          } catch (e) {
            console.error(e);
            return false;
          }
        }

        unit_data = unit_data.map(unit => ({
          ...unit,
          checked: book.checked ? false : true,
        }));

        if (printBooksView[book.id] && book.checked) {
          setPrintBooksView(prev => {
            const newView = { ...prev };
            delete newView[book.id];
            return newView;
          });
        } else {
          setPrintBooksView(prev => ({
            ...prev,
            [book.id]: {
              id: book.id,
              bookName: book.name
                .replace('<span style="color: rgb(210, 210, 210)">&nbsp', '')
                .replace(/<\/span>/gi, '')
                .replace('<span style="color: rgb(210, 210, 210)">', '')
                .replace(/<\/span>/gi, '')
                .replace('<span style="background-color: #fffa8b;">', '')
                .replace(/<\/span>/gi, ''),
              category: bookCategoryId,
              units: unit_data.reduce(
                (acc, unit) => ({ ...acc, [unit.id]: { unitId: unit.id, unitName: unit.name } }),
                {},
              ),
            },
          }));
        }

        setUnits(prev => ({ ...prev, [book.id]: unit_data }));
        setBookListObj(prev => ({ ...prev, homeMade: book_list }));
      } else {
        let book_list: OriginalBookDataType[];

        if (tabValue.includes('유아 / 초등')) {
          book_list = bookListObj.ce ?? [];
        } else if (tabValue.includes('중등 / 고등')) {
          book_list = bookListObj.mh ?? [];
        } else if (tabValue.includes('토익 / 공무원')) {
          book_list = bookListObj.tc ?? [];
        } else if (tabValue.includes('교과서')) {
          book_list = bookListObj.textBook ?? [];
        } else {
          book_list = bookListObj.mockTest ?? [];
        }

        book_list = book_list.map(item =>
          item.id === book.id ? { ...item, checked: book.checked ? false : true } : item,
        );

        let unit_data = units[book.id];
        if (!unit_data) {
          try {
            const getUnitsUrl = `/customers/${customer_id}/books/${book.id}/units`;
            const res: GetUnitListResType = await fetchGetApi(getUnitsUrl);
            unit_data = res.data;
          } catch (e) {
            console.error(e);
            return false;
          }
        }

        unit_data = unit_data.map(unit => ({
          ...unit,
          checked: book.checked ? false : true,
        }));

        if (printBooksView[book.id] && book.checked) {
          setPrintBooksView(prev => {
            const newView = { ...prev };
            delete newView[book.id];
            return newView;
          });
        } else {
          setPrintBooksView(prev => ({
            ...prev,
            [book.id]: {
              id: book.id,
              bookName: book.name
                .replace('<span style="color: rgb(210, 210, 210)">&nbsp;', '')
                .replace(/<\/span>/gi, '')
                .replace('<span style="background-color: #fffa8b;">', ''),
              category: bookCategoryId,
              units: unit_data.reduce(
                (acc, unit) => ({ ...acc, [unit.id]: { unitId: unit.id, unitName: unit.name } }),
                {},
              ),
            },
          }));
        }

        setUnits(prev => ({ ...prev, [book.id]: unit_data }));

        if (tabValue.includes('유아 / 초등')) {
          setBookListObj(prev => ({ ...prev, ce: book_list }));
        } else if (tabValue.includes('중등 / 고등')) {
          setBookListObj(prev => ({ ...prev, mh: book_list }));
        } else if (tabValue.includes('토익 / 공무원')) {
          setBookListObj(prev => ({ ...prev, tc: book_list }));
        } else if (tabValue.includes('교과서')) {
          setBookListObj(prev => ({ ...prev, textBook: book_list }));
        } else {
          setBookListObj(prev => ({ ...prev, mockTest: book_list }));
        }
      }
    },
    [bookListObj, tabValue, bookCategoryId, selectedFolderId],
  );

  const onClickFolder = useCallback((folder_id: number) => {
    setSelectedFolderId(folder_id);
  }, []);

  //tab
  const tab_contents = useMemo(() => {
    return tabList.map(tab => tab.name);
  }, [tabList]);

  const onClickTab = useCallback(
    (newSelectedTab: string, initTabId?: number) => {
      const tab = tabList.find(tab => tab.name == newSelectedTab);
      if (tab || initTabId) {
        if (tab) {
          setBookCategoryId(tab.id);
        } else if (initTabId) {
          setBookCategoryId(initTabId);
        }
      }
      setSortGrade('');
      setSortingMethod('');
      setSearchKeyword('');
      setSearchBarKey(prev => prev + 1);
      setIsSearch(false);
      setTabValue(newSelectedTab);
      setSelectedFolderId(0);
    },
    [tabList],
  );

  // dropdownNormal (grade)
  const gradeDropdownContents = useMemo(() => {
    if (selectedCourse == 'course') {
      // 학습모드
      switch (tabValue) {
        case '유아 / 초등':
          return STUDENT_GRADE_LIST_DROPDOWN.filter(item => {
            return item.content.includes('전체') || item.content.includes('초');
          });
        case '중등 / 고등':
          return STUDENT_GRADE_LIST_DROPDOWN.filter(item => {
            return item.content.includes('전체') || item.content.includes('중') || item.content.includes('고');
          });
        default:
          return STUDENT_GRADE_LIST_DROPDOWN.filter(item => {
            return item.content.includes('전체');
          });
      }
    } else {
      // 내신모드
      switch (tabValue) {
        case '교과서':
          return STUDENT_GRADE_LIST_DROPDOWN.filter(item => {
            return !['a1', 'a2'].includes(item.id);
          });
        case '수능/모의고사':
          return STUDENT_GRADE_LIST_DROPDOWN.filter(item => {
            return item.content.includes('전체') || item.content.includes('고');
          });
        default:
          return STUDENT_GRADE_LIST_DROPDOWN;
      }
    }
  }, [selectedCourse, tabValue]);

  const gradeDropdownTitle = useMemo(() => {
    let gradeTitle = '학년';

    const selectedGradeObj = gradeDropdownContents.find(grade => grade.id == sortGrade);
    if (selectedGradeObj) {
      gradeTitle = selectedGradeObj.content;
    }

    return gradeTitle;
  }, [sortGrade]);

  const onClickGradeDropdownItem = useCallback(
    (idx: number) => {
      const selectedGrade = gradeDropdownContents[idx].id;
      if (selectedGrade !== sortGrade) {
        setSortGrade(selectedGrade);
      }
    },
    [sortGrade, gradeDropdownContents],
  );

  const gradeSortProps = {
    dropdownContents: gradeDropdownContents,
    onClickItem: onClickGradeDropdownItem,
    title: gradeDropdownTitle,
  };

  // dropdownNormal (sorting)
  const sortingDropdownContents = useMemo(() => {
    return BOOK_SORTING_DROPDOWN;
  }, []);

  const sortingDropdownTitle = useMemo(() => {
    let methodTitle = '정렬';

    const selectedSortingMethod = sortingDropdownContents.find(sortingContent => sortingContent.id == sortingMethod);

    if (selectedSortingMethod) {
      methodTitle = selectedSortingMethod.content;
    }

    return methodTitle;
  }, [sortingMethod]);

  const onClickSortingDropdownItem = useCallback(
    (idx: number) => {
      const selectedSortMethod = sortingDropdownContents[idx].id;
      if (sortingMethod !== selectedSortMethod) {
        setSortingMethod(selectedSortMethod);
      }
    },
    [sortingMethod],
  );

  const subSortProps = {
    dropdownContents: sortingDropdownContents,
    onClickItem: onClickSortingDropdownItem,
    title: sortingDropdownTitle,
  };

  // SearchBar
  const placeholder = useMemo(() => {
    return '교재명, 단원명, 제작자';
  }, []);

  const onChangeValue = useCallback(
    (value: string) => {
      if (value == searchKeyword) return;
      setSearchKeyword(value);
      setIsSearch(true);
    },
    [searchKeyword],
  );

  const searchBook = {
    searchKeyword,
    placeholder,
    onChangeValue,
  };

  const clickUnitSetPrintBooksViews = useCallback(
    (bookId: number, bookName: string, checked: boolean, updates: { unitId: number; unitName: string }[]) => {
      const new_books: {
        [key: string]: {
          id: number;
          bookName: string;
          category: number;
          units: { [key: string]: { unitId: number; unitName: string } };
        };
      } = {
        ...printBooksView,
      };
      let unit_data = units[bookId];
      if (checked) {
        // 유닛 제거
        unit_data = unit_data.map(unit => {
          const isUpdateIncluded = updates.some(update => update.unitId === unit.id);
          if (isUpdateIncluded) {
            return { ...unit, checked: false };
          }
          return unit;
        });
        setUnits(prev => ({ ...prev, [bookId]: unit_data }));
        const new_units: { [key: string]: { unitId: number; unitName: string } } = {
          ...printBooksView[bookId].units,
        };
        for (let i = 0; i < updates.length; i++) {
          delete new_units[updates[i].unitId];
        }
        if (Object.keys(new_units).length == 0) {
          delete new_books[bookId];
        } else {
          new_books[bookId].units = new_units;
        }
        setPrintBooksView(new_books);
        if (bookCategoryId == -1) {
          let book_list: (CustomBookDataType | FolderDataType)[] = bookListObj.homeMade ?? [];
          if (selectedFolderId > 0) {
            const folder = book_list.find(book => book.id === selectedFolderId) as FolderDataType;
            if (folder.books) {
              folder.books = folder.books.map(item => (item.id === bookId ? { ...item, checked: false } : item));

              book_list.map(book => (book.id === selectedFolderId ? folder : book));
              setBookListObj(prev => ({ ...prev, homeMade: book_list }));
            }
          } else {
            book_list = book_list.map(item => (item.id === bookId ? { ...item, checked: false } : item));
            setBookListObj(prev => ({ ...prev, homeMade: book_list }));
          }
        } else {
          let book_list: OriginalBookDataType[];
          if (tabValue.includes('유아 / 초등')) {
            book_list = bookListObj.ce ?? [];
          } else if (tabValue.includes('중등 / 고등')) {
            book_list = bookListObj.mh ?? [];
          } else if (tabValue.includes('토익 / 공무원')) {
            book_list = bookListObj.tc ?? [];
          } else if (tabValue.includes('교과서')) {
            book_list = bookListObj.textBook ?? [];
          } else {
            book_list = bookListObj.mockTest ?? [];
          }

          book_list = book_list.map(item => (item.id === bookId ? { ...item, checked: false } : item));
          if (tabValue.includes('유아 / 초등')) {
            setBookListObj(prev => ({ ...prev, ce: book_list }));
          } else if (tabValue.includes('중등 / 고등')) {
            setBookListObj(prev => ({ ...prev, mh: book_list }));
          } else if (tabValue.includes('토익 / 공무원')) {
            setBookListObj(prev => ({ ...prev, tc: book_list }));
          } else if (tabValue.includes('교과서')) {
            setBookListObj(prev => ({ ...prev, textBook: book_list }));
          } else if (tabValue.includes('수능/모의고사')) {
            setBookListObj(prev => ({ ...prev, mockTest: book_list }));
          }
        }
      } else {
        // 유닛 추가
        unit_data = unit_data.map(unit => {
          const isUpdateIncluded = updates.some(update => update.unitId === unit.id);
          if (isUpdateIncluded) {
            return { ...unit, checked: true };
          }
          return unit;
        });
        setUnits(prev => ({ ...prev, [bookId]: unit_data }));
        const new_units: { [key: string]: { unitId: number; unitName: string } } = {};
        for (let i = 0; i < unit_data.length; i++) {
          if (unit_data[i].checked) {
            new_units[unit_data[i].id] = {
              unitId: unit_data[i].id,
              unitName: unit_data[i].name,
            };
          }
        }
        setPrintBooksView(prev => ({
          ...prev,
          [bookId]: {
            id: bookId,
            bookName: bookName
              .replace('<span style="color: rgb(210, 210, 210)">&nbsp;', '')
              .replace(/<\/span>/gi, '')
              .replace('<span style="background-color: #fffa8b;">', ''),
            units: new_units,
          },
        }));
        const all_checked = unit_data.every(unit => unit.checked === true);

        if (bookCategoryId == -1) {
          let book_list: (CustomBookDataType | FolderDataType)[] = bookListObj.homeMade ?? [];
          if (selectedFolderId > 0) {
            const folder = book_list.find(book => book.id === selectedFolderId) as FolderDataType;
            if (folder.type == 'folder' && folder.books) {
              folder.books = folder.books.map(item =>
                item.id === bookId ? { ...item, checked: all_checked ? true : false } : item,
              );

              book_list.map(book => (book.id === selectedFolderId ? folder : book));
              setBookListObj(prev => ({ ...prev, homeMade: book_list }));
            }
          } else {
            book_list = book_list.map(item =>
              item.id === bookId ? { ...item, checked: all_checked ? true : false } : item,
            );
            setBookListObj(prev => ({ ...prev, homeMade: book_list }));
          }
        } else {
          let book_list: OriginalBookDataType[];
          if (tabValue.includes('유아 / 초등')) {
            book_list = bookListObj.ce ?? [];
          } else if (tabValue.includes('중등 / 고등')) {
            book_list = bookListObj.mh ?? [];
          } else if (tabValue.includes('토익 / 공무원')) {
            book_list = bookListObj.tc ?? [];
          } else if (tabValue.includes('교과서')) {
            book_list = bookListObj.textBook ?? [];
          } else {
            book_list = bookListObj.mockTest ?? [];
          }
          book_list = book_list.map(item =>
            item.id === bookId ? { ...item, checked: all_checked ? true : false } : item,
          );
          if (tabValue.includes('유아 / 초등')) {
            setBookListObj(prev => ({ ...prev, ce: book_list }));
          } else if (tabValue.includes('중등 / 고등')) {
            setBookListObj(prev => ({ ...prev, mh: book_list }));
          } else if (tabValue.includes('토익 / 공무원')) {
            setBookListObj(prev => ({ ...prev, tc: book_list }));
          } else if (tabValue.includes('교과서')) {
            setBookListObj(prev => ({ ...prev, textBook: book_list }));
          } else if (tabValue.includes('수능/모의고사')) {
            setBookListObj(prev => ({ ...prev, mockTest: book_list }));
          }
        }
      }
    },
    [printBooksView, units, selectedFolderId, tabValue],
  );

  const clickUnitSetPrintBooksView = useCallback(
    ({
      bookId,
      bookName,
      unitId,
      unitName,
    }: {
      bookId: number;
      bookName: string;
      unitId: number;
      unitName: string;
    }) => {
      if (!printBooksView[bookId]) {
        let unit_data = units[bookId];
        unit_data = unit_data.map(unit => (unit.id == unitId ? { ...unit, checked: true } : unit));
        setUnits(prev => ({ ...prev, [bookId]: unit_data }));
        const all_checked = unit_data.every(unit => unit.checked === true);
        if (all_checked) {
          if (bookCategoryId == -1) {
            let book_list: (CustomBookDataType | FolderDataType)[] = bookListObj.homeMade ?? [];
            if (selectedFolderId > 0) {
              const folder = book_list.find(book => book.id === selectedFolderId) as FolderDataType;
              if (folder.type == 'folder' && folder.books) {
                folder.books = folder.books.map(item => (item.id === bookId ? { ...item, checked: true } : item));

                book_list.map(book => (book.id === selectedFolderId ? folder : book));
                setBookListObj(prev => ({ ...prev, homeMade: book_list }));
              }
            } else {
              book_list = book_list.map(item => (item.id === bookId ? { ...item, checked: true } : item));
              setBookListObj(prev => ({ ...prev, homeMade: book_list }));
            }
          } else {
            let book_list: OriginalBookDataType[];
            if (tabValue.includes('유아 / 초등')) {
              book_list = bookListObj.ce ?? [];
            } else if (tabValue.includes('중등 / 고등')) {
              book_list = bookListObj.mh ?? [];
            } else if (tabValue.includes('토익 / 공무원')) {
              book_list = bookListObj.tc ?? [];
            } else if (tabValue.includes('교과서')) {
              book_list = bookListObj.textBook ?? [];
            } else {
              book_list = bookListObj.mockTest ?? [];
            }
            book_list = book_list.map(item => (item.id === bookId ? { ...item, checked: true } : item));
            if (tabValue.includes('유아 / 초등')) {
              setBookListObj(prev => ({ ...prev, ce: book_list }));
            } else if (tabValue.includes('중등 / 고등')) {
              setBookListObj(prev => ({ ...prev, mh: book_list }));
            } else if (tabValue.includes('토익 / 공무원')) {
              setBookListObj(prev => ({ ...prev, tc: book_list }));
            } else if (tabValue.includes('교과서')) {
              setBookListObj(prev => ({ ...prev, textBook: book_list }));
            } else if (tabValue.includes('수능/모의고사')) {
              setBookListObj(prev => ({ ...prev, mockTest: book_list }));
            }
          }
        }
        // 해당 bookId 추가 안 됨 (신규 추가)
        setPrintBooksView(prev => ({
          ...prev,
          [bookId]: {
            id: bookId,
            bookName: bookName
              .replace('<span style="color: rgb(210, 210, 210)">&nbsp;', '')
              .replace(/<\/span>/gi, '')
              .replace('<span style="background-color: #fffa8b;">', ''),
            category: bookCategoryId,
            units: {
              [unitId]: {
                unitId,
                unitName,
              },
            },
          },
        }));
      } else {
        // 해당 bookId 이미 추가 됨 (기존) - 유닛 제거, 유닛 추가
        const isExistUnit = printBooksView[bookId].units[unitId];
        const new_books: {
          [key: string]: {
            id: number;
            bookName: string;
            category: number;
            units: { [key: string]: { unitId: number; unitName: string } };
          };
        } = {
          ...printBooksView,
        };
        let new_units: { [key: string]: { unitId: number; unitName: string } } = {
          ...printBooksView[bookId].units,
        };
        let unit_data = units[bookId];
        if (isExistUnit) {
          unit_data = unit_data.map(unit => (unit.id == unitId ? { ...unit, checked: false } : unit));
          setUnits(prev => ({ ...prev, [bookId]: unit_data }));
          delete new_units[unitId];
          if (Object.keys(new_units).length == 0) {
            delete new_books[bookId];
            setPrintBooksView(new_books);
          } else {
            setPrintBooksView(prev => ({
              ...prev,
              [bookId]: {
                id: bookId,
                bookName: bookName
                  .replace('<span style="color: rgb(210, 210, 210)">&nbsp;', '')
                  .replace(/<\/span>/gi, '')
                  .replace('<span style="background-color: #fffa8b;">', ''),
                units: new_units,
              },
            }));
          }
          const all_checked = unit_data.every(unit => unit.checked === true);

          if (bookCategoryId == -1) {
            let book_list: (CustomBookDataType | FolderDataType)[] = bookListObj.homeMade ?? [];
            if (selectedFolderId > 0) {
              const folder = book_list.find(book => book.id === selectedFolderId) as FolderDataType;
              if (folder.books) {
                folder.books = folder.books.map(item =>
                  item.id === bookId ? { ...item, checked: all_checked ? true : false } : item,
                );

                book_list.map(book => (book.id === selectedFolderId ? folder : book));
                setBookListObj(prev => ({ ...prev, homeMade: book_list }));
              }
            } else {
              book_list = book_list.map(item =>
                item.id === bookId ? { ...item, checked: all_checked ? true : false } : item,
              );
              setBookListObj(prev => ({ ...prev, homeMade: book_list }));
            }
          } else {
            let book_list: OriginalBookDataType[];
            if (tabValue.includes('유아 / 초등')) {
              book_list = bookListObj.ce ?? [];
            } else if (tabValue.includes('중등 / 고등')) {
              book_list = bookListObj.mh ?? [];
            } else if (tabValue.includes('토익 / 공무원')) {
              book_list = bookListObj.tc ?? [];
            } else if (tabValue.includes('교과서')) {
              book_list = bookListObj.textBook ?? [];
            } else {
              book_list = bookListObj.mockTest ?? [];
            }
            book_list = book_list.map(item =>
              item.id === bookId ? { ...item, checked: all_checked ? true : false } : item,
            );
            if (tabValue.includes('유아 / 초등')) {
              setBookListObj(prev => ({ ...prev, ce: book_list }));
            } else if (tabValue.includes('중등 / 고등')) {
              setBookListObj(prev => ({ ...prev, mh: book_list }));
            } else if (tabValue.includes('토익 / 공무원')) {
              setBookListObj(prev => ({ ...prev, tc: book_list }));
            } else if (tabValue.includes('교과서')) {
              setBookListObj(prev => ({ ...prev, textBook: book_list }));
            } else if (tabValue.includes('수능/모의고사')) {
              setBookListObj(prev => ({ ...prev, mockTest: book_list }));
            }
          }
        } else {
          unit_data = unit_data.map(unit => (unit.id == unitId ? { ...unit, checked: true } : unit));
          setUnits(prev => ({ ...prev, [bookId]: unit_data }));
          new_units = {
            ...new_units,
            [unitId]: {
              unitId,
              unitName,
            },
          };
          setPrintBooksView(prev => ({
            ...prev,
            [bookId]: {
              id: bookId,
              category: bookCategoryId,
              bookName: bookName
                .replace('<span style="color: rgb(210, 210, 210)">&nbsp;', '')
                .replace(/<\/span>/gi, '')
                .replace('<span style="background-color: #fffa8b;">', ''),
              units: new_units,
            },
          }));
          const all_checked = unit_data.every(unit => unit.checked === true);

          if (bookCategoryId == -1) {
            let book_list: (CustomBookDataType | FolderDataType)[] = bookListObj.homeMade ?? [];
            if (selectedFolderId > 0) {
              const folder = book_list.find(book => book.id === selectedFolderId) as FolderDataType;
              if (folder.type == 'folder' && folder.books) {
                folder.books = folder.books.map(item =>
                  item.id === bookId ? { ...item, checked: all_checked ? true : false } : item,
                );

                book_list.map(book => (book.id === selectedFolderId ? folder : book));
                setBookListObj(prev => ({ ...prev, homeMade: book_list }));
              }
            } else {
              book_list = book_list.map(item =>
                item.id === bookId ? { ...item, checked: all_checked ? true : false } : item,
              );
              setBookListObj(prev => ({ ...prev, homeMade: book_list }));
            }
          } else {
            let book_list: OriginalBookDataType[];
            if (tabValue.includes('유아 / 초등')) {
              book_list = bookListObj.ce ?? [];
            } else if (tabValue.includes('중등 / 고등')) {
              book_list = bookListObj.mh ?? [];
            } else if (tabValue.includes('토익 / 공무원')) {
              book_list = bookListObj.tc ?? [];
            } else if (tabValue.includes('교과서')) {
              book_list = bookListObj.textBook ?? [];
            } else {
              book_list = bookListObj.mockTest ?? [];
            }
            book_list = book_list.map(item =>
              item.id === bookId ? { ...item, checked: all_checked ? true : false } : item,
            );
            if (tabValue.includes('유아 / 초등')) {
              setBookListObj(prev => ({ ...prev, ce: book_list }));
            } else if (tabValue.includes('중등 / 고등')) {
              setBookListObj(prev => ({ ...prev, mh: book_list }));
            } else if (tabValue.includes('토익 / 공무원')) {
              setBookListObj(prev => ({ ...prev, tc: book_list }));
            } else if (tabValue.includes('교과서')) {
              setBookListObj(prev => ({ ...prev, textBook: book_list }));
            } else if (tabValue.includes('수능/모의고사')) {
              setBookListObj(prev => ({ ...prev, mockTest: book_list }));
            }
          }
        }
      }
    },
    [printBooksView, units, selectedFolderId, tabValue],
  );

  const onClickUnit = useCallback(
    (
      event: React.MouseEvent<any, MouseEvent>,
      checked: boolean,
      {
        bookId,
        bookName,
        unitId,
        unitName,
      }: {
        bookId: number;
        bookName: string;
        unitId: number;
        unitName: string;
      },
    ) => {
      setLastClickUnit({ checked, bookId, unitId });
      if (event.shiftKey && lastClickUnit && bookId == lastClickUnit.bookId) {
        const idx1 = units[bookId].findIndex(unit => unit.id == lastClickUnit.unitId);
        const idx2 = units[bookId].findIndex(unit => unit.id == unitId);
        if (idx1 == idx2) {
          clickUnitSetPrintBooksView({ bookId, bookName, unitId, unitName });
        } else {
          const updates = [];
          if (idx1 > idx2) {
            for (let i = idx2; i <= idx1; i++) {
              if (lastClickUnit.checked == units[bookId][i].checked) {
                const unit_id = units[bookId][i].id;
                const unit_name = units[bookId][i].name;
                updates.push({ unitId: unit_id, unitName: unit_name });
              }
            }
          } else if (idx2 > idx1) {
            for (let i = idx1; i <= idx2; i++) {
              if (lastClickUnit.checked == units[bookId][i].checked) {
                const unit_id = units[bookId][i].id;
                const unit_name = units[bookId][i].name;
                updates.push({ unitId: unit_id, unitName: unit_name });
              }
            }
          }
          if (updates.length > 0) clickUnitSetPrintBooksViews(bookId, bookName, lastClickUnit.checked, updates);
        }
      } else {
        clickUnitSetPrintBooksView({ bookId, bookName, unitId, unitName });
      }
    },
    [clickUnitSetPrintBooksView, lastClickUnit],
  );

  const onClickPrintConfirm = useCallback(() => {
    if (printType == 0) {
      setType(0);
      fetchPrintWords();
    } else {
      setType(0);
      fetchTestWords('print');
    }
  }, [printBooksView, printType]);

  const fetchPrintWords = useCallback(async () => {
    const origin_books: IBook[] = [];
    const customer_books: IBook[] = [];
    Object.keys(printBooksView).forEach(bookId => {
      const { id, units, category } = printBooksView[bookId];

      const bookUnitData: {
        id: number;
        units: number[];
      } = {
        id,
        units: Object.keys(units).map(unitId => parseInt(unitId)),
      };

      if (category === -1) {
        customer_books.push(bookUnitData);
      } else {
        origin_books.push(bookUnitData);
      }
    });
    const uri = `/customers/${customer_id}/books/printwords`;
    const args = { origin_books, customer_books };
    const res: PrintBookListResType = await fetchPostApi(uri, args);
    if (res.result) {
      setPrintBookList(res.data);
      setMode('print');
    }
  }, [printBooksView]);

  const onClickBackButton = useCallback(() => {
    setMode('list');
    setPrintBookList({});
  }, []);

  const onClickBookDelete = useCallback(
    (book: {
      id: number;
      bookName: string;
      category: number;
      units: { [key: string]: { unitId: number; unitName: string } };
    }) => {
      if (book.category == -1) {
        let book_list: (CustomBookDataType | FolderDataType)[] = bookListObj.homeMade ?? [];

        book_list = book_list.map(item => (item.id === book.id ? { ...item, checked: false } : item));

        let unit_data = units[book.id];

        unit_data = unit_data.map(unit => ({
          ...unit,
          checked: false,
        }));

        setPrintBooksView(prev => {
          const newView = { ...prev };
          delete newView[book.id];
          return newView;
        });

        setUnits(prev => ({ ...prev, [book.id]: unit_data }));
        setBookListObj(prev => ({ ...prev, homeMade: book_list }));
      } else {
        let book_list: OriginalBookDataType[];
        if (tabValue.includes('유아 / 초등')) {
          book_list = bookListObj.ce ?? [];
        } else if (tabValue.includes('중등 / 고등')) {
          book_list = bookListObj.mh ?? [];
        } else if (tabValue.includes('토익 / 공무원')) {
          book_list = bookListObj.tc ?? [];
        } else if (tabValue.includes('교과서')) {
          book_list = bookListObj.textBook ?? [];
        } else {
          book_list = bookListObj.mockTest ?? [];
        }

        book_list = book_list.map(item => (item.id === book.id ? { ...item, checked: false } : item));

        let unit_data = units[book.id];

        unit_data = unit_data.map(unit => ({
          ...unit,
          checked: false,
        }));

        setPrintBooksView(prev => {
          const newView = { ...prev };
          delete newView[book.id];
          return newView;
        });

        setUnits(prev => ({ ...prev, [book.id]: unit_data }));

        if (tabValue.includes('유아 / 초등')) {
          setBookListObj(prev => ({ ...prev, ce: book_list }));
        } else if (tabValue.includes('중등 / 고등')) {
          setBookListObj(prev => ({ ...prev, mh: book_list }));
        } else if (tabValue.includes('토익 / 공무원')) {
          setBookListObj(prev => ({ ...prev, tc: book_list }));
        } else if (tabValue.includes('교과서')) {
          setBookListObj(prev => ({ ...prev, textBook: book_list }));
        } else if (tabValue.includes('수능/모의고사')) {
          setBookListObj(prev => ({ ...prev, mockTest: book_list }));
        }
      }
    },
    [printBooksView, tabValue],
  );

  const onClickTestCheck = useCallback(
    (type: 'mtos' | 'stom', e?: React.ChangeEvent<HTMLInputElement>) => {
      if (e) e.stopPropagation();
      const copyTestSetting = JSON.parse(JSON.stringify(testSetting));
      copyTestSetting[type].check = !copyTestSetting[type].check;
      if (!copyTestSetting[type].check) {
        copyTestSetting[type].short = '';
        copyTestSetting[type].multi = '';
        const totalSelectCount =
          +copyTestSetting.mtos.multi +
          +copyTestSetting.mtos.short +
          +copyTestSetting.stom.multi +
          +copyTestSetting.stom.short;
        setRemainWordCount(wordCount - totalSelectCount);
      }
      setTestSetting(copyTestSetting);
    },
    [testSetting, wordCount, remainWordCount],
  );

  const onChangeCounter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, key1: 'mtos' | 'stom', key2: 'multi' | 'short') => {
      const copyTestSetting = JSON.parse(JSON.stringify(testSetting));
      copyTestSetting[key1][key2] = +e.target.value.replace(/[^0-9]/g, '');
      const totalSelectCount =
        +copyTestSetting.mtos.multi +
        +copyTestSetting.mtos.short +
        +copyTestSetting.stom.multi +
        +copyTestSetting.stom.short;
      if (wordCount < totalSelectCount) {
        modal_alert.openModalAlert('overflow_test_word');
      } else {
        setTestSetting(copyTestSetting);
        setRemainWordCount(wordCount - totalSelectCount);
      }
    },
    [testSetting, wordCount, remainWordCount],
  );

  const onChangeRadio = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTestPrintType(+e.target.value);
      if (+e.target.value == 1) {
        setRemainWordCount(wordCount);
        setTestSetting({
          stom: {
            check: true,
            multi: 0,
            short: 0,
          },
          mtos: {
            check: true,
            multi: 0,
            short: 0,
          },
        });
      }
    },
    [testPrintType, wordCount],
  );

  const fetchTestWords = useCallback(
    async (type: string) => {
      const origin_books: {
        id: number;
        units: number[];
      }[] = [];
      const customer_books: {
        id: number;
        units: number[];
      }[] = [];
      Object.keys(printBooksView).forEach(bookId => {
        const { id, units, category } = printBooksView[bookId];

        const bookUnitData: {
          id: number;
          units: number[];
        } = {
          id,
          units: Object.keys(units).map(unitId => parseInt(unitId)),
        };

        if (category === -1) {
          customer_books.push(bookUnitData);
        } else {
          origin_books.push(bookUnitData);
        }
      });
      const uri = `/customers/${customer_id}/books/testwords?type=${type}`;
      const args = { origin_books, customer_books };
      if (type == 'count') {
        const res: TestPrintCountResType = await fetchPostApi(uri, args);
        if (res.result) {
          const word_count = res.data;
          const copyTestSetting = JSON.parse(JSON.stringify(testSetting));
          let totalSelectCount =
            copyTestSetting.mtos.multi +
            copyTestSetting.mtos.short +
            copyTestSetting.stom.multi +
            copyTestSetting.stom.short;
          if (word_count < totalSelectCount) {
            modal_alert.openModalAlert('overflow_test_word');
            copyTestSetting.stom.multi = 0;
            copyTestSetting.stom.short = 0;
            copyTestSetting.mtos.multi = 0;
            copyTestSetting.mtos.short = 0;
            setTestSetting(copyTestSetting);
            totalSelectCount = 0;
          }
          setWordCount(word_count);
          setRemainWordCount(word_count - totalSelectCount);
        }
      } else {
        const res: TestPrintResType = await fetchPostApi(uri, args);
        if (res.result) {
          setTestWordList(res.data);
          const origin_books_ids = origin_books.map(book => book.id);
          const customer_books_ids = customer_books.map(book => book.id);

          const books_ids = [...origin_books_ids, ...customer_books_ids];
          setPrintTestList(res.data);
          const example_uri = `/customers/${customer_id}/books/testexample`;
          const example_args = { books_ids };

          const example_res: TestPrintExampleResType = await fetchPostApi(example_uri, example_args);
          if (example_res.result) {
            setExample(example_res.data);
            setMode('print');
          }
        }
      }
    },
    [printBooksView, testSetting],
  );

  const tab_props = {
    tabValue,
    contents: tab_contents,
    onClickTab,
  };

  const print_props = {
    type: type,
    testWordList,
    example,
    printBookList,
    onClickBackButton,
  };

  return (
    <BookPrintUI
      {...tab_props}
      {...print_props}
      mode={mode}
      printType={printType}
      testPrintType={testPrintType}
      searchBarKey={searchBarKey}
      selectedBook={selectedBook}
      gradeSortProps={gradeSortProps}
      subSortProps={subSortProps}
      searchBook={searchBook}
      bookList={bookList}
      testSetting={testSetting}
      wordCount={wordCount}
      remainWordCount={remainWordCount}
      openTooltip={openTooltip}
      setOpenTooltip={setOpenTooltip}
      onClickBook={onClickBook}
      onClickBookCheck={onClickBookCheck}
      onClickBookDelete={onClickBookDelete}
      units={units}
      selectedCourse={selectedCourse}
      onClickUnit={onClickUnit}
      onClickFolder={onClickFolder}
      printBooksView={printBooksView}
      isLoading={isLoading}
      onClickPrintConfirm={onClickPrintConfirm}
      onChangeCounter={onChangeCounter}
      onChangeRadio={onChangeRadio}
      onClickTestCheck={onClickTestCheck}
      onClickStudyContents={onClickStudyContents}
    />
  );
}
