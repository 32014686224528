import * as React from 'react';
import { useMemo } from 'react';
import { CSSProperties, Dispatch, SetStateAction, useEffect, useState, useContext } from 'react';
import { SxProps, Theme } from '@mui/material';
import { styled, Box, Button, Menu as MuiMenu, MenuItem, MenuProps } from '@mui/material';

import { GoTriangleDown } from 'react-icons/go';
import { ModalContext } from '../../provider/ModalProvider';
import { FaChevronDown } from 'react-icons/fa';

interface DropdownCircleProps {
  id?: string;
  contents?: string[];
  type: 'edit' | 'custom';
  title?: string;
  setTitle?: Dispatch<SetStateAction<string>>;
  idx?: number;
  checkCount?: number;
  useDisabled?: boolean;
  onClickItem: (idx: number | string) => void;
  className?: string;
  disabled?: boolean;
  disableOwner?: boolean;
  sx?: SxProps<Theme>;
  menuStyle?: CSSProperties;
}

const Menu = styled((props: MenuProps) => (
  <MuiMenu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme, style }) => ({
  '& .MuiPaper-root': {
    borderRadius: 2,
    marginTop: theme.spacing(0),
    minWidth: '9rem',
    color: 'var(--black)',
    fontSize: '1rem',
    border: '1px solid var(--gray-2)',
    borderTop: 'none',
    maxHeight: '18rem',
    overflowX: 'auto',
    ...style,
    '& .MuiMenu-list': {
      width: '100%',
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      height: '40px',
      padding: '0 1rem',
      overflow: 'hidden',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      div: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },

    '.down': {
      color: '#c0c0c0',
    },
  },
}));

const DropdownButton = styled(Button)({
  minWidth: '9rem',
  height: '2.4rem',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 1rem',
  textTransform: 'none',
  svg: {
    width: '0.875rem',
    height: '0.875rem',
    marginTop: '-2px',
  },
  backgroundColor: '#f2f3f5',
  color: '#9d9e9f',
  fontSize: '1rem',
  border: 'none',
  borderRadius: '24px',
  '&:hover': {
    backgroundColor: '#f2f3f5',
    color: '#9d9e9f',
    border: 'none',
  },
  '&.Mui-disabled': {
    color: '#9d9e9f',
    border: 'none',
  },
});

const DetailDeco = styled('div')(() => ({
  width: '0.875rem',
  height: '0.875rem',
  borderBottom: '1px dotted var(--gray-1)',
  borderLeft: '1px dotted var(--gray-1)',
  position: 'absolute',
  top: 'calc(22px - 0.5rem)',
  left: '1rem',
  transform: 'translateY(-50%)',
}));

const ExposureDivider = styled(Box)(({ theme }) => ({
  width: '90%',
  height: '2px',
  backgroundColor: '#f7f7f7',

  margin: '0.25rem auto',
}));

function DropdownCircle(props: DropdownCircleProps) {
  const { root_visible } = useContext(ModalContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const id = props.id;
  const contents = props.contents;
  const type = props.type;
  const onClickItem = props.onClickItem;
  const className = props.className;
  const disabled = props.disabled;
  const check_count = props.checkCount;
  const use_disabled = props.useDisabled;
  const title = props.title;
  const setTitle = props.setTitle;
  const sx = props.sx;
  const menu_style = props.menuStyle;
  const disable_owner = props.disableOwner;

  const [dropdownContents, setDropdownContents] = useState<
    (
      | string
      | {
          title: string;
          listView: JSX.Element;
        }
    )[]
  >([]);
  const [dropdownTitle, setDropdownTitle] = useState('');
  const [classList, setClassList] = useState<{ label: string; id: number }[]>([]);
  const [clickStatus, setClickStatus] = useState(false);
  const [originClassList, setOriginClassList] = useState<ClassListType[]>([]);
  const [exposureIndex, setExposureIndex] = useState<null | number>(null);

  useEffect(() => {
    return () => {
      setDropdownContents([]);
      setDropdownTitle('');
      setClassList([]);
      setClickStatus(false);
      setExposureIndex(null);
    };
  }, []);

  useEffect(() => {
    setCurrentTitle();
  }, [contents, title]);

  useEffect(() => {
    if (open && contents) {
      const exposure_idx = contents.findIndex(item => `${item}`.includes('exposure'));
      if (Number(exposure_idx) >= 0) {
        setExposureIndex(exposure_idx);
      }
    }
  }, [open]);

  const setCurrentTitle = () => {
    if (contents && contents.length > 0) {
      if (type == 'custom' && title) {
        setDropdownTitle(title);
      } else {
        setDropdownTitle('관리');
      }

      setDropdownContents([...contents]);
    }
  };

  useEffect(() => {
    if (!root_visible && clickStatus) {
      setCurrentTitle();
      setClickStatus(false);
    }
  }, [root_visible]);

  function handleMenuClick(idx: number) {
    onClickItem(idx);

    let title: string;
    if (typeof dropdownContents[idx] === 'string') {
      title = `${dropdownContents[idx]}`;
    } else {
      title = `${(dropdownContents[idx] as { title: 'string'; listView: JSX.Element }).title}`;
    }

    title = title.replace('_require', '');
    title = title.replace('::disabled', '');
    title = title.replace('::exposure', '');
    setDropdownTitle(title);
    if (setTitle) {
      let title: string;
      if (typeof dropdownContents[idx] === 'string') {
        title = `${dropdownContents[idx]}`;
      } else {
        title = `${(dropdownContents[idx] as { title: 'string'; listView: JSX.Element }).title}`;
      }

      title = title.replace('_require', '');
      title = title.replace('::disabled', '');
      title = title.replace('::exposure', '');
      setTitle(title);
    }
    setClickStatus(true);
    handleClose();
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setMenuDisabled = (text: string | JSX.Element): boolean => {
    if (use_disabled && typeof text == 'string') {
      if (text.indexOf('::disabled') >= 0) {
        if (text.indexOf('disabled_require') >= 0) {
          return true;
        } else {
          if (check_count == 1) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isNotice = type == 'custom' && contents?.includes('공지사항');

  // 공지사항 페이지에 들어가는 아이콘
  const dropdownCircleEndIcon = useMemo(() => {
    return <FaChevronDown />;
  }, []);

  return (
    <>
      <DropdownButton
        id='demo-customized-button'
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='outlined'
        disableElevation
        color={'blue'}
        onClick={handleClick}
        className={className}
        disabled={disabled}
        sx={sx}
      >
        {title ? title : dropdownTitle}
        {isNotice && dropdownCircleEndIcon}
      </DropdownButton>
      <Menu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={menu_style}
      >
        {dropdownContents.length > 0
          ? dropdownContents.map((item, idx) => {
              return [
                exposureIndex == idx ? <ExposureDivider /> : null,
                <MenuItem
                  onClick={() => handleMenuClick(idx)}
                  disableRipple
                  key={`menu_item_${idx}`}
                  disabled={
                    disable_owner
                      ? disable_owner
                      : typeof item == 'string'
                      ? setMenuDisabled(item)
                      : setMenuDisabled(item.title)
                  }
                >
                  <Box
                    sx={{ positon: 'relative' }}
                    className={['휴원생', '퇴원생', '미등록생'].includes(`${item}`) ? 'down' : ''}
                  >
                    {typeof item == 'string'
                      ? item.replace('::disabled', '').replace('_require', '').replace('::exposure', '')
                      : item.listView}
                  </Box>
                </MenuItem>,
              ];
            })
          : null}
      </Menu>
    </>
  );
}

export default DropdownCircle;
