import { canvasStyles } from './RealisticConfetti.Styles';
import Realistic from 'react-canvas-confetti/dist/presets/realistic';
import { ConfettiType } from 'recoil/common/confetti';

interface RealisticConfettiVAProps extends ConfettiType {
  decorateOptions: any;
}

export function RealisticConfettiUI({ active, decorateOptions }: RealisticConfettiVAProps) {
  if (active)
    return (
      <Realistic
        autorun={{ speed: 0.5, duration: 8 }}
        style={canvasStyles}
        decorateOptions={decorateOptions}
      ></Realistic>
    );
  return null;
}
