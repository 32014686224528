import { SearchBarUI, SearchIcon, XIcon, SearchBarVAProps } from './SearchBar.Presenter';
import { useState, useMemo, useCallback, useEffect } from 'react';
import React from 'react';

export interface SearchBarContainerProps extends Pick<SearchBarVAProps, 'sx'> {
  searchKeyword?: string;
  placeholder?: string;
  searchRightAway?: boolean;
  onChangeValue: (value: string) => void;
  removeTrigger?: boolean;
  offRemoveTrigger?: () => void;
}

export const SearchBarContainer = React.memo(function SearchBarContainer({
  searchKeyword,
  placeholder,
  onChangeValue,
  searchRightAway,
  sx,
  removeTrigger,
  offRemoveTrigger,
}: SearchBarContainerProps) {
  const [focus, setFocus] = useState(false);
  const [keep, setKeep] = useState(searchKeyword ? true : false);
  const [value, setValue] = useState(searchKeyword ? searchKeyword : '');

  useEffect(() => {
    if (removeTrigger) {
      removeKeyword();
      if (offRemoveTrigger) offRemoveTrigger();
    }
  }, [removeTrigger]);

  const onFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const onBlur = useCallback(() => {
    setFocus(false);
  }, []);

  const removeKeyword = useCallback(() => {
    setValue('');
    setKeep(false);
    onChangeValue('');
  }, [onChangeValue]);

  const confirm = useCallback(
    (keyword?: string) => {
      if (!focus) return;
      if ((value.length != 0 && !searchRightAway) || (keyword && keyword.trim().length > 0 && searchRightAway)) {
        setKeep(true);
      } else if (keyword?.trim() === '' && searchRightAway) {
        setKeep(false);
      }

      if (searchRightAway && typeof keyword === 'string') {
        onChangeValue(keyword);
      } else {
        onChangeValue(value);
      }
    },
    [focus, value, onChangeValue, searchRightAway],
  );

  const icon = useMemo(() => {
    if (keep) {
      return <XIcon className='x_icon' onClick={removeKeyword} />;
    } else {
      return <SearchIcon className={focus ? 'active' : ''} onMouseDown={() => confirm()} />;
    }
  }, [keep, focus, confirm]);

  const onChangeText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setKeep(false);
      setValue(e.target.value);
      if (searchRightAway) {
        confirm(e.target.value);
      }
    },
    [searchRightAway, confirm],
  );

  const onKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key == 'Enter') {
        confirm();
      }
    },
    [confirm],
  );

  const props = {
    icon,
    value,
    placeholder,
    sx,
    onChange: onChangeText,
    onKeyPress,
    onFocus,
    onBlur,
  };

  return <SearchBarUI {...props} />;
});
