import {
  BirthDateInputWrap,
  CheckBoxWrapper,
  ContentWrapper,
  FormWrap,
  FormWrapper,
  InputTextStyle,
  ModalBackground,
  ModalCloseButton,
  ModalContainer,
  RegisterCardButton,
} from './ModalCardInfo.Styles';

import { Checkbox, MenuItem, Modal, Select, SelectChangeEvent } from '@mui/material';
import { checkboxData } from './ModalCardInfo.Container';
import { MdOutlineClose } from 'react-icons/md';

export interface ModalCardInfoProps {
  promotionCheckboxData: (
    | {
        id: string;
        label: string;
        notice?: undefined;
        content?: undefined;
      }
    | {
        id: string;
        label: string;
        notice: string;
        content: string;
      }
  )[];
  duration: number;
  visible: boolean;
  handleClose: () => void;
  cardNumber: string;
  handleCardNumberInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBirthDateInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleMonthChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  handleYearChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  handleTermsButtonClick: (id: string) => void;
  selectedYear: string;
  selectedMonth: string;
  futureYears: number[];
  birthDate: string;
  checkedIds: string[];
  handleCheckboxChange: (id: string) => void;
  handleCardPaymentButtonClick: () => void;
  checkBoxSelectAll: boolean;
  currentPage: string;
  isCorporation: boolean;
  installmentMonths?: string;
  handleInstallmentMonthChange: (event: SelectChangeEvent<string>) => void;
  handlePromotionCheckboxChange: (id: string) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  top: string;
}

export const ModalCardInfoUI = ({
  promotionCheckboxData,
  duration,
  visible,
  handleClose,
  cardNumber,
  handleCardNumberInputChange,
  handleBirthDateInputChange,
  handleYearChange,
  handleMonthChange,
  selectedMonth,
  selectedYear,
  futureYears,
  birthDate,
  checkedIds,
  currentPage,
  handleCheckboxChange,
  handleCardPaymentButtonClick,
  handleTermsButtonClick,
  checkBoxSelectAll,
  isCorporation,
  installmentMonths,
  handleInstallmentMonthChange,
  handlePromotionCheckboxChange,
  onFocus,
  onBlur,
  top,
}: ModalCardInfoProps) => {
  const MenuProps = {
    PaperProps: {
      style: {
        height: 200,
      },
    },
  };

  const paymentButtonDisabled =
    selectedYear &&
    selectedMonth &&
    (isCorporation ? birthDate.length == 12 : birthDate.length == 6) &&
    cardNumber.replace(/-/g, '').length >= 16 &&
    checkBoxSelectAll
      ? false
      : true;

  return (
    <Modal open={visible}>
      <ModalBackground>
        <ModalContainer sx={{ marginTop: `${top}` }}>
          <ModalCloseButton onClick={handleClose}>
            <MdOutlineClose />
          </ModalCloseButton>
          {currentPage === 'main' ? (
            <ContentWrapper>
              <FormWrapper>
                <FormWrap>
                  <span style={{ width: '5.6rem', fontWeight: '600' }}>카드번호</span>
                  <InputTextStyle
                    className='card-number'
                    placeholder='카드번호를 기입하세요.'
                    value={cardNumber}
                    onChange={handleCardNumberInputChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                  />
                </FormWrap>
                <FormWrap>
                  <span className='valid-date-label' style={{ width: '6.7rem', fontWeight: '600' }}>
                    유효기간
                  </span>
                  <Select
                    displayEmpty
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    renderValue={(selected: string) => (selected ? selected : 'MM')}
                    sx={{
                      color: selectedMonth ? '' : '#cbcfda',
                      width: '50%',
                      height: '2.5rem',
                      borderRadius: '0',
                      '&:hover': {
                        '&& fieldset': {
                          border: '1px solid #c4c4c4',
                        },
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #c4c4c4',
                      },
                      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid red',
                      },
                    }}
                    className='dropdown-year'
                    MenuProps={MenuProps}
                  >
                    {Array.from({ length: 12 }, (_, index) => (
                      <MenuItem key={index + 1} value={String(index + 1)}>
                        {String(index + 1).padStart(2, '0')}
                      </MenuItem>
                    ))}
                  </Select>
                  <span style={{ marginLeft: '-0.5rem' }}>월</span>
                  <Select
                    displayEmpty
                    onChange={handleYearChange}
                    value={selectedYear}
                    renderValue={(selected: string) => (selected ? selected : 'YY')}
                    sx={{
                      width: '50%',
                      height: '2.5rem',
                      borderRadius: '0',
                      color: selectedYear ? '' : '#cbcfda',
                      '&:hover': {
                        '&& fieldset': {
                          border: '1px solid #c4c4c4',
                        },
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #c4c4c4',
                      },
                    }}
                    className='dropdown-year'
                    MenuProps={MenuProps}
                  >
                    {futureYears.map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                  <span style={{ marginLeft: '-0.5rem' }}>년</span>
                </FormWrap>
                <BirthDateInputWrap>
                  <span style={{ width: '4.29rem', fontWeight: '600' }}>
                    {isCorporation ? '사업자번호' : '생년월일'}
                  </span>
                  <InputTextStyle
                    placeholder={isCorporation ? '숫자 10자리' : 'YYMMDD'}
                    value={birthDate}
                    onChange={handleBirthDateInputChange}
                    sx={{ width: isCorporation ? '79%' : '38%' }}
                  />
                  {!isCorporation && (
                    <div className='birthdate-explanation' style={{ color: '#cbcbd7' }}>{`(주민등록번호 앞자리)`}</div>
                  )}
                </BirthDateInputWrap>
                <div key={checkboxData[0].id} className='check-box-corporation'>
                  <Checkbox
                    sx={{
                      '&.Mui-checked': {
                        color: '#4620e9',
                      },
                    }}
                    checked={isCorporation}
                    onChange={() => handleCheckboxChange(checkboxData[0].id)}
                  />
                  <span
                    onClick={() => handleCheckboxChange(checkboxData[0].id)}
                    style={{ marginRight: '0.1rem', cursor: 'pointer' }}
                  >
                    {checkboxData[0].label}
                  </span>
                  {checkboxData[0].notice && (
                    <span
                      onClick={() => handleTermsButtonClick(checkboxData[0].id)}
                      className='read-notice'
                    >{`${checkboxData[0].notice}`}</span>
                  )}
                </div>
                {duration == 0 ? null : (
                  <FormWrap>
                    <span style={{ width: '5.6rem', fontWeight: '600' }}>할부기간</span>
                    <Select
                      displayEmpty
                      value={installmentMonths}
                      onChange={handleInstallmentMonthChange}
                      renderValue={(selected: string) => (selected && selected != '1' ? `${selected}개월` : '일시불')}
                      sx={{
                        width: '100%',
                        height: '2.5rem',
                        borderRadius: '0',
                        '&:hover': {
                          '&& fieldset': {
                            border: '1px solid #c4c4c4',
                          },
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid #c4c4c4',
                        },
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value='1'>일시불</MenuItem>
                      {Array.from({ length: 11 }, (_, index) => (
                        <MenuItem key={index + 2} value={String(index + 2)}>
                          {`${index + 2}개월`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormWrap>
                )}
              </FormWrapper>
              <CheckBoxWrapper>
                {promotionCheckboxData.map((checkbox, index) => (
                  <>
                    {index === 0 && duration == 0 ? (
                      <div
                        className='check-box-line'
                        style={{
                          width: '120%',
                          border: '1px solid #e9e9e9',
                          margin: '-6px 0 8px 40px',
                        }}
                      />
                    ) : null}
                    <div key={`chk_${checkbox.id}`} className='check-box'>
                      <Checkbox
                        sx={{
                          '&.Mui-checked': {
                            color: '#4620e9',
                          },
                        }}
                        checked={checkedIds.includes(checkbox.id)}
                        onChange={() => handlePromotionCheckboxChange(checkbox.id)}
                      />
                      <span
                        onClick={() => handlePromotionCheckboxChange(checkbox.id)}
                        style={{ marginRight: '0.1rem', cursor: 'pointer' }}
                      >
                        {checkbox.label}
                      </span>
                      {checkbox.notice && (
                        <span
                          onClick={() => handleTermsButtonClick(checkbox.id)}
                          className='read-notice'
                        >{`${checkbox.notice}`}</span>
                      )}
                    </div>
                    {index === 0 && duration != 0 ? (
                      <div
                        className='check-box-line'
                        style={{
                          width: '120%',
                          border: '1px solid #e9e9e9',
                          margin: '0.2rem 0 0.5rem 2.5rem',
                        }}
                      />
                    ) : null}
                  </>
                ))}
              </CheckBoxWrapper>
              <RegisterCardButton
                onClick={!paymentButtonDisabled && checkBoxSelectAll ? () => handleCardPaymentButtonClick() : undefined}
                variant='contained'
                disabled={paymentButtonDisabled}
              >
                카드결제 등록
              </RegisterCardButton>
            </ContentWrapper>
          ) : (
            <div style={{ height: '100%', overflow: 'auto' }}>
              <div
                className='show-scrollbar'
                style={{
                  height: currentPage === 'eula' ? '36rem' : '100%',
                  overflow: 'auto',
                  marginBottom: '1rem',
                }}
              >
                <p
                  style={{
                    height: 'max-content',
                    fontSize: '0.9rem',
                    color: 'gray',
                    padding: currentPage === 'eula' ? '0 1rem 0 1rem' : '',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: checkboxData.filter(item => item.id === currentPage)[0].content as string,
                  }}
                ></p>
              </div>
              <RegisterCardButton
                sx={{
                  marginTop: '1rem',
                  '@media (max-width: 415px)': {
                    marginTop: '0rem',
                  },
                }}
                variant='contained'
                onClick={() => handleTermsButtonClick('main')}
              >
                닫기
              </RegisterCardButton>
            </div>
          )}
        </ModalContainer>
      </ModalBackground>
    </Modal>
  );
};
