export function autoHyphenBusinessNumber(str: string) {
  str = str.replace(/[^0-9]/g, '');

  let tmp = '';

  for (let i = 0; i < str.length; i++) {
    if (i === 3 || i === 5) {
      tmp += '-';
    }
    tmp += str[i];
  }

  return tmp;
}

export function autoHyphenCard(str: string) {
  str = str.replace(/[^0-9]/g, '');

  let tmp = '';

  for (let i = 0; i < str.length; i += 4) {
    tmp += str.substring(i, i + 4);
    tmp += '-';
  }

  return tmp.slice(0, tmp.length - 1);
}
