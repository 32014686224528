import { Modal, Wrapper, Wrap, Header, HeaderIconWrap, Body, ChoiceCard } from './ModalStudySetting.Styles';
import { Box } from '@mui/material';
import { IoCloseSharp } from 'react-icons/io5';
import { ModalStudySettingContentType } from 'recoil/common/modalStudySetting';
import React from 'react';

export interface ModalStudySettingVAProps {
  visible: boolean;
  onClose: () => void;
  onClickClose: () => void;
  title: string;
  type: string;
  contents: ModalStudySettingContentType[];
  value?: number;
  onSuccess: (idx: number) => void;
}

export const ModalStudySettingUI = React.memo(function ModalStudySettingUI({
  visible,
  onClose,
  onClickClose,
  type,
  title,
  contents,
  value,
  onSuccess,
}: ModalStudySettingVAProps) {
  console.log('type', type);
  return (
    <Modal open={visible} onClose={onClose}>
      <Wrapper>
        <Wrap>
          <Header>
            <Box component={'span'} dangerouslySetInnerHTML={{ __html: title }} />
            <HeaderIconWrap onClick={onClickClose}>
              <IoCloseSharp />
            </HeaderIconWrap>
          </Header>
          <Body className={`${value && value !== 0 ? 'selected' : ''}`}>
            {contents?.map((content, cIdx) => (
              <ChoiceCard
                key={`content_${cIdx}`}
                onClick={() => onSuccess(cIdx)}
                className={`${cIdx + 1 === value ? 'selected' : ''}`}
                sx={{
                  width: `calc(${Math.round(100 / contents.length)}% - 0.5rem)`,
                  overflow: type.includes('courseType') || type.includes('joinType') ? 'hidden' : '',
                }}
              >
                <Box>
                  <img
                    style={{
                      transform:
                        type == 'printType' ? (content.content.includes('시험지') ? 'scale(0.67)' : 'scale(0.6)') : '',
                      maxWidth: type.includes('courseType') || type.includes('joinType') ? '100%' : '90%',
                      height: type.includes('courseType') || type.includes('joinType') ? '100%' : 'auto',
                    }}
                    src={content.src}
                    alt='image_1'
                  />
                </Box>
                <div
                  style={{ paddingRight: type.includes('courseType') || type.includes('joinType') ? '1.25rem' : '' }}
                  dangerouslySetInnerHTML={{
                    __html: content.content,
                  }}
                />
              </ChoiceCard>
            ))}
          </Body>
        </Wrap>
      </Wrapper>
    </Modal>
  );
});
