import bi_png from 'assets/images/longedu_bi.png';
import React from 'react';
import {
  Container,
  Contents,
  Footer,
  Header,
  MovePageButton,
  PrintWrap,
  ContainerBox,
  Worksheet,
  Wrapper,
  FullscreenButton,
  HeaderContents,
  DictionaryImageBox,
  TitleWrap,
  Title,
  FooterContent,
  FooterWrap,
  Content,
  NameBox,
  Name,
} from './WordWorksheet.Styles';
import { Box } from '@mui/material';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { BiExpand, BiCollapse } from 'react-icons/bi';
import dictionaryIcon from 'assets/images/print_dictionary.png';
import { LoadingContainer } from 'components/TestPrint/Loading/Loading.Container';

export interface WordWorksheetVAProps {
  worksheetKey: number;
  currentPage: number;
  maxPage: number;
  loading: boolean;
  progress: number;
  scrollWidth: number;
  scrollHeight: number;
  isFullscreen: boolean;
  printSetting: PrintSettingType;
  printWordList: TestPrintWordType[];
  userStateData: UserType;
  printRef: React.MutableRefObject<any>;
  containerRef: React.MutableRefObject<any>;
  onClickPageMove: (direction: 'prev' | 'next') => void;
  onClickFullscreen: () => void;
}

export const WordWorksheetUI = React.memo(function PrintUI({
  worksheetKey,
  currentPage,
  maxPage,
  loading,
  progress,
  scrollWidth,
  scrollHeight,
  isFullscreen,
  printSetting,
  printWordList,
  userStateData,
  printRef,
  containerRef,
  onClickPageMove,
  onClickFullscreen,
}: WordWorksheetVAProps) {
  return (
    <Wrapper>
      {loading ? <LoadingContainer progress={progress} /> : null}
      <FullscreenButton
        sx={{ right: `calc(1rem + ${scrollWidth}px)` }}
        disableRipple
        onClick={() => onClickFullscreen()}
        className='nonprint'
      >
        {isFullscreen ? <BiCollapse /> : <BiExpand />}
      </FullscreenButton>
      <MovePageButton
        className='nonprint'
        disableRipple
        onClick={() => onClickPageMove('prev')}
        disabled={currentPage == 0}
      >
        <FaChevronLeft />
      </MovePageButton>
      <MovePageButton
        disableRipple
        className='nonprint'
        sx={{ right: `${scrollWidth}px` }}
        onClick={() => onClickPageMove('next')}
        disabled={currentPage == maxPage}
      >
        <FaChevronRight />
      </MovePageButton>
      <Worksheet className='worksheet-template'>
        <Header className='worksheet-header'>
          <HeaderContents>
            <DictionaryImageBox>
              <img src={dictionaryIcon}></img>
            </DictionaryImageBox>
            <TitleWrap>
              <Title className='title'>
                <Box></Box>
              </Title>
              <NameBox>
                <Name className='worksheet-name'>이름 : </Name>
              </NameBox>
            </TitleWrap>
          </HeaderContents>
        </Header>
        <Contents className='worksheet-contents'></Contents>
        <Footer className='worksheet-footer'>
          <FooterWrap>
            <FooterContent sx={{ flex: 2 }} className='worksheet-logo'>
              <img src={bi_png} />
            </FooterContent>
            <FooterContent
              sx={{
                justifyContent: 'center',
                flex: 1,
              }}
              className='worksheet-pagenum'
            />
            <FooterContent className='worksheet-cname' sx={{ justifyContent: 'flex-end', flex: 2 }}>
              {userStateData.center_name}
            </FooterContent>
          </FooterWrap>
        </Footer>
      </Worksheet>
      <Container
        id='worksheet-container'
        className='show-scrollbar'
        sx={{ overflow: isFullscreen ? 'auto' : 'hidden', padding: isFullscreen ? 0 : '4px' }}
      >
        <ContainerBox ref={containerRef} fullscreen={isFullscreen ? 'true' : 'false'}>
          <PrintWrap ref={printRef} className='print-wrap'>
            <style type='text/css' media='print'>
              {'\
   @page { size: A4 portrait; }\
'}
            </style>
            {loading ? (
              <Worksheet>
                <Header className='worksheet-header'>
                  <HeaderContents>
                    <DictionaryImageBox>
                      <img src={dictionaryIcon}></img>
                    </DictionaryImageBox>
                    <TitleWrap>
                      <Title className='title'>
                        <Box></Box>
                      </Title>
                      <NameBox>
                        <Name className='worksheet-name'>이름 : </Name>
                      </NameBox>
                    </TitleWrap>
                  </HeaderContents>
                </Header>
                <Contents className='worksheet-contents'></Contents>
                <Footer className='worksheet-footer'>
                  <FooterWrap>
                    <FooterContent sx={{ flex: 2 }} className='worksheet-logo'>
                      <img src={bi_png} />
                    </FooterContent>
                    <FooterContent
                      sx={{
                        justifyContent: 'center',
                        flex: 1,
                      }}
                      className='worksheet-pagenum'
                    />
                    <FooterContent className='worksheet-cname' sx={{ justifyContent: 'flex-end', flex: 2 }}>
                      {userStateData.center_name}
                    </FooterContent>
                  </FooterWrap>
                </Footer>
              </Worksheet>
            ) : null}
          </PrintWrap>
        </ContainerBox>
      </Container>
      <Box key={`words_${worksheetKey}`} id='words' style={{ display: 'none' }}>
        {printWordList.map((word: PrintWordType, idx: number) => (
          <Content
            id={`word_${idx}`}
            className={`dontsplit font-${printSetting.fontsize} line-${printSetting.line}`}
            key={`word_${idx}`}
          >
            <Box>{idx + 1}</Box>
            <Box>
              <Box component={'span'}>{word.spell}</Box>
            </Box>
            <Box>
              <Box component={'span'}>{word.mean}</Box>
            </Box>
          </Content>
        ))}
      </Box>
    </Wrapper>
  );
});
