import { styled, OutlinedInput, Box } from '@mui/material';

interface InputWrapType {
  is_password?: string;
}

export const InputWrap = styled(Box)<InputWrapType>(props => ({
  width: '100%',
  position: 'relative',
  height: '3rem',
  marginBottom: props.is_password === 'true' ? '12px' : '0.5rem',
  '@media (max-width: 769px)': {
    height: '3.5rem',
    marginBottom: '0.5rem',
  },
}));

export const InputLoginText = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: theme.palette.white.main,
  fontSize: '1rem',
  borderRadius: '0.5rem',

  '&.Mui-disabled': {
    backgroundColor: 'rgb(239,239,239)',
    color: 'rgb(75,85,99)',
  },

  '.MuiOutlinedInput-input': {
    paddingTop: '17px',
    '@media (max-width: 769px)': {
      paddingLeft: '1rem',
    },
    '&::placeholder': {
      color: '#c7c7c7 !important',
      fontWeight: '500',
      letterSpacing: '-1px',
      opacity: 1,
      '@media (max-width: 769px)': {
        fontSize: '1.15rem',
      },
    },
  },

  '&:hover': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'var(--gray-2)',
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'var(--gray-2)',
  },

  '.password_icon': {
    position: 'absolute',
    right: '0.9rem',
    width: '1.4rem',
    height: '1.4rem',
    cursor: 'pointer',
    color: '#bdbbbc',
  },
}));
