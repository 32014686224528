import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ModalLectureApplyPresenters } from './ModalLectureApplyPresenters';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  ModalLectureApplyDataType,
  modalLectureApplyState,
  closeModalLectureApply,
  handlePricingAndBenefits,
  setIsSelectedDuration,
  setIsSelectedProduct,
  setRecommCode,
  addMonth,
} from 'recoil/common/modalLectureApply';
import { fetchGetApi, fetchPostApi } from 'utils/api';
import dayjs from 'dayjs';
import { resetUserState, userState } from 'recoil/model/user';
import {
  ModalCardInfoDataType,
  modalCardInfoState,
  openModalCardInfo,
  resetModalCardInfo,
} from 'recoil/common/modalCardInfo';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import {
  closeLoadingCircleSpinner,
  LoadingCircleSpinnerState,
  openLoadingCircleSpinner,
} from 'recoil/common/loadingCircleSpinner';
import { BASE_URL, PERSONAL_PRODUCT_CODES, PRESIDENT_OF_CENTER_TYPE, TEMP_STUDENTS_ACCOUNT_IDS } from 'utils/constants';
import { calculateProPayment } from 'utils/tools';
import { setUserInFireStoreToNull } from 'utils/firebase';
import { useNavigate } from 'react-router';
import { bookState } from 'recoil/model/book';
import { learningState } from 'recoil/model/learning';
import { pocketNoteState } from 'recoil/model/pocket_note';
import { modalHiddenMissionState } from 'recoil/common/modalHiddenMission';
import { hiddenMissionState } from 'recoil/common/mission';
import { BackgroundSoundContext } from 'provider/BackgroundSoundProvider';
import { deviceState } from 'recoil/common/device';
import { profilesState, ProfileType, setProfilePageMode } from 'recoil/common/profiles';

declare let window: any;

export const ModalLectureApplyContainer = React.memo(function ModalLectureApplyContainer() {
  const navigate = useNavigate();
  const setLoadingCircleSpinner = useSetRecoilState(LoadingCircleSpinnerState);
  const setToastBar = useSetRecoilState(toastBarState);
  const deviceStateData = useRecoilValue(deviceState);
  const { is_mobile, platform } = deviceStateData;
  const profilesStateData = useRecoilValue(profilesState);
  const { customer_id: profile_customer_id } = profilesStateData;
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const [modalLectureApply, setModalLectureApply] = useRecoilState<ModalLectureApplyDataType>(modalLectureApplyState);
  const [modalCardInfo, setModalCardInfo] = useRecoilState<ModalCardInfoDataType>(modalCardInfoState);
  const [paymentKind, setPaymentKind] = useState<number>(0);
  const [joinComp, setJoinComp] = useState<boolean>(false);
  const { isJoinEscape, customer_id } = userStateData;
  const { isRegister, cardNumber, year, cardMonth, birthDate, installmentMonths } = modalCardInfo;
  const { resetBGM } = useContext(BackgroundSoundContext);
  const [top, setTop] = useState<string>('0px');
  const [externalKeyboard, setExternalKeyboard] = useState<boolean>(false);
  const [isAccountPaymentProcessing, setIsAccountPaymentProcessing] = useState(false);
  const [isCardPaymentProcessing, setIsCardPaymentProcessing] = useState(false);

  const resetLearningState = useResetRecoilState(learningState);
  const resetBookState = useResetRecoilState(bookState);
  const resetPocketNoteState = useResetRecoilState(pocketNoteState);
  const resetModalMissionState = useResetRecoilState(modalHiddenMissionState);
  const resetHiddenMissionState = useResetRecoilState(hiddenMissionState);

  useEffect(() => {
    if (modalLectureApply.visible) {
      if (is_mobile) {
        if (typeof window.ExternalKeyboard == 'function') window.ExternalKeyboard.checkOnce();
        if (window.ExternalKeyboard.isUsed) setExternalKeyboard(true);
      }
    } else {
      setIsAccountPaymentProcessing(false);
      setIsCardPaymentProcessing(false);
    }
  }, [modalLectureApply.visible]);

  useEffect(() => {
    if (
      modalLectureApply.visible &&
      userStateData.isJoinEscape &&
      userStateData.product == 'F' &&
      modalLectureApply.isSelectedDuration == 0
    ) {
      setPaymentKind(1);
    }
  }, [modalLectureApply.visible, userStateData.product, userStateData.isJoinEscape]);

  const onClickClose = useCallback(() => {
    if (modalLectureApply.onClose) {
      modalLectureApply.onClose();
    }

    closeModalLectureApply({ setModalLectureApply }, { enter: 0, exit: 0 });
    resetModalCardInfo({ setModalCardInfo });
  }, [modalLectureApply.onClose]);

  const onClose = useCallback(() => {
    onClickClose();
  }, [onClickClose]);

  //STEP 1(1단계) 비즈니스 로직용
  const onRecommHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRecommCode({ setModalLectureApply }, value);
  };

  const validateRecommcode = async () => {
    if (!userStateData.code && !profilesStateData.recommCode) return;

    const recomm_code = profilesStateData.recommCode ? profilesStateData.recommCode : userStateData.code;

    //! 추천인 코드 유효성 및 헤택 검증
    const url = `/etc/recommcode/check?recommCode=${recomm_code}`;
    const res = await fetchGetApi(url);
    const return_obj = {
      applyRecomm: false,
      recommCode: '',
      applyBenefit: '',
    };
    if (res.result) {
      if (
        userStateData.personal_product == 'A003Y' ||
        userStateData.personal_product == 'F' ||
        profilesStateData.product_type == 'F' ||
        profilesStateData.product_type == 'A003Y'
      ) {
        return_obj.applyRecomm = true;
        return_obj.recommCode = recomm_code;
        return_obj.applyBenefit = res.data.benefit;

        return return_obj;
      }
    } else {
      return return_obj;
    }
  };

  const onClickApplyRecomm = async (recommCode: string, duration: number) => {
    if (!recommCode) return;
    if (duration !== 2) {
      openToastBar({ setToastBar }, '추천코드는 12개월 상품에서만 입력 가능합니다.', 'red', 2000);
      return;
    }
    if (modalLectureApply.isSelectedProduct !== 1) {
      openToastBar({ setToastBar }, '추천코드는 올인원 클래스만 가능합니다.', 'red', 2000);
      return;
    }

    const url = `/etc/recommcode/check?recommCode=${recommCode}`;
    const res = await fetchGetApi(url);
    if (res.result) {
      setModalLectureApply(prevState => {
        return {
          ...prevState,
          applyRecomm: true,
          applyBenefit: res.data.benefit,
        };
      });
      addMonth({ setModalLectureApply });
      handlePricingAndBenefits(
        { setModalLectureApply },
        modalLectureApply.isSelectedProduct,
        modalLectureApply.isSelectedDuration,
      );
    } else {
      openToastBar({ setToastBar }, '유효하지 않은 추천코드입니다.', 'red', 2000);
      setModalLectureApply(prevState => {
        return {
          ...prevState,
          applyRecomm: false,
          applyBenefit: '',
        };
      });
    }
  };

  const onClickToDuration = async (idx: number) => {
    if (idx === modalLectureApply.isSelectedDuration) return;
    if (idx !== 2) {
      setModalLectureApply(prevState => {
        return {
          ...prevState,
          applyRecomm: false,
          applyBenefit: '',
          addMonth: 0,
          minusPrice: 0,
          recommCode: '',
        };
      });
    } else if (
      idx == 2 &&
      modalLectureApply.isSelectedProduct == 1 &&
      (userStateData.code || profilesStateData.recommCode)
    ) {
      // 추천인 코드 + 혜택 처리
      const return_recomm = await validateRecommcode();

      if (return_recomm) {
        const { applyBenefit, applyRecomm, recommCode } = return_recomm;

        setModalLectureApply(prevState => {
          return {
            ...prevState,
            applyRecomm,
            applyBenefit,
            recommCode,
          };
        });
      }
    }

    if (idx == 0) setPaymentKind(1);
    else setPaymentKind(0);

    setIsSelectedDuration({ setModalLectureApply }, idx); // 상태 업데이트
    handlePricingAndBenefits({ setModalLectureApply }, modalLectureApply.isSelectedProduct, idx);
  };
  const onClickToProduct = async (idx: number) => {
    if (idx === modalLectureApply.isSelectedProduct) return;
    if (idx !== 1) {
      //올인원 클래스만 추천코드 적용 가능
      setModalLectureApply(prevState => {
        return {
          ...prevState,
          applyRecomm: false,
          applyBenefit: '',
          addMonth: 0,
          minusPrice: 0,
          recommCode: '',
        };
      });
    } else if (
      idx == 1 &&
      modalLectureApply.isSelectedDuration == 2 &&
      (userStateData.code || profilesStateData.recommCode)
    ) {
      // 추천인 코드 + 혜택 처리
      const return_recomm = await validateRecommcode();

      if (return_recomm) {
        const { applyBenefit, applyRecomm, recommCode } = return_recomm;

        setModalLectureApply(prevState => {
          return {
            ...prevState,
            applyRecomm,
            applyBenefit,
            recommCode,
          };
        });
      }
    }

    setIsSelectedProduct({ setModalLectureApply }, idx);
    handlePricingAndBenefits({ setModalLectureApply }, idx, modalLectureApply.isSelectedDuration);
  };
  const onClickToNextStep = () => {
    setModalLectureApply(prevState => {
      return {
        ...prevState,
        step: prevState.step + 1,
      };
    });
  };

  //STEP 2(2단계) 비즈니스 로직용
  const onClickToBackStep = () => {
    setModalLectureApply(prevState => {
      return {
        ...prevState,
        step: prevState.step - 1,
      };
    });
  };
  const today = new Date().getDate();
  const durations = [1, 6, 12];
  const products = ['핵심문법', '올인원', '기초어휘', '실전회화'];

  const enddate =
    modalLectureApply.isSelectedDuration == 0
      ? dayjs().endOf('month')
      : dayjs()
          .add(
            today > 5
              ? modalLectureApply.applyRecomm || modalLectureApply.recommCode
                ? durations[modalLectureApply.isSelectedDuration] + modalLectureApply.addMonth
                : durations[modalLectureApply.isSelectedDuration]
              : modalLectureApply.applyRecomm || modalLectureApply.recommCode
              ? durations[modalLectureApply.isSelectedDuration] + modalLectureApply.addMonth - 1
              : durations[modalLectureApply.isSelectedDuration] - 1,
            'month',
          )
          .endOf('month');

  const n_paydate = dayjs()
    .add(
      today > 5
        ? modalLectureApply.applyRecomm || modalLectureApply.recommCode
          ? durations[modalLectureApply.isSelectedDuration] +
            (modalLectureApply.isSelectedDuration != 0 ? 1 : 0) +
            modalLectureApply.addMonth
          : durations[modalLectureApply.isSelectedDuration] + (modalLectureApply.isSelectedDuration != 0 ? 1 : 0)
        : modalLectureApply.applyRecomm || modalLectureApply.recommCode
        ? durations[modalLectureApply.isSelectedDuration] + modalLectureApply.addMonth
        : durations[modalLectureApply.isSelectedDuration],
      'month',
    )
    .startOf('month')
    .format('YYYY. MM. DD');

  const onClickCardInfo = () => {
    if ((profile_customer_id || customer_id) && !joinComp) {
      openModalCardInfo({ setModalCardInfo }, modalLectureApply.isSelectedDuration);
    }
  };

  const resetRecoilState = () => {
    resetUserState({ setUserStateData });
    resetLearningState();
    resetBookState();
    resetPocketNoteState();
    resetModalMissionState();
    resetHiddenMissionState();
    resetBGM();
  };

  const onLogout = () => {
    if (userStateData.type != PRESIDENT_OF_CENTER_TYPE && !TEMP_STUDENTS_ACCOUNT_IDS.includes(userStateData.id)) {
      setUserInFireStoreToNull(userStateData.id);
    }
    resetRecoilState();
    window.sessionStorage.removeItem('user');
    window.sessionStorage.removeItem('authToken');
    window.sessionStorage.removeItem('refreshToken');
    setTimeout(() => {
      navigate(`${BASE_URL}/login`, { state: paymentKind == 0 ? 'oa_pay' : 'card_pay' });
    }, 0);
  };

  const postPaymentInfo = async () => {
    //* 개인 고객 결제 요청 함수
    if (joinComp) return;
    if (paymentKind == 0 || (paymentKind == 1 && isRegister)) {
      const payment_kind = paymentKind == 0 ? 'OA' : 'CD'; // OA: 전용계좌 / CD: 키인결제
      const { isSelectedDuration, isSelectedProduct, cost } = modalLectureApply;
      const real_cost = isSelectedDuration == 0 ? calculateProPayment(cost) : cost;
      const cid = customer_id != 0 ? customer_id : profile_customer_id;

      if (paymentKind == 0) {
        const postPaymentInfo = {
          paymentKind: payment_kind,
          product: PERSONAL_PRODUCT_CODES[isSelectedProduct][isSelectedDuration],
          price: real_cost,
          endDate: enddate.format('YYYY-MM-DD 23:59:59'),
          isJoin: isJoinEscape,
        };

        const api_path = isJoinEscape ? '/joinproduct' : '/addproduct';

        try {
          onClickClose();
          openLoadingCircleSpinner({ setLoadingCircleSpinner }, 8, '오래영어 첫 클래스 수강을 준비중입니다!', {
            enter: 0,
            exit: 0,
          });
          const res = await fetchPostApi(`/customers/${cid}/payments${api_path}`, postPaymentInfo);
          if (res.result) {
            if (!res.errorcode) {
              closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
              onLogout();
            }
          } else {
            if (res.errorcode || res.message) {
              openToastBar({ setToastBar }, res.message, 'red', 2000);
              console.error(res.message);
              closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
            }
          }
        } catch (error) {
          console.log('전용계좌 결제 에러');
          console.error(error);
          closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
        }
      } else {
        // 카드결제
        let selectedYear = year.toString();
        selectedYear = selectedYear.length === 4 ? selectedYear.substring(2) : selectedYear;
        const postCardPaymentInfo = {
          paymentKind: isSelectedDuration == 0 ? 'AC' : 'CD',
          product: PERSONAL_PRODUCT_CODES[isSelectedProduct][isSelectedDuration],
          price: real_cost,
          cardno: cardNumber,
          year: selectedYear,
          month: cardMonth,
          payerNumber: birthDate,
          installmentCount: installmentMonths,
          endDate: enddate.format('YYYY-MM-DD 23:59:59'),
          isJoin: isJoinEscape,
        };
        console.log('카드결제', postCardPaymentInfo);

        const api_path = isJoinEscape ? '/joinproduct' : '/addproduct';

        try {
          onClickClose();
          openLoadingCircleSpinner({ setLoadingCircleSpinner }, 8, '오래영어 첫 클래스 수강을 준비중입니다!', {
            enter: 0,
            exit: 0,
          });

          const res = await fetchPostApi(`/customers/${cid}/payments${api_path}`, postCardPaymentInfo);
          if (res.result) {
            if (!res.errorcode) {
              closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
              //! 로그아웃 처리 > 기존 오래영어 관리자의 카드결제 참고
              onLogout();
            }
          } else {
            if (res.errorcode || res.message) {
              openToastBar({ setToastBar }, res.message, 'red', 2000);
              console.error(res.message);
              closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
            }
          }
        } catch (error) {
          console.log('카드 결제 에러');
          console.error(error);
          closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
        }
      }
    }
  };

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform == 'android') {
      setTop('-91vh');
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform == 'android') {
      setTop('0px');
    }
  };

  const props_obj = {
    step: modalLectureApply.step,
    visible: modalLectureApply.visible,
    title: modalLectureApply.title,
    lectureClass: modalLectureApply.lectureClass,
    recommCode: modalLectureApply.recommCode,
    applyBenefit: modalLectureApply.applyBenefit,
    minusPrice: modalLectureApply.minusPrice,
    month: modalLectureApply.month,
    benefitPrice: modalLectureApply.benefitPrice,
    full: modalLectureApply.full,
    earlybird: modalLectureApply.earlybird,
    pkg: modalLectureApply.pkg,
    scholarshipDisCountPrice: modalLectureApply.scholarshipDisCountPrice,
    onClose,
    isSelectedDuration: modalLectureApply.isSelectedDuration,
    onClickToDuration,
    isSelectedProduct: modalLectureApply.isSelectedProduct,
    applyRecomm: modalLectureApply.applyRecomm,
    cost: modalLectureApply.cost,
    onClickToProduct,
    onRecommHandler,
    onClickApplyRecomm,
    addMonth: modalLectureApply.addMonth,
    onClickToNextStep,
    onClickToBackStep,
    enddate,
    n_paydate,
    paymentKind,
    setPaymentKind,
    joinComp,
    setJoinComp,
    onClickCardInfo,
    modalCardInfo,
    timeout: modalLectureApply.timeout,
    isJoinEscape: userStateData.isJoinEscape && userStateData.product == 'F',
    postPaymentInfo,
    onFocus,
    onBlur,
    top,
    isAccountPaymentProcessing,
    isCardPaymentProcessing,
  };

  return <ModalLectureApplyPresenters {...props_obj} />;
});
