import { Link, useNavigate, useRoutes } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from '../../../recoil/model/user';
import { routesState } from '../../../recoil/common/routes';
import { useEffect, useState, useContext } from 'react';
import { ModalContext } from '../../../provider/ModalProvider';

import { CgNotes } from 'react-icons/cg';
import { FaChartPie } from 'react-icons/fa';
import { IoExitOutline } from 'react-icons/io5';
import { TbCheckbox } from 'react-icons/tb';

import logo2 from '../../../assets/images/longvoca_logo_white.png';
import exit_icon from '../../../assets/images/exit_icon_2.png';

import { BASE_URL } from '../../../utils/constants';

import { styled, Box } from '@mui/material';
import theme from '../../../theme';
import { d_flex, d_flex_start, dir_column, d_flex_center } from '../../../styles/common';

import Button from '../../button/Button';
import { EffectSoundContext } from '../../../provider/EffectSoundProvider';

interface ReportSideProps {
  tab: number;
  mode: string;
  onClickTab: (idx: number) => void;
  nextStepStudy: (() => void) | undefined;
  modLength?: number;
  closeResult?: (() => void) | undefined;
}

interface ListMenuType {
  [key: string]: { icon?: any }[];
}

const StyledSideMenuWrap = styled(Box)(props => ({
  padding: '1rem 0 1rem 1rem',
  width: '4.25rem',
  height: '100%',
  fontSize: '1.1rem',
  userSelect: 'none',
  float: 'left',
  display: 'flex',
  justifyContent: 'flex-start',
  alignContent: 'flex-start',
  flexWrap: 'wrap',
}));

interface StyledSideMenuItemProps {
  is_active?: string;
  idx?: string;
}

const style_side_menu = {
  width: '100%',
  height: '4.5rem',
  position: 'relative' as const,
  alignItems: 'center',
  ...d_flex,
  svg: {
    width: '2rem',
    height: '2rem',
    marginLeft: '0.75rem',
    zIndex: '1',
  },
};

const StyledIconWrap = styled(Box)(props => ({
  width: '4.25rem',
  height: '4.5rem',
}));

const StyledIconBox = styled(Box)(props => ({
  width: '4.25rem',
  height: '4.5rem',
  paddingTop: '0.45rem',
  paddingLeft: '0.06rem',
  backgroundColor: props.theme.palette.purple_3.main,
  borderTopLeftRadius: '1rem',
  borderTopRightRadius: '1rem',
  ...d_flex_center,
  ...dir_column,
}));

const StyledIconButton = styled(Button)(props => ({
  display: 'block',
  width: '2.5rem',
  height: '2.5rem',
  backgroundImage: `url(${logo2})`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  transform: 'scale(0.8)',
}));

const LinkButton = styled(Button)<StyledSideMenuItemProps>(props => {
  const before = {
    content: '""',
    display: 'block',
    width: '22px',
    height: '22px',
    position: 'absolute',
    borderBottomRightRadius: '12px',
    zIndex: '1',
    top: '-10px',
    right: '-2px',
    boxShadow: '1px 11.4px 0 0 #fff',
    overflow: 'hidden',
    transform: 'translateY(-50%)',
  };

  const after = {
    content: '""',
    display: 'block',
    width: '22px',
    height: '22px',
    position: 'absolute',
    borderTopRightRadius: '12px',
    zIndex: '1',
    bottom: '-10px',
    right: '-2px',
    boxShadow: '6.4px 0 0 0 #fff',
    overflow: 'hidden',
    transform: 'translateY(50%)',
  };

  return {
    minWidth: 'unset',
    zIndex: '0',
    postion: 'relative',
    color: props.is_active == 'true' ? props.theme.palette.purple_3.main : props.theme.palette.white.main,
    letterSpacing: '-1px',
    justifyContent: 'flex-start',
    borderRadius: 'unset !important',
    borderTopRightRadius: '12px !important',
    borderBottomRightRadius: '12px !important',
    transition: 'unset',
    padding: 0,
    borderRight: `1px solid ${props.theme.palette.purple_3.main}`,
    '&:active': {
      boxShadow: 'none',
    },

    '&.active': {
      color: props.theme.palette.purple_3.main,
      backgroundColor: '#fff',
      borderRight: '1px solid #fff !important',
      borderLeft: `1px solid ${props.theme.palette.purple_3.main}`,
      borderTopLeftRadius: '12px !important',
      borderBottomLeftRadius: '12px !important',
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
      boxShadow: '1px 0 0 0 #fff',

      '&:before': {
        ...before,
      },

      '&:after': {
        ...after,
      },

      svg: {
        color: props.theme.palette.purple_3.main,
      },
    },
    ...style_side_menu,
  };
});

const ExitLinkButton = styled(Button)<StyledSideMenuItemProps>(props => ({
  minWidth: 'unset',
  zIndex: '0',
  postion: 'relative',
  color: props.theme.palette.white.main,
  letterSpacing: '-1px',
  justifyContent: 'flex-start',
  borderRadius: 'unset !important',
  transition: 'unset',
  padding: 0,
  borderRight: `1px solid ${props.theme.palette.purple_3.main}`,
  width: '100%',
  height: '5rem',
  position: 'relative' as const,
  alignItems: 'center',
  ...d_flex,

  ' .exit-icon': {
    path: {
      strokeWidth: '2.3rem !important',
    },
  },

  svg: {
    width: '2.85rem',
    height: '2.85rem',
    marginLeft: '0.5rem',
  },
}));

function ReportSide(props: ReportSideProps) {
  const tab = props.tab;
  const mode = props.mode;
  const onClickTab = props.onClickTab;
  const modLength = props.modLength;
  const navigate = useNavigate();
  const [routesStateData, setRoutesStateData] = useRecoilState<RoutesType>(routesState);
  const { root_path, child_path } = routesStateData;
  const [currentMenu, setCurrentMenu] = useState<ListMenuType>();
  const { modal_confirm } = useContext(ModalContext);
  const nextStepStudy = props.nextStepStudy;
  const { playEffectSound } = useContext(EffectSoundContext);

  const onClickExit = () => {
    playEffectSound('click');
    navigate(child_path == 'result' ? `${BASE_URL}/${root_path}` : `${BASE_URL}/${root_path}/${child_path}`);

    props.closeResult && child_path == 'chart' && props.closeResult();

    // if (mode == 'basic') {
    //   if (nextStepStudy) {
    //     modal_confirm.openModalConfirm(
    //       'report',
    //       'confirm_advanced',
    //       () => {
    //         nextStepStudy();
    //       },
    //       () => {
    //         navigate(child_path == 'result' ? `${BASE_URL}/${root_path}` : `${BASE_URL}/${root_path}/${child_path}`);
    //       },
    //     );
    //   } else {
    //     modal_confirm.openModalConfirm('normal', 'exit_report', () => {
    //       navigate(child_path == 'result' ? `${BASE_URL}/${root_path}` : `${BASE_URL}/${root_path}/${child_path}`);
    //     });
    //   }
    // } else if (mode == 'advanced') {
    //   if (nextStepStudy) {
    //     modal_confirm.openModalConfirm(
    //       'report',
    //       'confirm_wrong',
    //       () => {
    //         nextStepStudy();
    //       },
    //       () => {
    //         navigate(child_path == 'result' ? `${BASE_URL}/${root_path}` : `${BASE_URL}/${root_path}/${child_path}`);
    //       },
    //     );
    //   } else {
    //     modal_confirm.openModalConfirm('normal', 'exit_report', () => {
    //       navigate(child_path == 'result' ? `${BASE_URL}/${root_path}` : `${BASE_URL}/${root_path}/${child_path}`);
    //     });
    //   }
    // } else if (mode == 'wrong') {
    //   modal_confirm.openModalConfirm('normal', 'exit_report', () => {
    //     navigate(child_path == 'result' ? `${BASE_URL}/${root_path}` : `${BASE_URL}/${root_path}/${child_path}`);
    //   });
    // }
  };

  // 메뉴 아이템
  const listmenu_items: ListMenuType = {
    '': [{ icon: <FaChartPie /> }],
    basic: [{ icon: <FaChartPie /> }, { icon: <CgNotes /> }, { icon: <TbCheckbox /> }],
    advanced: [{ icon: <FaChartPie /> }, { icon: <CgNotes /> }, { icon: <TbCheckbox /> }],
    wrong: [{ icon: <FaChartPie /> }],
    speak: [{ icon: <FaChartPie /> }, { icon: <CgNotes /> }, { icon: <TbCheckbox /> }],
    grammar_t:
      mode == 'grammar_t' && modLength == 1
        ? [{ icon: <FaChartPie /> }]
        : [{ icon: <FaChartPie /> }, { icon: <CgNotes /> }, { icon: <TbCheckbox /> }],
    grammar_b: [{ icon: <FaChartPie /> }, { icon: <CgNotes /> }, { icon: <TbCheckbox /> }],
    grammar_d: [{ icon: <FaChartPie /> }, { icon: <CgNotes /> }, { icon: <TbCheckbox /> }],
  };

  useEffect(() => {
    setCurrentMenu(listmenu_items);
  }, []);

  return (
    <StyledSideMenuWrap>
      <StyledIconWrap>
        <StyledIconBox className='icon_box'>
          <StyledIconButton onClick={onClickExit} />
        </StyledIconBox>
      </StyledIconWrap>
      <Box
        sx={{
          width: '4.25rem',
          height: 'calc(100% - 10.5rem)',
          backgroundColor: theme.palette.purple_3.main,
          paddingTop: '2vw',
        }}
      >
        <Box
          sx={{ width: '3.9rem', marginLeft: '0.35rem', display: 'grid', gap: '1vh' }}
          className='customize side-menu'
        >
          {currentMenu &&
            currentMenu[mode]?.map((menu, idx) => {
              return (
                <LinkButton
                  className={tab == idx ? 'active' : ''}
                  is_active={`${tab == idx}`}
                  onClick={() => onClickTab(idx)}
                  key={`side_menu_${idx}`}
                  variant='contained'
                  color={tab == idx ? 'white' : 'purple_3'}
                  disableRipple
                  idx={`${idx}`}
                >
                  {menu.icon}
                </LinkButton>
              );
            })}
          {currentMenu ? (
            <LinkButton
              key='last_link_button'
              variant='contained'
              disableRipple
              idx={`${currentMenu[mode].length}`}
              color='purple_3'
              sx={{ cursor: 'inherit' }}
            ></LinkButton>
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{
          width: '4.25rem',
          height: '6rem',
          borderBottomLeftRadius: '1rem',
          borderBottomRightRadius: '1rem',
          backgroundColor: theme.palette.purple_3.main,
          zIndex: '1',
        }}
        className='exit_box'
      >
        <ExitLinkButton
          disableRipple
          color='purple_3'
          sx={{ ...d_flex_center, width: '4.25rem', cursor: 'default' }}
          // onClick={onClickExit}
        >
          {/* <StyledIconButton
            onClick={onClickExit}
            sx={{ backgroundImage: `url(${exit_icon})`, transform: 'scale(1.15)' }}
          /> */}
        </ExitLinkButton>
      </Box>
    </StyledSideMenuWrap>
  );
}

export default ReportSide;
