import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userState } from '../../../recoil/model/user';
import { routesState } from '../../../recoil/common/routes';
import { ModalContext } from '../../../provider/ModalProvider';
import { BASE_URL } from '../../../utils/constants';

import default_profile from '../../../assets/images/default_img.png';

import { styled, Box } from '@mui/material';
import { d_flex_center, d_flex_start, d_flex_space_between, d_flex_end } from '../../../styles/common';
import Button from '../../button/Button';
import InputNormal from '../../input/InputNormal';
import DropdownList from '../../dropdown/DropdownList';

import { IoSearch } from 'react-icons/io5';
import { EffectSoundContext } from '../../../provider/EffectSoundProvider';
import exit_icon from '../../../assets/images/exit_icon_black.png';

import { confettiState, setActiveConfetti } from 'recoil/common/confetti';
import { modalLectureApplyState, openModalLectureApply } from 'recoil/common/modalLectureApply';
import { separateProduct } from 'utils/tools';
import { fetchGetApi } from 'utils/api';

interface ReportHeaderProps {
  mode: string;
  searchWord: string;
  onSearch: (spell: string) => void;
  thisStepReStudy: (() => void) | undefined;
  nextStepStudy: (() => void) | undefined;
  wrongStudy: (() => void) | undefined;
  setWord: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  closeResult?: () => void;
}

interface DropdownContentType {
  id: number;
  label: string;
}

const StyledHeaderWrapper = styled(Box)(() => ({
  height: '6.5rem',
  padding: '1rem 1rem 1rem 2rem',
  width: 'calc(100% - 4.25rem)',
  userSelect: 'none',
  display: 'flex',
}));

const StyledAnchorWrap = styled(Box)(() => ({
  width: '100%',
  height: '4.5rem',
}));

const StyledAnchorWBox = styled(Box)(props => ({
  width: '100%',
  height: '4.5rem',
  backgroundColor: props.theme.palette.light_yellow_2.main,
  borderRadius: '1rem',
  ...d_flex_space_between,
}));

const InputBox = styled(Box)(props => ({
  ...d_flex_start,
  marginLeft: '1rem',
  minWidth: '20vw',
  position: 'relative',
  '& > svg': {
    position: 'absolute',
    top: '50%',
    left: '1rem',
    transform: 'translateY(-50%)',
    color: '#ccc',
    zIndex: '1',
  },
  '& input': {
    color: props.theme.palette.black.main,
    backgroundColor: props.theme.palette.white.main,
  },
}));

const StyledInputNormal = styled(InputNormal)(() => ({
  '& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorGray_1': {
    height: '2.5rem',
    fontSize: '1rem',
    marginBottom: 0,
    borderColor: 'var(--white) !important',
  },
}));

const InfoBox = styled(Box)(() => ({
  ...d_flex_end,
  height: '4rem',
  marginRight: '1rem',
}));

const ProfileBox = styled(Box)(props => ({
  width: '4rem',
  height: '4rem',
  background: props.theme.palette.white.main,
  border: '1px solid #f7e9d4',
  borderRadius: '50%',
  ...d_flex_center,
  marginRight: '1rem',
  position: 'relative',
  overflow: 'hidden',
  '.default_icon': {
    width: '70%',
    height: '70%',
    color: props.theme.palette.gray_3.main,
  },
  '.edit_icon': {
    cursor: 'pointer',
    position: 'absolute',
    bottom: '0rem',
    right: '0rem',
    width: '2.25rem',
    height: '2.25rem',
    color: props.theme.palette.white.main,
    backgroundColor: props.theme.palette.gray_2.main,
    strokeWidth: 0.5,
    zIndex: 1,
  },

  '.user_profile': {
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
  },
}));

const StyledIconButton = styled(Button)(() => ({
  display: 'block',
  width: '3rem',
  height: '3rem',
  lineHeight: '1.75',
  minWidth: '0',
  backgroundImage: `url(${exit_icon})`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  padding: '0',
}));

const Line = styled(Box)({
  height: '2.5rem',
  width: '1px',
  marginLeft: '1rem',
  marginRight: '0.35rem',
  backgroundColor: '#f0e4c9',
});

function ReportHeader(props: ReportHeaderProps) {
  const mode = props.mode;
  const thisStepReStudy = props.thisStepReStudy;
  const nextStepStudy = props.nextStepStudy;
  const wrongStudy = props.wrongStudy;
  const searchWord = props.searchWord;
  const setWord = props.setWord;
  const onKeyDown = props.onKeyDown;
  const closeResult = props.closeResult;
  const setConfetti = useSetRecoilState(confettiState);
  const setModalLectureApply = useSetRecoilState(modalLectureApplyState);
  const navigate = useNavigate();
  const routesStateData = useRecoilValue<RoutesType>(routesState);
  const { root_path, child_path } = routesStateData;
  const userStateData = useRecoilValue<UserType>(userState);
  const { center_type, product, scholarshipStudyCnt, scholarshipBenefit } = userStateData;
  const { modal_confirm, modal_alert, modal_study_confirm } = useContext(ModalContext);
  const { playEffectSound } = useContext(EffectSoundContext);

  const [profileSrc, setProfileSrc] = useState('');

  const [dropdownContent, setDropdownContent] = useState<DropdownContentType[]>([{ id: 2, label: '나가기' }]);

  useEffect(() => {
    const dropdown_content = [];
    if (userStateData.id < 6) {
      dropdown_content.push({ id: 0, label: '나가기' });
    } else if (child_path == 'chart') {
      dropdown_content.push({ id: 0, label: '학습 설정' });
    } else if (mode == 'basic') {
      if (thisStepReStudy) dropdown_content.push({ id: 0, label: '재학습' });
      if (nextStepStudy) dropdown_content.push({ id: 1, label: '심화학습' });
    } else if (mode == 'advanced') {
      if (thisStepReStudy) dropdown_content.push({ id: 0, label: '재학습' });
      if (nextStepStudy) dropdown_content.push({ id: 1, label: '오답학습' });
    } else if (mode == 'wrong') {
      if (thisStepReStudy) dropdown_content.push({ id: 0, label: '재학습' });
    } else if (mode == 'grammar_t') {
      if (thisStepReStudy) dropdown_content.push({ id: 0, label: '재학습' });
      if (nextStepStudy) dropdown_content.push({ id: 1, label: '기본학습' });
    } else if (mode == 'grammar_b') {
      if (thisStepReStudy) dropdown_content.push({ id: 0, label: '재학습' });
      if (nextStepStudy) dropdown_content.push({ id: 1, label: '심화학습' });
    } else if (mode == 'grammar_d') {
      if (thisStepReStudy) dropdown_content.push({ id: 0, label: '재학습' });
    } else if (mode == 'speak') {
      if (thisStepReStudy) dropdown_content.push({ id: 0, label: '재학습' });
    }

    setDropdownContent([...dropdown_content]);
  }, [mode, thisStepReStudy, nextStepStudy, wrongStudy]);

  useEffect(() => {
    if (userStateData.profile) {
      setProfileSrc(`data:image/png;base64,${userStateData.profile}`);
    } else {
      setProfileSrc('');
    }
  }, [userStateData]);

  const onClickItem = (id: number, type?: string, index?: number) => {
    movePage(id);
  };

  const onClickExit = () => {
    playEffectSound('click');
    child_path == 'chart' && closeResult && closeResult();
    navigate(child_path == 'result' ? `${BASE_URL}/${root_path}` : `${BASE_URL}/${root_path}/${child_path}`);
  };

  const onClickRestudy = () => {
    if (
      (mode == 'speak' && ['V003Y', 'V003DY', 'V003TY', 'G003Y', 'G003DY', 'G003TY'].includes(userStateData.product)) ||
      (['basic', 'advanced'].includes(mode) &&
        ['S003Y', 'S003DY', 'S003TY', 'G003Y', 'G003DY', 'G003TY'].includes(userStateData.product)) ||
      (['grammar_t', 'grammar_d', 'grammar_b'].includes(mode) &&
        ['S003Y', 'S003DY', 'S003TY', 'V003Y', 'V003DY', 'V003TY'].includes(userStateData.product))
    ) {
      // !toast 추후 확인 예정
      modal_alert.openModalAlert(
        userStateData.type == 11 ? 'personal_product_student_alert' : 'personal_product_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        true,
      );
      return false;
    }
    if (thisStepReStudy)
      modal_confirm.openModalConfirm('normal', 'start_restudy', () => {
        thisStepReStudy();
      });
  };

  const validateRecommcode = async () => {
    if (!userStateData.code) return;

    //! 추천인 코드 유효성 및 헤택 검증
    const url = `/etc/recommcode/check?recommCode=${userStateData.code}`;
    const res = await fetchGetApi(url);
    const return_obj = {
      applyRecomm: false,
      recommCode: '',
      applyBenefit: '',
    };
    if (res.result) {
      if (userStateData.personal_product == 'A003Y' || userStateData.personal_product == 'F') {
        return_obj.applyRecomm = true;
        return_obj.recommCode = userStateData.code;
        return_obj.applyBenefit = res.data.benefit;

        return return_obj;
      }
    } else {
      return return_obj;
    }
  };

  const onClickApplicationBtn = async () => {
    setActiveConfetti(setConfetti, false);
    let options = undefined;
    if (userStateData.code && userStateData.product == 'F') {
      const return_recomm = await validateRecommcode();
      if (return_recomm) {
        const { applyBenefit, applyRecomm, recommCode } = return_recomm;
        options = {
          step: 1,
          isSelectedDuration: 2,
          isSelectedProduct: 1,
          applyBenefit,
          applyRecomm,
          recommCode,
        };
      }
    }

    openModalLectureApply(
      { setModalLectureApply },
      '오래영어 클래스 수강신청',
      scholarshipBenefit,
      { enter: 0, exit: 0 },
      options,
    );
  };

  const onClickNextStep = () => {
    if (
      (mode == 'speak' && ['V003Y', 'V003DY', 'V003TY', 'G003Y', 'G003DY', 'G003TY'].includes(userStateData.product)) ||
      (['basic', 'advanced'].includes(mode) &&
        ['S003Y', 'S003DY', 'S003TY', 'G003Y', 'G003DY', 'G003TY'].includes(userStateData.product)) ||
      (['grammar_t', 'grammar_d', 'grammar_b'].includes(mode) &&
        ['S003Y', 'S003DY', 'S003TY', 'V003Y', 'V003DY', 'V003TY'].includes(userStateData.product))
    ) {
      // !toast 추후 확인 예정
      modal_alert.openModalAlert(
        userStateData.type == 11 ? 'personal_product_student_alert' : 'personal_product_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        true,
      );
      return false;
    }
    if (nextStepStudy) {
      let target = 'start_wrong';

      switch (mode) {
        case 'basic':
          target = 'start_advanced';
          break;
        case 'advanced':
          target = 'start_wrong';
          break;
        case 'grammar_t':
          target = 'start_basic';
          break;
        case 'grammar_b':
          target = 'start_advanced';
          break;
      }

      //* 프리미엄 횟수 소진시 다음 문법 학습 못하도록 세팅
      if (
        center_type == 'C' &&
        product == 'F' &&
        scholarshipStudyCnt == 0 &&
        ['start_basic', 'start_advanced'].includes(target)
      ) {
        modal_study_confirm.openModalStudyConfirm(
          'free_premiere_speak_prohibit',
          'free_premiere_speak_prohibit',
          () => onClickApplicationBtn(),
          () => {
            setActiveConfetti(setConfetti, false);
          },
          `대신 <span style="color:#4620e9; font-weight:600;">우수 장학생</span>에 선발되셨어요.\r\n얼리버드 패키지 할인 등 지금 신청해보세요!`,
        );
        return;
      }

      modal_confirm.openModalConfirm('normal', target, () => {
        nextStepStudy();
      });
    }
  };

  const onClickSettings = () => {
    modal_confirm.openModalConfirm(
      'normal',
      'move_page',
      () => {
        navigate(`${BASE_URL}/manager/settings`);
      },
      undefined,
      '학습 설정으로',
    );
  };

  const movePage = (id: number) => {
    if (child_path == 'chart') {
      onClickSettings();
      return;
    }
    if (mode == 'basic') {
      switch (id) {
        case 0:
          onClickRestudy();
          break;
        case 1:
          onClickNextStep();
          break;
        case 2:
          onClickExit();
          break;
      }
    } else if (mode == 'advanced') {
      switch (id) {
        case 0:
          onClickRestudy();
          break;
        case 1:
          onClickNextStep();
          break;
        case 2:
          onClickExit();
          break;
      }
    } else if (mode == 'wrong') {
      switch (id) {
        case 0:
          onClickRestudy();
          break;
        case 1:
          onClickExit();
          break;
      }
    } else if (mode == 'grammar_t') {
      switch (id) {
        case 0:
          onClickRestudy();
          break;
        case 1:
          onClickNextStep();
          break;
        case 2:
          onClickExit();
          break;
      }
    } else if (mode == 'grammar_b') {
      switch (id) {
        case 0:
          onClickRestudy();
          break;
        case 1:
          onClickNextStep();
          break;
        case 2:
          onClickExit();
          break;
      }
    } else if (mode == 'grammar_d') {
      switch (id) {
        case 0:
          onClickRestudy();
          break;
        case 1:
          onClickNextStep();
          break;
        case 2:
          onClickExit();
          break;
      }
    } else if (mode == 'speak') {
      switch (id) {
        case 0:
          onClickRestudy();
          break;
      }
    }
  };

  return (
    <StyledHeaderWrapper>
      <StyledAnchorWrap>
        <StyledAnchorWBox>
          <InputBox>
            {mode == 'wrong' ? (
              <StyledInputNormal placeholder='오답학습 Report' readOnly />
            ) : (
              <>
                {searchWord ? null : <IoSearch />}
                <StyledInputNormal
                  value={searchWord}
                  onChange={setWord}
                  onKeyDown={onKeyDown}
                  placeholder='&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;키워드 검색'
                />
              </>
            )}
          </InputBox>
          <InfoBox>
            <ProfileBox>
              {userStateData.profile_type == 'default' ? (
                userStateData.default_profile == '0' ? (
                  <Box component='img' src={default_profile} alt='default_profile' className='default_icon' />
                ) : (
                  <Box sx={{ backgroundImage: `url(${profileSrc})` }} className='user_profile' />
                )
              ) : (
                <Box sx={{ backgroundImage: `url(${profileSrc})` }} className='user_profile' />
              )}
            </ProfileBox>
            <DropdownList contents={dropdownContent} title={userStateData.name} onClickItem={onClickItem} />
            <Line />
            <StyledIconButton onClick={onClickExit} disableFocusRipple disableRipple disableTouchRipple />
          </InfoBox>
        </StyledAnchorWBox>
      </StyledAnchorWrap>
    </StyledHeaderWrapper>
  );
}

export default ReportHeader;
