import { styled, Box } from '@mui/material';
import ButtonContainer from 'components/button/Button';

export const ModalBackground = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '30',
});

export const ModalContainer = styled(Box)(props => ({
  backgroundColor: props.theme.palette.white.main,
  padding: '4rem 1.5rem 2rem',
  position: 'relative',
  width: '30rem',
  borderRadius: '3rem',
  overflow: 'auto',
  maxHeight: '100vh',
}));

export const ModalCloseButton = styled('button')({
  position: 'absolute',
  top: '1.5rem',
  right: '1.7rem',
  background: 'none',
  border: 'none',
  fontSize: '1.2rem',
  cursor: 'pointer',
});

export const ContentWrapper = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  padding: '0 1rem',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '1rem',
  position: 'relative',
}));

export const RegisterCardButton = styled(ButtonContainer)(props => ({
  backgroundColor: props.theme.palette.purple.main,
  '&:hover': {
    backgroundColor: props.theme.palette.purple.main,
  },
  width: '100%',
  height: '3rem',
  borderRadius: '0.5rem',
}));

export const FormWrapper = styled(Box)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '100%',
  '& .check-box-corporation': {
    fontSize: '0.95rem',
    marginLeft: '4.7rem',
    marginTop: '-0.8rem',
    marginBottom: '-0.8rem',
  },
}));

export const FormWrap = styled(Box)(props => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: '1rem',

  '& > span': {
    display: 'inline-block',
  },
}));

export const InputTextStyle = styled('input')(props => ({
  width: '100%',
  border: '1px solid #c4c4c4',
  height: '2.5rem',
  padding: '0.8rem',
  outline: 'none',
  fontSize: '1rem',
  '&::placeholder': {
    color: '#cdcfdc',
  },
}));

export const BirthDateInputWrap = styled(Box)(props => ({
  display: 'flex',
  alignItems: 'baseline',
  width: '100%',
  gap: '1rem',

  '& > div:first-of-type': {
    width: '39%',
    marginLeft: '-0.5rem',
  },

  '& > span': {
    display: 'inline-block',
  },
}));

export const CheckBoxWrapper = styled(Box)(props => ({
  marginLeft: '-1.5rem',
  fontSize: '0.95rem',
  '& .check-box': {
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    '& .read-notice': {
      color: props.theme.palette.blue_2.main,
      cursor: 'pointer',
    },
    '& > span': {
      marginRight: '-0.5rem',
    },
  },
}));

export const LoadingFilter = styled(Box)(props => ({
  width: '100%',
  height: props.className === 'month-product' ? '20rem' : '25rem',
  background: 'white',
  zIndex: '10',
  top: '0',
  filter: 'opacity(0.8)',
  position: 'absolute',
}));
