import React, { memo, Key, useContext } from 'react';

import { useSelect, useClassNames, UseSelectProps, ClassName } from './utils';
import { Box } from '@mui/material';
import { ModalContext } from 'provider/ModalProvider';

const makeDiscountLabel = (label: string) => {
  let discount_label = '';
  switch (label) {
    case '10명~15명':
      discount_label = '8% 할인';
      break;
    case '15명~30명':
      discount_label = '14% 할인';
      break;
    case '30명~50명':
      discount_label = '20% 할인';
      break;
    case '50명~100명':
      discount_label = '30% 할인';
      break;
    case '100명~300명':
      discount_label = '50% 할인';
      break;
    case '300명 이상':
      discount_label = '50% 할인';
      break;
  }

  return discount_label;
};

export function MultipleLevelSelectionComponent<TItem = string>({
  classes,
  getItemKey,
  getItemLabel,
  hasNestedItems,
  ...rest
}: MultipleLevelSelectionProps<TItem>) {
  const { modal_confirm } = useContext(ModalContext);
  const {
    open,
    type,
    setType,
    label,
    renderingItems,
    toggle,
    handleClickItem,
    isSelectedItem,
    isPlaceholder,
    disabled,
  } = useSelect({
    ...rest,
    getItemLabel,
    hasNestedItems,
  });

  const classesNames = useClassNames(classes);

  const handleToggle = () => {
    if (disabled) return;

    if (type == 'modify' || type == 'modify-pin-code') {
      modal_confirm.openModalConfirm(
        'normal_reverse',
        'change_grade',
        () => {
          return;
        },
        () => {
          setType('add');
          toggle();
        },
      );
    } else {
      toggle();
    }
  };

  return (
    <div
      className={classesNames.root()}
      style={disabled ? { backgroundColor: 'rgb(239,239,239)', color: '#a4a4a4' } : {}}
    >
      <div className={classesNames.overlay(open)} onClick={handleToggle} />
      <div
        className={classesNames.selectionHeader()}
        onClick={handleToggle}
        style={disabled ? { cursor: 'default' } : {}}
      >
        <span
          style={{
            fontSize: '0.9rem',
            color: isPlaceholder ? '#e5e5e5' : '#a4a4a4',
          }}
        >
          {label}
        </span>
      </div>
      {renderingItems && (
        <div className={classesNames.selectionEntries(open)}>
          <div className='flex flex-row'>
            {Object.keys(renderingItems).map((level: string) => (
              <>
                <ul key={`entry-level-${level}`} className={classesNames.levelEntry()}>
                  {renderingItems[+level].map(item => {
                    const selected = isSelectedItem(item, +level);
                    const nestable = hasNestedItems(item, +level);
                    let level_1_checked = false;

                    if (+level == 1) {
                      level_1_checked = renderingItems[+level].map(item => isSelectedItem(item, +level)).includes(true);
                    }

                    return (
                      <li
                        key={getItemKey(item)}
                        className={classesNames.levelItem({
                          nestable,
                          selected,
                        })}
                        title={getItemLabel(item)}
                        onClick={handleClickItem(item, +level)}
                        style={{
                          color: +level == 1 && level_1_checked && selected == false ? '#eaebec' : '#aaa',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {getItemLabel(item)}
                        <span
                          style={{
                            fontSize: '0.75rem',
                            backgroundColor: '#ff5f40',
                            color: '#fff',
                            padding: '0 0.35rem',
                            marginLeft: '0.25rem',
                            borderRadius: '0.35rem',
                          }}
                          className='discount-label'
                        >
                          {makeDiscountLabel(getItemLabel(item))}
                        </span>
                      </li>
                    );
                  })}
                </ul>
                {level == '1' ? (
                  <Box
                    sx={{
                      height: '12.625rem',
                      width: '1px',
                      background: '#eaecef',
                      marginLeft: '1rem',
                      marginRight: '0.5rem',
                      '@media (max-width: 960px) and (min-height: 405px)': {
                        height: '11rem',
                      },
                      '@media (max-width: 960px) and (max-height: 404px)': {
                        height: '11rem',
                      },
                      '@media(max-width: 768px)': {
                        height: '11rem',
                      },
                    }}
                  ></Box>
                ) : null}
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

const MultipleLevelSelection = memo(MultipleLevelSelectionComponent) as typeof MultipleLevelSelectionComponent &
  React.ComponentType<any>;
MultipleLevelSelection.displayName = 'MultipleLevelSelection';

export default MultipleLevelSelection;

export interface MultipleLevelSelectionProps<TItem> extends UseSelectProps<TItem> {
  getItemKey: (item: TItem) => Key; // React key generator based on item
  classes?: Partial<Record<ClassName, string>>; // Custom classe names
}
