import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WordWorksheetUI } from './WordWorksheet.Presenter';
import { useRecoilValue } from 'recoil';
import { deviceState } from 'recoil/common/device';
import { userState } from 'recoil/model/user';

interface WordWorksheetContainerProps {
  duplicate: boolean;
  title: string;
  duplicateLoading: boolean;
  printSetting: PrintSettingType;
  printInfo: PrintInfoType;
  printBookWordList: PrintBookListType;
  printRef: React.MutableRefObject<any>;
  endLoading: () => void;
  printMethod?: string;
}

export const WordWorksheetContainer = React.memo(function WordWorksheetContainer({
  title,
  printSetting,
  printInfo,
  printBookWordList,
  printRef,
  duplicate,
  duplicateLoading,
  endLoading,
  printMethod,
}: WordWorksheetContainerProps) {
  const userStateData = useRecoilValue<UserType>(userState);
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { screen_width, screen_height } = deviceStateData;
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [worksheetKey, setWorksheetKey] = useState(0);
  const totalPageRef = useRef(0);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef<any>(null);
  const [printWordList, setPrintWordList] = useState<PrintBookListType>({});
  const [firstWord, setFirstWord] = useState('');

  const before_print_setting_sort = useRef(printSetting.sort);
  const before_print_setting_lang = useRef(printSetting.lang);
  const before_print_word_list = useRef<PrintBookListType>({});
  const before_print_word_list_deduplication = useRef<PrintBookListType>({});
  const duplicate_ref = useRef(duplicate);

  useEffect(() => {
    setScale();
  }, [screen_width, screen_height, isFullscreen]);

  useEffect(() => {
    setTitle();
  }, [title]);

  useEffect(() => {
    const current_sheet = $('.worksheet').eq(currentPage);
    current_sheet.removeClass('hidden');
    if (currentPage < maxPage)
      $('.worksheet')
        .eq(currentPage + 1)
        .addClass('hidden');
    if (currentPage > 0)
      $('.worksheet')
        .eq(currentPage - 1)
        .addClass('hidden');
  }, [currentPage]);

  useEffect(() => {
    before_print_setting_sort.current = 0;
    before_print_setting_lang.current = 0;
  }, [printBookWordList]);

  useEffect(() => {
    duplicate_ref.current = duplicate;
  }, [duplicate]);

  useEffect(() => {
    if (Object.keys(printBookWordList).length > 0) {
      const sortedPrintWordList = JSON.parse(JSON.stringify(printBookWordList)) as PrintBookListType;
      if (before_print_setting_sort.current != printSetting.sort) {
        before_print_setting_sort.current = printSetting.sort;
        if (printSetting.sort != 0) {
          for (const bookKey in sortedPrintWordList) {
            const book = sortedPrintWordList[bookKey];
            for (const unitKey in book.units) {
              const unit = book.units[unitKey];
              let with_lang_unit: PrintUnitType = JSON.parse(JSON.stringify(unit));

              if (duplicate_ref.current) {
                if (before_print_word_list_deduplication.current[bookKey]) {
                  with_lang_unit = JSON.parse(
                    JSON.stringify(before_print_word_list_deduplication.current[bookKey].units[unitKey]),
                  );
                } else {
                  with_lang_unit = JSON.parse(JSON.stringify(before_print_word_list.current[bookKey].units[unitKey]));
                }
              } else {
                if (before_print_word_list_deduplication.current[bookKey]) {
                  with_lang_unit = JSON.parse(
                    JSON.stringify(before_print_word_list_deduplication.current[bookKey].units[unitKey]),
                  );
                  before_print_word_list_deduplication.current = {};
                } else {
                  with_lang_unit = JSON.parse(JSON.stringify(before_print_word_list.current[bookKey].units[unitKey]));
                }
              }

              let wordsCopy = unit.words.slice();

              if (printSetting.sort == 1) {
                const originalWordsStr = JSON.stringify(unit.words);
                let limit = 0;
                do {
                  wordsCopy.sort(() => Math.random() - 0.5);
                  limit++;
                } while (originalWordsStr === JSON.stringify(wordsCopy) && limit < 10);
              } else if (printSetting.sort == 2) {
                wordsCopy.sort((a, b) => a.spell.localeCompare(b.spell));
              }

              wordsCopy = wordsCopy.map(word => {
                const words: PrintWordType[] = JSON.parse(JSON.stringify(with_lang_unit.words));
                const word_id = word.id;
                const word_with_lang_index = words.findIndex(w => w.id === word_id);

                const lang = words[word_with_lang_index].lang;

                if (!duplicate_ref.current) {
                  words.splice(word_with_lang_index, 1);
                }

                words.splice(word_with_lang_index, 1);
                return { ...word, lang: lang };
              });

              before_print_setting_lang.current = printSetting.lang;
              unit.words = wordsCopy;
            }
          }
        } else {
          for (const bookKey in sortedPrintWordList) {
            const book = sortedPrintWordList[bookKey];
            for (const unitKey in book.units) {
              const unit = book.units[unitKey];
              let with_lang_unit: PrintUnitType = JSON.parse(JSON.stringify(unit));

              if (duplicate_ref.current) {
                if (before_print_word_list_deduplication.current[bookKey]) {
                  with_lang_unit = JSON.parse(
                    JSON.stringify(before_print_word_list_deduplication.current[bookKey].units[unitKey]),
                  );
                }
              } else {
                if (before_print_word_list.current[bookKey]) {
                  with_lang_unit = JSON.parse(JSON.stringify(before_print_word_list.current[bookKey].units[unitKey]));
                }
              }

              let wordsCopy = unit.words.slice();

              wordsCopy = wordsCopy.map(word => {
                const words: PrintWordType[] = JSON.parse(JSON.stringify(with_lang_unit.words));
                const word_id = word.id;
                const word_with_lang_index = words.findIndex(w => w.id === word_id);
                const lang = words[word_with_lang_index].lang;

                if (!duplicate_ref.current) {
                  words.splice(word_with_lang_index, 1);
                }

                return { ...word, lang: lang };
              });

              before_print_setting_lang.current = printSetting.lang;
              unit.words = wordsCopy;
            }
          }
        }
      } else {
        if (printSetting.lang != before_print_setting_lang.current) {
          before_print_setting_lang.current = printSetting.lang;
          // lang 변경 시점에만 처리 로직
          for (const bookKey in sortedPrintWordList) {
            const book = sortedPrintWordList[bookKey];
            for (const unitKey in book.units) {
              const unit = book.units[unitKey];
              let with_lang_unit: PrintUnitType = JSON.parse(JSON.stringify(unit));

              if (duplicate_ref.current) {
                if (before_print_word_list_deduplication.current[bookKey]) {
                  with_lang_unit = JSON.parse(
                    JSON.stringify(before_print_word_list_deduplication.current[bookKey].units[unitKey]),
                  );
                }
              } else {
                if (before_print_word_list.current[bookKey]) {
                  with_lang_unit = JSON.parse(JSON.stringify(before_print_word_list.current[bookKey].units[unitKey]));
                }
              }

              let wordsCopy = with_lang_unit.words.slice();
              if (printSetting.lang === 3) {
                //  랜덤으로 섞어주는 로직 작성
                const random_number = Math.floor(Math.random() * 10) + 1;
                const words_count = with_lang_unit.words.length;
                let more_lang = '';
                if (words_count % 2 != 0) {
                  if (random_number <= 5) {
                    more_lang = 'eng';
                  } else {
                    more_lang = 'kor';
                  }
                }
                const eng_count = more_lang
                  ? more_lang === 'eng'
                    ? Math.ceil(words_count / 2)
                    : Math.floor(words_count / 2)
                  : words_count / 2;
                const kor_count = words_count - eng_count;
                let cur_eng_count = 0;
                let cur_kor_count = 0;
                let dup_type_count = 0;
                const limit = 3;
                let before_lang_type = '';
                wordsCopy = wordsCopy.map(word => {
                  const random_number = Math.floor(Math.random() * 10) + 1;

                  //  (50%) 해당 단어 영어 또는 한글 출력유형 설정
                  let lang_type = '';
                  if (random_number <= 5) {
                    lang_type = 'eng';
                  } else {
                    lang_type = 'kor';
                  }

                  //  이전 단어와 출력유형 같으면 중복 카운트 증가, 아니면 이전 단어 출력 유형 변경
                  if (before_lang_type == lang_type) {
                    dup_type_count++;
                  } else {
                    before_lang_type = lang_type;
                  }

                  // 중복 카운트 limit(3)이면 중복카운트 초기화 하고 출력 유형(이전것까지) 반대로 변경
                  if (dup_type_count == limit) {
                    dup_type_count = 0;
                    if (before_lang_type == 'eng') {
                      lang_type = 'kor';
                      before_lang_type = 'kor';
                    } else {
                      lang_type = 'eng';
                      before_lang_type = 'eng';
                    }
                  }

                  // 현재 영어 또는 한글 출력 유형이 max_count 채웠으면 반대로 안 채워진 유형으로 무조건 변경되게 처리
                  if (cur_eng_count == eng_count) {
                    lang_type = 'kor';
                    before_lang_type = 'kor';
                  } else if (cur_kor_count == kor_count) {
                    lang_type = 'eng';
                    before_lang_type = 'eng';
                  }

                  // 출력하는 유형 카운트 증가 로직
                  if (lang_type == 'eng') {
                    cur_eng_count++;
                  } else {
                    cur_kor_count++;
                  }

                  return {
                    ...word,
                    lang: lang_type == 'eng' ? 1 : 2,
                  };
                });
                unit.words = wordsCopy;
              } else {
                wordsCopy = wordsCopy.map(word => ({ ...word, lang: printSetting.lang }));
                unit.words = wordsCopy;
              }
            }
          }
        }
      }

      setPrintWordList(sortedPrintWordList);
      setWorksheetKey(prev => prev + 1);
    }
  }, [printBookWordList, printSetting.sort, printSetting.lang]);

  // useEffect(() => {
  //   if (!duplicate_ref.current) {
  //     before_print_word_list_deduplication.current = {};
  //   }
  // }, [duplicate]);

  useEffect(() => {
    if (duplicate_ref.current) {
      before_print_word_list_deduplication.current = JSON.parse(JSON.stringify(printWordList));
    } else if (!duplicate_ref.current) {
      before_print_word_list.current = JSON.parse(JSON.stringify(printWordList));
    }
  }, [printWordList]);

  useEffect(() => {
    setWorksheetKey(prev => prev + 1);
  }, [printSetting.column, printSetting.fontsize, printSetting.lang, printSetting.line, printSetting.width]);

  useEffect(() => {
    if (Object.keys(printWordList).length > 0) {
      setProgress(0);
      setLoading(true);
      setCurrentPage(-1);
      setTimeout(() => {
        init();
      }, 1);
    }
  }, [worksheetKey]);

  useEffect(() => {
    if (printInfo.logo) {
      $('.worksheet-logo').removeClass('invisible');
    } else {
      $('.worksheet-logo').addClass('invisible');
    }
    if (printInfo.pagenum) {
      $('.worksheet-pagenum').removeClass('invisible');
    } else {
      $('.worksheet-pagenum').addClass('invisible');
    }
    if (printInfo.cname) {
      $('.worksheet-cname').removeClass('invisible');
    } else {
      $('.worksheet-cname').addClass('invisible');
    }
  }, [printInfo]);

  const buildNewsletter = (tagid: string) => {
    if ($(`.units#${tagid}`).contents().length > 0) {
      const [book_id, unit_id] = tagid.split('_');
      const book_name = printBookWordList[tagid]?.name || '';
      const unit_name = printBookWordList[tagid]?.units[unit_id]?.name || '';

      const worksheet = $('.worksheet-template')
        .clone()
        .removeClass('worksheet-template')
        .addClass('worksheet pagebreak')
        .addClass(`page-${totalPageRef.current}`);

      $('.print-wrap').append(worksheet);
      totalPageRef.current++;

      $('.worksheet:last .worksheet-header .worksheet-bookname').text(book_name);
      $('.worksheet:last .worksheet-header .worksheet-unitname').text(unit_name);
      $('.worksheet:last .worksheet-footer .worksheet-pagenum').text('- ' + totalPageRef.current + ' -');

      $('#' + tagid).columnize({
        l_rate: 50,
        r_rate: 50,
        buildOnce: true,
        columns: printSetting.column + 1,
        target: '.worksheet:last .worksheet-contents',
        overflow: {
          height: 860,
          id: '#' + tagid,
          doneFunc: function () {
            $('.worksheet:last').addClass('hidden');
          },
        },
      });
    }
  };

  const init = useCallback(async () => {
    console.log('init');
    const prev_contents = $('.worksheet');
    if (prev_contents.length > 0) {
      prev_contents.remove();
    }
    const contents = $('.units');
    totalPageRef.current = 0;

    for (let i = 0; i < contents.length; i++) {
      await onChangeProgress(Math.round(((i + 1) / contents.length) * 100));
      let limit = 0;
      const tagid = $('.units')[i].id;
      while ($(`.units#${tagid}`).contents().length > 0 && $('.worksheet') && limit < 1000) {
        buildNewsletter(tagid);
        limit++;
      }
    }

    setMaxPage(totalPageRef.current - 1);
    $('.column').addClass('h-100');
    setTimeout(() => {
      setLoading(false);
      endLoading();
      if (firstWord) {
        const classNames = document.getElementById(firstWord)?.parentElement?.parentElement?.parentElement?.className;
        const classList = classNames && classNames.includes(' ') ? classNames.split(' ') : false;

        let page = 0;
        if (classList) {
          for (const className of classList) {
            if (className.includes('page-')) {
              page = Number(className.replace('page-', ''));
            }
          }
        }

        setCurrentPage(page);
      } else {
        setCurrentPage(0);
      }
      setTimeout(renderEnd, 10);
    }, 100);
  }, [printBookWordList, printSetting]);

  const renderEnd = () => {
    const worksheet = $('.worksheet:not(.hidden)');

    if (worksheet.length > 0) {
      setScale();
    } else {
      setTimeout(renderEnd, 10);
    }
  };

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  const onChangeProgress = async (value: number) => {
    await delay(50);
    setProgress(value);
  };

  const onClickPageMove = useCallback(
    (direction: 'prev' | 'next') => {
      if (direction == 'prev') {
        setCurrentPage(prev => prev - 1);
        const first_word = document
          .getElementsByClassName(`page-${currentPage - 1}`)[0]
          .getElementsByClassName('dontsplit')[0].id;
        setFirstWord(first_word);
      } else if (direction == 'next') {
        setCurrentPage(prev => prev + 1);
        const first_word = document
          .getElementsByClassName(`page-${currentPage + 1}`)[0]
          .getElementsByClassName('dontsplit')[0].id;
        setFirstWord(first_word);
      }
    },
    [currentPage],
  );

  const onClickFullscreen = useCallback(() => {
    setIsFullscreen(prev => !prev);
  }, [isFullscreen]);

  const setScale = useCallback(() => {
    const element = document.getElementById('worksheet-container');

    if (element) {
      setScrollWidth(element.offsetWidth - element.clientWidth);
      setScrollHeight(element.offsetHeight - element.clientHeight);
    } else {
      setScrollWidth(0);
      setScrollHeight(0);
    }

    if (isFullscreen) {
      containerRef.current.style.transform = '';
    } else {
      const x = containerRef.current.clientWidth / printRef.current.clientWidth;
      const y = containerRef.current.clientHeight / printRef.current.clientHeight;
      if (x > y) containerRef.current.style.transform = `scale(${y})`;
      else containerRef.current.style.transform = `scale(${x})`;
    }
  }, [screen_width, screen_height, isFullscreen]);

  const setTitle = useCallback(() => {
    const title_element = $('.title > div');
    if (title) {
      title_element.css('color', 'inherit');
      title_element.text(title);
    } else {
      title_element.css('color', 'rgb(172, 125, 255)');
      title_element.text('제목을 입력하세요.');
    }
  }, [title]);

  const props = {
    title,
    worksheetKey,
    currentPage,
    maxPage,
    loading,
    progress,
    scrollWidth,
    scrollHeight,
    isFullscreen,
    printSetting,
    printWordList,
    userStateData,
    printRef,
    containerRef,
    duplicate,
    duplicateLoading,
    printMethod,
    onClickPageMove,
    onClickFullscreen,
    endLoading,
  };

  return <WordWorksheetUI {...props} />;
});
