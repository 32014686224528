// * 교재 출력 메뉴 아이콘
export const DocumentIcon = () => {
  return (
    <svg viewBox='0 0 27 30'>
      <polygon
        // className={className}
        fill='#ffffff'
        stroke='#ffffff'
        strokeWidth='3'
        strokeMiterlimit='10'
        points='24.5,30 3,30 3,2 20,2 24.5,8'
        rx='4'
        ry='4'
      />
      <rect x='6.2' y='11' width='15' height='2' rx='1' ry='1' fill='#6A52F2' />
      <rect x='6.2' y='15' width='15' height='2' rx='1' ry='1' fill='#6A52F2' />
      <rect x='6.2' y='19' width='15' height='2' rx='1' ry='1' fill='#6A52F2' />
      <rect x='6.2' y='23' width='15' height='2' rx='1' ry='1' fill='#6A52F2' />
    </svg>
  );
};

export const ActiveDocumentIcon = () => {
  return (
    <svg viewBox='0 0 27 30'>
      <polygon
        fill='#6A52F2'
        stroke='#6A52F2'
        strokeWidth='3'
        strokeMiterlimit='10'
        points='24.5,30 3,30 3,2 20,2 24.5,8'
        rx='4'
        ry='4'
      />
      <rect x='6.2' y='11' width='15' height='2' rx='1' ry='1' fill='#ffffff' />
      <rect x='6.2' y='15' width='15' height='2' rx='1' ry='1' fill='#ffffff' />
      <rect x='6.2' y='19' width='15' height='2' rx='1' ry='1' fill='#ffffff' />
      <rect x='6.2' y='23' width='15' height='2' rx='1' ry='1' fill='#ffffff' />
    </svg>
  );
};

// * 성적 분석 메뉴 아이콘
export const BarChartIcon = () => (
  <svg viewBox='0 0 12 16'>
    <rect x='1' y='10' width='3.5' height='5.5' rx='0.3' fill='currentColor' />
    <rect x='5' y='7' width='3.5' height='8.5' rx='0.3' fill='currentColor' />
    <rect x='9' y='4' width='3.5' height='11.5' rx='0.3' fill='currentColor' />
  </svg>
);
