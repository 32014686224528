import { styled, Menu as MuiMenu, MenuProps, Button } from '@mui/material';

export const Menu = styled((props: MenuProps) => (
  <MuiMenu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme, style, className }) => ({
  '& .MuiPaper-root': {
    borderRadius: 2,
    marginTop: theme.spacing(0),
    minWidth:
      className === 'study-setting'
        ? '11.745rem'
        : className === 'radio'
        ? '22.125rem'
        : className === 'contained'
        ? 'calc((((((100vw - 18.571rem) * 0.5) - 3px ) - 2rem ) * 0.35 - 1.25rem) / 2)'
        : className?.includes('study-target-modal')
        ? '17.4rem'
        : '18.4rem',
    color: 'var(--black)',
    fontSize: '1rem',
    border: '1px solid var(--gray-2)',
    borderTop: className?.includes('need-border') ? '1px solid var(--gray-2)' : 'none',
    maxHeight: '15rem',
    overflowX: 'auto',
    ...style,
    '& .MuiMenu-list': {
      width: '100%',
      padding: '4px 0',
      paddingTop: '0rem',
      paddingBottom: '0rem',
    },
    '& .MuiMenuItem-root': {
      height: '2.5rem',
      padding: '0 1rem',
      overflow: 'hidden',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      div: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },

    '@media (min-width: 1266px) and (max-width: 1905px)': {
      minWidth: '27rem',
      '& .MuiMenuItem-root': {
        height: '3rem',
      },
    },

    '@media (min-width: 962px) and (max-width: 1265px)': {
      minWidth: '23.25rem',
      '& .MuiMenuItem-root': {
        height: '3rem',
      },
    },

    '@media (min-width: 770px) and (max-width: 961px)': {
      minWidth: '22.75rem',
      maxHeight: '12rem',
      '& .MuiMenuItem-root': {
        height: '2.75rem',
      },
    },
    '@media (max-width: 768px)': {
      minWidth: '18rem',
      maxHeight: '9rem',
      '& .MuiMenuItem-root': {
        height: '2.5rem',
      },
    },
  },
}));

export const DropdownButton = styled(Button)<{ uitype?: 'circle' }>(({ variant, uitype }) => {
  let style_obj = {};
  if (uitype === 'circle') {
    style_obj = {
      backgroundColor: '#f2f3f5',
      color: '#9d9e9f',
      fontSize: '0.9rem',
      border: 'none',
      borderRadius: '24px',
      '&:hover': {
        backgroundColor: '#f2f3f5',
        color: '#9d9e9f',
        border: 'none',
      },
    };
  }
  return {
    minWidth: '9rem',
    height: '100%',
    backgroundColor: variant === 'contained' ? '' : 'var(--white)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 1rem',
    borderRadius: 4,
    fontSize: '1rem',
    textTransform: 'none',

    '&:hover': {
      backgroundColor: variant === 'contained' ? '' : 'var(--white)',
    },
    svg: {
      width: '0.875rem',
      height: '0.875rem',
      // marginTop: '-2px',
      position: 'absolute',
      right: '0.5rem',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    ...style_obj,
  };
});

export const DetailDeco = styled('div')(() => ({
  width: '0.875rem',
  height: '0.875rem',
  borderBottom: '1px dotted var(--gray-1)',
  borderLeft: '1px dotted var(--gray-1)',
  position: 'absolute',
  top: 'calc(22px - 0.5rem)',
  left: '1rem',
  transform: 'translateY(-50%)',
}));
