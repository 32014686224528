import { Box, Button, ButtonProps, Input, styled } from '@mui/material';
import ButtonGroupDate from 'components/button/ButtonGroupDate';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FEE_DATE_GROUP } from 'utils/constants';
import dayjs, { Dayjs } from 'dayjs';
import InputNormal from 'components/input/InputNormal';
import Table from 'components/table/Table';
import { payment_fee_fields } from 'utils/table_fields';
import { makeUri } from 'utils/tools';
import { setLocaleDateString } from 'utils/tools';
import { userState } from 'recoil/model/user';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { fetchGetApi } from 'utils/api';
import { ModalContext } from 'provider/ModalProvider';
import { Content, PAYMENT_FEE_CARD_CONTENTS, Title } from 'components/card/InformationCard';
import InfoIcon from 'components/card/InfoIcon';
import theme from '../../theme';
import { LoadingContainer } from 'components/loading/Loading/Loading.Container';
import { CardContainer } from 'components/card/Card.Container';
import { RiArrowRightSLine } from 'react-icons/ri';
import { isPaymentChangeModalOpenState } from 'recoil/common/modalPaymentChange';
import { PaymentKindInfoType } from './Setup';
import { ModalPaymentMethodChangeContainer } from 'components/modal/ModalPaymentMethodChange/ModalPaymentMethodChange.Container';

const SX = {
  width: '10rem',
};
const INPUT_SX = {
  '& input': {
    textAlign: 'center !important',
  },
  '&.Mui-disabled.MuiInputBase-colorGray_1': {
    '& fieldset': {
      borderColor: 'var(--gray-2) !important',
    },
  },
};

export const CardAutoPaymentNoticeWrap = styled(Box)(props => ({
  position: 'relative',
  '& .payment-change-button': {
    position: 'absolute',
    bottom: '0',
    right: '0.6rem',
    width: 'max-content',
    padding: '0.6rem 1rem 0.6rem 1.6rem',
    fontSize: '0.95rem',
    backgroundColor: props.theme.palette.red_2.main,
    '&:hover': {
      backgroundColor: props.theme.palette.red_2.main,
    },
  },
}));

const StyledBox = styled(Button)<ButtonProps>(props => ({
  boxShadow: 'none',
  fontSize: '1rem',
  lineHeight: 1.5,

  '&.regi': {
    minWidth: '8rem',
  },
  '&:hover': {
    boxShadow: 'none',
  },
  '&.disabled': {
    '&.MuiButton-contained': {
      backgroundColor: 'rgb(232, 232, 232) ',
      color: props.theme.palette.white.main,
    },

    '&.MuiButton-outlined': {
      borderColor: '#e8e8e8 ',
    },
  },
  '&.Mui-disabled': {
    '&.MuiButton-contained': {
      backgroundColor: 'rgb(232, 232, 232) ',
      color: props.theme.palette.white.main,
    },

    '&.MuiButton-outlined': {
      borderColor: '#e8e8e8 ',
    },
  },
}));

export default function PaymentInfo({
  paymentKindInfo,
  isPaymentKindEmpty,
  isCardPay,
  getPaymentKind,
}: {
  paymentKindInfo: PaymentKindInfoType | undefined;
  isPaymentKindEmpty: boolean;
  isCardPay: boolean | undefined;
  getPaymentKind: () => void;
}) {
  const [buttonGroupType, setButtonGroupType] = useState(FEE_DATE_GROUP);
  const { modal_alert } = useContext(ModalContext);
  const [selectedIndex, setSelectedIndex] = useState(2);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const setIsPaymentChangeModalOpen = useSetRecoilState(isPaymentChangeModalOpenState);
  const [dates, setDates] = useState({
    startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  });
  const [userStateData] = useRecoilState(userState);
  const { customer_id } = userStateData;

  const [tableItems, setTableItems] = useState<TableItemsType[]>([]);
  const [tableQuery, setTableQuery] = useState<TableQueryType>({
    target: '',
    sort: 'asc',
    page: 1,
    max: 10,
  });

  const tableFields = useMemo(() => {
    return [...payment_fee_fields];
  }, []);

  useEffect(() => {
    let startDate: Dayjs | string = dayjs(dayjs().startOf('year'));
    let endDate: Dayjs | string = dayjs();

    if (selectedIndex === 1) {
      startDate = dayjs(startDate.add(-1, 'year'));
      endDate = startDate.endOf('year');
    } else if (selectedIndex === 0) {
      startDate = dayjs(startDate.add(-2, 'year'));
      endDate = startDate.endOf('year');
    }

    startDate = startDate.format('YYYY-MM-DD');
    endDate = endDate.format('YYYY-MM-DD');

    setDates({ startDate, endDate });
  }, [selectedIndex]);

  const getPaymentsUri = useMemo(() => {
    const start = dayjs(dates.startDate).get('year');
    const uri = makeUri(`/customers/${customer_id}/payments/year/${start}`, {
      ...tableQuery,
    });

    return uri;
  }, [tableQuery, dates.startDate]);

  useEffect(() => {
    setIsLoading(true);
    getPayments();
  }, [dates.startDate, tableQuery]);

  const onClickButtonGroupDate = (index: number) => {
    setSelectedIndex(index);
  };

  const getPayments = async () => {
    const res = await fetchGetApi(getPaymentsUri);
    if (res.result) {
      let cnt = res.count;
      setCount(cnt);
      const table_items = res.data.map((item: any) => {
        cnt--;
        const no = cnt + tableQuery.max * tableQuery.page - tableQuery.max;

        let paymentKind = null;
        if (item.paymentkind == 'CD' || item.paymentkind == 'AC') {
          paymentKind = '카드결제';
        } else if (item.paymentkind == 'OA') {
          paymentKind = '전용계좌';
        } else if (item.paymentkind == 'VA') {
          paymentKind = '가상계좌';
        }

        return {
          ...item,
          no,
          paymentKind,
          year: item.year + '년',
          month: item.month + '월',
          overUseCount: item.overusedcnt ? (res.allCnt == item.paycnt ? '-' : item.overusedcnt + '명') : '0명',
          cost: item.cost.toLocaleString('ko-KR') + '원',
          payment: item.payment
            ? { type: 'color #f44336', label: `${item.payment.toLocaleString('ko-KR')}원` }
            : { type: 'color #000', label: '미납' },
          payDay: item.postdate ? setLocaleDateString(new Date(item.postdate), '.') : '',
          evidenceExpenditure:
            item.mgtkey && item.mgtkey.includes('popbill')
              ? {
                  type: 'element',
                  element: (
                    <StyledBox
                      color='purple'
                      variant='contained'
                      onClick={() => {
                        window.open(item.mgtkey, '_blank');
                      }}
                    >
                      조회
                    </StyledBox>
                  ),
                }
              : ['CD', 'AC'].includes(item.paymentkind)
              ? {
                  type: 'element',
                  // !toast 추후 확인 예정
                  element: (
                    <StyledBox
                      color='purple'
                      variant='contained'
                      onClick={() => modal_alert.openModalAlert('payment_kind_card', undefined, undefined)}
                      style={{ cursor: 'pointer' }}
                    >
                      조회
                    </StyledBox>
                  ),
                }
              : { type: 'color #9E9E9E', label: '발행 전' },
        };
      });

      setTableItems(table_items);
      setIsLoading(false);
    }
  };

  const handlePaymentChangeButtonClick = () => {
    setIsPaymentChangeModalOpen(true);
  };

  const CARD_AUTO_PAYMENT_CONTENTS = useMemo(() => {
    if (!paymentKindInfo) return;
    return {
      id: 'payment_card_auto',
      title: {
        text: (
          <Title>
            <InfoIcon />
            {isCardPay ? '카드 자동결제 안내' : '가상계좌 납부 안내'}
          </Title>
        ),
      },
      paragraph: [
        {
          text: (
            <Content>
              •{' '}
              <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                {isCardPay
                  ? `카드사 : ${paymentKindInfo.paymentCompany}카드`
                  : `계좌정보 : ${paymentKindInfo?.accountBank} ${paymentKindInfo?.accountNumber}`}
              </Box>
            </Content>
          ),
        },
        {
          text: (
            <Content>
              •{' '}
              <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                {isCardPay
                  ? `카드번호 : ${paymentKindInfo.paymentNumber}`
                  : `예금주명 : ${paymentKindInfo?.accountName}`}
              </Box>
            </Content>
          ),
        },
        {
          text: (
            <Content>
              •{' '}
              {isCardPay ? (
                <>
                  <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                    {`매월 기준일 (1일) `}
                  </Box>
                  오래영어 사용료가 자동 결제됩니다.
                </>
              ) : (
                `원활한 서비스 이용을 위해 결제 부탁드립니다. (~${new Date().getMonth() + 1}/7)`
              )}
            </Content>
          ),
        },
        {
          text: <Content>{`• 납부하신 결제 내역을 조회할 수 있습니다.`}</Content>,
        },
        {
          text: (
            <Content>
              {`• 결제내역은 `}
              <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                최근 청구된 순
              </Box>
              {`으로 표기됩니다.`}
            </Content>
          ),
        },
        {
          text: (
            <Content>
              • 지출증빙 정보를 입력하신 경우,{' '}
              <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                영수 계산서 확인
              </Box>
              이 가능합니다.
            </Content>
          ),
        },
      ],
    };
  }, [isCardPay]);

  return (
    <Box
      sx={{
        height: {
          md: 'calc(100% - 3rem)',
          lg: 'calc(100% - 6rem)',
        },
        overflow: 'auto',
      }}
    >
      {isLoading ? <LoadingContainer /> : null}
      <Box sx={{ justifyContent: 'right', display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
        <InputNormal disable={true} value={dates.startDate} sx={SX} inputSx={INPUT_SX} />
        <span>&nbsp;~&nbsp;</span>
        <InputNormal disable={true} value={dates.endDate} sx={SX} inputSx={INPUT_SX} />
        <ButtonGroupDate
          type={buttonGroupType}
          setButtonGroupType={setButtonGroupType}
          onClickButtonGroupDate={onClickButtonGroupDate}
          sx={{
            width: '15rem',
            height: '2.5rem',
            ml: '0.5rem',
            mr: 0,
            button: { padding: '0', width: '33.3333%', height: 1, minWidth: 0 },
          }}
        />
      </Box>
      <Table
        items={tableItems}
        fields={tableFields}
        number={true}
        pagination={true}
        count={count}
        emptyText='이용료 납부내역이 존재하지 않습니다.'
        dataKey='payment_fee'
        tableQuery={tableQuery}
        onChangeTable={(query: TableQueryType) => setTableQuery(query)}
      />

      <CardContainer sx={{ marginBottom: '1rem' }} directContent={PAYMENT_FEE_CARD_CONTENTS} />
      {isPaymentKindEmpty && (
        <CardContainer
          options={
            <CardAutoPaymentNoticeWrap>
              <StyledBox onClick={handlePaymentChangeButtonClick} variant='contained' className='payment-change-button'>
                {isCardPay ? `결제정보 변경` : `결제수단 변경`}
                <RiArrowRightSLine size={20} />
              </StyledBox>
            </CardAutoPaymentNoticeWrap>
          }
          directContent={CARD_AUTO_PAYMENT_CONTENTS}
        />
      )}
      <ModalPaymentMethodChangeContainer
        updatePaymentItems={getPayments}
        updatePaymentInfo={getPaymentKind}
        paymentInfo={paymentKindInfo}
      />
    </Box>
  );
}
