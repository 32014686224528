import { AccountInfoType } from 'recoil/model/accountInfo';
import {
  CardPayNoticeWrap,
  ContentWrap,
  ContentWrapper,
  DashedLine,
  ModalBackground,
  ModalContainer,
  NoticeContentWrap,
  RegisterCardButton,
  Title,
} from './ModalServicePaymentNotice.Styles';
import theme from 'theme';

export interface ModalServicePaymentNoticeProps {
  onClose: () => void;
  accountInfo: AccountInfoType;
  admin_real_stop: boolean;
}

export const ModalServicePaymentNoticeUI = ({
  onClose,
  accountInfo,
  admin_real_stop,
}: ModalServicePaymentNoticeProps) => {
  const isServiceStopDate = admin_real_stop;
  const isCardNonPay = accountInfo && ['AC', 'CD'].includes(accountInfo.paymentKind);

  return (
    <ModalBackground>
      <ModalContainer>
        <ContentWrapper>
          <ContentWrap>
            <Title>서비스 {isServiceStopDate ? '중단' : '이용료'} 안내</Title>
            <DashedLine />
            {isCardNonPay ? (
              <CardPayNoticeWrap>
                <div>카드 결제에 실패하였습니다.</div>
                <div>
                  등록하신 카드로&nbsp;
                  <span style={{ color: theme.palette.purple.main }}>{accountInfo.limitDate}</span>&nbsp;결제 시도
                  됩니다.
                </div>
                <div style={{ display: 'flex', gap: '1rem', marginTop: '0.6rem' }}>
                  결제금액&nbsp;<span style={{ color: 'red' }}>{accountInfo.cost}원</span>
                </div>
              </CardPayNoticeWrap>
            ) : (
              <NoticeContentWrap>
                <div>
                  <span className='info-title'>계좌번호</span>
                  <span>
                    {accountInfo?.bankname}&nbsp;{accountInfo?.account}
                  </span>
                </div>
                <div>
                  <span className='info-title'>예금주명</span>
                  <span>{accountInfo?.accountnm}</span>
                </div>
                <div>
                  <span className='info-title'>결제금액</span>
                  <span style={{ color: 'red' }}>{accountInfo?.cost}원</span>
                </div>
                <div>
                  <span className='info-title'>입금기한</span>
                  <span>{isServiceStopDate ? '입금 후 즉시 사용 가능합니다.' : accountInfo?.limitDate}</span>
                </div>
              </NoticeContentWrap>
            )}
          </ContentWrap>
          <RegisterCardButton onClick={onClose} variant='contained'>
            확인
          </RegisterCardButton>
        </ContentWrapper>
      </ModalContainer>
    </ModalBackground>
  );
};
