import { LoadingBox, CircularProgressBottom, CircularProgressTop, LoadingLabel } from './Loading.Styles';
import { circularProgressClasses, BoxProps } from '@mui/material';

export interface LoadingVAProps extends BoxProps {
  progress: number;
}

export function LoadingUI({ progress, sx }: LoadingVAProps) {
  return (
    <LoadingBox sx={sx}>
      <CircularProgressBottom variant='determinate' size={'9rem'} thickness={4} value={100} />
      <CircularProgressTop
        variant='indeterminate'
        disableShrink
        sx={{
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={'9rem'}
        thickness={4}
      />
      <LoadingLabel>{progress}%</LoadingLabel>
    </LoadingBox>
  );
}
