import { styled, SxProps, Theme } from '@mui/material/styles';
import { ButtonGroup, Button, ButtonProps } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';

// ButtonGroupDate Style
const StyledButtonGroupDate = styled(ButtonGroup)(props => ({
  width: '100%',
  margin: 'auto',
  display: 'flex',
  'button.MuiButtonGroup-grouped': {
    minWidth: '0px',
  },
}));

// Button Style -> 추가 필요 (margin, border 한쪽 남는것 수정)
const StyledButton = styled(Button)<ButtonProps>(props => ({
  width: `calc(100% / ${props.className} - 2%)`,
  textAlign: 'center',
  border: 'none',
  boxShadow: 'none',
  '&:active': {
    border: 'none',
    boxShadow: 'none',
  },
  '&:hover': {
    border: 'none',
    borderRightColor: '#ced4da',
    boxShadow: 'none',
  },
  fontFamily: "'Apple SD Gothic Neo', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue'",
  // fontWeight: 'bold',
  fontSize: '1rem',
  borderRadius: '0px',
  '&.MuiButton-outlined': {
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    '&:hover': {
      border: '1px solid #ced4da',
    },
  },
  '&.MuiButtonGroup-grouped:not(:last-of-type)': {
    borderRightColor: '#ced4da',
    '&:hover': {
      borderRightColor: '#ced4da',
    },
  },

  '@media(max-width: 1023px)': {
    '&.MuiButton-outlined': {
      borderColor: '#f0f0f0',
    },
    '&.MuiButtonGroup-grouped:not(:last-of-type)': {
      borderColor: '#f0f0f0',
    },
  },
}));

// props로 받을 수 있는 데이터의 형식 정의

interface Type {
  size: {
    pt: string;
    pb: string;
  };
  margin: boolean;
  active: boolean[];
  text: string[];
}
interface ButtonGroupDateProps {
  type: Type;
  setButtonGroupType: Dispatch<SetStateAction<Type>>;
  sx?: SxProps<Theme>;
  onClickButtonGroupDate: (index: number) => void;
}

function CustomButtonGroupDate(props: ButtonGroupDateProps) {
  const onClickButtonGroupDate = props.onClickButtonGroupDate;
  const type = props.type;
  const sx = props.sx;
  const setButtonGroupType = props.setButtonGroupType;

  if (props.type.text.includes('')) {
    // 현재 월 구하는 코드
    const month: number = new Date().getMonth() + 1;
    const new_text: string[] = type.text.map<string>((v, i): string => {
      if (i === 0) return month - 1 + '월';
      else if (i === 1) return month + '월';
      else return month + 1 + '월';
    });
    setButtonGroupType({ ...type, text: new_text });
  }

  const onClickDateButton = (idx: number) => {
    const new_actives = type.active.map((v, i) => {
      if (i === idx) v = true;
      else v = false;
      return v;
    });
    setButtonGroupType({ ...type, active: new_actives });

    if (onClickButtonGroupDate) {
      onClickButtonGroupDate(idx);
    }
  };

  return (
    <StyledButtonGroupDate className='button__group' disableRipple={true} title={type.margin ? 'margin' : ''} sx={sx}>
      {type.text.map((v: string, i: number) => (
        <StyledButton
          key={i}
          variant={type.active[i] ? 'contained' : 'outlined'}
          color={type.active[i] ? 'green' : 'gray_1'}
          sx={type.margin ? { ml: 1, mr: 1, ...type.size } : { ...type.size }}
          className={`${type.text.length}`}
          onClick={() => onClickDateButton(i)}
        >
          {type.text[i]}
        </StyledButton>
      ))}
    </StyledButtonGroupDate>
  );
}

export default CustomButtonGroupDate;
