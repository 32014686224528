import { CardUI } from './Card.Presenter';
import { useRecoilValue } from 'recoil';
import { CardTypes, CardContentTypes, cardState } from 'recoil/common/card';
import { PathTypes, pathState } from 'recoil/model/path';
import { CARD_PATH_VALIDATE } from 'utils/constants';
import { validateUseRecoilPath } from 'utils/tools';
import { SxProps, Theme } from '@mui/material';
import React, { ReactNode, useMemo } from 'react';

export interface CardContainerProps {
  directContent?: CardContentTypes;
  sx?: SxProps<Theme>;
  options?: ReactNode;
}

export const CardContainer = React.memo(function CardContainer({ options, directContent, sx }: CardContainerProps) {
  const cardObj = useRecoilValue<CardTypes>(cardState);
  const path = useRecoilValue<PathTypes>(pathState);

  if (directContent) {
    return <CardUI options={options} contents={directContent} sx={sx} />;
  } else {
    const propsObj = useMemo(() => {
      return validateUseRecoilPath(path, CARD_PATH_VALIDATE, cardObj);
    }, [path.rootPath, path.childPath]);

    if (propsObj) {
      const id = propsObj.id;
      const title = propsObj.title;
      const paragraph = propsObj.paragraph;
      const props_obj = { id, title, paragraph };
      return <CardUI contents={props_obj} sx={sx} />;
    } else {
      return null;
    }
  }
});
