import { Input, ErrorText, edit_triangle } from './InputSelect.Styles';
import { InputTitleContainer } from 'components/common/InputTitle/InputTitle.Container';
import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

export interface InputSelectVAProps {
  value: string;
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
  onChangeValue?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  errorTextStyle?: SxProps<Theme>;
  title?: string;
  required?: boolean;
}

export const InputSelectUI = React.memo(function InputSelectUI({
  title,
  required,
  value,
  onClick,
  onChangeValue,
  placeholder,
  disabled,
  error,
  errorText,
  errorTextStyle,
}: InputSelectVAProps) {
  return (
    <>
      {title ? <InputTitleContainer title={title} required={required} /> : null}
      <Box sx={{ width: 1 }}>
        <Input
          placeholder={placeholder}
          color='gray_1'
          readOnly={true}
          disabled={disabled}
          value={value}
          onClick={onClick}
          onChange={onChangeValue}
          error={error}
          autoComplete={'off'}
        />
        <span className='input_triangle' style={edit_triangle}></span>
        {error && errorText ? <ErrorText sx={errorTextStyle}>{errorText}</ErrorText> : null}
      </Box>
    </>
  );
});
