import React from 'react';
import { styled, Box, Modal as MuiModal } from '@mui/material';
import { keyframes } from '@mui/system';

export const Modal = styled(MuiModal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(55, 55, 55,0.6)',
});

const pulseAnimation = keyframes`
  0%, 100% {
    transform: scale(0);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
`;

interface ContainerProps {
  size?: string;
  color?: string;
  speed?: string;
}

export const Container = styled(Box)<ContainerProps>(({ size = '5.5rem', color = 'white', speed = '0.9s' }) => ({
  '--uib-size': size,
  '--uib-color': color,
  '--uib-speed': speed,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: 'var(--uib-size)',
  width: 'var(--uib-size)',
}));

interface DotProps {
  index: number;
}

const baseColor = { r: 75, g: 90, b: 98 }; // 첫 번째 ball의 RGB 값

const getColor = (index: number) => {
  const brightnessFactor = 0.1; // 밝아지는 비율 (10%)
  const r = Math.min(255, Math.floor(baseColor.r + index * brightnessFactor * 255));
  const g = Math.min(255, Math.floor(baseColor.g + index * brightnessFactor * 255));
  const b = Math.min(255, Math.floor(baseColor.b + index * brightnessFactor * 255));

  return `rgb(${r}, ${g}, ${b})`;
};

export const Dot = styled(Box)<DotProps>(({ index }) => {
  const color = getColor(index); // 색깔 계산

  return {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
    transform: `rotate(${index * 45}deg)`,

    '&::before': {
      content: "''",
      height: '20%',
      width: '20%',
      borderRadius: '50%',
      backgroundColor: color, // 동적으로 색깔 설정
      transform: 'scale(0)',
      opacity: 0.5,
      animation: `${pulseAnimation} calc(var(--uib-speed) * 1.111) ease-in-out infinite`,
      transition: 'background-color 0.3s ease',
      animationDelay: `calc(var(--uib-speed) * ${-0.875 + index * 0.125})`,
    },
  };
});

interface LoadingDotsProps {
  ballCount: number;
}

export const LoadingDots: React.FC<LoadingDotsProps> = ({ ballCount }) => (
  <Container>
    {Array.from({ length: ballCount }).map((_, index) => (
      <Dot key={index} index={index} />
    ))}
  </Container>
);

export default LoadingDots;
