import { IoCloseSharp } from 'react-icons/io5';
import {
  Modal,
  ModalBox,
  ModalHeader,
  Box,
  ModalHeaderIconWrap,
  ModalContentWrapper,
  ModalContentWrap,
  Line,
  Margin,
  ModalStepTwoContentBox,
  ModalStepTwoContentFormTextLeftBox,
  ModalStepTwoContentFormTextRightBox,
  ModalStepTwoContentTextBox,
  ModalStepTwoContentTextBoxTwo,
  ModalStepTwoContentTextLeft,
  ModalStepTwoContentTextRight,
  StyledPaymentKindRadio,
  ColorButton,
  IntendTitle,
  ModalContentScrollBox,
} from './ModalProductIntend.Styles';
import { ModalCardInfoContainer } from '../ModalCardInfo/ModalCardInfo.Container';
import { FaArrowRight } from 'react-icons/fa';

interface ModalProductIntendVAProps {
  visible: boolean;
  onClose: () => void;
  enddate: string;
  diff_duration: string;
  diff_price: number;
  price_sub_text: string;
  currentClass: string;
  buttons: boolean;
  paymentKind: number;
  setPaymentKind: (paymentKind: number) => void;
  onClickCardInfo: () => void;
  isRegister: boolean;
  cardNumber: string;
  isAccountPaymentProcessing: boolean;
  isCardPaymentProcessing: boolean;
  postPaymentInfo: () => void;
}

export function ModalProductIntendUI({
  visible,
  onClose,
  diff_duration,
  diff_price,
  enddate,
  price_sub_text,
  currentClass,
  buttons,
  paymentKind,
  setPaymentKind,
  onClickCardInfo,
  isRegister,
  cardNumber,
  isAccountPaymentProcessing,
  isCardPaymentProcessing,
  postPaymentInfo,
}: ModalProductIntendVAProps) {
  const title = '혹시 올인원 클래스는 어떠세요?';

  return (
    <Modal open={visible}>
      <ModalBox>
        <ModalHeader>
          <Box
            sx={{ fontFamily: 'Apple SD Gothic Neo', fontWeight: '300', fontSize: '1.2rem' }}
            component={'span'}
            dangerouslySetInnerHTML={{ __html: title as string }}
          ></Box>
          <ModalHeaderIconWrap onClick={onClose}>
            <IoCloseSharp />
          </ModalHeaderIconWrap>
        </ModalHeader>
        <ModalContentWrapper>
          <IntendTitle>
            🙏 남은 기간 <span>{'올인원 클래스 (문법, 회화, 어휘, 내신)'}</span> 수강을 추천드려요!
          </IntendTitle>
          <ModalContentScrollBox>
            <ModalContentWrap>
              <ModalStepTwoContentBox>
                <ModalStepTwoContentTextBox>
                  <ModalStepTwoContentTextLeft textAlign='right'>추천 클래스</ModalStepTwoContentTextLeft>
                  <ModalStepTwoContentTextRight color='rgb(85, 0, 255)' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box component={'span'} sx={{ color: '#bdbdbd' }}>{`${currentClass} 클래스 `}</Box>&nbsp;
                    <FaArrowRight color='#000' />
                    &nbsp; 올인원 클래스
                  </ModalStepTwoContentTextRight>
                </ModalStepTwoContentTextBox>
                <ModalStepTwoContentTextBox>
                  <Box
                    sx={{
                      fontSize: '1.25rem',
                      textAlign: 'right',
                      width: '25%',
                      fontWeight: '500',
                      padding: '0.5rem 1rem',
                    }}
                  >
                    잔여 기간
                  </Box>
                  <ModalStepTwoContentTextRight>
                    {diff_duration} &nbsp;&nbsp;
                    <Box
                      component={'span'}
                      sx={{
                        fontSize: '0.8rem',
                        color: '#bdbdbd',
                      }}
                    >
                      {`(현재 클래스 ${enddate} 까지)`}
                    </Box>
                  </ModalStepTwoContentTextRight>
                </ModalStepTwoContentTextBox>
                <ModalStepTwoContentTextBox>
                  <ModalStepTwoContentTextLeft>결제 금액</ModalStepTwoContentTextLeft>
                  <ModalStepTwoContentTextRight color='red' sx={{ display: 'flex', alignItems: 'center' }}>
                    {Math.round(diff_price).toLocaleString()}원&nbsp;
                    <Box component={'span'} sx={{ fontSize: '0.8rem', color: '#bdbdbd' }}>
                      {price_sub_text}
                    </Box>
                  </ModalStepTwoContentTextRight>
                </ModalStepTwoContentTextBox>
              </ModalStepTwoContentBox>
              <Margin height={'1'} />
              <ModalStepTwoContentBox backgroundColor='white'>
                <ModalStepTwoContentTextBoxTwo borderBottom='on'>
                  <ModalStepTwoContentFormTextLeftBox textAlign='right' isPaymentKind={paymentKind === 0}>
                    <Box
                      sx={{
                        cursor: 'pointer',
                        alignItems: 'center',
                        display: 'inline-flex',
                        verticalAlign: 'middle',
                        marginLeft: '-1.388rem',
                      }}
                      onClick={() => {
                        setPaymentKind(0); // 전용 계좌 선택
                      }}
                    >
                      <StyledPaymentKindRadio checked={paymentKind === 0} value={0} />
                      전용 계좌
                    </Box>
                  </ModalStepTwoContentFormTextLeftBox>
                  <ModalStepTwoContentTextRight color='rgb(206, 206, 206)' sx={{ fontWeight: '400' }}>
                    {'결제 버튼을 누르면 전용 계좌가 생성됩니다.'}&nbsp;
                  </ModalStepTwoContentTextRight>
                </ModalStepTwoContentTextBoxTwo>
                <ModalStepTwoContentTextBoxTwo>
                  <ModalStepTwoContentFormTextLeftBox textAlign='right' isPaymentKind={paymentKind === 1}>
                    <Box
                      sx={{
                        cursor: 'pointer',
                        alignItems: 'center',
                        display: 'inline-flex',
                        verticalAlign: 'middle',
                        marginLeft: '-1.388rem',
                      }}
                      onClick={() => {
                        setPaymentKind(1); // 카드 결제 선택
                      }}
                    >
                      <StyledPaymentKindRadio checked={paymentKind === 1} value={1} />
                      카드 결제
                    </Box>
                  </ModalStepTwoContentFormTextLeftBox>
                  <ModalStepTwoContentFormTextRightBox>
                    {paymentKind == 1 ? (
                      <Box
                        sx={{
                          width: '100%',
                          height: '2.5rem',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#bdbdbd',
                          backgroundColor: '#f5f5f5',
                          fontSize: '0.9rem',
                          '@media (max-width: 639px)': {
                            fontSize: '0.875rem',
                          },
                          '@media (max-width: 381px)': {
                            fontSize: '0.8rem',
                          },
                        }}
                        onClick={() => onClickCardInfo()}
                      >
                        {isRegister && cardNumber
                          ? cardNumber.replace(/(\d{4})-(\d{2})\d{2}-(\d{4})-(\d{4})/, '$1-$2**-****-$4')
                          : '+ 카드를 등록해주세요.'}
                      </Box>
                    ) : (
                      <>&nbsp;</>
                    )}
                    &nbsp;
                  </ModalStepTwoContentFormTextRightBox>
                </ModalStepTwoContentTextBoxTwo>
              </ModalStepTwoContentBox>
              <Margin height={'1.25'} />
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                {buttons ? (
                  <>
                    <ColorButton
                      sx={{ width: '14%' }}
                      onClick={postPaymentInfo}
                      paymentKind={paymentKind}
                      isRegister={isRegister}
                      isPayment={isAccountPaymentProcessing || isCardPaymentProcessing}
                    >
                      {isAccountPaymentProcessing ? '발급 중' : isCardPaymentProcessing ? '결제 중' : '클래스 변경'}
                    </ColorButton>
                    <ColorButton
                      sx={{ width: '14%' }}
                      onClick={onClose}
                      paymentKind={paymentKind}
                      isRegister={isRegister}
                      isExitButton={true}
                    >
                      그대로 유지
                    </ColorButton>
                  </>
                ) : (
                  <ColorButton
                    sx={{ width: '14%' }}
                    onClick={postPaymentInfo}
                    paymentKind={paymentKind}
                    isRegister={isRegister}
                    isPayment={isAccountPaymentProcessing || isCardPaymentProcessing}
                  >
                    {isAccountPaymentProcessing ? '발급 중' : isCardPaymentProcessing ? '결제 중' : '결제'}
                  </ColorButton>
                )}
              </Box>
              <Margin height={'1.25'} />
              <Line height='0.4rem'>&nbsp;</Line>
              <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '0.85rem' }}>
                <Box sx={{ fontWeight: '600', fontSize: '0.9rem', marginBottom: '0.35rem' }}>
                  오래영어 이용권 구매 주의사항
                </Box>
                <Box
                  sx={{
                    paddingtop: '0.2rem',
                    fontWeight: '300',
                    fontSize: '0.9rem',
                    color: '#a7a6a4',
                    whiteSpace: 'nowrap',
                    marginLeft: '-0.15rem',
                    lineHeight: '1.35rem',
                  }}
                >
                  &nbsp;&nbsp;.&nbsp;&nbsp;모든 이용권은 부가세(VAT) 포함 가격입니다.
                  <br />
                  &nbsp;&nbsp;.&nbsp;&nbsp;상품 가격은 내부 정책으로 인해 변경 될 수 있습니다.
                  <br />
                  &nbsp;&nbsp;.&nbsp;&nbsp;자동결제 시 결제 당일을 제외한 이용기간 중 언제든지 자동결제를 해지하실 수
                  있습니다.
                  <br />
                  &nbsp;&nbsp;.&nbsp;&nbsp;미사용 상품은 구매 후, 7일 이내 청약철회 가능합니다.
                  <br />
                  &nbsp;&nbsp;.&nbsp;&nbsp;고객센터를 통한 자동결제 해지 신청은 본인만 가능합니다.
                </Box>
              </Box>
            </ModalContentWrap>
          </ModalContentScrollBox>
          <Box
            sx={{
              position: 'fixed',
              bottom: '0',
              width: 'calc(100% - 4.7rem)',
              backgroundColor: '#fff',
              height: '3.5rem',
              borderBottomLeftRadius: '1.5rem',
              borderBottomRightRadius: '1.5rem',
              left: '2.35rem',
              zIndex: '-1',
            }}
          />
        </ModalContentWrapper>
        <ModalCardInfoContainer />
      </ModalBox>
    </Modal>
  );
}
