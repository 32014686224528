import { Fragment, useContext, useEffect, useState } from 'react';
import { TableBody, TableRow, TableCell, Box, Table as MuiTable, styled, ButtonProps } from '@mui/material';
import { d_flex, d_flex_center, d_flex_end } from '../../styles/common';
import Button from '../../components/button/Button';
import CustomDatePicker from '../../components/datepicker/DatePicker';
import { GoTriangleRight } from 'react-icons/go';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { routesState } from '../../recoil/common/routes';
import { ModalContext } from '../../provider/ModalProvider';
import dayjs from 'dayjs';
import { setPercent, loadingState } from '../../recoil/common/loading';
import { learningState } from '../../recoil/model/learning';
import { BASE_URL, GRAMMAR_MODES } from '../../utils/constants';
import { useNavigate } from 'react-router';
import { moduleSettingState } from '../../recoil/model/module_setting';
import { bookState } from '../../recoil/model/book';
import { userState } from '../../recoil/model/user';
import { fetchGetApi, fetchTabStudyGetApi, fetchStartStudyAlim } from '../../utils/api';
import { arrShuffle } from '../../utils/tools';
import { settingState } from '../../recoil/model/settings';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';

const StyledRecentContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  padding: '1rem',
  backgroundColor: '#fff',
}));

const StyledRecentWrap = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#fff',
  borderRadius: '1rem',
  boxShadow: props.theme.palette.boxShadow.main,
}));

const StyledTabWrap = styled(Box)(() => ({
  width: '100%',
  height: '7vh',
  ...d_flex,
}));

const StyledTabButton = styled(Button)(props => ({
  width: '100%',
  height: '100%',
  fontSize: '1rem',
  backgroundColor: '#fff3d6',
  borderBottom: `2px solid ${props.theme.palette.gray_3.main}`,
  borderRadius: '0',
  fontWeight: '600',
  color: '#493000',
  '&.Mui-disabled': {
    color: '#493000',
  },
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
  '@media(max-width: 1023px)': {
    borderBottom: `1px solid #f5f5f5`,
  },
}));

const StyledFilterWrap = styled(Box)(() => ({
  width: '100%',
  height: '7vh',
  borderBottom: '1px solid #101010',
  '@media(max-width: 1023px)': {
    borderColor: `#777`,
  },
  padding: '0 16px',
  ...d_flex_end,
  justifyContent: 'space-between',
  '& > div': {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    '&:first-of-type': {
      justifyContent: 'flex-start',
    },
    '&:last-of-type': {
      justifyContent: 'flex-end',
    },
  },
}));

const StyledBookListWrap = styled(Box)(() => ({
  width: '100%',
  height: 'calc(100% - 14vh)',
  overflowY: 'auto',
}));

interface DateButtonProps extends ButtonProps {
  datetype: string;
}

const StyledDateButton = styled(Button)<DateButtonProps>(props => ({
  width: '12rem',
  maxWidth: '30%',
  marginLeft: '4px',
  color: 'white',
  fontWeight: '600',
  borderRadius: '0',
  fontSize: '1rem',
  padding: '0.375rem 0.75rem',
  '@media (max-width: 851px)': {
    maxWidth: '22%',
  },
  backgroundColor: props.datetype == 'true' ? props.theme.palette.green.main : '#e9e9e9',
  '&:hover': {
    backgroundColor: props.datetype == 'true' ? props.theme.palette.green.main : '#e9e9e9',
  },
}));

const StyledFilterButton = styled(StyledDateButton)(props => ({
  backgroundColor: props.datetype == 'true' ? props.theme.palette.light_orange_2.main : '#e9e9e9',
  '&:hover': {
    backgroundColor: props.datetype == 'true' ? props.theme.palette.light_orange_2.main : '#e9e9e9',
  },
}));

const StyledLabelButton = styled(Button)(() => ({
  fontWeight: '500',
  padding: '0.46rem 1rem 0.34rem',
  color: 'white !important',
  borderRadius: '8px',
}));

const CustomTable = styled(MuiTable)(() => ({
  width: '100%',
  tableLayout: 'fixed',

  '.MuiCheckbox-root': {
    width: 'auto !important',
  },
}));

const CustomTableBody = styled(TableBody)(() => ({
  width: '100%',
  td: {
    paddingRight: '0.25rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const CustomTableRow = styled(TableRow)(() => ({
  width: '100%',
  height: '7vh',
  borderBottom: '1px solid var(--gray-3)',
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
  '&.detail-row:not(:last-child)': {
    borderBottom: 'none',
  },
  cursor: 'pointer',
  '& > *': {
    borderBottom: 'unset',
  },
  'td, th': {
    padding: 0,
    width: 'auto',
    position: 'relative',
    fontSize: '1rem',
    div: {
      whiteSpace: 'pre-wrap',
      display: 'flex',
      alignItems: 'center',
    },
  },
  '&:focus-within': {
    // background: 'var(--light-cyan)',
  },
}));

const CustomTableCell = styled(TableCell)(props => ({
  display: 'table-cell !important',
  border: 'none',
  fontWeight: '500',
  '&.MuiTableCell-alignCenter': {
    width: `${props.rowSpan ? '100%' : 'auto'}`,
  },
}));

const ShowIconBox = styled(Box)(() => ({
  ...d_flex_center,
}));

const CustomTableTdBox = styled(Box)(() => ({
  ...d_flex,
  height: '8vh',
  maxHeight: '50px',
  alignItems: 'center',
  justifyContent: 'center',
}));

function Recent() {
  const navigate = useNavigate();
  const { playEffectSound } = useContext(EffectSoundContext);
  const userStateData = useRecoilValue<UserType>(userState);
  const { center_type, customer_id, id } = userStateData;
  const routesStateData = useRecoilValue<RoutesType>(routesState);
  const setLearningStateData = useSetRecoilState<LearningType>(learningState);
  const setLoadingStateData = useSetRecoilState<LoadingType>(loadingState);
  const [bookStateData, setBookStateData] = useRecoilState<BookType>(bookState);
  const { booklist_set } = bookStateData;
  const settingStateData = useRecoilValue<SettingsType>(settingState);
  const moduleSettingStateData = useRecoilValue<ModuleSettings>(moduleSettingState);
  const { root_path } = routesStateData;
  const { modal_study_confirm, modal_alert } = useContext(ModalContext);
  const [tableItems, setTableItems] = useState<ScheduleModuleRecordType[]>([]);
  const [startdate, setStartdate] = useState(new Date(dayjs().date(1).format()));
  const [enddate, setEnddate] = useState(new Date(dayjs().format()));
  const [datetype, setDatetype] = useState('t-month');
  const [filtertype, setFiltertype] = useState('all');
  const [tableFilterType, setTableFilterType] = useState('all');
  const [datepickerKey, setDatepickerKey] = useState(0);

  useEffect(() => {
    if (booklist_set) {
      if (booklist_set.startdate && booklist_set.enddate) {
        setStartdate(booklist_set.startdate);
        setEnddate(booklist_set.enddate);
        setDatepickerKey(datepickerKey + 1);
      }
      setBookStateData(prevState => ({
        ...prevState,
        booklist_set: null,
      }));
    }

    return () => {
      setTableItems([]);
    };
  }, []);

  useEffect(() => {
    getRecordList();
    changeDatetype();
  }, [userStateData.pass, startdate, enddate, filtertype]);

  const changeDatetype = () => {
    if (
      dayjs(startdate).format('YYYY-MM-DD') == dayjs().format('YYYY-MM-01') &&
      dayjs(enddate).format('YYYY-MM-DD') == dayjs().format('YYYY-MM-DD')
    ) {
      setDatetype('t-month');
    } else if (
      dayjs(startdate).format('YYYY-MM-DD') == dayjs().subtract(1, 'month').format('YYYY-MM-01') &&
      dayjs(enddate).format('YYYY-MM-DD') == dayjs().subtract(1, 'month').endOf('M').format('YYYY-MM-DD')
    ) {
      setDatetype('l-month');
    } else {
      setDatetype('selected');
    }
  };

  const changeStartdate = (date: Date) => {
    if (dayjs(startdate).format('YYYY-MM-DD') != dayjs(date).format('YYYY-MM-DD')) setStartdate(date);
  };

  const changeEnddate = (date: Date) => {
    if (dayjs(enddate).format('YYYY-MM-DD') != dayjs(date).format('YYYY-MM-DD')) setEnddate(date);
  };

  const changeDate = (type: string) => {
    if (type == datetype) return false;
    playEffectSound('click');

    if (type == 't-month') {
      setStartdate(new Date(dayjs().format('YYYY-MM-01')));
      setEnddate(new Date(dayjs().format('YYYY-MM-DD')));
      setDatepickerKey(datepickerKey + 1);
    } else if (type == 'l-month') {
      setStartdate(new Date(dayjs().subtract(1, 'month').format('YYYY-MM-01')));
      setEnddate(new Date(dayjs().subtract(1, 'month').endOf('M').format('YYYY-MM-DD')));
      setDatepickerKey(datepickerKey + 1);
    }
  };

  const getRecordList = async () => {
    let uri = `/customers/${userStateData.customer_id}/accounts/${
      userStateData.id
    }/schedules/results/list?sdate=${dayjs(startdate).format('YYYY-MM-DD')}&edate=${dayjs(enddate).format(
      'YYYY-MM-DD',
    )}&ver=3`;

    if (filtertype == 'done' || filtertype == 'studying') {
      if (filtertype == 'done') {
        uri += '&status=1';
      } else {
        uri += '&status=0';
      }
    }

    const res = await fetchGetApi(uri);

    if (res.result) {
      setTableItems([...res.data]);
    } else {
      setTableItems([]);
    }
    setTableFilterType(filtertype);
  };

  const onRowClicked = async (idx: number, e?: React.MouseEvent<HTMLTableRowElement>) => {
    if (e) {
      if (
        (e.target as HTMLElement).tagName != 'TD' &&
        (e.target as HTMLElement).tagName != 'SPAN' &&
        (e.target as HTMLElement).tagName != 'svg' &&
        (e.target as HTMLElement).tagName != 'path' &&
        (e.target as HTMLElement).tagName != 'DIV'
      ) {
        return;
      }
    }

    if (!tableItems[idx]) return false;

    const item = tableItems[idx];
    const book_type =
      (userStateData.type < 20 || center_type == 'C') && item.schedule_type == 'schedules' ? 'schedules' : 'books';

    if (item.score == null) {
      if (
        (item.mode == 'speak' &&
          ['V003Y', 'V003DY', 'V003TY', 'G003Y', 'G003DY', 'G003TY'].includes(userStateData.product)) ||
        (['basic', 'advanced'].includes(item.mode) &&
          ['S003Y', 'S003DY', 'S003TY', 'G003Y', 'G003DY', 'G003TY'].includes(userStateData.product)) ||
        (['grammar_t', 'grammar_d', 'grammar_b'].includes(item.mode) &&
          ['S003Y', 'S003DY', 'S003TY', 'V003Y', 'V003DY', 'V003TY'].includes(userStateData.product))
      ) {
        // !toast 추후 확인 예정
        modal_alert.openModalAlert(
          userStateData.type == 11 ? 'personal_product_student_alert' : 'personal_product_alert',
          undefined,
          undefined,
          undefined,
          undefined,
          true,
          true,
        );
        return false;
      }
      modal_study_confirm.openModalStudyConfirm(
        'continue',
        'continue',
        async () => {
          continueLearning(item);
        },
        () => {
          setPercent(setLoadingStateData, 1);

          resetRecord(item);
        },
        `${tableItems[idx].schedule_name}\n${tableItems[idx].unit_name}`,
      );
    } else {
      modal_study_confirm.openModalStudyConfirm(
        'normal',
        'move_record',
        () => {
          setPercent(setLoadingStateData, 1, '학습 데이터를 분석하는 중입니다.');
          setBookStateData(prevState => ({
            ...prevState,
            booklist_set: {
              tab: undefined,
              schedule_id: null,
              grade: null,
              sort: null,
              folder_id: null,
              unit_id: null,
              tab_name: null,
              startdate: startdate,
              enddate: enddate,
              ready: false,
            },
          }));

          navigate(`${BASE_URL}/${root_path}/result`, {
            state: {
              schedule_id: item.schedule_id,
              unit_id: item.unit_id,
              record_id: item.record_id,
              module_record_id: item.module_record_id,
              tab_name: item.tab_name,
              book_type: book_type,
              schedule_type: book_type,
            },
          });
        },
        undefined,
        `${tableItems[idx].schedule_name}\n${tableItems[idx].unit_name}`,
      );
    }
  };

  const RecordType = ({ mode }: { mode: string }) => {
    switch (mode) {
      case 'basic':
      case 'grammar_b':
        return (
          <CustomTableTdBox>
            <StyledLabelButton disabled sx={{ color: '#fff', backgroundColor: '#1bd05b' }}>
              기본학습
            </StyledLabelButton>
          </CustomTableTdBox>
        );
      case 'advanced':
      case 'grammar_d':
        return (
          <CustomTableTdBox>
            <StyledLabelButton disabled sx={{ color: '#fff', backgroundColor: '#5bc7ff' }}>
              심화학습
            </StyledLabelButton>
          </CustomTableTdBox>
        );
      case 'wrong':
        return (
          <CustomTableTdBox>
            <StyledLabelButton disabled sx={{ color: '#fff', backgroundColor: '#ffd611' }}>
              오답학습
            </StyledLabelButton>
          </CustomTableTdBox>
        );
      case 'speak':
        return (
          <CustomTableTdBox>
            <StyledLabelButton disabled sx={{ color: '#fff', backgroundColor: '#4620e9' }}>
              회화학습
            </StyledLabelButton>
          </CustomTableTdBox>
        );
      case 'grammar_t':
        return (
          <CustomTableTdBox>
            <StyledLabelButton disabled sx={{ color: '#fff', backgroundColor: '#ffa139' }}>
              문법학습
            </StyledLabelButton>
          </CustomTableTdBox>
        );
      default:
        return null;
    }
  };

  const continueLearning = async (item: ScheduleModuleRecordType) => {
    setPercent(setLoadingStateData, 1);
    const book_type =
      (userStateData.type < 20 || center_type == 'C') && item.schedule_type == 'schedules' ? 'schedules' : 'books';
    const middlesheet_res = await fetchGetApi(
      `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${item.schedule_id}/units/${item.unit_id}/records/${item.record_id}/modules/${item.module_record_id}?type=${book_type}`,
    );
    if (item.mode == 'speak') {
      if (item.module_record_id && middlesheet_res.result && middlesheet_res.data.mod) {
        const modules = '786';
        setPercent(setLoadingStateData, 66);

        setLearningStateData(prevState => ({
          ...prevState,
          status: true,
          starttime: dayjs().format(),
          learning_type: 6 as learningTypeIndex,
          studytime: middlesheet_res.data.studytime,
          current_page: middlesheet_res.data.current_page ?? 0,
          current_step: middlesheet_res.data.current_step ?? 0,
          mod: middlesheet_res.data.mod,
          modules: modules,
          modal_disable: false,
          record_id: item.record_id,
          schedule_id: item.schedule_id,
          schedule_name: item.schedule_name,
          unit_id: item.unit_id,
          unit_name: item.unit_name,
          book_type: book_type,
          tab_name: 'speak',
          contents: middlesheet_res.data.contents,
          resultsheet: middlesheet_res.data.mod[middlesheet_res.data.current_page].resultsheet,
          show_modal: false,
          current_module_settings: moduleSettingStateData,
          module_record_id: item.module_record_id,
          use_image: 1,
          study_method: 0,
          save_enabled: true,
          first_alert: true,
          schedule_type: book_type,
          category_id: item.category_id,
          folder_id: item.folder_id,
        }));

        setBookStateData(prevState => ({
          ...prevState,
          booklist_set: {
            tab: undefined,
            schedule_id: null,
            grade: null,
            sort: null,
            folder_id: null,
            unit_id: null,
            tab_name: null,
            startdate: startdate,
            enddate: enddate,
            ready: false,
          },
        }));

        // * (개인) 학습 알림톡 발송 로직
        // ! 이어서 학습은 제외
        // if (center_type == 'C') {
        //   const time = dayjs().format('HH:mm분');
        //   fetchStartStudyAlim({
        //     time,
        //     customer_id,
        //     account_id: id,
        //     book_name: item.schedule_name,
        //     unit_name: item.unit_name,
        //   });
        // }

        navigate(`${BASE_URL}/${root_path}/speak`);
      } else {
        resetRecord(item);
      }
    } else if (GRAMMAR_MODES.includes(item.mode)) {
      //! 문법 학습 이어하기 처리
      if (item.module_record_id && middlesheet_res.result) {
        const learning_type = item.mode == 'grammar_t' ? 7 : item.mode == 'grammar_b' ? 8 : 9;
        const type = learning_type == 7 ? 't' : learning_type == 8 ? 'b' : 'd';
        setPercent(setLoadingStateData, 50);
        const grammar_count = await fetchTabStudyGetApi(
          `v2/book/getgrammarcount?unit_id=${(item as any).tab_unit_id}&type=G${type.toUpperCase()}`,
        );

        if (grammar_count) {
          let modules: number[] = grammar_count.cnt.split('-').map((item: any) => Number(item));

          const grammar_total_count = modules.reduce((acc, cur) => {
            return (acc += cur);
          }, 0);

          if (type == 't') {
            //! 이론학습
            if (grammar_total_count == 0) {
              //! 복습강의 O
              modules = [0];
            }
          } else {
            //! 기본학습, 심화학습
            const filter_modules = modules.filter(item => item !== 0);

            if (filter_modules.length != modules.length) {
              //! 복습강의
              modules = [...filter_modules];
            }
          }

          modules = modules.map((item, idx) => idx + 1);

          setLearningStateData(prevState => ({
            ...prevState,
            status: true,
            starttime: dayjs().format(),
            learning_type: learning_type,
            studytime: middlesheet_res.data.studytime,
            current_page: middlesheet_res.data.current_page ?? 0,
            current_step: middlesheet_res.data.current_step ?? 0,
            mod: middlesheet_res.data.mod,
            modules: modules.join(''),
            modal_disable: false,
            record_id: item.record_id,
            schedule_id: item.schedule_id,
            schedule_name: item.schedule_name,
            book_type: 'books',
            tab_name: item.tab_name,
            unit_id: item.unit_id,
            unit_name: `Unit ${(item as any).sequence}`,
            resultsheet: middlesheet_res.data.mod[middlesheet_res.data.current_page].resultsheet,
            show_modal: false,
            save_enabled: true,
            first_alert: true,
            schedule_type: 'books',
            grammar_learning_type: type,
            grammar_unit_seq: (item as any).sequence,
            grammar_tab_unit_id: (item as any).tab_unit_id,
            grammar_total_count: grammar_total_count,
            grammar_origin_unit_name: item.unit_name,
            module_record_id: item.module_record_id,
            contents: middlesheet_res.data.mod[middlesheet_res.data.current_page].contents ?? [],
            font_level: 1,
          }));

          setBookStateData(prevState => ({
            ...prevState,
            booklist_set: {
              tab: undefined,
              schedule_id: null,
              grade: null,
              sort: null,
              unit_id: null,
              tab_name: null,
              folder_id: null,
              startdate: startdate,
              enddate: enddate,
              ready: false,
            },
          }));

          // * (개인) 학습 알림톡 발송 로직
          // ! 이어서 학습은 제외
          // if (center_type == 'C') {
          //   const time = dayjs().format('HH:mm분');
          //   fetchStartStudyAlim({
          //     time,
          //     customer_id,
          //     account_id: id,
          //     book_name: item.schedule_name,
          //     unit_name: item.unit_name,
          //   });
          // }

          navigate(`${BASE_URL}/${root_path}/grammar`);
        } else {
          resetRecord(item);
        }
      } else {
        resetRecord(item);
      }
    } else {
      if (item.module_record_id && middlesheet_res.result) {
        let learning_type = 1;

        if (middlesheet_res.data.mod) {
          setPercent(setLoadingStateData, 50);
          if (middlesheet_res.data.mode == 'basic') {
            learning_type = 3;
          } else if (middlesheet_res.data.mode == 'advanced') {
            learning_type = 4;
          } else {
            learning_type = 5;
          }

          let modules = '';
          switch (item.mode) {
            case 'basic':
              modules = item.basic;
              break;
            case 'advanced':
              modules = item.advanced;
              break;
          }

          setLearningStateData(prevState => ({
            ...prevState,
            status: true,
            starttime: dayjs().format(),
            learning_type: learning_type as learningTypeIndex,
            studytime: middlesheet_res.data.studytime,
            current_page: middlesheet_res.data.current_page ?? 0,
            current_step: middlesheet_res.data.current_step ?? 0,
            mod: middlesheet_res.data.mod,
            modal_disable: false,
            record_id: item.record_id,
            schedule_id: item.schedule_id,
            schedule_name: item.schedule_name,
            unit_id: item.unit_id,
            unit_name: item.unit_name,
            book_type: book_type,
            tab_name: item.tab_name,
            contents: middlesheet_res.data.contents,
            resultsheet: middlesheet_res.data.mod[middlesheet_res.data.current_page].resultsheet,
            show_modal: false,
            current_module_settings: moduleSettingStateData,
            module_record_id: item.module_record_id,
            save_enabled: true,
            first_alert: true,
            tag: item.tag,
            modules,
          }));

          setBookStateData(prevState => ({
            ...prevState,
            booklist_set: {
              tab: undefined,
              schedule_id: null,
              grade: null,
              sort: null,
              folder_id: null,
              unit_id: null,
              tab_name: null,
              startdate: startdate,
              enddate: enddate,
              ready: false,
            },
          }));

          // * (개인) 학습 알림톡 발송 로직
          if (center_type == 'C') {
            const time = dayjs().format('HH:mm분');
            fetchStartStudyAlim({
              time,
              customer_id,
              account_id: id,
              book_name: item.schedule_name,
              unit_name: item.unit_name,
              studyAlim: userStateData.studyAlim,
            });
          }

          navigate(`${BASE_URL}/${root_path}/learning`);
        } else {
          resetRecord(item);
        }
      } else {
        resetRecord(item);
      }
    }
  };

  const resetRecord = async (item: ScheduleModuleRecordType) => {
    if (item.module_record_id) {
      if (item.mode == 'speak') {
        const book_type =
          (userStateData.type < 20 || center_type == 'C') && item.schedule_type == 'schedules' ? 'schedules' : 'books';
        const word_type = 'speak';
        const contents = await fetchGetApi(
          `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${item.schedule_id}/units/${item.unit_id}/${word_type}?type=${book_type}`,
        );
        if (contents.result) {
          setPercent(setLoadingStateData, 50);

          // 학습 시작시 로직, 이어하기 로직 필요
          const learning_type = 6;
          const modules = '786';
          const mod: ModType[] = [];
          const contents_data = contents.data;

          for (let i = 0; i < modules.length; i++) {
            const module = Number(modules[i]) as ModuleIndex;

            mod[i] = {
              module: module,
              seq: i, // 학습모듈 순서
              done: 0, // 완료여부 (0: 미완료, 1: 완료)
              step: 0, // 학습 진행도
            };
          }

          setLearningStateData(prevState => ({
            ...prevState,
            status: true,
            starttime: dayjs().format(),
            studytime: 0,
            learning_type: learning_type,
            current_page: 0,
            current_step: 0,
            mod: mod,
            modules: modules,
            modal_disable: false,
            record_id: item.record_id,
            schedule_id: item.schedule_id,
            schedule_name: item.schedule_name,
            book_type: book_type,
            tab_name: 'speak',
            unit_id: item.unit_id,
            unit_name: item.unit_name,
            contents: contents_data,
            resultsheet: [],
            show_modal: false,
            current_module_settings: moduleSettingStateData,
            use_image: 1,
            study_method: 0,
            first_alert: true,
            schedule_type: book_type,
            category_id: item.category_id,
            folder_id: item.folder_id,
            module_record_id: item.module_record_id,
            reset_learning: true,
            save_enabled: false,
          }));

          setBookStateData(prevState => ({
            ...prevState,
            booklist_set: {
              tab: undefined,
              schedule_id: null,
              grade: null,
              sort: null,
              folder_id: null,
              unit_id: null,
              tab_name: null,
              startdate: startdate,
              enddate: enddate,
              ready: false,
            },
          }));

          if (center_type == 'C') {
            const time = dayjs().format('HH:mm분');

            fetchStartStudyAlim({
              time,
              customer_id,
              account_id: id,
              book_name: item.schedule_name,
              unit_name: item.unit_name,
              studyAlim: userStateData.studyAlim,
            });
          }

          navigate(`${BASE_URL}/${root_path}/speak`);
        } else {
          // error
          setPercent(setLoadingStateData, 100);
        }
      } else if (GRAMMAR_MODES.includes(item.mode)) {
        const book_type =
          (userStateData.type < 20 || center_type == 'C') && item.schedule_type == 'schedules' ? 'schedules' : 'books';
        //! 문법 처음부터 학습 처리
        const learning_type = item.mode == 'grammar_t' ? 7 : item.mode == 'grammar_b' ? 8 : 9;
        const type = learning_type == 7 ? 't' : learning_type == 8 ? 'b' : 'd';

        const grammar_count = await fetchTabStudyGetApi(
          `v2/book/getgrammarcount?unit_id=${(item as any).tab_unit_id}&type=G${type?.toUpperCase()}`,
        );

        const mod: ModType[] = [];

        if (grammar_count) {
          let modules: number[] = grammar_count.cnt.split('-').map((item: any) => Number(item));

          const grammar_total_count = modules.reduce((acc, cur) => {
            return (acc += cur);
          }, 0);

          if (type == 't') {
            //! 이론학습
            if (grammar_total_count == 0) {
              //! 복습강의 O
              modules = [0];
            }
          } else {
            //! 기본학습, 심화학습
            const filter_modules = modules.filter(item => item !== 0);

            if (filter_modules.length != modules.length) {
              //! 복습강의
              modules = [...filter_modules];
            }
          }

          modules = modules.map((item, idx) => idx + 1);

          for (let i = 0; i < modules.length; i++) {
            mod.push({
              module: modules[i],
              seq: i, // 학습모듈 순서
              done: 0, // 완료여부 (0: 미완료, 1: 완료)
              step: 0, // 학습 진행도
            });
          }

          setLearningStateData(prevState => ({
            ...prevState,
            status: true,
            starttime: dayjs().format(),
            studytime: 0,
            learning_type: learning_type,
            current_page: 0,
            current_step: 0,
            mod: mod,
            modules: modules.join(''),
            modal_disable: false,
            record_id: item.record_id,
            schedule_id: item.schedule_id,
            schedule_name: item.schedule_name,
            book_type: book_type,
            tab_name: item.tab_name,
            unit_id: item.unit_id,
            unit_name: `Unit ${(item as any).sequence}`,
            show_modal: false,
            resultsheet: [],
            first_alert: true,
            schedule_type: book_type,
            grammar_learning_type: type,
            grammar_unit_seq: (item as any).sequence,
            grammar_tab_unit_id: (item as any).tab_unit_id,
            grammar_total_count: grammar_total_count,
            grammar_origin_unit_name: item.unit_name,
            module_record_id: item.module_record_id,
            reset_learning: true,
            save_enabled: false,
            font_level: 1,
          }));

          setBookStateData(prevState => ({
            ...prevState,
            booklist_set: {
              tab: undefined,
              schedule_id: null,
              grade: null,
              sort: null,
              unit_id: null,
              tab_name: null,
              folder_id: null,
              startdate: startdate,
              enddate: enddate,
              ready: false,
            },
          }));

          navigate(`${BASE_URL}/${root_path}/grammar`);
        } else {
          // error
          setPercent(setLoadingStateData, 100);
        }
      } else {
        const book_type =
          (userStateData.type < 20 || center_type == 'C') && item.schedule_type == 'schedules' ? 'schedules' : 'books';

        const word_type = item.mode == 'wrong' ? 'wrongwords' : 'words';
        const contents = await fetchGetApi(
          `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${item.schedule_id}/units/${item.unit_id}/${word_type}?type=${book_type}`,
        );
        if (contents.result) {
          setPercent(setLoadingStateData, 50);

          // 학습 시작시 로직, 이어하기 로직 필요
          const learning_type = item.mode == 'basic' ? 3 : item.mode == 'advanced' ? 4 : 5;
          const modules = item.mode == 'basic' ? item.basic : item.mode == 'advanced' ? item.advanced : '23';
          const mod: ModType[] = [];
          let contents_data = contents.data;

          // 교재순서 랜덤
          if (!settingStateData.order_word) {
            contents_data = arrShuffle(contents_data);
          }

          for (let i = 0; i < modules.length; i++) {
            const module = Number(modules[i]) as ModuleIndex;

            mod[i] = {
              module: module,
              seq: i, // 학습모듈 순서
              done: 0, // 완료여부 (0: 미완료, 1: 완료)
              step: 0, // 학습 진행도
            };
          }

          setLearningStateData(prevState => ({
            ...prevState,
            status: true,
            starttime: dayjs().format(),
            studytime: 0,
            learning_type: learning_type,
            current_page: 0,
            current_step: 0,
            mod: mod,
            modules: modules,
            modal_disable: false,
            record_id: item.record_id,
            schedule_id: item.schedule_id,
            schedule_name: item.schedule_name,
            book_type: book_type,
            tab_name: item.tab_name,
            unit_id: item.unit_id,
            unit_name: item.unit_name,
            contents: contents_data,
            resultsheet: [],
            show_modal: false,
            current_module_settings: moduleSettingStateData,
            module_record_id: item.module_record_id,
            reset_learning: true,
            first_alert: true,
            tag: item.tag,
          }));

          setBookStateData(prevState => ({
            ...prevState,
            booklist_set: {
              tab: undefined,
              schedule_id: null,
              grade: null,
              sort: null,
              folder_id: null,
              unit_id: null,
              tab_name: null,
              startdate: startdate,
              enddate: enddate,
              ready: false,
            },
          }));

          navigate(`${BASE_URL}/${root_path}/learning`);
        } else {
          // error
          setPercent(setLoadingStateData, 100);
        }
      }
    } else {
      setPercent(setLoadingStateData, 100);
    }
  };

  return (
    <StyledRecentContainer>
      <StyledRecentWrap>
        <StyledTabWrap>
          <StyledTabButton disabled disableRipple>
            최근 학습내역
          </StyledTabButton>
        </StyledTabWrap>
        <StyledFilterWrap>
          <Box>
            <StyledFilterButton
              sx={{ marginLeft: 0 }}
              datetype={filtertype == 'all' ? 'true' : 'false'}
              onClick={() => {
                playEffectSound('click');
                setFiltertype('all');
              }}
            >
              전체
            </StyledFilterButton>
            <StyledFilterButton
              datetype={filtertype == 'done' ? 'true' : 'false'}
              onClick={() => {
                playEffectSound('click');
                setFiltertype('done');
              }}
            >
              완료
            </StyledFilterButton>
            <StyledFilterButton
              datetype={filtertype == 'studying' ? 'true' : 'false'}
              onClick={() => {
                playEffectSound('click');
                setFiltertype('studying');
              }}
            >
              학습중
            </StyledFilterButton>
          </Box>
          <Box>
            <CustomDatePicker
              sx={{
                width: '12rem',
                maxWidth: '30%',
                '@media (max-width: 851px)': {
                  maxWidth: '22%',
                },
                '& .datepicker_input': {
                  width: '100%',
                },
              }}
              maxDate={dayjs(enddate).format('YYYY-MM-DD')}
              onClose={date => changeStartdate(date)}
              selected={dayjs(startdate).format()}
              key={`start_${datepickerKey}`}
            />
            &nbsp;~&nbsp;
            <CustomDatePicker
              sx={{
                width: '12rem',
                maxWidth: '30%',
                '@media (max-width: 851px)': {
                  maxWidth: '22%',
                },
                '& .datepicker_input': {
                  width: '100%',
                },
              }}
              minDate={dayjs(startdate).format('YYYY-MM-DD')}
              onClose={date => changeEnddate(date)}
              selected={dayjs(enddate).format()}
              key={`end_${datepickerKey}`}
            />
            <StyledDateButton
              datetype={datetype == 'l-month' ? 'true' : 'false'}
              onClick={() => changeDate('l-month')}
              disableRipple
            >
              지난달
            </StyledDateButton>
            <StyledDateButton
              datetype={datetype == 't-month' ? 'true' : 'false'}
              onClick={() => changeDate('t-month')}
              disableRipple
            >
              이번달
            </StyledDateButton>
          </Box>
        </StyledFilterWrap>
        <StyledBookListWrap>
          <CustomTable id='data_table'>
            <CustomTableBody>
              {tableItems.length > 0 ? (
                tableItems.map((item, idx1) => {
                  return (
                    <Fragment key={`body_row_wrap_${idx1}`}>
                      <CustomTableRow
                        key={`body_row_${idx1}`}
                        onClick={(e: React.MouseEvent<HTMLTableRowElement>) => {
                          onRowClicked(idx1, e);
                        }}
                      >
                        <TableCell
                          sx={{
                            width: '5.5% !important',
                            borderRight: '1px solid var(--gray-3)',
                            borderBottom: 'unset',
                            '@media(max-width: 1023px)': {
                              borderColor: '#f5f5f5',
                            },
                          }}
                          component='td'
                        >
                          <ShowIconBox className='icon-box'>
                            <GoTriangleRight />
                          </ShowIconBox>
                        </TableCell>
                        <TableCell
                          sx={{
                            width: '7.5% !important',
                            borderBottom: 'unset',
                            '@media (max-width: 1904px)': {
                              width: '7.5% !important',
                            },
                            '@media (min-width: 1740px) and (max-width: 1904px)': {
                              width: '5.5% !important',
                            },
                            '@media (min-width: 1500px) and (max-width: 1740px)': {
                              width: '6.5% !important',
                            },
                            '@media (max-width: 1265px)': {
                              width: '10% !important',
                            },
                            '@media (max-width: 846px)': {
                              width: '12% !important',
                            },
                            '@media (max-width: 700px)': {
                              width: '14% !important',
                            },
                          }}
                          component='td'
                        >
                          <RecordType mode={item.mode} />
                        </TableCell>
                        <CustomTableCell component='td'>
                          <Box
                            sx={{
                              ...d_flex,
                              paddingTop: '0.15rem',
                              borderLeft: '1px solid var(--gray-3)',
                              height: '2.8vh',
                              '@media(max-width: 1023px)': {
                                borderColor: '#f5f5f5',
                              },
                            }}
                          >
                            <Box
                              sx={{
                                flex: '5',
                                paddingLeft: '24px',
                                fontSize: '1rem',
                                ...d_flex,
                                alignItems: 'center',
                                color: '#7d7d7d',
                                maxWidth: '82%',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'block !important',
                                  color: '#6a6a6a',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap !important',
                                }}
                              >
                                {item.schedule_name}
                                {item.schedule_words_count ? (
                                  <Box component={'span'} sx={{ color: '#b5b5b5' }}>
                                    &nbsp;-&nbsp;{item.schedule_words_count}개 분할
                                  </Box>
                                ) : null}
                              </Box>
                              &nbsp;
                              <Box sx={{ color: '#b5b5b5', whiteSpace: 'nowrap !important' }}>{item.unit_name}</Box>
                              {item.score == null ? (
                                tableFilterType == 'studying' &&
                                (item.done_count <= 1 || item.done_count == null) ? null : (
                                  <Box sx={{ color: '#b5b5b5' }}>
                                    {item.done_count > 1 ? (
                                      <Box
                                        component={'span'}
                                        sx={{ color: '#ff976b', whiteSpace: 'nowrap !important' }}
                                      >
                                        &nbsp;&nbsp;{item.done_count > 1 ? `(${item.done_count - 1}차 재학습)` : ''}
                                      </Box>
                                    ) : null}
                                    {tableFilterType == 'studying' ? null : (
                                      <Box
                                        component={'span'}
                                        sx={{ color: '#b5b5b5', whiteSpace: 'nowrap !important' }}
                                      >
                                        &nbsp;&nbsp;-&nbsp;&nbsp;
                                      </Box>
                                    )}
                                    {tableFilterType == 'studying' ? null : (
                                      <Box
                                        component={'span'}
                                        sx={{ color: 'var(--danger)', whiteSpace: 'nowrap !important' }}
                                      >
                                        학습중
                                      </Box>
                                    )}
                                  </Box>
                                )
                              ) : item.done_count == 1 ? null : (
                                <Box sx={{ color: '#b5b5b5' }}>
                                  <Box component={'span'} sx={{ color: '#ff976b', whiteSpace: 'nowrap !important' }}>
                                    &nbsp;&nbsp;{`(${item.done_count - 1}차 재학습)`}
                                  </Box>
                                </Box>
                              )}
                            </Box>
                            <Box sx={{ flex: '1', paddingRight: '16px', ...d_flex_center }}>
                              {dayjs.unix(item.lastdate).format('YYYY.MM.DD')}
                            </Box>
                          </Box>
                        </CustomTableCell>
                      </CustomTableRow>
                    </Fragment>
                  );
                })
              ) : (
                <CustomTableRow>
                  <CustomTableCell
                    width={'100%'}
                    sx={{ textAlign: 'center', color: '#c0c0c0', fontWeight: '400', cursor: 'default' }}
                    colSpan={3}
                  >
                    최근 학습내역이 없습니다.
                  </CustomTableCell>
                </CustomTableRow>
              )}
            </CustomTableBody>
          </CustomTable>
        </StyledBookListWrap>
      </StyledRecentWrap>
    </StyledRecentContainer>
  );
}

export default Recent;
