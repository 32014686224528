import { atom, SetterOrUpdater } from 'recoil';

export interface ModalStudyStopType {
  visible: boolean;
}

export const modalStudyStopState = atom<ModalStudyStopType>({
  key: 'modalStudyStop',
  default: {
    visible: false,
  },
});

interface ModalStudyStopSetter {
  setModalStudyStop: SetterOrUpdater<ModalStudyStopType>;
}

export const openModalStudyStop = ({ setModalStudyStop }: ModalStudyStopSetter) => {
  setModalStudyStop({
    visible: true,
  });
};

export const closeModalStudyStop = ({ setModalStudyStop }: ModalStudyStopSetter) => {
  setModalStudyStop({
    visible: false,
  });
};
