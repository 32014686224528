import { styled, Box } from '@mui/material';

export const Container = styled(Box)({
  zIndex: '1000',
  overflowX: 'hidden',
  overflowY: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  inset: '0',
  position: 'fixed',
  '&  *': {
    fontFamily: 'Apple SD Gothic Neo',
  },
});

export const Background = styled(Box)({
  background: 'rgba(0, 0, 0, 0.5)',
  width: '100%',
  height: '100%',
  position: 'absolute',
});

export const Wrapper = styled(Box)({
  minWidth: '18.75rem',
  backgroundColor: '#fff',
  borderRadius: '0.5rem',
  position: 'relative',
  // top: '1.75rem',

  transform: 'scale(1.1)',

  '@media (min-width: 1266px) and (max-width: 1905px)': {
    transform: 'scale(1.6)',
  },

  '@media (min-width: 962px) and (max-width: 1265px)': {
    transform: 'scale(1.4)',
  },

  '@media (min-width: 770px) and (max-width: 961px)': {
    transform: 'scale(1.35)',
  },
});

export const TopWrap = styled(Box)({
  padding: '1.5rem',
  height: '21.375rem',
  width: '20.75rem',
});

export const Title = styled('p')({
  color: 'rgb(75, 85, 99)',
  fontWeight: '500',
  marginBottom: '0.25rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > span': {
    fontWeight: '400',
    fontSize: '0.85rem',
    letterSpacing: '0px',
  },
});

export const SubTitle = styled('p')({
  color: 'rgb(156, 163, 175)',
  fontWeight: '600',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  cursor: 'pointer',
  marginTop: '0.75rem',
  marginBottom: '1rem',
  letterSpacing: '-0.8px',
});

export const ContentBox = styled(Box)({
  width: '18rem',
  background: 'rgb(249, 246, 255)',
  padding: '1rem 0.75rem',
  borderRadius: '0.5rem',
  marginBottom: '0.5rem',
  '&:last-of-type': {
    marginBottom: '0',
  },
  '& > div': {
    display: 'flex',
    marginBottom: '0.25rem',
    '& > span': {
      '&:last-of-type': {
        fontSize: '0.925rem',
        color: 'rgba(83, 21, 255, 0.8)',
        fontWeight: '600',
        textAlign: 'center',
        marginLeft: '0.4rem',
      },
    },
  },
  '& > p': {
    color: 'rgb(156, 163, 175)',
    fontWeight: '300',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
});

export const BottomWrap = styled(Box)({
  display: 'flex',
  height: '3.125rem',
  borderBottomLeftRadius: '0.5rem',
  borderBottomRightRadius: '0.5rem',
});

export const BoxButton = styled(Box)({
  position: 'relative',
  width: '50%',
  background: 'rgb(240, 240, 240)',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
});

export const InputContentBox = styled(Box)(() => {
  return {
    width: '17.75rem',
    background: 'rgb(249, 246, 255)',
    padding: '0.75rem 1rem',
    borderRadius: '0.5rem',
    marginBottom: '0.5rem',
    '&:last-of-type': {
      marginBottom: '0',
    },

    '& > div:first-of-type': {
      display: 'flex',
      marginBottom: '0.25rem',
      '& > span': {
        '&:last-of-type': {
          fontSize: '0.925rem',
          color: 'rgba(83, 21, 255, 0.8)',
          fontWeight: '600',
          textAlign: 'center',
          marginLeft: '0.4rem',
        },
      },
    },
  };
});

export const DomainButtonWrap = styled(Box)({
  marginTop: '0.5rem',
  display: 'flex',
  gap: '0.5rem',
  '& > button': {
    fontSize: '0.85rem',
    padding: '0.3rem 1rem',
    minWidth: '0',
    flexGrow: '1',
  },
});

export const DayButtonWrap = styled(Box)({
  marginTop: '0.5rem',
  display: 'flex',
  gap: '0.5rem',
  '& > button': {
    fontSize: '0.85rem',
    padding: '0.3rem 0.1rem',
    minWidth: '0',
    flexGrow: '1',
  },
});

export const DropdownWrap = styled(Box)({
  marginTop: '0.5rem',
  display: 'flex',
  height: '2rem',
  '& > button': {
    fontSize: '0.85rem',
    padding: '0.3rem 0.1rem',
    minWidth: '0',
    flexGrow: '1',
    '.MuiButton-endIcon': {
      marginRight: '0.25rem',
      color: '#000',
    },
  },
});
