import { Box, Checkbox } from '@mui/material';
import React from 'react';
import {
  CheckboxBox,
  CheckboxWrap,
  ContentWrap,
  Footer,
  Header,
  SettingBoxWrap,
  SettingBox,
  WordPrintButton,
  Wrapper,
  SettingLabel,
  SettingButtonGroup,
  SettingButton,
  Line,
} from './PrintSetting.Styles';
import { Input } from 'components/common/InputText/InputText.Styles';

export interface PrintSettingVAProps {
  title: string;
  printSetting: PrintSettingType;
  printInfo: PrintInfoType;
  onClickWordPrint: () => void;
  onChangePrintSetting: (key: string, value: number) => void;
  onChangePrintInfo: (key: string, value: boolean) => void;
  onChangeTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickPrintType: () => void;
}

export const PrintSettingUI = React.memo(function PrintSettingUI({
  title,
  printSetting,
  printInfo,
  onClickWordPrint,
  onChangePrintSetting,
  onChangePrintInfo,
  onChangeTitle,
  onClickPrintType,
}: PrintSettingVAProps) {
  return (
    <Wrapper>
      <Header>
        <Line />
        <Box>출력 설정</Box>
      </Header>
      <ContentWrap>
        <SettingBoxWrap>
          <Box>
            <SettingBox>
              <SettingLabel>제목</SettingLabel>
              <Input
                placeholder={'20자 이내로 작성 가능합니다'}
                color='gray_1'
                value={title}
                onChange={onChangeTitle}
                autoComplete={'off'}
              />
            </SettingBox>
            <SettingBox>
              <SettingLabel>유형</SettingLabel>
              <SettingButtonGroup>
                <SettingButton
                  variant={printSetting.type == 0 ? 'contained' : 'outlined'}
                  color={printSetting.type == 0 ? 'purple_5' : 'gray_1'}
                  onClick={() => onClickPrintType()}
                >
                  서술형
                </SettingButton>
                <SettingButton
                  variant={printSetting.type == 1 ? 'contained' : 'outlined'}
                  color={printSetting.type == 1 ? 'purple_5' : 'gray_1'}
                  onClick={() => onChangePrintSetting('type', 1)}
                >
                  리스트 형
                </SettingButton>
              </SettingButtonGroup>
            </SettingBox>
            <SettingBox>
              <SettingLabel>순서</SettingLabel>
              <SettingButtonGroup>
                <SettingButton
                  variant={printSetting.sort == 0 ? 'contained' : 'outlined'}
                  color={printSetting.sort == 0 ? 'purple_5' : 'gray_1'}
                  onClick={() => onChangePrintSetting('sort', 0)}
                >
                  레벨
                </SettingButton>
                <SettingButton
                  variant={printSetting.sort == 1 ? 'contained' : 'outlined'}
                  color={printSetting.sort == 1 ? 'purple_5' : 'gray_1'}
                  onClick={() => onChangePrintSetting('sort', 1)}
                >
                  랜덤
                </SettingButton>
                <SettingButton
                  variant={printSetting.sort == 2 ? 'contained' : 'outlined'}
                  color={printSetting.sort == 2 ? 'purple_5' : 'gray_1'}
                  onClick={() => onChangePrintSetting('sort', 2)}
                >
                  ABC순
                </SettingButton>
              </SettingButtonGroup>
            </SettingBox>
            <SettingBox>
              <SettingLabel>글씨 크기</SettingLabel>
              <SettingButtonGroup>
                <SettingButton
                  variant={printSetting.fontsize == 0 ? 'contained' : 'outlined'}
                  color={printSetting.fontsize == 0 ? 'purple_5' : 'gray_1'}
                  onClick={() => onChangePrintSetting('fontsize', 0)}
                >
                  작게
                </SettingButton>
                <SettingButton
                  variant={printSetting.fontsize == 1 ? 'contained' : 'outlined'}
                  color={printSetting.fontsize == 1 ? 'purple_5' : 'gray_1'}
                  onClick={() => onChangePrintSetting('fontsize', 1)}
                >
                  보통
                </SettingButton>
                <SettingButton
                  variant={printSetting.fontsize == 2 ? 'contained' : 'outlined'}
                  color={printSetting.fontsize == 2 ? 'purple_5' : 'gray_1'}
                  onClick={() => onChangePrintSetting('fontsize', 2)}
                >
                  크게
                </SettingButton>
              </SettingButtonGroup>
            </SettingBox>
            <SettingBox>
              <SettingLabel>줄 간격</SettingLabel>
              <SettingButtonGroup>
                <SettingButton
                  variant={printSetting.line == 0 ? 'contained' : 'outlined'}
                  color={printSetting.line == 0 ? 'purple_5' : 'gray_1'}
                  onClick={() => onChangePrintSetting('line', 0)}
                >
                  좁게
                </SettingButton>
                <SettingButton
                  variant={printSetting.line == 1 ? 'contained' : 'outlined'}
                  color={printSetting.line == 1 ? 'purple_5' : 'gray_1'}
                  onClick={() => onChangePrintSetting('line', 1)}
                >
                  보통
                </SettingButton>
                <SettingButton
                  variant={printSetting.line == 2 ? 'contained' : 'outlined'}
                  color={printSetting.line == 2 ? 'purple_5' : 'gray_1'}
                  onClick={() => onChangePrintSetting('line', 2)}
                >
                  넓게
                </SettingButton>
              </SettingButtonGroup>
            </SettingBox>
            <SettingBox>
              <SettingLabel>다단 설정</SettingLabel>
              <SettingButtonGroup>
                <SettingButton
                  variant={printSetting.column == 0 ? 'contained' : 'outlined'}
                  color={printSetting.column == 0 ? 'purple_5' : 'gray_1'}
                  onClick={() => onChangePrintSetting('column', 0)}
                >
                  1단
                </SettingButton>
                <SettingButton
                  variant={printSetting.column == 1 ? 'contained' : 'outlined'}
                  color={printSetting.column == 1 ? 'purple_5' : 'gray_1'}
                  onClick={() => onChangePrintSetting('column', 1)}
                >
                  2단
                </SettingButton>
                <SettingButton
                  variant={printSetting.column == 2 ? 'contained' : 'outlined'}
                  color={printSetting.column == 2 ? 'purple_5' : 'gray_1'}
                  onClick={() => onChangePrintSetting('column', 2)}
                >
                  3단
                </SettingButton>
              </SettingButtonGroup>
            </SettingBox>
          </Box>
        </SettingBoxWrap>
        <CheckboxWrap>
          <CheckboxBox>
            <Checkbox
              onChange={() => onChangePrintInfo('logo', !printInfo.logo)}
              checked={printInfo.logo}
              color='green'
            />
            <Box onClick={() => onChangePrintInfo('logo', !printInfo.logo)}>&nbsp;&nbsp;로고 삽입</Box>
          </CheckboxBox>
          <CheckboxBox>
            <Checkbox
              onChange={() => onChangePrintInfo('pagenum', !printInfo.pagenum)}
              checked={printInfo.pagenum}
              color='green'
            />
            <Box onClick={() => onChangePrintInfo('pagenum', !printInfo.pagenum)}>&nbsp;&nbsp;쪽번호 삽입</Box>
          </CheckboxBox>
          <CheckboxBox>
            <Checkbox
              onChange={() => onChangePrintInfo('cname', !printInfo.cname)}
              checked={printInfo.cname}
              color='green'
            />
            <Box onClick={() => onChangePrintInfo('cname', !printInfo.cname)}>&nbsp;&nbsp;수업명 삽입</Box>
          </CheckboxBox>
          {printSetting.type == 0 ? (
            <CheckboxBox>
              <Checkbox
                onChange={() => onChangePrintInfo('answer', !printInfo.answer)}
                checked={printInfo.answer}
                color='green'
              />
              <Box onClick={() => onChangePrintInfo('answer', !printInfo.answer)}>&nbsp;&nbsp;답안지 출력</Box>
            </CheckboxBox>
          ) : null}
        </CheckboxWrap>
      </ContentWrap>
      <Footer>
        <WordPrintButton onClick={onClickWordPrint}>시험지 출력</WordPrintButton>
      </Footer>
    </Wrapper>
  );
});
