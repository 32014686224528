import { Box, IconButton, styled } from '@mui/material';
import { ModalContext } from 'provider/ModalProvider';
import { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useRecoilState } from 'recoil';
import { loadingState, setPercent } from 'recoil/common/loading';
import { bookState } from 'recoil/model/book';
import { learningState } from 'recoil/model/learning';

interface VideoLearningProps {
  sendResultsheet: (resultsheet: any) => void;
  isReviewLearning: boolean;
}

function VideoLearning(props: VideoLearningProps) {
  const sendResultsheet = props.sendResultsheet;
  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const [bookStateData, setBookStateData] = useRecoilState<BookType>(bookState);
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const frameRef = useRef<HTMLIFrameElement | null>(null);
  const { modal_alert, modal_study_confirm } = useContext(ModalContext);
  const { schedule_name, grammar_unit_seq, current_step, current_page, direction, grammar_contents, resultsheet } =
    learningStateData;

  const tab_dev_host = 'https://dev.tabstudy.co.kr';
  const tab_real_host = 'https://api.tabstudy.co.kr';
  const host = process.env.REACT_APP_NODE_ENV === 'production' ? tab_real_host : tab_dev_host;

  useEffect(() => {
    window.addEventListener('message', listenMessage);
    return () => {
      window.removeEventListener('message', listenMessage);
    };
  }, []);

  useEffect(() => {
    if (frameRef.current) {
      vodLoad();
    }
  }, [frameRef.current]);

  const vodLoad = () => {
    if (frameRef.current) {
      setTimeout(() => {
        frameRef.current?.contentWindow?.postMessage(
          {
            type: 'load',
            bookname: schedule_name,
            unit_seq: grammar_unit_seq,
          },
          '*',
        );
        setPercent(setLoadingStateData, 100);

        setTimeout(() => {
          modal_alert.openModalAlert('video_learning_start');
        }, 300);
      }, 1000);
    }
  };

  function listenMessage(e: MessageEvent) {
    if (e.data.event === 'ended') {
      if (frameRef.current) {
        openVideoEndConfirmModal();
        // frameRef.current?.contentWindow?.postMessage(
        //   {
        //     type: 'currentTime',
        //     time: 0,
        //   },
        //   '*',
        // );
      }
    }
  }

  const openVideoEndConfirmModal = () => {
    modal_study_confirm.openModalStudyConfirm(
      props.isReviewLearning ? 'review_video' : 'end_video',
      `end_video`,
      () => goNextProcess(),
      () => {
        frameRef.current?.contentWindow?.postMessage(
          {
            type: 'currentTime',
            time: 0,
          },
          '*',
        );
      },
      props.isReviewLearning
        ? `개념이해가 부족했다면 영상강의 재시청을,\n다음 학습을 진행하려면 확인을 선택하세요.`
        : `개념이해가 부족했다면 영상강의 재시청을,\n문제풀이를 하려면 다음학습을 시작하세요.`,
    );
  };

  const goNextProcess = () => {
    setPercent(setLoadingStateData, 1, '학습 데이터를 불러오는 중입니다.');

    //! sendResultSheet > learningtateData에 들어갈 데이터 세팅
    const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheet));
    resultsheet_tmp[current_step] = {
      no: current_step + 1,
      answer: true,
      type: 'video',
    };
    setTimeout(() => {
      sendResultsheet(resultsheet_tmp);
    }, 1);
  };

  return (
    <Box
      id='vod_wrapper'
      sx={{
        height: '100%',
        zIndex: '1',
        width: '100%',
      }}
    >
      <iframe
        ref={frameRef}
        id='vod_iframe'
        src={`${host}/vod_long.html`}
        frameBorder='0'
        allow='fullscreen'
        scrolling='no'
        style={{
          display: 'block',
          width: '1px',
          height: '1px',
          minWidth: '100%',
          minHeight: '100%',
        }}
      ></iframe>
    </Box>
  );
}

export default VideoLearning;
