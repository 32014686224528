import React from 'react';
import {
  BackIconBox,
  Body,
  Container,
  FirstWrap,
  Header,
  IconWrap,
  SecondWrap,
  ThirdWrap,
  Wrapper,
  BackIcon,
} from './Print.Styles';
import { PrintWordlistContainer } from './Wordlist/PrintWordlist.Container';
import { PrintSettingContainer } from './Setting/PrintSetting.Container';
import { WordWorksheetContainer } from './Worksheet/WordWorksheet/WordWorksheet.Container';

export interface PrintVAProps {
  duplicate: boolean;
  duplicateLoading: boolean;
  originalWordList: PrintBookListType;
  printWordList: PrintBookListType;
  type: number;
  title: string;
  printSetting: PrintSettingType;
  printInfo: PrintInfoType;
  printRef: React.MutableRefObject<any>;
  setOriginalWordList: React.Dispatch<React.SetStateAction<PrintBookListType>>;
  onChangeDuplicate: () => void;
  onClickBackButton: () => void;
  onClickWordPrint: () => void;
  onChangePrintSetting: (key: string, value: number) => void;
  onChangePrintInfo: (key: string, value: boolean) => void;
  onChangeTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCommitted: (event: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => void;
  endLoading: () => void;
  printMethod?: string;
}

export const PrintUI = React.memo(function PrintUI({
  duplicate,
  duplicateLoading,
  originalWordList,
  printWordList,
  type,
  title,
  printSetting,
  printInfo,
  printRef,
  setOriginalWordList,
  onChangeDuplicate,
  onClickBackButton,
  onClickWordPrint,
  onChangePrintSetting,
  onChangePrintInfo,
  onChangeTitle,
  endLoading,
  printMethod,
  onChangeCommitted,
}: PrintVAProps) {
  const wordlist_props = {
    duplicate,
    originalWordList,
    printBookWordList: printWordList,
    setOriginalWordList,
    onChangeDuplicate,
    printMethod,
  };
  const worksheet_props = {
    title,
    printSetting,
    printInfo,
    printBookWordList: printWordList,
    printRef,
    duplicate,
    duplicateLoading,
    endLoading,
    printMethod,
  };
  const setting_props = {
    title,
    printSetting,
    printInfo,
    type,
    onClickWordPrint,
    onChangePrintSetting,
    onChangePrintInfo,
    onChangeTitle,
    printMethod,
    onChangeCommitted,
  };
  return (
    <Wrapper>
      <Container>
        <Header className='nonprint'>
          <IconWrap>
            <BackIconBox onClick={onClickBackButton}>
              <BackIcon />
            </BackIconBox>
          </IconWrap>
        </Header>
        <Body>
          <FirstWrap className='nonprint'>
            <PrintWordlistContainer {...wordlist_props} />
          </FirstWrap>
          <SecondWrap>
            {type == 0 ? <WordWorksheetContainer {...worksheet_props} /> : type == 1 ? '쓰기 연습' : '단어 카드'}
          </SecondWrap>
          <ThirdWrap className='nonprint'>
            <PrintSettingContainer {...setting_props} />
          </ThirdWrap>
        </Body>
      </Container>
    </Wrapper>
  );
});
