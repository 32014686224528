import { OutlinedInput, FormHelperText, OutlinedInputProps, styled, Box, SxProps, Theme } from '@mui/material';
import React, { CSSProperties, ReactElement } from 'react';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import InputTitle from '../common/InputTitle';
import { useState, useEffect } from 'react';

const StyledInput = styled(OutlinedInput)(props => ({
  fontSize: '1rem',
  width: '100%',
  height: '2.5rem',
  outline: 'none',
  '&.Mui-disabled.MuiInputBase-colorGray_1': {
    backgroundColor: '#f5f5f5',
    '& input': {
      WebkitTextFillColor: 'var(--black)',
    },
    '& fieldset': {
      borderColor: 'var(--white) !important',
    },
  },
  '&.Mui-error ': {
    '& fieldset': {
      borderColor: `${props.theme.palette.red.main} !important`,
    },
  },
  '&.Mui-focused': {
    fieldset: {
      borderWidth: '1px !important',
    },
  },
  '& input': {
    cursor: `${props.type === 'select' ? 'pointer' : 'auto'}`,
    padding: '0.5rem 1rem',
    borderRadius: '10px',
    '&::placeholder': {
      color: '#c7c7c7 !important',
      transition: 'none',
      fontWeight: '300',
      opacity: '1',
    },
    '&:disabled': {
      backgroundColor: '#f5f5f5',
    },
  },

  '&:hover': {
    'input, fieldset': {
      borderColor: 'var(--white)',
    },
  },
  '& fieldset': {
    borderColor: 'var(--white)',
    legend: {
      outline: 'none',
    },
  },

  '.password_icon': {
    position: 'absolute',
    right: '0.9rem',
    width: '1.4rem',
    height: '1.4rem',
    cursor: 'pointer',
    color: '#bdbbbc',
  },
}));

export const ProductPeriod = styled(Box)({
  left: '1rem',
  top: '50%',
  transform: 'translate(0, -50%)',
  position: 'absolute',
  fontSize: '1rem',
  span: {
    color: 'red',
    marginLeft: '0.25rem',
  },
});

const CustomFormHelperText = styled(FormHelperText)(({ theme }) => ({
  position: 'absolute',
  top: 'calc(2.25rem / 3)',
  right: '0',
  color: 'var(--red)',
  fontSize: '0.85rem',
}));

// select type의 오른쪽 아래 삼각형
const edit_triangle = {
  content: '',
  position: 'absolute',
  right: 0,
  bottom: '0.5rem',
  width: 0,
  height: 0,
  borderLeft: '7px solid transparent',
  borderRight: '7px solid #c2c2c2',
  borderTop: '7px solid transparent',
  borderBottom: '7px solid #c2c2c2',
  borderBottomRightRadius: '4px',
  marginBottom: '1px',
  cursor: 'pointer',
  zIndex: 1,
} as CSSProperties;

const CloseEye = styled(IoMdEyeOff)(({ theme }) => ({
  transform: 'scaleX(-1)',
}));

const OpenEye = styled(IoMdEye)(({ theme }) => ({}));

// Input props Type
interface InputProps extends OutlinedInputProps {
  title?: string;
  sx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
  tooltip?: {
    text: ReactElement;
    direction:
      | 'bottom'
      | 'left'
      | 'right'
      | 'top'
      | 'bottom-end'
      | 'bottom-start'
      | 'left-end'
      | 'left-start'
      | 'right-end'
      | 'right-start'
      | 'top-end'
      | 'top-start'
      | undefined;
  };
  required?: boolean;
  type?: 'text' | 'select' | 'password' | undefined | string;
  id?: string;
  errorText?: string;
  disable?: boolean;
  visibleButton?: boolean;
  classification?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  value?: string;
  error?: boolean;
  titleBold?: boolean;
  usePasswordIcon?: boolean;
  product?: string;
  period?: string;
  inputSx?: SxProps<Theme>;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, type?: string) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClickButtonName?: (type: string) => void;
  onClickButtonTime?: (type: string) => void;
}

const InputNormal = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const title = props.title;
  const sx = props.sx;
  const tooltip = props.tooltip;
  const required = props.required;
  const input_type = props.type ? props.type : 'text';
  const placeholder = props.placeholder;
  const id = props.id;
  const disable = props.disable;
  const value = props.value;
  const visible_button = props.visibleButton;
  const classification = props.classification;
  const read_only = props.readOnly;
  const product = props.product;
  const period = props.period;
  const error = props.error;
  const error_text = props.errorText;
  const auto_focus = props.autoFocus;
  const onChange = props.onChange;
  const onClickButtonName = props.onClickButtonName;
  const onClickButtonTime = props.onClickButtonTime;
  const onFocus = props.onFocus;
  const onKeyDown = props.onKeyDown;
  const onKeyPress = props.onKeyPress;
  const onBlur = props.onBlur;
  const title_bold = props.titleBold;
  const title_sx = props.titleSx;
  const use_password_icon = props.usePasswordIcon;
  const input_sx = props.inputSx;

  const [inputType, setInputType] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [endIcon, setEndIcon] = useState<null | React.ReactNode>(null);

  const CloseEyeButton = () => {
    return (
      <CloseEye
        className='password_icon'
        onClick={() => {
          setPasswordVisible(true);
          setInputType('text');
        }}
      />
    );
  };

  const OpenEyeButton = () => {
    return (
      <OpenEye
        className='password_icon'
        onClick={() => {
          setPasswordVisible(false);
          setInputType('password');
        }}
      />
    );
  };

  useEffect(() => {
    setInputType(input_type);
    if (input_type == 'password' && use_password_icon) {
      setEndIcon(<CloseEyeButton />);
    }
  }, [input_type]);

  useEffect(() => {
    if (!passwordVisible) {
      setEndIcon(<CloseEyeButton />);
    } else {
      setEndIcon(<OpenEyeButton />);
    }
  }, [passwordVisible]);

  return (
    <>
      {title ? (
        <InputTitle
          title={title}
          tooltip={tooltip}
          required={required}
          visibleButton={visible_button ? visible_button : false}
          onClickButtonName={onClickButtonName ? onClickButtonName : undefined}
          onClickButtonTime={onClickButtonTime ? onClickButtonTime : undefined}
          classification={classification}
          sx={{ mb: '0', ...title_sx }}
          bold={title_bold}
        />
      ) : null}
      <Box sx={sx ? sx : { width: '100%', position: 'relative' }}>
        <StyledInput
          id={id}
          name={id}
          type={inputType}
          placeholder={placeholder}
          color='gray_1'
          readOnly={inputType === 'select' || read_only ? true : false}
          disabled={disable ? true : false}
          value={value}
          onChange={
            onChange ? (event: React.ChangeEvent<HTMLInputElement>) => onChange(event, classification) : undefined
          }
          onClick={inputType === 'select' ? props.onClick : undefined}
          error={error}
          onFocus={onFocus ? onFocus : undefined}
          onBlur={onBlur ? onBlur : undefined}
          onKeyDown={onKeyDown ? onKeyDown : undefined}
          autoFocus={auto_focus}
          onKeyPress={onKeyPress ? onKeyPress : undefined}
          ref={ref}
          sx={input_sx}
          endAdornment={input_type == 'password' ? endIcon : null}
        />
        {inputType === 'select' ? <span style={edit_triangle}></span> : null}
        {product && period ? (
          <ProductPeriod>
            {product} {period ? <Box component={'span'}>{period}</Box> : null}
          </ProductPeriod>
        ) : null}
        {error && error_text ? <CustomFormHelperText>{error_text}</CustomFormHelperText> : null}
      </Box>
    </>
  );
});

InputNormal.displayName = 'InputNormal';

export default InputNormal;
