import { ModalTestPrintSettingUI } from './ModalTestPrintSetting.Presenter';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import React from 'react';
import {
  ModalTestPrintSettingDataType,
  closeModalTestPrintSetting,
  modalTestPrintSettingData,
} from 'recoil/common/modalTestPrintSetting';
import { ModalContext } from 'provider/ModalProvider';

export const ModalTestPrintSettingContainer = React.memo(function ModalTestPrintSettingContainer() {
  const [modalTestPrintSetting, setModalTestPrintSetting] =
    useRecoilState<ModalTestPrintSettingDataType>(modalTestPrintSettingData);
  const { modal_alert } = useContext(ModalContext);
  const [wordCount, setWordCount] = useState(0);
  const [remainWordCount, setRemainWordCount] = useState(0);
  const [openTooltip, setOpenTooltip] = useState([false, false]);
  const [testSetting, setTestSetting] = useState<TestPrintSettingType>({
    stom: {
      check: true,
      multi: 0,
      short: 0,
    },
    mtos: {
      check: true,
      multi: 0,
      short: 0,
    },
  });

  useEffect(() => {
    if (modalTestPrintSetting.visible) {
      setTestSetting({
        stom: {
          check: true,
          multi: 0,
          short: 0,
        },
        mtos: {
          check: true,
          multi: 0,
          short: 0,
        },
      });
      setWordCount(modalTestPrintSetting.wordCount);
      setRemainWordCount(modalTestPrintSetting.wordCount);
    }
  }, [modalTestPrintSetting.visible]);

  const onChangeCounter = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, key1: 'mtos' | 'stom', key2: 'multi' | 'short') => {
      const copyTestSetting = JSON.parse(JSON.stringify(testSetting));
      copyTestSetting[key1][key2] = +e.target.value.replace(/[^0-9]/g, '');
      const totalSelectCount =
        +copyTestSetting.mtos.multi +
        +copyTestSetting.mtos.short +
        +copyTestSetting.stom.multi +
        +copyTestSetting.stom.short;
      if (wordCount < totalSelectCount) {
        modal_alert.openModalAlert('overflow_test_word');
      } else {
        setTestSetting(copyTestSetting);
        setRemainWordCount(wordCount - totalSelectCount);
      }
    },
    [testSetting, wordCount, remainWordCount],
  );

  const onClickTestCheck = useCallback(
    (type: 'mtos' | 'stom', e?: React.ChangeEvent<HTMLInputElement>) => {
      if (e) e.stopPropagation();
      const copyTestSetting = JSON.parse(JSON.stringify(testSetting));
      copyTestSetting[type].check = !copyTestSetting[type].check;
      if (!copyTestSetting[type].check) {
        copyTestSetting[type].short = '';
        copyTestSetting[type].multi = '';
        const totalSelectCount =
          +copyTestSetting.mtos.multi +
          +copyTestSetting.mtos.short +
          +copyTestSetting.stom.multi +
          +copyTestSetting.stom.short;
        setRemainWordCount(wordCount - totalSelectCount);
      }
      setTestSetting(copyTestSetting);
    },
    [testSetting, wordCount, remainWordCount],
  );

  const onClickClose = useCallback(() => {
    closeModalTestPrintSetting({ setModalTestPrintSetting });
  }, [modalTestPrintSetting]);

  const onSuccess = useCallback(() => {
    modalTestPrintSetting.onSuccess(testSetting);
    closeModalTestPrintSetting({ setModalTestPrintSetting });
  }, [modalTestPrintSetting, testSetting]);

  const props_obj = {
    visible: modalTestPrintSetting.visible,
    wordCount,
    remainWordCount,
    onSuccess,
    testSetting,
    openTooltip,
    setOpenTooltip,
    onClickClose,
    onChangeCounter,
    onClickTestCheck,
  };

  return <ModalTestPrintSettingUI {...props_obj} />;
});
