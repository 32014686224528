import MainHeader from '../common/MainHeader';

import ListHeader from '../common/ListHeader';
import ListSide from '../common/ListSide';

import { Routes, Route, useNavigate } from 'react-router-dom';

import { useEffect, useState, Suspense, lazy, useContext, useRef } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { ModalContext } from '../../provider/ModalProvider';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { resetUserState, userState } from '../../recoil/model/user';
import { routesState } from '../../recoil/common/routes';
import { loadingState } from '../../recoil/common/loading';
import { searchbarState } from '../../recoil/common/searchbar';

import MainIndex from '../../pages/Index';
import ScheduleIndex from '../../pages/schedule/Index';
import ReportIndex from '../../pages/report/Index';
import LeaderboardIndex from '../../pages/leaderboard/Index';
import Pocketnote from '../../pages/pocketnote/Pocketnote';
import LeveltestIndex from '../../pages/leveltest/Leveltest';
import ManagerIndex from '../../pages/manager/Index';
import BookIndex from '../../pages/book/Index';
import QuizIndex from '../../pages/quiz/Index';
import LearningIndex from '../../pages/learning/Index';
import SpeakIndex from 'pages/speak/Index';
import NotFound from '../../pages/error/NotFound';

import { BASE_URL, PRESIDENT_OF_CENTER_TYPE, TEMP_STUDENTS_ACCOUNT_IDS } from '../../utils/constants';

import { validationPath } from '../../utils/tools';

import Loading from '../../components/loading/Loading';

import { styled, Box } from '@mui/material';
import ResultIndex from '../../pages/result/Index';
import Recent from '../../pages/report/Recent';
import { settingState } from '../../recoil/model/settings';
import { learningState } from '../../recoil/model/learning';
import { deviceState } from '../../recoil/common/device';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';
import { fetchPutApi } from '../../utils/api';
import dayjs from 'dayjs';
import { bookState } from '../../recoil/model/book';
import { pocketNoteState } from '../../recoil/model/pocket_note';
import { BackgroundSoundContext } from '../../provider/BackgroundSoundProvider';
import { setUserInFireStoreToNull } from '../../utils/firebase';
import { ModalCheck2DataType, modalChec2kData, openModalCheck2 } from '../../recoil/common/modalCheck2';
import { modalHiddenMissionState } from 'recoil/common/modalHiddenMission';
import { hiddenMissionState } from 'recoil/common/mission';
import GrammarLearningIndex from 'pages/learning/GrammarLearning/Index';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { toast_contents } from 'utils/modal_contents';
import {
  closeLoadingCircleSpinner,
  LoadingCircleSpinnerDataType,
  LoadingCircleSpinnerState,
} from 'recoil/common/loadingCircleSpinner';

declare let navigator: any;
declare let window: any;

const StyledLayoutDefault = styled(Box)({
  width: '100%',
  height: '100vh',
  backgroundColor: '#fff',
});

const StyledLayoutMain = styled(Box)({
  width: '100%',
  display: 'flex',
});

interface ContentProps {
  check_main_path: string;
  check_side: string;
}

const StyledLayoutContents = styled(Box)<ContentProps>(props => ({
  height: '100%',
  width: `${props.check_main_path || props.check_side ? '100%' : 'calc(100% - 7rem)'}`,
  zIndex: 2,
}));

function LayoutDefault() {
  const navigate = useNavigate();
  const [modalCheck2, setModalCheck2] = useRecoilState<ModalCheck2DataType>(modalChec2kData);
  const { modal_alert, modal_confirm } = useContext(ModalContext);
  const { playEffectSound } = useContext(EffectSoundContext);
  const setLoadingCircleSpinner = useSetRecoilState<LoadingCircleSpinnerDataType>(LoadingCircleSpinnerState);
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const { pass } = userStateData;
  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const [routesStateData, setRoutesStateData] = useRecoilState<RoutesType>(routesState);
  const { path, root_path, check_main_path, child_path, prev_path } = routesStateData;
  const pathRef = useRef(path);
  const accountIdRef = useRef(userStateData.id);
  const userTypeRef = useRef(userStateData.type);
  const [settingStateData, setSettingStateData] = useRecoilState<SettingsType>(settingState);
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const learningStateData_ref = useRef(learningStateData);
  learningStateData_ref.current = JSON.parse(JSON.stringify(learningStateData));

  const { modal_disable, current_page, current_step, resultsheet } = learningStateData;
  const setToastBar = useSetRecoilState(toastBarState);
  const [deviceStateData, setDeviceStateData] = useRecoilState<DeviceType>(deviceState);
  const { is_mobile, device_pause } = deviceStateData;
  const { percent } = loadingStateData;
  const { is_fullscreen } = settingStateData;
  const resetSearchbarState = useResetRecoilState(searchbarState);
  const [showMain, setShowMain] = useState(true);
  const [blocking, setBlocking] = useState(false);
  const [header, setHeader] = useState(true);
  const [firstFullscreen, setFirstFullscreen] = useState(true);

  const resetLearningState = useResetRecoilState(learningState);
  const resetBookState = useResetRecoilState(bookState);
  const resetPocketNoteState = useResetRecoilState(pocketNoteState);
  const resetModalMissionState = useResetRecoilState(modalHiddenMissionState);
  const resetHiddenMissionState = useResetRecoilState(hiddenMissionState);

  const api_interval = useRef<null | NodeJS.Timeout>(null);

  const location = useLocation();

  const [showNotFound, setShowNotFound] = useState(false);

  const { resetBGM, firstPlayingBGM, firstStart } = useContext(BackgroundSoundContext);

  const success_cb = () => {
    openModalCheck2({ setModalCheck2 }, 'duplicate_logout');
  };

  // const unloadApp = () => {
  //   if (window.FB_UNSCRIBE) {
  //     window.FB_UNSCRIBE();
  //   }

  //   if (
  //     accountIdRef.current &&
  //     userTypeRef.current != PRESIDENT_OF_CENTER_TYPE &&
  //     !TEMP_STUDENTS_ACCOUNT_IDS.includes(accountIdRef.current)
  //   ) {
  //     setUserInFireStoreToNull(accountIdRef.current);
  //   }
  // };

  // useEffect(() => {
  //   if (window?.cordova?.platformId === 'ios' || window?.cordova?.platformId === 'android') {
  //     window.addEventListener('beforeunload', unloadApp);
  //     window.removeEventListener('pagehide', unloadApp);
  //   } else {
  //     window.addEventListener('pagehide', unloadApp);
  //     window.addEventListener('unload', unloadApp);
  //     window.removeEventListener('beforeunload', unloadApp);
  //   }

  //   return () => {
  //     window.removeEventListener('beforeunload', unloadApp);
  //     window.removeEventListener('pagehide', unloadApp);
  //     window.addEventListener('unload', unloadApp);
  //   };
  // }, []);

  useEffect(() => {
    if (showMain) {
      const user = JSON.parse(sessionStorage.getItem('user') as string);
      if (user && user.pass) {
        setShowMain(true);
        accountIdRef.current = user.id;
        setUserStateData(prevState => ({ ...prevState, ...user }));
      } else {
        setShowMain(false);
      }
    }
    window.addEventListener('beforeunload', beforeUnLoadFunction);
    return () => {
      window.removeEventListener('beforeunload', beforeUnLoadFunction);
    };
  }, []);

  const beforeUnLoadFunction = (event: BeforeUnloadEvent) => {
    if (pathRef.current != '/manager/qna') {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  useEffect(() => {
    if (pass) {
      sessionStorage.setItem('user', JSON.stringify(userStateData));
      setTimeout(() => {
        if (firstPlayingBGM) firstStart();
      }, 3000);
    }
  }, [userStateData]);

  useEffect(() => {
    pathRef.current = path;
    if (is_mobile) {
      document.addEventListener('backbutton', onBackButton, false);
    }

    console.log('path', path);
    if (
      [
        '/book/study/result',
        '/book/school/result',
        '/book/grammar/result',
        '/book/speak/result',
        '/recent/result',
        '/report/lookup/result',
      ].includes(path) == false
    ) {
      closeLoadingCircleSpinner({ setLoadingCircleSpinner });
    }

    return () => {
      if (is_mobile) document.removeEventListener('backbutton', onBackButton, false);
    };
  }, [is_mobile, modal_disable, path, root_path, child_path, learningStateData, percent]);

  const onBackButton = () => {
    console.log('onBackButton', modal_disable, root_path);
    if (modal_disable) return false;
    if (root_path == '') {
      modal_confirm.openModalConfirm('logout', 'quit_voca', () => navigator.app.exitApp(), quit_voca_callback);
    } else if (learningStateData?.status) {
      if (percent > 0) return false;
      const exit_message = getExitMessage();
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
      const exit_type =
        !learningStateData.save_enabled || learningStateData.book_type == 'pocketnote'
          ? 'exit_study_2'
          : 'exit_study_1';
      modal_confirm.openModalConfirm(
        exit_type,
        exit_message,
        () => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: false,
          }));
        },
        exit_learning_callback,
      );
    } else {
      playEffectSound('click');
      navigate(`${BASE_URL}/`);
    }
  };

  const quit_voca_callback = () => {
    window.sessionStorage.removeItem('user');
    window.sessionStorage.removeItem('authToken');
    window.sessionStorage.removeItem('refreshToken');
    resetRecoilState();
    setTimeout(() => {
      window.screen.orientation.lock('portrait');
      navigate(`${BASE_URL}/login`);
    }, 0);
  };

  const resetRecoilState = () => {
    if (
      userTypeRef.current != PRESIDENT_OF_CENTER_TYPE &&
      accountIdRef.current !== 0 &&
      !TEMP_STUDENTS_ACCOUNT_IDS.includes(accountIdRef.current)
    ) {
      setUserInFireStoreToNull(userStateData.id);
    }
    resetUserState({ setUserStateData });
    resetLearningState();
    resetBookState();
    resetPocketNoteState();
    resetModalMissionState();
    resetHiddenMissionState();
    resetBGM();
  };

  const exit_learning_callback = async () => {
    if (learningStateData.book_type != 'pocketnote' && learningStateData.save_enabled) {
      const learning_data = JSON.parse(JSON.stringify(learningStateData));
      learning_data.mod[current_page].resultsheet = resultsheet;
      if (learning_data.bingo_card) learning_data.mod[current_page].bingo_card = learning_data.bingo_card;
      learning_data.studytime = learning_data.studytime + dayjs().diff(learningStateData.starttime, 's');

      const save_res = await fetchPutApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${learningStateData.schedule_id}/units/${learningStateData.unit_id}/records/${learningStateData.record_id}/modules/${learningStateData.module_record_id}`,
        learning_data,
      );
    }
    resetLearningState();
    navigate(
      root_path == 'recent' || child_path == 'learning'
        ? `${BASE_URL}/${root_path}`
        : `${BASE_URL}/${root_path}/${child_path}`,
    );
  };

  const getExitMessage = () => {
    let exit = 'exit_';
    switch (learningStateData.learning_type) {
      case 1:
      case 2:
        exit += 'memorize';
        break;
      case 3:
        exit += 'basic';
        break;
      case 4:
        exit += 'advanced';
        break;
      case 5:
        exit += 'wrong';
        break;
      case 6:
        exit += 'speak';
        break;
      default:
        break;
    }
    return exit;
  };

  useEffect(() => {
    const listpage = ['schedule', 'report', 'leaderboard', 'manager'];

    if (listpage.includes(root_path) && !learningStateData.status) setHeader(false);
    else setHeader(true);

    setShowNotFound(validationPath(path));

    return () => {
      resetSearchbarState();

      if (api_interval.current) {
        clearInterval(api_interval.current);
      }
      api_interval.current = null;
    };
  }, [location.pathname, root_path, learningStateData.status]);

  useEffect(() => {
    const set_path = location.pathname;
    const set_root_path = set_path.split('/')[1];
    const set_child_path = set_path.split('/')[2];

    setRoutesStateData(prevState => ({
      ...prevState,
      path: set_path,
      prev_path: path,
      root_path: set_root_path,
      child_path: set_child_path ? set_child_path : '',
      check_main_path: set_root_path != '' ? false : true,
    }));
  }, [location.pathname]);

  const components = [
    { component: <MainIndex />, path: '/' },
    { component: <ScheduleIndex />, path: '/schedule/*' },
    { component: <SpeakIndex />, path: '/report/lookup/speak' },
    { component: <LearningIndex />, path: '/report/lookup/learning' },
    { component: <GrammarLearningIndex />, path: '/report/lookup/grammar' },
    { component: <ResultIndex />, path: '/report/lookup/result' },
    { component: <ReportIndex />, path: '/report/*' },
    { component: <LeaderboardIndex />, path: '/leaderboard/*' },
    { component: <Pocketnote />, path: '/pocketnote' },
    { component: <LearningIndex />, path: '/pocketnote/learning' },
    { component: <LeveltestIndex />, path: '/leveltest/*' },
    { component: <ManagerIndex />, path: '/manager/*' },
    { component: <Recent />, path: '/recent' },
    { component: <SpeakIndex />, path: '/recent/speak' },
    { component: <LearningIndex />, path: '/recent/learning' },
    { component: <GrammarLearningIndex />, path: '/recent/grammar' },
    { component: <ResultIndex />, path: '/recent/result' },
    { component: <ResultIndex />, path: '/book/speak/result' },
    { component: <SpeakIndex />, path: '/book/speak/speak' },
    { component: <LearningIndex />, path: '/book/study/learning' },
    { component: <LearningIndex />, path: '/book/school/learning' },
    { component: <GrammarLearningIndex />, path: '/book/grammar/learning' },
    { component: <ResultIndex />, path: '/book/study/result' },
    { component: <ResultIndex />, path: '/book/school/result' },
    { component: <ResultIndex />, path: '/book/grammar/result' },
    { component: <BookIndex />, path: '/book/*' },
    { component: <QuizIndex />, path: '/quiz/*' },
    { component: <NotFound />, path: '/*' },
  ];

  useEffect(() => {
    if (percent === 100) {
      setTimeout(() => {
        setLoadingStateData(() => ({
          percent: 0,
          message: '잠시만 기다려주세요.',
        }));
      }, 300);
    }
  }, [percent]);

  useEffect(() => {
    if (firstFullscreen) {
      setFirstFullscreen(false);
    } else {
      if (is_fullscreen) {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: true,
        }));
        if (learningStateData_ref.current.show_modal) {
          modal_alert.openModalAlert('fullscreen_on');
        } else {
          modal_alert.openModalAlert('fullscreen_on', undefined, undefined, () => {
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
          });
        }
      } else {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: true,
        }));
        if (learningStateData_ref.current.show_modal) {
          modal_alert.openModalAlert('fullscreen_on');
        } else {
          modal_alert.openModalAlert('fullscreen_off', undefined, undefined, () => {
            console.log(learningStateData_ref.current);
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
          });
        }
      }
    }
  }, [is_fullscreen]);

  return (
    <>
      {showMain ? (
        <StyledLayoutDefault>
          {!blocking ? (
            <>
              {header || percent > 0 ? (
                <MainHeader />
              ) : (
                <>
                  <ListSide />
                  <ListHeader />
                </>
              )}
              {percent > 0 ? <Loading /> : null}
              <Suspense fallback={<Loading isLogo={true} />}>
                <StyledLayoutMain
                  sx={{
                    height: header
                      ? ['', 'pocketnote'].includes(root_path) && !learningStateData.status
                        ? 'calc(100vh - 6.5rem)'
                        : 'calc(100vh - 4rem)'
                      : 'calc(100vh - 6.5rem)',
                    width: !header && !['', 'pocketnote'].includes(root_path) ? 'calc(100% - 4.25rem)' : '100%',
                  }}
                >
                  <StyledLayoutContents check_main_path={`${check_main_path}`} check_side={`${header}`}>
                    <Suspense fallback={<Loading isLogo={true} />}>
                      <Routes>
                        {showNotFound && <Route path='/*' element={<NotFound />}></Route>}
                        {components.map((item, idx) => {
                          return (
                            <Route
                              path={`${item.path}`}
                              element={<Suspense fallback={<Loading isLogo={true} />}>{item.component}</Suspense>}
                              key={`layout_route_${idx}`}
                            />
                          );
                        })}
                      </Routes>
                    </Suspense>
                  </StyledLayoutContents>
                </StyledLayoutMain>
              </Suspense>
            </>
          ) : null}
        </StyledLayoutDefault>
      ) : (
        <Navigate to={`${BASE_URL}/login`} />
      )}
    </>
  );
}

export default LayoutDefault;
