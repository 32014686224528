import { Box, styled } from '@mui/material';
import ButtonContainer from 'components/button/Button';

export const LayoutRegistartion = styled(Box)({
  width: '50%',
  maxWidth: '1000px',
  height: 'auto',
  margin: '0 auto',
  padding: '2.5rem 0 4rem',
  gap: '1rem',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

export const RegistartionRow = styled(Box)({
  width: '100%',
  gap: '2rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const RegistartionButton = styled(ButtonContainer)({
  width: '10rem',
  marginTop: '2rem',
  '&.Mui-disabled': {
    backgroundColor: '#f3f3f3',
  },
});

export const PostWrapper = styled(Box)({
  position: 'relative',
  marginBottom: '1.5rem',
  display: 'flex',
  justifyContent: 'center',
});

export const PostCloseButton = styled(Box)({
  width: '20px',
  height: '20px',
  backgroundColor: 'black',
  color: 'white',
  position: 'absolute',
  right: '10%',
  top: '0',
  zIndex: '99',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const postCodeStyle = {
  display: 'block',
  top: '20%',
  width: '80%',
  height: '21.429rem',
  marginBottom: '1rem',
  padding: '0.5rem 0 0 0.5rem',
  zIndex: 1,
  border: '1px solid black',
};
