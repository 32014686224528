import { Wrapper, RadioGroup, RadioLabel } from './ExpenditureEvidence.Styles';
import {
  LayoutRegistrationContainer,
  LayoutRegistrationContainerProps,
} from 'components/common/LayoutRegistration/LayoutRegistration.Container';
import { CardContainer, CardContainerProps } from 'components/card/Card.Container';
import { Radio } from '@mui/material';
import React from 'react';
import { LoadingContainer } from 'components/loading/Loading/Loading.Container';

export interface ExpenditureEvidenceVAProps extends LayoutRegistrationContainerProps, CardContainerProps {
  originRegiContainer: Array<RegistrationDataType[]>;
  type: 'add' | 'update' | 'error' | 'check';
  receiptType: 'taxInvoice' | 'cashReceipts' | 'cardUsage' | '';
  onSubmit: (data: RegistrationDatasType, initRegiDatas?: () => void) => void;
  radioArray: { value: string; label: string }[];
  onChangeRadio: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  pageId: string;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  reference: any;
}

export const ExpenditureEvidenceUI = React.memo(function ExpenditureEvidenceUI({
  originRegiContainer,
  type,
  onSubmit,
  radioArray,
  receiptType,
  onChangeRadio,
  directContent,
  isLoading,
  pageId,
  onBlur,
  onFocus,
  reference,
}: ExpenditureEvidenceVAProps) {
  const registration_props = {
    originRegiContainer,
    type,
    onSubmit,
    pageId,
    onBlur,
    onFocus,
  };

  return (
    <Wrapper length={`${originRegiContainer.length}`} ref={reference}>
      {isLoading || receiptType == '' ? (
        <LoadingContainer
          sx={{
            top: '-5rem',
          }}
        />
      ) : null}
      <RadioGroup row onChange={onChangeRadio}>
        {radioArray.map(radio => (
          <RadioLabel
            key={`radio_${radio.value}`}
            style={{ pointerEvents: 'none' }}
            control={<Radio style={{ pointerEvents: 'auto' }} color='green' checked={receiptType === radio.value} />}
            label={radio.label}
            sx={{ span: { pointerEvents: 'auto' } }}
            value={radio.value}
          />
        ))}
      </RadioGroup>
      <LayoutRegistrationContainer {...registration_props} />
      <CardContainer directContent={directContent} />
    </Wrapper>
  );
});
