import {
  Modal,
  Wrapper,
  Wrap,
  Header,
  HeaderIconWrap,
  Body,
  Footer,
  SelectBox,
  SelectWordCounter,
  RemainWordCounter,
  Tooltip,
  CounterInputBox,
} from './ModalTestPrintSetting.Styles';
import { Box, Checkbox, ClickAwayListener } from '@mui/material';
import { IoCloseSharp } from 'react-icons/io5';
import React from 'react';
import ButtonContainer from 'components/button/Button';
import { BsInfoCircle } from 'react-icons/bs';

export interface ModalStudySettingVAProps {
  visible: boolean;
  wordCount: number;
  remainWordCount: number;
  testSetting: TestPrintSettingType;
  openTooltip: boolean[];
  setOpenTooltip: React.Dispatch<React.SetStateAction<boolean[]>>;
  onClickClose: () => void;
  onSuccess: () => void;
  onChangeCounter: (e: React.ChangeEvent<HTMLInputElement>, key1: 'mtos' | 'stom', key2: 'multi' | 'short') => void;
  onClickTestCheck: (type: 'mtos' | 'stom', e?: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ModalTestPrintSettingUI = React.memo(function ModalTestPrintSettingUI({
  visible,
  wordCount,
  remainWordCount,
  testSetting,
  openTooltip,
  setOpenTooltip,
  onClickClose,
  onSuccess,
  onChangeCounter,
  onClickTestCheck,
}: ModalStudySettingVAProps) {
  return (
    <Modal
      open={visible}
      onClose={() => {
        return;
      }}
    >
      <Wrapper>
        <Wrap>
          <Body>
            <HeaderIconWrap onClick={onClickClose}>
              <IoCloseSharp />
            </HeaderIconWrap>
            <SelectBox>
              <Box>
                <SelectWordCounter>
                  선택 문제 수&nbsp;<Box component={'span'}>{wordCount}개</Box>
                </SelectWordCounter>
                <RemainWordCounter>남은 단어 수 {remainWordCount}개</RemainWordCounter>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onClickTestCheck('stom', e)}
                    checked={testSetting.stom.check}
                    color='green'
                  />
                  <Box onClick={() => onClickTestCheck('stom')}>철자 → 뜻</Box>
                  <ClickAwayListener
                    onClickAway={() => {
                      setOpenTooltip([false, false]);
                    }}
                  >
                    <Box component={'span'} sx={{ ml: '0.25rem', cursor: 'pointer' }}>
                      <Tooltip
                        title={'단어 철자를 보고 뜻을 적어봅니다.'}
                        placement={'top'}
                        open={openTooltip[0]}
                        onClose={() => setOpenTooltip([false, false])}
                        onClick={() => {
                          setTimeout(() => {
                            setOpenTooltip([!openTooltip[0], false]);
                          }, 0);
                        }}
                      >
                        <Box
                          component={'span'}
                          sx={{ fontSize: '1.2rem', display: 'flex', alignItems: 'center', color: '#c1c1c1' }}
                        >
                          &nbsp;
                          <BsInfoCircle />
                        </Box>
                      </Tooltip>
                    </Box>
                  </ClickAwayListener>
                </Box>
                <CounterInputBox
                  disabled={!testSetting.stom.check}
                  sx={{ color: testSetting.stom.check ? '#9e9e9e' : '#e9e9e9' }}
                >
                  객관식&nbsp;&nbsp;
                  <input
                    disabled={!testSetting.stom.check}
                    value={testSetting.stom.multi ? testSetting.stom.multi : ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCounter(e, 'stom', 'multi')}
                  />
                  &nbsp;&nbsp;주관식&nbsp;&nbsp;
                  <input
                    disabled={!testSetting.stom.check}
                    value={testSetting.stom.short ? testSetting.stom.short : ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCounter(e, 'stom', 'short')}
                  />
                </CounterInputBox>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onClickTestCheck('mtos', e)}
                    checked={testSetting.mtos.check}
                    color='green'
                  />
                  <Box onClick={() => onClickTestCheck('mtos')}>뜻 → 철자</Box>
                  <ClickAwayListener onClickAway={() => setOpenTooltip([false, false])}>
                    <Box component={'span'} sx={{ ml: '0.25rem', cursor: 'pointer' }}>
                      <Tooltip
                        title={'단어 뜻을 보고 철자를 적어봅시다.'}
                        placement={'top'}
                        open={openTooltip[1]}
                        onClose={() => setOpenTooltip([false, false])}
                        onClick={() => {
                          setTimeout(() => {
                            setOpenTooltip([false, !openTooltip[1]]);
                          }, 0);
                        }}
                      >
                        <Box
                          component={'span'}
                          sx={{ fontSize: '1.2rem', display: 'flex', alignItems: 'center', color: '#c1c1c1' }}
                        >
                          &nbsp;
                          <BsInfoCircle />
                        </Box>
                      </Tooltip>
                    </Box>
                  </ClickAwayListener>
                </Box>
                <CounterInputBox
                  disabled={!testSetting.mtos.check}
                  sx={{ color: testSetting.mtos.check ? '#9e9e9e' : '#e9e9e9' }}
                >
                  객관식&nbsp;&nbsp;
                  <input
                    disabled={!testSetting.mtos.check}
                    value={testSetting.mtos.multi ? testSetting.mtos.multi : ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCounter(e, 'mtos', 'multi')}
                  />
                  &nbsp;&nbsp;주관식&nbsp;&nbsp;
                  <input
                    disabled={!testSetting.mtos.check}
                    value={testSetting.mtos.short ? testSetting.mtos.short : ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCounter(e, 'mtos', 'short')}
                  />
                </CounterInputBox>
              </Box>
            </SelectBox>
          </Body>
          <Footer>
            <ButtonContainer
              disabled={wordCount == remainWordCount}
              onClick={() => onSuccess()}
              variant='contained'
              color='purple'
            >
              출력 설정
            </ButtonContainer>
          </Footer>
        </Wrap>
      </Wrapper>
    </Modal>
  );
});
