import styled from '@emotion/styled';
import { Box, Button } from '@mui/material';
import theme from 'theme';

export const Wrapper = styled(Box)({
  width: '100%',
  height: '100%',
});

export const Header = styled(Box)({
  width: '100%',
  height: '3rem',
  backgroundColor: '#f5f5f5',
  borderBottom: '1px solid #edeeef',
  display: 'flex',
  '& > div:first-of-type': {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    color: 'rgba(0,0,0,.6)',
    '& > div': {
      flex: 1,
    },
  },
});

export const SwitchBox = styled(Box)(props => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  color: '#b6b6b6',
  justifyContent: 'flex-end',
  '& > .small': {
    [theme.breakpoints.up('xl')]: {
      height: 'calc(1rem + 2px)',
    },
    [theme.breakpoints.down('xl')]: {
      height: 'calc(1rem + 3px)',
    },
    '& .MuiSwitch-thumb': {
      [theme.breakpoints.up('xl')]: {
        width: 'calc(1rem - 2px)',
        height: 'calc(1rem - 2px)',
      },
      [theme.breakpoints.down('xl')]: {
        width: 'calc(1rem - 1px)',
        height: 'calc(1rem - 1px)',
      },
    },
  },
}));

export const ContentWrap = styled(Box)(props => ({
  width: '100%',
  height: 'calc(100% - 6.75rem)',
  overflowY: 'auto',
  display: 'flex',
  '& > div:first-of-type': {
    height: 'calc(100% - 6.75rem)',
    width: '100%',
  },
}));

interface WordItemProps {
  active: string;
}

export const ContentsBox = styled(Box)<WordItemProps>(props => ({
  height: '2.5rem',
  minHeight: '40px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: props.active === 'true' ? '#ecfbff' : '#fff',
  color: props.active === 'true' ? '#2196f3' : '#000',
  borderBottom: '1px solid #e8f1f4',
  cursor: 'pointer',
  padding: '0 1rem',
  position: 'relative',
  zIndex: 0,
  borderRight: '3px solid #eef0f1',
}));

export const WordBox = styled(Box)(props => ({
  width: '100%',
  maxWidth: 'calc(100% - 0.75rem)',
  textAlign: 'center',
  paddingLeft: 'calc(2.4rem + 18px)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: '2',
  wordBreak: 'break-word',
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
}));

export const Footer = styled(Box)({
  width: '100%',
  height: '3.75rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const WordDeleteButton = styled(Button)(props => ({
  width: '100%',
  height: '100%',
  color: props.disabled ? '#a7a5a5' : 'white',
  backgroundColor: props.disabled ? 'white' : '#f44336',
  borderTop: '3px solid #edeeef',
  whiteSpace: 'nowrap',
  lineHeight: '1.435rem',
  borderRadius: 0,
  fontWeight: 'bold',
  fontSize: '1rem',
  '&:hover': {
    color: props.disabled ? '#a7a5a5' : 'white',
    backgroundColor: props.disabled ? 'white' : '#f44336',
  },
}));

export const Line = styled(Box)({
  width: '3px',
  height: '100%',
  backgroundColor: '#eef0f1',
});
