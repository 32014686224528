import { useContext, useEffect, useState } from 'react';
import { ModalPaymentMethodChangeUI } from './ModalPaymentMethodChange.Presenter';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isPaymentChangeModalOpenState } from 'recoil/common/modalPaymentChange';
import { PaymentKindInfoType } from 'pages/main/Setup';
import { fetchPostApi, fetchPutApi } from 'utils/api';
import { convertPaymentFailMessage, convertRegisterCardFailMessage } from 'utils/tools';

import { userState } from 'recoil/model/user';
import { ModalContext } from 'provider/ModalProvider';

interface ModalPaymentChangeProps {
  paymentInfo: PaymentKindInfoType | undefined;
  updatePaymentInfo: () => void;
  updatePaymentItems: () => void;
}

export interface CardInfoType {
  cardNumber: string;
  month: string;
  year: string;
  birthDate: string;
  installmentMonths?: string;
}

export interface AddCardInfoType {
  paymentCompany: string;
  paymentNumber: string;
}

export function ModalPaymentMethodChangeContainer({
  paymentInfo,
  updatePaymentInfo,
  updatePaymentItems,
}: ModalPaymentChangeProps) {
  const [isPaymentChangeModalOpen, setIsPaymentChangeModalOpen] = useRecoilState(isPaymentChangeModalOpenState);
  const [isCardInfoModalOpen, setIsCardInfoModalOpen] = useState(false);
  const [userStateData] = useRecoilState(userState);
  const { modal_alert, modal_confirm } = useContext(ModalContext);

  const { customer_id } = userStateData;
  const [addCardInfo, setAddCardInfo] = useState<AddCardInfoType>();
  const [isAgree, setIsAgree] = useState(false);
  const [expanded, setExpanded] = useState('');
  const isCardPay = paymentInfo && ['AC', 'CD'].includes(paymentInfo.paymentKind);

  const [isCardInfoLoading, setIsCardInfoLoading] = useState(false);
  const [isChangePaymentLoading, setIsChangePaymentLoading] = useState(false);

  useEffect(() => {
    if (isCardPay) {
      setExpanded('card');
    } else {
      setExpanded('account');
    }
  }, [paymentInfo]);

  const handleAgreeCheckBoxClick = () => {
    setIsAgree(prev => !prev);
  };

  const handleCardInfoOpenClick = () => {
    onClose();
    setIsCardInfoModalOpen(true);
  };

  const handleCardInfoCloseClick = () => {
    setIsPaymentChangeModalOpen(true);
    setIsCardInfoModalOpen(false);
  };

  const handleChange = (panel: string) => {
    setExpanded(panel);
  };

  const onClose = () => {
    setIsPaymentChangeModalOpen(false);
    if (isCardPay) {
      setExpanded('card');
    } else {
      setExpanded('account');
    }
  };

  const onChangeCardSuccess = () => {
    updatePaymentInfo();
    updatePaymentItems();
    onClose();
  };

  // 카드 등록, 수정 api
  const postAddCardInfo = async (cardInfo: CardInfoType) => {
    const uri = `/customers/${customer_id}/payments/paymentkind/card`;

    const addCardInfo = {
      cardno: cardInfo.cardNumber,
      year: cardInfo.year.slice(-2),
      month: cardInfo.month,
      payerNumber: cardInfo.birthDate,
    };

    if (isCardPay) {
      // 카드정보 변경
      setIsChangePaymentLoading(true);
      const res = await fetchPutApi(uri, addCardInfo);
      const result = await res.json();
      setIsChangePaymentLoading(false);
      if (result.result) {
        // !toast 추후 확인 예정
        modal_alert.openModalAlert('empty', undefined, '카드정보 변경이 완료되었습니다.', onChangeCardSuccess);
        setAddCardInfo(result.data);
        setExpanded('card');
        handleCardInfoCloseClick();
      } else {
        modal_alert.openModalAlert('empty', undefined, convertPaymentFailMessage(result.messag));
      }
    } else {
      // 카드 등록 (가상계좌)
      setIsPaymentChangeModalOpen(true);
      const res = await fetchPostApi(uri, addCardInfo);
      setIsPaymentChangeModalOpen(false);
      if (res.result) {
        modal_alert.openModalAlert('empty', undefined, '카드가 등록되었습니다.', () => setExpanded('card'));
        setAddCardInfo(res.data);
        handleCardInfoCloseClick();
      } else {
        modal_alert.openModalAlert('empty', undefined, convertRegisterCardFailMessage(res.message));
      }
    }
  };

  const onAddCardInfoSubmit = () => {
    const onSuccess = async () => {
      setIsChangePaymentLoading(true);
      const uri = `/customers/${customer_id}/payments/paymentkind`;
      const res = await fetchPutApi(uri, { paymentKind: isCardPay ? 'VA' : 'AC' });
      setIsChangePaymentLoading(false);
      const result = await res.json();
      if (result.result) {
        modal_alert.openModalAlert('empty', undefined, '결제수단 변경이 완료되었습니다.', onChangeCardSuccess);
      } else {
        modal_alert.openModalAlert(
          'empty',
          undefined,
          isCardPay ? `카드 결제에 실패했습니다. 다른 카드를 등록해주세요.\r${result.message}` : `${result.message}`,
        );
      }
    };

    if (isCardPay) {
      onSuccess();
    } else {
      modal_confirm.openModalConfirm(
        'normal',
        'empty',
        onSuccess,
        undefined,
        `결제수단 변경시 미납금 ${paymentInfo?.unpaid}원이 바로 결제됩니다.`,
      );
    }
  };

  const handlePaymentButtonClick = (cardInfo: CardInfoType) => {
    if (isCardPay && paymentInfo.unpaid) {
      modal_confirm.openModalConfirm(
        'normal',
        'empty',
        () => postAddCardInfo(cardInfo),
        undefined,
        `카드정보 변경시 미납금 ${paymentInfo?.unpaid}원이 바로 결제됩니다.`,
      );
    } else {
      postAddCardInfo(cardInfo);
    }
  };

  const props = {
    onClose,
    visible: isPaymentChangeModalOpen,
    paymentInfo,
    expanded,
    handleChange,
    handleCardInfoOpenClick,
    handleCardInfoCloseClick,
    isCardInfoModalOpen,
    handlePaymentButtonClick,
    isCardInfoLoading: isCardPay ? isChangePaymentLoading : isCardInfoLoading,
    isChangePaymentLoading,
    addCardInfo,
    handleAgreeCheckBoxClick,
    isAgree,
    onAddCardInfoSubmit,
  };

  return <ModalPaymentMethodChangeUI {...props} />;
}
