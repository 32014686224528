import { useContext, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { routesState } from '../../recoil/common/routes';
import { learningState } from '../../recoil/model/learning';
import { loadingState, setPercent } from '../../recoil/common/loading';
import { userState } from '../../recoil/model/user';
import { tutorialStateData } from '../../recoil/common/tutorial';
import {
  openLoadingCircleSpinner,
  LoadingCircleSpinnerState,
  changeLoadingCircleSpinner,
} from 'recoil/common/loadingCircleSpinner';
import { imgPreload } from 'utils/tools';
import { MEMORIZE_IMGS, BASIC_IMGS, ADVANCED_IMGS, VIDEO, CHECK, CDN } from 'utils/constants';

import { styled, Box } from '@mui/material';
import dayjs from 'dayjs';

import { fetchPostApi, fetchPutApi } from '../../utils/api';
import { BASE_URL, SPEAKING } from '../../utils/constants';

import Stepper from '../../components/common/Study/SpeakStepper';

import Video from './Video';
import Check from './Check';
import Speaking from './Speaking';

import { TutorialContainer } from '../../components/common/Tutorial/Tutorial.Container';
import { ModalSelectCardContainer } from '../../components/common/ModalSelectCard/ModalSelectCard.Container';
import { ModalContext } from 'provider/ModalProvider';

const StyledLearningWrap = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
}));

function SpeakIndex() {
  const navigate = useNavigate();
  const { modal_alert } = useContext(ModalContext);
  const { visible: visibleTutorial } = useRecoilValue(tutorialStateData);
  const routesStateData = useRecoilValue<RoutesType>(routesState);
  const { root_path, child_path } = routesStateData;
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const {
    status,
    current_page,
    mod,
    learning_type,
    bingo_card,
    resultsheet,
    current_step,
    book_type,
    schedule_id,
    unit_id,
    record_id,
    first_learning,
    relearning,
    reset_learning,
    save_enabled,
  } = learningStateData;

  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const { customer_id, center_type, product } = userStateData;
  const setLoadingStateData = useSetRecoilState<LoadingType>(loadingState);
  const setLoadingCircleSpinner = useSetRecoilState(LoadingCircleSpinnerState);

  useLayoutEffect(() => {
    switch (learning_type) {
      case 1:
      case 2:
        imgPreload(MEMORIZE_IMGS);
        break;
      case 3:
        imgPreload(BASIC_IMGS);
        break;
      case 4:
        imgPreload(ADVANCED_IMGS);
        break;
    }
  }, []);

  useEffect(() => {
    if (!status || learning_type == null) {
      if (child_path == 'learning') navigate(`${BASE_URL}/${root_path}`);
      else if (root_path && child_path) navigate(`${BASE_URL}/${root_path}/${child_path}`);
      else navigate(`${BASE_URL}/`);
    } else if (['V003Y', 'V003DY', 'V003TY', 'G003Y', 'G003DY', 'G003TY'].includes(userStateData.product)) {
      setLearningStateData(prevState => ({
        ...prevState,
        status: false,
      }));
      // !toast 추후 확인 예정
      modal_alert.openModalAlert(
        userStateData.type == 11 ? 'personal_product_student_alert' : 'personal_product_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        true,
      );
      navigate(`${BASE_URL}/`);
    }
  }, []);

  const sendResultsheet = async () => {
    let mod_tmp: ModType[] = [];

    if (!resultsheet) return false;

    // 현재 모듈 정보 저장
    mod_tmp = JSON.parse(JSON.stringify(mod));
    mod_tmp[current_page].done = 1;
    mod_tmp[current_page].step = current_step;
    mod_tmp[current_page].resultsheet = resultsheet;

    let module_record_id = learningStateData.module_record_id;

    if (userStateData.id > 5) {
      if (first_learning) {
        const type = 'speak';
        const prepare_record = await fetchPostApi(
          `/customers/${customer_id}/accounts/${userStateData.id}/schedules/${schedule_id}/units/${unit_id}/records/${record_id}/modules`,
          { type: type, book_type: book_type },
        );

        if (prepare_record.result) {
          module_record_id = prepare_record.data.moduleRecordId;
          setLearningStateData(prevState => ({
            ...prevState,
            module_record_id: module_record_id,
            first_learning: false,
            save_enabled: true,
          }));
        }
      } else if (relearning) {
        const reset_record = await fetchPostApi(
          `/customers/${customer_id}/accounts/${userStateData.id}/schedules/${schedule_id}/units/${unit_id}/records/${record_id}/modules/${module_record_id}`,
          { type: 'relearning', book_type: book_type },
        );

        if (reset_record.result) {
          module_record_id = reset_record.data.moduleRecordId;
          setLearningStateData(prevState => ({
            ...prevState,
            module_record_id: module_record_id,
            relearning: false,
            save_enabled: true,
          }));
        }
      } else if (reset_learning) {
        const reset_record = await fetchPostApi(
          `/customers/${customer_id}/accounts/${userStateData.id}/schedules/${schedule_id}/units/${unit_id}/records/${record_id}/modules/${module_record_id}`,
          { type: 'reset', book_type: book_type },
        );

        if (reset_record.result) {
          setLearningStateData(prevState => ({
            ...prevState,
            reset_learning: false,
            save_enabled: true,
          }));
        }
      } else if (!save_enabled) {
        setLearningStateData(prevState => ({
          ...prevState,
          save_enabled: true,
        }));
      }
    }

    // 현재 단계 이후 완료 모듈 확인
    const incompleteModules = mod.slice(current_page + 1).filter((module: ModType) => module.done == 0);

    // 학습 단계 이후 미완료 모듈이 있는 경우
    if (incompleteModules.length > 0) {
      setPercent(setLoadingStateData, 1, '학습 데이터를 불러오는 중입니다.');
      const firstIncompleteModule = incompleteModules[0];

      setLearningStateData(prevState => ({
        ...prevState,
        mod: mod_tmp,
        current_page: firstIncompleteModule.seq,
        current_step: firstIncompleteModule.step ?? 0, // 학습 진행도 없으면 0
        resultsheet: [], // 성적표 초기화
      }));
    } else {
      // 학습 단계 이전 미완료 모듈 확인
      setPercent(setLoadingStateData, 1, '학습 데이터를 불러오는 중입니다.');
      const incompleteModulesBeforeCurrent = mod.slice(0, current_page).filter((module: ModType) => module.done == 0);

      // 이전 학습 단계에 미완료 모듈이 있을 경우
      if (incompleteModulesBeforeCurrent.length > 0) {
        const firstIncompleteModule = incompleteModulesBeforeCurrent[0];
        setLearningStateData(prevState => ({
          ...prevState,
          mod: mod_tmp,
          current_page: firstIncompleteModule.seq,
          current_step: firstIncompleteModule.step ?? 0, // 학습 진행도 없으면 0
          resultsheet: [], // 성적표 초기화
        }));
      } else {
        setPercent(setLoadingStateData, 1, '학습 데이터를 분석하는 중입니다.');
        const learning_data = JSON.parse(JSON.stringify(learningStateData));
        learning_data.mod = mod_tmp;
        learning_data.studytime = learning_data.studytime + dayjs().diff(learningStateData.starttime, 's');

        if (userStateData.id > 5) {
          const result_res = await fetchPutApi(
            `/customers/${customer_id}/accounts/${userStateData.id}/schedules/${schedule_id}/units/${unit_id}/records/${record_id}/modules/${module_record_id}/result`,
            { ...learning_data },
          );
          if (result_res.result) {
            //* (개인 + 무료체험) 장학생 혜택 횟수 + 여부 처리
            if (typeof result_res.data.scholarshipStudyCnt == 'number' && product == 'F' && center_type == 'C') {
              setUserStateData(prev => {
                const new_user_state_data = {
                  ...prev,
                  scholarshipBenefit: result_res.data.scholarshipBenefit,
                  scholarshipStudyCnt: result_res.data.scholarshipStudyCnt,
                };

                window.sessionStorage.setItem('user', JSON.parse(JSON.stringify(new_user_state_data)));

                return new_user_state_data;
              });
            }

            console.log('result_res', result_res);

            if (result_res.data.complete_schedule && center_type == 'C') {
              // * 학습 레벨업 처리
              const path =
                root_path == 'recent'
                  ? `${BASE_URL}/${root_path}/result`
                  : `${BASE_URL}/${root_path}/${child_path}/result`;
              navigate(path, {
                state: {
                  schedule_id: schedule_id,
                  unit_id: unit_id,
                  record_id: record_id,
                  module_record_id: module_record_id,
                  tab_name: learningStateData.tab_name,
                  schedule_type: learningStateData.book_type,
                  afterLearn: true,
                },
              });

              openLoadingCircleSpinner(
                { setLoadingCircleSpinner },
                8,
                '다음 학습 스케쥴을 생성 중입니다!<br />잠시만 기다려 주세요.',
                {
                  enter: 0,
                  exit: 0,
                },
              );

              const url = `/customers/${customer_id}/accounts/${userStateData.id}/target/upgrade`;
              const body_params = {
                tab_name: learning_data.tab_name,
              };
              const res = await fetchPostApi(url, body_params);

              if (res.result) {
                // * 스케쥴 배포 완료 처리
                const data = res.data;
                const content = `${data.name} 학습 스케쥴이 생성되었습니다!<br />다음 학습을 진행해주세요.`;

                let speak_study_level = userStateData.speak_study_level;
                if (data.new_study_target && data.new_study_target.study_domains.includes('speak')) {
                  const speak_domain_idx = data.new_study_target.study_domains
                    .split(',')
                    .findIndex((v: any) => v == 'speak');
                  const cur_study_level = data.new_study_target.study_level.split(',')[speak_domain_idx];
                  speak_study_level = cur_study_level;
                }

                setUserStateData(prev => ({
                  ...prev,
                  study_target: data.new_study_target,
                  speak_study_level,
                }));

                let thumbnailUrl: string | undefined = undefined;

                if (data.folder_id) {
                  // * 회화 썸네일 (레벨얼)
                  thumbnailUrl = `${CDN}cover/${data.category_id}/${data.folder_id}/${data.name}.jpg`;
                } else {
                  // * 보카 / 문법 썸네일 (레베얼)
                  thumbnailUrl = `${CDN}cover/${data.category_id}/${data.name}.png`;
                }

                changeLoadingCircleSpinner({ setLoadingCircleSpinner }, content, thumbnailUrl, true);
              }
            } else {
              setTimeout(() => {
                const path =
                  root_path == 'recent'
                    ? `${BASE_URL}/${root_path}/result`
                    : `${BASE_URL}/${root_path}/${child_path}/result`;
                navigate(path, {
                  state: {
                    schedule_id: schedule_id,
                    unit_id: unit_id,
                    record_id: record_id,
                    module_record_id: module_record_id,
                    tab_name: learningStateData.tab_name,
                    schedule_type: learningStateData.book_type,
                    afterLearn: true,
                  },
                });
              }, 0);
            }
          }
        } else {
          setLearningStateData(prevState => ({
            ...prevState,
            mod: mod_tmp,
          }));
          setTimeout(() => {
            const path =
              root_path == 'recent'
                ? `${BASE_URL}/${root_path}/result`
                : `${BASE_URL}/${root_path}/${child_path}/result`;
            navigate(path, {
              state: {
                schedule_id: schedule_id,
                unit_id: unit_id,
                record_id: record_id,
                module_record_id: module_record_id,
                tab_name: learningStateData.tab_name,
                schedule_type: learningStateData.book_type,
              },
            });
          }, 0);
        }
      }
    }
  };

  useEffect(() => {
    if (visibleTutorial) {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
    }
  }, [visibleTutorial]);

  return (
    <StyledLearningWrap>
      {visibleTutorial ? <TutorialContainer /> : false}
      {status && learning_type == 6 ? (
        <>
          <Stepper></Stepper>
          {mod && current_page >= 0
            ? (function () {
                if (mod[current_page].module == VIDEO) {
                  return <Video sendResultsheet={sendResultsheet} />;
                } else if (mod[current_page].module == CHECK) {
                  return <Check sendResultsheet={sendResultsheet} />;
                } else if (mod[current_page].module == SPEAKING) {
                  return <Speaking sendResultsheet={sendResultsheet} />;
                }
              })()
            : null}
        </>
      ) : null}
      <ModalSelectCardContainer />
    </StyledLearningWrap>
  );
}

export default SpeakIndex;
