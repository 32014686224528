import { TabList as MuiTabList } from '@mui/lab';
import { styled, Box } from '@mui/material';

export const TabWrapper = styled(Box)(() => ({
  width: '100%',
  typography: 'body1',
  height: '2.5rem',
  maxHeight: 'calc(100% - 1rem)',
  marginBottom: '0.5rem',
}));

export const TabWrap = styled(Box)(() => ({
  borderBottom: '1px solid #e5e5e5',
}));

export const TabList = styled(MuiTabList)(({ theme }) => ({
  minHeight: '2.3rem',
  '.MuiTabs-scroller': {
    '.MuiTabs-flexContainer': {
      '.MuiButtonBase-root': {
        position: 'relative',
        top: '1px',
        fontSize: '1rem',
        color: theme.palette.gray_2.main,
        padding: '0.25rem 0.75rem',
        minHeight: '2.3rem',
        letterSpacing: '-1px',
        '&.Mui-selected': {
          color: theme.palette.green.main,
        },
      },
    },
    '.MuiTabs-indicator': {
      backgroundColor: theme.palette.green.main,
    },
  },
}));
