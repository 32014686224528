import { LayoutRegistartion, RegistartionRow, RegistartionButton } from './LayoutRegistration.Styles';
import { Box } from '@mui/material';
import React from 'react';

export type { Address } from 'react-daum-postcode';
export { DaumPostcodeEmbed } from 'react-daum-postcode';
export { FaTimes } from 'react-icons/fa';
export { PostWrapper, PostCloseButton, postCodeStyle } from './LayoutRegistration.Styles';
export type { ModalRadioContentsType } from 'components/modal/ModalRadio/ModalRadio.Container';
export interface LayoutRegistrationVAProps {
  type: 'add' | 'update' | 'error' | 'check';
  originRegiContainer: Array<RegistrationDataType[]>;
  regiDatas: RegistrationDatasType;
  regibtnDisabled: boolean;
  setRegistrationInput: (regiData: RegistrationDataType) => React.ReactNode;
  onSubmit: () => void;
}

export const LayoutRegistrationUI = React.memo(function LayoutRegistrationUI({
  type,
  originRegiContainer,
  regiDatas,
  regibtnDisabled,
  setRegistrationInput,
  onSubmit,
}: LayoutRegistrationVAProps) {
  return (
    <LayoutRegistartion>
      {originRegiContainer.map((originRegiContainerDatas, idx) => {
        return (
          <RegistartionRow key={`row_${idx}`}>
            {originRegiContainerDatas.map((originRegiContainerData, idx2) => {
              const key = originRegiContainerData.key;
              return (
                <Box sx={{ width: 1, position: 'relative' }} key={`input_${idx2}`}>
                  {setRegistrationInput(regiDatas[key])}
                </Box>
              );
            })}
          </RegistartionRow>
        );
      })}
      <RegistartionButton variant='contained' color='green' onClick={onSubmit} disabled={regibtnDisabled}>
        {type == 'add' ? '등록' : type == 'error' ? '접수' : type == 'check' ? '확인' : '수정'}
      </RegistartionButton>
    </LayoutRegistartion>
  );
});
