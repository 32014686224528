export const canvasStyles: React.CSSProperties = {
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  zIndex: '1300',
  top: '0',
  left: '0',
};

export const decorateOptions = (originalOptions: any) => {
  return {
    ...originalOptions,
    particleCount: 125, // 조각 개수 설정
    spread: 300, // 퍼짐 정도 설정
    startVelocity: 60, // 초기 속도 설정
    ticks: 500, // 애니메이션 지속 시간 설정
    origin: { x: 0.5, y: 0.25 }, // 발사 위치 설정
    shapes: ['circle', 'circle', 'circle'], // 이미지 배열을 shapes로 설정
    gravity: 0.5, // 중력 설정
    scalar: 1.5, // 조각의 크기 설정
  };
};
