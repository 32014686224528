import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ModalInputPinType, modalInputPinState, closeModalInputPin } from 'recoil/common/modalInputPin';
import { styled, Box, Modal, IconButton } from '@mui/material';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { fetchGetApi, fetchPostApi } from 'utils/api';
import { profilesState, resetProfilesState, setProfilePageModifyPinCode } from 'recoil/common/profiles';
import {
  LoadingCircleSpinnerDataType,
  LoadingCircleSpinnerState,
  openLoadingCircleSpinner,
  closeLoadingCircleSpinner,
} from 'recoil/common/loadingCircleSpinner';
import { MissionType } from 'recoil/common/mission';
import { modalChec2kData, ModalCheck2DataType, openModalCheck2 } from 'recoil/common/modalCheck2';
import { userState } from 'recoil/model/user';
import { EffectSoundContext } from 'provider/EffectSoundProvider';
import { settingState } from 'recoil/model/settings';
import { PRESIDENT_OF_CENTER_TYPE, TEMP_STUDENTS_ACCOUNT_IDS } from 'utils/constants';
import { checkDuplicateLogin } from 'utils/firebase';
import { ModalContext } from 'provider/ModalProvider';
import { useNavigate } from 'react-router';
import { BackgroundSoundContext } from 'provider/BackgroundSoundProvider';
import { deviceState } from 'recoil/common/device';

const Container = styled(Box)({
  width: '25rem',
  height: '12.5rem',
  backgroundColor: '#fff',
  borderRadius: '0.75rem',
  overflow: 'hidden',
  transform: 'scale(1.1)',

  '@media (min-width: 1264px) and (max-width: 1903px)': {
    transform: 'scale(1.4)',
  },
  '@media (min-width: 960px) and (max-width: 1263px)': {
    transform: 'scale(1.4)',
  },
  '@media (min-width: 768px) and (max-width: 959px)': {
    transform: 'scale(1.5)',
  },
  '@media (min-width: 600px) and (max-width: 767px)': {
    transform: 'scale(1.2)',
  },
  '@media (max-width: 599px)': {
    transform: 'scale(1.2)',
  },
});

const Wrapper = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1.5rem',
  position: 'relative',
});

const Title = styled(Box)({
  fontSize: '1rem',
  textAlign: 'center',
});

const InputWrap = styled(Box)({
  width: '100%',
  position: 'relative',
  padding: '0 2rem',
});

const Input = styled('input')({
  outline: 'none',
  whiteSpace: 'nowrap',
  padding: '0.75rem 0 0.7rem',
  color: '#747474',
  fontWeight: '600',
  fontSize: '1rem',
  lineHeight: '1.3rem',
  borderRadius: '0.5rem',
  width: '100%',
  border: '1px solid #c4c4c4',
  textAlign: 'center',
  '&::placeholder': {
    fontWeight: '400',
    fontSize: '0.8rem',
    paddingLeft: '0.25rem',
    paddingBottom: '0',
    color: '#e4e4e4',
    textAlign: 'center',
  },
});

const FindBackButton = styled(IconButton)({
  position: 'absolute',
  top: '0.2rem',
  left: '0.2rem',
  svg: {
    width: '1.4rem',
    height: '1.4rem',
    color: '#828282',
  },
});

const NavigateBtn = styled(Box)({
  position: 'absolute',
  bottom: '0.2rem',
  right: '0.4rem',
  fontSize: '0.8rem',
  color: '#e4e4e4',
  cursor: 'pointer',
  padding: '8px',

  span: {
    fontFamily: 'NanumSquare',
  },
});

const CheckBtn = styled(Box)({
  position: 'absolute',
  right: '2.3rem',
  top: '50%',
  transform: 'translateY(-50%)',
  color: '#fff',
  backgroundColor: '#8313ff',
  borderRadius: '8px',
  fontSize: '0.9rem',
  padding: '0 1.1rem',
  height: '2.3rem',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  marginLeft: '0.5rem',
  lineHeight: '1',
  display: 'flex',
  alignItems: 'center',
});

declare let window: any;

export function ModalInputPin() {
  const navigate = useNavigate();
  const { modal_confirm } = useContext(ModalContext);
  const { playEffectSound } = useContext(EffectSoundContext);
  const { firstStart } = useContext(BackgroundSoundContext);
  const deviceStateData = useRecoilValue(deviceState);
  const { is_mobile, platform } = deviceStateData;
  const setToastBar = useSetRecoilState(toastBarState);
  const setLoadingCircleSpinner = useSetRecoilState<LoadingCircleSpinnerDataType>(LoadingCircleSpinnerState);
  const setModalCheck2 = useSetRecoilState<ModalCheck2DataType>(modalChec2kData);
  const setUserStateData = useSetRecoilState<UserType>(userState);
  const setSettingStateData = useSetRecoilState(settingState);
  const [profilesStateData, setProfileStateData] = useRecoilState(profilesState);
  const [modalInputPin, setModalInputPin] = useRecoilState<ModalInputPinType>(modalInputPinState);
  const [top, setTop] = useState<string>('0px');
  const [externalKeyboard, setExternalKeyboard] = useState<boolean>(false);
  const { visible, step, profile } = modalInputPin;
  const { customer_id } = profilesStateData;
  const [inputValue, setInputValue] = useState<string>('');
  const input_ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        input_ref.current?.focus();
      }, 0);
    } else {
      setInputValue('');
    }
  }, [visible]);

  useEffect(() => {
    if (is_mobile) {
      if (typeof window.ExternalKeyboard == 'function') window.ExternalKeyboard.checkOnce();
      if (window.ExternalKeyboard.isUsed) setExternalKeyboard(true);
    }
  }, [is_mobile]);

  const title = useMemo(() => {
    if (step == 2) return `${profile?.name} 님의 <b>PIN 번호</b> 초기화를 위해<br /><b>비밀번호</b>를 입력해주세요.`;
    return `<span>${profile?.name} 님의 <span style='font-weight : 600;'>PIN 번호</span>를 입력해주세요.</span>`;
  }, [step, profile]);

  const onChangeInputValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      if (step == 1) {
        if (/^\d*$/.test(inputValue)) {
          setInputValue(inputValue);
        } else {
          openToastBar({ setToastBar }, '숫자만 입력해주세요.', 'red', 2000);
        }
      } else {
        setInputValue(inputValue);
      }
    },
    [step],
  );

  const onClickNavigateBtn = useCallback(() => {
    setInputValue('');
    setModalInputPin(prev => ({
      ...prev,
      step: 2,
    }));
  }, []);

  const onClickBackButton = useCallback(() => {
    if (step == 1) {
      closeModalInputPin({ setModalInputPin });
    } else {
      setInputValue('');
      setModalInputPin(prev => ({
        ...prev,
        step: 1,
      }));
    }
  }, [step]);

  const direction = useMemo(() => {
    return step == 1 ? 'right' : 'left';
  }, [step]);

  const setLoginUser = async (info: {
    center_id: number;
    center_name: string;
    id: number;
    logo: string;
    name: string;
    profile: string;
    profile_type: string;
    product: string;
    type: number;
    class: ClassType[];
    postdate: string;
    show_tutorial: ShowTutorial;
    mission?: MissionType;
    effect_sound: number;
    series_filter: number;
    default_profile: string;
    study_target: StudyTarget | null;
    center_type: string;
    startdate: string;
    enddate: string;
    is_promotion: number;
    personal_product: string;
    product_name: string;
    isJoinEscape: boolean | null;
    recomm_code: number;
    code: string;
    scholarshipBenefit: boolean;
    scholarshipStudyCnt: number;
    grade: string;
    loginid: string;
    isMainProfile: StringBooleanType;
    intend_product: boolean;
    printCount: number;
    studyAlim: StringBooleanType;
  }) => {
    let speak_study_level: string | null = null;

    if (info.study_target && info.study_target.study_domains.includes('speak')) {
      const speak_domain_idx = info.study_target.study_domains.split(',').findIndex(v => v == 'speak');
      const cur_study_level = info.study_target.study_level.split(',')[speak_domain_idx];
      speak_study_level = cur_study_level;
    }

    const payload: UserType = {
      customer_id: info.center_id,
      center_name: info.center_name,
      center_type: info.center_type,
      id: info.id,
      logo: info.logo,
      name: info.name,
      profile: info.profile,
      profile_type: info.profile_type,
      product: info.product,
      type: info.type,
      class: info.class,
      pass: true,
      postdate: info.postdate,
      show_tutorial: info.show_tutorial,
      default_profile: info.default_profile,
      study_target: info.study_target,
      is_promotion: info.is_promotion,
      startdate: info.startdate,
      enddate: info.enddate,
      personal_product: info.personal_product,
      product_name: info.product_name,
      isJoinEscape: info.isJoinEscape,
      recomm_code: info.recomm_code,
      code: info.code,
      scholarshipBenefit: info.scholarshipBenefit,
      scholarshipStudyCnt: info.scholarshipStudyCnt,
      grade: info.grade,
      loginid: info.loginid,
      isMainProfile: info.center_type == 'C' && info.type > 20 ? 'Y' : 'N',
      intend_product: info.intend_product,
      printCount: 0,
      studyAlim: info.studyAlim,
      speak_study_level,
    };

    const success_cb = () => {
      openModalCheck2({ setModalCheck2 }, 'duplicate_logout');
    };

    const successCallback = async () => {
      setTimeout(async () => {
        setUserStateData(() => ({
          ...payload,
        }));
        setSettingStateData(prevState => ({
          ...prevState,
          effect_sound: !!info.effect_sound,
          series_filter: !!info.series_filter,
        }));
        window.sessionStorage.setItem('user', JSON.stringify(payload));
        closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
        firstStart();
        navigate('/');
        resetProfilesState({ setProfileStateData });
        playEffectSound('after_login');
        openToastBar({ setToastBar }, `${payload.name}님 오래영어에 오신 것을 환영합니다.`, '#3f3f3f', 2000);
      }, 200);
    };

    // 추가적으로 임시 학생 데이터도 안하게 처리 필요
    if (payload.type !== PRESIDENT_OF_CENTER_TYPE && !TEMP_STUDENTS_ACCOUNT_IDS.includes(payload.id)) {
      const res = await checkDuplicateLogin(payload.id, success_cb);
      if (res === 'DUP') {
        // 중복 로그인 팝업 띄움
        modal_confirm.openModalConfirm(
          'normal',
          'duplicate_login',
          async () => {
            successCallback();
          },
          undefined,
          undefined,
          true,
        );
        return;
      }
    }

    successCallback();
  };

  const validatePinCode = useCallback(async () => {
    const args = {
      pin_code: inputValue,
    };

    try {
      const res = await fetchPostApi(`/customers/${customer_id}/profile/${profile?.id}/check`, args);
      if (res.result) {
        closeModalInputPin({ setModalInputPin });
        openLoadingCircleSpinner({ setLoadingCircleSpinner }, 8, '오래영어 학습을 불러오는 중입니다.');
        const res = await fetchGetApi(`/customers/${customer_id}/profile/${profile?.id}/${profile?.type}`);
        if (res.result) {
          setLoginUser(res.data);
        } else {
          if (res.message == '사용 중지 학원') {
            openToastBar({ setToastBar }, '현재 학습 사용중지 상태입니다.', 'red', 2000);
          } else {
            openToastBar({ setToastBar }, '로그인에 실패하였습니다.', 'red', 2000);
          }
          closeLoadingCircleSpinner({ setLoadingCircleSpinner });
        }
      } else {
        openToastBar({ setToastBar }, 'PIN 번호가 일치하지 않습니다.', 'red', 2000);
      }
    } catch (error) {
      console.error(error);
      closeLoadingCircleSpinner({ setLoadingCircleSpinner });
      openToastBar({ setToastBar }, '오류가 발생했습니다.', 'red', 2000);
    }
  }, [inputValue]);

  const requestInitPinCode = useCallback(async () => {
    const args = {
      password: inputValue,
    };
    try {
      const res = await fetchPostApi(`/customers/${customer_id}/profile/${profile?.id}/code/init`, args);

      if (res.result) {
        closeModalInputPin({ setModalInputPin });
        setProfilePageModifyPinCode({ setProfileStateData }, profile);
      } else {
        openToastBar({ setToastBar }, '패스워드가 일치하지 않습니다.', 'red', 2000);
      }
    } catch (error) {
      openToastBar({ setToastBar }, '오류가 발생했습니다.', 'red', 2000);
    }
  }, [inputValue]);

  const onClickCheckBtn = useCallback(async () => {
    if (step == 1) {
      await validatePinCode();
    } else {
      await requestInitPinCode();
    }
  }, [step, validatePinCode, requestInitPinCode]);

  const onClickKeyDown = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key == 'Enter') {
        if (step == 1) {
          await validatePinCode();
        } else {
          await requestInitPinCode();
        }
      }
    },
    [step, validatePinCode, requestInitPinCode],
  );

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform == 'android') {
      setTop('-38vh');
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform == 'android') {
      setTop('0px');
    }
  };

  return (
    <Modal
      open={visible}
      onClose={() => ''}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '1300',
        '& > .MuiBox-root': {
          '&:focus-visible': {
            outline: 'none',
          },
        },
      }}
    >
      <Container sx={{ marginTop: `${top}` }}>
        <TransitionGroup style={{ width: '100%', height: '100%' }}>
          <CSSTransition
            key={step}
            timeout={300}
            classNames={direction == 'left' ? 'step-slide-left' : 'step-slide-right'}
          >
            <Wrapper>
              <FindBackButton onClick={onClickBackButton}>
                <HiOutlineArrowSmLeft />
              </FindBackButton>
              <Title dangerouslySetInnerHTML={{ __html: title }}></Title>
              <InputWrap>
                <Input
                  key={step}
                  type={step == 2 ? 'password' : 'text'}
                  maxLength={step == 2 ? 20 : 8}
                  placeholder='입력 후 확인을 눌러주세요'
                  value={inputValue}
                  onChange={onChangeInputValue}
                  ref={input_ref}
                  onKeyDown={onClickKeyDown}
                  autoComplete='one-time-code'
                  onFocus={onFocus}
                  onBlur={onBlur}
                />
                {inputValue ? <CheckBtn onClick={onClickCheckBtn}>확인</CheckBtn> : null}
              </InputWrap>
              {step == 1 ? (
                <NavigateBtn onClick={onClickNavigateBtn}>
                  {'초기화 '}
                  <span>{'>'}</span>
                </NavigateBtn>
              ) : null}
            </Wrapper>
          </CSSTransition>
        </TransitionGroup>
      </Container>
    </Modal>
  );
}
