import { useCallback } from 'react';
import { ModalServicePaymentNoticeUI } from './ModalServicePaymentNotice.Presenter';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  modalServiceNoticeState,
  ModalServiceNoticeType,
  closeModalServiceNotice,
} from 'recoil/common/modalServiceNotice';
import { accountInfoState, AccountInfoType } from 'recoil/model/accountInfo';
import { profilesState } from 'recoil/common/profiles';

export const ModalServicePaymentNoticeContainer = () => {
  const [modalServiceNotice, setModalServiceNotice] = useRecoilState<ModalServiceNoticeType>(modalServiceNoticeState);
  const accountInfo = useRecoilValue<AccountInfoType>(accountInfoState);
  const profilesStateData = useRecoilValue(profilesState);
  const { admin_real_stop } = profilesStateData;

  const onClose = useCallback(() => {
    closeModalServiceNotice({ setModalServiceNotice });
  }, []);

  const props = {
    onClose,
    admin_real_stop,
    accountInfo,
  };

  if (modalServiceNotice.visible && accountInfo != null) return <ModalServicePaymentNoticeUI {...props} />;

  return null;
};
