import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { atom, SetterOrUpdater, useRecoilValue } from 'recoil';

export interface ToastBarDataType {
  visible: boolean; // toast 열고 닫기
  message: string;
  time: number;
  backgroundColor?: string;
}

export const toastBarState = atom<ToastBarDataType>({
  key: 'toastBarState',
  default: {
    visible: false,
    message: '',
    time: 3000,
    backgroundColor: '#404040',
  },
});

interface ToastBarSetter {
  setToastBar: SetterOrUpdater<ToastBarDataType>;
}

export const openToastBar = (
  { setToastBar }: ToastBarSetter,
  message: string,
  backgroundColor: string,
  time: number,
) => {
  setToastBar(prevState => ({
    ...prevState,
    visible: true,
    message,
    backgroundColor,
    time,
  }));
};
