import { atom } from 'recoil';

export interface PathTypes {
  rootPath: string;
  childPath: string;
}

export const pathState = atom<PathTypes>({
  key: 'pathState',
  default: {
    rootPath: '',
    childPath: '',
  },
});
