import { ButtonGroupUI, RequiredProps, UnrequiredProps } from './ButtonGroup.Presenter';
import { useMemo } from 'react';
import React from 'react';

export interface ButtonGroupContainerProps extends RequiredProps, Partial<UnrequiredProps> {
  contentsKey?: string;
}

export const ButtonGroupContainer = React.memo(function ButtonGroupContainer({
  contents,
  onCheck,
  checkColor: c_color,
  unCheckColor: u_color,
  contentsKey,
  offColor,
  disabled,
}: ButtonGroupContainerProps) {
  const checkColor = useMemo(() => {
    if (c_color) {
      return c_color;
    } else {
      return 'green';
    }
  }, [c_color]);

  const unCheckColor = useMemo(() => {
    if (u_color) {
      return u_color;
    } else {
      return 'gray_1';
    }
  }, [u_color]);

  const props_obj = {
    contents,
    onCheck,
    checkColor,
    unCheckColor,
    offColor,
    contentsKey,
    disabled,
  };

  return <ButtonGroupUI {...props_obj} />;
});
