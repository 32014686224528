import { useEffect, useMemo } from 'react';
import { RealisticConfettiUI } from './RealisticConfetti.Presenter';
import { useRecoilState, useRecoilValue } from 'recoil';
import { confettiState, setActiveConfetti } from 'recoil/common/confetti';
import { deviceState } from 'recoil/common/device';

export function RealisticConfettiContainer() {
  const deviceStateData = useRecoilValue(deviceState);
  const { screen_width, screen_height } = deviceStateData;
  const [confetti, setConfetti] = useRecoilState(confettiState);
  const { active } = confetti;

  useEffect(() => {
    if (active) {
      const timeoutId = setTimeout(() => {
        setActiveConfetti(setConfetti, false);
        clearTimeout(timeoutId);
      }, 8000); // duration 값에 맞춰서 설정 (여기서는 4초로 설정)
      return () => clearTimeout(timeoutId);
    }
  }, [active]);

  const decorateOptions = useMemo(() => {
    let spread = 300;
    let scalar = 1.5;
    let startVelocity = 57;

    switch (true) {
      case screen_width <= 1749 && screen_width >= 1400:
        startVelocity = 54;
        break;
      case screen_width <= 1399 && screen_width >= 1264:
        startVelocity = 50;
        scalar = 1.25;
        break;
      case screen_width <= 1264 && screen_width >= 961:
        startVelocity = 45;
        spread = 280;
        scalar = 1.1;
        if (screen_height > 800) {
          scalar = 1.2;
          spread = 300;
          startVelocity = 54;
        }
        break;
      case screen_width <= 960 && screen_width >= 769:
        startVelocity = 35;
        spread = 280;
        scalar = 1;
        break;
      case screen_width <= 768 && screen_width >= 601:
        startVelocity = 30;
        spread = 280;
        scalar = 0.8;
        break;
      case screen_width <= 600:
        startVelocity = 30;
        spread = 280;
        scalar = 0.8;
        break;
    }

    return (originalOptions: any) => {
      return {
        ...originalOptions,
        particleCount: 125, // 조각 개수 설정
        ticks: 800, // 애니메이션 지속 시간 설정
        origin: { x: 0.5, y: 0.25 }, // 발사 위치 설정
        shapes: ['circle', 'circle', 'circle'], // 이미지 배열을 shapes로 설정
        gravity: 0.5, // 중력 설정
        startVelocity, // 초기 속도 설정
        spread, // 퍼짐 정도 설정
        scalar, // 조각의 크기 설정
      };
    };
  }, [screen_width, screen_height]);

  return <RealisticConfettiUI {...confetti} decorateOptions={decorateOptions} />;
}
