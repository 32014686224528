import {
  Wrapper,
  FirstWrap,
  SecondWrap,
  Line,
  Header,
  Footer,
  SortingBox,
  BookRowWrap,
  BookRow,
  DetailRow,
  Box,
  Checkbox,
  PrintBookTitle,
  PrintBookTitleBox,
  PrintUnitTitle,
  RadioGroup,
  OptionBox,
  RadioBox,
  SelectWordCounter,
  RemainWordCounter,
  Tooltip,
  CounterInputBox,
  SelectBox,
  TestInputWrap,
  StyleContainer,
  StyledWrap,
} from './BookPrint.Styles';
import ButtonContainer from 'components/button/Button';
import {
  DropdownContainerProps,
  DropdownNormalContainer,
} from 'components/common/DropdownNormal/DropdownNormal.Container';
import { SearchBarContainer, SearchBarContainerProps } from 'components/common/SearchBar/SearchBar.Container';
import { TabsNormalContainer, TabsNormalContainerProps } from 'components/common/TabsNormal/TabsNormal.Container';
import { LoadingContainer } from 'components/loading/Loading/Loading.Container';
import { Deco } from 'components/modal/ModalScopeExam/ModalScopeExam.Styles';
import { GoTriangleRight, GoTriangleDown } from 'react-icons/go';
import { Fragment } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { FiCornerRightUp } from 'react-icons/fi';
import { ImFolderOpen } from 'react-icons/im';
import { ModalStudySettingContainer } from 'components/modal/ModalStudySetting/ModalStudySetting.Container';
import { PrintContainer } from 'components/Print/Print.Container';
import { ClickAwayListener, FormControlLabel, Radio } from '@mui/material';
import { BsInfoCircle } from 'react-icons/bs';
import { TestPrintContainer } from 'components/TestPrint/TestPrint.Container';

export interface BookType {
  bookId: number;
  name: string;
}

export interface BookPrintVAProps extends TabsNormalContainerProps {
  bookList: OriginalBookDataType[] | (CustomBookDataType | FolderDataType)[];
  gradeSortProps: DropdownContainerProps;
  subSortProps: DropdownContainerProps;
  searchBook: SearchBarContainerProps;
  selectedBook: BookType;
  units: { [key: string]: UnitDataType[] };
  isLoading: boolean;
  printBooksView: {
    [key: string]: {
      id: number;
      bookName: string;
      category: number;
      units: { [key: string]: { unitId: number; unitName: string } };
    };
  };
  mode: string;
  searchBarKey: number;
  type: number;
  printType: number;
  testPrintType: number;
  testSetting: TestPrintSettingType;
  openTooltip: boolean[];
  setOpenTooltip: React.Dispatch<React.SetStateAction<boolean[]>>;
  wordCount: number;
  remainWordCount: number;
  printBookList: PrintBookListType;
  testWordList: TestPrintWordType[];
  example: TestExampleType;
  selectedCourse: string;
  onClickBook: (book: OriginalBookDataType | CustomBookDataType | FolderDataType) => void;
  onClickFolder: (folder_id: number) => void;
  onClickBookCheck: (
    e: React.ChangeEvent<HTMLInputElement>,
    book: OriginalBookDataType | CustomBookDataType | FolderDataType,
  ) => void;
  onClickBookDelete: (book: {
    id: number;
    bookName: string;
    category: number;
    units: { [key: string]: { unitId: number; unitName: string } };
  }) => void;
  onClickUnit: (
    event: React.MouseEvent<any, MouseEvent>,
    checked: boolean,
    {
      bookId,
      bookName,
      unitId,
      unitName,
    }: {
      bookId: number;
      bookName: string;
      unitId: number;
      unitName: string;
    },
  ) => void;
  onClickPrintConfirm: () => void;
  onClickBackButton: () => void;
  onClickTestCheck: (type: 'mtos' | 'stom', e?: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCounter: (e: React.ChangeEvent<HTMLInputElement>, key1: 'mtos' | 'stom', key2: 'multi' | 'short') => void;
  onChangeRadio: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickStudyContents: () => void;
  tabValue: string;
  onClickTab: (selectedTab: string) => void;
  contents: string[];
}

export function BookPrintUI({
  selectedCourse,
  mode,
  searchBarKey,
  contents,
  onClickTab,
  tabValue,
  type,
  printType,
  testPrintType,
  testSetting,
  wordCount,
  remainWordCount,
  openTooltip,
  setOpenTooltip,
  printBookList,
  bookList,
  gradeSortProps,
  subSortProps,
  searchBook,
  selectedBook,
  units,
  isLoading,
  printBooksView,
  testWordList,
  example,
  onClickBook,
  onClickBookCheck,
  onClickBookDelete,
  onClickUnit,
  onClickFolder,
  onClickPrintConfirm,
  onClickBackButton,
  onClickTestCheck,
  onChangeCounter,
  onChangeRadio,
  onClickStudyContents,
}: BookPrintVAProps) {
  const tab_props = {
    contents,
    tabValue,
    onClickTab,
  };
  const print_props = {
    type: type,
    printBookList,
    onClickBackButton: onClickBackButton,
  };

  const test_props = {
    testSetting,
    testWordList,
    testPrintType,
    example,
    onClickBackButton,
  };

  return (
    <StyleContainer>
      <StyledWrap>
        <Wrapper>
          {mode == 'print' ? (
            printType == 0 ? (
              <PrintContainer {...print_props}></PrintContainer>
            ) : (
              <TestPrintContainer {...test_props}></TestPrintContainer>
            )
          ) : null}
          <FirstWrap>
            <Header>
              {selectedCourse != '' && printType !== -1 ? (
                <span className='open-course-setting' onClick={onClickStudyContents}>
                  {'< 학습 컨텐츠'}
                </span>
              ) : null}
              교재 목록
            </Header>
            <Box sx={{ height: 'calc(100% - 3rem)' }}>
              {selectedCourse == '' || printType == -1 ? null : (
                <>
                  <TabsNormalContainer
                    {...tab_props}
                    sx={{
                      '.MuiTabs-indicator': {
                        backgroundColor: 'green.main !important',
                      },
                      height: '3rem',
                      marginBottom: '0.25rem',
                      '& > div': {
                        height: '3rem',
                        '& > div': {
                          height: '3rem',
                          '& > div': {
                            height: '3rem',
                            '& > div': {
                              height: '100%',
                              '& > button': {
                                width: 'calc(100% / 3)',
                                '&.Mui-selected': { color: 'green.main !important', fontWeight: 'bold' },
                              },
                            },
                          },
                        },
                      },
                    }}
                  />
                  <SortingBox>
                    <Box>
                      <DropdownNormalContainer
                        {...gradeSortProps}
                        color='green'
                        variant='contained'
                        useRecoil={false}
                        className='contained'
                      />
                      <DropdownNormalContainer
                        {...subSortProps}
                        color='orange_2'
                        variant='contained'
                        useRecoil={false}
                        className='contained'
                      />
                    </Box>
                    <Box>
                      <SearchBarContainer key={`searchBarKey_${searchBarKey}`} {...searchBook} />
                    </Box>
                  </SortingBox>
                  <BookRowWrap>
                    {isLoading ? (
                      <LoadingContainer sx={{ height: '100%' }} />
                    ) : bookList.length > 0 ? (
                      bookList.map((book, idx1) => (
                        <Fragment key={`bookList_${tabValue}_${idx1}`}>
                          {book.type == 'folder' ? (
                            <BookRow onClick={() => onClickFolder(book.id)}>
                              <Box>
                                <GoTriangleRight size={'0.8rem'} />
                              </Box>
                              <Box
                                component={'span'}
                                sx={{
                                  height: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {(book as FolderDataType).isBack ? (
                                  <FiCornerRightUp color='#f44336' size={20} />
                                ) : (
                                  <ImFolderOpen color='#ffeb3b' className='folder' size={20}></ImFolderOpen>
                                )}
                              </Box>
                              <div dangerouslySetInnerHTML={{ __html: book.name }}></div>
                            </BookRow>
                          ) : (
                            <>
                              {book.type == 'trial' ? (
                                <BookRow className={selectedBook.bookId == book.id ? 'active' : ''}>
                                  <Box onClick={() => onClickBook(book)}>
                                    {selectedBook.bookId == book.id ? (
                                      <GoTriangleDown size={'0.8rem'} />
                                    ) : (
                                      <GoTriangleRight size={'0.8rem'} />
                                    )}
                                  </Box>
                                  <Box>
                                    <Checkbox
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onClickBookCheck(e, book)}
                                      checked={book.checked ? true : false}
                                    />
                                  </Box>
                                  <Box sx={{ color: '#C1C1C1' }} onClick={() => onClickBook(book)}>
                                    <Box component={'span'} dangerouslySetInnerHTML={{ __html: book.name }}></Box>
                                    &nbsp;&nbsp;
                                    {
                                      <Box component={'span'} sx={{ color: '#E5E5E5 !important' }}>
                                        - &nbsp;{book.sub_name}
                                      </Box>
                                    }
                                  </Box>
                                </BookRow>
                              ) : (
                                <>
                                  <BookRow className={selectedBook.bookId == book.id ? 'active' : ''}>
                                    <Box onClick={() => onClickBook(book)}>
                                      {selectedBook.bookId == book.id ? (
                                        <GoTriangleDown size={'0.8rem'} />
                                      ) : (
                                        <GoTriangleRight size={'0.8rem'} />
                                      )}
                                    </Box>
                                    <Box>
                                      <Checkbox
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                          selectedBook.bookId == book.id ? onClickBookCheck(e, book) : onClickBook(book)
                                        }
                                        checked={book.checked ? true : false}
                                      />
                                    </Box>
                                    <div
                                      style={{ color: book.type == 'trial' ? '#C1C1C1' : 'unset' }}
                                      onClick={() => onClickBook(book)}
                                      dangerouslySetInnerHTML={{ __html: book.name }}
                                    ></div>
                                  </BookRow>
                                  {selectedBook.bookId == book.id
                                    ? units[book.id]
                                      ? units[book.id].map((unit, uIdx) => (
                                          <DetailRow
                                            key={unit.id}
                                            className={uIdx == units[book.id].length - 1 ? 'last' : ''}
                                            onClick={(event: React.MouseEvent<any, MouseEvent>) =>
                                              onClickUnit(event, unit.checked, {
                                                bookId: book.id,
                                                bookName: book.name,
                                                unitId: unit.id,
                                                unitName: unit.name,
                                              })
                                            }
                                          >
                                            <Box></Box>
                                            <Box>
                                              <Deco />
                                            </Box>
                                            <Box>
                                              <Checkbox checked={unit.checked} />
                                            </Box>
                                            <Box>{unit.name}</Box>
                                          </DetailRow>
                                        ))
                                      : null
                                    : null}
                                </>
                              )}
                            </>
                          )}
                        </Fragment>
                      ))
                    ) : (
                      <BookRow
                        sx={{ display: 'flex', color: '#C1C1C1', alignItems: 'center', justifyContent: 'center' }}
                      >
                        {searchBook.searchKeyword ? '검색된 결과가 없습니다.' : '등록된 교재가 없습니다.'}
                      </BookRow>
                    )}
                  </BookRowWrap>
                </>
              )}
            </Box>
          </FirstWrap>
          <Line />
          <SecondWrap>
            <Header sx={{ borderRadius: '0 2rem 0 0' }}>출력 목록</Header>
            <Box
              sx={{
                height:
                  printType == 0
                    ? 'calc(100% - 6.75rem)'
                    : testPrintType == 0
                    ? 'calc(100% - 21.75rem)'
                    : 'calc(100% - 10.5rem)',
                overflow: 'auto',
                paddingTop: '6px',
              }}
            >
              {Object.keys(printBooksView).map(bookId => (
                <Box key={`printBooksView_${bookId}`}>
                  <PrintBookTitleBox>
                    <PrintBookTitle>
                      <Box component={'span'}>{printBooksView[bookId].bookName}</Box>{' '}
                      <IoMdCloseCircle
                        onClick={() => onClickBookDelete(printBooksView[bookId])}
                        style={{
                          color: '#9e9e9e',
                          marginLeft: '8px',
                          cursor: 'pointer',
                        }}
                      />
                    </PrintBookTitle>
                  </PrintBookTitleBox>
                  {Object.keys(printBooksView[bookId].units).map(unitId => (
                    <PrintUnitTitle key={unitId}>
                      <Deco
                        sx={{
                          display: 'inline-block',
                          marginRight: '0.5rem',
                          borderLeft: '1px dotted #9f9f9f',
                          borderBottom: '1px dotted #9f9f9f',
                          transform: 'translateY(-50%)',
                          width: '0.75rem',
                          height: '0.75rem',
                          minHeight: '10px',
                          minWidth: '10px',
                        }}
                      />
                      <Box component={'span'}>{printBooksView[bookId].units[unitId].unitName}</Box>
                      <IoMdCloseCircle
                        onClick={(event: React.MouseEvent<any, MouseEvent>) =>
                          onClickUnit(event, true, {
                            bookId: Number(bookId),
                            bookName: printBooksView[bookId].bookName,
                            unitId: Number(unitId),
                            unitName: printBooksView[bookId].units[unitId].unitName,
                          })
                        }
                        style={{
                          color: '#9e9e9e',
                          marginLeft: '8px',
                          cursor: 'pointer',
                        }}
                      />
                    </PrintUnitTitle>
                  ))}
                </Box>
              ))}
            </Box>
            {Object.keys(printBooksView).length > 0 ? (
              <>
                {printType == 1 ? (
                  <OptionBox sx={{ height: testPrintType == 0 ? '15rem' : '3.75rem' }}>
                    {testPrintType == 0 ? (
                      <SelectBox>
                        <Box className='header-wrap'>
                          <SelectWordCounter>
                            선택 문제 수&nbsp;<Box component={'span'}>{wordCount}개</Box>
                          </SelectWordCounter>
                          <RemainWordCounter>남은 단어 수 {remainWordCount}개</RemainWordCounter>
                        </Box>
                        <TestInputWrap>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onClickTestCheck('stom', e)}
                              checked={testSetting.stom.check}
                              color='green'
                            />
                            <Box onClick={() => onClickTestCheck('stom')}>철자 → 뜻</Box>
                            <ClickAwayListener
                              onClickAway={() => {
                                setOpenTooltip([false, false]);
                              }}
                            >
                              <Box component={'span'} sx={{ ml: '0.25rem', cursor: 'pointer' }}>
                                <Tooltip
                                  title={'단어 철자를 보고 뜻을 적어봅니다.'}
                                  placement={'top'}
                                  open={openTooltip[0]}
                                  onClose={() => setOpenTooltip([false, false])}
                                  onClick={() => {
                                    setTimeout(() => {
                                      setOpenTooltip([!openTooltip[0], false]);
                                    }, 0);
                                  }}
                                >
                                  <Box
                                    component={'span'}
                                    sx={{ fontSize: '1.2rem', display: 'flex', alignItems: 'center', color: '#c1c1c1' }}
                                  >
                                    &nbsp;
                                    <BsInfoCircle />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </ClickAwayListener>
                          </Box>
                          <CounterInputBox
                            disabled={!testSetting.stom.check}
                            sx={{ color: testSetting.stom.check ? '#9e9e9e' : '#e9e9e9' }}
                          >
                            객관식&nbsp;&nbsp;
                            <input
                              disabled={!testSetting.stom.check}
                              value={testSetting.stom.multi ? testSetting.stom.multi : ''}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCounter(e, 'stom', 'multi')}
                            />
                            &nbsp;&nbsp;주관식&nbsp;&nbsp;
                            <input
                              disabled={!testSetting.stom.check}
                              value={testSetting.stom.short ? testSetting.stom.short : ''}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCounter(e, 'stom', 'short')}
                            />
                          </CounterInputBox>
                        </TestInputWrap>
                        <TestInputWrap>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onClickTestCheck('mtos', e)}
                              checked={testSetting.mtos.check}
                              color='green'
                            />
                            <Box onClick={() => onClickTestCheck('mtos')}>뜻 → 철자</Box>
                            <ClickAwayListener onClickAway={() => setOpenTooltip([false, false])}>
                              <Box component={'span'} sx={{ ml: '0.25rem', cursor: 'pointer' }}>
                                <Tooltip
                                  title={'단어 뜻을 보고 철자를 적어봅시다.'}
                                  placement={'top'}
                                  open={openTooltip[1]}
                                  onClose={() => setOpenTooltip([false, false])}
                                  onClick={() => {
                                    setTimeout(() => {
                                      setOpenTooltip([false, !openTooltip[1]]);
                                    }, 0);
                                  }}
                                >
                                  <Box
                                    component={'span'}
                                    sx={{ fontSize: '1.2rem', display: 'flex', alignItems: 'center', color: '#c1c1c1' }}
                                  >
                                    &nbsp;
                                    <BsInfoCircle />
                                  </Box>
                                </Tooltip>
                              </Box>
                            </ClickAwayListener>
                          </Box>
                          <CounterInputBox
                            disabled={!testSetting.mtos.check}
                            sx={{ color: testSetting.mtos.check ? '#9e9e9e' : '#e9e9e9' }}
                          >
                            객관식&nbsp;&nbsp;
                            <input
                              disabled={!testSetting.mtos.check}
                              value={testSetting.mtos.multi ? testSetting.mtos.multi : ''}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCounter(e, 'mtos', 'multi')}
                            />
                            &nbsp;&nbsp;주관식&nbsp;&nbsp;
                            <input
                              disabled={!testSetting.mtos.check}
                              value={testSetting.mtos.short ? testSetting.mtos.short : ''}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeCounter(e, 'mtos', 'short')}
                            />
                          </CounterInputBox>
                        </TestInputWrap>
                      </SelectBox>
                    ) : null}
                    <RadioBox>
                      <RadioGroup onChange={onChangeRadio} value={testPrintType}>
                        <FormControlLabel
                          style={{ pointerEvents: 'none', paddingRight: '0.75vw' }}
                          value={0}
                          label={'서술형 문제'}
                          labelPlacement='end'
                          control={<Radio style={{ pointerEvents: 'none' }} disableRipple={true} color='green' />}
                          sx={{ span: { pointerEvents: 'auto' } }}
                        />
                        <FormControlLabel
                          style={{ pointerEvents: 'none', paddingLeft: '0.75vw' }}
                          value={1}
                          label={'워크시트 형'}
                          labelPlacement='end'
                          control={<Radio style={{ pointerEvents: 'none' }} disableRipple={true} color='green' />}
                          sx={{ span: { pointerEvents: 'auto' } }}
                        />
                      </RadioGroup>
                    </RadioBox>
                  </OptionBox>
                ) : null}
                <Footer>
                  <ButtonContainer
                    disabled={printType == 1 && testPrintType == 0 && wordCount == remainWordCount}
                    onClick={() => onClickPrintConfirm()}
                    variant='contained'
                    color='purple'
                    sx={{
                      borderRadius: '0 0 2rem 0 !important',
                    }}
                  >
                    출력 설정
                  </ButtonContainer>
                </Footer>
              </>
            ) : null}
          </SecondWrap>
          <ModalStudySettingContainer />
        </Wrapper>
      </StyledWrap>
    </StyleContainer>
  );
}
