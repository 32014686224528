import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PrintWordlistUI } from './PrintWordlist.Presenter';
import { ModalContext } from 'provider/ModalProvider';

export interface PrintWordlistContainerProps {
  duplicate: boolean;
  originalWordList: PrintBookListType;
  printBookWordList: PrintBookListType;
  setOriginalWordList: React.Dispatch<React.SetStateAction<PrintBookListType>>;
  onChangeDuplicate: () => void;
  printMethod?: string;
}

export const PrintWordlistContainer = React.memo(function PrintWordlistContainer({
  duplicate,
  originalWordList,
  printBookWordList,
  setOriginalWordList,
  onChangeDuplicate,
  printMethod,
}: PrintWordlistContainerProps) {
  const { modal_confirm, modal_alert } = useContext(ModalContext);
  const [printWordList, setPrintWordList] = useState<PrintBookListType>({});
  const [checkCount, setCheckCount] = useState(0);
  const [lastClickWord, setLastClickWord] = useState<{
    active: boolean;
    bookId: number;
    unitId: number;
    wordId: number | string;
    seq: number;
    idx: number;
  } | null>(null);

  useEffect(() => {
    if (Object.keys(printBookWordList).length > 0) {
      const tmpPrintWordList = JSON.parse(JSON.stringify(printBookWordList)) as PrintBookListType;
      let idx = 0;
      Object.values(tmpPrintWordList).forEach(printBook => {
        Object.values(printBook.units).forEach(printUnit => {
          for (let i = 0; i < printUnit.words.length; i++) {
            printUnit.words[i].idx = idx;
            idx++;
          }
        });
      });
      setPrintWordList(tmpPrintWordList);
      setLastClickWord(null);
    }
  }, [printBookWordList, duplicate]);

  const wordCount = useMemo(() => {
    let count = 0;
    for (const printBookKey in printWordList) {
      const printBook = printWordList[printBookKey];
      for (const unitKey in printBook.units) {
        const unit = printBook.units[unitKey];
        count += unit.words.length;
      }
    }
    return count;
  }, [printWordList]);

  const onClickCheckbox = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement>,
      active: boolean,
      bookId: number,
      unitId: number,
      wordId: number | string,
      seq: number,
      idx?: number,
    ) => {
      const click_word = {
        active,
        bookId,
        unitId,
        wordId,
        seq,
        idx: idx ? idx : 0,
      };
      setLastClickWord(click_word);
      if (e.shiftKey && lastClickWord) {
        if (lastClickWord.idx == click_word.idx) {
          onClickWord(bookId, unitId, wordId, seq);
        } else if (lastClickWord.idx > click_word.idx) {
          onClickWords(click_word, lastClickWord, lastClickWord.active);
        } else {
          onClickWords(lastClickWord, click_word, lastClickWord.active);
        }
      } else {
        onClickWord(bookId, unitId, wordId, seq);
      }
    },
    [printWordList, lastClickWord],
  );

  const onClickWord = useCallback(
    (bookId: number, unitId: number, wordId: number | string, seq: number) => {
      const bookUnitKey = `${bookId}_${unitId}`;
      const book = printWordList[bookUnitKey];
      const unit = book?.units[unitId];
      const word = unit?.words.find(word => word.id === wordId && word.seq == seq);

      if (word) {
        const updatedPrintWordList = {
          ...printWordList,
          [bookUnitKey]: {
            ...book,
            units: {
              ...book.units,
              [unitId]: {
                ...unit,
                words: unit.words.map(w =>
                  w.id === wordId && w.seq == seq ? { ...word, active: word.active ? false : true } : w,
                ),
              },
            },
          },
        };

        const activeCount = Object.values(updatedPrintWordList).reduce(
          (count, printBook) =>
            count +
            Object.values(printBook.units).reduce(
              (unitCount, printUnit) => unitCount + printUnit.words.filter(word => word.active).length,
              0,
            ),
          0,
        );

        setPrintWordList(updatedPrintWordList);
        setCheckCount(activeCount);
      }
    },
    [printWordList],
  );

  const onClickWords = useCallback(
    (
      firstWord: { active: boolean; bookId: number; unitId: number; wordId: number | string; seq: number; idx: number },
      lastWord: { active: boolean; bookId: number; unitId: number; wordId: number | string; seq: number; idx: number },
      active: boolean,
    ) => {
      const updatedPrintWordList = printWordList;

      Object.values(updatedPrintWordList).forEach(printBook => {
        Object.values(printBook.units).forEach(printUnit => {
          printUnit.words.map(word => {
            if (word.idx != undefined && word.idx >= firstWord.idx && word.idx <= lastWord.idx) word.active = !active;
          });
        });
      });

      const activeCount = Object.values(updatedPrintWordList).reduce(
        (count, printBook) =>
          count +
          Object.values(printBook.units).reduce(
            (unitCount, printUnit) => unitCount + printUnit.words.filter(word => word.active).length,
            0,
          ),
        0,
      );

      setPrintWordList(updatedPrintWordList);
      setCheckCount(activeCount);
    },
    [printWordList],
  );

  const onClickWordDelete = useCallback(() => {
    if (checkCount > 0) {
      if (checkCount == wordCount) {
        modal_alert.openModalAlert('do_not_print_word_delete');
      } else {
        modal_confirm.openModalConfirm(
          'normal_reverse',
          'delete_contents_2',
          () => {
            return;
          },
          () => {
            originalWordList;
            for (const printBookKey in printWordList) {
              const printBook = printWordList[printBookKey];
              for (const unitKey in printBook.units) {
                const unit = printBook.units[unitKey];
                for (let i = 0; i < unit.words.length; i++) {
                  if (unit.words[i].active) {
                    const idx = originalWordList[printBookKey].units[unitKey].words.findIndex(
                      word => word.id == unit.words[i].id && word.seq == unit.words[i].seq,
                    );
                    if (idx > -1) originalWordList[printBookKey].units[unitKey].words[idx].active = true;
                  }
                }
                const newWords = originalWordList[printBookKey].units[unitKey].words.filter(word => !word.active);
                originalWordList[printBookKey].units[unitKey].words = newWords;
              }
            }
            modal_alert.openModalAlert('success_delete', undefined, '선택된 단어가');
            setOriginalWordList({ ...originalWordList });
            setLastClickWord(null);
          },
          '선택 단어를 목록에서',
        );
      }
    }
  }, [originalWordList, printWordList, checkCount]);

  const props = {
    wordCount,
    checkCount,
    printBookList: printWordList,
    duplicate,
    onChangeDuplicate,
    onClickCheckbox,
    onClickWordDelete,
    printMethod,
  };
  return <PrintWordlistUI {...props} />;
});
