import React from 'react';
import {
  BackIconBox,
  Body,
  Container,
  FirstWrap,
  Header,
  IconWrap,
  SecondWrap,
  ThirdWrap,
  Wrapper,
  BackIcon,
} from './TestPrint.Styles';
import { PrintWordlistContainer } from './Wordlist/PrintWordlist.Container';
import { PrintSettingContainer } from './Setting/PrintSetting.Container';
import { WordWorksheetContainer } from './Worksheet/WordWorksheet/WordWorksheet.Container';
import { ModalTestPrintSettingContainer } from 'components/modal/ModalTestPrintSetting/ModalTestPrintSetting.Container';
import { TestWorksheetContainer } from './Worksheet/TestWorksheet/TestWorksheet.Container';

export interface TestPrintVAProps {
  title: string;
  printSetting: PrintSettingType;
  printInfo: PrintInfoType;
  printRef: React.MutableRefObject<any>;
  originalTestWordList: TestPrintWordType[];
  selectWordList: TestPrintWordType[];
  setOriginalTestWordList: React.Dispatch<React.SetStateAction<TestPrintWordType[]>>;
  onClickPrintType: () => void;
  onClickBackButton: () => void;
  onClickWordPrint: () => void;
  onChangePrintSetting: (key: string, value: number) => void;
  onChangePrintInfo: (key: string, value: boolean) => void;
  onChangeTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TestPrintUI = React.memo(function PrintUI({
  title,
  printSetting,
  printInfo,
  printRef,
  originalTestWordList,
  selectWordList,
  setOriginalTestWordList,
  onClickBackButton,
  onClickWordPrint,
  onChangePrintSetting,
  onChangePrintInfo,
  onChangeTitle,
  onClickPrintType,
}: TestPrintVAProps) {
  const wordlist_props = {
    originalTestWordList,
    setOriginalTestWordList,
    selectWordList,
    printSetting,
  };
  const test_wortksheet_props = {
    title,
    printSetting,
    printInfo,
    selectWordList,
    printRef,
  };
  const worksheet_props = {
    title,
    printSetting,
    printInfo,
    originalTestWordList,
    printRef,
  };
  const setting_props = {
    title,
    printSetting,
    printInfo,
    onClickWordPrint,
    onChangePrintSetting,
    onChangePrintInfo,
    onChangeTitle,
    onClickPrintType,
  };
  return (
    <Wrapper>
      <Container>
        <Header className='nonprint'>
          <IconWrap>
            <BackIconBox onClick={onClickBackButton}>
              <BackIcon />
            </BackIconBox>
          </IconWrap>
        </Header>
        <Body>
          <FirstWrap className='nonprint'>
            <PrintWordlistContainer {...wordlist_props} />
          </FirstWrap>
          <SecondWrap>
            {printSetting.type == 0 ? (
              <TestWorksheetContainer {...test_wortksheet_props} />
            ) : (
              <WordWorksheetContainer {...worksheet_props} />
            )}
          </SecondWrap>
          <ThirdWrap className='nonprint'>
            <PrintSettingContainer {...setting_props} />
          </ThirdWrap>
        </Body>
      </Container>
      <ModalTestPrintSettingContainer />
    </Wrapper>
  );
});
