import { TabsNormalUI, TabsNormalVAProps } from './TabsNormal.Presenter';
import { useCallback } from 'react';
import React from 'react';

export interface TabsNormalContainerProps extends Omit<TabsNormalVAProps, 'onChangeTabList'> {
  onClickTab: (selectedTab: string) => void;
}

// tab 바뀔 때, api 요청 필요
export const TabsNormalContainer = React.memo(function TabsNormalContainer({
  tabValue,
  contents,
  onClickTab,
  sx,
}: TabsNormalContainerProps) {
  const onChangeTabList = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      onClickTab(newValue);
    },
    [onClickTab],
  );

  const props_obj = {
    tabValue,
    contents,
    sx,
    onChangeTabList,
  };

  return <TabsNormalUI {...props_obj} />;
});
