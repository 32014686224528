import React, { useMemo, useCallback, useEffect } from 'react';
import { LoadingCircleSpinnerPresenters } from './LoadingCircleSpinnerPresenters';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  LoadingCircleSpinnerDataType,
  LoadingCircleSpinnerState,
  closeLoadingCircleSpinner,
} from 'recoil/common/loadingCircleSpinner';
import { loadingState } from 'recoil/common/loading';
import { confettiState, setActiveConfetti } from 'recoil/common/confetti';

export const LoadingCircleSpinnerContainer = React.memo(function LoadingCircleSpinnerContainer() {
  const [loadingCircleSpinner, setLoadingCircleSpinner] =
    useRecoilState<LoadingCircleSpinnerDataType>(LoadingCircleSpinnerState);
  const loadingStateData = useRecoilValue<LoadingType>(loadingState);
  const [confetti, setConfetti] = useRecoilState(confettiState);
  const { percent } = loadingStateData;

  const zIndex = useMemo(() => {
    if (percent != 0) return '-1300';
    else return '1300';
  }, [percent]);

  const onClose = useCallback(() => {
    closeLoadingCircleSpinner({ setLoadingCircleSpinner });
    setActiveConfetti(setConfetti, false);
  }, []);

  useEffect(() => {
    if (loadingCircleSpinner.thumbnailUrl) {
      setActiveConfetti(setConfetti, true);

      setTimeout(() => {
        setActiveConfetti(setConfetti, false);
      }, 10000);
    }
  }, [loadingCircleSpinner.thumbnailUrl]);

  const props_obj = {
    visible: loadingCircleSpinner.visible,
    ballCount: loadingCircleSpinner.ballCount,
    content: loadingCircleSpinner.content,
    timeout: loadingCircleSpinner.timeout,
    thumbnailUrl: loadingCircleSpinner.thumbnailUrl,
    isSpeak: loadingCircleSpinner.isSpeak,
    active: confetti.active,
    zIndex,
    onClose,
  };

  return <LoadingCircleSpinnerPresenters {...props_obj} />;
});
