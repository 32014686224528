import { Routes, Route, Navigate, useNavigate } from 'react-router';
import { Suspense, lazy, useContext, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { routesState } from 'recoil/common/routes';
import { ModalContext } from 'provider/ModalProvider';
import { userState } from 'recoil/model/user';
import { BASE_URL } from 'utils/constants';

const Study = lazy(() => import('./Study'));
const School = lazy(() => import('./School'));
const Speak = lazy(() => import('./Speak'));
const Grammar = lazy(() => import('./Grammar'));

function ScheduleIndex() {
  const navigate = useNavigate();
  const { modal_alert } = useContext(ModalContext);
  const routesStateData = useRecoilValue<RoutesType>(routesState);
  const userStateData = useRecoilValue<UserType>(userState);
  const { path } = routesStateData;

  useEffect(() => {
    if (
      (path == '/book/speak' &&
        ['V003Y', 'V003DY', 'V003TY', 'G003Y', 'G003DY', 'G003TY'].includes(userStateData.product)) ||
      (['/book/study', '/book/school'].includes(path) &&
        ['S003Y', 'S003DY', 'S003TY', 'G003Y', 'G003DY', 'G003TY'].includes(userStateData.product)) ||
      (path == '/book/grammar' &&
        ['S003Y', 'S003DY', 'S003TY', 'V003Y', 'V003DY', 'V003TY'].includes(userStateData.product))
    ) {
      navigate(`${BASE_URL}/`);
      // !toast 추후 확인 예정
      modal_alert.openModalAlert(
        userStateData.type == 11 ? 'personal_product_student_alert' : 'personal_product_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        true,
      );
    }
  }, [path]);
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path='/' element={<Navigate to='/book/study' />} />
        <Route path='/study' element={<Study />} />
        <Route path='/school' element={<School />} />
        <Route path='/speak' element={<Speak />} />
        <Route path='/grammar' element={<Grammar />} />
      </Routes>
    </Suspense>
  );
}

export default ScheduleIndex;
