import { styled, Modal as MuiModal, Box, Button, BoxProps } from '@mui/material';
import { ButtonContainer } from '../Button/Button.Container';
export { Box } from '@mui/material';

export const Modal = styled(MuiModal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '1300',
});

export const ModalBox = styled(Box)({
  transform: 'scale(0.88)',
  width: '60rem',
  height: 'auto',
  backgroundColor: 'white',
  borderRadius: '1.5rem',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  letterSpacing: '-0.0266rem',

  '@media (max-width: 769px)': {
    transform: 'scale(0.725)',
  },
});

export const ModalHeader = styled(Box)({
  transform: 'scale(1.002)',
  width: '100%',
  height: '4rem',
  backgroundColor: '#6b20e9',
  color: 'white',
  padding: '0px 1rem',
  borderRadius: '1.5rem 1.5rem 0 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.2rem',
  textAlign: 'center',
  position: 'absolute',
  top: '-5%',
});

export const ModalHeaderIconWrap = styled(Box)(() => ({
  right: '1rem',
  position: 'absolute',
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: 'white',
  color: 'rgb(107, 32, 233)',
  svg: {
    width: '1.6rem',
    height: '1.6rem',
    strokeWidth: 30,
    marginRight: '-1px',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@media  (max-width: 959px)': {
    width: '2.75rem',
    height: '2.75rem',
    svg: {
      width: '2.65rem',
      height: '2.65rem',
    },
  },
  '@media (max-width: 767px)': {
    width: '2.25rem',
    height: '2.25rem',
    svg: {
      width: '2rem',
      height: '2rem',
    },
  },
}));

export const ModalContentWrapper = styled(Box)({
  padding: '3.5rem 1.75rem 1.5rem 1.75rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  overflow: 'hidden',
  position: 'relative',
  flexDirection: 'column',
});

export const Title = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  fontSize: '2rem',
  fontWeight: '700',
  marginBottom: '2rem',
  letterSpacing: '-1px',
});

export const SelectedTitle = styled(Box)({
  fontSize: '1.2rem',
  fontWeight: '700',
  marginBottom: '0.5rem',
});

export const SelectedInfo = styled(Box)(props => ({
  width: '100%',
  display: 'flex',
  alignItems: 'baseline',
  padding: '1.5rem 2rem',
  border: '1px solid #f5f5f5',
  fontSize: '1.2rem',
  fontWeight: '700',
  backgroundColor: props.className === 'payment-info' ? '#f8f8f8' : '',
  color: props.className === 'price' ? 'red' : '',
  marginBottom: '1rem',
  '& > span': {
    color: props.theme.palette.purple.main,
  },
}));

interface SelectedFinalInfoProps extends BoxProps {
  length: number;
}

export const SelectedFinalInfo = styled(Box)<SelectedFinalInfoProps>(props => {
  return {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '2.5rem 2.2rem',
    border: '1px solid #f5f5f5',
    fontSize: '1.05rem',
    fontWeight: '700',
    marginBottom: '1rem',
    gap: '2.5rem',
    color: props.theme.palette.gray_1.main,
    '& .payment-info-section-1': {
      width: '100%',
      '& > div': {
        marginBottom: '0.75rem',
      },
      '& > div:nth-of-type(3)': {
        paddingBottom: props.length == 5 ? '0.8rem' : '0rem',
        borderBottom: props.length == 5 ? '1px solid #ebebeb' : 'none',
        marginBottom: props.length == 5 ? '0' : '0.75rem',
      },
      '& > div:nth-of-type(4)': {
        paddingTop: props.length == 5 ? '0.8rem' : '0',
        paddingBottom: props.length == 5 ? '0' : '0.8rem',
        borderBottom: props.length == 5 ? 'none' : '1px solid #ebebeb',
        marginBottom: props.length == 5 ? '0.75rem' : '0',
      },
      '& > div:nth-of-type(5)': {
        paddingTop: props.length == 5 ? '0' : '0.8rem',
      },
    },
    '& .payment-info-section-2': {
      display: 'flex',
      flexDirection: 'column',
      width: '108%',
      padding: '1.8rem',
      borderRadius: '2rem',
      backgroundColor: props.theme.palette.gray_5.main,
      gap: '0.5rem',
      '& .row-wrapper': {
        display: 'flex',
        gap: '1rem',
        '& > span:nth-of-type(2)': {
          color: 'black',
        },
      },
    },
  };
});

export const FinalPaymentButton = styled(ButtonContainer)(props => ({
  backgroundColor: `${props.theme.palette.purple.main} !important`,
  '&:hover': {
    backgroundColor: `${props.theme.palette.purple.main} !important`,
  },
  width: '100%',
  height: '3.5rem',
  borderRadius: '0.5rem',
}));
