import theme from '../../../theme';
import {
  styled,
  Box,
  RadioGroup as MuiRadioGroup,
  Tooltip as MuiTooltip,
  TooltipProps,
  tooltipClasses,
  BoxProps,
} from '@mui/material';
import { TfiArrowLeft } from 'react-icons/tfi';
import { dir_column, d_flex } from 'styles/common';

export { Box, Checkbox } from '@mui/material';

export const StyleContainer = styled(Box)({
  height: '100%',
  width: '100%',
  padding: '0 1rem 1rem 2rem',
  ...d_flex,
});

export const StyledWrap = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
  borderRadius: '2rem',
  boxShadow: props.theme.palette.boxShadow.main,
  ...d_flex,
  ...dir_column,
}));

export const Wrapper = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',
  '& > div': {
    height: '100%',
  },
});

export const Line = styled(Box)({
  width: '3px',
  backgroundColor: '#eef0f1',
});

export const FirstWrap = styled(Box)({
  width: 'calc(65% - 1.5px)',
  height: '100%',
  borderRadius: '2rem 0 0 0',
});

export const SecondWrap = styled(Box)({
  width: 'calc(35% - 1.5px)',
  fontWeight: 400,
});

export const Header = styled(Box)({
  width: '100%',
  height: '3rem',
  backgroundColor: '#f5f5f5',
  borderBottom: '1px solid #edeeef',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  borderTopLeftRadius: '2rem',
  span: {
    color: 'rgba(0,0,0,.6)',
    '&.open-course-setting': {
      fontSize: '0.9rem',
      position: 'absolute',
      left: '1rem',
      cursor: 'pointer',
    },
    '&.close-schedule-list': {
      fontSize: '0.9rem',
      position: 'absolute',
      right: '1rem',
      cursor: 'pointer',
    },
  },
});

export const Footer = styled(Box)({
  width: '100%',
  height: '3.75rem',
  borderTop: '3px solid #edeeef',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > button': {
    width: '100%',
    height: '100%',
    borderRadius: '0',
  },
});

export const SortingBox = styled(Box)(() => ({
  height: '2.75rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #e8f1f4',
  padding: '0 1rem 0.25rem 1rem',
  '& > div:first-of-type': {
    width: '27.5%',
    height: '100%',
    button: {
      span: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-57.5%)',
        width: '100%',
        textOverflow: 'ellipsis',
      },
      '&:first-of-type': {
        marginRight: '0.25rem',
      },
      minWidth: 'calc((100% - 1.25rem) / 2)',
      maxWidth: 'calc((100% - 0.25rem) / 2)',
      justifyContent: 'center',
      position: 'relative',
      svg: {
        position: 'absolute',
        right: '5%',
      },
    },
  },
  '& > div:last-of-type': {
    width: '36%',
    height: '100%',
    '& > div': {
      width: '100%',
      height: '100%',
    },
  },
}));

export const BookRowWrap = styled(Box)({
  height: 'calc(100% - 6rem)',
  position: 'relative',
  overflowY: 'auto',
  fontWeight: 400,
});

export const BookRow = styled(Box)({
  cursor: 'pointer',
  height: '3.75rem',
  display: 'grid',
  gridTemplateColumns: '6.5% 6.5% 1fr',
  borderBottom: '1px solid #e8f1f4',
  whiteSpace: 'pre-wrap',
  '&.active': {
    color: '#2196f3',
    backgroundColor: '#ecfbff',
  },
  '& > div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& > div:first-of-type': {
    borderRight: '1px solid #e8f1f4',
  },
  '&> div:last-of-type': {
    justifyContent: 'flex-start',
  },
});

export const DetailRow = styled(Box)(props => ({
  height: '3.75rem',
  display: 'grid',
  gridTemplateColumns: '4.5% 2% 6.5% 1fr',

  '& > div': {
    borderBottom: '1px solid #e8f1f4',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  '& > div:first-of-type': {
    borderBottom: props.className == 'last' ? '1px solid #e8f1f4' : 'none',
    cursor: 'pointer',
  },
  '&> div:last-of-type': {
    cursor: 'pointer',
    justifyContent: 'flex-start',
  },
}));

export const PrintBookTitleBox = styled(Box)(props => ({
  height: '2.85rem',
  padding: '0 1.3rem',
}));

export const PrintBookTitle = styled(Box)(props => ({
  maxWidth: '100%',
  height: '100%',
  display: 'inline-flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  overflow: 'hidden',
  '& > span': {
    borderRadius: '1rem',
    color: props.theme.palette.purple.main,
    backgroundColor: props.theme.palette.purple_5.main,
    padding: '0.35rem 1rem',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export const PrintUnitTitle = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  display: 'inline-flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  overflow: 'hidden',
  fontSize: '1rem',
  color: 'rgba(0,0,0,.87)',
  padding: '4px 1.3rem 4px 2.6rem',
  '& > span': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  '& > svg': {
    flexShrink: 0,
  },
}));

export const IconWrap = styled(Box)(props => {
  return {
    // [theme.breakpoints.up('xxl')]: {
    //   width: `calc(18px + 2.75rem + (calc(100px - 2.75rem) / 2))`,
    // },

    // [theme.breakpoints.down('xxl')]: {
    //   width: `calc(18px + 2.75rem + (calc(90px - 2.75rem) / 2))`,
    // },

    // [theme.breakpoints.down('xl')]: {
    //   width: `calc(18px + 2.75rem + (calc(72.5px - 2.75rem) / 2))`,
    // },

    // [theme.breakpoints.down('md')]: {
    //   width: `calc(18px + 2.75rem + (calc(65px - 2.75rem) / 2))`,
    // },

    // [theme.breakpoints.down('sm')]: {
    //   width: `calc(18px + 2.75rem + (calc(60px - 2.75rem) / 2))`,
    // },

    height: '100%',
    display: 'flex',
    alignItems: 'center',
  };
});

export const BackIconBox = styled(Box)({
  backgroundColor: '#f5f5f5',
  borderRadius: '50%',
  border: 'none',
  width: '2.75rem',
  height: '2.75rem',
  padding: '0',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const BackIcon = styled(TfiArrowLeft)({
  color: '#9f9f9f',
  strokeWidth: '1.5px',
  width: '1.35rem',
  height: '1.35rem',
});

export const OptionBox = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  '@media (max-width: 863px)': {
    minWidth: '23rem',
  },
});

export const SelectBox = styled(Box)({
  width: '100%',
  height: '11.25rem',
  padding: '1.3rem',
  display: 'flex',
  borderTop: '3px solid #edeeef',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& > div': { width: '100%', cursor: 'default' },
  '& .header-wrap': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '@media (max-width: 1277px) and (min-width: 1264px)': {
    padding: '1.3rem 1.1rem',
  },
  '@media (max-width: 1099px) and (min-width: 960px)': {
    padding: '1.3rem 0.8rem',
    height: '100%',
  },
  '@media (max-width: 863px)': {
    padding: '1.3rem 0.8rem',
    height: '100%',
  },
});

export const TestInputWrap = styled(Box)(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 1099px) and (min-width: 960px)': {
    flexDirection: 'column',
    alignItems: 'baseline',
  },
  '@media (max-width: 863px)': {
    flexDirection: 'column',
    alignItems: 'baseline',
  },
}));

export const SelectWordCounter = styled(Box)(props => ({
  display: 'inline-block',
  borderRadius: '1rem',
  backgroundColor: '#f2f3f5',
  padding: '0.35rem 1rem',
  '& > span': {
    color: props.theme.palette.purple.main,
    fontWeight: 'bold',
  },
}));

export const RemainWordCounter = styled(Box)(props => ({
  display: 'inline-block',
  padding: '0.35rem 0',
  color: props.theme.palette.grey[500],
  fontWeight: '500',
}));

interface CounterInputBoxProps extends BoxProps {
  disabled: boolean;
}

export const CounterInputBox = styled(Box)<CounterInputBoxProps>(props => ({
  '& > input': {
    width: '4rem',
    border: props.disabled ? '1px solid #c9c9c9' : '1px solid #9c9c9c',
    borderRadius: 0,
    height: '2.188rem',
    lineHeight: 2.5,
    fontSize: '1rem',
    paddingRight: '0.6rem',
    textAlign: 'right',
    '&:focus-visible': {
      outline: 'none',
    },
  },
  '@media (max-width: 1099px) and (min-width: 960px)': {
    paddingLeft: '0.8rem',
  },
  '@media (max-width: 863px)': {
    paddingLeft: '0.8rem',
  },
}));

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} disableHoverListener />
))(({ placement }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#3f3f3f',
    left: placement === 'top-start' ? '1.5rem !important' : '0px !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    cursor: 'default',
    backgroundColor: '#3f3f3f',
    fontSize: '1rem',
    maxWidth: 'unset',
  },
}));

export const RadioBox = styled(Box)({
  width: 'calc(100% - 2.6rem)',
  height: '3.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderTop: '1px solid #edeeef',
  '@media (max-width: 1099px) and (min-width: 960px)': {
    marginBottom: '3rem',
  },
  '@media (max-width: 863px)': {
    marginBottom: '5rem',
    marginTop: '2rem',
  },
});

export const RadioGroup = styled(MuiRadioGroup)({
  flexDirection: 'row',
  justifyContent: 'center',
  '& span.MuiFormControlLabel-label': {
    color: '#9e9e9e',
    marginBottom: '2px',
  },
});
