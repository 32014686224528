import { styled, ButtonGroup as MuiButtonGroup, Button as MuiButton } from '@mui/material';

export const ButtonGroup = styled(MuiButtonGroup)(props => ({
  width: '100%',
  height: '3rem',
  margin: 'auto',
  display: 'flex',

  '& > :first-of-type': {
    borderTopLeftRadius: '0.5rem',
    borderBottomLeftRadius: '0.5rem',
  },
  '& > :last-of-type': {
    borderBottomRightRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
  },

  'button.MuiButtonGroup-grouped': {
    minWidth: '0px',
  },
}));

export const Button = styled(MuiButton)<{ checkcolor?: string }>(props => ({
  textAlign: 'center',
  border: 'none',
  boxShadow: 'none',
  padding: '6px 15px 5px !important',
  color: '#e5e5e5',
  '&:active': {
    border: 'none',
    boxShadow: 'none',
  },
  '&:hover': {
    border: 'none',
    borderRightColor: '#f6f1f5',
    boxShadow: 'none',
  },
  fontFamily: "'Apple SD Gothic Neo', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue'",
  fontSize: '0.9srem',
  borderRadius: '0px',
  '&.MuiButton-outlined': {
    backgroundColor: '#ffffff',
    border: '1px solid #f6f1f5',

    '&:hover': {
      border: '1px solid #f6f1f5',
    },
  },
  '&.MuiButtonGroup-grouped:not(:last-of-type)': {
    borderRightColor: '#f6f1f5',
    '&:hover': {
      borderRightColor: '#f6f1f5',
    },
  },
}));
