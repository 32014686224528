import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ToastBarDataType, toastBarState } from 'recoil/common/toastBar';
import { ToastBarPresenters } from './ToastBarPresenters';
import { SnackbarCloseReason } from '@mui/material';
import { getElementRect } from '../../../utils/tools';

export const ToastBarContainer = React.memo(function ToastBarContainer() {
  const [toastBar, setToastBar] = useRecoilState<ToastBarDataType>(toastBarState);
  const [positioning, setPositioning] = useState({});

  useEffect(() => {
    if (toastBar.message.includes('추천코드') && toastBar.backgroundColor == 'red') {
      setPositioning({
        payModalHeaderTop: getElementRect('pay-modal-header'),
        payModalHeaderHeight: getElementRect('pay-modal-header', 'height'),
        toastHeight: getElementRect('toast-alert', 'height'),
      });
    }
  }, [toastBar.message, getElementRect('pay-modal-header')]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastBar(prevState => {
      return {
        ...prevState,
        visible: false,
      };
    });
  };

  const props_obj = {
    visible: toastBar.visible,
    message: toastBar.message,
    time: toastBar.time,
    backgroundColor: toastBar.backgroundColor,
    handleClose,
    positioning,
  };

  return <ToastBarPresenters {...props_obj} />;
});
