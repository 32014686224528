import { styled, Modal as MuiModal, Box, Radio, Button } from '@mui/material';
export { Box } from '@mui/material';

export const Modal = styled(MuiModal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '1300',
});

export const ModalBox = styled(Box)({
  transform: 'scale(0.88)',
  width: '64rem',
  height: '45.5rem',
  backgroundColor: 'white',
  borderRadius: '1.5rem',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  letterSpacing: '-0.0266rem',

  '@media (max-width: 769px)': {
    transform: 'scale(0.725)',
  },
});

export const ModalHeader = styled(Box)({
  transform: 'scale(1.002)',
  width: '100%',
  height: '4rem',
  backgroundColor: '#6b20e9',
  color: 'white',
  padding: '0px 1rem',
  borderRadius: '1.5rem 1.5rem 0 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.2rem',
  textAlign: 'center',
  position: 'absolute',
  top: '-5%',
});

export const ModalHeaderIconWrap = styled(Box)(() => ({
  right: '1rem',
  position: 'absolute',
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: 'white',
  color: 'rgb(107, 32, 233)',
  svg: {
    width: '1.6rem',
    height: '1.6rem',
    strokeWidth: 30,
    marginRight: '-1px',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@media  (max-width: 959px)': {
    width: '2.75rem',
    height: '2.75rem',
    svg: {
      width: '2.65rem',
      height: '2.65rem',
    },
  },
  '@media (max-width: 767px)': {
    width: '2.25rem',
    height: '2.25rem',
    svg: {
      width: '2rem',
      height: '2rem',
    },
  },
}));

export const ModalContentWrapper = styled(Box)({
  padding: '3.5rem 2rem 3rem 2.35rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  overflow: 'hidden',
  position: 'relative',
  flexDirection: 'column',
});

export const ModalContentScrollBox = styled(Box)({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  paddingRight: '0.5rem',

  '&::-webkit-scrollbar': {
    display: 'block',
    width: '0.7rem',
  },
  ' &::-webkit-scrollbar-thumb': {
    background: 'rgba(210, 210, 210, 0.6)',
    borderRadius: '100px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
    borderRadius: '100px',
  },
});

export const ModalContentWrap = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '1.25rem',
  minHeight: '40rem',
});

interface ModalStepTwoContentBoxProps {
  backgroundColor?: string; // 선택적 속성으로 정의
  borderBottom?: string;
}

interface ModalStepTwoContentTextProps {
  radioValue?: number;
  color?: string;
  fontSize?: string;
  isPaymentKind?: boolean;
}

export const ModalStepTwoContentBox = styled(Box)<ModalStepTwoContentBoxProps>(({ backgroundColor }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '11.5rem',
    borderWidth: '0.063rem',
    borderColor: 'rgb(229, 231, 235)',
    borderStyle: 'solid',
    backgroundColor: backgroundColor ? `${backgroundColor}` : '#f7f8f9',
  };
});

export const ModalStepTwoContentTextBox = styled(Box)<ModalStepTwoContentTextProps>(({ borderBottom }) => {
  return {
    display: 'flex',
    width: '100%',
    maxWidth: '33.25rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: borderBottom ? '0.0625rem solid rgb(229, 231, 235)' : undefined,
  };
});

export const ModalStepTwoContentTextLeft = styled(Box)<ModalStepTwoContentTextProps>(({ color }) => {
  return {
    fontSize: '1.25rem',
    textAlign: 'right',
    width: '25%',
    fontWeight: '500',
    padding: '0.5rem 1rem',
    color: color || 'inherit',
    whiteSpace: 'nowrap',
  };
});

export const ModalStepTwoContentTextRight = styled(Box)<ModalStepTwoContentTextProps>(({ color }) => {
  return {
    fontSize: '1.25rem',
    textAlign: 'left',
    width: '75%',
    color: color ? `${color}` : 'black',
    fontWeight: '600',
    padding: '0.5rem 1rem',
    whiteSpace: 'nowrap',
  };
});

export const ModalStepTwoContentTextBoxTwo = styled(Box)<ModalStepTwoContentTextProps>(({ borderBottom }) => {
  return {
    display: 'flex',
    width: '100%',
    maxWidth: '33.25rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: borderBottom ? '0.0625rem solid rgb(229, 231, 235)' : undefined,
    padding: '1rem 0 1rem 0',

    '@media (min-width: 1266px) and (max-width: 1905px)': {
      '& > div': {
        position: 'relative',
        inset: borderBottom ? '0.2rem 0 0 0' : '-0.2rem 0 0 0',
      },
    },

    '@media (min-width: 962px) and (max-width: 1265px)': {
      '& > div': {
        position: 'relative',
        inset: borderBottom ? '0.2rem 0 0 0' : '-0.25rem 0 0 0',
      },
    },

    '@media (min-width: 770px) and (max-width: 961px)': {
      '& > div': {
        position: 'relative',
        inset: borderBottom ? '0.65rem 0 0 0' : '-0.5rem 0 0 0',
      },
    },

    '@media (max-width: 769px)': {
      '& > div': {
        position: 'relative',
        inset: borderBottom ? '0.5rem 0 0 0' : '-0.4rem 0 0 0',
      },
    },
  };
});

export const ModalStepTwoContentFormTextLeftBox = styled(Box)<ModalStepTwoContentTextProps>(({ isPaymentKind }) => {
  return {
    fontSize: '1.25rem',
    textAlign: 'right',
    width: '25%',
    fontWeight: '500',
    padding: '0.5rem 1rem',
    color: isPaymentKind ? '#04d14a' : '#777777',
    whiteSpace: 'nowrap',
  };
});

export const ModalStepTwoContentFormTextRightBox = styled(Box)<ModalStepTwoContentTextProps>(({ color }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.25rem',
    textAlign: 'left',
    width: '75%',
    color: color ? `${color}` : 'black',
    fontWeight: '600',
    padding: '0.5rem 1rem',
    whiteSpace: 'nowrap',
  };
});

export const StyledPaymentKindRadio = styled(Radio)<ModalStepTwoContentTextProps>(({ radioValue }) => {
  return {
    color: '#777777',
    '&.Mui-checked': {
      color: '#04d14a',
    },
  };
});

interface MarginProps {
  height: string;
}
export const Margin = styled(Box)<MarginProps>(({ height }) => {
  return {
    height: height ? `${height}rem` : '',
  };
});

export const Line = styled(Box)<{ height: string }>(({ height }) => {
  return {
    width: '100%',
    height: height ? `${height}` : '0.7rem',
    borderBottom: '0.0625rem solid rgb(229, 231, 235)',
  };
});

interface PaymentButtonProps {
  paymentKind: number;
  isRegister: boolean;
  isPayment?: boolean;
  isExitButton?: boolean;
}

export const ColorButton = styled(Button)<PaymentButtonProps>(
  ({ paymentKind, isRegister, isPayment, isExitButton }) => {
    const isClickable = paymentKind === 0 || (paymentKind === 1 && isRegister);

    return {
      cursor: isPayment ? 'default' : isClickable ? 'pointer' : 'default',
      fontSize: '1rem',
      width: '11%',
      color: 'white',
      fontWeight: '600',
      backgroundColor: isPayment ? '#856cef' : isExitButton ? '#ddd' : isClickable ? '#5500ff' : '#f6f6f6',
      '&:hover': {
        backgroundColor: isPayment ? '#856cef' : isExitButton ? '#ccc' : isClickable ? '#4806cb' : '#f6f6f6', // 클릭 가능할 때만 hover 색상 변경
      },
    };
  },
);

export const IntendTitle = styled(Box)({
  fontSize: '1.275rem',
  fontWeight: 'bold',
  height: '4rem',
  paddingLeft: '1.25rem',
  span: {
    color: 'rgb(85, 0, 255)',
    background: 'linear-gradient(180deg,rgba(255,255,255,0) 85%, gold 15%)',
  },
});
