import { Box, Checkbox } from '@mui/material';
import React from 'react';
import {
  CheckboxBox,
  CheckboxWrap,
  ContentWrap,
  Footer,
  Header,
  SettingBoxWrap,
  SettingBox,
  WordPrintButton,
  Wrapper,
  SettingLabel,
  SettingButtonGroup,
  SettingButton,
  Line,
  CustomSlider,
} from './PrintSetting.Styles';
import { Input } from 'components/common/InputText/InputText.Styles';

export interface PrintSettingVAProps {
  title: string;
  printSetting: PrintSettingType;
  printInfo: PrintInfoType;
  type: number;
  onClickWordPrint: () => void;
  onChangePrintSetting: (key: string, value: number) => void;
  onChangePrintInfo: (key: string, value: boolean) => void;
  onChangeTitle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCommitted: (event: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => void;
  sliderValueLabelFormat: (value: number, index: number) => string;
  printMethod?: string;
}

export const PrintSettingUI = React.memo(function PrintSettingUI({
  title,
  printSetting,
  printInfo,
  type,
  onClickWordPrint,
  onChangePrintSetting,
  onChangePrintInfo,
  onChangeTitle,
  printMethod,
  sliderValueLabelFormat,
  onChangeCommitted,
}: PrintSettingVAProps) {
  return (
    <Wrapper>
      <Header>
        <Line />
        <Box>출력 설정</Box>
      </Header>
      <ContentWrap>
        <SettingBoxWrap>
          {type == 0 ? (
            <Box>
              <SettingBox>
                <SettingLabel>제목</SettingLabel>
                <Input
                  placeholder={'20자 이내로 작성 가능합니다'}
                  color='gray_1'
                  value={title}
                  onChange={onChangeTitle}
                  autoComplete={'off'}
                />
              </SettingBox>
              <SettingBox>
                <SettingLabel>유형</SettingLabel>
                <SettingButtonGroup>
                  <SettingButton
                    variant={printSetting.lang == 0 ? 'contained' : 'outlined'}
                    color={printSetting.lang == 0 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('lang', 0)}
                  >
                    전체
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.lang == 1 ? 'contained' : 'outlined'}
                    color={printSetting.lang == 1 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('lang', 1)}
                  >
                    ENG
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.lang == 2 ? 'contained' : 'outlined'}
                    color={printSetting.lang == 2 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('lang', 2)}
                  >
                    KOR
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.lang == 3 ? 'contained' : 'outlined'}
                    color={printSetting.lang == 3 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('lang', 3)}
                  >
                    랜덤
                  </SettingButton>
                </SettingButtonGroup>
              </SettingBox>
              <SettingBox>
                <SettingLabel>순서</SettingLabel>
                <SettingButtonGroup>
                  <SettingButton
                    variant={printSetting.sort == 0 ? 'contained' : 'outlined'}
                    color={printSetting.sort == 0 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('sort', 0)}
                  >
                    원본
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.sort == 1 ? 'contained' : 'outlined'}
                    color={printSetting.sort == 1 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('sort', 1)}
                  >
                    랜덤
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.sort == 2 ? 'contained' : 'outlined'}
                    color={printSetting.sort == 2 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('sort', 2)}
                  >
                    ABC순
                  </SettingButton>
                </SettingButtonGroup>
              </SettingBox>
              <SettingBox>
                <SettingLabel>글씨 크기</SettingLabel>
                <SettingButtonGroup>
                  <SettingButton
                    variant={printSetting.fontsize == 0 ? 'contained' : 'outlined'}
                    color={printSetting.fontsize == 0 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('fontsize', 0)}
                  >
                    작게
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.fontsize == 1 ? 'contained' : 'outlined'}
                    color={printSetting.fontsize == 1 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('fontsize', 1)}
                  >
                    보통
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.fontsize == 2 ? 'contained' : 'outlined'}
                    color={printSetting.fontsize == 2 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('fontsize', 2)}
                  >
                    크게
                  </SettingButton>
                </SettingButtonGroup>
              </SettingBox>
              <SettingBox>
                <SettingLabel>줄 간격</SettingLabel>
                <SettingButtonGroup>
                  <SettingButton
                    variant={printSetting.line == 0 ? 'contained' : 'outlined'}
                    color={printSetting.line == 0 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('line', 0)}
                  >
                    좁게
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.line == 1 ? 'contained' : 'outlined'}
                    color={printSetting.line == 1 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('line', 1)}
                  >
                    보통
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.line == 2 ? 'contained' : 'outlined'}
                    color={printSetting.line == 2 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('line', 2)}
                  >
                    넓게
                  </SettingButton>
                </SettingButtonGroup>
              </SettingBox>
              {printMethod == 'wrongSentence' ? (
                <SettingBox>
                  <SettingLabel>너비</SettingLabel>
                  <CustomSlider
                    size='small'
                    defaultValue={printSetting.width}
                    min={0}
                    max={100}
                    step={25}
                    valueLabelDisplay='on'
                    valueLabelFormat={sliderValueLabelFormat}
                    onChangeCommitted={onChangeCommitted}
                  />
                </SettingBox>
              ) : (
                <SettingBox>
                  <SettingLabel>다단 설정</SettingLabel>
                  <SettingButtonGroup>
                    <SettingButton
                      variant={printSetting.column == 0 ? 'contained' : 'outlined'}
                      color={printSetting.column == 0 ? 'purple_5' : 'gray_1'}
                      onClick={() => onChangePrintSetting('column', 0)}
                    >
                      1단
                    </SettingButton>
                    <SettingButton
                      variant={printSetting.column == 1 ? 'contained' : 'outlined'}
                      color={printSetting.column == 1 ? 'purple_5' : 'gray_1'}
                      onClick={() => onChangePrintSetting('column', 1)}
                    >
                      2단
                    </SettingButton>
                    <SettingButton
                      variant={printSetting.column == 2 ? 'contained' : 'outlined'}
                      color={printSetting.column == 2 ? 'purple_5' : 'gray_1'}
                      onClick={() => onChangePrintSetting('column', 2)}
                    >
                      3단
                    </SettingButton>
                  </SettingButtonGroup>
                </SettingBox>
              )}
            </Box>
          ) : type == 1 ? (
            <>
              <SettingBox>
                <SettingLabel>제목</SettingLabel>
                <Input
                  placeholder={'20자 이내로 작성 가능합니다'}
                  color='gray_1'
                  value={title}
                  onChange={onChangeTitle}
                  autoComplete={'off'}
                />
              </SettingBox>
              <SettingBox>
                <SettingLabel>유형</SettingLabel>
                <SettingButtonGroup>
                  <SettingButton
                    variant={printSetting.lang == 0 ? 'contained' : 'outlined'}
                    color={printSetting.lang == 0 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('lang', 0)}
                  >
                    ENG
                  </SettingButton>
                </SettingButtonGroup>
              </SettingBox>
              <SettingBox>
                <SettingLabel>순서</SettingLabel>
                <SettingButtonGroup>
                  <SettingButton
                    variant={printSetting.sort == 0 ? 'contained' : 'outlined'}
                    color={printSetting.sort == 0 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('sort', 0)}
                  >
                    원본
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.sort == 1 ? 'contained' : 'outlined'}
                    color={printSetting.sort == 1 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('sort', 1)}
                  >
                    랜덤
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.sort == 2 ? 'contained' : 'outlined'}
                    color={printSetting.sort == 2 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('sort', 2)}
                  >
                    ABC순
                  </SettingButton>
                </SettingButtonGroup>
              </SettingBox>
              <SettingBox>
                <SettingLabel>글씨 크기</SettingLabel>
                <SettingButtonGroup>
                  <SettingButton
                    variant={printSetting.fontsize == 0 ? 'contained' : 'outlined'}
                    color={printSetting.fontsize == 0 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('fontsize', 0)}
                  >
                    작게
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.fontsize == 1 ? 'contained' : 'outlined'}
                    color={printSetting.fontsize == 1 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('fontsize', 1)}
                  >
                    보통
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.fontsize == 2 ? 'contained' : 'outlined'}
                    color={printSetting.fontsize == 2 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('fontsize', 2)}
                  >
                    크게
                  </SettingButton>
                </SettingButtonGroup>
              </SettingBox>
              <SettingBox>
                <SettingLabel>줄 간격</SettingLabel>
                <SettingButtonGroup>
                  <SettingButton
                    variant={printSetting.line == 0 ? 'contained' : 'outlined'}
                    color={printSetting.line == 0 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('line', 0)}
                  >
                    좁게
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.line == 1 ? 'contained' : 'outlined'}
                    color={printSetting.line == 1 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('line', 1)}
                  >
                    보통
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.line == 2 ? 'contained' : 'outlined'}
                    color={printSetting.line == 2 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('line', 2)}
                  >
                    넓게
                  </SettingButton>
                </SettingButtonGroup>
              </SettingBox>
              <SettingBox>
                <SettingLabel>표기 단어 수</SettingLabel>
                <SettingButtonGroup>
                  <SettingButton
                    variant={printSetting.repeat == 0 ? 'contained' : 'outlined'}
                    color={printSetting.repeat == 0 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('repeat', 0)}
                  >
                    1개
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.repeat == 1 ? 'contained' : 'outlined'}
                    color={printSetting.repeat == 1 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('repeat', 1)}
                  >
                    2개
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.repeat == 2 ? 'contained' : 'outlined'}
                    color={printSetting.repeat == 2 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('repeat', 2)}
                  >
                    3개
                  </SettingButton>
                </SettingButtonGroup>
              </SettingBox>
            </>
          ) : (
            <>
              <SettingBox>
                <SettingLabel>유형</SettingLabel>
                <SettingButtonGroup>
                  <SettingButton
                    variant={printSetting.lang == 0 ? 'contained' : 'outlined'}
                    color={printSetting.lang == 0 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('lang', 0)}
                  >
                    전체
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.lang == 1 ? 'contained' : 'outlined'}
                    color={printSetting.lang == 1 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('lang', 1)}
                  >
                    ENG
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.lang == 2 ? 'contained' : 'outlined'}
                    color={printSetting.lang == 2 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('lang', 2)}
                  >
                    KOR
                  </SettingButton>
                </SettingButtonGroup>
              </SettingBox>
              <SettingBox>
                <SettingLabel>순서</SettingLabel>
                <SettingButtonGroup>
                  <SettingButton
                    variant={printSetting.sort == 0 ? 'contained' : 'outlined'}
                    color={printSetting.sort == 0 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('sort', 0)}
                  >
                    원본
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.sort == 1 ? 'contained' : 'outlined'}
                    color={printSetting.sort == 1 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('sort', 1)}
                  >
                    랜덤
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.sort == 2 ? 'contained' : 'outlined'}
                    color={printSetting.sort == 2 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('sort', 2)}
                  >
                    ABC순
                  </SettingButton>
                </SettingButtonGroup>
              </SettingBox>
              <SettingBox>
                <SettingLabel>글씨 크기</SettingLabel>
                <SettingButtonGroup>
                  <SettingButton
                    variant={printSetting.fontsize == 0 ? 'contained' : 'outlined'}
                    color={printSetting.fontsize == 0 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('fontsize', 0)}
                  >
                    작게
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.fontsize == 1 ? 'contained' : 'outlined'}
                    color={printSetting.fontsize == 1 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('fontsize', 1)}
                  >
                    보통
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.fontsize == 2 ? 'contained' : 'outlined'}
                    color={printSetting.fontsize == 2 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('fontsize', 2)}
                  >
                    크게
                  </SettingButton>
                </SettingButtonGroup>
              </SettingBox>
              <SettingBox>
                <SettingLabel>줄 간격</SettingLabel>
                <SettingButtonGroup>
                  <SettingButton
                    variant={printSetting.line == 0 ? 'contained' : 'outlined'}
                    color={printSetting.line == 0 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('line', 0)}
                  >
                    좁게
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.line == 1 ? 'contained' : 'outlined'}
                    color={printSetting.line == 1 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('line', 1)}
                  >
                    보통
                  </SettingButton>
                  <SettingButton
                    variant={printSetting.line == 2 ? 'contained' : 'outlined'}
                    color={printSetting.line == 2 ? 'purple_5' : 'gray_1'}
                    onClick={() => onChangePrintSetting('line', 2)}
                  >
                    넓게
                  </SettingButton>
                </SettingButtonGroup>
              </SettingBox>
              {printMethod && printMethod == 'wrongSentence' ? null : (
                <SettingBox>
                  <SettingLabel>다단 설정</SettingLabel>
                  <SettingButtonGroup>
                    <SettingButton
                      variant={printSetting.column == 0 ? 'contained' : 'outlined'}
                      color={printSetting.column == 0 ? 'purple_5' : 'gray_1'}
                      onClick={() => onChangePrintSetting('column', 0)}
                    >
                      1단
                    </SettingButton>
                    <SettingButton
                      variant={printSetting.column == 1 ? 'contained' : 'outlined'}
                      color={printSetting.column == 1 ? 'purple_5' : 'gray_1'}
                      onClick={() => onChangePrintSetting('column', 1)}
                    >
                      2단
                    </SettingButton>
                    <SettingButton
                      variant={printSetting.column == 2 ? 'contained' : 'outlined'}
                      color={printSetting.column == 2 ? 'purple_5' : 'gray_1'}
                      onClick={() => onChangePrintSetting('column', 2)}
                    >
                      3단
                    </SettingButton>
                  </SettingButtonGroup>
                </SettingBox>
              )}
            </>
          )}
        </SettingBoxWrap>
        {type == 0 || type == 1 ? (
          <CheckboxWrap>
            <CheckboxBox>
              <Checkbox
                onChange={() => onChangePrintInfo('logo', !printInfo.logo)}
                checked={printInfo.logo}
                color='green'
              />
              <Box onClick={() => onChangePrintInfo('logo', !printInfo.logo)}>&nbsp;&nbsp;로고 삽입</Box>
            </CheckboxBox>
            <CheckboxBox>
              <Checkbox
                onChange={() => onChangePrintInfo('pagenum', !printInfo.pagenum)}
                checked={printInfo.pagenum}
                color='green'
              />
              <Box onClick={() => onChangePrintInfo('pagenum', !printInfo.pagenum)}>&nbsp;&nbsp;쪽번호 삽입</Box>
            </CheckboxBox>
            <CheckboxBox>
              <Checkbox
                onChange={() => onChangePrintInfo('cname', !printInfo.cname)}
                checked={printInfo.cname}
                color='green'
              />
              <Box onClick={() => onChangePrintInfo('cname', !printInfo.cname)}>&nbsp;&nbsp;수업명 삽입</Box>
            </CheckboxBox>
          </CheckboxWrap>
        ) : null}
      </ContentWrap>
      <Footer>
        <WordPrintButton onClick={onClickWordPrint}>
          {printMethod && ['wrongWord', 'wrongSentence'].includes(printMethod) ? '오답노트 출력' : '교재 출력'}
        </WordPrintButton>
      </Footer>
    </Wrapper>
  );
});
