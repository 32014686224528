import { Box, IconButton, styled } from '@mui/material';

export const Container = styled(Box)({
  backgroundColor: '#f9fafb',
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ListWrapper = styled(Box)({
  backgroundColor: '#fff',
  border: '1px solid #f6f8fa',
  borderRadius: '0.75rem',
  width: '40vw',
  paddingTop: '3rem',

  '@media (min-width: 1265px) and (max-width: 1904px)': {
    transform: 'scale(1.3)',
  },

  '@media (max-width: 1264px)': {
    transform: 'scale(1.3)',
  },

  '@media (max-width: 1187px)': {
    width: '45vw',
  },

  '@media (max-width: 960px)': {
    width: '40vw',
  },

  '@media (max-width: 960px) and (min-height: 405px)': {
    transform: 'scale(1.4)',
  },

  '@media (max-width: 960px) and (max-height: 404px)': {
    transform: 'scale(1.2)',
  },

  '@media(max-width: 768px)': {
    transform: 'scale(1.1)',
    width: '50vw',
  },

  '@media(max-width: 739px)': {
    width: '60vw',
  },
});

export const RegisterWrapper = styled(Box)({
  backgroundColor: '#fff',
  border: '1px solid #f6f8fa',
  borderRadius: '0.75rem',
  width: '40vw',
  paddingTop: '3rem',
  position: 'relative',

  '@media (min-width: 1265px) and (max-width: 1904px)': {
    transform: 'scale(1.3)',
  },

  '@media (max-width: 1264px)': {
    transform: 'scale(1.3)',
  },

  '@media (max-width: 1187px)': {
    transform: 'scale(1.25)',
    width: '45vw',
  },

  '@media (max-width: 960px) and (min-height: 405px)': {
    transform: 'scale(1.25)',
  },

  '@media (max-width: 960px) and (max-height: 404px)': {
    transform: 'scale(1.2)',
  },

  '@media (max-width: 960px) and (max-height: 374px)': {
    transform: 'scale(1)',
  },

  '@media(max-width: 768px)': {
    transform: 'scale(1)',
    width: '50vw',
  },

  '@media(max-width: 739px)': {
    width: '60vw',
  },
});

export const ProfileTitle = styled(Box)({
  textAlign: 'center',
  fontSize: '1.3rem',
  fontWeight: '700',
  color: '#565656',
  marginBottom: '.75rem',
});

export const ProfileSubTitle = styled(Box)({
  textAlign: 'center',
  fontSize: '1.1rem',
  color: '#afafaf',
  fontWeight: '600',
});

export const ProfileWrap = styled(Box)({
  display: 'flex',
  padding: '3.4rem 0 4.25rem 0',
  justifyContent: 'center',
  gap: '5.75rem',
});

export const Profile = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const ProfileCircle = styled(Box)({
  width: '7rem',
  height: '7rem',
  border: '1px solid #ededed',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '1.5rem',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',

  '& > svg': {
    width: '60%',
    height: '60%',
    fill: '#dddddd',
  },

  '& > img': {
    width: '100%',
    '&.main_profile_mark': {
      width: '16%',
      position: 'absolute',
      top: '1.1rem',
      right: '1.1rem',
    },
  },

  '.user_profile': {
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
  },

  '.default_profile': {
    width: '60%',
    height: '60%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginBottom: '0.35rem',
  },
});

export const ProfileAddButton = styled(Box)({
  width: '7rem',
  height: '7rem',
  borderRadius: '50%',
  marginBottom: '1.5rem',
  backgroundColor: '#f3f3f3',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '& > svg': {
    width: '45%',
    height: '45%',
    fill: '#fff',
  },
});

export const ProfileName = styled(Box)({
  color: '#afafaf',
  fontWeight: '600',
  cursor: 'pointer',
  fontSize: '1.1rem',
});

export const FindBackButton = styled(IconButton)({
  position: 'absolute',
  top: '1rem',
  left: '1rem',
  svg: {
    width: '1.75rem',
    height: '1.75rem',
    color: '#828282',
  },
});

export const Form = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  paddingBottom: '3rem',
  '& > div': {
    width: '26rem',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: '#f6f1f5 !important',
    },

    '&:hover': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#f6f1f5 !important',
      },
    },

    '.MuiOutlinedInput-input': {
      '&::placeholder': {
        color: '#e5e5e5 !important',
        fontSize: '0.9rem',
      },
      color: '#a4a4a4',
      fontSize: '0.9rem',
      fontWeight: '500',
    },

    '&:nth-of-type(2)': {
      '.MuiOutlinedInput-input': {
        fontWeight: '500',
      },
    },
  },
});

export const RegisterButton = styled(Box)({
  position: 'absolute',
  right: '1.85rem',
  bottom: '1.5rem',
  textDecoration: 'underline',
  cursor: 'pointer',
  color: '#a4a4a4',
  fontWeight: '600',
  fontSize: '0.9rem',
});

export const ModalHeaderIconWrap = styled(Box)(() => ({
  right: '1rem',
  position: 'absolute',
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: 'white',
  color: 'rgb(107, 32, 233)',
  svg: {
    width: '1.6rem',
    height: '1.6rem',
    strokeWidth: 30,
    marginRight: '-1px',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@media  (max-width: 959px)': {
    width: '2.75rem',
    height: '2.75rem',
    svg: {
      width: '2.65rem',
      height: '2.65rem',
    },
  },
  '@media (max-width: 767px)': {
    width: '2.25rem',
    height: '2.25rem',
    svg: {
      width: '2rem',
      height: '2rem',
    },
  },
}));
