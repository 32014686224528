import bi_png from 'assets/images/longedu_bi.png';
import React, { Fragment } from 'react';
import {
  Container,
  Contents,
  Footer,
  Header,
  MovePageButton,
  PrintWrap,
  ContainerBox,
  Worksheet,
  Wrapper,
  FullscreenButton,
  HeaderContents,
  DictionaryImageBox,
  TitleWrap,
  Title,
  SubTitle,
  SubTitleBox,
  FooterContent,
  FooterWrap,
  Content,
  NameBox,
  Name,
} from './WordWorksheet.Styles';
import { Box } from '@mui/material';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { LoadingContainer } from 'components/Print/Loading/Loading.Container';
import { BiExpand, BiCollapse } from 'react-icons/bi';
import dictionaryIcon from 'assets/images/print_dictionary.png';

export interface WordWorksheetVAProps {
  title: string;
  duplicate: boolean;
  worksheetKey: number;
  currentPage: number;
  maxPage: number;
  loading: boolean;
  progress: number;
  scrollWidth: number;
  scrollHeight: number;
  isFullscreen: boolean;
  duplicateLoading: boolean;
  printSetting: PrintSettingType;
  printWordList: PrintBookListType;
  userStateData: UserType;
  printRef: React.MutableRefObject<any>;
  containerRef: React.MutableRefObject<any>;
  onClickPageMove: (direction: 'prev' | 'next') => void;
  onClickFullscreen: () => void;
  printMethod?: string;
}

export const WordWorksheetUI = React.memo(function PrintUI({
  title,
  worksheetKey,
  currentPage,
  maxPage,
  loading,
  progress,
  scrollWidth,
  scrollHeight,
  isFullscreen,
  printSetting,
  printWordList,
  userStateData,
  printRef,
  duplicate,
  duplicateLoading,
  containerRef,
  onClickPageMove,
  onClickFullscreen,
  printMethod,
}: WordWorksheetVAProps) {
  return (
    <Wrapper>
      {/* <LoadingContainer duplicate={duplicate} duplicateLoading={duplicateLoading} progress={progress} /> */}
      {loading ? (
        <LoadingContainer duplicate={duplicate} duplicateLoading={duplicateLoading} progress={progress} />
      ) : null}
      <FullscreenButton
        sx={{ right: `calc(1rem + ${scrollWidth}px)` }}
        disableRipple
        onClick={() => onClickFullscreen()}
        className='nonprint'
      >
        {isFullscreen ? <BiCollapse /> : <BiExpand />}
      </FullscreenButton>
      <MovePageButton
        className='nonprint'
        disableRipple
        onClick={() => onClickPageMove('prev')}
        disabled={currentPage == 0}
      >
        <FaChevronLeft />
      </MovePageButton>
      <MovePageButton
        disableRipple
        className='nonprint'
        sx={{ right: `${scrollWidth}px` }}
        onClick={() => onClickPageMove('next')}
        disabled={currentPage == maxPage}
      >
        <FaChevronRight />
      </MovePageButton>
      <Worksheet className='worksheet-template'>
        {printMethod && ['wrongWord', 'wrongSentence'].includes(printMethod) ? (
          <Header className='worksheet-header'>
            <HeaderContents>
              <DictionaryImageBox>
                <img src={dictionaryIcon}></img>
              </DictionaryImageBox>
              <TitleWrap className='wrong-word'>
                <Title sx={{ height: '100%', display: 'flex', alignItems: 'center' }} className='title'>
                  <Box></Box>
                </Title>
                <NameBox>
                  <Name className='worksheet-name'>이름 : </Name>
                </NameBox>
              </TitleWrap>
            </HeaderContents>
          </Header>
        ) : (
          <Header className='worksheet-header'>
            <HeaderContents>
              <DictionaryImageBox>
                <img src={dictionaryIcon}></img>
              </DictionaryImageBox>
              <TitleWrap>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Title className='title'>
                    <Box></Box>
                  </Title>
                </Box>
                <SubTitleBox>
                  <SubTitle className='worksheet-bookname'></SubTitle>
                  <SubTitle className='worksheet-unitname'></SubTitle>
                </SubTitleBox>
              </TitleWrap>
            </HeaderContents>
          </Header>
        )}

        <Contents className='worksheet-contents'></Contents>
        <Footer className='worksheet-footer'>
          <FooterWrap>
            <FooterContent sx={{ flex: 2 }} className='worksheet-logo'>
              <img src={bi_png} />
            </FooterContent>
            <FooterContent
              sx={{
                justifyContent: 'center',
                flex: 1,
              }}
              className='worksheet-pagenum'
            />
            <FooterContent className='worksheet-cname' sx={{ justifyContent: 'flex-end', flex: 2 }}>
              {userStateData.center_name}
            </FooterContent>
          </FooterWrap>
        </Footer>
      </Worksheet>
      <Container
        id='worksheet-container'
        className='show-scrollbar'
        sx={{ overflow: isFullscreen ? 'auto' : 'hidden', padding: isFullscreen ? 0 : '4px' }}
      >
        <ContainerBox ref={containerRef} fullscreen={isFullscreen ? 'true' : 'false'}>
          <PrintWrap ref={printRef} className='print-wrap'>
            <style type='text/css' media='print'>
              {'\
   @page { size: A4 portrait; }\
'}
            </style>
            {loading ? (
              <Worksheet>
                <Header className='worksheet-header'>
                  <HeaderContents>
                    <DictionaryImageBox>
                      <img src={dictionaryIcon}></img>
                    </DictionaryImageBox>
                    <TitleWrap>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Title className='title'>
                          <Box sx={{ color: title ? 'inherit' : 'rgb(172, 125, 255)' }}>
                            {title ? title : '제목을 입력하세요.'}
                          </Box>
                        </Title>
                      </Box>
                      <SubTitleBox>
                        <SubTitle
                          sx={{ color: 'transparent', backgroundColor: 'transparent !important' }}
                          className='worksheet-bookname'
                        >
                          1
                        </SubTitle>
                        <SubTitle
                          sx={{ color: 'transparent', backgroundColor: 'transparent !important' }}
                          className='worksheet-unitname'
                        >
                          1
                        </SubTitle>
                      </SubTitleBox>
                    </TitleWrap>
                  </HeaderContents>
                </Header>
                <Contents className='worksheet-contents'></Contents>
                <Footer className='worksheet-footer'>
                  <FooterWrap>
                    <FooterContent sx={{ flex: 2 }} className='worksheet-logo'>
                      <img src={bi_png} />
                    </FooterContent>
                    <FooterContent
                      sx={{
                        justifyContent: 'center',
                        flex: 1,
                      }}
                      className='worksheet-pagenum'
                    />
                    <FooterContent className='worksheet-cname' sx={{ justifyContent: 'flex-end', flex: 2 }}>
                      {userStateData.center_name}
                    </FooterContent>
                  </FooterWrap>
                </Footer>
              </Worksheet>
            ) : null}
          </PrintWrap>
        </ContainerBox>
      </Container>
      <Fragment key={`worksheet_${worksheetKey}`}>
        {Object.values(printWordList).map((printBook: PrintBookType, idx1: number) => (
          <Fragment key={`books_${printBook.id}_${idx1}`}>
            {Object.values(printBook.units).map((printUnit: PrintUnitType, idx2: number) => (
              <Box
                className='units'
                id={`${printBook.id}_${printUnit.id}`}
                key={`units_${printUnit.id}_${idx2}`}
                style={{ display: 'none' }}
              >
                {printUnit.words.map((word: PrintWordType, idx3: number) => (
                  <Content
                    id={`${printBook.id}_${printUnit.id}_${word.id}_${word.seq}`}
                    className={`dontsplit font-${printSetting.fontsize} line-${printSetting.line} ${
                      printMethod == 'wrongSentence' ? 'sentence-content' : ''
                    } width-${printSetting.width}`}
                    key={`words_${word.id}_${idx3}`}
                  >
                    <Box>{idx3 + 1}</Box>
                    <Box>
                      <Box component={'span'}>{word.lang == 2 ? null : word.spell}</Box>
                    </Box>
                    <Box>
                      <Box component={'span'}>{word.lang == 1 ? null : word.mean}</Box>
                    </Box>
                  </Content>
                ))}
              </Box>
            ))}
          </Fragment>
        ))}
      </Fragment>
    </Wrapper>
  );
});
