import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TestWorksheetUI } from './TestWorksheet.Presenter';
import { useRecoilValue } from 'recoil';
import { deviceState } from 'recoil/common/device';
import { userState } from 'recoil/model/user';

interface TestWorksheetContainerProps {
  title: string;
  printSetting: PrintSettingType;
  printInfo: PrintInfoType;
  selectWordList: TestPrintWordType[];
  printRef: React.MutableRefObject<any>;
}

export const TestWorksheetContainer = React.memo(function TestWorksheetContainer({
  title,
  printSetting,
  printInfo,
  selectWordList,
  printRef,
}: TestWorksheetContainerProps) {
  const userStateData = useRecoilValue<UserType>(userState);
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { screen_width, screen_height } = deviceStateData;
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [worksheetKey, setWorksheetKey] = useState(0);
  const totalPageRef = useRef(0);
  const questionsPageRef = useRef(0);
  const answersPageRef = useRef(0);
  const [sortingWordList, setSortingWordList] = useState<TestPrintWordType[]>([]);
  const [printTestList, setPrintTestList] = useState<PrintTestType[]>([]);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef<any>(null);
  const [firstWord, setFirstWord] = useState('');

  useEffect(() => {
    setScale();
  }, [screen_width, screen_height, isFullscreen]);

  useEffect(() => {
    setTitle();
  }, [title]);

  useEffect(() => {
    const current_sheet = $('.worksheet').eq(currentPage);
    current_sheet.removeClass('hidden');
    if (currentPage < maxPage)
      $('.worksheet')
        .eq(currentPage + 1)
        .addClass('hidden');
    if (currentPage > 0)
      $('.worksheet')
        .eq(currentPage - 1)
        .addClass('hidden');
  }, [currentPage]);

  useEffect(() => {
    if (selectWordList.length > 0) {
      let sortedWordList;

      switch (printSetting.sort) {
        case 0:
          // grade 기준으로 정렬
          sortedWordList = [...selectWordList];
          break;
        case 1:
          // 무작위로 정렬
          sortedWordList = [...selectWordList];
          for (let i = sortedWordList.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [sortedWordList[i], sortedWordList[j]] = [sortedWordList[j], sortedWordList[i]];
          }
          break;
        case 2:
          // spell 기준으로 정렬
          sortedWordList = [...selectWordList].sort((a, b) => a.spell.localeCompare(b.spell));
          break;
        default:
          sortedWordList = [...selectWordList];
      }

      setSortingWordList(sortedWordList);
    }
  }, [selectWordList, printSetting.sort]);

  useEffect(() => {
    if (sortingWordList.length > 0) {
      const copyWordList = JSON.parse(JSON.stringify(sortingWordList));
      const print_test_list: PrintTestType[] = [];
      for (let i = 0; i < copyWordList.length; i++) {
        if (copyWordList[i].example && (copyWordList[i].type == 'stom1' || copyWordList[i].type == 'mtos1')) {
          copyWordList[i].example = copyWordList[i].example.sort(() => Math.random() - 0.5);
          if (copyWordList[i].type == 'stom1') {
            const answer = copyWordList[i].example.findIndex((mean: string) => mean == copyWordList[i].mean);
            print_test_list.push({
              id: copyWordList[i].id,
              answer: answer + 1,
              question: copyWordList[i].spell,
              example: copyWordList[i].example,
              type: copyWordList[i].type,
            });
          } else if (copyWordList[i].type == 'mtos1') {
            const answer = copyWordList[i].example.findIndex((spell: string) => spell == copyWordList[i].spell);
            print_test_list.push({
              id: copyWordList[i].id,
              answer: answer + 1,
              question: copyWordList[i].mean,
              example: copyWordList[i].example,
              type: copyWordList[i].type,
            });
          }
        } else if (copyWordList[i].type == 'stom2') {
          print_test_list.push({
            id: copyWordList[i].id,
            answer: copyWordList[i].mean,
            question: copyWordList[i].spell,
            type: copyWordList[i].type,
          });
        } else if (copyWordList[i].type == 'mtos2') {
          print_test_list.push({
            id: copyWordList[i].id,
            answer: copyWordList[i].spell,
            question: copyWordList[i].mean,
            type: copyWordList[i].type,
          });
        }
      }
      setPrintTestList(print_test_list);
      setTimeout(() => {
        setWorksheetKey(prev => prev + 1);
      }, 0);
    }
  }, [sortingWordList, printSetting.column, printSetting.fontsize, printSetting.lang, printSetting.line]);

  useEffect(() => {
    if (printTestList.length > 0) {
      setProgress(0);
      setLoading(true);
      setCurrentPage(-1);
      setTimeout(() => {
        init();
      }, 1);
    }
  }, [worksheetKey]);

  useEffect(() => {
    if (worksheetKey > 0) {
      if (printInfo.answer) {
        $('.worksheet.answer').removeClass('d-none');
        setMaxPage(totalPageRef.current - 1);
      } else {
        if (currentPage >= questionsPageRef.current) {
          setCurrentPage(questionsPageRef.current - 1);
        }
        $('.worksheet.answer').addClass('hidden');
        $('.worksheet.answer').addClass('d-none');
        setMaxPage(questionsPageRef.current - 1);
      }
    }
  }, [printInfo.answer]);

  useEffect(() => {
    if (printInfo.logo) {
      $('.worksheet-logo').removeClass('invisible');
    } else {
      $('.worksheet-logo').addClass('invisible');
    }
    if (printInfo.pagenum) {
      $('.worksheet-pagenum').removeClass('invisible');
    } else {
      $('.worksheet-pagenum').addClass('invisible');
    }
    if (printInfo.cname) {
      $('.worksheet-cname').removeClass('invisible');
    } else {
      $('.worksheet-cname').addClass('invisible');
    }
  }, [printInfo]);

  const buildNewsletter = (tagid: string) => {
    if ($(tagid).contents().length > 0) {
      const worksheet = $('.worksheet-template')
        .clone()
        .removeClass('worksheet-template')
        .addClass('worksheet pagebreak')
        .addClass(`page-${totalPageRef.current}`);

      if (tagid == '#answers') {
        worksheet.addClass('answer');
      }

      $('.print-wrap').append(worksheet);
      totalPageRef.current++;
      if (tagid == '#questions') {
        questionsPageRef.current++;
      } else {
        answersPageRef.current++;
      }

      if (tagid == '#questions') {
        $('.worksheet:last .worksheet-footer .worksheet-pagenum').text('- ' + questionsPageRef.current + ' -');
      } else {
        $('.worksheet:last .worksheet-footer .worksheet-pagenum').text('- ' + answersPageRef.current + ' -');
      }
      if (printSetting.column == 0 && tagid == '#questions') {
        $(tagid).columnize({
          columns: 1,
          target: '.worksheet:last .worksheet-contents',
          buildOnce: true,
          overflow: {
            height: 860,
            id: tagid,
            doneFunc: function () {
              $('.worksheet:last').addClass('hidden');
            },
          },
        });
      } else {
        $(tagid).columnize({
          l_rate: 50,
          r_rate: 50,
          columns: 2,
          buildOnce: true,
          target: '.worksheet:last .worksheet-contents',
          overflow: {
            height: 860,
            id: tagid,
            doneFunc: function () {
              $('.worksheet:last').addClass('hidden');
            },
          },
        });
      }
    }
  };

  const init = useCallback(async () => {
    console.log('init');
    const prev_contents = $('.worksheet');
    if (prev_contents.length > 0) {
      prev_contents.remove();
    }
    totalPageRef.current = 0;
    questionsPageRef.current = 0;
    answersPageRef.current = 0;

    let limit = 0;
    const question_tagid = '#questions';
    while ($('#questions').contents().length > 0 && $('.worksheet') && limit < 1000) {
      buildNewsletter(question_tagid);
      limit++;
    }

    limit = 0;
    const answer_tagid = '#answers';
    while ($('#answers').contents().length > 0 && $('.worksheet') && limit < 1000) {
      buildNewsletter(answer_tagid);
      limit++;
    }

    if (printInfo.answer) {
      setMaxPage(totalPageRef.current - 1);
    } else {
      $('.worksheet.answer').addClass('d-none');
      setMaxPage(questionsPageRef.current - 1);
    }
    $('.column').addClass('h-100');
    setTimeout(() => {
      setLoading(false);
      if (firstWord) {
        const classNames = document.getElementById(firstWord)?.parentElement?.parentElement?.parentElement?.className;
        const classList = classNames && classNames.includes(' ') ? classNames.split(' ') : false;

        let page = 0;
        if (classList) {
          for (const className of classList) {
            if (className.includes('page-')) {
              page = Number(className.replace('page-', ''));
            }
          }
        }

        setCurrentPage(page);
      } else {
        setCurrentPage(0);
      }
      setTimeout(renderEnd, 10);
    }, 100);
  }, [printTestList, printSetting, printInfo]);

  const renderEnd = () => {
    const worksheet = $('.worksheet:not(.hidden)');

    if (worksheet.length > 0) {
      setScale();
    } else {
      setTimeout(renderEnd, 10);
    }
  };

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  const onChangeProgress = async (value: number) => {
    await delay(50);
    setProgress(value);
  };

  const onClickPageMove = useCallback(
    (direction: 'prev' | 'next') => {
      if (direction == 'prev') {
        setCurrentPage(prev => prev - 1);
        const first_word = document
          .getElementsByClassName(`page-${currentPage - 1}`)[0]
          .getElementsByClassName('dontsplit')[0].id;
        setFirstWord(first_word);
      } else if (direction == 'next') {
        setCurrentPage(prev => prev + 1);
        const first_word = document
          .getElementsByClassName(`page-${currentPage + 1}`)[0]
          .getElementsByClassName('dontsplit')[0].id;
        setFirstWord(first_word);
      }
    },
    [currentPage],
  );

  const onClickFullscreen = useCallback(() => {
    setIsFullscreen(prev => !prev);
  }, [isFullscreen]);

  const setScale = useCallback(() => {
    const element = document.getElementById('worksheet-container');

    if (element) {
      setScrollWidth(element.offsetWidth - element.clientWidth);
      setScrollHeight(element.offsetHeight - element.clientHeight);
    } else {
      setScrollWidth(0);
      setScrollHeight(0);
    }

    if (isFullscreen) {
      containerRef.current.style.transform = '';
    } else {
      const x = containerRef.current.clientWidth / printRef.current.clientWidth;
      const y = containerRef.current.clientHeight / printRef.current.clientHeight;
      if (x > y) containerRef.current.style.transform = `scale(${y})`;
      else containerRef.current.style.transform = `scale(${x})`;
    }
  }, [screen_width, screen_height, isFullscreen]);

  const setTitle = useCallback(() => {
    const title_element = $('.title > div');
    if (title) {
      title_element.css('color', 'inherit');
      title_element.text(title);
    } else {
      title_element.css('color', 'rgb(172, 125, 255)');
      title_element.text('제목을 입력하세요.');
    }
  }, [title]);

  const makeQuestion = useCallback((idx: number, test: PrintTestType) => {
    const { id, type, question, answer, example } = test;
    const numbers = ['①', '②', '③', '④'];
    let contents: React.ReactNode = <></>;

    if (type == 'stom1') {
      contents = (
        <Fragment>
          <b>{idx + 1}. 다음 단어에 맞는 올바른 뜻을 고르세요.</b>
          <div className='dashed lh-15 qi-12 mq'>{question}</div>
          <>
            {example?.map((item: string, idx2: number) => {
              return (
                <div key={`${id}_${idx2}`} className='lh-18 el-12'>
                  {numbers[idx2]}&nbsp;{item}
                </div>
              );
            })}
          </>
        </Fragment>
      );
    } else if (type == 'stom2') {
      contents = (
        <Fragment>
          <b>{idx + 1}. 주어진 단어의 뜻을 적으세요.</b>
          <div className='dashed lh-15 qi-12 mq'>{question}</div>
          <div className='p_relative' style={{ borderBottom: '1px solid black', height: '32px' }}></div>
        </Fragment>
      );
    } else if (type == 'mtos1') {
      contents = (
        <Fragment>
          <b>{idx + 1}. 다음 뜻에 맞는 올바른 단어를 고르세요.</b>
          <div className='dashed lh-15 qi-12 mq'>{question}</div>
          <>
            {example?.map((item: string, idx2: number) => {
              return (
                <div key={`${id}_${idx2}`} className='lh-18 el-12'>
                  {numbers[idx2]}&nbsp;{item}
                </div>
              );
            })}
          </>
        </Fragment>
      );
    } else if (type == 'mtos2') {
      contents = (
        <Fragment>
          <b>{idx + 1}. 주어진 한글 뜻에 맞게 단어를 적으세요.</b>
          <div className='dashed lh-15 qi-12 mq'>{question}</div>
          <div className='p_relative' style={{ borderBottom: '1px solid black', height: '32px' }}></div>
        </Fragment>
      );
    }

    return contents;
  }, []);

  const makeAnswer = useCallback((idx: number, test: PrintTestType) => {
    const { id, type, question, answer, example } = test;
    const numbers = ['①', '②', '③', '④'];
    let contents: React.ReactNode = <></>;

    if (typeof answer == 'number') {
      contents = (
        <Fragment>
          <b>{idx + 1}.</b>&nbsp;
          {numbers[answer - 1]}번
        </Fragment>
      );
    } else if (typeof answer == 'string') {
      contents = (
        <Fragment>
          <b>{idx + 1}.</b>&nbsp;
          {answer}
        </Fragment>
      );
    }

    return contents;
  }, []);

  const props = {
    worksheetKey,
    currentPage,
    maxPage,
    loading,
    progress,
    scrollWidth,
    scrollHeight,
    isFullscreen,
    printSetting,
    printTestList,
    userStateData,
    printRef,
    containerRef,
    onClickPageMove,
    onClickFullscreen,
    makeQuestion,
    makeAnswer,
  };

  return <TestWorksheetUI {...props} />;
});
