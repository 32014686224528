import React from 'react';
import { StyledToastBar } from './ToastBarStyles';
import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';
import Grow, { GrowProps } from '@mui/material/Grow';

function GrowTransition(props: GrowProps) {
  return <Grow {...props} />;
}

type PositioningType = {
  payModalHeaderTop: number;
  payModalHeaderHeight: number;
  toastHeight: number;
};

interface ToastBarPresentersProps {
  visible: boolean;
  message: string;
  time: number;
  backgroundColor?: string;
  handleClose: (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => void;
  positioning?: Partial<PositioningType>;
}

export const ToastBarPresenters = React.memo(function ToastBarPresenters(props: ToastBarPresentersProps) {
  const { visible, message, time, backgroundColor, handleClose, positioning } = props;
  const vertical = 'top';
  const horizontal = 'center';
  const topValue =
    message.includes('추천코드') &&
    backgroundColor == 'red' &&
    positioning &&
    positioning.payModalHeaderTop &&
    positioning.toastHeight &&
    positioning.payModalHeaderHeight
      ? positioning.payModalHeaderTop - (positioning.toastHeight - positioning.payModalHeaderHeight) / 2 - 23.5
      : null;

  return (
    <Snackbar
      id='toast-alert'
      anchorOrigin={{ vertical, horizontal }}
      open={visible}
      onClose={handleClose}
      autoHideDuration={time}
      TransitionComponent={GrowTransition}
      sx={{
        marginTop: topValue !== null ? `${topValue}px` : '7rem',

        '@media (min-width: 962px) and (max-width: 1265px)': {
          marginTop: topValue !== null ? `${topValue}px` : '3rem',
        },

        '@media (min-width: 770px) and (max-width: 961px)': {
          marginTop: topValue !== null ? `${topValue}px` : '2.75rem',
        },

        '@media (max-width: 769px)': {
          marginTop: topValue !== null ? `${topValue}px` : '2.25rem',
        },
      }}
    >
      <StyledToastBar icon={false} backgroundColor={backgroundColor}>
        {message}
      </StyledToastBar>
    </Snackbar>
  );
});
