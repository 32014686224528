import { atom, SetterOrUpdater } from 'recoil';

export interface ModalServiceNoticeType {
  visible: boolean;
}

export const modalServiceNoticeState = atom<ModalServiceNoticeType>({
  key: 'modalServiceNotice',
  default: {
    visible: false,
  },
});

interface ModalServiceNoticeSetter {
  setModalServiceNotice: SetterOrUpdater<ModalServiceNoticeType>;
}

export const openModalServiceNotice = ({ setModalServiceNotice }: ModalServiceNoticeSetter) => {
  setModalServiceNotice({
    visible: true,
  });
};

export const closeModalServiceNotice = ({ setModalServiceNotice }: ModalServiceNoticeSetter) => {
  setModalServiceNotice({
    visible: false,
  });
};
