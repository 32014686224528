export const BASE_URL = process.env.REACT_APP_NODE_ENV == 'production' ? '' : '';
export const CDN =
  process.env.REACT_APP_NODE_ENV == 'production'
    ? 'https://etltuzgdzyaa1067545.cdn.ntruss.com/longvoca/'
    : 'https://etltuzgdzyaa1067545.cdn.ntruss.com/longvoca-dev/';
export const TTS_CDN = 'https://kr.object.ncloudstorage.com/longvoca-tts/examples/';
export const IMAGE_DIR = 'wordimages/';
export const VOICE_DIR = 'voices/';
export const LECTURE_DIR = 'lecture/';
export const VOICE_TTS_CDN = `https://kr.object.ncloudstorage.com/voca-tts/`;
export const VOICE_TTS_URL = `https://pwu7jsh8kk.apigw.ntruss.com/tts/v1/fPGur2foEm/json`;
export const NG_CDN = 'https://kr.object.ncloudstorage.com/cdn-irontrain/ngstudy';
export const ADMIN_URL =
  process.env.REACT_APP_NODE_ENV == 'production'
    ? process.env.REACT_APP_REAL_ADMIN_HOST
    : process.env.REACT_APP_DEV_ADMIN_HOST;
export const HOMEPAGE_URL =
  process.env.REACT_APP_NODE_ENV == 'production'
    ? process.env.REACT_APP_REAL_HOMEPAGE
    : process.env.REACT_APP_DEV_HOMEPAGE;
export const STUDENT_URL =
  process.env.REACT_APP_NODE_ENV == 'production'
    ? process.env.REACT_APP_REAL_STUDENT_HOST
    : process.env.REACT_APP_DEV_STUDENT_HOST;

import MemorizeTutorialImage1 from 'assets/images/tutorial/t1.png';
import MemorizeTutorialImage2 from 'assets/images/tutorial/t2.png';
import MemorizeTutorialImage3 from 'assets/images/tutorial/t3.png';
import MemorizeTutorialImage4 from 'assets/images/tutorial/t4.png';
import BasicTutorialImage1 from 'assets/images/tutorial/t4.png';
import BasicTutorialImage2 from 'assets/images/tutorial/t5.png';
import BasicTutorialImage3 from 'assets/images/tutorial/t6.png';
import AdvancedTutorialImage1 from 'assets/images/tutorial/t7.png';
import AdvancedTutorialImage2 from 'assets/images/tutorial/t8.png';

import arrow from 'assets/images/tutorial/content/arrow.png';
import key01 from 'assets/images/tutorial/content/key01.png';
import key02 from 'assets/images/tutorial/content/key02.png';
import key03 from 'assets/images/tutorial/content/key03.png';
import key04 from 'assets/images/tutorial/content/key04.png';
import key05 from 'assets/images/tutorial/content/key05.png';
import key06 from 'assets/images/tutorial/content/key06.png';
import search from 'assets/images/tutorial/content/search.png';
import wave from 'assets/images/tutorial/content/wave.png';
import t_s_setting_1 from 'assets/images/tutorial/setting01.png';
import t_s_setting_2 from 'assets/images/tutorial/setting02.png';

export const LEARNING_PATHS = [
  // SPEAK
  '/report/lookup/speak',
  '/recent/speak',
  '/book/speak/speak',
  // longvoca, school
  '/report/lookup/learning',
  '/recent/learning',
  '/book/study/learning',
  '/book/school/learning',
  // grammar
  '/report/lookup/grammar',
  '/recent/grammar',
  '/book/grammar/learning',
];

export const ALL_PERSONAL_PRODUCT_PRICES = {
  M: { full: 89000, earlybird: 0, pkg: 0 },
  H: { full: 534000, earlybird: 40000, pkg: 40000 },
  Y: { full: 1068000, earlybird: 379000, pkg: 300000 },
};

export const SPEAK_PERSONAL_PRODUCT_PRICES = {
  M: { full: 31000, earlybird: 0, pkg: 0 },
  H: { full: 186000, earlybird: 20000, pkg: 20000 },
  Y: { full: 372000, earlybird: 113000, pkg: 100000 },
};

export const VOCA_PERSONAL_PRODUCT_PRICES = {
  M: { full: 38000, earlybird: 0, pkg: 0 },
  H: { full: 228000, earlybird: 20000, pkg: 20000 },
  Y: { full: 456000, earlybird: 157000, pkg: 100000 },
};

export const GRAMMAR_PERSONAL_PRODUCT_PRICES = {
  M: { full: 45000, earlybird: 0, pkg: 0 },
  H: { full: 270000, earlybird: 20000, pkg: 20000 },
  Y: { full: 540000, earlybird: 201000, pkg: 100000 },
};

export const DEFAULT_SPEAK_CATEGORY: { [key: string]: number } = {
  d: 33,
  d1: 33,
  d2: 33,
  d3: 33,
  a1: 33,
  a2: 34,
  a3: 35,
  a4: 36,
  a5: 37,
  a6: 38,
  b1: 39,
  b2: 40,
  b3: 24,
  c1: 42,
  c2: 43,
  c3: 20,
  e1: 20,
  e2: 20,
  e3: 20,
  e4: 20,
  e: 20,
};

export const PERSONAL_PRODUCT_CODES: string[][] = [
  ['G003M', 'G003H', 'G003Y'],
  ['A003M', 'A003H', 'A003Y'],
  ['V003M', 'V003H', 'V003Y'],
  ['S003M', 'S003H', 'S003Y'],
];

export const PERSONAL_PRODUCT_PRICES: { [key: string]: number } = {
  V003Y: 199000,
  S003Y: 159000,
  G003Y: 239000,
  A003Y: 389000,
};

export const P_SPEAK_PRODUCT_CODES = ['S003Y', 'S003H', 'S003M', 'S003DY', 'S003TY'];

export const IMAGE_DATA_BASE_64 = '';
export const EMPTY_BANNER: BannerType = {
  enddate: '2040-02-15 23:59:59',
  height: '96',
  href: '',
  id: 'defaul',
  modifydate: '2023-01-01 00:00:00',
  path: `longvoca_banner.png`,
  postdate: '2023-01-01 00:00:00',
  priority: '999',
  short_path: `longvoca_banner.png`,
  startdate: '2023-01-01 00:00:00',
  status: '진행 중',
  target: '_blank',
  title: 'ready',
  ver: '2',
  width: '499',
};

export const FIND_TEXT_FIELD_PLACEHOLDER_LIST: { [key: string]: string } = {
  name: '이름 입력',
  loginid: '아이디 입력',
  phone: '휴대폰 번호 입력',
  cert_num: '인증번호 입력',
};

export const ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '.',
  '?',
  '!',
  "'",
  ',',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '-',
];

/* 대표관리자 type */
export const PRESIDENT_OF_CENTER_TYPE = 21;

/* 임시 체험 학생의 account_id */
export const TEMP_STUDENTS_ACCOUNT_IDS = [1, 2, 3, 4, 5];

//** 문법학습 모드 **//
export const GRAMMAR_MODES = ['grammar_t', 'grammar_b', 'grammar_d'];

//** 학습 방식 **//
export const MEMORIZE_HOT = 1;
export const MEMORIZE_NEW = 2;
export const BASIC = 3;
export const ADVANCED = 4;
export const WRONG = 5;
export const SPEAK = 6;
//** 문법학습 방식 **//
export const GRAMMAR_T = 't';
export const GRAMMAR_B = 'b';
export const GRAMMAR_D = 'd';
//** 학습 모듈 분류 **//
export const FLASHCARD = 1;
export const WORDBINGO = 2;
export const POPQUIZ = 3;
export const DICTATION = 4;
export const WRITING = 5;
export const SPEAKING = 6;
export const VIDEO = 7;
export const CHECK = 8;
//** 암기학습 모듈 분류 **//
export const EXAMPLE = 1;
export const LECTURE = 2;
export const MEAN = 3;
export const SPELL = 4;
export const IMAGE = 5;
export const DICTIONARY = 6;
//** 문법학습 모듈 분류 **//
export const GRAMMAR_CAB = 'CAB';
export const GRAMMAR_MC = 'MC';
export const GRAMMAR_SC = 'SC';
export const GRAMMAR_SA = 'SA';

//** 학습 헤더 설정 **//
export const HEADER: { [key: number]: string } = {
  [MEMORIZE_HOT]: '암기학습',
  [MEMORIZE_NEW]: '암기학습',
  [BASIC]: '기본학습',
  [ADVANCED]: '심화학습',
  [WRONG]: '오답학습',
  [SPEAK]: '생활영어',
};

export const MODS: { [key: number]: string } = {
  [MEMORIZE_HOT]: 'memorize_hot',
  [MEMORIZE_NEW]: 'memorize_new',
  [BASIC]: 'basic',
  [ADVANCED]: 'advanced',
  [WRONG]: 'wrong',
  [SPEAK]: 'speak',
};

export const MODULES: { [key: number]: string } = {
  [FLASHCARD]: 'flash',
  [WORDBINGO]: 'bingo',
  [POPQUIZ]: 'popquiz',
  [DICTATION]: 'dictation',
  [WRITING]: 'writing',
  [SPEAKING]: 'speaking',
};

export const MODULES_LABEL: { [key: number]: string } = {
  [FLASHCARD]: 'FLASH CARD',
  [WORDBINGO]: 'WORD BINGO',
  [POPQUIZ]: 'POP QUIZ',
  [DICTATION]: 'DICTATION',
  [WRITING]: 'WRITING',
  [SPEAKING]: 'SPEAKING',
};

export const MEMORIZE_MODULES: { [key: number]: string } = {
  [EXAMPLE]: 'example',
  [LECTURE]: 'lecture',
  [MEAN]: 'mean',
  [SPELL]: 'spell',
  [IMAGE]: 'image',
  [DICTIONARY]: 'dictionary',
};

export const MEMORIZE_LABEL: { [key: number]: string } = {
  [EXAMPLE]: '예문',
  [LECTURE]: '강의',
  [MEAN]: '의미',
  [SPELL]: '철자',
  [IMAGE]: '이미지',
  [DICTIONARY]: '사전',
};

export const SPEAK_LABEL: { [key: number]: string } = {
  [VIDEO]: '추천 학습영상',
  [CHECK]: '주요예문 점검',
  [SPEAKING]: '실전 회화학습',
};

export const THEORY_LABEL: { [key: number]: string } = {
  1: '이론영상',
  2: '단어점검',
  3: '이론점검',
};

export const SEASON = {
  SPRING: [3, 4, 5],
  SUMMER: [6, 7, 8],
  FALL: [9, 10, 11],
  WINTER: [12, 1, 2],
};

export const FONT_STD = {
  HEAD: 1.8,
  STD: 2,
  MAIN: 1.6,
  FOOT: 1.8,
  WIDTH: 49.5,
  HEIGHT: 8,
};

export const effect_sounds: Sounds = {
  after_login: '/assets/audio/effect/after_login.mp3',
  alert: '/assets/audio/effect/alert.mp3',
  click: '/assets/audio/effect/click.mp3',
  confirm: '/assets/audio/effect/confirm.mp3',
  correct: '/assets/audio/effect/correct.mp3',
  dicbtn: '/assets/audio/effect/dicbtn.mp3',
  earthquake: '/assets/audio/effect/earthquake.mp3',
  fail: '/assets/audio/effect/fail.mp3',
  lecture: '/assets/audio/effect/lecture.mp3',
  pass: '/assets/audio/effect/pass.mp3',
  report: '/assets/audio/effect/report.mp3',
  show: '/assets/audio/effect/show.mp3',
  speaking_rec: '/assets/audio/effect/speaking_rec.mp3',
  study_start: '/assets/audio/effect/study_start.mp3',
  wrong: '/assets/audio/effect/wrong.mp3',
  speaking_count_1: '/assets/audio/effect/speaking_count_1.mp3',
  speaking_count_2: '/assets/audio/effect/speaking_count_2.mp3',
  alert_video: '/assets/audio/effect/alert_video_2.mp3',
};

export const explain_tutorial_text: { [key: string]: string } = {
  m_1: '올바른 암기를 위해 <b>예문 문제를 풀어야</b>지만,\r\n<b>다음 학습으로</b> 넘어갈 수 있습니다.',
  m_2: '빠른 학습을 위해 <b>4지선다 객관식 문제</b>는\r\n마우스 보다는, <b>번호 + Enter 로 진행</b>해주세요!',
  m_3: '<b>강의 음성은 재생바</b>로 위치 조절이 가능하며,\r\n일시정지와 재생은 <b>스페이스 바</b>로 활용해주세요.',
  m_4: '학습카드는 <b>방향키 or 드래그로 상하좌우 이동</b> 가능하며,\r\n<b>다시암기 1번, 암기완료 2번</b>을 눌러주세요.',
  b_1: '플래시 카드는 <b>방향키 or 드래그로 이동</b> 가능하며,\r\n <b>스페이스 바</b>로 일시정지/재생 가능합니다.',
  b_2: '화면 상단의 <b>학습단계 박스를 선택</b>하여,\r\n<b>자유롭게 학습 과정을 이동</b>할 수 있습니다.',
  b_3: '틀린 단어는 모아 모아 <b>오답 학습에 자동 생성</b>되고,\r\n<b>★을 눌러 포켓노트 암기장</b>으로 이용해보세요.',
  a_1: '<b>제시된 알파벳</b>을 조합하여\r\n빈 칸에 <b>철자 클릭 or 타이핑하여 완성</b>하세요!',
  a_2: '<b>빨간색 원형의 마이크 버튼</b>을 누르고,\r\n<b>띠리링 소리 이후,</b> 제시된 문장을 따라 말해보세요!',
  s_1: '화면 상단의 <b>학습단계 박스를 선택</b>하여,\r\n<b>자유롭게 학습 과정을 이동</b>할 수 있습니다.',
  s_2: '학습 문장은 <b>방향키로 상하 이동</b> 가능하며,\r\n<b>효과적인 학습을 위해 3번씩 반복</b>하여 들어주세요.',
  s_3: '예문의 주요 어휘 <b>객관식 문제</b>는\r\n마우스 보다는, <b>번호 + Enter 로 진행</b>해주세요!',
  s_4: '제시된 문장을 <b>AI 선생님의 목소리</b>로 들어보고,\r\n<b>음성 인식을 눌러</b> 따라 말해보세요!',
};

interface TutorialContent {
  key: string;
  tutorialImage: string;
  explainText: string;
}

export const memorize_tutorial: TutorialContent[] = [
  {
    key: 'm_1',
    tutorialImage: MemorizeTutorialImage1,
    explainText: explain_tutorial_text.m_1,
  },
  {
    key: 'm_2',
    tutorialImage: MemorizeTutorialImage2,
    explainText: explain_tutorial_text.m_2,
  },
  {
    key: 'm_3',
    tutorialImage: MemorizeTutorialImage3,
    explainText: explain_tutorial_text.m_3,
  },
  {
    key: 'm_4',
    tutorialImage: MemorizeTutorialImage4,
    explainText: explain_tutorial_text.m_4,
  },
];

export const basic_tutorial: TutorialContent[] = [
  {
    key: 'b_1',
    tutorialImage: BasicTutorialImage1,
    explainText: explain_tutorial_text.b_1,
  },
  {
    key: 'b_2',
    tutorialImage: BasicTutorialImage2,
    explainText: explain_tutorial_text.b_2,
  },
  {
    key: 'b_3',
    tutorialImage: BasicTutorialImage3,
    explainText: explain_tutorial_text.b_3,
  },
];

export const advanced_tutorial: TutorialContent[] = [
  {
    key: 'a_1',
    tutorialImage: AdvancedTutorialImage1,
    explainText: explain_tutorial_text.a_1,
  },
  {
    key: 'a_2',
    tutorialImage: AdvancedTutorialImage2,
    explainText: explain_tutorial_text.a_2,
  },
];

export const speak_tutorial: TutorialContent[] = [
  {
    key: 's_1',
    tutorialImage: BasicTutorialImage2,
    explainText: explain_tutorial_text.s_1,
  },
  {
    key: 's_2',
    tutorialImage: MemorizeTutorialImage4,
    explainText: explain_tutorial_text.s_2,
  },
  {
    key: 's_3',
    tutorialImage: MemorizeTutorialImage1,
    explainText: explain_tutorial_text.s_3,
  },
  {
    key: 's_4',
    tutorialImage: AdvancedTutorialImage2,
    explainText: explain_tutorial_text.s_4,
  },
];

export const MEMORIZE_IMGS: string[] = [
  t_s_setting_1,
  t_s_setting_2,
  MemorizeTutorialImage1,
  MemorizeTutorialImage2,
  MemorizeTutorialImage3,
  MemorizeTutorialImage4,
  key01,
  key02,
  key03,
  key04,
  key05,
];
export const BASIC_IMGS: string[] = [
  t_s_setting_1,
  t_s_setting_2,
  BasicTutorialImage1,
  BasicTutorialImage2,
  BasicTutorialImage3,
  key04,
  key03,
  search,
];
export const ADVANCED_IMGS: string[] = [
  t_s_setting_1,
  t_s_setting_2,
  AdvancedTutorialImage1,
  AdvancedTutorialImage2,
  key06,
  arrow,
  wave,
];

export const CARD_PAYMENT_TERMS_1 = `
                제 1 장 총칙<br><br><br>
                제 1 조【 목 적 】<br>
                이 약관은 효성에프엠에스 주식회사 (이하 '회사'라 합니다)가
                제공하는 전자지급결제대행서비스 및 전자고지결제서비스를
                이용자가 이용함에 있어 회사와 이용자 사이의 전자금융거래에
                관한 기본적인 사항을 정함을 목적으로 합니다.<br><br><br>
                제 2 조【 용어의 정의 】<br>
                이 약관에서 정하는 용어의 정의는 다음과 같습니다.<br>
                ① '전자금융거래'라 함은 회사가 전자적 장치를 통하여
                전자지급결제대행서비스 및 전자고지결제서비스(이하
                '전자금융거래 서비스'라고 합니다)를 제공하고, 이용자가 회사의
                종사자와 직접 대면하거나 의사소통을 하지 아니하고 자동화된
                방식으로 이를 이용하는 거래를 말합니다.<br>
                ② '전자지급결제대행서비스'라 함은 전자적 방법으로 재화의 구입
                또는 용역의 이용에 있어서 지급결제정보를 송신하거나 수신하는
                것 또는 그 대가의 정산을 대행하거나 매개하는 서비스를
                말합니다.<br>
                ③ '전자고지결제서비스'라 함은 각종 요금 등의 지급과 관련하여
                "청구서"등의 전자적인 방법으로 자금 내역을 고지하고, 이를
                수수하여 그 정산을 대행하는 업무를 제공하는 시스템 및 서비스
                일체를 말합니다.<br>
                ④ '이용자'라 함은 이 약관에 동의하고 회사가 제공하는
                전자금융거래 서비스를 이용하는 자를 말합니다.<br>
                ⑤ '접근매체'라 함은 전자금융거래에 있어서 거래지시를 하거나
                이용자 및 거래내용의 진실성과 정확성을 확보하기 위하여
                사용되는 수단 또는 정보로서 전자식 카드 및 이에 준하는 전자적
                정보(신용카드번호를 포함한다), '전자서명법'상의 인증서, 회사에
                등록된 이용자번호, 이용자의 생체정보, 이상의 수단이나 정보를
                사용하는데 필요한 비밀번호 등 전자금융거래법 제2조 제10호에서
                정하고 있는 것을 말합니다.<br>
                ⑥ '청구서'라 함은 '회사'가 수납기관을 대행하여 이용자에게
                전송하는 전자적인 방식의 고지방법을 말합니다.<br>
                ⑦ '거래지시'라 함은 이용자가 본 약관에 의하여 체결되는
                전자금융거래계약에 따라 회사에 대하여 전자금융거래의 처리를
                지시하는 것을 말합니다.<br>
                ⑧ '오류'라 함은 이용자의 고의 또는 과실 없이 전자금융거래가
                전자금융거래계약 또는 이용자의 거래지시에 따라 이행되지 아니한
                경우를 말합니다.<br><br><br>
                제 3 조【 약관의 명시 및 변경 】<br>
                ① 회사는 이용자가 전자금융거래 서비스를 이용하기 전에 이
                약관을 게시하고 이용자가 이 약관의 중요한 내용을 확인할 수
                있도록 합니다.<br>
                ② 회사는 이용자의 요청이 있는 경우 전자문서의 전송방식에
                의하여 본 약관의 사본을 이용자에게 교부합니다.<br>
                ③ 회사가 약관을 변경하는 때에는 그 시행일 1월 전에 변경되는
                약관을 회사가 제공하는 전자금융거래 서비스 이용 초기화면 및
                회사의 홈페이지에 게시함으로써 이용자에게 공지합니다.<br>
                ④ 회사가 전항에 따라 개정약관을 공지 또는 통지하면서 이용자가
                30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로
                본다는 뜻을 명확하게 공지 또는 통지하였음에도 이용자가
                명시적으로 거부의사를 표명하지 아니한 경우 이용자가 개정약관에
                동의한 것으로 본다.<br><br><br>
                제 4 조【 전자지급결제대행서비스의 종류 】<br>
                회사가 제공하는 전자지급결제대행서비스는 지급결제수단에 따라
                다음과 같이 구별됩니다.<br>
                ① 신용카드결제대행서비스: 이용자가 결제대금의 지급을 위하여
                제공한 지급결제수단이 신용카드인 경우로서, 회사가
                전자결제시스템을 통하여 신용카드 지불정보를 송,수신하고
                결제대금의 정산을 대행하거나 매개하는 서비스를 말합니다.<br>
                ② 자동이체서비스 : 이용자가 정기적으로 납부해야 할 물품대금,
                용역대금 등 각종 수납자금의 납부가 자동으로 이루어지는 업무를
                말합니다.<br>
                1.이용자가 납부하여야 할 요금에 대하여 별도의 통지 없이 본인의
                지정출금계좌에서 수납기관이 정한 지정 출금일(휴일인 경우
                익영업일)에 출금대체 납부하여야 합니다.<br>
                2.출금이체를 위하여 지정출금계좌의 예금을 출금하는 경우에는
                예금약관이나 약정서의 규정에 불구하고 예금청구서나 수표 없이
                출금이체 처리절차에 의하여 출금하여도 이의가 없습니다.<br>
                3.출금이체 지정계좌의 예금잔액(자동대출약정이 있는 경우
                대출한도 포함)이 지정 출금일 현재 수납기관의 청구금액보다
                부족하거나, 예금의 지급제한 또는 약정대출의 연체 등으로
                대체납부가 불가능한 경우의 손해는 본인의 책임으로 합니다.<br>
                4.지정출금일에 동일한 수종의 출금이체청구가 있는 경우의
                출금우선순위는 출금은행이 정하는 바에 따르도록 합니다.<br>
                5.출금이체 신규신청에 의한 이체개시일은 수납기관의 사정에
                의하여 결정됩니다.<br>
                6.출금이체신청(신규, 해지)은 해당납기일 30일전까지 신청서를
                제출하여야 합니다.<br>
                7.출금이체 신청에 의한 지정계좌에서의 출금은 수납기관의
                청구대로 출금키로 하며 출금요금에 이의가 있는 경우에는 본인과
                수납기관이 협의하여 조정키로 합니다.<br>
                8.출금이체금액은 해당 지정출금일 은행 영업시간 내에 입금된
                예금(지정출금일에 입금된 타점권은 제외)에 한하여 출금처리
                됩니다.<br>
                9.이 약관은 신청서를 수납기관에 직접 제출하여 출금이체를
                신청한 경우에도 적용합니다.<br>
                ③ 실시간출금서비스: 이용자가 결제대금을 회사의
                전자결제시스템을 통하여 금융기관에 등록한 자신의 계좌에서
                실시간으로 출금하여 원하는 계좌로 이체할 수 있는 서비스를
                말합니다.<br>
                ④ 가상계좌서비스: 이용자가 결제대금을 현금으로 결제하고자 경우
                회사의 전자결제시스템을 통하여 자동으로 이용자만의 고유한
                일회용 계좌의 발급을 통하여 결제대금의 지급이 이루어지는
                서비스를 말합니다.<br><br><br>
                제 5 조【 이용시간 】<br>
                ① 회사는 이용자에게 연중무휴 1일 24시간 전자금융거래 서비스를
                제공함을 원칙으로 합니다. 단, 금융기관 기타 결제수단
                발행업자의 사정에 따라 달리 정할 수 있습니다.<br>
                ② 회사는 정보통신설비의 보수, 점검 기타 기술상의 필요나
                금융기관 기타 결제수단 발행업자의 사정에 의하여 서비스 중단이
                불가피한 경우, 서비스 중단 3일 전까지 게시 가능한 전자적
                수단을 통하여 서비스 중단 사실을 게시한 후 서비스를 일시
                중단할 수 있습니다. 다만, 시스템 장애복구, 긴급한 프로그램
                보수, 외부요인 등 불가피한 경우에는 사전 게시 없이 서비스를
                중단할 수 있습니다.<br><br><br>
                제 6 조【 접근매체의 선정과 사용 및 관리 】<br>
                ① 회사는 전자금융거래 서비스 제공 시 접근매체를 선정하여
                이용자의 신원, 권한 및 거래지시의 내용 등을 확인할 수
                있습니다.<br>
                ② 이용자는 접근매체를 제3자에게 대여하거나 사용을 위임하거나
                양도 또는 담보 목적으로 제공할 수 없습니다.<br>
                ③ 이용자는 자신의 접근매체를 제3자에게 누설 또는 노출하거나
                방치하여서는 안되며, 접근매체의 도용이나 위조 또는 변조를
                방지하기 위하여 충분한 주의를 기울여야 합니다.<br>
                ④ 회사는 이용자로부터 접근매체의 분실이나 도난 등의 통지를
                받은 때에는 그 때부터 제3자가 그 접근매체를 사용함으로 인하여
                이용자에게 발생한 손해를 배상할 책임이 있습니다.<br><br><br>
                제 7 조【 거래내용의 확인 】<br>
                ① 회사는 이용자와 미리 약정한 전자적 방법을 통하여 이용자의
                거래내용(이용자의 '오류정정 요구사실 및 처리결과에 관한
                사항'을 포함합니다)을 확인할 수 있도록 하며, 이용자의 요청이
                있는 경우에는 요청을 받은 날로부터 2주 이내에 모사전송 등의
                방법으로 거래내용에 관한 서면을 교부합니다.<br>
                ② 회사는 제①항에 따른 이용자의 거래내용 서면 교부 요청을 받은
                경우 전자적 장치의 운영장애, 그 밖의 사유로 거래내용을 제공할
                수 없는 때에는 즉시 이용자에게 전자문서 전송(전자우편을 이용한
                전송을 포함합니다)의 방법으로 그러한 사유를 알려야 하며,
                전자적 장치의 운영장애 등의 사유로 거래내용을 제공할 수 없는
                기간은 제 ①항의 거래내용에 관한 서면의 교부기간에 산입하지
                아니합니다.<br>
                ③ 제 ①항의 대상이 되는 거래내용 중 대상기간이 5년인 것은 다음
                각호와 같습니다.<br>
                1. 거래계좌의 명칭 또는 번호<br>
                2. 거래의 종류 및 금액<br>
                3. 거래상대방을 나타내는 정보<br>
                4. 거래일시<br>
                5. 전자적 장치의 종류 및 전자적 장치를 식별할 수 있는 정보<br>
                6. 회사가 전자금융거래의 대가로 받은 수수료<br>
                7. 이용자의 출금동의에 관한 사항<br>
                8. 해당 전자금융거래와 관련한 전자적 장치의 접속기록<br>
                9. 전자금융거래의 신청 및 조건의 변경에 관한 사항<br>
                10. 건당 거래금액이 1만원을 초과하는 전자금융거래에 관한
                기록<br>
                ④ 제 ①항의 대상이 되는 거래내용 중 대상기간이 1년인 것은 다음
                각호와 같습니다.<br>
                1. 건당 거래금액이 1만원 이하인 소액 전자금융거래에 관한
                기록<br>
                2. 전자지급수단 이용과 관련된 거래승인에 관한 기록<br>
                3. 이용자의 오류정정 요구 사실 및 처리결과에 관한 사항<br><br><br>
                제 8 조【 오류의 정정 등 】<br>
                ①이용자는 전자금융거래 서비스를 이용함에 있어 오류가 있음을
                인지한 때에는 회사에 대하여 그 정정을 요구할 수 있습니다.<br>
                ② 회사는 전항의 규정에 따른 오류의 정정요구를 받은 때에는 이를
                즉시 조사하여 처리한 후 정정요구를 받은 날부터 2주 이내에 그
                결과를 이용자에게 알려 드립니다.<br><br><br>
                제 9 조【 회사의 책임 】<br>
                ① 회사는 전자금융거래를 위한 전자적 장치 또는 ‘정보통신망
                이용촉진 및 정보보호 등에 관한 법률’ 제2조제1항제1호에 따른
                정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 획득한
                접근매체의 이용으로 발생한 사고로 인하여 이용자에게 그 손해가
                발생한 경우에는 그 손해를 배상할 책임이 있습니다.<br>
                ② 접근매체의 위조나 변조로 발생한 사고로 인하여 이용자에게
                발생한 손해에 대하여 배상책임이 있습니다. 다만 이용자가 제6조
                제2항에 위반하거나 제3자가 권한없이 이용자의 접근매체를
                이용하여 전자금융거래를 할 수 있음을 알았거나 알 수 있었음에도
                불구하고 이용자가 자신의 접근매체를 누설 또는 노출하거나
                방치한 경우 그 책임의 전부 또는 일부를 이용자가 부담하게 할 수
                있습니다.<br>
                ③ 회사는 계약체결 또는 거래지시의 전자적 전송이나 처리과정에서
                발생한 사고로 인하여 이용자에게 그 손해가 발생한 경우에는 그
                손해를 배상할 책임이 있습니다. 다만 본조 제2항 단서에
                해당하거나 법인('중소기업기본법' 제2조 제2항에 의한 소기업을
                제외합니다)인 이용자에게 손해가 발생한 경우로서 회사가 사고를
                방지하기 위하여 보안절차를 수립하고 이를 철저히 준수하는 등
                합리적으로 요구되는 충분한 주의의무를 다한 경우 그 책임의 전부
                또는 일부를 이용자가 부담하게 할 수 있습니다.<br><br><br>
                제 10 조【 전자지급거래계약의 효력 】<br>
                ① 회사는 이용자의 거래지시가 전자지급거래에 관한 경우 그
                지급절차를 대행하며, 전자지급거래에 관한 거래지시의 내용을
                전송하여 지급이 이루어지도록 합니다.<br>
                ② 회사는 이용자의 전자지급거래에 관한 거래지시에 따라
                지급거래가 이루어지지 않은 경우 수령한 자금을 이용자에게
                반환하여야 합니다.<br><br><br>
                제 11 조【 거래지시의 철회 】<br>
                ① 이용자는 전자금융거래에 관한 거래지시의 경우 지급의 효력이
                발생하기 전까지 거래지시를 철회할 수 있습니다.<br>
                ② 전항의 지급의 효력이 발생한 시점이란 (i) 전자자금이체의
                경우에는 거래 지시된 금액의 정보에 대하여 수취인의 계좌가
                개설되어 있는 금융기관의 계좌 원장에 입금기록이 끝난 때 (ii)
                그 밖의 전자지급수단으로 지급하는 경우에는 거래 지시된 금액의
                정보가 수취인의 계좌가 개설되어 있는 금융기관의 전자적 장치에
                입력이 끝난 때를 말합니다.<br>
                ③ 이용자는 지급의 효력이 발생한 경우에는 전자상거래 등에서의
                소비자보호에 관한 법률 등 관련 법령상 청약의 철회의 방법등으로
                결제대금을 반환 받을 수 있습니다.<br><br><br>
                제 12 조【 전자금융거래 서비스 이용 기록의 생성 및 보존 】<br>
                ① 회사는 이용자가 전자금융거래의 내용을 추적, 검색하거나 그
                내용에 오류가 발생한 경우에 이를 확인하거나 정정할 수 있는
                기록을 생성하여 보존합니다.<br>
                ② 전항의 규정에 따라 회사가 보존하여야 하는 기록의 종류 및
                보존 기간은 제7조에서 정한 바에 따릅니다.<br><br><br>
                제 13 조【 전자금융거래정보의 제공금지 】<br>
                회사는 전자금융거래 서비스를 제공함에 있어서 취득한 이용자의
                인적사항, 이용자의 계좌, 접근매체 및 전자금융거래의 내용과
                실적에 관한 정보 또는 자료를 금융실명거래 및 비밀보장에 관한
                법률 등 법령에 의하거나 이용자의 동의를 얻지 아니하고
                제3자에게 제공, 누설하거나 업무상 목적 외에 사용하지
                아니합니다.<br><br><br>
                제 14 조【 분쟁처리 및 분쟁조정 】<br>
                ① 이용자는 다음의 분쟁처리 책임자 및 담당자에 대하여
                전자금융거래 서비스 이용과 관련한 의견 및 불만의 제기,
                손해배상의 청구 등의 분쟁처리를 요구할 수 있습니다.<br>
                담당자: 효성CMS<br>
                연락처(전화번호, 전자우편주소): 1544-5162, cms@hyosung.com<br>
                ② 이용자가 회사에 대하여 분쟁처리를 신청한 경우에는 회사는
                15일 이내에 이에 대한 조사 또는 처리 결과를 이용자에게
                안내합니다.<br>
                ③ 이용자는 회사의 분쟁처리결과에 이의가 있을 경우 '금융위원회
                설치 등에 관한 법률' 에 따른 금융감독원의 금융분쟁조정위원회나
                '소비자기본법'에 따른 한국소비자원의 소비자분쟁조정위원회에
                회사의 전자금융거래 서비스의 이용과 관련한 분쟁조정을 신청할
                수 있습니다.<br><br><br>
                제 15 조【 회사의 안정성 확보 의무 】<br>
                회사는 전자금융거래의 안전성과 신뢰성을 확보할 수 있도록
                전자금융거래의 종류별로 전자적 전송이나 처리를 위한 인력,
                시설, 전자적 장치 등의 정보기술부문 및 전자금융업무에 관하여
                금융위원회가 정하는 기준을 준수합니다.<br><br><br>
                제 16 조【 약관외 준칙 및 관할 】<br>
                ① 이 약관에서 정하지 아니한 사항에 대하여는 전자금융거래법,
                전자상거래 등에서의 소비자 보호에 관한 법률, 통신판매에 관한
                법률, 여신전문금융업법 등 소비자보호 관련 법령에서 정한 바에
                따릅니다.<br>
                ② 회사와 이용자간에 발생한 분쟁에 관한 관할은 민사소송법에서
                정한 바에 따릅니다.<br><br><br>
                부칙 (‘07. 7. .)<br>
                제 1조 (시행일)<br>
                본 약관은 ${'`07. 7. 1'}부터 시행한다.<br><br><br>
                부칙 (‘13. 8. .)<br>
                제 1조 (시행일)<br>
                본 약관은 ${'`13. 8. 20'}부터 시행한다.<br><br><br>
                부칙 (‘17. 3. .)<br>
                제 1조 (시행일)<br>
                본 약관은 ${'`17. 3. 10'}부터 시행한다.<br><br><br>
                부칙('20.03. .)<br>
                제 1조 (시행일)<br>
                본 약관은 ${'`20. 03. 23'} 부터 시행한다.
              `;

export const CARD_PAYMENT_TERMS_2 = `
                개인정보수집 및 이용동의<br><br>
                 수집 및 이용목적 : 효성CMS 카드자동결제를 통한 요금 수납<br>
                 수집항목 : 성명, 성별, 생년월일, 연락처, 결제사명, 결제자명,
                카드번호, 유효기간, 휴대전화번호<br>
                 보유 및 이용기간 : 수집/이용 동의일부터 자동이체
                종료일(해지일)까지<br>
                 신청자는 개인정보의 수집 및 이용을 거부할 수 있습니다. 단,
                거부 시 자동이체 신청이 처리되지 않습니다. <br>
`;

export const CARD_PAYMENT_TERMS_3 = `
                개인정보 제3자 제공동의<br><br>
                 개인정보를 제공받는 자: 효성에프엠에스㈜, 카드사(BC, 국민,
                외환, 삼성, 신한, 롯데, 하나SK, 현대 등),
                결제대행사(KG이니시스, KCP), 효성ITX 등<br>
                 개인정보를 제공받는 자의 이용 목적: 자동이체서비스 제공 및
                자동이체 동의 사실 통지, 고객센터 운영<br>
                 제공하는 개인정보의 항목 : 성명, 성별, 생년월일, 연락처,
                결제사명, 결제자명, 카드번호, 유효기간, 휴대전화번호<br>
                 개인정보를 제공받는자의 개인정보 보유 및 이용기간:
                동의일부터 자동이체의 종료일(해지일)까지. 단, 관계 법령에 의거
                일정기간 동안 보관<br>
                 신청자는 개인정보에 대해 수납업체가 제 3자에 제공하는 것을
                거부할 수 있습니다. 단, 거부 시 자동이체 신청이 처리되지
                않습니다<br><br>
                ※ 개인정보의 처리와 관련된 사항은 회사의 개인정보취급방침에
                따릅니다.
`;

export const STUDY_BOOK_DETAIL_OPTIONS = {
  detailStartNum: 1,
  detailFields: { units: '유닛' },
};

export const TEMP_STDUENT_IDS = [1, 2, 3, 4, 5];

export const BOOK_SORTING_DROPDOWN = [
  { id: 'abc', content: '가나다순' },
  { id: 'created', content: '최근 제작순' },
];

export const DIST_BOOK_REGEX = /_빈출단어 모음(?: - (\d+))?/;

export const STUDENT_GRADE_LIST_DROPDOWN = [
  { id: 'all', content: '전체' },
  { id: 'a1', content: '초 1' },
  { id: 'a2', content: '초 2' },
  { id: 'a3', content: '초 3' },
  { id: 'a4', content: '초 4' },
  { id: 'a5', content: '초 5' },
  { id: 'a6', content: '초 6' },
  { id: 'b1', content: '중 1' },
  { id: 'b2', content: '중 2' },
  { id: 'b3', content: '중 3' },
  { id: 'c1', content: '고 1' },
  { id: 'c2', content: '고 2' },
  { id: 'c3', content: '고 3' },
];

export const image_content_1 = `<h3>
                  연상 학습 (<span>이미지 O</span>)
                </h3>
                <p>학습 간 단어에 알맞는 사진과 함께</p>
                <p>
                  이미지 연상 학습으로 단어를 암기합니다.
                </p>
              </div>`;

export const image_content_2 = `<h3>
                  일반 학습 (<span>이미지 X</span>)
                </h3>
                <p>
                  이미지 없이 철자, 뜻으로만
                </p>
                <p>반복학습을 통해 단어를 암기합니다.</p>`;

export const method_content_1 = `<h3>
                  자율 학습 (<span>학생 스스로 과정 선택</span>)
                </h3>
                <p>
                  모든 학습 과정을 학습할 수 있지만,
                </p>
                <p>
                 강제성 없이 자유롭게 단계 이동이 가능합니다.
                 </p>`;

export const method_content_2 = `<h3>
                  단계별 학습 (<span>관리자가 정해준 과정</span>)
                </h3>
                <p>
                  관리자 모드를 통해 부여된 과정에 의해
                </p>
                <p>
                 강제성 있게 단계적 학습을 부여합니다.
                 </p>`;

export const wrongNote_content_1 = `<h3>
                  자동 생성 (<span>오답단어 자동 학습</span>)
                </h3>
                <p>
                  기본, 심화학습 간 오답 단어 발생 시,
                </p>
                <p>자동으로 오답 학습을 생성합니다.</p>`;

export const wrongNote_content_2 = `<h3>
                  사용 안함 (<span>오답단어 성적 기록만</span>)
                </h3>
                <p>
                  오답 단어가 발생하더라도 레포트에 기록하고
                </p>
                <p>오답 학습을 생성하지 않습니다.</p>`;

export const testMethod_content_1 = `<h3>
                  통합 출제 (<span>테스트 1개 출제</span>)
                </h3>
                <p>
                  선택된 시험 범위에서 단일 테스트를 출제하여
                </p>
                <p>모의평가를 치룹니다.</p>`;
export const testMethod_content_2 = `<h3>
                  개별 출제 (<span>유닛 갯수대로 출제</span>)
                </h3>
                <p>
                  선택된 유닛을 각 시험범위로 설정하고
                </p>
                <p>테스트를 한 번에 개별 생성합니다.</p>`;
export const print_content_1 = `<h3>
                  <span class="highlight">
                    <div>단어 목록</div>
                  </span>
                </h3>
                <p>
                  교재에 수록된 단어 목록을 출력하여
                </p>
                <p><span>암기식 테스트지</span>로 활용합니다.</p>`;
export const print_content_2 = `<h3>
                  <span class="highlight">
                    <div>쓰기 연습</div>
                  </span>
                </h3>
                <p>
                  영어 줄노트가 포함된 목록으로
                </p>
                <p><span>철자 쓰기 연습용</span>으로 활용합니다.</p>`;
export const print_content_3 = `<h3>
                  <span class="highlight">
                   <div>단어 카드</div>
                  </span>
                </h3>
                <p>
                  사전 전용 암기카드 형식으로
                </p>
                <p><span>이미지 연상 학습용</span>으로 활용합니다.</p>`;
export const print_type_content_1 = `<h3>
                    <div style="font-size: 1.3rem;">교재 출력 (<span>유닛별 한 장씩 출력</span>)</div>
                </h3>
                <p>
                  온라인 학습 이후, 직접 출력하시어
                </p>
                <p>워크시트로 이용해보세요!</p>`;
export const print_type_content_2 = `<h3>
                    <div style="font-size: 1.3rem;">시험지 출력 (<span>범위 설정 후 문제출력</span>)</div>
                </h3>
                <p>
                  주간, 월간 해당 기간에 학습된 단어를
                </p>
                <p>시험지로 이용해보세요!</p>`;

export const course_type_content_1 = `<h3>
                    <div style="font-size: 1.3rem;">필수어휘 코스북</div>
                </h3>
                <p style="font-size: 1.1rem; ">
                  초중고 내신 필수어휘와 토익, 입시영어 등
                </p>
                <p style="font-size: 1.1rem; ">수준별 어휘 컨텐츠</p>`;
export const course_type_content_2 = `<h3>
                    <div style="font-size: 1.3rem;">내신대비 컨텐츠</div>
                </h3>
                <p style="font-size: 1.1rem; ">
                  초중고 내신, 모의고사, 수능대비 등
                </p>
                <p style="font-size: 1.1rem; ">학교별 내신 어휘 컨텐츠</p>`;
export const join_type_content_1 = `<h3>
                    <div style="font-size: 1.3rem;">7일 무료체험 학습</div>
                </h3>
                <p style="font-size: 1.1rem; ">
                  오래 기억되는 초/중등 온라인 영어학습
                </p>
                <p style="font-size: 1.1rem; ">지금 무료로 체험해보세요.</p>`;
export const join_type_content_2 = `<h3>
                    <div style="font-size: 1.3rem;">즉시 수강신청</div>
                </h3>
                <p style="font-size: 1.1rem; ">
                  회원님께 제공되는 최대 수강신청 혜택으로
                </p>
                <p style="font-size: 1.1rem; ">오래영어를 시작해보세요.</p>`;

export const domainButtonGroup = [
  {
    id: 1,
    label: '어휘',
    check: false,
    disabled: false,
  },
  {
    id: 2,
    label: '내신',
    check: false,
    disabled: false,
  },
  {
    id: 3,
    label: '문법',
    check: false,
    disabled: false,
  },
  {
    id: 4,
    label: '회화',
    check: false,
    disabled: false,
  },
];

export const KOREAN_REGEX = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;

export const FEE_DATE_GROUP = {
  size: {
    pt: '0.25rem',
    pb: '0.25rem',
  },
  margin: false,
  active: [false, false, true],
  text: ['2년 전', '작년', '올해'],
};

export interface PathTypes {
  rootPath: string;
  childPath: string;
}

export interface PATH_VALIDATE_TYPE {
  [key: string]: {
    validateChildPath: string[];
  };
}

export const CARD_PATH_VALIDATE: PATH_VALIDATE_TYPE = {
  student: {
    validateChildPath: ['', 'teacher', 'list', 'listexcel'],
  },
  lesson: {
    validateChildPath: ['booklist'],
  },
  quiz: {
    validateChildPath: [],
  },
  test: {
    validateChildPath: ['testlist'],
  },
  score: {
    validateChildPath: [],
  },
  setting: {
    validateChildPath: ['', 'noticement', 'paymentview', 'admininfo'],
  },
};

export const paymentChangeNoticeList = [
  `카드 정보는 전자결제대행사 '효성에프엠에스(주)'를 통해<br />안전하게 관리됩니다.`,
  `자동결제 시 이용기간 중 결제 당일을 제외하고, 언제든지<br />자동결제 해지가 가능합니다.`,
  `기타 자세한 사항은 이용권 안내 페이지를 참고해주세요.`,
  `미사용 상품은 구매 후, 7일 이내 청약철회 가능합니다.`,
];

export const cardRegisterNoticeList = [
  `카드 정보는 결제대행사 '효성에프엠에스(주)'를 통해 안전하게 관리됩니다.`,
  `자동결제 시 이용기간 중 결제 당일을 제외하고, 자동결제 해지 가능합니다.`,
  `기타 자세한 사항은 이용권 안내 페이지를 참고해주세요.`,
  `미사용 상품은 구매 후, 7일 이내 청약철회 가능합니다.`,
];

export const CASH_RECEIPTS_TYPE_LIST = [
  { id: 1, name: '소득공제용' },
  { id: 2, name: '지출증빙용' },
];

export const DIRECT_INPUT_ID_REGEX = /direct_/gi;

export const NOT_USE_TAB = -1;
