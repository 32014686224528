import { styled, Card, CardContent as MuiCardContent } from '@mui/material';

export const CardWrapper = styled(Card)(props => ({
  width: '100%',
  height: 'auto',
  border: 'none',
  padding: '0.95rem 0 0.95rem 0.95rem',
  backgroundColor: '#f8f9fa',
  position: 'relative',
  fontSize: '0.9125rem',
  marginBottom: '3.5rem',

  '.open_button': {
    position: 'absolute',
    right: '1rem',
    top: '0.95rem',
    cursor: 'pointer',
    svg: {
      width: '1.5rem',
      height: '1.5rem',
    },
  },

  '&.close': {
    h4: {
      marginBottom: '0 !important',
    },
  },
}));

export const CardContent = styled(MuiCardContent)(props => ({
  '&:last-child': {
    paddingBottom: '0',
  },
  padding: '0',

  '& > div': {
    position: 'relative',
  },
}));
