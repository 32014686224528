import { styled, Box, Modal as MuiModal } from '@mui/material';
import profile_01 from 'assets/images/default-profile/profile_01.png';
import profile_02 from 'assets/images/default-profile/profile_02.png';
import profile_03 from 'assets/images/default-profile/profile_03.png';
import profile_04 from 'assets/images/default-profile/profile_04.png';
import profile_05 from 'assets/images/default-profile/profile_05.png';
import profile_06 from 'assets/images/default-profile/profile_06.png';
import profile_07 from 'assets/images/default-profile/profile_07.png';
import profile_08 from 'assets/images/default-profile/profile_08.png';
import { ModalContext } from 'provider/ModalProvider';
import { useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  ModalDefaultProfileSettingType,
  modalDefaultProfileSettingState,
  closeModalDefaultProfileSetting,
} from 'recoil/common/modalDefaultProfileSetting';
import { d_flex_center } from 'styles/common';
import { fetchPostApi } from '../../../src/utils/api';
import { userState } from 'recoil/model/user';
import { hiddenMissionState } from 'recoil/common/mission';
import { ModalLeaderBoardType, modalLeaderBoardState, openModalLeaderBoard } from 'recoil/common/modalLeaderBoard';
import {
  ModalHiddenMissionType,
  modalHiddenMissionState,
  openModalHiddenMission,
} from 'recoil/common/modalHiddenMission';
import { IoCloseSharp } from 'react-icons/io5';
import { ModalHeaderIconWrap } from 'pages/profile/Profile.Styles';

// interface ModalDefaultProfileSettingProps {
//   visible: boolean;
//   onClose: () => void;
// }

const Modal = styled(MuiModal)({
  backgroundColor: 'rgba(33, 33, 33, 0.46)',
  transition: '0.2s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms !important',
  '& > .MuiBox-root': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
});

const Wrapper = styled(Box)({
  borderRadius: '4px',
  overflow: 'hidden',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  boxShadow: 'none',
});

const Wrap = styled(Box)(props => ({
  height: 'auto',
  borderRadius: '1.5rem',
  overflow: 'hidden',
  backgroundColor: 'transparent',
}));

const Header = styled(Box)(props => ({
  width: '100%',
  height: '3.5rem',
  padding: '0 1rem',
  fontSize: '1.1rem',
  background: '#6b20e9',
  color: props.theme.palette.white.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '.sub-title': {
    fontSize: '0.875rem',
    marginLeft: '0.25rem',
  },
  '@media (max-width: 1903px)': {
    fontSize: '1.25rem',
  },
  '@media (max-width: 1263px)': {
    height: '3.75rem',
  },
  '@media (max-width: 959px)': {
    height: '5rem',
    fontSize: '1.625rem',
  },
  '@media (max-width: 767px)': {
    height: '4rem',
    fontSize: '1.35rem',
  },
}));

const Body = styled(Box)(props => ({
  width: '60rem',
  height: '32rem',
  backgroundColor: props.theme.palette.white.main,
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '1rem',
  padding: '1rem',

  '@media (max-width: 959px)': {
    width: '52rem',
    height: '28rem',
  },

  '& .profile-option': {
    width: '100%',
    borderRadius: '1rem',
    cursor: 'pointer',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    ...d_flex_center,
    '& > img': {
      width: '100%',
    },
  },
}));

function ModalDefaultProfileSetting() {
  const { modal_alert } = useContext(ModalContext);
  const hiddenMission = useRecoilValue(hiddenMissionState);
  const setModalHiddenMission = useSetRecoilState<ModalHiddenMissionType>(modalHiddenMissionState);
  const setModalLeaderBoard = useSetRecoilState<ModalLeaderBoardType>(modalLeaderBoardState);
  const [userStateData, setUserStateData] = useRecoilState(userState);
  const [modalDefaultProfileSetting, setModalDefaultProfileSetting] = useRecoilState<ModalDefaultProfileSettingType>(
    modalDefaultProfileSettingState,
  );

  const { visible, clickDefaultProfile } = modalDefaultProfileSetting;

  const [imgReady, setImgReady] = useState(false);

  const onClickClose = async (profile_number: number) => {
    // profile 변경 로직
    try {
      if (clickDefaultProfile) {
        clickDefaultProfile(profile_number);
        closeModalDefaultProfileSetting({ setModalDefaultProfileSetting });
      } else {
        const res = await fetchPostApi(
          `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/profile/default`,
          {
            default_profile: profile_number,
          },
        );

        if (res.result) {
          setUserStateData(prev => ({
            ...prev,
            profile: res.data.profile,
            default_profile: `${profile_number}`,
            profile_type: 'default',
          }));
        }
        closeModalDefaultProfileSetting({ setModalDefaultProfileSetting });
        modal_alert.openModalAlert('success_choice', undefined, '학습 프로필이', () => {
          if (hiddenMission.curr_step == 0) {
            if (userStateData.class && userStateData.class[0].use_rank) {
              openModalLeaderBoard({ setModalLeaderBoard });
            }
          } else {
            openModalHiddenMission({ setModalHiddenMission }, true);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const defaultProfileArr = [
    { id: 1, imgSrc: profile_01 },
    { id: 2, imgSrc: profile_02 },
    { id: 3, imgSrc: profile_03 },
    { id: 4, imgSrc: profile_04 },
    { id: 5, imgSrc: profile_05 },
    { id: 6, imgSrc: profile_06 },
    { id: 7, imgSrc: profile_07 },
    { id: 8, imgSrc: profile_08 },
  ];

  useEffect(() => {
    const loadImage = defaultProfileArr.map(profile => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = profile.imgSrc;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(loadImage).finally(() => {
      setImgReady(true);
    });
  }, []);

  return (
    <>
      {imgReady ? (
        <Modal
          open={visible}
          onClose={
            modalDefaultProfileSetting.arbitrary
              ? () => closeModalDefaultProfileSetting({ setModalDefaultProfileSetting })
              : undefined
          }
        >
          <Wrapper>
            <Wrap>
              <Header>
                <Box component={'span'}>학습 프로필을 선택해주세요!</Box>
                <ModalHeaderIconWrap
                  onClick={() =>
                    modalDefaultProfileSetting.arbitrary
                      ? closeModalDefaultProfileSetting({ setModalDefaultProfileSetting })
                      : undefined
                  }
                >
                  <IoCloseSharp />
                </ModalHeaderIconWrap>
              </Header>
              <Body>
                {defaultProfileArr?.map((profile, idx) => {
                  return (
                    <Box
                      className='profile-option'
                      key={`default-profile-${profile.id}`}
                      onClick={() => onClickClose(idx + 1)}
                    >
                      <img src={profile.imgSrc} alt='default-profile' />
                    </Box>
                  );
                })}
              </Body>
            </Wrap>
          </Wrapper>
        </Modal>
      ) : null}
    </>
  );
}

export default ModalDefaultProfileSetting;
