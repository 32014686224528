import React, { useState } from 'react';
import {
  Modal,
  ModalBox,
  ModalContentLeftDuratioBox,
  ModalContentBoxHeadline,
  ModalContentLeftDurationWrapper,
  ModalContentLeftProductBox,
  ModalContentLeftProductWrapper,
  ModalStepOneContentLeftSection,
  ModalStepOneContentRightSection,
  ModalContentWrapper,
  ModalHeader,
  ModalHeaderIconWrap,
  RecommInputBox,
  ModalContentRightWrapper,
  ModalContentRightPriceBox,
  ModalContentRightLine,
  DetailDeco,
  ModalStepTwoContentWrapper,
  ModalStepTwoContentBox,
  Margin,
  ModalStepTwoContentTextBox,
  ModalStepTwoContentTextLeft,
  ModalStepTwoContentTextRight,
  StyledPaymentKindRadio,
  ModalStepTwoContentFormTextLeftBox,
  ModalStepTwoContentFormTextRightBox,
  ColorButton,
  Line,
  SpeechBubble,
  ModalStepTwoContentTextBoxTwo,
  JoinButton,
  LeftTilte,
} from './ModalLectureApplyStyles';
import { IoCloseSharp } from 'react-icons/io5';
import { Box } from '@mui/system';
import productImage0 from 'assets/images/modalLectureApply/product_0.png';
import productImage1 from 'assets/images/modalLectureApply/product_1.png';
import productImage2 from 'assets/images/modalLectureApply/product_2.png';
import productImage3 from 'assets/images/modalLectureApply/product_3.png';
import dayjs from 'dayjs';
import { IoIosArrowBack } from 'react-icons/io';
import { Fade } from '@mui/material';
import { ModalCardInfoDataType } from 'recoil/common/modalCardInfo';
import { ModalCardInfoContainer } from '../ModalCardInfo/ModalCardInfo.Container';
import { calculateProPayment } from 'utils/tools';

interface ModalLectureApplyPresentersProps {
  step: number; // 단계 (1단계 : 수강신청, 2단계:결제)
  visible: boolean; // 모달창
  title: string; // 모달 제목
  lectureClass: string; // 수강 클래스
  recommCode: string; // 추천인
  applyBenefit: string; // 혜택 코드
  minusPrice: number; // 혜택 금액
  month: number; // 혜택 기간
  addMonth: number; //추가 혜택 기간
  applyRecomm: boolean; // 추천 유무
  benefitPrice: number; // 혜택 금액
  cost: number; // 추가된 부분
  full: number; // 전체 금액
  earlybird: number; // 얼리버드 할인
  pkg: number; // 패키지
  scholarshipDisCountPrice: number; // 장학생 할인
  isSelectedDuration: number; // 기간 선택
  isSelectedProduct: number; //상품 선택
  onClose: () => void; // 닫기 함수
  onClickToDuration: (idx: number) => void;
  onClickToProduct: (idx: number) => void;
  onRecommHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickApplyRecomm: (recommCode: string, duration: number) => void;
  onClickToNextStep: () => void;
  onClickToBackStep: () => void;
  enddate: any;
  n_paydate: any;
  paymentKind: number;
  setPaymentKind: (paymentKind: number) => void;
  joinComp: boolean;
  setJoinComp: (joinComp: boolean) => void;
  onClickCardInfo: () => void;
  modalCardInfo: ModalCardInfoDataType;
  timeout?: { enter: number; exit: number };
  isJoinEscape: boolean | null;
  postPaymentInfo: () => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  top: string;
  isAccountPaymentProcessing: boolean;
  isCardPaymentProcessing: boolean;
}

export const ModalLectureApplyPresenters = React.memo(function ModalLectureApplyPresenters(
  props: ModalLectureApplyPresentersProps, // props 타입 지정
) {
  const {
    step,
    visible,
    title,
    recommCode,
    minusPrice,
    month,
    addMonth,
    applyRecomm,
    benefitPrice,
    cost,
    full,
    earlybird,
    pkg,
    scholarshipDisCountPrice,
    onClose,
    isSelectedDuration,
    isSelectedProduct,
    onClickToDuration,
    onClickToProduct,
    onRecommHandler,
    onClickApplyRecomm,
    onClickToNextStep,
    onClickToBackStep,
    enddate,
    n_paydate,
    paymentKind,
    setPaymentKind,
    joinComp,
    onClickCardInfo,
    modalCardInfo,
    timeout,
    isJoinEscape,
    postPaymentInfo,
    onFocus,
    onBlur,
    top,
    isAccountPaymentProcessing,
    isCardPaymentProcessing,
  } = props;
  const monthes = [1, 6, 12];
  const products = ['핵심문법', '올인원', '기초어휘', '실전회화'];
  const productImages = [productImage0, productImage1, productImage2, productImage3];
  const today = new Date().getDate();
  const { isRegister, cardNumber, year, cardMonth, birthDate, installmentMonths } = modalCardInfo;

  const onKeyDownEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') {
      onClickApplyRecomm(recommCode, isSelectedDuration);
    }
  };

  return (
    <Modal open={visible}>
      <Fade in={visible} timeout={timeout ? timeout : { enter: 225, exit: 195 }}>
        <ModalBox sx={{ marginTop: `${top}` }}>
          <ModalHeader id='pay-modal-header'>
            <Box
              sx={{ fontFamily: 'Apple SD Gothic Neo', fontWeight: '300', fontSize: '1.2rem' }}
              component={'span'}
              dangerouslySetInnerHTML={{ __html: title as string }}
            ></Box>
            <ModalHeaderIconWrap onClick={onClose}>
              <IoCloseSharp />
            </ModalHeaderIconWrap>
          </ModalHeader>
          <ModalContentWrapper>
            {step === 1 && (
              <>
                <ModalStepOneContentLeftSection>
                  <div style={{ fontSize: '1.34rem', fontWeight: 'bold', letterSpacing: '-0.1094rem' }}>학습 기간</div>
                  <ModalContentLeftDurationWrapper>
                    {monthes.map((month, idx) => (
                      <ModalContentLeftDuratioBox
                        key={`monthes_${idx}`}
                        onClick={() => onClickToDuration(idx)}
                        isSelected={idx == isSelectedDuration}
                      >
                        {(idx == 0 || idx == 2) && (
                          <ModalContentBoxHeadline isSelected={idx == isSelectedDuration}>
                            {idx == 2 ? 'BEST' : '구독형'}
                          </ModalContentBoxHeadline>
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            margin: '1.3rem 0px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            letterSpacing: '-0.1094rem',
                            fontWeight: '600',
                          }}
                        >
                          {month}개월
                        </Box>
                      </ModalContentLeftDuratioBox>
                    ))}
                  </ModalContentLeftDurationWrapper>
                  <div>
                    <Box
                      sx={{ fontSize: '1.34rem', letterSpacing: '-0.1094rem', fontWeight: '700', paddingTop: '1rem' }}
                    >
                      수강 클래스
                    </Box>
                    <ModalContentLeftProductWrapper>
                      {products.map((product, idx) => {
                        return (
                          <ModalContentLeftProductBox
                            isSelected={isSelectedProduct === idx}
                            key={`product_${idx}`}
                            onClick={() => onClickToProduct(idx)}
                          >
                            {idx == 1 ? (
                              <ModalContentBoxHeadline isSelected={isSelectedProduct === idx}>
                                BEST
                              </ModalContentBoxHeadline>
                            ) : null}
                            <img src={productImages[idx]} alt={`product_${idx}`} />
                            {product} 클래스
                            {idx == 1 ? (
                              <span
                                style={{
                                  fontSize: '0.775rem',
                                  lineHeight: '1rem',
                                  opacity: '0.5',
                                  color: 'rgb(107 114 128',
                                  bottom: '3.1%',
                                  position: 'absolute',
                                }}
                              >
                                (문법+어휘+회화)
                              </span>
                            ) : null}
                          </ModalContentLeftProductBox>
                        );
                      })}
                    </ModalContentLeftProductWrapper>
                  </div>
                  <LeftTilte
                    isScholarShip={scholarshipDisCountPrice != 0 && isSelectedProduct == 1 && isSelectedDuration == 2}
                  >
                    추천코드&nbsp;
                    <span style={{ opacity: '0.5', fontWeight: '500' }}>(선택)</span>
                  </LeftTilte>
                  <RecommInputBox>
                    <input
                      style={{
                        width: '100%',
                        color: 'rgb(117, 117, 117)',
                        backgroundColor: 'transparent',
                        margin: '0.65rem 0px',
                        fontSize: '1.19rem',
                        border: 'none',
                        fontWeight: '500',
                        boxSizing: 'border-box',
                        borderRadius: '0.2rem',
                      }}
                      placeholder='코드 입력'
                      value={recommCode ? recommCode : ''}
                      onChange={onRecommHandler}
                      disabled={applyRecomm}
                      autoComplete='off'
                      onKeyDown={onKeyDownEnter}
                      onFocus={onFocus}
                      onBlur={onBlur}
                    />
                    {recommCode ? (
                      <Box
                        sx={{
                          color: applyRecomm ? '#cccccc' : '#ffffff',
                          backgroundColor: applyRecomm ? '#ffffff' : '#575757',
                          borderRadius: '0.5rem',
                          fontSize: '0.9rem',
                          padding: '0.35rem 0.875rem',
                          cursor: applyRecomm ? 'default' : 'pointer',
                          whiteSpace: 'nowrap',
                          marginLeft: '0.5rem',
                          lineHeight: '1',
                        }}
                        component='div'
                        onClick={() => onClickApplyRecomm(recommCode, isSelectedDuration)}
                      >
                        적용
                      </Box>
                    ) : null}
                  </RecommInputBox>
                </ModalStepOneContentLeftSection>
                <ModalStepOneContentRightSection>
                  <Box component='div' sx={{ width: '100%', letterSpacing: '-0.08125rem' }}>
                    &nbsp;
                  </Box>
                  <ModalContentRightWrapper>
                    <ModalContentRightPriceBox>
                      <Box sx={{ fontWeight: '700', color: 'rgba(110,110,124)', fontSize: '1.19rem' }}>정가</Box>
                      <Box
                        sx={{
                          fontWeight: '700',
                          color: 'rgba(110,110,124)',
                          fontSize: '1.19rem',
                          letterSpacing: '-1.3px',
                        }}
                      >
                        {full.toLocaleString()}원
                      </Box>
                    </ModalContentRightPriceBox>
                    <ModalContentRightPriceBox>
                      <Box sx={{ fontWeight: '700', color: 'rgb(255, 95, 95)', fontSize: '1.19rem' }}>
                        얼리버드 할인
                      </Box>
                      <Box
                        sx={{
                          fontWeight: '700',
                          color: 'rgb(255, 95, 95)',
                          fontSize: '1.19rem',
                          letterSpacing: '-1.3px',
                        }}
                      >
                        -{earlybird.toLocaleString()}원
                      </Box>
                    </ModalContentRightPriceBox>
                    <ModalContentRightPriceBox>
                      <Box sx={{ fontWeight: '700', color: 'rgb(255, 95, 95)', fontSize: '1.19rem' }}>패키지 할인</Box>
                      <Box
                        sx={{
                          fontWeight: '700',
                          color: 'rgb(255, 95, 95)',
                          fontSize: '1.19rem',
                          letterSpacing: '-1.3px',
                        }}
                      >
                        -{pkg.toLocaleString()}원
                      </Box>
                    </ModalContentRightPriceBox>
                    {/* TODO: 장학생 할인은 UI 표시만을 위해 이밋로 넣어둠. */}
                    {scholarshipDisCountPrice != 0 && isSelectedProduct == 1 && isSelectedDuration == 2 ? (
                      <ModalContentRightPriceBox>
                        <Box
                          sx={{
                            position: 'relative',
                            fontWeight: '700',
                            color: 'rgb(255, 95, 95)',
                            fontSize: '1.19rem',
                            display: 'inline-block',
                            '&:after': {
                              content: '""',
                              position: 'absolute',
                              left: 0,
                              right: 0,
                              top: '50%',
                              height: '1.7rem',
                              background: '#fcfcb9',
                              zIndex: -1, // 텍스트 뒤에 위치
                              borderRadius: '0.3rem',
                              transform: 'translateY(-50%)', // 수직 중앙 정렬
                            },
                          }}
                        >
                          장학생 할인
                        </Box>
                        <Box
                          sx={{
                            position: 'relative',
                            fontSize: '1.19rem',
                            fontWeight: '700',
                            color: 'rgb(255, 95, 95)',
                            display: 'inline-block',
                            '&:after': {
                              content: '""',
                              position: 'absolute',
                              left: 0,
                              right: 0,
                              top: '50%',
                              letterSpacing: '-1.3px',
                              height: '1.7rem',
                              background: '#fcfcb9',
                              zIndex: -1,
                              borderRadius: '0.3rem',
                              transform: 'translateY(-50%)',
                            },
                          }}
                        >
                          -30,000원
                        </Box>
                      </ModalContentRightPriceBox>
                    ) : null}
                    <ModalContentRightPriceBox>
                      <Box sx={{ fontWeight: '300', color: 'rgb(169 169 169)', fontSize: '0.8rem', left: '0' }}>
                        *최대 할인 금액 자동 적용
                      </Box>
                      <Box sx={{ fontWeight: '300', color: 'rgb(169 169 169)', fontSize: '0.8rem', right: '0' }}>
                        {dayjs().format('M월 D일')}&nbsp;23:59까지
                      </Box>
                    </ModalContentRightPriceBox>
                    <Box
                      sx={{
                        width: '100%',
                        height: '0.1rem',
                        borderBottom: '0.063rem dashed rgb(169, 169, 169)',
                        lineHeight: '1',
                        marginTop: '0.5rem',
                        marginBottom: '0.9rem',
                      }}
                    >
                      &nbsp;
                    </Box>
                    <ModalContentRightLine>
                      <ModalContentRightPriceBox>
                        <Box sx={{ fontWeight: '700', fontSize: '1.19rem' }}>총 혜택 금액</Box>
                        <Box sx={{ fontWeight: '700', fontSize: '1.19rem', letterSpacing: '-1.3px' }}>
                          {cost.toLocaleString()}원
                        </Box>
                      </ModalContentRightPriceBox>
                      <ModalContentRightPriceBox sx={{ marginBottom: '1rem' }}>
                        <Box sx={{ fontWeight: '700', fontSize: '1.19rem', color: 'rgb(85 0 255)' }}>월 혜택 금액</Box>
                        <Box
                          sx={{
                            fontWeight: '700',
                            fontSize: '1.19rem',
                            color: 'rgb(85 0 255)',
                            letterSpacing: '-1.3px',
                          }}
                        >
                          {benefitPrice.toLocaleString()}원
                        </Box>
                      </ModalContentRightPriceBox>
                      <div
                        style={{
                          width: '100%',
                          height: '6.5rem',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-evenly',
                          backgroundColor: '#f4f4f4',
                          padding: '1.25rem 0',
                          gap: '1rem',
                          borderRadius: '0.5rem',
                          fontSize: '0.9rem',
                          marginBottom: '1rem',
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0px 1.5rem',
                          }}
                        >
                          <Box
                            component='div'
                            sx={{
                              fontWeight: '500',
                              fontSize: '1rem',
                            }}
                          >
                            <DetailDeco style={{ marginRight: '0.25rem' }} />
                            추천코드
                          </Box>
                          <Box
                            component='div'
                            sx={{
                              fontWeight: '500',
                              fontSize: '1rem',
                              color: applyRecomm ? 'black' : '#d0d0d8',
                            }}
                          >
                            {applyRecomm
                              ? addMonth
                                ? `${addMonth}개월 추가`
                                : minusPrice
                                ? `${minusPrice.toLocaleString()}원 할인`
                                : '0개월 추가'
                              : '0개월 추가'}
                          </Box>
                        </div>

                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '0 1.5rem',
                          }}
                        >
                          <Box
                            component='div'
                            sx={{
                              fontWeight: '500',
                              fontSize: '1rem',
                            }}
                          >
                            <DetailDeco style={{ marginRight: '0.25rem' }} />
                            최종 학습기간
                          </Box>
                          <Box
                            component='div'
                            sx={{
                              fontWeight: '500',
                              fontSize: '1rem',
                            }}
                          >
                            {month + addMonth}개월
                          </Box>
                        </div>
                      </div>
                      <Margin height='0.94'></Margin>
                      <JoinButton
                        onClick={onClickToNextStep}
                        component='div'
                        isScholarShip={
                          scholarshipDisCountPrice != 0 && isSelectedProduct == 1 && isSelectedDuration == 2
                        }
                      >
                        {today > 5 &&
                        isSelectedDuration !== 0 &&
                        dayjs().endOf('month').diff(dayjs(), 'day') !== 0 &&
                        dayjs().format('YYYY-MM-DD') >= '2024-07-01' ? (
                          <Box
                            sx={{
                              position: 'absolute',
                              backgroundColor: '#ffe65c',
                              padding: '0.4rem 1.1rem',
                              borderRadius: '0.5rem',
                              transform: 'translateY(-50%) scale(0.8)',
                              boxSizing: 'content-box',
                              top: 0,
                              right: '-1.5rem',
                              fontWeight: '700',
                              letterSpacing: '-0.063rem',
                              display: 'flex',
                              '&::before': {
                                zIndex: '-1',
                                content: '""',
                                position: 'absolute',
                                bottom: '-0.1rem',
                                left: 0,
                                width: 0,
                                height: 0,
                                borderLeft: '0.9375rem solid transparent',
                                borderRight: '0.9375rem solid transparent',
                                borderBottom: '0.9375rem solid #ffe65c',
                                transform: 'rotate(-45deg)',
                              },
                            }}
                          >
                            오늘만 +{dayjs().endOf('month').diff(dayjs(), 'day')}일 더
                          </Box>
                        ) : null}
                        {dayjs().format('YYYY-MM-DD') < '2024-07-01' ? (
                          <Box
                            style={{
                              position: 'absolute',
                              backgroundColor: '#ffe65c',
                              padding: '0.2rem 0.625rem',
                              borderRadius: '0.5rem',
                              transform: 'translateY(-50%)',
                              boxSizing: 'content-box',
                              top: 0,
                              right: '-1rem',
                              fontWeight: '700',
                              letterSpacing: '-1px',
                            }}
                            sx={{
                              '&::before': {
                                zIndex: '-1',
                                content: '""',
                                position: 'absolute',
                                bottom: '-0.1rem',
                                left: 0,
                                width: 0,
                                height: 0,
                                borderLeft: '0.9375rem solid transparent',
                                borderRight: '0.9375rem solid transparent',
                                borderBottom: '0.9375rem solid #ffe65c',
                                transform: 'rotate(-45deg)',
                              },
                            }}
                          >
                            6/30(일)까지
                          </Box>
                        ) : null}

                        <div
                          style={{
                            color: 'white',
                            fontWeight: '600',
                            fontSize: '1.328rem',
                            letterSpacing: '-0.05rem',
                          }}
                          className='title'
                        >
                          월 {benefitPrice.toLocaleString()}원 가입
                        </div>
                        <Margin height='0.25'></Margin>
                        <Box
                          sx={{
                            color: '#b4f7ff',
                            fontSize: '0.8rem',
                            letterSpacing: '-0.05rem',
                          }}
                        >
                          (걱정마세요, 불만족 시 보증기간 내 100% 환불)
                        </Box>
                      </JoinButton>
                    </ModalContentRightLine>
                  </ModalContentRightWrapper>
                </ModalStepOneContentRightSection>
              </>
            )}
            {step === 2 && (
              <>
                {isJoinEscape ? null : (
                  <Box
                    component='div'
                    sx={{
                      height: '75%',
                      position: 'absolute',
                      left: '0.5rem',
                      fontSize: '3rem',
                      display: 'flex',
                      alignItems: 'center',
                      color: 'rgb(186, 186, 186)',
                    }}
                  >
                    <IoIosArrowBack style={{ cursor: 'pointer' }} onClick={onClickToBackStep} />
                  </Box>
                )}
                <ModalStepTwoContentWrapper>
                  <ModalStepTwoContentBox>
                    <ModalStepTwoContentTextBox>
                      <ModalStepTwoContentTextLeft textAlign='right'>클래스</ModalStepTwoContentTextLeft>
                      <ModalStepTwoContentTextRight color='rgb(85, 0, 255)'>
                        {products[isSelectedProduct]} 클래스 {month}개월
                      </ModalStepTwoContentTextRight>
                    </ModalStepTwoContentTextBox>
                    <ModalStepTwoContentTextBox>
                      <Box
                        sx={{
                          fontSize: '1.25rem',
                          textAlign: 'right',
                          width: '25%',
                          fontWeight: '500',
                          padding: '0.5rem 1rem',
                        }}
                      >
                        이용 기간
                      </Box>
                      <ModalStepTwoContentTextRight>
                        {dayjs().format('YYYY. MM. DD')}&nbsp;~&nbsp;
                        {enddate.format('YYYY. MM. DD')}
                        &nbsp;&nbsp;
                        <Box
                          component={'span'}
                          sx={{
                            fontSize: '0.8rem',
                            color: '#bdbdbd',
                            // '@media (max-width: 400px)': { display: 'block' },
                          }}
                        >
                          (다음 결제일&nbsp;
                          {n_paydate})
                        </Box>
                      </ModalStepTwoContentTextRight>
                    </ModalStepTwoContentTextBox>
                    <ModalStepTwoContentTextBox>
                      <ModalStepTwoContentTextLeft>결제 금액</ModalStepTwoContentTextLeft>
                      <ModalStepTwoContentTextRight color='red' sx={{ display: 'flex', alignItems: 'center' }}>
                        {isSelectedDuration == 0 ? calculateProPayment(cost).toLocaleString() : cost.toLocaleString()}
                        원&nbsp;
                        <Box
                          component={'span'}
                          sx={
                            isSelectedDuration == 0
                              ? { fontSize: '1rem', color: '#bdbdbd' }
                              : { fontSize: '0.8rem', color: '#bdbdbd' }
                          }
                        >
                          {isSelectedDuration == 0
                            ? `(당월 잔여 기간만큼 일할 계산액)`
                            : `(월 ${Math.round(cost / (month + addMonth)).toLocaleString()}원 )`}
                        </Box>
                        {scholarshipDisCountPrice != 0 ? <SpeechBubble>장학생 추가 할인</SpeechBubble> : null}
                      </ModalStepTwoContentTextRight>
                    </ModalStepTwoContentTextBox>
                  </ModalStepTwoContentBox>
                  <Margin height={'1'} />
                  <ModalStepTwoContentBox backgroundColor='white'>
                    {isSelectedDuration == 0 ? null : (
                      <ModalStepTwoContentTextBoxTwo borderBottom='on'>
                        <ModalStepTwoContentFormTextLeftBox textAlign='right' isPaymentKind={paymentKind === 0}>
                          <Box
                            sx={{
                              cursor: 'pointer',
                              alignItems: 'center',
                              display: 'inline-flex',
                              verticalAlign: 'middle',
                              marginLeft: '-1.388rem',
                            }}
                            onClick={() => {
                              setPaymentKind(0); // 전용 계좌 선택
                            }}
                          >
                            <StyledPaymentKindRadio checked={paymentKind === 0} value={0} disabled={joinComp} />
                            전용 계좌
                          </Box>
                        </ModalStepTwoContentFormTextLeftBox>
                        <ModalStepTwoContentTextRight color='rgb(206, 206, 206)' sx={{ fontWeight: '400' }}>
                          {/* {paymentKind == 0 ? '결제 버튼을 누르면 전용 계좌가 생성됩니다.' : null}&nbsp; */}
                          {'결제 버튼을 누르면 전용 계좌가 생성됩니다.'}&nbsp;
                        </ModalStepTwoContentTextRight>
                      </ModalStepTwoContentTextBoxTwo>
                    )}
                    <ModalStepTwoContentTextBoxTwo>
                      <ModalStepTwoContentFormTextLeftBox textAlign='right' isPaymentKind={paymentKind === 1}>
                        <Box
                          sx={{
                            cursor: 'pointer',
                            alignItems: 'center',
                            display: 'inline-flex',
                            verticalAlign: 'middle',
                            marginLeft: '-1.388rem',
                          }}
                          onClick={() => {
                            setPaymentKind(1); // 카드 결제 선택
                          }}
                        >
                          <StyledPaymentKindRadio checked={paymentKind === 1} value={1} disabled={joinComp} />
                          카드 결제
                        </Box>
                      </ModalStepTwoContentFormTextLeftBox>
                      <ModalStepTwoContentFormTextRightBox>
                        {paymentKind == 1 ? (
                          <Box
                            sx={{
                              width: '100%',
                              height: '2.5rem',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: '#bdbdbd',
                              backgroundColor: '#f5f5f5',
                              fontSize: '0.9rem',
                              '@media (max-width: 639px)': {
                                fontSize: '0.875rem',
                              },
                              '@media (max-width: 381px)': {
                                fontSize: '0.8rem',
                              },
                            }}
                            onClick={() => onClickCardInfo()}
                          >
                            {isRegister && cardNumber
                              ? cardNumber.replace(/(\d{4})-(\d{2})\d{2}-(\d{4})-(\d{4})/, '$1-$2**-****-$4')
                              : '+ 카드를 등록해주세요.'}
                          </Box>
                        ) : (
                          <>&nbsp;</>
                        )}
                        &nbsp;
                      </ModalStepTwoContentFormTextRightBox>
                    </ModalStepTwoContentTextBoxTwo>
                  </ModalStepTwoContentBox>
                  <Margin height={'1.25'} />
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ColorButton
                      onClick={postPaymentInfo}
                      joinComp={joinComp}
                      paymentKind={paymentKind}
                      isRegister={isRegister}
                      isPayment={isAccountPaymentProcessing || isCardPaymentProcessing}
                    >
                      {isAccountPaymentProcessing ? '발급 중' : isCardPaymentProcessing ? '결제 중' : '결제'}
                    </ColorButton>
                  </Box>
                  <Margin height={'1.25'} />
                  <Line height='0.4rem'>&nbsp;</Line>
                  <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '0.85rem' }}>
                    <Box sx={{ fontWeight: '600', fontSize: '0.9rem', marginBottom: '0.35rem' }}>
                      오래영어 이용권 구매 주의사항
                    </Box>
                    <Box
                      sx={{
                        paddingtop: '0.2rem',
                        fontWeight: '300',
                        fontSize: '0.9rem',
                        color: '#a7a6a4',
                        whiteSpace: 'nowrap',
                        marginLeft: '-0.15rem',
                        lineHeight: '1.35rem',
                      }}
                    >
                      &nbsp;&nbsp;.&nbsp;&nbsp;모든 이용권은 부가세(VAT) 포함 가격입니다.
                      <br />
                      &nbsp;&nbsp;.&nbsp;&nbsp;상품 가격은 내부 정책으로 인해 변경 될 수 있습니다.
                      <br />
                      &nbsp;&nbsp;.&nbsp;&nbsp;자동결제 시 결제 당일을 제외한 이용기간 중 언제든지 자동결제를 해지하실
                      수 있습니다.
                      <br />
                      &nbsp;&nbsp;.&nbsp;&nbsp;미사용 상품은 구매 후, 7일 이내 청약철회 가능합니다.
                      <br />
                      &nbsp;&nbsp;.&nbsp;&nbsp;고객센터를 통한 자동결제 해지 신청은 본인만 가능합니다.
                    </Box>
                  </Box>
                </ModalStepTwoContentWrapper>
              </>
            )}
          </ModalContentWrapper>
          <ModalCardInfoContainer />
        </ModalBox>
      </Fade>
    </Modal>
  );
});
