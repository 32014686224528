import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { learningState } from '../../../recoil/model/learning';
import { ModalContext } from '../../../provider/ModalProvider';
import { styled, Box, BoxProps, Button } from '@mui/material';
import '../../../assets/scss/index.scss';
import { d_flex_center, d_flex_end, d_flex_start } from '../../../styles/common';
import { loadingState, setPercent } from '../../../recoil/common/loading';
import { THEORY_LABEL } from 'utils/constants';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { toast_contents } from 'utils/modal_contents';
import { userState } from 'recoil/model/user';

const StyledStepper = styled(Box)(props => ({
  width: '100%',
  height: '5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (min-width: 1024px) and (max-width: 1179px) and (min-height: 768px) and (max-height: 835px)': {
    //* IPad Mini
    position: 'relative',
    top: '8px',
  },
  '@media (min-width: 1180px) and (max-width: 1365px) and (min-height: 820px) and (max-height: 900px)': {
    //* IPad Air
    position: 'relative',
    top: '8px',
  },
  '@media (width: 1366px) and (min-height: 1024px) and (max-height: 1050px)': {
    //* IPad Pro
    position: 'relative',
    top: '9px',
  },
  '@media (width: 1368px) and (height: 912px)': {
    //* Surface Pro 7
    position: 'relative',
    top: '9px',
  },
}));

const StyledStepperLeftBox = styled(Box)(props => ({
  width: '15%',
  height: '100%',
  paddingLeft: '16px',
  ...d_flex_start,
}));

const StyledStepperCenterBox = styled(Box)(props => ({
  width: '70%',
  height: '100%',
  flexBasis: 'auto',
  ...d_flex_center,
}));

const StyledStepperRightBox = styled(Box)(props => ({
  width: '15%',
  height: '100%',
  paddingRight: '16px',
  ...d_flex_end,
}));

const StyledFontButton = styled(Button)({
  width: '4.5rem',
  maxWidth: 'calc(50% - 1px)',
  height: '3.528rem',
  minWidth: '0',
  backgroundColor: '#f7f7f7',
  color: '#636363',
  boxShadow: 'none',
  fontSize: '1.2rem',
  '&:hover': {
    backgroundColor: '#f7f7f7',
    color: '#636363',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: '#f7f7f7',
    color: '#636363',
    boxShadow: 'none',
  },
});

interface StepBox extends BoxProps {
  type: 'before' | 'after' | 'now';
}

const StyledStepBox = styled(Box)<StepBox>(props => ({
  width: '100%',
  height: '3.528rem',
  lineHeight: '1.375',
  fontSize: '1.26rem',
  padding: '6px 16px',
  borderRadius: '8px',
  ...d_flex_center,
  backgroundColor: props.type == 'now' ? '#ffd70f' : props.type == 'after' ? '#fff4e1' : '#ffef9f',
  color: props.type == 'now' ? '#fff' : props.type == 'after' ? '#5d5d5d' : '#ffde90',
  cursor: 'pointer',
  textAlign: 'center',
  marginLeft: '4px',
  marginRight: '4px',
  boxShadow: 'none',
  fontWeight: props.type == 'after' ? '500' : '700',
  '&:hover': {
    backgroundColor: props.type == 'now' ? '#ffd70f' : props.type == 'after' ? '#fff4e1' : '#ffef9f',
    color: props.type == 'now' ? '#fff' : props.type == 'after' ? '#5d5d5d' : '#ffde90',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: props.type == 'now' ? '#ffd70f' : props.type == 'after' ? '#fff4e1' : '#ffef9f',
    color: props.type == 'now' ? '#fff' : props.type == 'after' ? '#5d5d5d' : '#ffde90',
    boxShadow: 'none',
  },
}));

interface CounterBox extends BoxProps {
  type: string;
}

const StyledStepCounter = styled(Box)<CounterBox>(props => ({
  width: '9rem',
  height: '3.528rem',
  backgroundColor: '#f7f7f7',
  position: 'relative',
  color: '#636363',
  boxShadow: 'none',
  fontSize: '1.2rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: props.type == 'button' ? 'space-between' : 'center',
  padding: '6px 0.8rem',
  minWidth: '64px',
  lineHeight: '1.925',
  borderRadius: '8px',
  cursor: props.type == 'button' ? 'pointer' : 'default',
  '&::after': {
    display: props.type == 'button' ? 'inline-block' : 'none',
    verticalAlign: '0.255em',
    content: '""',
    borderTop: '0.3em solid',
    borderRight: '0.3em solid transparent',
    borderBottom: '0',
    borderLeft: '0.3em solid transparent',
  },
  '&::before': {
    display: props.type == 'button' ? 'inline-block' : 'none',
    verticalAlign: '0.255em',
    content: '""',
    color: 'transparent',
    borderTop: '0.3em solid',
    borderRight: '0.3em solid transparent',
    borderBottom: '0',
    borderLeft: '0.3em solid transparent',
  },
}));

const StyledCounter = styled(Box)(props => ({
  color: props.theme.palette.purple.main,
  fontSize: '1.4rem',
  lineHeight: '0',
}));

function Stepper() {
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const setLoadingStateData = useSetRecoilState<LoadingType>(loadingState);
  const { modal_confirm, modal_alert } = useContext(ModalContext);

  const setToastBar = useSetRecoilState(toastBarState);

  const { modal_disable, current_step, current_page, mod, show_modal, grammar_contents, learning_type, font_level } =
    learningStateData;

  const [userStateData] = useRecoilState<UserType>(userState);
  const { center_type } = userStateData;

  const contents_leng = useMemo(() => {
    return grammar_contents.length;
  }, [grammar_contents]);

  const kor_mode = useMemo(() => {
    switch (learning_type) {
      case 7:
        return '문법학습';
      case 8:
        return '기본학습';
      default:
        return '심화학습';
    }
  }, [learning_type]);

  const setCurrentPage = (page: number) => {
    if (page == current_page || learningStateData.study_method == 1 || show_modal || modal_disable) return false;

    if (mod[page].done == 1) {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));

      //* B2C toast로 분기처리
      if (center_type == 'A' || center_type == 'B') {
        modal_alert.openModalAlert('module_move_unable', undefined, undefined, () => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: false,
          }));
        });
      } else {
        openToastBar({ setToastBar }, toast_contents.info.module_move_unable, '', 2000);
      }
    } else {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
      const mods = JSON.parse(JSON.stringify(mod));
      mods[current_page].step = current_step;
      mods[current_page].resultsheet = learningStateData.resultsheet;

      const modal_content = 'move_study_module';
      const target =
        typeof learning_type == 'number' && [8, 9].includes(learning_type)
          ? `${kor_mode}${page + 1}`
          : THEORY_LABEL[page + 1];

      console.log('mods', mods);
      console.log('mods[page]', mods[page]);

      modal_confirm.openModalConfirm(
        'normal',
        modal_content,
        () => {
          setPercent(setLoadingStateData, 1, '학습 데이터를 불러오는 중입니다.');
          setLearningStateData(prevState => ({
            ...prevState,
            mod: mods,
            resultsheet: mods[page].resultsheet ?? [],
            current_page: page,
            current_step: mods[page].step ?? 0,
            show_modal: false,
            contents: mods[page].contents ?? [],
          }));
        },
        undefined,
        target,
      );
    }
  };

  const upFontLevel = useCallback(() => {
    if (typeof font_level == 'number') {
      const new_font_level = Number((font_level + 0.2).toFixed(1));

      console.log('new_font_level', new_font_level);
      if (new_font_level == 1.6) {
        //! alert 띄우기
        //* B2C toast로 분기처리
        if (center_type == 'A' || center_type == 'B') {
          modal_alert.openModalAlert('limit_up_font_level');
        } else {
          openToastBar({ setToastBar }, toast_contents.error.limit_up_font_level, 'red', 2000);
        }
      } else {
        //! font_level up + alert 띄우기
        let target = '';
        switch (new_font_level) {
          case 0.6:
            target = '작게';
            break;
          case 0.8:
            target = '조금 작게';
            break;
          case 1:
            target = '기본';
            break;
          case 1.2:
            target = '조금 크게';
            break;
          case 1.4:
            target = '크게';
            break;
        }

        setLearningStateData(prevState => ({
          ...prevState,
          font_level: new_font_level,
          show_modal: true,
        }));

        if (center_type == 'A' || center_type == 'B') {
          modal_alert.openModalAlert('up_font_level', undefined, target, () => {
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
          });
        } else {
          openToastBar({ setToastBar }, `글자 크기가 커졌습니다. (${target})`, '', 2000);
        }
      }
    }
  }, [font_level]);

  const downFontLevel = useCallback(() => {
    if (typeof font_level == 'number') {
      const new_font_level = Number((font_level - 0.2).toFixed(1));

      if (new_font_level < 0.6) {
        //! alert 띄우기
        //* B2C toast로 분기처리
        if (center_type == 'A' || center_type == 'B') {
          modal_alert.openModalAlert('limit_down_font_level');
        } else {
          openToastBar({ setToastBar }, toast_contents.error.limit_down_font_level, 'red', 2000);
        }
      } else {
        //! font_level up + alert 띄우기
        let target = '';
        switch (new_font_level) {
          case 0.6:
            target = '작게';
            break;
          case 0.8:
            target = '조금 작게';
            break;
          case 1:
            target = '기본';
            break;
          case 1.2:
            target = '조금 크게';
            break;
          case 1.4:
            target = '크게';
            break;
        }

        setLearningStateData(prevState => ({
          ...prevState,
          font_level: new_font_level,
          show_modal: true,
        }));

        if (center_type == 'A' || center_type == 'B') {
          modal_alert.openModalAlert('down_font_level', undefined, target, () => {
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
          });
        } else {
          openToastBar({ setToastBar }, `글자 크기가 작아졌습니다. (${target})`, '', 2000);
        }
      }
    }
  }, [font_level]);

  return (
    <StyledStepper>
      <StyledStepperLeftBox>
        <StyledFontButton
          sx={{
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
            fontSize: '0.9rem',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={downFontLevel}
        >
          A
        </StyledFontButton>
        <Box sx={{ width: '1px', height: '3.528rem', backgroundColor: '#eaeaea' }}></Box>
        <StyledFontButton
          sx={{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px', display: 'flex', alignItems: 'center' }}
          onClick={upFontLevel}
        >
          A
        </StyledFontButton>
      </StyledStepperLeftBox>
      <StyledStepperCenterBox>
        {mod?.map((v, i) => (
          <StyledStepBox
            onClick={() => setCurrentPage(v.seq)}
            key={v.seq}
            type={v.seq == current_page ? 'now' : v.done == 1 ? 'before' : 'after'}
          >
            {typeof learning_type == 'number' && [8, 9].includes(learning_type) ? (
              //! 기본학습 or 심화학습
              <Box sx={{ marginTop: '1px' }}>{`${kor_mode}${v.seq + 1}`}</Box>
            ) : (
              //! 이론학습
              <Box sx={{ marginTop: '1px' }}>{THEORY_LABEL[v.module]}</Box>
            )}
          </StyledStepBox>
        ))}
      </StyledStepperCenterBox>
      <StyledStepperRightBox>
        <StyledStepCounter type={'box'}>
          <Box>
            <StyledCounter component={'span'} sx={{ transform: 'translateY(-2px)' }}>
              {current_step + 1}
            </StyledCounter>
            &nbsp;/&nbsp;{contents_leng}
          </Box>
        </StyledStepCounter>
      </StyledStepperRightBox>
    </StyledStepper>
  );
}

export default Stepper;
