import { useState, useEffect, useRef, useContext } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { learningState } from '../../recoil/model/learning';
import { loadingState, setPercent } from '../../recoil/common/loading';
import { pocketNoteState } from '../../recoil/model/pocket_note';
import { deviceState } from '../../recoil/common/device';
import { userState } from '../../recoil/model/user';
import { settingState } from '../../recoil/model/settings';

import { ModalContext } from '../../provider/ModalProvider';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { styled, Box, Button, IconButton, IconButtonProps, BoxProps } from '@mui/material';

import 'react-h5-audio-player/lib/styles.css';

import { fetchGetApi, fetchPostApi, fetchPutApi } from '../../utils/api';

import ModalAddPocketNote from '../../components/modal/ModalAddPocketNote';
import OXMark from '../../components/common/Study/OXMark';

import { d_flex, d_flex_center, dir_column, d_flex_space_between } from '../../styles/common';
import { arrShuffle, getExampleFilename, makeEndModuleModalProps } from '../../utils/tools';

import { AiOutlineRight } from 'react-icons/ai';
import { FiStar } from 'react-icons/fi';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';
import { modalSelectCardData } from 'recoil/common/modalSelectCard';
import ModalEndModule from 'components/modal/ModalEndModule';
import { tutorialStateData, openTutorial } from '../../recoil/common/tutorial';

interface CheckProps {
  sendResultsheet: () => void;
}

interface MemorizeBoxProps extends BoxProps {
  is_mobile: number;
}

const StyledMemorizeWrap = styled(Box)<MemorizeBoxProps>(props => ({
  width: '100%',
  height: 'calc(100% - 5rem)',
  backgroundColor: 'white',
  position: props.is_mobile == 1 ? 'relative' : 'inherit',
  '& *': { fontFamily: "'Apple SD Gothic Neo'", lineHeight: '1.20' },
}));

const StyledMemorizeBody = styled(Box)(props => ({
  width: '100%',
  height: 'calc(100% - 4rem)',
  display: 'block',
  position: 'relative',
  overflow: 'auto',
}));

const StyledMemorizeContentWrap = styled(Box)(props => ({
  width: '100%',
  height: 'calc(100% - 9.5rem)',
  position: 'relative',
  alignItems: 'center',
  maxHeight: 'unset',
  margin: '4.75rem 0',
  ...d_flex,
  ...dir_column,
}));

const StyledRightButtonBox = styled(Box)<MemorizeBoxProps>(props => ({
  position: 'absolute',
  top: props.is_mobile ? 'calc((100% - 4rem) / 2)' : '50%',
  right: '16px',
  transform: props.is_mobile ? 'translateY(-50%)' : 'translateY(50%)',
  ...d_flex_center,
}));

const StyledIconButton = styled(IconButton)(props => ({
  backgroundColor: 'transparent',
  width: '3rem',
  height: '3rem',
  padding: '0',
  svg: {
    color: '#b3b7bf',
    width: '2.5rem',
    height: '2.5rem',
    strokeWidth: '4rem',
  },
  '&.Mui-disabled > svg': {
    color: '#e8e9ec',
  },
}));

const StyledMemorizeContents = styled(Box)(props => ({
  width: '70%',
  height: '100%',
  maxHeight: '100%',
  ...d_flex_center,
  ...dir_column,
  backgroundColor: '#faf7ff',
  borderRadius: '8px',
  position: 'relative',
  cursor: 'pointer',
}));

const StyledLeftHeaderBox = styled(Box)(props => ({
  position: 'absolute',
  display: 'inline-flex',
  alignItems: 'center',
  top: '1.75vh',
  left: '1.75vh',
}));

const StyledRightHeaderBox = styled(Box)(props => ({
  position: 'absolute',
  top: '1.75vh',
  right: '1.75vh',
  display: 'inline-flex',
  alignItems: 'center',
}));

interface CustomSpeakButtonProps extends IconButtonProps {
  speak: number;
}

const CustomSpeakButton = styled(IconButton)<CustomSpeakButtonProps>(props => ({
  zIndex: 1,
  padding: '0.8vh',
  svg: {
    fill: props.speak == 1 ? props.theme.palette.purple.main : props.theme.palette.gray_2.main,
    width: '3.5vh',
    height: '3.5vh',
  },
}));

const CustomDiagonalLine = styled(Box)(props => ({
  width: '3.5vh',
  height: '2px',
  position: 'absolute',
  backgroundColor: props.theme.palette.red.main,
  transform: 'translateX(-0.25rem) rotate(-45deg)',
}));

interface CustomRepeatButtonProps extends IconButtonProps {
  repeat: number;
}

const CustomRepeatButton = styled(IconButton)<CustomRepeatButtonProps>(props => ({
  zIndex: 1,
  padding: '0.8vh',
  svg: {
    color: props.repeat == 1 ? props.theme.palette.purple.main : props.theme.palette.gray_2.main,
    width: '3.5vh',
    height: '3.5vh',
  },
}));

interface CustomBookmarkButtonProps extends IconButtonProps {
  bookmark: number;
}

const CustomBookmarkButton = styled(IconButton)<CustomBookmarkButtonProps>(props => ({
  zIndex: 1,
  padding: '0.8vh',
  svg: {
    color: props.bookmark == 1 ? '#f1bc5f' : props.theme.palette.purple.main,
    width: '3.5vh',
    height: '3.5vh',
    fill: props.bookmark == 1 ? '#f5ff1b' : 'none',
  },
}));

const StyledContentsBody = styled(Box)(props => ({
  width: 'calc(100% - 23.9vh - 24px)',
  height: 'calc(100% - 2rem)',
  position: 'relative',
  padding: '1rem 0',
  ...d_flex_center,
}));

const StyledBottom = styled(Box)(props => ({
  width: '100%',
  height: '4rem',
  position: 'relative',
  ...d_flex,
}));

const StyledSelectionWrap = styled(Box)(props => ({
  width: '70%',
  ...d_flex_space_between,
  flexWrap: 'wrap',
}));

interface SelectionBoxProps extends BoxProps {
  status: 'selected' | 'right' | 'wrong' | '';
  is_mobile: number;
}

const StyledSelectionBox = styled(Box)<SelectionBoxProps>(props => ({
  width: props.is_mobile == 1 ? 'calc(50% - 2px)' : 'calc(50% - 4px)',
  height: props.is_mobile == 1 ? '6.5vh' : '8vh',
  margin: props.is_mobile == 1 ? '2px 0' : '4px 0',
  cursor: 'pointer',
  backgroundColor:
    props.status == 'selected'
      ? '#daffd7'
      : props.status == 'wrong'
      ? '#ffe9e5'
      : props.status == 'right'
      ? '#caf9ff'
      : '#f7f7f7',
  border:
    props.status == 'selected'
      ? '1px solid #b6eabb'
      : props.status == 'wrong'
      ? '1px solid #ffd5d1'
      : props.status == 'right'
      ? '1px solid #8abdde'
      : '1px solid #f1f1f1',
  borderRadius: '1rem',
  ...d_flex_center,
  boxSizing: 'border-box',
  '& > span': {
    letterSpacing: props.is_mobile ? '-0.425px' : '-1.225px',
    color:
      props.status == 'selected'
        ? '#0fd527'
        : props.status == 'wrong'
        ? '#ff4c39'
        : props.status == 'right'
        ? '#2973d0'
        : '#6c6c6c',
  },
}));

function Check(props: CheckProps) {
  const sendResultsheet = props.sendResultsheet;
  const [tutorialState, setTutorialState] = useRecoilState(tutorialStateData);
  const { visible: visibleTutorial, reopen: reopenTutorial } = tutorialState;
  const visibleTutorialRef = useRef(visibleTutorial);
  visibleTutorialRef.current = visibleTutorial;

  const { playEffectSound } = useContext(EffectSoundContext);

  const userStateData = useRecoilValue<UserType>(userState);

  const settingStateData = useRecoilValue(settingState);
  const { enable_keyboard, check_type } = settingStateData;

  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const { resultsheet, show_modal, mod, current_page, current_step, contents } = learningStateData;

  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;

  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { screen_width, is_mobile, is_tablet } = deviceStateData;

  const pocketNoteStateData = useRecoilValue<PocketNoteType>(pocketNoteState);
  const { note_contents } = pocketNoteStateData;

  const [content, setContent] = useState<ContentType>();
  const [disableMove, setDisableMove] = useState<boolean>(false);

  const { modal_alert } = useContext(ModalContext);
  const [spell, setSpell] = useState('');
  const [mean, setMean] = useState('');
  const [example, setExample] = useState('');
  const [translate, setTranslate] = useState('');
  const [exampleHTML, setExampleHTML] = useState('');
  const [translateHTML, setTranslateHTML] = useState('');
  const [flashOn, setFlashOn] = useState<boolean>(true);
  const [flashReady, setFlashReady] = useState<boolean>(false);
  const [exampleReady, setExampleReady] = useState<boolean>(false);
  const [firstFlash, setFirstFlash] = useState<boolean>(true);
  const [nextDisable, setNextDisable] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);

  const [endModuleModal, setEndModuleModal] = useState<{
    visible: boolean;
    icon: JSX.Element;
    label: string;
    kor_label: string;
    score: number;
  }>({
    visible: false,
    icon: <></>,
    label: '',
    kor_label: '',
    score: 0,
  });

  const [checkVoice, setCheckVoice] = useState<boolean>(true);

  const [right, setRight] = useState<boolean>(false);
  const [allContents, setAllContents] = useState<ContentType[]>([]);
  const [rightanswer, setRightanswer] = useState<number>(0);
  const [question, setQuestion] = useState<string | undefined>('');
  const [choices, setChoices] = useState<string[]>([]);

  const settimeout1 = useRef<ReturnType<typeof setTimeout>[]>([]);
  const settimeout2 = useRef<ReturnType<typeof setTimeout>[]>([]);
  const settimeout3 = useRef<ReturnType<typeof setTimeout>[]>([]);

  const [visibleAddPocketNote, setVisibleAddPocketNote] = useState<boolean>(false);

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [wrongIndex, setWrongIndex] = useState<number | null>(null);
  const [visibleOX, setVisibleOX] = useState<boolean>(false);

  const exampleVoiceRef = useRef<HTMLAudioElement | null>(null);

  const [pause, setPause] = useState(false);
  const pauseRef = useRef(pause);

  const [mobileResolution, setMobileResolution] = useState(false); // 모바일 해상도 여부

  const nextCallback = () => {
    sendResultsheet();
  };

  const [keyboardStop, setKeyboardStop] = useState(false);
  const filteredContent = contents.filter(content => content.word_id);

  useEffect(() => {
    const setInit = async () => {
      getAllContents();
    };
    setMobileResolution(screen_width < 768 || (is_mobile && !is_tablet));
    setInit();

    checkExampleTTS();

    if (mod[current_page].resultsheet && mod[current_page].resultsheet != undefined) {
      const resultsheet_tmp = mod[current_page].resultsheet as ResultsheetType[];
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: resultsheet_tmp,
      }));
    } else {
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: [],
      }));
    }

    setTimeout(() => {
      setPercent(setLoadingStateData, 100);
    }, 10);

    return () => {
      clearTimeout1();
      exampleVoiceRef.current?.pause();
      exampleVoiceRef.current = null;
    };
  }, []);

  const checkExampleTTS = async () => {
    for (let i = 0; i < contents.length; i++) {
      const example = contents[i].sentence;
      if (!example) continue;
      const gender = 0; // 남자 임시 고정
      const filename = getExampleFilename(example);
      const res = await fetchGetApi(`/etc/tts/example?filename=${filename}&gender=${gender}&ver=2`);
      if (!res.result) {
        const postdata = {
          example: example,
          filename: filename,
          gender: gender,
        };
        await fetchPostApi(`/etc/tts/example`, postdata);
      }
    }
  };

  useEffect(() => {
    setFlashReady(false);
    if (loaded) {
      const box = document.getElementById('contents-box');
      if (box) {
        box.style.transform = 'translateX(-100%)';
        box.style.position = 'absolute';
        box.style.transition = 'opacity 300ms, transform 300ms, filter 300ms';
      }
    }
    exampleVoiceRef.current?.pause();
    exampleVoiceRef.current = null;

    if (filteredContent.length > 0) {
      setContent(filteredContent[current_step]);
    }

    setVisibleOX(false);
    setNextDisable(false);

    clearTimeout1();
    clearTimeout2();
    clearTimeout3();
    setPause(false);
    pauseRef.current = false;
    setTimeout(() => {
      setFlashOn(true);
    }, 1);
    setDisableMove(true);
    setTimeout(() => {
      setDisableMove(false);
    }, 1000);

    if (firstFlash) {
      setFirstFlash(false);
      settimeout2.current.push(
        setTimeout(() => {
          console.log('setFlashReady', true);
          setFlashReady(true);
        }, 2000),
      );
    } else {
      settimeout2.current.push(
        setTimeout(() => {
          console.log('setFlashReady', true);
          setFlashReady(true);
        }, 1),
      );
    }
  }, [current_step]);

  useEffect(() => {
    // 예문 음성 control
    if (loaded && flashReady && exampleVoiceRef.current) {
      if (visibleTutorial) {
        // 이 부분에 걸리면 예문 음성 x
        if (!exampleVoiceRef.current.paused) {
          exampleVoiceRef.current.pause();
        }
      } else {
        setTimeout(() => {
          exampleVoiceRef.current?.play();
        }, 1500);
      }
    }
  }, [flashReady, exampleReady, visibleTutorial]);

  const getAllContents = async () => {
    const all_contents = await fetchGetApi(
      `/customers/${userStateData.customer_id}/books/${learningStateData.schedule_id}/speakwords?type=${learningStateData.book_type}`,
    );
    setAllContents(all_contents.data);
  };

  const clearTimeout1 = () => {
    for (let i = 0; i < settimeout1.current.length; i++) {
      clearTimeout(settimeout1.current[i]);
    }
    settimeout1.current = [];
  };

  const clearTimeout2 = () => {
    for (let i = 0; i < settimeout2.current.length; i++) {
      clearTimeout(settimeout2.current[i]);
    }
    settimeout2.current = [];
  };

  const clearTimeout3 = () => {
    for (let i = 0; i < settimeout3.current.length; i++) {
      clearTimeout(settimeout3.current[i]);
    }
    settimeout3.current = [];
  };

  useEffect(() => {
    if (percent == 100) {
      if (userStateData.show_tutorial && userStateData.show_tutorial.speak) {
        // 튜토리얼을 진행해야 한다면
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          openTutorial({ setTutorialState }, { reopen: false });
        }, 275);
        setTimeout(() => {
          setLoaded(true);
        }, 400);
      } else {
        // 튜토리얼 진행해도 되지 않다면
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));

          modal_alert.openModalAlert('speak_check_start', undefined, undefined, () => {
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
          });
          setLoaded(true);
        }, 400);
      }
    }
  }, [percent]);

  useEffect(() => {
    if (show_modal) clearTimeout1();
    if (enable_keyboard) {
      bindKeyboard();
    }
    return () => {
      if (enable_keyboard) {
        unbindKeyboard();
      }
    };
  }, [spell, selectedIndex, show_modal, nextDisable, disableMove, pause, keyboardStop]);

  const bindKeyboard = () => {
    document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const keyboardDownEvent = (e: KeyboardEvent) => {
    if (show_modal || !loaded) return false;

    if (e.keyCode == 13 && !keyboardStop) {
      goNext();
    } else if ((e.keyCode == 49 || e.keyCode == 97) && !show_modal) {
      onClickSelection(0);
    } else if ((e.keyCode == 50 || e.keyCode == 98) && !show_modal) {
      onClickSelection(1);
    } else if ((e.keyCode == 51 || e.keyCode == 99) && !show_modal) {
      onClickSelection(2);
    } else if ((e.keyCode == 52 || e.keyCode == 100) && !show_modal) {
      onClickSelection(3);
    } else if (e.keyCode == 32) {
      onClickBody();
    }
  };

  useEffect(() => {
    setChoice();
  }, [allContents]);

  useEffect(() => {
    if (content) {
      setExampleReady(false);
      setChoice();
      setSpell(content['spell']);
      setMean(content['mean']);
      setExample(content['example'] ? content['example'] : '');
      setTranslate(content['translate'] ? content['translate'] : '');
      setExampleTTS(content['sentence']);
    }
  }, [content]);

  useEffect(() => {
    if (!exampleVoiceRef.current) return;
    if (checkVoice) {
      exampleVoiceRef.current.volume = 1;
    } else {
      exampleVoiceRef.current.volume = 0;
    }
  }, [checkVoice]);

  const setExampleTTS = async (example?: string) => {
    if (example) {
      const gender = 0; // 추후 변경
      const filename = getExampleFilename(example);
      const path = filename.charAt(0).toLocaleLowerCase();
      const full_path = `examples/${gender}/${path}/${filename}.mp3`;
      const res = await fetchGetApi(`/etc/tts/example?filename=${filename}&gender=${gender}&ver=2`);
      if (res.result) {
        const url = 'https://kr.object.ncloudstorage.com/longvoca-tts/' + full_path;
        exampleVoiceRef.current = new Audio(url);
        exampleVoiceRef.current.onloadedmetadata = () => setExampleReady(true);
        if (!checkVoice) exampleVoiceRef.current.volume = 0;
      } else {
        const postdata = {
          example: example,
          filename: filename,
          gender: gender,
        };
        await fetchPostApi(`/etc/tts/example`, postdata);
        await setExampleTTS(example);
      }
    } else {
      exampleVoiceRef.current = null;
    }
  };

  const setChoice = () => {
    if (content) {
      let tmp_choices: string[] = [];

      tmp_choices.push(content['spell']);

      let cnt = 0;
      const all_leng = allContents.length;
      let ran_num = 0;
      while (tmp_choices.length < 4) {
        ran_num = Math.floor(Math.random() * all_leng);
        if (allContents[ran_num]) {
          const tmp_choice = allContents[ran_num]['spell'];
          const tmp_answer = allContents[ran_num]['mean'];
          const trim_choice = tmp_choice.trim();
          if (
            !tmp_choices.includes(tmp_choice) &&
            !tmp_choices.includes(trim_choice) &&
            content['mean'] != tmp_answer
          ) {
            tmp_choices.push(tmp_choice);
          }
        }

        if (cnt > 1000) break;
        cnt += 1;
      }

      tmp_choices = arrShuffle(tmp_choices);

      setRightanswer(tmp_choices.findIndex(choice => choice == content['spell']) + 1);
      setQuestion(content['example']);
      setVisibleOX(false);
      setChoices([...tmp_choices]);
    }
  };

  useEffect(() => {
    const updatedExample = transExample();
    setExampleHTML(updatedExample);

    const updatedTranslate = transTranslate();
    setTranslateHTML(updatedTranslate);
  }, [nextDisable, example]);

  const exampleSentence = () => {
    if (example) {
      return (
        <Box
          sx={{
            width: '100%',
            ...d_flex_center,
            ...dir_column,
            whiteSpace: 'break-spaces',
            textAlign: 'center',
          }}
        >
          <Box
            component={'span'}
            sx={{
              fontWeight: '600',
              fontSize: is_mobile ? '4vh' : '4.5vh',
              color: 'rgb(108, 108, 108)',
              textAlign: 'center',
              letterSpacing: is_mobile ? '-0.425px' : '-1.225px',
              lineHeight: '1.1232',
            }}
            dangerouslySetInnerHTML={{
              __html: exampleHTML,
            }}
          ></Box>
          <Box
            component={'span'}
            sx={{
              fontSize: is_mobile ? '3.15vh' : '3.24vh',
              fontWeight: '300',
              color: 'rgb(108, 108, 108)',
              textAlign: 'center',
              marginTop: is_mobile ? '1.5vh' : '1.25vh',
            }}
            dangerouslySetInnerHTML={{
              __html: translateHTML,
            }}
          ></Box>
        </Box>
      );
    } else {
      return null;
    }
  };

  const transExample = () => {
    if (example) {
      if (!nextDisable) {
        const length =
          choices && choices.length > 0
            ? Math.round(choices.reduce((total, choice) => total + choice.length, 0) / choices.length)
            : spell.length;
        const blank = '  '.repeat(length + 2);
        if (example.includes('{{') && example.includes('}}')) {
          return example.replace(
            /{{(.*?)}}/g,
            `<span style="color: #eceaff;background-color: #eceaff;border-radius: 8px;text-wrap:nowrap;">${blank}</span>`,
          );
        } else {
          const regex = new RegExp(spell, 'ig');
          const replacement = `<span style="color: #eceaff;background-color: #eceaff;border-radius: 8px;text-wrap:nowrap;">${blank}</span>`;
          return example.replace(regex, replacement);
        }
      } else {
        if (example.includes('{{') && example.includes('}}')) {
          return example.replace(/{{(.*?)}}/g, '<span style="color: #4620e9">$1</span>');
        } else {
          return example.replace(new RegExp(spell, 'ig'), '<span style="color: #4620e9">$&</span>');
        }
      }
    } else {
      return '';
    }
  };

  const transTranslate = () => {
    if (translate) {
      return translate.replace(/{{(.*?)}}/g, '<span style="color: #4620e9; font-weight: 700;">$1</span>');
    } else {
      return '';
    }
  };

  const goNext = async () => {
    if (nextDisable) return;
    const current_right = typeof selectedIndex == 'number' && selectedIndex + 1 == rightanswer ? true : false;
    setRight(current_right);

    setNextDisable(true);

    setLearningStateData(prevState => ({
      ...prevState,
      show_modal: true,
    }));

    if (check_type && exampleVoiceRef.current) {
      setVisibleOX(true);
      if (!exampleVoiceRef.current.paused) {
        exampleVoiceRef.current.pause();
      }
      exampleVoiceRef.current.currentTime = 0;
      exampleVoiceRef.current.onended = () => {
        goNextProcess();
      };
      await playEffectSound(current_right ? 'correct' : 'wrong');
      if (!current_right) {
        setWrongIndex(selectedIndex);
        setVisibleOX(false);
        await exampleVoiceRef.current.play();
      } else {
        goNextProcess();
      }
    } else {
      setTimeout(() => {
        goNextProcess();
      }, 2000);
    }
  };

  const goNextProcess = async () => {
    setKeyboardStop(true);
    setWrongIndex(null);
    for (let i = 0; i < settimeout1.current.length; i++) {
      clearTimeout(settimeout1.current[i]);
    }
    settimeout1.current = [];
    const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheet));

    if (content) {
      resultsheet_tmp[current_step] = {
        no: current_step + 1,
        word_id: content['word_id'],
        sentence_id: content['sentence_id'],
        answer: selectedIndex != null ? selectedIndex + 1 : null,
        rightanswer: rightanswer,
        question: question,
        choices: choices,
      };
    }
    if (current_step == filteredContent.length - 1) {
      setVisibleOX(false);
      setSelectedIndex(null);
      setTimeout(() => {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
          resultsheet: resultsheet_tmp,
        }));

        const { label, icon, score, kor_label } = makeEndModuleModalProps(resultsheet_tmp);
        setEndModuleModal({ visible: true, icon, label, score, kor_label });
      }, 0);
    } else {
      setSelectedIndex(null);
      setTimeout(() => {
        setKeyboardStop(false);
      }, 1000);
      setTimeout(() => {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
          current_step: current_step + 1,
          resultsheet: resultsheet_tmp,
        }));
      }, 0);
    }
  };

  const toggleSpeak = (e: React.MouseEvent) => {
    e.stopPropagation();
    setLearningStateData(prevState => ({
      ...prevState,
      show_modal: true,
    }));
    setCheckVoice(!checkVoice);
    modal_alert.openModalAlert(checkVoice ? 'memorize_voice_off' : 'memorize_voice_on', undefined, undefined, () => {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: false,
      }));
    });
  };

  const toggleBookmark = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!note_contents.some(word => word.spell == spell)) {
      setVisibleAddPocketNote(true);
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
    }
  };

  const onClickSelection = (idx: number) => {
    setSelectedIndex(idx);
  };

  const onClickBody = () => {
    if (exampleVoiceRef.current) {
      if (!exampleVoiceRef.current.paused) {
        exampleVoiceRef.current.pause();
      }
      exampleVoiceRef.current.currentTime = 0;
      exampleVoiceRef.current.play();
    }
    setPause(!pause);
    if (!pause) {
      clearTimeout1();
      setFlashOn(true);
    }
  };

  useEffect(() => {
    if (pause) {
      setFlashOn(true);
    } else if (loaded) {
      clearTimeout2();
      settimeout2.current.push(
        setTimeout(() => {
          setFlashReady(true);
        }, 1),
      );
    }
  }, [pause]);

  const VoiceIcon = (bol: boolean) => {
    if (bol)
      return (
        <svg viewBox='0 0 43 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M18 3.50134L6.05859 14.5006L3.5 14.4998C2.2693 14.4998 0.5 14.3263 0.5 15.4998V25.4998C0.5 26.6736 2.2693 26.4998 3.5 26.4998H6L18 37.4998C19.4918 38.5666 21.0027 39.2778 21.0027 37.4998V3.50065C21.0027 1.72268 19.4545 2.43456 18 3.50134Z'
            fill='current'
          />
          <path
            d='M26.7745 15.3426C26.1462 14.8858 25.0992 14.8858 24.4712 15.3426C23.8429 15.7994 23.8429 16.5608 24.4712 17.0176C27.496 19.2171 27.496 22.7873 24.4712 24.9868C23.8429 25.4436 23.8429 26.205 24.4712 26.6616C24.7967 26.8985 25.2155 27 25.6111 27C26.0066 27 26.449 26.8817 26.7512 26.6447C31.079 23.5485 31.079 18.4727 26.7745 15.3426Z'
            fill='current'
          />
          <path
            d='M28.5026 8.49083C27.8325 9.14929 27.8325 10.2467 28.5026 10.9052C31.1094 13.4658 32.5496 16.8557 32.5496 20.4894C32.5496 24.123 31.1094 27.5129 28.5026 30.0735C27.8325 30.732 27.8325 31.8294 28.5026 32.4879C28.8501 32.829 29.297 33 29.7191 33C30.166 33 30.6128 32.8293 30.9356 32.5123C34.1879 29.3175 36 25.0498 36 20.5137C36 15.9777 34.2125 11.7343 30.9356 8.51522C30.2901 7.83238 29.1977 7.83238 28.5026 8.49083Z'
            fill='current'
          />
        </svg>
      );
    else
      return (
        <svg viewBox='0 0 43 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M18 3.50134L6.05859 14.5006L3.5 14.4998C2.2693 14.4998 0.5 14.3263 0.5 15.4998V25.4998C0.5 26.6736 2.2693 26.4998 3.5 26.4998H6L18 37.4998C19.4918 38.5666 21.0027 39.2778 21.0027 37.4998V3.50065C21.0027 1.72268 19.4545 2.43456 18 3.50134Z'
            fill='current'
          />
        </svg>
      );
  };

  return (
    <>
      <StyledMemorizeWrap is_mobile={mobileResolution ? 1 : 0}>
        <OXMark show={visibleOX} right={right}></OXMark>
        <StyledMemorizeBody>
          <SwitchTransition mode='in-out'>
            <CSSTransition key={`check-${current_step}`} classNames={'horizontal-right'} timeout={300}>
              <StyledMemorizeContentWrap id='contents-box'>
                <StyledMemorizeContents
                  sx={{ margin: mobileResolution ? '4px 0 2px 0' : '4px 0' }}
                  onClick={() => onClickBody()}
                >
                  <StyledLeftHeaderBox sx={{ justifyContent: 'flex-start' }}>
                    <CustomSpeakButton
                      disableRipple
                      onClick={(e: React.MouseEvent) => toggleSpeak(e)}
                      speak={checkVoice ? 1 : 0}
                    >
                      {VoiceIcon(checkVoice)}
                      {checkVoice ? null : <CustomDiagonalLine />}
                    </CustomSpeakButton>
                  </StyledLeftHeaderBox>
                  <StyledRightHeaderBox sx={{ justifyContent: 'flex-end' }}></StyledRightHeaderBox>

                  <StyledContentsBody>
                    {(function () {
                      return exampleSentence();
                    })()}
                  </StyledContentsBody>
                </StyledMemorizeContents>
                {loaded ? (
                  <StyledSelectionWrap>
                    {choices?.map((choice, idx) => {
                      return (
                        <StyledSelectionBox
                          sx={{
                            marginLeft: idx % 2 == 1 ? (mobileResolution ? '2px' : '4px') : '',
                            marginRight: idx % 2 == 0 ? (mobileResolution ? '2px' : '4px') : '',
                          }}
                          is_mobile={mobileResolution ? 1 : 0}
                          status={
                            wrongIndex == idx
                              ? 'wrong'
                              : selectedIndex == idx
                              ? visibleOX
                                ? right
                                  ? 'right'
                                  : 'wrong'
                                : 'selected'
                              : ''
                          }
                          key={`selection_${idx}`}
                          onClick={() => onClickSelection(idx)}
                        >
                          <Box component={'span'} sx={{ fontSize: mobileResolution ? '3.5vh' : '4vh' }}>
                            {choice}
                          </Box>
                        </StyledSelectionBox>
                      );
                    })}
                  </StyledSelectionWrap>
                ) : null}
              </StyledMemorizeContentWrap>
            </CSSTransition>
          </SwitchTransition>
        </StyledMemorizeBody>
        <StyledRightButtonBox is_mobile={mobileResolution ? 1 : 0} onClick={() => goNext()}>
          <StyledIconButton disableRipple>
            <AiOutlineRight />
          </StyledIconButton>
        </StyledRightButtonBox>
        <StyledBottom></StyledBottom>
        <ModalAddPocketNote
          visible={visibleAddPocketNote}
          onClose={() => {
            setVisibleAddPocketNote(false);
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
          }}
          content={content ? [content] : undefined}
        />
        <ModalEndModule
          visible={endModuleModal.visible}
          label={endModuleModal.label}
          kor_label={endModuleModal.kor_label}
          icon={endModuleModal.icon}
          score={endModuleModal.score}
          nextCallback={nextCallback}
        />
      </StyledMemorizeWrap>
    </>
  );
}

export default Check;
