import { useState, useRef, useContext, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userState } from '../../recoil/model/user';

import ModalDefault from './ModalDefault';
import { IoMdDocument } from 'react-icons/io';
import Button from '../../components/button/Button';
import { styled, Box, TextField, ButtonProps, Typography, IconButton } from '@mui/material';
import { d_flex_center, d_flex_space_between, dir_column } from '../../styles/common';
import { fetchFileApi, fetchGetApi, fetchPostApi, fetchPutApi } from '../../utils/api';
import { ModalContext } from '../../provider/ModalProvider';

import { CgClose } from 'react-icons/cg';
import { MdOutlineInsertPhoto } from 'react-icons/md';
import { AiOutlineCamera } from 'react-icons/ai';
import { deviceState } from '../../recoil/common/device';
import { fetchDeleteApi } from '../../utils/api';
import { settingState } from '../../recoil/model/settings';
import {
  modalDefaultProfileSettingState,
  openModalDefaultProfileSetting,
} from 'recoil/common/modalDefaultProfileSetting';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { toast_contents } from 'utils/modal_contents';

declare let window: any;

interface StyledModalLogoContainerProps {
  is_select_file: string;
}

const StyledModalLogoContainer = styled(Box)<StyledModalLogoContainerProps>(props => ({
  width: '480px',
  backgroundColor: props.theme.palette.white.main,
}));

const StyledModalSelectWrapper = styled(Box)(props => ({
  width: '100%',
  height: '15.25rem',
  minHeight: '170px',
  paddingTop: '2rem',
  ...d_flex_center,
}));

const StyledModalSelectBox = styled(Box)(props => ({
  width: '100%',
  height: '80%',
  color: props.theme.palette.gray.main,
  ...d_flex_center,
  ...dir_column,
}));

const StyledModalTopWrapper = styled(Box)(props => ({
  width: '100%',
  height: '15.25rem',
  minHeight: '170px',
  padding: '1.5rem 2.25rem',
}));

const StyledModalTop = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  border: `1.5px dashed ${props.theme.palette.gray_3.main}`,
  padding: '5% 0',
  ...d_flex_space_between,
  ...dir_column,
}));

const StyledNotice = styled(Box)(props => ({
  height: '40%',
  fontSize: '1.3rem',
  color: 'rgba(0, 0, 0, 0.6)',
  ...d_flex_center,
  ...dir_column,
}));

interface UploadedProps {
  uploaded: string;
}

const StyledFileBox = styled(Box)(props => ({
  width: '100%',
  height: '50%',
  minHeight: '40px',
  color: props.theme.palette.gray_1.main,
  position: 'relative',
  ...d_flex_center,
}));

const StyledTextField = styled(TextField)<UploadedProps>(props => {
  const color = props.uploaded == 'true' ? props.theme.palette.blue.main : props.theme.palette.gray_1.main;
  return {
    width: '55%',
    height: '80%',
    minHeight: '40px',
    marginLeft: '1rem',
    zIndex: 0,
    position: 'relative',
    '& > div': {
      height: '100%',
      input: {
        cursor: 'pointer',
        textAlign: 'left',
        color: props.theme.palette.gray_1.main,
      },
    },
    '& > label.MuiInputLabel-root': {
      fontSize: '1.35rem',
      transform: 'translate(12px, -9px) scale(0.75)',
      color,
    },
    '& > fieldset.MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
    },
  };
});

const StyledButtonWrapper = styled(Box)(props => ({
  width: '100%',
  height: '3.75rem',
  minHeight: '42px',
  borderTop: `1px solid #edeeef `,
  color: props.theme.palette.gray_1.main,
  ...d_flex_center,
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
}));

interface StyledButtonProps extends ButtonProps {
  $width?: string;
  $direction?: string;
}

const StyledButton = styled(Button)<StyledButtonProps>(props => ({
  width: props.$width ? props.$width : '100%',
  height: '100%',
  borderRadius: '0',
  fontSize: '1.12rem',
  borderRight: props.$direction ? `1px solid ${props.theme.palette.gray_2.main}` : 'none',
  ...d_flex_center,
  '&.Mui-disabled': {
    cursor: 'inherit',
  },
}));

const FileIcon = styled(IoMdDocument)<UploadedProps>(props => {
  const color = props.uploaded == 'true' ? props.theme.palette.blue.main : props.theme.palette.gray_1.main;
  return {
    width: '2.25rem',
    height: '2.25rem',
    color,
  };
});

const StyledPreviewWrap = styled(Box)({
  width: '100%',
  height: '18.25rem',

  img: {
    maxWidth: '80%',
    maxHeight: '80%',
  },
  ...d_flex_center,
});

const FileButton = styled(Button)({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: 'calc(25% + 2.25rem) ',

  p: {
    display: 'flex',
    maxWidth: '8rem',
    textOverflow: 'ellipsis',
    span: {
      overflowX: 'hidden',
      display: 'block',
      textOverflow: 'ellipsis',
      paddingRight: '0.25rem',
    },
  },
});

const SelectIconButton = styled(IconButton)(props => ({
  width: '5.5rem',
  height: '5.5rem',
  backgroundColor: props.theme.palette.gray_3.main,
  svg: {
    color: props.theme.palette.gray.main,
    width: '3rem',
    height: '3rem',
  },
}));

const CloseIconButton = styled(IconButton)(props => ({
  zIndex: 1,
  position: 'absolute',
  top: '2rem',
  transform: 'translate(50%, -50%)',
  right: '2rem',
  svg: {
    color: props.theme.palette.gray.main,
    width: '1.5rem',
    height: '1.5rem',
  },
}));

const DeleteIconButton = styled(IconButton)(props => ({
  zIndex: 1,
  position: 'absolute',
  top: '50%',
  transform: 'translate(50%, -50%)',
  right: 'calc(25% - 1rem) ',
  svg: {
    color: props.theme.palette.blue.main,
    width: '1.5rem',
    height: '1.5rem',
  },
}));

interface ModalLogoUploadProps {
  visible: boolean;
  onClose: () => void;
  onSetImage?: (file: File, url: string, image_type: string) => void;
  onSetDefaultImage?: () => void;
  isDefault: boolean;
  uploadingCallback?: () => void;
  profileUpload?: (img: File | string | null) => void;
  clickDefaultProfile?: (id: number) => void;
}

function ModalLogoUpload(props: ModalLogoUploadProps) {
  const setModalDefaultProfileSetting = useSetRecoilState(modalDefaultProfileSettingState);
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const [settingStateData, setSettingStateData] = useRecoilState<SettingsType>(settingState);
  const { modal_alert, modal_confirm } = useContext(ModalContext);

  const [uploaded, setUploaded] = useState(false);
  const [imageName, setImageName] = useState('Choose File');
  const [imageUrl, setImageUrl] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageType, setImageType] = useState('');
  const [imageSize, setImageSize] = useState(0);
  const [selected, setSelected] = useState(false);
  const [onCamera, setOnCamera] = useState(false);
  const visible = props.visible;
  const onClose = props.onClose;
  const onSetImage = props.onSetImage;
  const onSetDefaultImage = props.onSetDefaultImage;
  const profileUpload = props.profileUpload;
  const uploadingCallback = props.uploadingCallback;
  const is_default = props.isDefault;
  const clickDefaultProfile = props.clickDefaultProfile;
  const [deviceStateData, setDeviceStateData] = useRecoilState<DeviceType>(deviceState);
  const setToastBar = useSetRecoilState(toastBarState);
  const { center_type } = userStateData;

  const { is_mobile } = deviceStateData;
  const dom_label = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    if (!visible) {
      clearModal();
    }
  }, [visible]);

  const onClickInput = () => {
    const label = dom_label.current;
    if (label) {
      if (settingStateData.is_fullscreen) {
        //* B2C toast로 분기처리
        if (center_type == 'A' || center_type == 'B') {
          modal_alert.openModalAlert('please_off_full_screen');
        } else {
          openToastBar({ setToastBar }, toast_contents.error.please_off_full_screen, 'red', 2000);
        }
      } else {
        label.click();
      }
    }
  };

  const onUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files[0]) {
      const accept = ['image/jpeg', 'image/png'];

      if (!accept.includes(files[0].type)) {
        //* B2C toast로 분기처리
        if (center_type == 'A' || center_type == 'B') {
          modal_alert.openModalAlert('accept_image_type');
        } else {
          openToastBar({ setToastBar }, toast_contents.error.accept_image_type, 'red', 2000);
        }
        return;
      }

      if (Number(Number(files[0].size / 1024).toFixed(1)) >= 10000) {
        //* B2C toast로 분기처리
        if (center_type == 'A' || center_type == 'B') {
          modal_alert.openModalAlert('upload_limit');
        } else {
          openToastBar({ setToastBar }, toast_contents.error.upload_limit, 'red', 2000);
        }

        return;
      }
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          setUploaded(true);
          setImageSize(Number(Number(files[0].size / 1024).toFixed(1)));
          setImageName(files[0].name);
          setImageUrl(`${`${reader.result}`.replace(`data:${files[0].type};base64,`, '')}`);
          setImageFile(files[0]);
          setImageType(files[0].type);
        }
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const uploadImage = async () => {
    if (imageFile) {
      if (uploadingCallback) uploadingCallback();
      const image_form_data = new FormData();
      image_form_data.append('profile', imageFile);

      const upload_res = await fetchFileApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/profile`,
        image_form_data,
      );
      if (upload_res.result) {
        const title_text = '프로필이';
        const onCompleteProfileImage = async () => {
          clearModal();
          setUserStateData(prevState => ({
            ...prevState,
            profile: upload_res.data.profile,
            profile_type: 'custom',
          }));
          //* B2C toast로 분기처리
          setTimeout(() => {
            if (center_type == 'A' || center_type == 'B') {
              modal_alert.openModalAlert('success_setting', undefined, title_text);
            } else {
              openToastBar({ setToastBar }, toast_contents.info.success_setting_profile, '', 2000);
            }
          }, 150);
        };
        onClose();
        await onCompleteProfileImage();
      }
    } else if (onCamera) {
      if (uploadingCallback) uploadingCallback();
      const upload_res = await fetchPutApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/profile`,
        { profile: imageUrl },
      );
      if (upload_res.result) {
        const title_text = '프로필이';
        const onCompleteProfileImage = async () => {
          clearModal();
          setUserStateData(prevState => ({
            ...prevState,
            profile: upload_res.data.profile,
            profile_type: 'custom',
          }));
          //* B2C toast로 분기처리
          setTimeout(() => {
            if (center_type == 'A' || center_type == 'B') {
              modal_alert.openModalAlert('success_setting', undefined, title_text);
            } else {
              openToastBar({ setToastBar }, toast_contents.info.success_setting_profile, '', 2000);
            }
          }, 150);
        };
        onClose();
        await onCompleteProfileImage();
      }
    }
  };

  const confirmResetLogo = () => {
    openModalDefaultProfileSetting({ setModalDefaultProfileSetting, arbitrary: true, clickDefaultProfile });
    onClose();
  };

  const resetImage = async () => {
    const reset_res = await fetchDeleteApi(
      `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/profile`,
    );

    if (reset_res.result) {
      onClose();
      const title_text = '프로필이';
      const onCompleteProfileDefaultImage = async () => {
        await setUserStateData(prevState => ({
          ...prevState,
          profile: reset_res.data.profile,
          profile_type: 'default',
        }));
        setTimeout(() => {
          modal_alert.openModalAlert('success_setting', undefined, `기본 ${title_text}`);
        }, 150);
      };
      await onCompleteProfileDefaultImage();
    }
  };

  const clearModal = () => {
    setImageUrl('');
    setImageSize(0);
    setUploaded(false);
    setOnCamera(false);
    setImageType('');
    setImageFile(null);
    setImageName('Choose File');
    setSelected(false);
  };

  const takePicture = (source: any) => {
    const options = {
      quality: 50,
      destinationType: window.Camera.DestinationType.DATA_URL,
      sourceType: source,
      encodingType: window.Camera.EncodingType.JPEG,
      mediaType: window.Camera.MediaType.PICTURE,
      correctOrientation: true,
      saveToPhotoAlbum: false,
    };

    window.navigator.camera.getPicture(onSuccess, onFail, options);
  };

  const onSuccess = (imageBase64: string) => {
    // Update the state
    setOnCamera(true);
    setSelected(true);
    setUploaded(true);
    setImageUrl(imageBase64);
  };

  const onFail = (message: string) => {
    console.log('Failed because: ' + message);
  };

  const onClickCamera = () => {
    takePicture(window.Camera.PictureSourceType.CAMERA);
  };

  const onClickFile = () => {
    if (settingStateData.is_fullscreen) {
      document.documentElement
        .requestFullscreen()
        .then(res => {
          const input_file = document.getElementById('upload_image');
          console.log(input_file);
          if (input_file) {
            input_file.click();
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  const onClickProfile = () => {
    if (profileUpload) profileUpload(is_mobile && imageFile ? imageFile : is_mobile && imageUrl ? imageUrl : imageUrl);
    onClose();
  };

  return (
    <ModalDefault visible={visible} onClose={onClose}>
      <StyledModalLogoContainer is_select_file={imageUrl.length > 0 ? 'true' : 'false'}>
        {!onCamera ? (
          <>
            {!selected && is_mobile ? (
              <StyledModalSelectWrapper>
                <CloseIconButton onClick={onClose} disableRipple>
                  <CgClose />
                </CloseIconButton>
                <StyledModalSelectBox sx={{ paddingLeft: is_mobile ? '1.5rem' : '' }}>
                  <SelectIconButton onClick={() => setSelected(true)} disableRipple>
                    <MdOutlineInsertPhoto />
                  </SelectIconButton>
                  <Box component={'span'} sx={{ cursor: 'default', lineHeight: '3rem', fontSize: '1.12rem' }}>
                    갤러리
                  </Box>
                </StyledModalSelectBox>
                {is_mobile ? (
                  <StyledModalSelectBox sx={{ paddingRight: '1.5rem', borderLeft: '1px solid #edeeef' }}>
                    <SelectIconButton onClick={onClickCamera} disableRipple>
                      <AiOutlineCamera />
                    </SelectIconButton>
                    <Box component={'span'} sx={{ cursor: 'default', lineHeight: '3rem' }}>
                      카메라
                    </Box>
                  </StyledModalSelectBox>
                ) : null}
              </StyledModalSelectWrapper>
            ) : (
              <StyledModalTopWrapper>
                <StyledModalTop>
                  <StyledNotice>
                    <Box component={'span'} sx={{ lineHeight: '2rem' }}>
                      이미지 파일을 선택해주세요.
                    </Box>
                    <Box component={'span'} sx={{ fontSize: '1rem' }}>
                      (jpeg, png 확장자만 지원합니다.)
                    </Box>
                  </StyledNotice>
                  <StyledFileBox>
                    <FileIcon uploaded={`${uploaded}`} />
                    <Box component={'label'} htmlFor='upload_image' ref={dom_label} style={{ display: 'none' }}></Box>
                    <Box
                      sx={{ display: 'none' }}
                      component={'input'}
                      type='file'
                      accept='image/*'
                      style={{ display: 'none' }}
                      id='upload_image'
                      onChange={onUploadImage}
                    />
                    <StyledTextField
                      id='upload_logo'
                      label='File'
                      uploaded={`${uploaded}`}
                      value={uploaded ? '' : imageName}
                      focused
                      color={uploaded ? 'blue' : 'gray_2'}
                      type='button'
                      onClick={onClickInput}
                    />
                    {uploaded ? (
                      <>
                        <DeleteIconButton onClick={clearModal} disableRipple>
                          <CgClose />
                        </DeleteIconButton>
                        <FileButton disableRipple variant='contained' color='blue' onClick={onClickInput}>
                          <Typography>
                            <Typography component='span' sx={{ whiteSpace: 'nowrap' }}>
                              {imageName}
                            </Typography>
                            {imageSize}KB
                          </Typography>
                        </FileButton>
                      </>
                    ) : null}
                  </StyledFileBox>
                </StyledModalTop>
              </StyledModalTopWrapper>
            )}
          </>
        ) : null}
        {imageUrl ? (
          <StyledPreviewWrap>
            <img src={`data:${imageType};base64,${imageUrl}`} />
          </StyledPreviewWrap>
        ) : null}
        <StyledButtonWrapper>
          {imageUrl ? (
            <>
              <StyledButton
                $width='50%'
                color='purple'
                $direction='left'
                onClick={profileUpload ? onClickProfile : uploadImage}
                disabled={!uploaded}
              >
                적용
              </StyledButton>
              <StyledButton $width='50%' color='gray_1' onClick={onClose}>
                취소
              </StyledButton>
            </>
          ) : (
            <StyledButton color='gray_1' onClick={confirmResetLogo} disabled={is_default}>
              기본 프로필 변경
            </StyledButton>
          )}
        </StyledButtonWrapper>
      </StyledModalLogoContainer>
    </ModalDefault>
  );
}

export default ModalLogoUpload;
