import { CASH_RECEIPTS_TYPE_LIST } from './constants';

export const admin_info_form: RegistrationDataType[] = [
  {
    id: '',
    key: 'product',
    type: 'text',
    disabled: true,
    value: '',
    period: '',
    title: '상품',
    required: false,
  },
  {
    id: '',
    key: 'name',
    type: 'text',
    value: '',
    title: '이름',
    required: false,
  },
  {
    id: '',
    key: 'accountId',
    type: 'text',
    value: '',
    title: '아이디(휴대폰 번호)',
    disabled: true,
    required: false,
  },
  {
    id: '',
    key: 'password',
    type: 'password',
    value: '',
    title: '비밀번호',
    maxLength: 20,
    required: false,
    placeholder: '변경 시 기재해주세요.',
    valiType: 'password',
    errorText: '최소 8자 이상 영문 소문자, 숫자, 특수문자 등 으로 조합하세요.',
    errorTextStyle: { top: 'calc(1.5rem / 3)' },
  },
];

export const tax_invoice_form: Array<RegistrationDataType[]> = [
  [
    {
      id: 'brn',
      key: 'brn',
      type: 'text',
      value: '',
      title: '사업자 등록 번호',
      valiType: 'brn',
      error: false,
      errorText: '사업자 등록번호는 올바른 정보로 입력해야 합니다.',
      required: true,
      errorTextStyle: { top: 'calc(2.25rem / 5)', margin: '0' },
    },
    {
      id: 'corporationName',
      key: 'corporationName',
      type: 'text',
      value: '',
      title: '상호명',
      required: true,
    },
  ],
  [
    {
      id: 'representativeTax',
      key: 'representative',
      type: 'text',
      value: '',
      title: '대표자',
      required: true,
    },
    {
      id: 'emailTax',
      key: 'email',
      type: 'text',
      value: '',
      title: '이메일',
      valiType: 'email',
      errorText: '이메일이 형식에 맞지 않습니다.',
      required: true,
      errorTextStyle: { top: 'calc(2.25rem / 5)', margin: '0' },
    },
  ],
  [
    {
      id: 'businessCondition',
      key: 'businessCondition',
      type: 'text',
      value: '',
      title: '업태',
      required: true,
    },
    {
      id: 'businessItem',
      key: 'businessItem',
      type: 'text',
      value: '',
      title: '종목',
      required: true,
    },
  ],

  [
    {
      id: '',
      key: 'businessAddress',
      type: 'text',
      value: '',
      title: '사업장 주소',
      required: true,
      disabled: true,
    },
    {
      id: '',
      key: 'zipCode',
      type: 'text',
      value: '',
      required: true,
      disabled: true,
    },
    {
      id: '',
      key: 'businessAddressbutton',
      type: 'postbutton',
      value: '',
      buttonTitle: '우편번호 검색',
    },
  ],
  [
    {
      id: 'post',
      key: 'post',
      type: 'post',
      value: '',
      visible: false,
      required: false,
    },
  ],
  [
    {
      id: 'detailAddress',
      key: 'detailAddress',
      type: 'text',
      value: '',
      required: true,
    },
  ],
];

export const cash_receipt_form: Array<RegistrationDataType[]> = [
  [
    {
      id: '',
      key: 'tradeType',
      type: 'select',
      value: '',
      title: '거래 구분',
      required: true,
      modalType: 'radio',
      modalTitle: '거래 구분',
      modalContents: CASH_RECEIPTS_TYPE_LIST,
    },
  ],
  [
    {
      id: 'representativeCash',
      key: 'representative',
      type: 'text',
      value: '',
      title: '대표자',
      required: true,
    },
  ],
  [
    {
      id: 'emailCash',
      key: 'email',
      type: 'text',
      value: '',
      title: '이메일',
      valiType: 'email',
      errorText: '이메일이 형식에 맞지 않습니다.',
      required: true,
      errorTextStyle: { top: 'calc(2.25rem / 5)', margin: '0' },
    },
  ],
  [
    {
      id: 'phone',
      key: 'phone',
      type: 'text',
      value: '',
      title: '휴대폰 번호',
      required: true,
      valiType: 'phone',
      placeholder: '숫자로만 입력해주세요.',
      errorText: '올바른 휴대폰 번호를 입력해주세요.',
      errorTextStyle: { top: 'calc(2.25rem / 5)', margin: '0' },
    },
  ],
];
