import { SignupUI } from './Signup.Presenter';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { modalTabState, openModalTab } from 'recoil/common/modaltab';
import { modalManual2State, openModalManual2 } from 'recoil/common/modalManual2';
import { fetchGetApi, fetchSuperPostApi } from 'utils/api';
import { autoHypenPhone } from 'utils/tools';
import { BASE_URL } from 'utils/constants';
import { deviceState } from 'recoil/common/device';
import { useNavigate } from 'react-router';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { toast_contents } from 'utils/modal_contents';

declare let window: any;

export function SignupContainer() {
  const navigate = useNavigate();
  const setModalTab = useSetRecoilState(modalTabState);
  const setModalManual2 = useSetRecoilState(modalManual2State);
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { is_mobile, platform } = deviceStateData;
  const setToastBar = useSetRecoilState(toastBarState);
  const [step, setStep] = useState(1);
  const [existMember, setExistMember] = useState<boolean>(false); //이미 존재하는 회원인지
  const [parentName, setParentName] = useState<string>(''); //학부모 성함
  const [phoneNumber, setPhoneNumber] = useState<string>(''); //핸드폰번호
  const [recommCode, setRecommCode] = useState<string>(''); // 추천코드(선택)
  const [applyRecomm, setApplyRecomm] = useState(false); // 추천코드 적용여부(선택)
  const [isChecked1, setIsChecked1] = useState<boolean>(false); //이용약관
  const [isChecked2, setIsChecked2] = useState<boolean>(false); //개인정보 취급방침
  const [isJoinProcessing, setIsJoinProcessing] = useState<boolean>(false); // 가입버튼 클릭중 상태
  const [postdate, setPostdate] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errMsgPassword, setErrMsgPassword] = useState<string>('');
  const [top, setTop] = useState<string>('0px');
  const [externalKeyboard, setExternalKeyboard] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const signUpBtnDisabled = useRef(false);

  const outSignupPage = useCallback(() => {
    navigate(`${BASE_URL}/login`);
  }, []);

  const onClickUnderbar = useCallback((tab: number) => {
    openModalTab({ setModalTab }, { tabNumber: tab });
  }, []);

  const onClickCustomerService = useCallback(() => {
    if (is_mobile) {
      if (platform == 'ios') {
        window.cordova.InAppBrowser.open('https://pf.kakao.com/_VvCxkxj/chat', '_system');
      } else {
        window.Open.openUrl('https://pf.kakao.com/_VvCxkxj/chat');
      }
    } else {
      window.open('https://pf.kakao.com/_VvCxkxj/chat');
    }
  }, [is_mobile, platform]);

  const onClickQuestion = useCallback(() => {
    const options = {
      title: '인증에 어려움이 있으시다면?',
      subTitle: '혹시 아래 내용에 해당되는지 확인해주세요.',
      contents: [
        {
          title: '인증번호 문자가 오지 않아요.',
          content: [
            '본인인증 기관의 발송번호 (1666-6410)가',
            '스팸으로 분류되지 않으셨는지 확인 부탁드리고',
            '그럼에도 수신 불가 시, 고객센터로 문의해주세요.',
          ],
        },
        {
          title: '국내 거주자가 아니에요',
          content: ['그럼에도 수신 불가 시, 고객센터로 문의해주세요.'],
        },
      ],
      buttonLabel: '고객센터',
      callback: onClickCustomerService,
    };

    openModalManual2({ setModalManual2 }, options);
  }, [onClickCustomerService]);

  const handlePostMessage = async (event: MessageEvent) => {
    // const msgdata = e.data;

    // if (typeof msgdata == 'string') {
    //   if (msgdata == 'kcpcert_display_none') {
    //     (document.getElementById('kcpcert_student') as HTMLLIElement).style.display = 'none';
    //   } else if (msgdata == 'kcpcert_display_blank') {
    //     (document.getElementById('kcpcert_student') as HTMLLIElement).style.display = '';
    //   } else {
    //     return;
    //   }
    // } else {
    //   if (msgdata && msgdata.msg == 'cert_result' && msgdata.cellno != '' && msgdata.name != '') {
    //     (document.getElementById('kcpcert_student') as HTMLLIElement).style.display = 'none';
    // setPhoneNumber(msgdata.cellno);
    // setParentName(msgdata.name);
    // handleNext(msgdata.cellno);
    //     // setFirstStepDone(true);
    //   }
    // }

    if (event.origin == cert_server) {
      const msgdata = event.data;
      console.log('event', msgdata);

      if (msgdata.msg == 'cert_result') {
        if (msgdata.id != '' && msgdata.cellno != '' && msgdata.name != '') {
          // * 인증 O
          setPhoneNumber(msgdata.cellno);
          setParentName(msgdata.name);
          handleNext(msgdata.cellno);
          console.log(name + ' ' + msgdata.cellno);
        } else {
          // * 인증 X
        }
      }

      if (msgdata == 'kcpcert_display_none') {
        const kcpcert_iframe = document.getElementById('kcpcert');
        if (kcpcert_iframe) {
          kcpcert_iframe.style.display = 'none';
        }
      }

      if (msgdata == 'kcpcert_display_blank') {
        const kcpcert_iframe = document.getElementById('kcpcert');
        if (kcpcert_iframe) {
          kcpcert_iframe.style.display = '';
        }
      }
    }
  };

  useEffect(() => {
    signUpBtnDisabled.current = false;
    //! kcpcert 세팅
    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);
      setPasswordVisible(false);
    };
  }, []);

  const certLoadingIndicator = {
    show: function (message = 'Loading...') {
      const certLoadingMessage = document.querySelector('#certLoadingIndicator .certLoading-content p');
      if (certLoadingMessage) {
        certLoadingMessage.innerHTML = message;
      }
      const certLoadingIndicator = document.getElementById('certLoadingIndicator');
      if (certLoadingIndicator) {
        certLoadingIndicator.style.display = 'flex';
      }
    },
    hide: function () {
      const certLoadingIndicator = document.getElementById('certLoadingIndicator');
      if (certLoadingIndicator) {
        certLoadingIndicator.style.display = 'none';
      }
    },
    updateMessage: function (message: string) {
      const certLoadingMessage = document.querySelector('#certLoadingIndicator .certLoading-content p');
      if (certLoadingMessage) {
        certLoadingMessage.innerHTML = message;
      }
    },
  };

  const onClickKCP = () => {
    certLoadingIndicator.show('인증을 준비중입니다.<br>잠시만 기다려주세요.');
    setTimeout(function () {
      certLoadingIndicator.hide();
    }, 1000);

    const frame = document.getElementById('kcpcert') as HTMLIFrameElement;
    if (frame && frame.contentWindow) frame.contentWindow.postMessage('kcpcert_start', cert_server);
  };

  //! Checkbox
  const onClickCheckbox = useCallback((idx: number) => {
    if (idx == 1) {
      // 이용약관
      setIsChecked1(prev => !prev);
    } else {
      // 개인정보취급방침
      setIsChecked2(prev => !prev);
    }
  }, []);

  //! 다음 단계로 넘기는 함수 (+ 기존 사용자인지 신규 회원인지 check하기)
  const handleNext = useCallback(async (phoneNumber: string) => {
    try {
      const uri = `/signup/find_account?loginid=${phoneNumber}`;
      const res = await fetchGetApi(uri);
      console.log('res', res);
      if (res.result) {
        // * 기존에 존재하는 회원
        const date = new Date(res.data.accountInfo.postdate);
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
          date.getDate(),
        ).padStart(2, '0')}`;
        setPostdate(formattedDate);
        setExistMember(true);
      } else {
        // * 신규 회원
        setExistMember(false);
      }

      setStep(prev => prev + 1);
    } catch (error) {
      console.log(error);
    }
  }, []);

  //! step2 로그인 버튼 클릭 메서드
  const onClickToLoginPage = useCallback(() => {
    outSignupPage();
  }, []);

  //! 비밀번호 입력 메서드
  const onChangePassword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }, []);

  //! 비밀번호 검증
  const passwordValidate = useCallback((password: string) => {
    if (password.length < 8) {
      setErrMsgPassword('최소 8자 이상입니다.');
      return false;
    }
    if (password.length > 20) {
      setErrMsgPassword('최대 20자 이하입니다.');
      return false;
    }
    const regex =
      /^(((?=.*[a-z])(?=.*[!@#$%&*_+]))|((?=.*[!@#$%&*_+])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9])))(?!.*[A-Zㄱ-힣])(\S){8,20}$/;

    if (!regex.test(password)) {
      setErrMsgPassword('영문 소문자, 숫자, 특수문자 등으로 다시 조합하세요.');
      return false;
    }

    setErrMsgPassword('');
    return true;
  }, []);

  //! step2 회원가입 버튼 클릭시 처리
  const handleSubmitToSignup = async () => {
    if (passwordValidate(password) && signUpBtnDisabled.current == false) {
      // * 비밀번호 검증 통과
      setIsJoinProcessing(true);
      signUpBtnDisabled.current = true;
      const params: { [key: string]: string } = {
        loginId: phoneNumber,
        password: password,
        name: parentName,
        centerName: '홈스쿨',
        phone: autoHypenPhone(phoneNumber),
        type: 'C',
      };

      try {
        const url = `/longvoca/customers/freetrial?ver=2${applyRecomm ? `&recommCode=${recommCode}` : ''}`;
        const res = await fetchSuperPostApi(url, params);

        // 회원가입 성공 후 로그인 페이지로 이동 후 toast 노출
        if (res.result) {
          console.log(res.result, 'res.result');
          navigate(`${BASE_URL}/login`, { state: { fromSignup: true } });
          signUpBtnDisabled.current = false;

          // modal_alert.openModalAlert(
          //   'empty',
          //   undefined,
          //   `회원가입 되었습니다. \r\n담당자를 통해 곧 연락드리겠습니다.`,
          //   () => {
          //     outSignupPage();
          //     signUpBtnDisabled.current = false;
          //   },
          // );
          // outSignupPage();

          // openToastBar({ setToastBar }, toast_contents.info.success_setting_profile, '', 2000);
        } else {
          console.log(res.message, 'res.message');
          // modal_alert.openModalAlert('empty', undefined, res.message);
          openToastBar({ setToastBar }, res.message, '', 2000);
          signUpBtnDisabled.current = false;
        }
      } catch (error) {
        console.error(error);
        // modal_alert.openModalAlert('error_post_failed');
        openToastBar({ setToastBar }, toast_contents.error.error_post_failed, 'red', 2000);
        signUpBtnDisabled.current = false;
      }
    }
  };

  useEffect(() => {
    if (is_mobile) {
      if (typeof window.ExternalKeyboard == 'function') window.ExternalKeyboard.checkOnce();
      if (window.ExternalKeyboard.isUsed) setExternalKeyboard(true);
    }
  }, [is_mobile]);

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform === 'android') setTop('-40vh');
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform === 'android') setTop('0px');
  };

  const brand = useMemo(() => {
    return process.env.NODE_ENV === 'development' ? 'LT' : 'L';
  }, []);

  const cert_server = useMemo(() => {
    return 'https://api.tabstudy.co.kr';
  }, []);

  const ifrmae_url = useMemo(() => {
    return `https://api.tabstudy.co.kr/lib/certcellno_irontrain/SMART_ENC/smartcert_start.php?brand=${brand}`;
  }, []);

  const onClickBackButton = useCallback(() => {
    navigate(`${BASE_URL}/login`);
  }, []);

  //* 눈클릭시 메서드
  const onClickEye = useCallback((visiblePassword: boolean) => {
    setPasswordVisible(visiblePassword);
  }, []);

  //* 추천코드 입력 메서드
  const onChangeRecommCode = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setRecommCode(e.target.value);
  }, []);

  //* 추천코드 유효성 검증 메서드
  const onClickApplyRecomm = useCallback(async () => {
    if (!recommCode) return;
    const url = `/etc/recommcode/check?recommCode=${recommCode}`;
    const res = await fetchGetApi(url);
    if (res.result) {
      setApplyRecomm(true);
    } else {
      alert('유효하지 않은 추천코드입니다.');
    }
  }, [recommCode]);

  const props = {
    step,
    existMember,
    parentName,
    phoneNumber,
    isChecked1,
    isChecked2,
    postdate,
    password,
    errMsgPassword,
    onClickUnderbar,
    onClickQuestion,
    onClickCheckbox,
    onClickKCP,
    handleNext,
    onClickToLoginPage,
    onChangePassword,
    handleSubmitToSignup,
    top,
    onFocus,
    onBlur,
    ifrmae_url,
    is_mobile,
    onClickBackButton,
    onClickEye,
    passwordVisible,
    recommCode,
    onChangeRecommCode,
    isJoinProcessing,
    onClickApplyRecomm,
    applyRecomm,
  };

  return <SignupUI {...props} />;
}
