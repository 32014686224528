import { ModalStudyStopUI } from './ModalStudyStop.Presenter';
import { useRecoilState } from 'recoil';
import { ModalStudyStopType, closeModalStudyStop, modalStudyStopState } from 'recoil/common/modalStudyStop';
import { useCallback } from 'react';

export function ModalStudyStopContainer() {
  const [modalStudyStop, setModalStudyStop] = useRecoilState<ModalStudyStopType>(modalStudyStopState);

  const clickCheckButton = useCallback(() => {
    closeModalStudyStop({ setModalStudyStop });
  }, []);

  const props = {
    visible: modalStudyStop.visible,
    clickCheckButton,
  };

  return <ModalStudyStopUI {...props} />;
}
