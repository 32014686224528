import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { routesState } from '../../recoil/common/routes';
import { userState } from '../../recoil/model/user';
import { searchbarState } from '../../recoil/common/searchbar';
import Button from '../button/Button';
import {
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Box,
  Table as MuiTable,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  ButtonProps,
  TableCellProps,
  TableRowProps,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { TableContext } from '../../provider/TableProvider';

import { GoTriangleDown, GoTriangleUp } from 'react-icons/go';
import {
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
} from 'react-icons/hi';

import { TdHover } from '../../styles/table_style';

import { BASE_URL } from '../../utils/constants';
import { ModalContext } from '../../provider/ModalProvider';
import { makeCommaAsThousandSeparator } from '../../utils/tools';
import { d_flex } from '../../styles/common';

import first from '../../assets/images/learderboard/1st.png';
import second from '../../assets/images/learderboard/2nd.png';
import third from '../../assets/images/learderboard/3rd.png';
import { useNavigate } from 'react-router';
import { bookState } from '../../recoil/model/book';
import dayjs from 'dayjs';
import { fetchPostApi } from '../../utils/api';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';

interface TableProps {
  fields: TableFieldsType[];
  items: TableItemsType[];
  pagination?: boolean;
  count: number;
  dataKey: string;
  check?: boolean;
  number?: boolean;
  detail?: boolean;
  detailStartNum?: number;
  detailFields?: { [key: string]: string };
  disabled?: boolean;
  emptyText?: string;
  tableQuery?: TableQueryType;
  detailOptions?: { detailWidth: string };
  onChangeTable?: (query: TableQueryType) => void;
  disabled_vail?: () => void;
  makeButtonOptions?: (
    field: TableFieldsType,
    item: TableItemsType,
    table_items: TableItemsType[],
    idx: number,
  ) => {
    disabled?: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    color?: string;
    setDisabled?: () => boolean;
    sx?: SxProps<Theme>;
  };
  onClickContent?: (field: TableFieldsType, item: TableItemsType, idx: number, key?: string, detail?: any) => void;
  onChangeCheckbox?: (tableItem: TableItemsType[], scount: number) => void;
  getStudyJournalButtonClick?: (item: any) => void;
}

const CustomTable = styled(MuiTable)(() => ({
  width: '100%',
  tableLayout: 'fixed',

  '.MuiCheckbox-root': {
    width: 'auto !important',
  },
}));

const CustomTableHead = styled(TableHead)(props => ({
  width: '100%',
  backgroundColor: '#f2f3f5',
  tr: {
    borderBottom: `1px solid #ededed`,
  },
  th: {
    '&:first-of-type': {
      paddingLeft: '16px',
    },
    '& > span': {
      width: '100% !important',
      fontWeight: 'bold',
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
}));

const CellIconBox = styled(Box)(() => ({
  width: '1.5rem',
  height: '1.5rem',
  position: 'absolute',
  left: '53%',
  top: '50%',
  transform: 'translate(-50%, -50%) rotate(-90deg)',
}));

const CustomTableBody = styled(TableBody)(() => ({
  width: '100%',
  td: {
    '&:first-of-type': {
      paddingLeft: '16px',
    },
    paddingRight: '0.25rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

interface CustomTableRowProps extends TableRowProps {
  detail?: string;
}

const CustomTableRow = styled(TableRow)<CustomTableRowProps>(props => ({
  width: '100%',
  borderBottom: '1px solid var(--gray-3)',

  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },

  '&.detail-row:not(:last-child)': {
    borderBottom: 'none',
  },
  cursor: props.detail === 'true' ? 'pointer' : 'initial',
  '& > *': {
    borderBottom: 'unset',
  },
  'td, th': {
    padding: 0,
    width: 'auto',
    position: 'relative',
    fontSize: '1rem',
    div: {
      whiteSpace: 'pre-wrap',
    },
  },
  '&:focus-within': {
    // background: 'var(--light-cyan)',
  },

  '&.show_details': {
    background: props.theme.palette.light_purple_3.main,
    'td, th': {
      color: props.theme.palette.purple.main,
    },

    '.icon-box': {
      svg: {
        transform: 'rotate(90deg)',
      },
    },

    input: {
      '&.focus': {
        backgroundColor: props.theme.palette.white.main,
      },
    },
  },
}));

const DetailDeco = styled('div')(() => ({
  width: '1rem',
  height: '1rem',
  borderBottom: '1px dotted var(--gray-1)',
  borderLeft: '1px dotted var(--gray-1)',
  position: 'absolute',
  top: 'calc(25px - 0.5rem)',
  right: '0.75rem',
  transform: 'translateY(-50%)',
  '@media(max-width: 1023px)': {
    top: 'calc(25px - 1.5rem)',
    right: '0.75rem',
  },
}));

interface CusotmTableCellProps extends TableCellProps {
  width?: string;
  sort?: string;
  active_color?: string;
  color?: string;
  show_details?: string;
  weight?: string;
  is_top?: string;
}

const CustomTableCell = styled(TableCell)<CusotmTableCellProps>(props => {
  const style =
    props.active_color && props.show_details === 'true'
      ? {
          color: 'props.active_color' + ' !important',
          width: props.width + ' !important',
        }
      : {
          width: props.width + ' !important',
        };
  const weight_style = { fontWeight: props.weight ? props.weight : 'normal' };
  const style_top =
    props.is_top === 'true'
      ? { verticalAlign: 'top', b: { height: '40px', display: 'flex', alignItems: 'center' } }
      : {};
  return {
    display: 'table-cell !important',
    border: 'none',
    cursor: props.sort === 'true' ? 'pointer' : 'unset',
    color: props.color ? props.color + ' !important' : 'initial',
    ...style,
    ...style_top,
    ...weight_style,
    '&.MuiTableCell-alignCenter': {
      width: `${props.rowSpan ? '100%' : 'auto'}`,
    },

    '.sort-box': {
      position: 'relative',
      marginLeft: '4px',
      marginTop: '-0.125rem',
      svg: {
        position: 'absolute',
        width: '0.625rem',
        height: '0.625rem',
        color: 'var(--gray-1)',
        '&:first-of-type': {
          top: '50%',
          transform: 'translateY(-50%)',
          marginTop: '-0.25rem',
        },
        '&:last-of-type': {
          bottom: '50%',
          transform: 'translateY(50%)',
          marginBottom: '-0.25rem',
        },
      },
    },
  };
});

const CustomTableThBox = styled(Box)(props => ({
  ...d_flex,
  height: '7vh',
  maxHeight: '40px',
  alignItems: 'center',
}));

const CustomTableTdBox = styled(Box)(props => ({
  ...d_flex,
  height: '8vh',
  maxHeight: '50px',
  alignItems: 'center',
}));

const Pagination = styled(Box)(() => ({
  width: '100%',
  height: '5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',

  '.MuiIconButton-root': {
    svg: {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  '& > .MuiBox-root:not(.page)': {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    fontSize: '0.9rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    '.MuiButton-root': {
      width: '5rem',
      fontSize: '0.9rem',
      justifyContent: 'space-around',
      borderBottom: '1px solid var(--gray-1)',
      borderRadius: 0,
    },
    svg: {
      width: '0.75rem',
      height: '0.75rem',
    },
  },

  '@media(max-width: 1023px)': {
    '& > .MuiBox-root:not(.page)': {
      '.MuiButton-root': {
        borderColor: '#f0f0f0',
      },
    },
  },
}));

const TableCheckbox = styled(Checkbox)(({ theme }) => ({
  color: 'rgba(0,0,0,.54)',
  '&.MuiCheckbox-root': {
    transition: '.3s cubic-bezier(.25,.8,.5,1),visibility 0s;',
    padding: '5px 5px',
  },
  '.MuiSvgIcon-root': {
    width: '1.5rem',
    height: '1.5rem',
  },
}));

// const StyledReportLabelButton = styled(Button)(props => ({
//   fontWeight: '500',
//   padding: '0.4rem 1rem',
//   color: 'white !important',
//   borderRadius: '8px',
// }));

// studyJournal의 경우 버튼 스타일링 다르게 처리
const StyledReportLabelButton = styled(Button)<{ isStudyJournal?: boolean }>(props => ({
  backgroundColor: props.isStudyJournal ? props.theme.palette.purple.main : '',
  color: 'white !important',
  fontWeight: '500',
  padding: '0.4rem 1rem',
  borderRadius: props.isStudyJournal ? '4px' : '8px',
  minWidth: props.isStudyJournal ? '0px' : '64px',

  '&:hover': {
    backgroundColor: props.isStudyJournal ? props.theme.palette.purple.main : '',
  },

  '&.disabled': {
    '&.MuiButton-contained': {
      backgroundColor: 'rgb(232, 232, 232) ',
      color: props.theme.palette.white.main,
    },
  },
}));

const StyledLabelButton = styled(Button)(props => ({
  fontWeight: '500',
  padding: '0.46rem 1rem 0.34rem',
  color: 'white !important',
  borderRadius: '8px',
  '@media (max-width: 800px)': {
    padding: '0.46rem 1.25rem 0.34rem',
    minWidth: '0px',
  },
}));

function Table(props: TableProps) {
  const { playEffectSound } = useContext(EffectSoundContext);
  const fields = props.fields;
  const pagination = props.pagination === undefined ? true : props.pagination;
  const items = props.items;
  const detail = props.detail;
  const detail_start_num = props.detailStartNum;
  const detail_fields = props.detailFields;
  const check_table = props.check;
  const number_table = props.number;
  const count = props.count;
  const make_button_options = props.makeButtonOptions;
  const onClickContent = props.onClickContent;
  const onChangeTable = props.onChangeTable;
  const empty_text = props.emptyText;
  const data_key = props.dataKey;
  const table_query = props.tableQuery;
  const detail_options = props.detailOptions;
  const onChangeCheckbox = props.onChangeCheckbox;
  const getStudyJournalButtonClick = props.getStudyJournalButtonClick;

  const navigate = useNavigate();
  const setBookStateData = useSetRecoilState<BookType>(bookState);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const { center_type } = userStateData;
  const searchbarStateData = useRecoilValue<SearchbarType>(searchbarState);
  const [tableFields, setTableFields] = useState<TableFieldsType[]>([]);
  const [tableItems, setTableItems] = useState<TableItemsType[]>([]);
  const [tableQuery, setTableQuery] = useState<TableQueryType>({
    target: '',
    sort: '',
    page: -1,
    max: -1,
  });
  const [checkedCount, setCheckedCount] = useState(0);
  const [allChecked, setAllChecked] = useState(false);

  const [curMinCount, setCurMinCount] = useState('0');
  const [curMaxCount, setCurMaxCount] = useState('0');
  const [totalCount, setTotalCount] = useState('0');

  const { setSavedTableIdx, table_data, clearTargetTableData } = useContext(TableContext);

  useEffect(() => {
    if (table_query) {
      setTableQuery({ ...table_query });
    }
  }, [table_query]);

  useEffect(() => {
    if (table_data[data_key]) {
      if (table_data[data_key].checked_cnt === 0) {
        setCheckedCount(0);
        const new_table_items = tableItems.map(item => {
          item.checked = false;
          return item;
        });
        const tmp_fields = [...tableFields];
        const check_index = tmp_fields.findIndex(item => item.key == 'check');
        if (Number(check_index) >= 0) {
          tmp_fields[check_index].checked = false;
        }
        setTableFields([...tmp_fields]);
        setTableItems(new_table_items);
        setAllChecked(false);
      }
    }
  }, [table_data[data_key]]);

  useEffect(() => {
    setTableData(fields, items, count);
  }, [items, fields]);

  useEffect(() => {
    if (tableQuery) {
      setCurMinCount(makeCommaAsThousandSeparator(tableQuery.max * tableQuery.page - tableQuery.max + 1));
      setCurMaxCount(makeCommaAsThousandSeparator(count));
      setTotalCount(makeCommaAsThousandSeparator(tableQuery.max * tableQuery.page));
    }
  }, [tableQuery, count]);

  useEffect(() => {
    return () => {
      clearTargetTableData(data_key);
      clearTableData();
    };
  }, []);

  const clearTableData = () => {
    setTableFields([]);
    setTableItems([]);
    setCheckedCount(0);
    setAllChecked(false);
  };

  const setTableData = async (fields: TableFieldsType[], items: TableItemsType[], count: number) => {
    const cur_table_query = tableQuery.max == -1 ? table_query : tableQuery;
    const tmp_fields = [...fields];
    const tmp_items = [...items];

    if (detail) {
      tmp_fields.unshift({
        key: 'show',
        label: '조회',
        center: true,
        width: detail_options ? detail_options.detailWidth : '7.5%',
      });
    }
    if (number_table) {
      if (data_key == 'report_lookup') {
        tmp_fields.splice(1, 0, {
          key: 'no',
          label: data_key.includes('account') ? '순번' : 'No.',
          // label: 'No.',
          center: false, // 임시로 true 세팅
          width: '6%', // 임시로 width 뒤에 6% 세팅
          is_padding: check_table ? '0' : '1rem',
        });
      } else {
        tmp_fields.unshift({
          key: 'no',
          label: data_key.includes('account') ? '순번' : 'No.',
          // label: 'No.',
          center: data_key === 'studyJournal' ? true : false, // 임시로 true 세팅
          width: '6%', // 임시로 width 뒤에 6% 세팅
          is_padding: check_table ? '0' : '1.25rem',
          sx: data_key === 'studyJournal' ? { textAlign: 'center', paddingLeft: '0 !important' } : {},
        });
      }
    }
    if (check_table) {
      tmp_fields.unshift({
        key: 'check',
        label: '',
        center: true,
        width: '5%',
        checked: false,
      });
    }

    let cnt = 0;
    let spread = false;
    if (searchbarStateData.value && tmp_items.length == 1) {
      spread = true;
      if (data_key == 'manager_notice' && tmp_items[0].readed) {
        fetchPostApi(`/customers/${userStateData.customer_id}/accounts/manager/${userStateData.id}/notice`, {
          notice_id: tmp_items[0].id,
        });
        tmp_items[0].readed = false;
      }
    }

    for (const x of tmp_items) {
      if (detail) {
        x.show_details = false;
        if (spread) {
          x.show_details = true;
        }
      }

      if (number_table && cur_table_query) {
        cnt++;
        x.no = cnt + cur_table_query.max * cur_table_query.page - cur_table_query.max;
      }

      if (check_table) {
        x.checked = false;
      }
    }

    setTableFields([...tmp_fields]);
    setTableItems([...tmp_items]);
    setCheckedCount(0);
    setAllChecked(false);
  };

  const setChildrenCheckbox = (idx: number) => {
    const tmp_check_idx = table_data[data_key] ? [...table_data[data_key].checked_idx] : [];
    if (tmp_check_idx.includes(idx)) {
      const find_idx = tmp_check_idx.findIndex(item => item == idx);
      tmp_check_idx.splice(find_idx, 1);
    } else {
      tmp_check_idx.push(idx);
    }
    setSavedTableIdx(data_key, [...tmp_check_idx]);

    let checked_count = 0;
    const checked_idx: number[] = [];
    const table_items: TableItemsType[] = tableItems.map((item, index) => {
      let return_item: TableItemsType = {};
      if (item.checked) checked_count++;
      if (idx == index) {
        if (!item.checked) checked_count++;
        else checked_count--;

        return_item = {
          ...item,
          checked: !item.checked,
          show_details: false,
        };
      } else {
        return_item = { ...item, show_details: false };
      }

      if (return_item.checked) checked_idx.push(index);
      return return_item;
    });
    setTableItems(table_items);

    setCheckedCount(checked_count);
    let all_checked = false;
    if (checked_count == tableItems.length) {
      all_checked = true;
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }

    setTableFields(state => {
      return [
        ...state.map(item => {
          if (item.key == 'check') {
            return {
              ...item,
              checked: all_checked,
            };
          } else {
            return item;
          }
        }),
      ];
    });

    if (onChangeCheckbox) onChangeCheckbox(table_items, checked_count);
  };

  const setHeaderCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const table_items: TableItemsType[] = tableItems.map(item => {
      return {
        ...item,
        checked: checked,
      };
    });
    setTableItems([...table_items]);
    setTableFields(state => {
      return [
        ...state.map(item => {
          if (item.key == 'check') {
            return {
              ...item,
              checked,
            };
          } else {
            return item;
          }
        }),
      ];
    });
    if (checked) {
      const all_idx = Array.from(Array(tableItems.length).keys());
      setAllChecked(true);
      setCheckedCount(tableItems.length);
      setSavedTableIdx(data_key, all_idx);
      if (onChangeCheckbox) onChangeCheckbox(table_items, tableItems.length);
    } else {
      setAllChecked(false);
      setCheckedCount(0);
      setSavedTableIdx(data_key, []);
      if (onChangeCheckbox) onChangeCheckbox(table_items, 0);
    }
  };

  const onRowClicked = async (idx: number, e?: React.MouseEvent<HTMLTableRowElement>) => {
    try {
      if (e) {
        if (
          (e.target as HTMLElement).tagName != 'TD' &&
          (e.target as HTMLElement).tagName != 'SPAN' &&
          (e.target as HTMLElement).tagName != 'svg' &&
          (e.target as HTMLElement).tagName != 'path' &&
          (e.target as HTMLElement).tagName != 'DIV'
        ) {
          return;
        }
      }
      const tmp_table_items = [...tableItems];

      if (!tmp_table_items[idx].details) return false;

      playEffectSound('click');

      let cnt = 0;
      const idxs: number[] = [];
      for (let i = 0, leng = tmp_table_items.length; i < leng; i++) {
        const item_idx = i;
        const item = tmp_table_items[i];
        let return_item: TableItemsType = {};
        let show = false;
        if (item.show_details == true) {
          show = false;
        } else {
          show = true;
        }
        if (item_idx == idx) {
          if (item.readed && show) {
            fetchPostApi(`/customers/${userStateData.customer_id}/accounts/manager/${userStateData.id}/notice`, {
              notice_id: item.id,
            });
            // 공지사항 읽었다는 api 필요
            return_item = {
              ...item,
              readed: false,
              show_details: show,
              checked: show,
            };
          } else {
            return_item = {
              ...item,
              show_details: show,
              checked: show,
            };
          }
        } else {
          return_item = {
            ...item,
            show_details: false,
            checked: false,
          };
        }

        if (return_item.checked) {
          idxs.push(item_idx);
          cnt++;
        }
        tmp_table_items[item_idx] = return_item;
      }
      setCheckedCount(cnt);
      setTableItems([...tmp_table_items]);
      setSavedTableIdx(data_key, idxs);
    } catch (error) {
      console.log(error);
    }
  };

  const setDetailContents = (
    item: TableItemsType,
    key: string,
    idx: number,
    parent_idx: number,
    field: TableFieldsType,
  ) => {
    if (item.details && Object.keys(item.details).includes('updateNewsContent')) {
      if (idx === 0) {
        return (
          <CustomTableCell
            component='td'
            colSpan={tableFields.length}
            sx={{
              border: 'none',
              '& > div': {
                width: '100%',
                margin: '0 auto',
              },
            }}
          >
            <div
              style={{
                padding: '1.5rem 10.125rem',
                backgroundColor: 'white',
              }}
              dangerouslySetInnerHTML={{ __html: item.details.updateNewsContent as string }}
            />
          </CustomTableCell>
        );
      }

      return null;
    }

    if (Number(detail_start_num) > idx - 2) {
      const center = field.center;

      let col_span = 1;
      let is_top = 'false';
      let content: React.ReactNode | null = <></>;
      if (detail_start_num == idx + 1) {
        if (Number.isInteger(+key.substring(1)) && !(key.substring(1) === '0')) {
          content = null;
        } else {
          content = <DetailDeco />;
        }
      } else if (Number(detail_start_num) > idx) {
        content = null;
      } else if (detail_start_num == idx) {
        if (item.details) {
          let value = item.details[key];
          if (detail_fields) {
            col_span = tableFields.length - Number(detail_start_num) - 1;
            const title = detail_fields[key];
            if (key == 'schedule_type' && typeof value == 'string') {
              value = value.replace(/기본/gi, '<span class="c_green">기본');
              value = value.replace(/심화/gi, '<span class="c_blue">심화');
              value = value.replace(/단계 /gi, '단계 </span>');
              content = (
                <CustomTableTdBox>
                  <div style={{ padding: '0.25rem 0', flex: '1' }}>
                    <b>{title}</b>
                  </div>
                  <div style={{ padding: '0.25rem 0', flex: '9' }} dangerouslySetInnerHTML={{ __html: value }}></div>
                </CustomTableTdBox>
              );
            } else if (key == 'content') {
              if (searchbarStateData.value && tableItems.length == 1) {
                value = (value as string).replace(
                  searchbarStateData.value,
                  `<span style='border-color: #ffff0f !important; background-color: #ffff0f !important'>${searchbarStateData.value}</span>`,
                );
                content = (
                  <div style={{ ...d_flex }}>
                    <div
                      style={{ padding: '1.5rem 0', lineHeight: '1.5' }}
                      dangerouslySetInnerHTML={{ __html: value }}
                    ></div>
                  </div>
                );
              } else {
                content = (
                  <div style={{ ...d_flex }}>
                    <div style={{ padding: '1.5rem 0', lineHeight: '1.5' }}>{value}</div>
                  </div>
                );
              }
            } else {
              content = (
                <CustomTableTdBox>
                  <div style={{ padding: '0.25rem 0', flex: '1' }}>
                    <b>{title}</b>
                  </div>
                  <div style={{ padding: '0.25rem 0', flex: '9' }}>{value}</div>
                </CustomTableTdBox>
              );
            }
          } else {
            col_span = tableFields.length - Number(detail_start_num) - 1;
            content = <div style={{ padding: '0.25rem 0' }}>{value}</div>;
          }
        } else {
          return null;
        }
      } else if (detail_start_num == idx - 1) {
        if (item.details) {
          if (detail_fields) {
            if (key == 'publishdate') {
              content =
                userStateData.type < 20 || center_type == 'C' ? (
                  <Button
                    id={`${data_key}_button_${field.key}_${idx}`}
                    variant='contained'
                    onClick={() => moveScheduleBook(item)}
                    color={'green'}
                    disableRipple
                    sx={{ display: 'flex', justifyContent: 'center', minWidth: '5.5rem', borderRadius: '0.5rem' }}
                  >
                    <Box sx={{ marginLeft: '0.5rem' }}>{'이동'}</Box>
                    <HiOutlineChevronRight />
                  </Button>
                ) : null;
            } else {
              return (
                <CustomTableCell
                  is_top={is_top}
                  key={`body_row_${parent_idx}_cell_${idx}`}
                  component='td'
                  colSpan={col_span}
                  sx={{
                    whiteSpace: 'pre-wrap',
                    borderBottom: idx > 1 ? '1px solid var(--gray-3)' : 'none',
                    '@media(max-width: 1023px)': {
                      borderColor: '#f5f5f5',
                    },
                  }}
                ></CustomTableCell>
              );
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      }

      if (key == 'notice') {
        is_top = 'true';
      }

      return (
        <CustomTableCell
          is_top={is_top}
          key={`body_row_${parent_idx}_cell_${idx}`}
          component='td'
          align={center ? 'center' : 'left'}
          colSpan={col_span}
          sx={{
            whiteSpace: 'pre-wrap',
            borderBottom:
              idx > 1
                ? '1px solid var(--gray-3)'
                : userStateData.type >= 20 && data_key == 'schedule_book' && idx == 1
                ? '1px solid var(--gray-3)'
                : 'none',
            '@media(max-width: 1023px)': {
              borderColor: '#f5f5f5',
            },
          }}
        >
          {content}
        </CustomTableCell>
      );
    }
    return null;
  };

  const setSort = (field: TableFieldsType) => {
    if (!field.sort) return;
    const target = field.key == 'study_status' ? 'done_count' : field.key;
    let sort = '';

    if (table_data[data_key]) {
      clearTargetTableData(data_key);
    }
    if (tableQuery && onChangeTable) {
      if (tableQuery.target == target) {
        if (tableQuery.sort == 'asc') {
          sort = 'desc';
        } else {
          sort = 'asc';
        }
      } else {
        sort = 'asc';
      }

      onChangeTable({ ...tableQuery, target, sort });
    }
  };

  const setSortContents = (field: TableFieldsType) => {
    const sort = field.sort;
    const target = field.key;

    if (sort && tableQuery) {
      return (
        <Box className='sort-box'>
          <GoTriangleUp
            style={{
              color: `${tableQuery.target == target && tableQuery.sort == 'asc' ? 'var(--black)' : '#d8d8d8'}`,
            }}
          />
          <GoTriangleDown
            style={{
              color: `${tableQuery.target == target && tableQuery.sort == 'desc' ? 'var(--black)' : '#d8d8d8'}`,
            }}
          />
        </Box>
      );
    }
    return null;
  };

  const setPage = (type: string) => {
    if (tableQuery && onChangeTable) {
      let page = tableQuery.page;
      if (type == 'first') {
        page = 1;
      } else if (type == 'prev') {
        page--;
      } else if (type == 'next') {
        page++;
      } else if (type == 'last') {
        page = Math.ceil(count / tableQuery.max);
      }

      clearTargetTableData(data_key);
      onChangeTable({ ...tableQuery, page });
    }
  };

  const setRowsMax = (max: number) => {
    const local = window.localStorage;
    const list_num = local.getItem('list_num');
    if (list_num) {
      const list_num_json = JSON.parse(list_num);
      list_num_json[location.pathname] = max;
      if (location.pathname == `${BASE_URL}/student`) {
        list_num_json[`${BASE_URL}/student/teacher`] = max;
      }
      local.setItem('list_num', JSON.stringify(list_num_json));
      setUserStateData(prevState => ({ ...prevState, current_list_num: max }));
    }
    closeDropdown();
    if (tableQuery && onChangeTable) {
      if (max == tableQuery.max) return;
      onChangeTable({ ...tableQuery, page: 1, max });
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeDropdown = () => {
    setAnchorEl(null);
  };

  const RecordType = ({ mode }: { mode: string }) => {
    switch (mode) {
      case 'basic':
      case 'grammar_b':
        return (
          <CustomTableTdBox>
            <StyledLabelButton disabled sx={{ color: '#fff', backgroundColor: '#1bd05b' }}>
              기본학습
            </StyledLabelButton>
          </CustomTableTdBox>
        );
      case 'advanced':
      case 'grammar_d':
        return (
          <CustomTableTdBox>
            <StyledLabelButton disabled sx={{ color: '#fff', backgroundColor: '#5bc7ff' }}>
              심화학습
            </StyledLabelButton>
          </CustomTableTdBox>
        );
      case 'wrong':
        return (
          <CustomTableTdBox>
            <StyledLabelButton disabled sx={{ color: '#fff', backgroundColor: '#ffd611' }}>
              오답학습
            </StyledLabelButton>
          </CustomTableTdBox>
        );
      case 'speak':
        return (
          <CustomTableTdBox>
            <StyledLabelButton disabled sx={{ color: '#fff', backgroundColor: '#4620e9' }}>
              회화학습
            </StyledLabelButton>
          </CustomTableTdBox>
        );
      case 'grammar_t':
        return (
          <CustomTableTdBox>
            <StyledLabelButton disabled sx={{ color: '#fff', backgroundColor: '#ffa139' }}>
              문법학습
            </StyledLabelButton>
          </CustomTableTdBox>
        );
      default:
        return null;
    }
  };

  const setRowsCell = (field: TableFieldsType, cur_item: TableItemsType, idx: number) => {
    let contents: React.ReactNode = <></>;
    let color = '';
    let weight = '';

    const item = { ...cur_item };
    if (
      item[field.key] &&
      item[field.key].type &&
      !item[field.key].$$typeof &&
      (item[field.key].type as string).includes('color')
    ) {
      const cur_color = (item[field.key].type as string).substring(6);
      if (cur_color.includes('#')) {
        color = cur_color;
      } else {
        color = `${cur_color}.main`;
      }
      if (item[field.key].weight) {
        weight = item[field.key].weight;
      }
    } else {
      color = field.text_color ? field.text_color : 'rgba(0, 0, 0, 0.87)';
    }
    const is_hash = color.includes('#') ? true : false;
    color = !is_hash ? color : `${color} !important`;

    if (searchbarStateData.value && tableItems.length == 1) {
      if (`${item[field.key]}`.includes(searchbarStateData.value)) {
        const cur_html = `${item[field.key]}`.replace(
          searchbarStateData.value,
          `<span style='border-color: #ffff0f !important; background-color: #ffff0f !important'>${searchbarStateData.value}</span>`,
        );
        item[field.key] = {
          type: 'element',
          element: <div dangerouslySetInnerHTML={{ __html: cur_html }} />,
        };
      }
    }

    const td_key = `row_${idx}`;
    if (data_key == 'report_month_detail') {
      let value = '';

      if (field.key == 'category') {
        const currentDate = dayjs();
        const itemDate = dayjs(`${item['year']}-${item['month']}`);
        value = `${dayjs(itemDate).format('YYYY년 M월')}`;
        contents = (
          <CustomTableCell
            component='td'
            active_color={field.active_color}
            width={field.width}
            show_details={`${item.show_details}`}
            weight={`${(item[field.key] && item[field.key].weight) ?? field.weight}`}
            sx={{
              justifyContent: field.center ? 'center' : 'left',
              textAlign: field.center ? 'center' : 'left',
              paddingLeft: `${field.is_padding} !important`,
              color,
            }}
            onClick={onClickContent ? () => onClickContent(field, item, idx, field.key) : undefined}
          >
            <CustomTableTdBox
              sx={{ justifyContent: field.center ? 'center' : 'left', textAlign: field.center ? 'center' : 'left' }}
            >
              {value ? value : '-'}
              {currentDate.isSame(itemDate, 'month') && currentDate.isSame(itemDate, 'year') ? (
                <Box component={'span'} fontWeight={600} className='c_red'>
                  &nbsp;(진행중)
                </Box>
              ) : null}
            </CustomTableTdBox>
          </CustomTableCell>
        );
      } else {
        if (field.key == 'word_count') {
          value = `${item[field.key].toLocaleString()} 개`;
        } else if (field.key == 'wrong_word_count') {
          value = `${item[field.key].toLocaleString()} 개`;
        } else if (field.key == 'wrong_rate') {
          value = `${item[field.key]} %`;
        } else if (field.key == 'avg') {
          value = `${item[field.key]} 점`;
        } else if (field.key == 'studytime') {
          value = `${item[field.key]} 분`;
        }
        contents = (
          <CustomTableCell
            component='td'
            active_color={field.active_color}
            width={field.width}
            show_details={`${item.show_details}`}
            weight={`${(item[field.key] && item[field.key].weight) ?? field.weight}`}
            sx={{
              justifyContent: field.center ? 'center' : 'left',
              textAlign: field.center ? 'center' : 'left',
              paddingLeft: `${field.is_padding} !important`,
              color,
            }}
            onClick={onClickContent ? () => onClickContent(field, item, idx, field.key) : undefined}
          >
            <CustomTableTdBox
              sx={{ justifyContent: field.center ? 'center' : 'left', textAlign: field.center ? 'center' : 'left' }}
            >
              {value ? value : '-'}
            </CustomTableTdBox>
          </CustomTableCell>
        );
      }
    } else {
      if (field.key == 'check') {
        contents = (
          <CustomTableCell component='td' sx={{ textAlign: 'center', padding: '0 !important' }}>
            <CustomTableTdBox sx={{ justifyContent: 'center' }}>
              <TableCheckbox
                onChange={() => setChildrenCheckbox(idx)}
                checked={item.checked ? item.checked : false}
                sx={{
                  width: '100%',
                  '&.Mui-checked': { color: item.show_details ? '#2196f3 !important' : '#2196f3 !important' },
                  '&.MuiCheckbox-indeterminate': {
                    color: item.show_details ? '#2196f3 !important' : '#2196f3 !important',
                  },
                }}
              />
            </CustomTableTdBox>
          </CustomTableCell>
        );
      } else if (field.key == 'show') {
        contents = (
          <CustomTableCell
            component='td'
            active_color={field.active_color ? field.active_color : ''}
            show_details={`${item.show_details ? item.show_details : ''}`}
            sx={{ textAlign: 'center', paddingLeft: field.padding, color: 'rgba(0, 0, 0, 0.54)' }}
          >
            <CustomTableTdBox>
              <CellIconBox className='icon-box'>
                <GoTriangleDown />
              </CellIconBox>
            </CustomTableTdBox>
          </CustomTableCell>
        );
      } else if (field.key == 'readed') {
        contents = (
          <CustomTableCell component='td' sx={{ textAlign: 'right', padding: '0 !important' }}>
            <CustomTableTdBox
              sx={{
                color: '#f44336',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0.175rem 0.5rem',
                borderRadius: '7px',
                fontSize: '1rem',
                transform: 'scale(0.5)',
              }}
            >
              {item[field.key] ? '●' : null}
            </CustomTableTdBox>
          </CustomTableCell>
        );
      } else if (typeof item[field.key] === 'object' && field.key !== 'details' && item[field.key] !== null) {
        // type 검증 시 item[field.key]의 type이 object라면 type property 분류를 통해 button or input 생성
        if (item[field.key].type === 'button') {
          // button 생성
          // label 이용하고 makebuttonoptions 함수 props로 받아서 options 생성

          const button_options = make_button_options
            ? make_button_options(field, item, tableItems, idx)
            : {
                disabled: undefined,
                onClick: undefined,
                setDisabled: undefined,
                color: undefined,
                sx: undefined,
              };

          contents = (
            <CustomTableCell
              component='td'
              active_color={field.active_color ? field.active_color : ''}
              show_details={`${item.show_details ? item.show_details : ''}`}
            >
              <CustomTableTdBox
                sx={{
                  justifyContent: 'center',
                }}
              >
                <Button
                  id={`${data_key}_button_${field.key}_${idx}`}
                  variant='contained'
                  onClick={button_options.onClick}
                  color={button_options.color || field.button_color}
                  disabled={button_options.setDisabled ? button_options.setDisabled() : button_options.disabled}
                  sx={button_options.sx}
                >
                  {item[field.key].label}
                </Button>
              </CustomTableTdBox>
            </CustomTableCell>
          );
        } else if (item[field.key].type === 'element') {
          const color = `${item[field.key].type}`.substring(6);
          contents = (
            <CustomTableCell
              component='td'
              active_color={item[field.key].active_color}
              width={field.width}
              show_details={`${item.show_details}`}
              weight={`${(item[field.key] && item[field.key].weight) ?? field.weight}`}
              sx={{
                justifyContent: field.center ? 'center' : 'left',
                color: color,
              }}
            >
              <CustomTableTdBox
                sx={{ justifyContent: field.center ? 'center' : 'left', textAlign: field.center ? 'center' : 'left' }}
              >
                {item[field.key].element}
              </CustomTableTdBox>
            </CustomTableCell>
          );
        } else if (item[field.key].type === 'box') {
          const bg_color = item[field.key].bgColor as string;
          const font_color = item[field.key].fontColor as string;
          contents = (
            <CustomTableCell component='td' sx={{ display: 'flex' }}>
              <CustomTableTdBox
                sx={{
                  backgroundColor: bg_color,
                  color: font_color,
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '1px',
                  padding: '0.175rem 0.875rem',
                  borderRadius: '7px',
                  fontSize: '0.95rem',
                }}
              >
                {item[field.key].label}
              </CustomTableTdBox>
            </CustomTableCell>
          );
        } else if (typeof item[field.key].type == 'string' && `${item[field.key].type}`.includes('color')) {
          contents = (
            <CustomTableCell
              component='td'
              active_color={item[field.key].active_color}
              width={field.width}
              show_details={`${item.show_details}`}
              weight={`${(item[field.key] && item[field.key].weight) ?? field.weight}`}
              color={color}
              sx={{
                justifyContent: field.center ? 'center' : 'left',
                textAlign: field.center ? 'center' : 'left',
                color: color,
              }}
            >
              <CustomTableTdBox
                sx={{ justifyContent: field.center ? 'center' : 'left', textAlign: field.center ? 'center' : 'left' }}
              >
                {item[field.key].label}
              </CustomTableTdBox>
            </CustomTableCell>
          );
        } else if (`${item[field.key].type}` === 'content') {
          contents = (
            <CustomTableCell
              component='td'
              color={color}
              active_color={item[field.key].active_color}
              width={field.width}
              show_details={`${item.show_details}`}
              weight={`${(item[field.key] && item[field.key].weight) ?? field.weight}`}
              sx={{
                justifyContent: field.center ? 'center' : 'left',
                textAlign: field.center ? 'center' : 'left',
                color,
              }}
            >
              <CustomTableTdBox
                sx={{ justifyContent: field.center ? 'center' : 'left', textAlign: field.center ? 'center' : 'left' }}
              >
                <TdHover component={'span'} $hover={field.hover}>
                  <Box
                    component={'span'}
                    onClick={onClickContent ? () => onClickContent(field, item, idx, field.key) : undefined}
                  >
                    {item[field.key].label}
                  </Box>
                </TdHover>
              </CustomTableTdBox>
            </CustomTableCell>
          );
        } else {
          contents = (
            <CustomTableCell
              component='td'
              active_color={field.active_color}
              width={field.width}
              show_details={`${item.show_details}`}
              weight={`${(item[field.key] && item[field.key].weight) ?? field.weight}`}
              color={color}
              sx={{
                justifyContent: field.center ? 'center' : 'left',
                textAlign: field.center ? 'center' : 'left',
                color,
                paddingLeft: `${field.is_padding} !important`,
              }}
              onClick={onClickContent ? () => onClickContent(field, item, idx, field.key) : undefined}
            >
              <CustomTableTdBox
                sx={{ justifyContent: field.center ? 'center' : 'left', textAlign: field.center ? 'center' : 'left' }}
              >
                {item[field.key].label}
              </CustomTableTdBox>
            </CustomTableCell>
          );
        }
        // 학습 일지에서 사용하는 테이블 커스텀
      } else if (field.key === 'no' && data_key === 'studyJournal') {
        contents = (
          <TableCell
            component='td'
            width={field.width}
            sx={{
              borderBottom: 'none',
              paddingLeft: '0 !important',
            }}
          >
            <div style={{ textAlign: 'center' }}>{idx + 1}</div>
          </TableCell>
        );
      } else if (field.key == 'studytime' || (field.key == 'study_status' && data_key === 'studyJournal')) {
        contents = (
          <TableCell
            component='td'
            sx={{
              borderBottom: 'none',
              width: '4.5% !important',
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                padding: '1rem 0rem 1rem 0.4rem',
              }}
            >
              {' '}
              {item[field.key]}
            </div>
          </TableCell>
        );
      } else if (field.key !== 'report_button' && data_key === 'studyJournal') {
        contents = (
          <TableCell
            component='td'
            sx={{
              borderBottom: 'none',
              width: '4.5% !important',
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                padding: '1rem 0',
              }}
            >
              {' '}
              {item[field.key]}
            </div>
          </TableCell>
        );
      } else if (field.key == 'type' && data_key !== 'studyJournal') {
        /* else if (field.key == 'mode') {
        const convert_report_label = (type: string) => {
          switch (type) {
            case 'basic':
              return '기본학습';
            case 'advanced':
              return '심화학습';
            case 'wrong':
              return '오답학습';
            default:
              return '';
          }
        };
        const convert_report_label_color = (type: string) => {
          switch (type) {
            case 'basic':
              return '#1bd05b';
            case 'advanced':
              return '#5bc7ff';
            case 'wrong':
              return '#ffcc00';
            default:
              return '#ff6c1c';
          }
        };
        const label = convert_report_label(item[field.key]);
        const label_color = convert_report_label_color(item[field.key]);
        contents = (
          <CustomTableCell
            component='td'
            active_color={field.active_color}
            width={field.width}
            show_details={`${item.show_details}`}
            weight={`${(item[field.key] && item[field.key].weight) ?? field.weight}`}
            sx={{
              justifyContent: field.center ? 'center' : 'left',
              textAlign: field.center ? 'center' : 'left',
              paddingLeft: `${field.is_padding} !important`,
              color,
              '@media (max-width: 800px)': {
                width: '20% !important',
              },
            }}
            onClick={onClickContent ? () => onClickContent(field, item, idx, field.key) : undefined}
          >
            <CustomTableTdBox
              sx={{ justifyContent: field.center ? 'center' : 'left', textAlign: field.center ? 'center' : 'left' }}
            >
              <StyledReportLabelButton sx={{ backgroundColor: label_color }} disabled>
                {label}
              </StyledReportLabelButton>
            </CustomTableTdBox>
          </CustomTableCell>
        );
      } */
        const convert_report_label_color = (type: string) => {
          switch (type) {
            case '전체공지':
              return '#1bd05b';
            case '수업공지':
              return '#5bc7ff';
            case '반별공지':
              return '#ffcc00';
            default:
              return '#ff6c1c';
          }
        };
        const label_color = convert_report_label_color(item[field.key]);
        contents = (
          <CustomTableCell
            component='td'
            active_color={field.active_color}
            width={field.width}
            show_details={`${item.show_details}`}
            weight={`${(item[field.key] && item[field.key].weight) ?? field.weight}`}
            sx={{
              justifyContent: field.center ? 'center' : 'left',
              textAlign: field.center ? 'center' : 'left',
              paddingLeft: `${field.is_padding} !important`,
              color,
            }}
            onClick={onClickContent ? () => onClickContent(field, item, idx, field.key) : undefined}
          >
            <CustomTableTdBox
              sx={{ justifyContent: field.center ? 'center' : 'left', textAlign: field.center ? 'center' : 'left' }}
            >
              <StyledReportLabelButton sx={{ backgroundColor: label_color }} disabled>
                {item[field.key]}
              </StyledReportLabelButton>
            </CustomTableTdBox>
          </CustomTableCell>
        );
      } else if (field.key == 'lastdate') {
        contents = (
          <CustomTableCell
            component='td'
            active_color={field.active_color}
            width={field.width}
            show_details={`${item.show_details}`}
            weight={`${(item[field.key] && item[field.key].weight) ?? field.weight}`}
            sx={{
              justifyContent: field.center ? 'center' : 'left',
              textAlign: field.center ? 'center' : 'left',
              paddingLeft: `${field.is_padding} !important`,
              color: data_key == 'report_lookup' ? '#d9d9d9' : color,
            }}
            onClick={
              onClickContent && data_key != 'report_lookup'
                ? () => onClickContent(field, item, idx, field.key)
                : undefined
            }
          >
            <CustomTableTdBox
              sx={{ justifyContent: field.center ? 'center' : 'left', textAlign: field.center ? 'center' : 'left' }}
            >
              {data_key == 'report_lookup' ? '내역 조회' : dayjs.unix(item[field.key]).format('YYYY-MM-DD HH:mm')}
            </CustomTableTdBox>
          </CustomTableCell>
        );
      } else if (field.key == 'ranking') {
        if ([1, 2, 3].includes(item.ranking)) {
          if (item.ranking == 1) {
            item[field.key] = <img src={first} style={{ width: '5vh', maxHeight: '40px', verticalAlign: 'middle' }} />;
          } else if (item.ranking == 2) {
            item[field.key] = <img src={second} style={{ width: '5vh', maxHeight: '40px', verticalAlign: 'middle' }} />;
          } else if (item.ranking == 3) {
            item[field.key] = <img src={third} style={{ width: '5vh', maxHeight: '40px', verticalAlign: 'middle' }} />;
          }
        }
        contents = (
          <CustomTableCell
            component='td'
            active_color={field.active_color}
            width={field.width}
            show_details={`${item.show_details}`}
            weight={`${(item[field.key] && item[field.key].weight) ?? field.weight}`}
            sx={{
              justifyContent: field.center ? 'center' : 'left',
              textAlign: field.center ? 'center' : 'left',
              paddingLeft: `${field.is_padding} !important`,
              color,
            }}
            onClick={onClickContent ? () => onClickContent(field, item, idx, field.key) : undefined}
          >
            <CustomTableTdBox
              sx={{ justifyContent: field.center ? 'center' : 'left', textAlign: field.center ? 'center' : 'left' }}
            >
              {item[field.key] ? item[field.key] : '-'}
            </CustomTableTdBox>
          </CustomTableCell>
        );
      } else if ((data_key == 'schedule_book' && field.key == 'name') || field.key == 'schedule_name') {
        contents = (
          <CustomTableCell
            component='td'
            active_color={field.active_color}
            width={field.width}
            show_details={`${item.show_details}`}
            weight={`${(item[field.key] && item[field.key].weight) ?? field.weight}`}
            sx={{
              justifyContent: field.center ? 'center' : 'left',
              textAlign: field.center ? 'center' : 'left',
              paddingLeft: `${field.is_padding} !important`,
              color,
            }}
            onClick={
              onClickContent && data_key != 'report_lookup'
                ? () => onClickContent(field, item, idx, field.key)
                : undefined
            }
          >
            <CustomTableTdBox
              sx={{ justifyContent: field.center ? 'center' : 'left', textAlign: field.center ? 'center' : 'left' }}
            >
              {item[field.key] ? item[field.key] : '-'}
              {item.schedule_words_count ? ` - ${item.schedule_words_count}개 분할` : null}
            </CustomTableTdBox>
          </CustomTableCell>
        );
      } else if (field.key === 'report_button' && data_key == 'studyJournal') {
        contents = (
          <TableCell
            component='td'
            width={field.width}
            sx={{
              textAlign: field.center ? 'center' : 'left',
              color: color,
              borderBottom: 'none',
            }}
            data-label={item[field.key].label ? item[field.key].label : ''}
          >
            {item.study_status !== '완료' ? (
              '-'
            ) : (
              <StyledReportLabelButton
                isStudyJournal={true}
                onClick={() => {
                  getStudyJournalButtonClick && getStudyJournalButtonClick(item);
                }}
                className='report-button'
                variant='contained'
                size='small'
              >
                조회
              </StyledReportLabelButton>
            )}
          </TableCell>
        );
      } else {
        if (data_key == 'modal_leaderboard' && item['student_id'] < 6) {
          color = '#c1c1c1';
        }

        if (data_key == 'leaderboard_board' && item['student_id'] < 6) {
          switch (field.key) {
            case 'name':
            case 'score':
              color = '#80b9e3';
              break;
            case 'class_name':
            case 'study_count':
            case 'studytime':
            case 'words_count':
              color = '#c1c1c1';
              break;
          }
        }
        contents = (
          <CustomTableCell
            component='td'
            active_color={field.active_color}
            width={field.width}
            show_details={`${item.show_details}`}
            weight={`${(item[field.key] && item[field.key].weight) ?? field.weight}`}
            sx={{
              justifyContent: field.center ? 'center' : 'left',
              textAlign: field.center ? 'center' : 'left',
              paddingLeft: `${field.is_padding} !important`,
              color,
            }}
            onClick={
              onClickContent && data_key != 'report_lookup'
                ? () => onClickContent(field, item, idx, field.key)
                : undefined
            }
          >
            <CustomTableTdBox
              sx={{ justifyContent: field.center ? 'center' : 'left', textAlign: field.center ? 'center' : 'left' }}
            >
              {item[field.key] ? item[field.key] : '-'}
            </CustomTableTdBox>
          </CustomTableCell>
        );
      }
    }

    return contents;
  };

  const moveScheduleBook = (item: TableItemsType) => {
    let path = ['longvoca'].includes(item.tab_name) ? `${BASE_URL}/book/study` : `${BASE_URL}/book/school`;

    if (center_type == 'C') {
      if (item.tab_name == 'grammar') {
        path = `${BASE_URL}/book/grammar`;
      } else if (item.tab_name == 'speak') {
        path = `${BASE_URL}/book/speak`;
      }
    }

    let tab = -1;
    if (item.tab_name != 'longvoca') {
      if (item.tab_name == 'textbook') tab = 0;
      else if (item.tab_name == 'exam') tab = 1;
      else if (item.tab_name == 'customer') tab = 2;
    }

    setBookStateData(prevState => ({
      ...prevState,
      booklist_set: {
        tab: tab > -1 ? tab : undefined,
        grade: null,
        sort: null,
        category_id: item.category_id ? item.category_id : undefined,
        folder_id: item.folder_id ? item.folder_id : null,
        schedule_id: item.schedule_id,
        tab_name: item.tab_name,
        unit_id: null,
        startdate: null,
        enddate: null,
        ready: false,
      },
    }));
    navigate(path);
  };

  return (
    <Fragment>
      <CustomTable id='data_table'>
        <CustomTableHead>
          <CustomTableRow>
            {tableFields.map((field, idx) => {
              return (
                <CustomTableCell
                  component='th'
                  width={field.width}
                  sort={`${field.sort}`}
                  active_color={field.active_color}
                  color={field.color}
                  show_details={field.show_details}
                  key={`header_${idx}`}
                  align={field.center ? 'center' : 'left'}
                  onClick={() => setSort(field)}
                  sx={{
                    textAlign: field.center ? 'center' : 'left',
                    paddingLeft:
                      data_key === 'studyJournal' && field.key === 'no'
                        ? '0 !important'
                        : `${field.is_padding} !important`,
                    '@media (max-width: 800px)': {
                      width:
                        (field.key == 'mode' || field.key == 'type') && data_key !== 'studyJournal'
                          ? '15% !important'
                          : field.width,
                    },
                  }}
                  className={field.key == 'check' ? 'check' : 'check'}
                >
                  {field.key == 'check' ? (
                    <TableCheckbox
                      indeterminate={checkedCount > 0 && !allChecked}
                      onChange={setHeaderCheckbox}
                      checked={field.checked ? field.checked : false}
                      sx={{
                        '&.Mui-checked': { color: '#2196f3' },
                        '&.MuiCheckbox-indeterminate': { color: '#2196f3' },
                      }}
                    />
                  ) : (
                    <CustomTableThBox component='span' sx={{ justifyContent: field.center ? 'center' : 'flex-start' }}>
                      {field.label}
                      {setSortContents(field)}
                    </CustomTableThBox>
                  )}
                </CustomTableCell>
              );
            })}
          </CustomTableRow>
        </CustomTableHead>
        <CustomTableBody>
          {tableItems.length > 0 ? (
            tableItems.map((item, idx1) => {
              return (
                <Fragment key={`body_row_wrap_${idx1}`}>
                  <CustomTableRow
                    detail={`${detail}`}
                    key={`body_row_${idx1}`}
                    onClick={(e: React.MouseEvent<HTMLTableRowElement>) => {
                      onRowClicked(idx1, e);
                    }}
                    className={item.show_details ? 'show_details' : ''}
                  >
                    {tableFields.map((field, idx2) => {
                      return (
                        <Fragment key={`body_row_${idx1}_cell_${idx2}`}>{setRowsCell(field, item, idx1)}</Fragment>
                      );
                    })}
                  </CustomTableRow>
                  {item.show_details && item.details && detail ? (
                    data_key === 'report_lookup' ? (
                      (item.details as unknown as any).map((detail: any) => {
                        return (
                          <CustomTableRow
                            sx={{ cursor: 'pointer' }}
                            key={detail.module_record_id}
                            onClick={
                              onClickContent ? () => onClickContent(item as any, item, 0, '', detail) : undefined
                            }
                          >
                            <CustomTableCell></CustomTableCell>
                            <CustomTableCell>
                              <DetailDeco
                                sx={{
                                  width: '0.875rem',
                                  height: '0.875rem',
                                  top: '40%',
                                  left: '1.65rem',
                                  right: '0',
                                  transform: 'translate(-50%, -50%)',
                                }}
                              />
                            </CustomTableCell>
                            <CustomTableCell>{RecordType(detail)}</CustomTableCell>
                            <CustomTableCell>
                              <CustomTableTdBox>
                                {item.schedule_name}
                                {item.schedule_words_count ? (
                                  <span style={{ color: '#aaa' }}>
                                    &nbsp;-&nbsp;{`${item.schedule_words_count}개 분할`}
                                  </span>
                                ) : null}
                              </CustomTableTdBox>
                            </CustomTableCell>
                            <CustomTableCell>
                              <CustomTableTdBox>{item.unit_name}</CustomTableTdBox>
                            </CustomTableCell>
                            <CustomTableCell>
                              <CustomTableTdBox sx={{ color: '#4620e9' }}>{detail.score}점</CustomTableTdBox>
                            </CustomTableCell>
                            <CustomTableCell>
                              <CustomTableTdBox>
                                {dayjs.unix(detail.lastdate).format('YYYY-MM-DD HH:mm')}
                              </CustomTableTdBox>
                            </CustomTableCell>
                          </CustomTableRow>
                        );
                      })
                    ) : (
                      <>
                        {Object.keys(item.details).map((key, idx2) => (
                          <CustomTableRow
                            className={
                              item.details && idx2 != Number(Object.keys(item.details).length) - 1 ? 'detail-row' : ''
                            }
                            key={`body_row_${idx1}_detail_${idx2}`}
                          >
                            {tableFields.map((field, idx3) => {
                              return setDetailContents(item, key, idx3, idx1, field);
                            })}
                          </CustomTableRow>
                        ))}
                      </>
                    )
                  ) : null}
                </Fragment>
              );
            })
          ) : (
            <CustomTableRow>
              <CustomTableCell
                width={'100%'}
                sx={{ textAlign: 'center', color: '#c0c0c0', fontWeight: '400' }}
                colSpan={tableFields.length}
              >
                <CustomTableTdBox sx={{ justifyContent: 'center' }}>
                  {empty_text ? empty_text : '등록된 데이터가 없습니다.'}
                </CustomTableTdBox>
              </CustomTableCell>
            </CustomTableRow>
          )}
        </CustomTableBody>
      </CustomTable>
      {pagination && tableQuery ? (
        <Pagination>
          <IconButton onClick={() => setPage('first')} disabled={tableQuery.page == 1}>
            <HiOutlineChevronDoubleLeft />
          </IconButton>
          <IconButton onClick={() => setPage('prev')} disabled={tableQuery.page == 1}>
            <HiOutlineChevronLeft />
          </IconButton>
          <Box className='page'>{`${curMinCount}-${
            tableQuery.max * tableQuery.page > count ? curMaxCount : totalCount
          } of ${curMaxCount}`}</Box>
          <IconButton
            onClick={() => setPage('next')}
            disabled={count == 0 || Math.ceil(count / tableQuery.max) == tableQuery.page}
          >
            <HiOutlineChevronRight />
          </IconButton>
          <IconButton
            onClick={() => setPage('last')}
            disabled={count == 0 || Math.ceil(count / tableQuery.max) == tableQuery.page}
          >
            <HiOutlineChevronDoubleRight />
          </IconButton>

          <Box>
            <Box className='count' component={'span'}>
              표시 개수
            </Box>
            <Button id='basic-button' onClick={handleClick} endIcon={<GoTriangleDown />} color='gray_1'>
              {tableQuery.max}
            </Button>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={closeDropdown}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => setRowsMax(10)}>10</MenuItem>
              <MenuItem onClick={() => setRowsMax(30)}>30</MenuItem>
              <MenuItem onClick={() => setRowsMax(50)}>50</MenuItem>
              <MenuItem onClick={() => setRowsMax(100)}>100</MenuItem>
            </Menu>
          </Box>
        </Pagination>
      ) : null}
    </Fragment>
  );
}

export default Table;
