import { AccountInfoType } from 'recoil/model/accountInfo';
import {
  Modal,
  ModalBox,
  ModalContentWrapper,
  ModalHeader,
  ModalHeaderIconWrap,
  Box,
  SelectedFinalInfo,
  SelectedInfo,
  SelectedTitle,
  Title,
  FinalPaymentButton,
} from './ModalBill.Styles';
import { IoCloseSharp } from 'react-icons/io5';
import { convertLabelToPaymentKind, convertProductName } from 'utils/tools';

interface PaymentInfoType {
  label: string;
  value: string | number;
}

interface ModalBillVAProps {
  visible: boolean;
  onClose: () => void;
  title: string;
  accountInfo: AccountInfoType;
  hostName: string;
  customerId: number;
  completedPaymentInfo: PaymentInfoType[];
}

export function ModalBillUI({
  visible,
  onClose,
  accountInfo,
  completedPaymentInfo,
  customerId,
  hostName,
  title,
}: ModalBillVAProps) {
  if (accountInfo)
    return (
      <Modal open={visible}>
        <ModalBox>
          <ModalHeader>
            <Box
              sx={{ fontFamily: 'Apple SD Gothic Neo', fontWeight: '300', fontSize: '1.2rem' }}
              component={'span'}
              dangerouslySetInnerHTML={{ __html: title as string }}
            ></Box>
            <ModalHeaderIconWrap onClick={onClose}>
              <IoCloseSharp />
            </ModalHeaderIconWrap>
          </ModalHeader>
          <ModalContentWrapper>
            <Title>
              {accountInfo.paymentKind === 'CD'
                ? `카드 결제 페이지를 통해 결제해주세요!`
                : `발급된 ${convertLabelToPaymentKind(accountInfo.paymentKind)}에 입금해주세요!`}
              <br />곧 정식 가입이 완료됩니다.
            </Title>
            <SelectedTitle>선택하신 상품</SelectedTitle>
            <SelectedInfo>
              <span>
                {accountInfo.product_name.split('명 ')[1] === undefined
                  ? accountInfo.product_name
                  : convertProductName(accountInfo.product_name.split('명 ')[1])}
              </span>
              &nbsp;
              <span style={{ color: '#a4a5a4', fontWeight: '500' }}>
                {`(${accountInfo.productStartDate} - ${accountInfo.productEndDate})`}
              </span>
            </SelectedInfo>
            <SelectedTitle>결제하실 금액</SelectedTitle>
            <SelectedFinalInfo length={completedPaymentInfo.length}>
              <div className='payment-info-section-1'>
                {completedPaymentInfo?.map((item: PaymentInfoType, index: number) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: '5rem',
                      width: '100%',
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        color: item.label.includes('총 혜택 금액')
                          ? 'black'
                          : item.label.includes('할인 적용')
                          ? 'blue'
                          : item.label.includes('얼리버드 할인') ||
                            item.label.includes('패키지 할인') ||
                            item.label.includes('추천코드 할인 금액')
                          ? '#fc7303'
                          : item.label.includes('월 혜택 금액')
                          ? '#4620e9'
                          : '',
                      }}
                    >
                      {item.label}
                    </div>
                    <div
                      style={{
                        color: item.label.includes('총 혜택 금액')
                          ? 'black'
                          : item.label.includes('할인 적용')
                          ? 'blue'
                          : item.label.includes('얼리버드 할인') ||
                            item.label.includes('패키지 할인') ||
                            item.label.includes('추천코드 할인 금액')
                          ? '#fc7303'
                          : item.label.includes('월 혜택 금액')
                          ? '#4620e9'
                          : '',
                      }}
                    >
                      {item.label.includes('할인 적용') ? `- ${item.value}` : item.value}
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ border: '1px solid rgb(248 248 248)', height: '10rem' }} />
              {accountInfo.paymentKind === 'CD' ? (
                <div className='payment-info-section-2'>
                  <div className='row-wrapper'>
                    <span>카드 결제</span>
                    <a
                      href={`https://${hostName}/cardpay?id=${customerId}`}
                      target='_blank'
                      rel='noreferrer'
                      style={{ textDecoration: 'underline', color: 'blue' }}
                    >
                      카드 결제 페이지
                    </a>
                  </div>
                </div>
              ) : (
                <div className='payment-info-section-2'>
                  <div className='row-wrapper'>
                    <span>계좌번호</span>
                    <span>{`${accountInfo.bankname} ${accountInfo.account}`}</span>
                  </div>
                  <div className='row-wrapper'>
                    <span>예금주명</span>
                    <span>{accountInfo.accountnm}</span>
                  </div>
                  <div className='row-wrapper'>
                    <span>결제금액</span>
                    <span style={{ color: 'red' }}>{`${accountInfo.cost}원`}</span>
                  </div>
                </div>
              )}
            </SelectedFinalInfo>
            {accountInfo.paymentKind !== 'CD' && (
              <FinalPaymentButton disabled variant='contained'>
                입금 후 10분 이내로 가입 승인됩니다.
              </FinalPaymentButton>
            )}
          </ModalContentWrapper>
        </ModalBox>
      </Modal>
    );

  return null;
}
