import { Box, Checkbox } from '@mui/material';
import React, { Fragment } from 'react';
import {
  ContentWrap,
  ContentsBox,
  Footer,
  Header,
  SwitchBox,
  WordBox,
  WordDeleteButton,
  Wrapper,
  Line,
} from './PrintWordlist.Styles';
import { SwitchContainer } from 'components/common/Switch/Switch.Container';

export interface PrintWordlistVAProps {
  wordCount: number;
  checkCount: number;
  printWordList: TestPrintWordType[];
  printSetting: PrintSettingType;
  onClickCheckbox: (e: React.MouseEvent<HTMLDivElement>, active: boolean, idx: number) => void;
  onClickWordDelete: () => void;
}

export const PrintWordlistUI = React.memo(function PrintWordlistUI({
  wordCount,
  checkCount,
  printWordList,
  printSetting,
  onClickCheckbox,
  onClickWordDelete,
}: PrintWordlistVAProps) {
  return (
    <Wrapper>
      <Header>
        <Box>
          <Box />
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box component={'span'}>
              단어 목록&nbsp;
              <Box component={'b'} style={{ color: '#f44336' }}>
                {wordCount}
              </Box>
            </Box>
          </Box>
          <SwitchBox>
            <SwitchContainer
              checked={true}
              onChange={() => {
                return;
              }}
              small={true}
            />
            <Box
              component={'span'}
              sx={{
                cursor: 'pointer',
                marginRight: '0.25rem',
              }}
            >
              &nbsp;&nbsp;중복&nbsp;&nbsp;
              <Box component={'b'}></Box>
            </Box>
          </SwitchBox>
        </Box>
        <Line />
      </Header>
      <ContentWrap
        sx={{
          height: printSetting.type == 0 ? 'calc(100% - 3rem)' : 'calc(100% - 6.75rem)',
          '& > div:first-of-type': {
            height: printSetting.type == 0 ? 'calc(100% - 3rem)' : 'calc(100% - 6.75rem)',
          },
        }}
      >
        <Box>
          {printWordList.map((word: TestPrintWordType, idx: number) => (
            <ContentsBox
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                onClickCheckbox(e, word.active ? true : false, word.idx ?? 0);
              }}
              active={`${word.active}`}
              key={`words_${word.id}_${idx}`}
            >
              <Checkbox
                disableRipple={printSetting.type == 0}
                disabled={word.disabled ? true : false}
                sx={{ position: 'absolute' }}
                checked={word.active ? true : false}
                color='primary'
              />
              <WordBox sx={{ color: word.disabled ? '#DBDBDB' : '#000' }}>{word.spell}</WordBox>
            </ContentsBox>
          ))}
        </Box>
      </ContentWrap>
      {printSetting.type > 0 ? (
        <Footer>
          <WordDeleteButton onClick={onClickWordDelete} disabled={checkCount > 0 ? false : true}>
            목록 삭제
          </WordDeleteButton>
        </Footer>
      ) : null}
    </Wrapper>
  );
});
