import { atom } from 'recoil';

export interface BlockerDataType {
  when: boolean;
  title: string;
  target?: string;
  cancelCallback?: () => void;
}

export const blockerData = atom<BlockerDataType>({
  key: 'blocker',
  default: {
    when: false,
    title: '',
    target: '',
    cancelCallback: () => {
      return;
    },
  },
});
