import { Link, useNavigate, useRoutes } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userState } from '../../recoil/model/user';
import { routesState } from '../../recoil/common/routes';
import { useEffect, useState, useContext } from 'react';
import { ModalContext } from '../../provider/ModalProvider';

import { BiEdit } from 'react-icons/bi';
import { TbFileSearch } from 'react-icons/tb';
import { FaChartPie, FaTrophy } from 'react-icons/fa';
import { IoExitOutline, IoStatsChartSharp, IoRibbonSharp, IoSettingsSharp } from 'react-icons/io5';
import { GoCalendar } from 'react-icons/go';
import { GiSergeant } from 'react-icons/gi';
import { HiChartBar, HiSpeakerphone } from 'react-icons/hi';
import { BsBellFill } from 'react-icons/bs';
import { MdMenuBook } from 'react-icons/md';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import logo2 from '../../assets/images/longvoca_logo_white.png';
import exit_icon from '../../assets/images/exit_icon_2.png';

import { BASE_URL } from '../../utils/constants';

import { styled, Box } from '@mui/material';
import theme from '../../theme';
import { d_flex, d_flex_start, dir_column, d_flex_center } from '../../styles/common';

import Button from '../button/Button';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';
import { BsFileEarmarkTextFill } from 'react-icons/bs';
import { modalStudySettingData, ModalStudySettingDataType } from 'recoil/common/modalStudySetting';
import { BarChartIcon, DocumentIcon, ActiveDocumentIcon } from 'styles/customIcons';

interface ListMenuType {
  [key: string]: { path: string; label: string; root?: boolean; visible?: boolean; icon?: any }[];
}

const StyledSideMenuWrap = styled(Box)(props => ({
  padding: '1rem 0 1rem 1rem',
  width: '4.25rem',
  height: '100%',
  fontSize: '1.1rem',
  userSelect: 'none',
  float: 'left',
  display: 'flex',
  justifyContent: 'flex-start',
  alignContent: 'flex-start',
  flexWrap: 'wrap',
}));

interface StyledSideMenuItemProps {
  is_active?: string | boolean;
  idx?: string;
}

const style_side_menu = {
  width: '100%',
  height: '4.5rem',
  position: 'relative' as const,
  alignItems: 'center',
  ...d_flex,
  svg: {
    width: '2rem',
    height: '2rem',
    marginLeft: '0.75rem',
    zIndex: '1',
  },
};

const StyledIconBox = styled(Box)(props => ({
  width: '4.25rem',
  height: '4.5rem',
  paddingTop: '0.45rem',
  paddingLeft: '0.06rem',
  marginBottom: '2vw',
  ...d_flex_center,
  ...dir_column,
}));

const StyledIconButton = styled(Button)(props => ({
  display: 'block',
  width: '2.5rem',
  height: '2.5rem',
  backgroundImage: `url(${logo2})`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  transform: 'scale(0.8)',
}));

const LinkButton = styled(Button)<StyledSideMenuItemProps>(props => {
  const before = {
    content: '""',
    display: 'block',
    width: '22px',
    height: '22px',
    position: 'absolute',
    borderBottomRightRadius: '12px',
    zIndex: '1',
    top: '-10px',
    right: '-2px',
    boxShadow: '1px 11.4px 0 0 #fff',
    overflow: 'hidden',
    transform: 'translateY(-50%)',
  };

  const after = {
    content: '""',
    display: 'block',
    width: '22px',
    height: '22px',
    position: 'absolute',
    borderTopRightRadius: '12px',
    zIndex: '1',
    bottom: '-10px',
    right: '-2px',
    boxShadow: '6.4px 0 0 0 #fff',
    overflow: 'hidden',
    transform: 'translateY(50%)',
  };

  return {
    minWidth: 'unset',
    zIndex: '0',
    postion: 'relative',
    color: props.is_active == 'true' ? props.theme.palette.purple_3.main : props.theme.palette.white.main,
    letterSpacing: '-1px',
    justifyContent: 'flex-start',
    borderRadius: 'unset !important',
    borderTopRightRadius: '12px !important',
    borderBottomRightRadius: '12px !important',
    transition: 'unset',
    padding: 0,
    borderRight: `1px solid ${props.theme.palette.purple_3.main}`,
    '&:active': {
      boxShadow: 'none',
    },

    '&.active': {
      color: props.theme.palette.purple_3.main,
      backgroundColor: '#fff',
      borderRight: '1px solid #fff !important',
      borderLeft: `1px solid ${props.theme.palette.purple_3.main}`,
      borderTopLeftRadius: '12px !important',
      borderBottomLeftRadius: '12px !important',
      borderTopRightRadius: '0 !important',
      borderBottomRightRadius: '0 !important',
      boxShadow: '1px 0 0 0 #fff',

      '&:before': {
        ...before,
      },

      '&:after': {
        ...after,
      },

      svg: {
        color: props.theme.palette.purple_3.main,
      },
    },
    ...style_side_menu,
  };
});

const ExitLinkButton = styled(Button)<StyledSideMenuItemProps>(props => ({
  minWidth: 'unset',
  zIndex: '0',
  postion: 'relative',
  color: props.theme.palette.white.main,
  letterSpacing: '-1px',
  justifyContent: 'flex-start',
  borderRadius: 'unset !important',
  transition: 'unset',
  padding: 0,
  borderRight: `1px solid ${props.theme.palette.purple_3.main}`,
  width: '100%',
  height: '5rem',
  position: 'relative' as const,
  alignItems: 'center',
  ...d_flex,

  ' .exit-icon': {
    path: {
      strokeWidth: '2.3rem !important',
    },
  },

  svg: {
    width: '2.85rem',
    height: '2.85rem',
    marginLeft: '0.5rem',
  },
}));

function ListSide() {
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const navigate = useNavigate();
  const [routesStateData, setRoutesStateData] = useRecoilState<RoutesType>(routesState);
  const { root_path, child_path } = routesStateData;
  const [currentMenu, setCurrentMenu] = useState<ListMenuType>();
  const { modal_confirm, modal_alert } = useContext(ModalContext);
  const { playEffectSound } = useContext(EffectSoundContext);
  const setModalStudySetting = useSetRecoilState<ModalStudySettingDataType>(modalStudySettingData);

  const handleOnClick = (path: string) => {
    playEffectSound('click');

    navigate(path, { replace: true });
    if (!path.includes('/bookprint')) {
      setModalStudySetting(prev => ({ ...prev, previousPath: path }));
    }
  };

  const onClickExit = () => {
    playEffectSound('click');
    navigate(`${BASE_URL}/`);
  };

  // 메뉴 아이템
  const listmenu_items: ListMenuType = {
    '': [],
    schedule: [
      { path: '/learningschedule', label: '학습 스케쥴', icon: <GoCalendar />, root: true },
      { path: '/learningbook', label: '스케쥴 교재', icon: <TbFileSearch /> },
    ],
    report: [
      { path: '/lookup', label: '학습 결과', icon: <BiEdit />, root: true },
      { path: '/period', label: '레포트 기간분석', icon: <FaChartPie /> },
      { path: '/month', label: '레포트 월별분석', icon: <IoStatsChartSharp /> },
    ],
    leaderboard: [
      { path: '/board', label: '리더보드', icon: <FaTrophy />, root: true },
      { path: '/record', label: '수상이력', icon: <IoRibbonSharp /> },
    ],
    leveltest: [{ path: '/report', label: '레벨 테스트 조회', icon: <GiSergeant />, root: true }],
    manager: [
      // { path: '/chart', label: '학습 성적분석', icon: <HiChartBar /> },
      // { path: '/bookprint', label: '단어 시험지 출력', icon: <BsFileEarmarkTextFill /> },
      {
        path: '/chart',
        label: '학습 성적분석',
        icon: <BarChartIcon />,
      },

      {
        path: '/bookprint',
        label: '단어 시험지 출력',
        icon: null,
      },

      { path: '/notice', label: '공지사항', icon: <HiSpeakerphone />, root: true },
      { path: '/notification', icon: <BsBellFill />, label: '학습 알림센터' },
      // { path: '/manual', icon: <MdMenuBook />, label: '사용자 매뉴얼' },
      { path: '/qna', icon: <AiOutlineQuestionCircle />, label: '자주 묻는 질문' },
      { path: '/settings', icon: <IoSettingsSharp />, label: '학습설정' },
    ],
  };

  //학습매니저 메인&서브 조건부 적용
  useEffect(() => {
    const tmp_sidemenu = { ...listmenu_items };

    // userStateData.type이 20 이하일 경우(서브프로필일 경우) 공지사항, 차트, 단어시험치출력 제거
    if (userStateData.type < 20) {
      tmp_sidemenu.manager = tmp_sidemenu.manager.filter(
        item => !item.path.includes('/notice') && !item.path.includes('/chart') && !item.path.includes('/bookprint'),
      );
    }

    // 기존 관리자 계정의 경우 성적 분석, 출력 탭 미표시
    if (userStateData.center_type !== 'C') {
      tmp_sidemenu.manager = tmp_sidemenu.manager.filter(
        item => !item.path.includes('/chart') && !item.path.includes('/bookprint'),
      );
    }

    setCurrentMenu(tmp_sidemenu);
  }, [userStateData]);

  return (
    <StyledSideMenuWrap>
      <Box
        sx={{
          width: '4.25rem',
          height: '100%',
          backgroundColor: theme.palette.purple_3.main,
          borderRadius: '1rem',
        }}
      >
        <StyledIconBox className='icon_box'>
          <StyledIconButton onClick={onClickExit} />
        </StyledIconBox>
        <Box
          sx={{ width: '3.9rem', marginLeft: '0.35rem', display: 'grid', gap: '1vh' }}
          className='customize side-menu'
        >
          {currentMenu &&
            currentMenu[root_path] &&
            currentMenu[root_path].map((menu, idx) => {
              const isActive = (child_path == '' && menu.root) || `/${child_path}` == menu.path;
              return menu.visible !== false ? (
                <LinkButton
                  className={isActive ? 'active' : ''}
                  is_active={isActive}
                  onClick={() => handleOnClick(`${BASE_URL}/${root_path}${menu.path}`)}
                  key={`side_menu_${idx}`}
                  variant='contained'
                  color={isActive ? 'white' : 'purple_3'}
                  disableRipple
                  idx={`${idx}`}
                >
                  {menu.path.includes('/bookprint') ? isActive ? <ActiveDocumentIcon /> : <DocumentIcon /> : menu.icon}
                </LinkButton>
              ) : null;
            })}
          {/* {currentMenu && currentMenu[root_path] ? (
            <LinkButton
              key='last_link_button'
              variant='contained'
              disableRipple
              idx={`${currentMenu[root_path].length}`}
              color='purple_3'
              sx={{ cursor: 'inherit' }}
            ></LinkButton>
          ) : null} */}
        </Box>
      </Box>
    </StyledSideMenuWrap>
  );
}

export default ListSide;
