import * as React from 'react';
import { useContext, useCallback } from 'react';
import ModalDefault from './ModalDefault';
import Button from '../button/Button';
import { ModalContext } from '../../provider/ModalProvider';
import { ConfirmModalWrapper, StyledModalTitle, StyledModalUtil, StyledLine } from '../../styles/common';
import { styled, Box } from '@mui/material';
import { learningState } from '../../recoil/model/learning';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';
import { useState, useEffect, useRef, useMemo } from 'react';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { deviceState } from 'recoil/common/device';
import { profilesState } from 'recoil/common/profiles';
import { fetchPostApi } from 'utils/api';
import { userState } from 'recoil/model/user';

declare let window: any;

const ModalConfirmButton = styled(Button)(() => ({
  fontSize: '1.1rem',
  width: 'calc(50% - 0.5px)',
  height: '100%',
}));

const InputWrap = styled(Box)({
  width: '100%',
  position: 'relative',
  padding: '0.8rem 1.6rem 1rem',

  'input:focus + .placeholder': {
    display: 'none',
  },
});

const Input = styled('input')({
  outline: 'none',
  whiteSpace: 'nowrap',
  padding: '0.6rem 0 0.55rem',
  color: '#747474',
  fontWeight: '400',
  fontSize: '0.95rem',
  lineHeight: '0.6rem',
  borderRadius: '0.25rem',
  width: '100%',
  border: '1px solid #dadbdc',
  textAlign: 'center',
  '&::placeholder': {
    fontWeight: '400',
    fontSize: '0.8rem',
    paddingLeft: '0.25rem',
    paddingBottom: '0',
    color: '#e4e4e4',
    textAlign: 'center',
  },
});

const Placeholder = styled('span')({
  position: 'absolute',
  fontWeight: '400',
  color: '#9f9f9f',
  fontSize: '0.95rem',
  cursor: 'text',

  top: '50%',
  left: '50%',

  transform: 'translate(-50%, -50%)',

  span: {
    color: '#000',
  },
});

function ModalConfirm() {
  const { modal_confirm } = useContext(ModalContext);
  const { playEffectSound } = useContext(EffectSoundContext);
  const deviceStateData = useRecoilValue(deviceState);
  const { is_mobile, platform } = deviceStateData;
  const userStateData = useRecoilValue(userState);
  const { customer_id: p_customer_id } = userStateData;
  const [inputValue, setInputValue] = useState('');
  const setLearningStateData = useSetRecoilState(learningState);
  const setToastBar = useSetRecoilState(toastBarState);
  const profileStateData = useRecoilValue(profilesState);
  const { customer_id, profiles } = profileStateData;
  const [externalKeyboard, setExternalKeyboard] = useState<boolean>(false);
  const [top, setTop] = useState<string>('0px');

  const useInput = React.useMemo(() => {
    return ['delete_profile', 'add_additional_book'].includes(modal_confirm.data.type);
  }, [modal_confirm.data.type]);

  const onCloseModal = () => {
    setLearningStateData(prevState => ({
      ...prevState,
      show_modal: false,
    }));
    modal_confirm.closeModalConfirm();
  };

  const onButtonClick = useCallback(
    async (text: string) => {
      if (text == modal_confirm.data.right) {
        if (useInput) {
          if (inputValue != '') {
            const url = `/customers/${profiles.length > 0 ? customer_id : p_customer_id}/validate/password`;
            const args = {
              password: inputValue,
            };
            const res = await fetchPostApi(url, args);

            if (res.result) {
              modal_confirm.data.cancle_callback ? modal_confirm.data.cancle_callback() : false;
            } else {
              openToastBar({ setToastBar }, '비밀번호가 틀렸습니다.', 'red', 2000);
              return;
            }
          } else {
            openToastBar({ setToastBar }, '비밀번호를 입력해주세요.', 'red', 2000);
            return;
          }
        } else {
          modal_confirm.data.cancle_callback ? modal_confirm.data.cancle_callback() : false;
        }
      } else {
        modal_confirm.data.confirm_callback();
      }

      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: false,
      }));
      modal_confirm.closeModalConfirm();
    },
    [modal_confirm, inputValue, customer_id, useInput, p_customer_id, profiles],
  );

  useEffect(() => {
    if (modal_confirm.data.visible) {
      playEffectSound('confirm');
    } else {
      setInputValue('');
    }
  }, [modal_confirm.data.visible]);

  useEffect(() => {
    if (is_mobile) {
      if (typeof window.ExternalKeyboard == 'function') window.ExternalKeyboard.checkOnce();
      if (window.ExternalKeyboard.isUsed) setExternalKeyboard(true);
    }
  }, [is_mobile]);

  const input_el = useRef<HTMLInputElement | null>(null);

  // * placeholder click method
  const onClickPlaceholder = useCallback(() => {
    if (input_el.current) {
      input_el.current.focus();
    }
  }, []);

  // * change method
  const onChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
  }, []);

  // * focus method
  const onFocusInput = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (!is_mobile || externalKeyboard) return false;
      if (platform == 'android') {
        setTop('-38vh');
      }
    },
    [is_mobile, externalKeyboard, platform],
  );

  // * blur method
  const onBlurInput = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (!is_mobile || externalKeyboard) return false;
      if (platform == 'android') {
        setTop('0px');
      }
    },
    [is_mobile, externalKeyboard],
  );

  return (
    <>
      <ModalDefault
        visible={modal_confirm.data.visible}
        onClose={
          modal_confirm.data.block_background_close
            ? () => {
                return;
              }
            : onCloseModal
        }
        sx={{ marginTop: top }}
      >
        <ConfirmModalWrapper>
          <StyledModalTitle
            sx={{
              lineHeight: '2rem',
              fontFamily: 'Apple SD Gothic Neo',
              padding: useInput ? '1.75rem 1.2rem 0 1.2rem' : '2.4rem 1.2rem',
            }}
            dangerouslySetInnerHTML={{ __html: modal_confirm.data.title }}
          ></StyledModalTitle>
          {useInput ? (
            <InputWrap>
              <Input
                value={inputValue}
                onChange={onChangeInput}
                ref={input_el}
                onFocus={onFocusInput}
                onBlur={onBlurInput}
              />
              {inputValue ? null : modal_confirm.data.type != 'delete_profile' ? null : (
                <Placeholder className='placeholder' onClick={onClickPlaceholder}>
                  삭제하려면 <span>{"'비밀번호'"}</span>를 입력하세요
                </Placeholder>
              )}
            </InputWrap>
          ) : null}
          <StyledModalUtil sx={{ heihgt: '52px' }}>
            <ModalConfirmButton color='purple' onClick={() => onButtonClick(modal_confirm.data.left)}>
              {modal_confirm.data.left}
            </ModalConfirmButton>
            {modal_confirm.data.right && (
              <>
                <StyledLine></StyledLine>
                <ModalConfirmButton color='gray_1' onClick={() => onButtonClick(modal_confirm.data.right)}>
                  {modal_confirm.data.right}
                </ModalConfirmButton>
              </>
            )}
          </StyledModalUtil>
        </ConfirmModalWrapper>
      </ModalDefault>
    </>
  );
}

export default ModalConfirm;
