import { ModalTargetSettingUI } from './ModalTargetSetting.Presenter';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  modalTargetSettingState,
  closeModalTargetSetting,
  ModalTargetSettingType,
} from 'recoil/common/modalTargetSetting';
import {
  LoadingCircleSpinnerState,
  openLoadingCircleSpinner,
  closeLoadingCircleSpinner,
} from 'recoil/common/loadingCircleSpinner';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { fetchPostApi, fetchPutApi } from 'utils/api';
import { userState } from 'recoil/model/user';
import { processingStudyTarget } from 'utils/tools';

export function ModalTargetSettingContainer() {
  const setLoadingCircleSpinner = useSetRecoilState(LoadingCircleSpinnerState);
  const [userStateData, setUserStateData] = useRecoilState(userState);
  const { customer_id, id, study_target, product } = userStateData;
  const setToastBar = useSetRecoilState(toastBarState);
  const [modalTargetSetting, setModalTargetSetting] = useRecoilState<ModalTargetSettingType>(modalTargetSettingState);
  const { isMain, step, domainButtonGroup, dayButtonGroup, alimTime } = modalTargetSetting;

  const [isFirstClosed, setIsFirstClosed] = useState(false);

  const dropdownContents = useMemo(() => {
    return [
      { id: 1, content: '00시 00분' },
      { id: 2, content: '00시 30분' },
      { id: 3, content: '01시 00분' },
      { id: 4, content: '01시 30분' },
      { id: 5, content: '02시 00분' },
      { id: 6, content: '02시 30분' },
      { id: 7, content: '03시 00분' },
      { id: 8, content: '03시 30분' },
      { id: 9, content: '04시 00분' },
      { id: 10, content: '04시 30분' },
      { id: 11, content: '05시 00분' },
      { id: 12, content: '05시 30분' },
      { id: 13, content: '06시 00분' },
      { id: 14, content: '06시 30분' },
      { id: 15, content: '07시 00분' },
      { id: 16, content: '07시 30분' },
      { id: 17, content: '08시 00분' },
      { id: 18, content: '08시 30분' },
      { id: 19, content: '09시 00분' },
      { id: 20, content: '09시 30분' },
      { id: 21, content: '10시 00분' },
      { id: 22, content: '10시 30분' },
      { id: 23, content: '11시 00분' },
      { id: 24, content: '11시 30분' },
      { id: 25, content: '12시 00분' },
      { id: 26, content: '12시 30분' },
      { id: 27, content: '13시 00분' },
      { id: 28, content: '13시 30분' },
      { id: 29, content: '14시 00분' },
      { id: 30, content: '14시 30분' },
      { id: 31, content: '15시 00분' },
      { id: 32, content: '15시 30분' },
      { id: 33, content: '16시 00분' },
      { id: 34, content: '16시 30분' },
      { id: 35, content: '17시 00분' },
      { id: 36, content: '17시 30분' },
      { id: 37, content: '18시 00분' },
      { id: 38, content: '18시 30분' },
      { id: 39, content: '19시 00분' },
      { id: 40, content: '19시 30분' },
      { id: 41, content: '20시 00분' },
      { id: 42, content: '20시 30분' },
      { id: 43, content: '21시 00분' },
      { id: 44, content: '21시 30분' },
      { id: 45, content: '22시 00분' },
      { id: 46, content: '22시 30분' },
      { id: 47, content: '23시 00분' },
      { id: 48, content: '23시 30분' },
    ];
  }, []);

  useEffect(() => {
    if (study_target) {
      setIsFirstClosed(true);
    }
  }, [study_target]);

  const isFirst = useMemo(() => {
    return study_target && isFirstClosed ? false : true;
  }, [study_target, isFirstClosed]);

  const onClickItem = useCallback((idx: number, initDrdopownTitle: () => void) => {
    const find_alim_time = { ...dropdownContents[idx] };

    setModalTargetSetting(prevState => ({
      ...prevState,
      alimTime: find_alim_time,
    }));
  }, []);

  const onClickNextButton = useCallback(() => {
    setModalTargetSetting(prev => ({
      ...prev,
      step: 2,
    }));
  }, []);

  const onClickDomainButton = useCallback(
    (id: number) => {
      if (!isFirst) {
        openToastBar({ setToastBar }, '학습 목표 수정은 불가합니다.', 'red', 2000);
        return;
      }

      let check_count = 0;
      const new_domain_button_group = domainButtonGroup.map(item => {
        if (item.id == id) {
          if (!item.check) check_count += 1;

          return {
            ...item,
            check: !item.check,
          };
        } else {
          if (item.check) check_count += 1;

          return {
            ...item,
          };
        }
      });
      if (check_count > 2) {
        // * 더 이상 체크 안 된다고 alert 띄우기
        openToastBar({ setToastBar }, '영역은 최대 2개만 선택 가능합니다.', 'black', 2000);
      } else {
        // * 체크
        setModalTargetSetting(prevState => ({
          ...prevState,
          domainButtonGroup: new_domain_button_group,
        }));
      }
    },
    [domainButtonGroup, isFirst],
  );

  const onClickDayButton = useCallback(
    (id: number) => {
      if (!isFirst) {
        openToastBar({ setToastBar }, '학습 목표 수정은 불가합니다.', 'red', 2000);
        return;
      }

      const new_day_button_group = dayButtonGroup.map(item => {
        if (item.id == id) {
          return {
            ...item,
            check: !item.check,
          };
        } else {
          return {
            ...item,
          };
        }
      });

      setModalTargetSetting(prevState => ({
        ...prevState,
        dayButtonGroup: new_day_button_group,
      }));
    },
    [dayButtonGroup, isFirst],
  );

  const onClickStudyTargetSettingButton = useCallback(async () => {
    if (!isFirst) {
      closeModalTargetSetting({ setModalTargetSetting });
      return;
    }
    openLoadingCircleSpinner({ setLoadingCircleSpinner }, 8, '학습 스케쥴을 준비중입니다!', {
      enter: 0,
      exit: 0,
    });

    // * body params 가공
    const study_domains = domainButtonGroup
      .filter(item => item.check)
      .map(item => {
        switch (item.id) {
          case 1:
            return 'longvoca';
          case 2:
            return 'school';
          case 3:
            return 'grammar';
          case 4:
            return 'speak';
        }
      })
      .join(',');

    const study_days = dayButtonGroup
      .filter(item => item.check)
      .map(item => {
        switch (item.id) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
            return item.id;
          case 7:
            return 0;
        }
      })
      .join(',');

    const study_start_time = alimTime.content.replace('시 ', '').replace('분', '');

    const params: any = {
      study_domains,
      study_days,
      study_start_time,
    };

    const url = `/customers/${customer_id}/accounts/${id}/target`;
    if (study_target == null) {
      // * 학습 목표 등록
      try {
        let limit = 5;
        let res = await fetchPostApi(url + `?limit=${limit}`, params);

        let refetch = res.data;
        if (refetch) params['refetch'] = true;

        let study_target: StudyTarget | null = null;
        if (res.study_target_result) {
          let speak_study_level = userStateData.speak_study_level;
          if (res.study_target_result && res.study_target_result.study_domains.includes('speak')) {
            const speak_domain_idx = res.study_target_result.study_domains
              .split(',')
              .findIndex((v: any) => v == 'speak');
            const cur_study_level = res.study_target_result.study_level.split(',')[speak_domain_idx];
            speak_study_level = cur_study_level;
          }

          study_target = res.study_target_result;
          setUserStateData(prev => ({
            ...prev,
            speak_study_level,
          }));
        }

        //! 완전히 끝날때까지 계속 요청하는 로직 만들기
        while (refetch) {
          limit += 5;
          res = await fetchPostApi(url + `?limit=${limit}`, params);

          refetch = res.data;
        }

        // * (목표 수립 알림톡 발송 로직)
        fetchPostApi(`/customers/${customer_id}/accounts/${id}/alim/target`, {});

        closeModalTargetSetting({ setModalTargetSetting });
        setUserStateData(prev => ({
          ...prev,
          study_target: study_target,
        }));

        if (res.result) {
          openToastBar({ setToastBar }, '학습 스케쥴이 설정되었습니다.', 'black', 2000);
          setIsFirstClosed(true);
        } else {
          openToastBar({ setToastBar }, '오류가 발생했습니다.', 'red', 2000);
        }
      } catch (error) {
        console.error(error);
      } finally {
        closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
      }
    } else {
      // * 학습 목표 수정
      try {
        const res = await fetchPutApi(url, params);
        closeModalTargetSetting({ setModalTargetSetting });

        if (res.result) {
          setUserStateData(prev => ({
            ...prev,
            study_target: params,
          }));
          openToastBar({ setToastBar }, '학습 스케쥴이 수정되었습니다.', 'black', 2000);
        } else {
          openToastBar({ setToastBar }, '오류가 발생했습니다.', 'red', 2000);
        }
      } catch (error) {
        console.error(error);
      } finally {
        closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
      }
    }
  }, [domainButtonGroup, dayButtonGroup, alimTime, study_target, isFirst]);

  const onClose = useCallback(() => {
    closeModalTargetSetting({ setModalTargetSetting });
  }, []);

  const buttonDisabled = useMemo(() => {
    const checkedDaysCnt = dayButtonGroup.filter(day => day.check).length;
    const checkedDomainCnt = domainButtonGroup.filter(day => day.check).length;

    if (checkedDomainCnt === 2 && checkedDaysCnt < 2) return true;
    if (study_target) {
      // * 수정 버튼 disabled
      const origin_values = processingStudyTarget(study_target, product);
      if (origin_values) {
        const {
          dayButtonGroup: origin_dayButtonGroup,
          domainButtonGroup: origin_domainButtonGroup,
          alimTime: origin_alimTime,
        } = origin_values;

        const vali_domains = JSON.stringify(domainButtonGroup) == JSON.stringify(origin_domainButtonGroup);
        const vali_days = JSON.stringify(dayButtonGroup) == JSON.stringify(origin_dayButtonGroup);
        const vali_alim_time = JSON.stringify(alimTime) == JSON.stringify(origin_alimTime);

        if (vali_domains && vali_days && vali_alim_time) return true;
        return false;
      }

      return true;
    } else {
      // * 등록 버튼 disabled
      const vali_study_domains = domainButtonGroup.filter(item => item.check).length;
      const vali_study_days = dayButtonGroup.filter(item => item.check).length;

      if (
        vali_study_domains > 0 &&
        vali_study_domains <= 2 &&
        vali_study_days > 0 &&
        vali_study_days <= 7 &&
        alimTime.id != 0
      )
        return false;
      else return true;
    }
  }, [domainButtonGroup, dayButtonGroup, alimTime, study_target, product]);

  const props = {
    isMain,
    step,
    onClickNextButton,
    domainButtonGroup,
    dayButtonGroup,
    dropdownContents,
    disabled: false,
    onClickItem,
    onClickDomainButton,
    onClickDayButton,
    alimTime,
    onClickStudyTargetSettingButton,
    isFirst,
    onClose,
    buttonDisabled,
  };

  if (modalTargetSetting.visible) {
    return <ModalTargetSettingUI {...props} />;
  } else {
    return null;
  }
}
