import { SetterOrUpdater, atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'profiles',
  storage: sessionStorage,
});

export interface ProfileType {
  id: number;
  default_profile: string;
  effect_sound: number;
  name: string;
  off_tutorial: string;
  phone: string;
  profile: string;
  profile_type: string;
  study_alim: number;
  type: string;
  grade: string | null;
  pin_code: string | null;
}

export interface ProfilesType {
  center_type: string;
  classification_type: string;
  customer_id: number;
  free_enddate: string | boolean;
  product_type: string;
  mode: string;
  profiles: Array<ProfileType>;
  modify_pin_profile: ProfileType | null;
  isStop: boolean;
  isWithDrawal: boolean;
  isBlocked: boolean;
  admin_real_stop: boolean;
  recommCode: string | null;
  isJoinEscape: boolean;
}

export const profilesState = atom<ProfilesType>({
  key: 'profilesState',
  default: {
    center_type: '',
    classification_type: '',
    customer_id: 0,
    free_enddate: '',
    product_type: '',
    mode: 'list',
    profiles: [],
    modify_pin_profile: null,
    isStop: false,
    isWithDrawal: false,
    isBlocked: false,
    admin_real_stop: false,
    recommCode: null,
    isJoinEscape: false,
  },
  effects_UNSTABLE: [persistAtom],
});

interface ProfilesStateSetter {
  setProfileStateData: SetterOrUpdater<ProfilesType>;
}

export const resetProfilesState = ({ setProfileStateData }: ProfilesStateSetter) => {
  setProfileStateData({
    center_type: '',
    classification_type: '',
    customer_id: 0,
    free_enddate: '',
    product_type: '',
    mode: 'list',
    profiles: [],
    modify_pin_profile: null,
    isStop: false,
    isWithDrawal: false,
    isBlocked: false,
    admin_real_stop: false,
    recommCode: null,
    isJoinEscape: false,
  });
};

export const setProfilePageMode = ({ setProfileStateData }: ProfilesStateSetter, mode: string) => {
  setProfileStateData(prevState => ({
    ...prevState,
    mode,
  }));
};

export const setProfilePageModifyPinCode = (
  { setProfileStateData }: ProfilesStateSetter,
  profile: ProfileType | null,
) => {
  setProfileStateData(prevState => ({
    ...prevState,
    mode: 'modify-pin-code',
    modify_pin_profile: profile,
  }));
};
