import React, { useCallback } from 'react';
import { PrintSettingUI, PrintSettingVAProps } from './PrintSetting.Presenter';

export const PrintSettingContainer = React.memo(function PrintSettingContainer(
  props: Omit<PrintSettingVAProps, 'sliderValueLabelFormat'>,
) {
  const sliderValueLabelFormat = useCallback((value: number, index: number) => {
    let return_value = '';
    switch (value) {
      case 0:
        return_value = '7:3';
        break;
      case 25:
        return_value = '6:4';
        break;
      case 50:
        return_value = '5:5';
        break;
      case 75:
        return_value = '4:6';
        break;
      case 100:
        return_value = '3:7';
        break;
    }
    return return_value;
  }, []);

  return <PrintSettingUI {...props} sliderValueLabelFormat={sliderValueLabelFormat} />;
});
