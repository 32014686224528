import { NumberToEmoji } from '../NumberToEmoji';
import {
  Container,
  BottomWrap,
  BoxButton,
  ContentBox,
  SubTitle,
  Title,
  TopWrap,
  Wrapper,
  InputContentBox,
  DomainButtonWrap,
  DayButtonWrap,
  DropdownWrap,
  Background,
} from './ModalTargetSetting.Styles';
import { ModalTargetSettingType } from 'recoil/common/modalTargetSetting';
import Button from 'components/button/Button';
import {
  DropdownNormalContainer,
  DropdownContainerProps,
} from 'components/common/DropdownNormal/DropdownNormal.Container';

interface ModalTargetSettingVAProps extends Omit<ModalTargetSettingType, 'visible'>, DropdownContainerProps {
  domainButtonGroup: Array<{ id: number; label: string; check: boolean; disabled: boolean }>;
  dayButtonGroup: Array<{ id: number; label: string; check: boolean }>;
  alimTime: { id: number; content: string };
  isFirst: boolean;
  buttonDisabled: boolean;
  onClickNextButton: () => void;
  onClickStudyTargetSettingButton: () => void;
  onClickDomainButton: (id: number) => void;
  onClickDayButton: (id: number) => void;
  onClose: () => void;
}

export function ModalTargetSettingUI({
  isMain,
  step,
  onClickNextButton,
  dayButtonGroup,
  domainButtonGroup,
  dropdownContents,
  isFirst,
  alimTime,
  onClickItem,
  onClickDomainButton,
  onClickDayButton,
  onClickStudyTargetSettingButton,
  onClose,
  buttonDisabled,
}: ModalTargetSettingVAProps) {
  return (
    <Container>
      <Background onClick={isFirst ? undefined : onClose} />
      <Wrapper>
        {step == 1 ? (
          <>
            <TopWrap>
              <Title>
                메인 프로필로 접속하셨군요!
                {isMain ? <span>{step}/2</span> : null}
              </Title>
              <SubTitle>메인 프로필은 다음과 같이 2가지 관리 기능이 제공되요!</SubTitle>
              <ContentBox>
                <div>
                  <NumberToEmoji word={`1`} colorCode='#5c3dff' />
                  <span>학습 시작과 종료 시 알림톡 무료 발송</span>
                </div>
                <p>하위에 등록된 프로필의 학습알림을 켜두셨나요?</p>
                <p>학습 시작과 종료, 주간 목표달성, 월간 학습분석 등</p>
                <p>실시간 알림톡을 무료로 발송해드려요!</p>
              </ContentBox>
              <ContentBox>
                <div>
                  <NumberToEmoji word={`2`} colorCode='#5c3dff' />
                  <span>PC에서는 학습지를 출력할 수 있어요.</span>
                </div>
                <p>PC로 로그인하시면, 모바일에서 학습을 하시더라도</p>
                <p>학습결과와 학습지를 무료로 출력할 수 있어요</p>
              </ContentBox>
            </TopWrap>
            <BottomWrap>
              <BoxButton
                sx={{
                  background: 'rgb(90, 20, 219)',
                  width: '100%',
                  borderBottomLeftRadius: '0.5rem',
                  borderBottomRightRadius: '0.5rem',
                }}
                onClick={onClickNextButton}
              >
                <p style={{ color: '#fff', fontWeight: '600' }}>다음</p>
              </BoxButton>
            </BottomWrap>
          </>
        ) : (
          <>
            <TopWrap>
              <Title sx={{ marginBottom: '1rem' }}>
                회원님의 학습 목표를 설정해주세요! {isMain ? <span>{step}/2</span> : null}
              </Title>
              <InputContentBox className='domain-button-wrap'>
                <div>
                  <NumberToEmoji word={`1`} colorCode='#5c3dff' />
                  <span>어떤 영역이 제일 취약하신가요? (택2)</span>
                </div>
                <DomainButtonWrap>
                  {domainButtonGroup.map(item => {
                    return (
                      <Button
                        onClick={() => onClickDomainButton(item.id)}
                        color={item.check ? 'yellow_4' : 'white_5'}
                        variant='contained'
                        key={item.id}
                        disabled={item.disabled}
                      >
                        {item.label}
                      </Button>
                    );
                  })}
                </DomainButtonWrap>
              </InputContentBox>
              <InputContentBox className='day-button-wrap'>
                <div>
                  <NumberToEmoji word={`2`} colorCode='#5c3dff' />
                  <span>학습하고자 하는 요일을 선택해주세요.</span>
                </div>
                <DayButtonWrap>
                  {dayButtonGroup.map(item => {
                    return (
                      <Button
                        onClick={() => onClickDayButton(item.id)}
                        color={item.check ? 'yellow_4' : 'white_5'}
                        variant='contained'
                        key={item.id}
                      >
                        {item.label}
                      </Button>
                    );
                  })}
                </DayButtonWrap>
              </InputContentBox>
              <InputContentBox>
                <div>
                  <NumberToEmoji word={`3`} colorCode='#5c3dff' />
                  <span>매일 몇 시 수업 시작을 알려드릴까요?</span>
                </div>
                <DropdownWrap>
                  <DropdownNormalContainer
                    isDisabled={!isFirst}
                    dropdownContents={dropdownContents}
                    onClickItem={onClickItem}
                    color={alimTime.id == 0 ? 'white' : 'yellow_4'}
                    title={alimTime.content}
                    variant='contained'
                    className='need-border study-target-modal'
                  />
                </DropdownWrap>
              </InputContentBox>
            </TopWrap>
            <BottomWrap>
              <BoxButton
                sx={{
                  background: isFirst ? (buttonDisabled ? '#f0f0f0' : 'rgb(90, 20, 219)') : 'rgb(90, 20, 219)',
                  width: '100%',
                  borderBottomLeftRadius: '0.5rem',
                  borderBottomRightRadius: '0.5rem',
                }}
                onClick={
                  isFirst
                    ? buttonDisabled
                      ? undefined
                      : onClickStudyTargetSettingButton
                    : onClickStudyTargetSettingButton
                }
              >
                <p style={{ color: '#fff', fontWeight: '700' }}>{isFirst ? '목표 설정' : '닫기'}</p>
              </BoxButton>
            </BottomWrap>
          </>
        )}
      </Wrapper>
    </Container>
  );
}
