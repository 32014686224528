import { useEffect, useState } from 'react';
import { ModalCardInfoUI } from './ModalCardInfo.Presenter';
import { autoHyphenCard, autoHyphenBusinessNumber } from 'utils/tools';
import { SelectChangeEvent } from '@mui/material';
import { CARD_PAYMENT_TERMS_1, CARD_PAYMENT_TERMS_2, CARD_PAYMENT_TERMS_3 } from 'utils/constants';
import { deviceState } from 'recoil/common/device';
import { useRecoilValue } from 'recoil';

interface ModalPriceCalcProps {
  onClose: () => void;
  handleCardPaymentButtonClick: (cardInfo: CardInfoType) => void;
  isLoading: boolean;
  selectedProduct: string;
}

export const checkboxData = [
  { id: 'corporation', label: '법인카드 결제' },
  { id: 'eula', label: '전자금융거래 이용약관', notice: '보기 >', content: CARD_PAYMENT_TERMS_1 },
  { id: 'privacy', label: '개인정보수집 및 이용동의', notice: '보기 >', content: CARD_PAYMENT_TERMS_2 },
  { id: 'thirdParty', label: '개인정보 제 3자 제공동의', notice: '보기 >', content: CARD_PAYMENT_TERMS_3 },
];

export const promotionCheckboxData = [
  { id: 'agreement', label: '전체 동의' },
  { id: 'eula', label: '전자금융거래 이용약관', notice: '보기 >', content: CARD_PAYMENT_TERMS_1 },
  { id: 'privacy', label: '개인정보수집 및 이용동의', notice: '보기 >', content: CARD_PAYMENT_TERMS_2 },
  { id: 'thirdParty', label: '개인정보 제 3자 제공동의', notice: '보기 >', content: CARD_PAYMENT_TERMS_3 },
];

export interface CardInfoType {
  cardNumber: string;
  month: string;
  year: string;
  birthDate: string;
  installmentMonths?: string;
}

declare let window: any;

export const ModalCardInfoContainer = ({
  onClose,
  handleCardPaymentButtonClick,
  isLoading,
  selectedProduct,
}: ModalPriceCalcProps) => {
  const [cardNumber, setCardNumber] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [checkBoxSelectAll, setCheckBoxSelectAll] = useState(false);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState('main');
  const [isCorporation, setIsCorporation] = useState(false);
  const [installmentMonths, setInstallmentMonths] = useState('일시불');

  const [top, setTop] = useState('0px');
  const [externalKeyboard, setExternalKeyboard] = useState<boolean>(false);
  const deviceStateData = useRecoilValue(deviceState);
  const { is_mobile, platform } = deviceStateData;

  useEffect(() => {
    if (is_mobile) {
      if (typeof window.ExternalKeyboard == 'function') window.ExternalKeyboard.checkOnce();
      if (window.ExternalKeyboard.isUsed) setExternalKeyboard(true);
    }
  }, [is_mobile]);

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const id = e.target.id;
    if (!is_mobile || externalKeyboard) return false;
    if (platform == 'android') {
      let size = '0px';
      if (id === 'brnOrBirth') {
        size = '-6vh';
      }
      setTop(size);
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform == 'android') {
      setTop('0px');
    }
  };

  const currentYear = new Date().getFullYear();
  const futureYears = Array.from({ length: 11 }, (_, index) => currentYear + index).sort((a, b) => b - a);

  const handleTermsButtonClick = (id: string) => {
    if (id !== 'corporation') {
      setCurrentPage(id);
    }
  };

  useEffect(() => {
    setCurrentPage('main');
  }, []);

  const handleInstallmentMonthChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value === '1' ? '일시불' : `${event.target.value}개월`;
    setInstallmentMonths(value);
  };

  const handleCheckboxChange = (id: string) => {
    if (id === 'corporation') {
      if (checkedIds.includes('corporation')) {
        setIsCorporation(false);
        setCheckedIds(checkedIds.filter(checkedId => checkedId !== 'corporation'));
      } else {
        setIsCorporation(true);
        setCheckedIds([...checkedIds, 'corporation']);
      }
    } else {
      let newCheckStates: string[];

      if (checkedIds.includes(id)) {
        newCheckStates = checkedIds.filter(checkedId => checkedId !== id);
      } else {
        newCheckStates = [...checkedIds, id];
      }

      setCheckedIds(newCheckStates);

      const otherCheckboxIds = checkboxData.map(data => data.id).filter(dataId => dataId !== 'corporation');
      const allOtherSelected = otherCheckboxIds.every(otherId => newCheckStates.includes(otherId));

      setCheckBoxSelectAll(allOtherSelected);
    }
  };

  const handlePromotionCheckboxChange = (id: string) => {
    if (id === 'agreement') {
      if (checkedIds.includes('agreement')) {
        setCheckedIds([]);
      } else {
        setCheckedIds(promotionCheckboxData.map(checkbox => checkbox.id));
      }
    } else {
      let newCheckStates: string[];

      if (checkedIds.includes(id)) {
        newCheckStates = checkedIds.filter(checkedId => checkedId !== id);
        if (newCheckStates.includes('agreement')) {
          newCheckStates = newCheckStates.filter((checkedId: string) => checkedId !== 'agreement');
        }
      } else {
        newCheckStates = [...checkedIds, id];
        const otherCheckboxIds = promotionCheckboxData.map(cbox => cbox.id).filter(cboxId => cboxId !== 'agreement');

        if (!otherCheckboxIds.some(cboxId => !newCheckStates.includes(cboxId))) {
          newCheckStates.push('agreement');
        }
      }
      setCheckedIds(newCheckStates);
    }
  };

  useEffect(() => {
    if (selectedProduct !== 'month') {
      if (checkedIds.includes('agreement')) {
        setCheckBoxSelectAll(true);
      } else {
        setCheckBoxSelectAll(false);
      }
    }
  }, [checkedIds]);

  const handleClose = () => {
    onClose();
  };

  const handleCardNumberInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    if (value.length < 20) {
      setCardNumber(autoHyphenCard(value));
    }
  };

  const handleMonthChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const value = event.target.value;
    setSelectedMonth(value.padStart(2, '0'));
  };

  const handleYearChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    setSelectedYear(String(event.target.value));
  };

  const handleBirthDateInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim().replace(/[^0-9]/g, '');

    if (isCorporation) {
      if (value.length < 11) setBirthDate(autoHyphenBusinessNumber(value));
    } else {
      if (value.length < 7) setBirthDate(value);
    }
  };

  useEffect(() => {
    setBirthDate('');
  }, [isCorporation]);

  const props = {
    handleClose,
    handleCardNumberInputChange,
    handleBirthDateInputChange,
    cardNumber,
    handleMonthChange,
    handleYearChange,
    selectedMonth,
    selectedYear,
    futureYears,
    birthDate,
    checkedIds,
    handleCheckboxChange,
    handleCardPaymentButtonClick,
    checkBoxSelectAll,
    isLoading,
    handleTermsButtonClick,
    currentPage,
    isCorporation,
    selectedProduct,
    installmentMonths,
    handleInstallmentMonthChange,
    handlePromotionCheckboxChange,
    top,
    onFocus,
    onBlur,
  };

  return <ModalCardInfoUI {...props} />;
};
