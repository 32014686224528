import styled from '@emotion/styled';
import { Box, BoxProps, IconButton } from '@mui/material';
import theme from 'theme';

export const Wrapper = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'relative',
});

export const Container = styled(Box)({
  width: '100%',
  height: '100%',
  backgroundColor: '#616161',
});

interface ContainerBoxProps extends BoxProps {
  fullscreen: string;
}

export const ContainerBox = styled(Box)<ContainerBoxProps>(props => ({
  width: props.fullscreen == 'true' ? '210mm' : '100%',
  height: props.fullscreen == 'true' ? '297mm' : '100%',
  display: props.fullscreen == 'true' ? 'block' : 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: props.fullscreen == 'true' ? '4px' : 'auto',
}));

export const FullscreenButton = styled(IconButton)(props => ({
  position: 'absolute',
  top: '1rem',
  zIndex: 2,
  backgroundColor: 'white',
  borderRadius: '4px',
  '& > svg': {
    color: '#212121',
    width: '1.5rem',
    height: '1.5rem',
  },
}));

export const MovePageButton = styled(IconButton)(props => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 2,
  '& > svg': {
    color: props.disabled ? '#e1e1e1' : theme.palette.purple.main,
    width: '2.25rem',
    height: '2.25rem',
  },
}));

export const PrintWrap = styled(Box)({
  backgroundColor: 'white',
});

export const Worksheet = styled(Box)({
  width: '210mm',
  height: '297mm',
  padding: '3mm',
  '&.worksheet-template': {
    display: 'none',
  },
  '&.worksheet': {
    backgroundColor: 'white',
    '&.hidden': {
      '@media print': {
        display: 'block !important',
      },
      '@media screen': {
        display: 'none !important',
      },
    },
  },
  '&.pagebreak': {
    pageBreakBefore: 'always',
  },
});

export const Header = styled(Box)({
  width: '100%',
  height: '11%',
  backgroundColor: '#7851ff',
  borderBottomLeftRadius: '20px',
  borderBottomRightRadius: '20px',
  display: 'flex',
  alignItems: 'center',
});

export const HeaderContents = styled(Box)({
  width: '100%',
  height: '85px',
  padding: '0 35px 0 40px',
  display: 'flex',
});

export const DictionaryImageBox = styled(Box)({
  width: '85px',
  height: '85px',
  backgroundColor: 'white',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > img': {
    width: '55%',
    height: '55%',
  },
});

export const TitleWrap = styled(Box)({
  width: 'calc(100% - 85px)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: 'white',
  paddingLeft: '20px',
});

export const Title = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '& > div': {
    fontFamily: 'TTTogether !important',
    fontSize: '38px',
    letterSpacing: '-1px',
    lineHeight: '1.57',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    transform: 'scaleX(0.9)',
    transformOrigin: 'left',
    position: 'relative',
  },
});

export const NameBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  lineHeight: 'normal',
});

export const Name = styled(Box)({
  color: 'black',
  width: '150px',
  fontSize: '15px',
  border: '1px solid #5200cf',
  backgroundColor: 'white',
  borderRadius: '100px',
  padding: '5px 20px',
});

export const Contents = styled(Box)({
  width: '100%',
  height: '83%',
  padding: '10px 20px',
  '& > .column': {
    padding: '10px',
    '& > div:not(:last-child)': {
      borderBottom: '1px solid #edeeef',
    },
    '&:not(:first-of-type)': {
      borderLeft: '2px solid #edeeef',
    },
    '&.h-100': {
      height: '100%',
    },
  },
});

export const Content = styled(Box)(props => ({
  width: '100%',
  display: 'flex',
  lineHeight: 1.2,
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    '&:first-of-type': {
      width: '10%',
      justifyContent: 'center',
    },
    '&:not(:first-of-type)': {
      width: '45%',
      borderLeft: '1px solid #edeeef',
      padding: '0 12px',
      '& > span': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        wordBreak: 'break-word',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      },
    },
  },
  '&.font-0': {
    fontSize: '12px',
  },
  '&.font-1': {
    fontSize: '14px',
  },
  '&.font-2': {
    fontSize: '16px',
  },
  '&.line-0': {
    height: '37px',
  },
  '&.line-1': {
    height: '41px',
  },
  '&.line-2': {
    height: '45px',
  },
}));

export const Footer = styled(Box)({
  width: '100%',
  height: '6%',
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '5px',
});

export const FooterWrap = styled(Box)({
  display: 'flex',
  flex: '2 1 2',
  width: '93%',
  height: '100%',
  borderTop: '2px solid #edeeef',
  justifyContent: 'space-between',
  '& > .invisible': {
    visibility: 'hidden',
  },
});

export const FooterContent = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  '& > img': {
    maxWidth: '90px',
    maxHeight: '30px',
  },
});
