import React from 'react';
import { InputWrap, InputLoginText } from './InputLoginText.Styles';

export interface InputLoginTextVAProps {
  value: string;
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDownEnter: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>, mode: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>, mode: string) => void;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
}

export const InputLoginTextUI = React.memo(function InputLoginTextUI({
  value,
  onChangeValue,
  onKeyDownEnter,
  placeholder,
  onFocus,
  onBlur,
  type,
  disabled,
}: InputLoginTextVAProps) {
  return (
    <InputWrap is_password='false'>
      <InputLoginText
        value={value}
        onChange={onChangeValue}
        onKeyDown={onKeyDownEnter}
        placeholder={placeholder}
        onFocus={
          onFocus
            ? (e: React.FocusEvent<HTMLInputElement>) =>
                onFocus(e, type == 'phone' ? 'phone' : type == 'pin' ? 'pin' : 'text')
            : undefined
        }
        onBlur={onBlur ? (e: React.FocusEvent<HTMLInputElement>) => onBlur(e, 'text') : undefined}
        disabled={disabled}
      />
    </InputWrap>
  );
});
