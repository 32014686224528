import {
  styled,
  Modal as MuiModal,
  Box,
  FormControl as MuiFormControl,
  FormLabel as MuiFormLabel,
  RadioGroup as MuiRadioGroup,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
} from '@mui/material';

export const Modal = styled(MuiModal)({
  '& > .MuiBox-root': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
});

export const Wrapper = styled(Box)({
  width: '29rem',
  height: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  input: {
    margin: '0',
    fontSize: '1.1rem !important',
  },
});

export const FormControl = styled(MuiFormControl)({
  borderRadius: '0.3rem',
  padding: '1rem 2rem 2rem',
  width: '100%',
  maxWidth: '500px',
  margin: 'auto',
  backgroundColor: 'var(--white)',
  '&:focus-visible': {
    outline: 'none',
  },
});

export const FormLabel = styled(MuiFormLabel)({
  color: 'var(--black)',
  fontSize: '1.125rem',
  '&.Mui-focused': {
    color: 'var(--black)',
  },
  borderBottom: `1px solid #e9ecef`,
  paddingBottom: '1rem',
  marginBottom: '2px',
});

export const RadioGroup = styled(MuiRadioGroup)({
  paddingRight: '2.125rem',
  width: '100%',
  maxHeight: '220px',
  overflowX: 'hidden',
  overflowY: 'auto',
  display: 'block',
  button: {
    marginTop: '0.125rem',
    minHeight: '2.5rem',
    marginRight: '1rem',
    marginLeft: '1rem',
    width: '97%',
  },
});

export const AccordionContainer = styled(Accordion)({
  '&.MuiAccordion-root': {
    margin: '0',
    boxShadow: 'none',
    border: '0',
  },
});

export const AccordionSummaryContainer = styled(AccordionSummary)(props => ({
  '&.MuiAccordionSummary-root .Mui-expanded': {
    margin: '1rem 0',
  },
}));

export const CardAccordionDetail = styled(AccordionDetails)(props => ({
  padding: '0 2rem',
  '& .method-detail-wrap': {
    backgroundColor: props.theme.palette.gray_4.main,
    cursor: 'pointer',
    textAlign: 'center',
    padding: '1rem',
  },
  '& .notice-wrap': {
    color: props.theme.palette.gray_1.main,
    fontSize: '0.9rem',
    padding: '1rem 0',
    paddingRight: '0.8rem',
  },
}));

export const CardAccountAccordionDetail = styled(AccordionDetails)(props => ({
  padding: '0 2.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.8rem',
  color: props.theme.palette.gray_1.main,
  alignItems: 'center',
  textAlign: 'center',
  '& .period-wrap': {
    backgroundColor: props.theme.palette.gray_4.main,
    textAlign: 'center',
    width: '114%',
    padding: '0.8rem',
    display: 'flex',
    justifyContent: 'center',
    gap: '1.5rem',
    fontSize: '0.9rem',
    '& > span': {
      color: 'black',
    },
  },
  '& .payment-cancel-button': {
    color: props.theme.palette.gray_1.main,
    border: '0',
    width: '4rem',
    borderRadius: '2rem',
    fontSize: '1rem',
    height: '2.5rem',
    cursor: 'pointer',
  },
}));

export const AccountAccordionDetail = styled(AccordionDetails)(props => ({
  display: 'flex',
  flexDirection: 'column',
  gap: props.className === 'card-register-wrapper' ? '0.5rem' : '',
  color: props.theme.palette.gray_1.main,
  alignItems: props.className === 'card-register-wrapper' ? 'center' : '',
  marginTop: props.className === 'card-register-wrapper' ? '0' : '-1.8rem',
  padding: props.className === 'card-register-wrapper' ? '' : '0.5rem 2.5rem',
  paddingLeft: props.className === 'card-register-wrapper' ? '2rem' : '',
  paddingBottom: props.className === 'card-register-wrapper' ? '' : '1.8rem',

  '& .check-box-wrap': {
    width: '100%',
    marginLeft: '-0.2rem',
  },

  '& .card-register': {
    backgroundColor: props.theme.palette.gray_4.main,
    textAlign: 'center',
    width: '96%',
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'center',
    gap: '1.5rem',
    fontSize: '1rem',
    cursor: 'pointer',
  },
  '& .payment-cancel-button': {
    color: props.theme.palette.gray_1.main,
    border: '0',
    width: '4rem',
    borderRadius: '2rem',
    fontSize: '1rem',
    height: '2.5rem',
    cursor: 'pointer',
  },
  '& .notice-wrap': {
    color: props.theme.palette.gray_1.main,
    marginTop: '0.5rem',
    fontSize: '0.85rem',
    width: '110%',
    lineHeight: '1.3rem',
  },
}));

export const LoadingFilter = styled(Box)({
  position: 'absolute',
  top: '5rem',
  width: '100%',
  height: 'calc(100% - 5rem)',
  zIndex: '1000',
  filter: 'opacity(0.8)',
  backgroundColor: 'white',
});

export const CustomFormControlLabel = styled(FormControlLabel)({
  '&.MuiFormControlLabel-root': {
    '& > span:nth-of-type(2)': {
      fontSize: '1.1rem',
    },
  },
});
