import {
  LayoutRegistrationUI,
  PostWrapper,
  PostCloseButton,
  postCodeStyle,
  DaumPostcodeEmbed,
  FaTimes,
  Address,
} from './LayoutRegistration.Presenter';
import { InputTitleContainer } from 'components/common/InputTitle/InputTitle.Container';
import { InputTextContainer } from 'components/common/InputText/InputText.Container';
import { InputSelectContainer } from 'components/common/InputSelect/InputSelect.Container';
import { ButtonGroupContainer } from 'components/common/ButtonGroup/ButtonGroup.Container';
import { autoBrn, autoHypenPhone } from 'utils/tools';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import validation from 'utils/validation';
import ButtonContainer from 'components/button/Button';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { modalRadioData, ModalRadioDataType } from 'recoil/common/modalRadio';
import { blockerData } from 'recoil/common/blocker';
import { useBlock } from 'hooks/useBlock';
import { KOREAN_REGEX } from 'utils/constants';
import { ModalContext } from 'provider/ModalProvider';

export interface LayoutRegistrationContainerProps {
  regiKey?: string;
  type: 'add' | 'update' | 'error' | 'check';
  originRegiContainer: Array<RegistrationDataType[]>;
  onSubmit: (data: RegistrationDatasType, initRegiDatas?: () => void) => void;
  pageId: string;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const LayoutRegistrationContainer = React.memo(function LayoutRegistrationContainer({
  regiKey,
  type,
  originRegiContainer,
  onSubmit,
  pageId,
  onBlur,
  onFocus,
}: LayoutRegistrationContainerProps) {
  const location = useLocation();
  const [modalRadioState, setModalRadioState] = useRecoilState<ModalRadioDataType>(modalRadioData);
  const { modal_confirm } = useContext(ModalContext);
  const setBlockerState = useSetRecoilState(blockerData);
  const [originRegiDatas, setOriginRegiDatas] = useState<RegistrationDatasType>({});
  const [regiDatas, setRegiDatas] = useState<RegistrationDatasType>({});

  // Block 기능
  useBlock();

  useEffect(() => {
    const tmp_origin_regi_data: RegistrationDatasType = {};
    const tmp_regi_data: RegistrationDatasType = {};
    originRegiContainer.forEach(originRegiContainerDatas => {
      originRegiContainerDatas.forEach(originRegiContainerData => {
        const key = originRegiContainerData.key;
        tmp_origin_regi_data[key] = {
          ...originRegiContainerData,
        };
        tmp_regi_data[key] = {
          ...originRegiContainerData,
        };
      });
    });

    setOriginRegiDatas({ ...tmp_origin_regi_data });

    setRegiDatas({ ...tmp_regi_data });
  }, [originRegiContainer]);

  useEffect(() => {
    const obj = Object.keys(regiDatas);
    if (type === 'update' || type === 'error') {
      if (obj.length > 0) {
        const originCheck = Object.values(originRegiDatas).reduce((a: any, b: any) => {
          let value = '';
          if (b.value) {
            value += b.value.trim();
          }
          const res = typeof a == 'object' && a ? a.value.trim() + value : a.trim() + value;
          return res as unknown as RegistrationDataType;
        });
        const newCheck = Object.values(regiDatas).reduce((a: any, b: any) => {
          let value = '';
          if (b.value) {
            value += b.value.trim();
          }
          const res = typeof a == 'object' && a ? a.value.trim() + value : a.trim() + value;
          return res as unknown as RegistrationDataType;
        });
        if (originCheck !== newCheck) {
          setBlockerState({
            when: true,
            title: 'registering',
            target: '다른 메뉴로 이동하시겠습니까?',
          });
        } else {
          setBlockerState({
            when: false,
            title: '',
            target: '',
          });
        }
      }
    } else {
      if (obj.length > 0) {
        const nullCheck = Object.values(regiDatas).reduce((a: any, b: any) => {
          return typeof a == 'object' ? a.value.trim() + b.value.trim() : a.trim() + b.value.trim();
        });
        if (nullCheck) {
          setBlockerState({
            when: true,
            title: 'registering',
            target: '다른 메뉴로 이동하시겠습니까?',
          });
        } else {
          setBlockerState({
            when: false,
            title: '',
            target: '',
          });
        }
      }
    }
  }, [regiDatas, originRegiDatas]);

  const onChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, key: string) => {
      let value = e.target.value;
      const isKorean = KOREAN_REGEX.test(value);

      value = value.replace(/[-]/g, '');
      const num_value_list = ['phone', 'parent_phone'];
      for (const check of num_value_list) {
        if (key.includes(check)) {
          value = autoHypenPhone(value);
          if (value.length > 13) return;
        }
      }

      const brn_value_list = ['brn'];
      for (const check of brn_value_list) {
        if (key.includes(check)) {
          if (value.length >= 11) return;
          value = autoBrn(value);
        }
      }

      const tmp_regi_datas = { ...regiDatas };
      if (tmp_regi_datas[key].maxLength) {
        if (Number(tmp_regi_datas[key].maxLength) < value.length) return;
      }

      if (value.trim() === '') tmp_regi_datas[key].value = '';
      else tmp_regi_datas[key].value = value;

      if (tmp_regi_datas[key].valiType) {
        const vali_res = validation[`${tmp_regi_datas[key].valiType}`](value);
        tmp_regi_datas[key].error = !vali_res;
      }

      if (key === 'accountId') {
        if (isKorean) {
          tmp_regi_datas[key].errorText = '한글이 포함되었습니다.';
        } else {
          tmp_regi_datas[key].errorText = '';
        }
      }

      if (key === 'password') {
        if (isKorean) {
          tmp_regi_datas[key].errorText = '한글이 포함되었습니다.';
        } else if (value.length < 8) {
          tmp_regi_datas[key].errorText = '최소 8자 이상입니다.';
        } else {
          tmp_regi_datas[key].errorText = '영문 소문자, 숫자, 특수문자 등으로 조합하세요.';
        }
      }

      if (key === 'brn') {
        if (value.length < 12) {
          tmp_regi_datas[key].errorText = '사업자 등록 번호는 10자리로 입력해주세요.';
        } else if (value.length == 12) {
          tmp_regi_datas[key].errorText = '사업자 등록번호는 올바른 정보로 입력해야 합니다.';
        }
      }

      setRegiDatas({ ...tmp_regi_datas });
    },
    [regiDatas],
  );

  // 학생 수정 시, 클래스 클릭 한 경우의 메서드
  const onClickError = useCallback(
    (key: string) => {
      const tmp_regi_datas = { ...regiDatas };
      tmp_regi_datas[key].error = true;

      setRegiDatas({ ...tmp_regi_datas });
    },
    [regiDatas],
  );

  // modal radio update 시
  const updateValueRadio = useCallback(
    (modalRadioState: ModalRadioDataType) => {
      const tmp_regi_datas = { ...regiDatas };
      tmp_regi_datas[modalRadioState.id].id = String(modalRadioState.curValue.id);
      tmp_regi_datas[modalRadioState.id].value = String(modalRadioState.curValue.name);
      setRegiDatas(tmp_regi_datas);
    },
    [regiDatas],
  );

  const openModal = useCallback(
    (key: string, type?: 'checkbox' | 'radio') => {
      if (type === 'radio') {
        const tmp_modal_contents = (regiDatas[key].modalContents as Array<{ id: number | string; name: string }>).map(
          content => ({
            ...content,
          }),
        );

        const value_label = regiDatas[key].value;
        let value: { id: string | number; name: string } = { id: '', name: '' };
        if (value_label) {
          const fine_modal_item = tmp_modal_contents.find(content => content.name === value_label);
          if (fine_modal_item) {
            value = { ...fine_modal_item };
          }
        }

        setModalRadioState({
          ...modalRadioState,
          id: key,
          visible: true,
          subTitle: regiDatas[key].subTitle ? regiDatas[key].subTitle : '',
          title: regiDatas[key].modalTitle ?? '',
          contents: tmp_modal_contents,
          oldValue: value,
          curValue: value,
          updateValue: updateValueRadio,
        });
      }
    },
    [regiDatas, updateValueRadio],
  );

  const changeButtonCheck = useCallback(
    (id: number | string, key?: string) => {
      if (key) {
        let new_value = '';
        const tmp_regi_datas = { ...regiDatas };
        const new_button = tmp_regi_datas[key].button?.map(
          (buttonItem: { id: number | string; label: string; check: boolean }) => {
            const type = tmp_regi_datas[key].type;
            if (buttonItem.id === id) {
              const return_obj = { ...buttonItem, check: type === 'checkday' ? !buttonItem.check : true };
              if (type === 'checkday' && return_obj.check) {
                new_value += new_value.length ? `, ${return_obj.label}` : return_obj.label;
              } else if (type === 'buttongroup') {
                new_value = return_obj.label;
              }
              return return_obj;
            } else {
              if (type === 'buttongroup') {
                return { ...buttonItem, check: false };
              } else {
                if (type === 'checkday' && buttonItem.check) {
                  new_value += new_value.length ? `, ${buttonItem.label}` : buttonItem.label;
                }
                return { ...buttonItem };
              }
            }
          },
        );
        tmp_regi_datas[key].button = new_button;
        tmp_regi_datas[key].value = new_value;
        setRegiDatas(tmp_regi_datas);
      }
    },
    [regiDatas],
  );

  const setRegistrationInput = useCallback(
    (regiData: RegistrationDataType): React.ReactNode => {
      // input 생성기
      if (regiData) {
        if (regiData.type === 'text') {
          return (
            <InputTextContainer
              onChangeValue={(e: React.ChangeEvent<HTMLInputElement>) => onChangeValue(e, regiData.key)}
              onBlur={onBlur}
              onFocus={onFocus}
              {...regiData}
            />
          );
        } else if (regiData.type === 'select') {
          const onClickInputSelect =
            type == 'update' && ['class_name'].includes(regiData.key)
              ? () => onClickError(regiData.key)
              : () => openModal(regiData.key, regiData.modalType);
          return (
            <InputSelectContainer
              onClick={onClickInputSelect}
              onChangeValue={(e: React.ChangeEvent<HTMLInputElement>) => onChangeValue(e, regiData.key)}
              {...regiData}
            />
          );
        } else if (regiData.type === 'buttongroup') {
          return (
            <>
              {regiData.title ? (
                <InputTitleContainer title={regiData.title} required={regiData.required} sx={{ mb: 0 }} />
              ) : null}
              <ButtonGroupContainer
                contents={regiData.button ? regiData.button : []}
                onCheck={changeButtonCheck}
                contentsKey={regiData.key}
                checkColor='purple_5'
              />
            </>
          );
        } else if (regiData.type === 'postbutton') {
          return (
            <ButtonContainer variant='outlined' color='green' onClick={openPost} disabled={regiData.disabled}>
              {regiData.buttonTitle}
            </ButtonContainer>
          );
        } else if (regiData.type === 'post' && regiData.visible) {
          return (
            <PostWrapper>
              <PostCloseButton>
                <FaTimes size={15} onClick={onClickClosePostButton} />
              </PostCloseButton>
              <DaumPostcodeEmbed
                style={{ ...postCodeStyle, position: 'relative' }}
                autoClose
                onComplete={onCompletePost}
              />
            </PostWrapper>
          );
        } else {
          return <></>;
        }
      }
    },
    [regiDatas],
  );

  const onCompletePost = useCallback(
    (data: Address) => {
      const tmp_regi_datas = { ...regiDatas };
      if (data.userSelectedType === 'R') {
        // 도로명
        tmp_regi_datas['businessAddress'].value = data.address;
      } else {
        // 지번
        tmp_regi_datas['businessAddress'].value = data.jibunAddress;
      }
      tmp_regi_datas['zipCode'].value = data.zonecode;
      tmp_regi_datas['post'].visible = false;
      setRegiDatas({ ...tmp_regi_datas });
    },
    [regiDatas],
  );

  const onClickClosePostButton = useCallback(() => {
    const key = 'post';
    const tmp_regi_data = { ...regiDatas };
    tmp_regi_data[key].visible = false;

    setRegiDatas({ ...tmp_regi_data });
  }, [regiDatas]);

  const openPost = useCallback(() => {
    const key = 'post';
    const tmp_regi_data = { ...regiDatas };
    tmp_regi_data[key].visible = true;

    setRegiDatas({ ...tmp_regi_data });
  }, [regiDatas]);

  const onContentSubmit = useCallback(async () => {
    if (['add', 'update', 'check'].includes(type)) {
      const path = location.pathname;

      // post할 데이터 전처리 ex) (010-1234-5678 -> 01012345678)
      const keys = Object.keys(regiDatas);
      const submit_data = { ...regiDatas };
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        submit_data[key] = { ...regiDatas[key] };
      }
      for (const key of [...Object.keys(regiDatas)]) {
        if (type === 'update' && submit_data[key].value == originRegiDatas[key].value) {
          delete submit_data[key];
          continue;
        }
        for (const check_key of ['phone', 'cell']) {
          if (key.includes(check_key)) {
            submit_data[key].value = submit_data[key].value.replace(/[-]/g, '');
          }
        }
      }

      // 임시 처리
      const modal_button_type = type === 'add' ? 'normal' : 'edit';
      let modal_type = `${type}_contents`;
      const onSuccess = () => {
        onSubmit(submit_data);
      };

      let title = '';
      if (path.indexOf('teacher') >= 0) {
        title = type === 'add' ? '관리자를' : '관리자 정보를';
      } else if (path.indexOf('class') >= 0) {
        title = type === 'add' ? '클래스를' : '클래스 정보를';
      } else if (path.indexOf('list') >= 0) {
        title = type === 'add' ? '학생을' : '학생 정보를';
      } else if (path.indexOf('admininfo') >= 0) {
        title = '관리자 정보를';
      } else if (pageId === 'evidence') {
        title = '';
        modal_type = 'registration_receipt';
      }

      setBlockerState({
        when: false,
        title: '',
        target: '',
      });

      const onCancel = () => {
        setBlockerState({
          when: true,
          title: 'registering',
          target: '다른 메뉴로 이동하시겠습니까?',
        });
      };

      modal_confirm.openModalConfirm(modal_button_type, modal_type, onSuccess, onCancel, title);
    } else {
      const submit_data = { ...regiDatas };
      for (const key of [...Object.keys(regiDatas)]) {
        for (const check_key of ['phone', 'cell']) {
          if (key.includes(check_key)) {
            submit_data[key].value = submit_data[key].value.replace(/[-]/g, '');
          }
        }
      }
      onSubmit(submit_data, initRegiDatas);
    }
  }, [regiDatas, type, regiKey]);

  const compareRegiDatasWithOrigin = useCallback(
    (
      originRegiDatas: RegistrationDatasType,
      newRegiDatas: RegistrationDatasType,
      type: 'add' | 'update' | 'error' | 'check',
    ): boolean => {
      let require_num = 0;
      let comp_require_num = 0;
      let edit_num = 0;
      let error_num = 0;
      const keys = Object.keys(originRegiDatas);
      keys.forEach(key => {
        if (originRegiDatas[key].required) require_num++;
        if (originRegiDatas[key].required && newRegiDatas[key].value && newRegiDatas[key].value.length > 0)
          comp_require_num++;
        if (originRegiDatas[key].value != newRegiDatas[key].value) edit_num++;
        if (newRegiDatas[key].error && newRegiDatas[key].type !== 'select') error_num++;
      });

      if (require_num == comp_require_num) {
        if (edit_num > 0) {
          if (error_num > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          if (type === 'update') {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
    },
    [],
  );

  const checkError = useMemo(() => {
    const keys = [...Object.keys(regiDatas)];
    for (const key of keys) {
      if (regiDatas[key].type !== 'select') {
        const check = regiDatas[key].error;
        if (check) return true;
      }
    }
    return false;
  }, [regiDatas]);

  const regibtnDisabled = useMemo(() => {
    const keys = Object.keys(regiDatas).length;
    if (keys > 0) {
      return compareRegiDatasWithOrigin(originRegiDatas, regiDatas, type) || checkError;
    } else {
      return true;
    }
  }, [regiDatas, checkError]);

  const initRegiDatas = useCallback(() => {
    const tmp_regi_data: RegistrationDatasType = {};
    originRegiContainer.forEach(originRegiContainerDatas => {
      originRegiContainerDatas.forEach(originRegiContainerData => {
        const key = originRegiContainerData.key;
        tmp_regi_data[key] = {
          ...originRegiContainerData,
        };
      });
    });
    setRegiDatas({ ...tmp_regi_data });
  }, [originRegiContainer]);

  const props_obj = {
    type,
    originRegiContainer,
    regiDatas,
    setRegistrationInput,
    onSubmit: onContentSubmit,
    regibtnDisabled,
  };

  return <LayoutRegistrationUI {...props_obj} />;
});
