import { atom, SetterOrUpdater } from 'recoil';

interface ModalManual2Type {
  visible: boolean;
  title: string;
  subTitle: string;
  contents: Array<{ title: string; content: string[] }>;
  callback: () => void;
  buttonLabel: string;
}

export const modalManual2State = atom<ModalManual2Type>({
  key: 'modalManual2',
  default: {
    visible: false,
    title: '',
    subTitle: '',
    contents: [],
    buttonLabel: '',
    callback: () => {
      return;
    },
  },
});

interface ModalManual2Setter {
  setModalManual2: SetterOrUpdater<ModalManual2Type>;
}

export const openModalManual2 = (
  { setModalManual2 }: ModalManual2Setter,
  options: Omit<ModalManual2Type, 'visible'>,
) => {
  setModalManual2({
    visible: true,
    ...options,
  });
};

export const closeModalManual2 = ({ setModalManual2 }: ModalManual2Setter) => {
  setModalManual2({
    visible: false,
    title: '',
    subTitle: '',
    contents: [],
    buttonLabel: '',
    callback: () => {
      return;
    },
  });
};
