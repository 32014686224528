import React from 'react';
import { Modal, LoadingDots } from './LoadingCircleSpinnerStyles';
import { Box, Fade, Grow } from '@mui/material';
import LoadingSpinner from 'assets/images/LoadingCircleSpinner/CircleSpinner.svg';
interface LoadingCircleSpinnerPresentersProps {
  visible: boolean;
  ballCount: number;
  content: string;
  zIndex: string;
  onClose: () => void;
  timeout?: { enter: number; exit: number };
  thumbnailUrl?: string;
  isSpeak?: boolean;
  active?: boolean;
}
import icon_close from 'assets/images/close.png';
import { RealisticConfettiContainer } from '../RealisticConfetti/RealisticConfetti.Container';

export const LoadingCircleSpinnerPresenters = React.memo(function LoadingCircleSpinnerPresenters(
  props: LoadingCircleSpinnerPresentersProps,
) {
  const { visible, ballCount, content, timeout, zIndex, thumbnailUrl, onClose, isSpeak, active } = props;

  return (
    <Modal open={visible} closeAfterTransition sx={{ zIndex }}>
      <>
        <Fade in={visible} timeout={timeout ? timeout : { enter: 225, exit: thumbnailUrl ? 0 : 195 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1302,
              '& > img': {
                width: '7.5rem',
              },
            }}
          >
            {thumbnailUrl ? (
              <Box
                component={'img'}
                src={icon_close}
                // className='dalay-fade-in'
                sx={{
                  width: '4.5rem !important',
                  height: '4.5rem',
                  position: 'fixed',
                  right: '1rem',
                  top: '1rem',
                  cursor: 'pointer',
                  pointerEvents: 'auto !important',
                }}
                onClick={onClose}
              ></Box>
            ) : null}
            {thumbnailUrl ? (
              <Box
                className='dalay-fade-in'
                sx={{
                  boxShadow: thumbnailUrl ? 'rgba(17, 17, 26, 0.5) 8px 8px 16px' : 'none',
                  width: isSpeak ? '34rem !important' : '17rem !important',
                  '@media (min-width: 1265px) and (max-width: 1905px)': {
                    width: isSpeak ? '37rem !important' : '20rem !important',
                  },

                  '@media (min-width: 961px) and (max-width: 1264px)': {
                    width: isSpeak ? '37rem !important' : '20rem !important',
                  },

                  '@media (min-width: 769px) and (max-width: 960px)': {
                    width: isSpeak ? '40rem !important' : '18rem !important',
                  },

                  '@media (max-width: 768px)': {
                    width: isSpeak ? '34rem !important' : '14rem !important',
                  },
                }}
                component={'img'}
                src={thumbnailUrl}
                alt={'nex_boot_thumbnail'}
              />
            ) : (
              <img src={LoadingSpinner} alt='LoadingSpinner' />
            )}
            {/* <LoadingDots ballCount={ballCount} /> */}
            <Box
              sx={{
                color: 'white !important',
                fontSize: thumbnailUrl ? '1.35rem' : '1rem',
                marginTop: thumbnailUrl ? '3.5rem' : '0.9rem',
                marginBottom: thumbnailUrl ? '-3.5rem' : '0rem',
                textAlign: 'center',
                lineHeight: '1.5',

                '@media (min-width: 1265px) and (max-width: 1905px)': {
                  fontSize: thumbnailUrl ? '1.5rem' : '1rem',
                  marginTop: thumbnailUrl ? '3.5rem' : '0.9rem',
                },

                '@media (min-width: 961px) and (max-width: 1264px)': {
                  fontSize: thumbnailUrl ? '1.35rem' : '1rem',
                  marginTop: thumbnailUrl ? '2.5rem' : '0.9rem',
                  marginBottom: thumbnailUrl ? '-1.5rem' : '0rem',
                },

                '@media (min-width: 769px) and (max-width: 960px)': {
                  fontSize: thumbnailUrl ? '1.75rem' : '1rem',
                  marginTop: thumbnailUrl ? '2.5rem' : '0.9rem',
                  marginBottom: thumbnailUrl ? '-1.5rem' : '0rem',
                },

                '@media (max-width: 768px)': {
                  fontSize: thumbnailUrl ? '1.5rem' : '1rem',
                  marginTop: thumbnailUrl ? '2rem' : '0.9rem',
                  marginBottom: thumbnailUrl ? '-1.5rem' : '0rem',
                },
              }}
              className={thumbnailUrl ? 'dalay-fade-in' : ''}
              dangerouslySetInnerHTML={{ __html: content }}
            ></Box>
          </Box>
        </Fade>
        <RealisticConfettiContainer />
      </>
    </Modal>
  );
});
