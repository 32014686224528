import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'SUIT',
  },
  palette: {
    boxShadow: {
      contrastText: '#fff',
      main: '#00000026 0 2.5px 10px',
    },
    red: {
      contrastText: '#fff',
      main: '#e9121d',
    },
    red_2: {
      contrastText: '#fff',
      main: '#f44336',
    },
    green: {
      contrastText: '#fff',
      main: '#1bd05b',
    },
    light_yellow_green: {
      contrastText: '#fff',
      main: '#f7f9e0',
    },
    yellow_green: {
      contrastText: '#fff',
      main: '#32ea88',
    },
    turquoise: {
      contrastText: '#fff',
      main: '#2BD1B1',
    },
    light_orange: {
      contrastText: '#fff',
      main: '#ffafa4',
    },
    light_orange_2: {
      contrastText: '#fff',
      main: '#ffc663',
    },
    orange: {
      contrastText: '#fff',
      main: '#fc521a',
    },
    orange_2: {
      contrastText: '#fff',
      main: '#ffa025',
    },
    deep_orange: {
      contrastText: '#fff',
      main: '#ff6c59',
    },
    light_yellow: {
      contrastText: '#fff',
      main: '#fff9ea',
    },
    light_yellow_2: {
      contrastText: '#fff',
      main: '#fff3d6',
    },
    yellow_1: {
      contrastText: '#fff',
      main: '#fcbe00',
    },
    yellow_2: {
      contrastText: '#fff',
      main: '#ffe100',
    },
    yellow_3: {
      contrastText: '#000',
      main: '#fecf45',
    },
    yellow_4: {
      contrastText: '#ff8900',
      main: '#fef6d2',
    },
    dark_blue: {
      contrastText: '#fff',
      main: '#b2c7da',
    },
    blue: {
      contrastText: '#fff',
      main: '#00bfff',
    },
    blue_2: {
      contrastText: '#fff',
      main: '#49a3ff',
    },
    deep_blue: {
      contrastText: '#fff',
      main: '#2f6bc1',
    },
    sky_blue: {
      contrastText: '#fff',
      main: '#e3f8ff',
    },
    deep_sky_blue: {
      contrastText: '#fff',
      main: '#71c5ff',
    },
    purple: {
      contrastText: '#fff',
      main: '#4620e9',
    },
    purple_2: {
      contrastText: '#fff',
      main: '#cc3fce',
    },
    purple_3: {
      contrastText: '#fff',
      main: '#6b53f4',
    },
    purple_4: {
      contrastText: '#fff',
      main: '#8212fd',
    },
    purple_5: {
      contrastText: '#4620e9',
      main: '#f4f4ff',
    },
    purple_6: {
      contrastText: '#fff',
      main: '#6b20e9',
    },
    light_purple: {
      contrastText: '#fff',
      main: '#efe5ff',
    },
    light_purple_2: {
      contrastText: '#fff',
      main: '#f4eeff',
    },
    light_purple_3: {
      contrastText: '#fff',
      main: '#f8f6fe',
    },
    gray: {
      contrastText: '#fff',
      main: '#9f9f9f',
    },
    light_gray: {
      contrastText: '#999',
      main: '#f5f5f5',
    },
    gray_1: {
      contrastText: '#fff',
      main: '#9f9f9f',
    },
    gray_2: {
      contrastText: '#fff',
      main: '#dbdbdb',
    },
    gray_3: {
      contrastText: '#fff',
      main: '#ebebeb',
    },
    gray_4: {
      contrastText: '#000',
      main: '#f0f0f0',
    },
    gray_5: {
      contrastText: '#fff',
      main: '#f8f9fa',
    },
    gray_6: {
      contrastText: '#fff',
      main: '#efefef',
    },
    gray_black: {
      contrastText: '#9f9f9f',
      main: '#e8e8e8',
    },
    gray_off: {
      contrastText: '#fff',
      main: '#c8c8c8',
    },
    white: {
      contrastText: '#000',
      main: '#fff',
    },
    white_2: {
      contrastText: '#000',
      main: '#f5fbff',
    },
    white_3: {
      contrastText: '#000',
      main: '#f6f6f6',
    },
    white_4: {
      contrastText: '#000',
      main: '#fafcff',
    },
    white_5: {
      contrastText: '#606060',
      main: '#fff',
    },
    dark: {
      contrastText: '#fff',
      main: '#000',
    },
    black: {
      contrastText: '#fff',
      main: '#000',
    },
    black_2: {
      contrastText: '#fff',
      main: '#404040',
    },
    black_3: {
      contrastText: '#fff',
      main: '#495057',
    },
    black_4: {
      contrastText: '#fff',
      main: '#777',
    },
    cyan: {
      contrastText: '#fff',
      main: '#3fbace',
    },
    cyan_2: {
      contrastText: '#fff',
      main: '#67ced5',
    },
    light_pink: {
      contrastText: '#000',
      main: '#fff3f1',
    },
    pink: {
      contrastText: '#fff',
      main: '#ff6060',
    },
    brown: {
      contrastText: '#000',
      main: '#736b16',
    },
    navy: {
      contrastText: '#FFF',
      main: '#1a2a3d',
    },
    manager_green: {
      contrastText: '#FFF',
      main: '#0fdc61',
    },
    manager_yellow: {
      contrastText: '#FFF',
      main: '#ffc520',
    },
    manager_blue: {
      contrastText: '#FFF',
      main: '#29d0ff',
    },
    manager_red: {
      contrastText: '#FFF',
      main: '#ff382a',
    },
    card_blue: {
      contrastText: '#fff',
      main: '#6b90d2',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 960,
      xl: 1264,
      xxl: 1904,
    },
  },
  spacing: 4,
});

export default theme;
