import dayjs from 'dayjs';
import { ModalBillUI } from './ModalBill.Presenter';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ModalBillType, closeModalBill, modalBillState } from 'recoil/common/modalbill';
import { profilesState } from 'recoil/common/profiles';
import { accountInfoState } from 'recoil/model/accountInfo';
import { fetchGetApi } from 'utils/api';
import {
  ALL_PERSONAL_PRODUCT_PRICES,
  GRAMMAR_PERSONAL_PRODUCT_PRICES,
  SPEAK_PERSONAL_PRODUCT_PRICES,
  VOCA_PERSONAL_PRODUCT_PRICES,
} from 'utils/constants';
import { getPersonalProductPackageAndPeriod } from 'utils/tools';

export function ModalBillContainer() {
  const [modalBill, setModalBill] = useRecoilState<ModalBillType>(modalBillState);
  const [applyBenefit, setApplyBenefit] = useState('');
  const accountInfo = useRecoilValue(accountInfoState);
  const profileStateData = useRecoilValue(profilesState);

  useEffect(() => {
    if (accountInfo) validateRecommcode();
  }, [profileStateData.recommCode, accountInfo]);

  const validateRecommcode = async () => {
    if (!profileStateData.recommCode) return;
    //! 추천인 코드 유효성 및 헤택 검증
    const url = `/etc/recommcode/check?recommCode=${profileStateData.recommCode}`;
    const res = await fetchGetApi(url);
    if (res.result) {
      if (accountInfo?.productType == 'A003Y') {
        setApplyBenefit(res.data.benefit);
      }
    } else {
      setApplyBenefit('');
    }
  };

  const title = useMemo(() => {
    return '오래영어 클래스 수강신청';
  }, []);

  const onClose = useCallback(() => {
    closeModalBill({ setModalBill });
  }, []);

  const hostName = useMemo(() => {
    return process.env.NODE_ENV == 'development' ? 'longhome.irontrain.co.kr' : 'home.longedu.co.kr';
  }, []);

  const addMonth = useMemo(() => {
    if (applyBenefit.includes('AP')) {
      const string_benefit = applyBenefit.replace('AP', '');
      let period_unit = 0;

      switch (string_benefit[1]) {
        case 'Y':
          period_unit = 12;
          break;
        case 'M':
          period_unit = 1;
          break;
      }

      return +string_benefit[0] * period_unit;
    } else {
      return 0;
    }
  }, [applyBenefit]);

  const minusPrice = useMemo(() => {
    let number_benefit = 0;
    if (applyBenefit.includes('MP')) {
      const string_benefit = applyBenefit.replace('MP', '');
      number_benefit = Number(string_benefit.replace('T', ''));

      switch (string_benefit[string_benefit.length - 1]) {
        case 'T':
          number_benefit = number_benefit * 1000;
          break;
      }
    }

    return number_benefit;
  }, [applyBenefit]);

  const completedPaymentInfo = useMemo(() => {
    if (accountInfo) {
      const product_info = getPersonalProductPackageAndPeriod(accountInfo.productType);
      const product_prices =
        product_info.package == 'all'
          ? ALL_PERSONAL_PRODUCT_PRICES
          : product_info.package == 'speak'
          ? SPEAK_PERSONAL_PRODUCT_PRICES
          : product_info.package == 'voca'
          ? VOCA_PERSONAL_PRODUCT_PRICES
          : GRAMMAR_PERSONAL_PRODUCT_PRICES;
      const product_price_info = product_prices[product_info.period as 'M' | 'H' | 'Y'];
      const diffMonth = dayjs(accountInfo.productEndDate).diff(dayjs(accountInfo.productStartDate), 'months') + 1;
      const startDate = dayjs(accountInfo.productStartDate).date();

      let benefitFactor = 1;

      if (product_info.period == 'Y') {
        //* 1년

        //? 기간 혜택 처리
        if (startDate <= 5) {
          benefitFactor = 13;
        } else {
          benefitFactor = 12;
        }

        if (addMonth > 0) benefitFactor += addMonth;
      } else if (product_info.period == 'H') {
        //! 6개월
        if (diffMonth == 8) {
          benefitFactor = 7;
        } else if (diffMonth == 7) {
          if (startDate <= 5) {
            benefitFactor = 7;
          } else {
            benefitFactor = 6;
          }
        } else {
          benefitFactor = 6;
        }
      } else {
        //! 1개월
        if (diffMonth == 3) {
          benefitFactor = 2;
        } else if (diffMonth == 2) {
          if (startDate <= 5) {
            benefitFactor = 2;
          } else {
            benefitFactor = 1;
          }
        } else {
          benefitFactor = 1;
        }
      }

      const return_value = [
        {
          label: '정가',
          value: `${product_price_info.full.toLocaleString()}원`,
        },
        {
          label: '얼리버드 할인',
          value: `${product_price_info.earlybird.toLocaleString()}원`,
        },
        {
          label: '패키지 할인',
          value: `${product_price_info.pkg.toLocaleString()}원`,
        },
      ];

      if (product_info.period == 'Y' && minusPrice > 0) {
        return_value.push({
          label: '추천코드 할인 금액',
          value: `${minusPrice.toLocaleString()}원`,
        });
      }

      return_value.push(
        {
          label: '총 혜택 금액',
          value: `${accountInfo.cost}원`,
        },
        {
          label: '월 혜택 금액',
          value: `${Math.round(Number(accountInfo.cost.replace(/,/g, '')) / benefitFactor).toLocaleString()}원`,
        },
      );

      return return_value;
    } else return [];
  }, [accountInfo, addMonth, minusPrice]);

  const props = {
    visible: modalBill.visible,
    onClose,
    title,
    accountInfo,
    customerId: profileStateData.customer_id,
    hostName,
    completedPaymentInfo,
  };

  if (modalBill.visible && accountInfo) return <ModalBillUI {...props} />;

  return null;
}
