import { Box, styled } from '@mui/system';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  CartesianGrid,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  Bar,
  TooltipProps,
} from 'recharts';
import { useRecoilState, useRecoilValue } from 'recoil';
import { deviceState } from '../../../recoil/common/device';
import theme from '../../../theme';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

interface CustomLineChartProps {
  data: any[];
  tab: number;
  useTootip: boolean;
}

const StyledLineChart = styled(ComposedChart)(props => ({
  '& > svg': {
    overflow: 'visible',
  },
}));

const StyledTooltipBox = styled(Box)(props => ({
  fontSize: '1.1rem',
  padding: '1rem',
  border: '1px solid rgb(204, 204, 204)',
  backgroundColor: 'white',
}));

const CustomLineChart = (props: CustomLineChartProps) => {
  const data = props.data;
  const tab = props.tab;
  const useTootip = props.useTootip;
  const [yTickCount, setYTickCount] = useState(11);
  const [realVerticalGrid, setRealVerticalGrid] = useState<{ [key: string]: number }[]>([]);
  const [verticalGrid, setVerticalGrid] = useState<{ [key: string]: number }[]>([]);
  const [lineStart, setLineStart] = useState(0);
  const tmp_vertical_Grid = useRef<{ [key: string]: number }[]>([]);
  const check_vertical_Grid = useRef<{ [key: string]: number }[]>([]);

  const [deviceStateData, setDeviceStateData] = useRecoilState<DeviceType>(deviceState);
  const { screen_width, screen_height, is_mobile } = deviceStateData;

  const modifiedData = useMemo(() => {
    if (data.length > 1) {
      let avg_score = 0;
      const new_datas = data.map((item, index) => {
        if (index > 0 && item.score === data[index - 1].score) {
          avg_score += Number(item.score) + 0.001;
          return { ...item, score: Number(item.score) + 0.001 };
        }
        avg_score += Number(item.score);
        return item;
      });
      avg_score = avg_score / data.length;
      new_datas.push({ ...data[data.length - 1], score: Number(avg_score.toFixed(0)), subject: 'end' });
      new_datas.unshift({ ...data[data.length - 1], score: Number(avg_score.toFixed(0)), subject: 'start' });
      return new_datas;
    } else {
      const new_datas = data.map((item, index) => {
        if (index > 0 && item.score === data[index - 1].score) {
          return { ...item, score: Number(item.score) + 0.001 };
        }
        return item;
      });

      if (data.length == 1) {
        new_datas.push({ ...data[0], score: Number(data[0].score) + 0.001, subject: 'end' });
        new_datas.unshift({ ...data[0], score: Number(data[0].score) - 0.001, subject: 'start' });
      }

      return new_datas;
    }
  }, [data]);

  useEffect(() => {
    if (screen_height > 796) setYTickCount(11);
    else if (screen_height > 599) setYTickCount(6);
    else setYTickCount(5);
  }, [screen_height]);

  const CustomXTick = (props: any) => {
    const { x, y, payload } = props;
    const isToday = payload.value === dayjs().format('YY.MM.DD');

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={'10%'}
          fill={isToday ? theme.palette.purple.main : '#a9a9a9'}
          fontSize={'1.1rem'}
          textAnchor='middle'
        >
          {isToday ? 'Today' : payload.value}
        </text>
        <circle cx={0} cy={-7} r={'0.525rem'} fill={theme.palette.purple.main} stroke='#ffffff' strokeWidth={2} />
      </g>
    );
  };

  const CustomYTick = ({ x, y, payload, index }: any) => {
    let unit = '';
    if (yTickCount == 11) {
      if (index == 0 || index % 2 == 1) {
        return null;
      } else if (index == 10) {
        switch (tab) {
          case -1:
            unit = '(점)';
            break;
          case 0:
          case 1:
            unit = '(개)';
            break;
          case 2:
            unit = '%';
            break;
          case 3:
            unit = '(분)';
            break;
        }
      }
    } else if (yTickCount == 6) {
      if (index == 0) {
        return null;
      } else if (index == 5) {
        switch (tab) {
          case -1:
            unit = '(점)';
            break;
          case 0:
          case 1:
            unit = '(개)';
            break;
          case 2:
            unit = '%';
            break;
          case 3:
            unit = '(분)';
            break;
        }
      }
    } else {
      if (index == 0) {
        return null;
      } else if (index == 4) {
        switch (tab) {
          case -1:
            unit = '(점)';
            break;
          case 0:
          case 1:
            unit = '(개)';
            break;
          case 2:
            unit = '%';
            break;
          case 3:
            unit = '(분)';
            break;
        }
      }
    }
    return (
      <text x={0} y={y} fill='#a9a9a9' fontSize={'1.1rem'} textAnchor='start'>
        {payload.value}
        {unit}
      </text>
    );
  };

  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    if (label == 'start' || label == 'end') return null;
    if (active && payload && payload.length) {
      const schedule_name = payload[0].payload.schedule_name;
      const unit_name = payload[0].payload.unit_name;
      const mode = payload[0].payload.mode;

      return (
        <StyledTooltipBox>
          <Box>{schedule_name}</Box>
          <Box>{unit_name}</Box>
          <Box
            sx={{
              color:
                mode == '기본학습'
                  ? '#31c82a'
                  : mode == '심화학습'
                  ? '#41b3bd'
                  : mode == '문법학습'
                  ? '#ffa139'
                  : '#4620e9',
            }}
          >
            {mode}
          </Box>
        </StyledTooltipBox>
      );
    }

    return null;
  };

  useEffect(() => {
    if (check_vertical_Grid.current.length == 1) {
      console.log('1');
      if (
        check_vertical_Grid.current[0]['x'] != tmp_vertical_Grid.current[0]['x'] ||
        check_vertical_Grid.current[0]['y'] != tmp_vertical_Grid.current[0]['y']
      ) {
        setRealVerticalGrid([{ x: check_vertical_Grid.current[0]['x'], y: check_vertical_Grid.current[0]['y'] }]);
      }
    } else {
      console.log('2');
      setRealVerticalGrid([...verticalGrid]);
      tmp_vertical_Grid.current = [];
      check_vertical_Grid.current = [];
    }
  }, [verticalGrid]);

  const responsiveLineY = (y: number): number => {
    if (1903 >= screen_width && screen_width >= 1264) {
      return y + 5;
    } else if (1263 >= screen_width && screen_width >= 960) {
      return y + 4;
    } else if (959 >= screen_width && screen_width >= 600) {
      return y + 3;
    } else if (599 >= screen_width) {
      return y + 3;
    }

    return y + 6;
  };

  const responsiveLineStart = (height: number): number => {
    if (1903 >= screen_width && screen_width >= 1264) {
      return height + 1;
    } else if (1263 >= screen_width && screen_width >= 960) {
      return height + 1;
    } else if (959 >= screen_width && screen_width >= 600) {
      return height + 2;
    } else if (599 >= screen_width) {
      return height + 3;
    }

    return height - 1;
  };

  const compareVerticalGrid = () => {
    for (let i = 0; i < check_vertical_Grid.current.length; i++) {
      const position = check_vertical_Grid.current[i];
      const real_position = realVerticalGrid[i];
      if (position.x == real_position.x && position.y == real_position.y) {
        continue;
      } else {
        return false;
      }
    }
    return true;
  };

  const settingFn = () => {
    setVerticalGrid([...check_vertical_Grid.current]);
    setRealVerticalGrid([...check_vertical_Grid.current]);
    setTimeout(() => {
      const recharts_cartesian_grid_horizontal = document.querySelector('.recharts-cartesian-grid-horizontal');
      if (recharts_cartesian_grid_horizontal) {
        const line = recharts_cartesian_grid_horizontal.childNodes[0] as Element;
        if (line) {
          const height = responsiveLineStart(Number(line.getAttribute('height')));
          setLineStart(height);
        }
      }
    }, 0);
  };

  const CustomLineLabel = ({ x, y, payload, index }: any) => {
    if (modifiedData.length > 1 && index == modifiedData.length - 1) {
      check_vertical_Grid.current[index] = { x, y: responsiveLineY(y) };
    } else {
      check_vertical_Grid.current[index] = { x, y: responsiveLineY(y + 2) };
    }

    if (!tmp_vertical_Grid.current[index]) {
      if (modifiedData.length > 1 && index == modifiedData.length - 1) {
        tmp_vertical_Grid.current[index] = { x, y: responsiveLineY(y) };
      } else {
        tmp_vertical_Grid.current[index] = { x, y: responsiveLineY(y + 2) };
      }
      if (tmp_vertical_Grid.current.length == modifiedData.length) {
        if (verticalGrid.length == 0 && realVerticalGrid.length == 0) {
          settingFn();
        } else {
          if (compareVerticalGrid()) {
            tmp_vertical_Grid.current = [];
            check_vertical_Grid.current = [];
          } else {
            settingFn();
          }
        }
      }
    }
    return null;
  };

  return (
    <ResponsiveContainer width='95%' height='80%'>
      <StyledLineChart data={modifiedData} style={{ overflow: 'visible' }}>
        <CartesianGrid vertical={false} />
        <Line
          type='monotone'
          dataKey='score'
          stroke='url(#colorGradient)'
          strokeWidth={'0.65rem'}
          dot={false}
          animationDuration={0}
          strokeLinecap={'round'}
          activeDot={false}
          connectNulls={true}
          label={<CustomLineLabel />}
        />
        <XAxis dataKey='subject' axisLine={false} tickLine={false} tick={<CustomXTick />} />
        <YAxis
          tickCount={yTickCount}
          tick={<CustomYTick />}
          interval='preserveStartEnd'
          axisLine={false}
          tickLine={false}
          allowDecimals={false}
          max={tab == -1 || tab == 3 ? 100 : undefined}
        />
        {useTootip ? <Tooltip cursor={false} content={<CustomTooltip />} /> : null}
        <defs>
          <linearGradient id='colorGradient' x1='0' y1='0' x2='1' y2='0'>
            <stop offset='0%' stopColor='#4a7cff' />
            <stop offset='50%' stopColor='#3325ff' />
            <stop offset='100%' stopColor='#9745ec' />
          </linearGradient>
          <filter id='lineShadow' filterUnits='userSpaceOnUse'>
            <feGaussianBlur cy='10' in='SourceGraphic' stdDeviation='5' />
          </filter>
        </defs>
        {data.length
          ? realVerticalGrid.length
            ? realVerticalGrid.map((line, index) => {
                if (realVerticalGrid.length > 1 && index == realVerticalGrid.length - 1) return null;
                if (index === 0) return null;
                if (lineStart <= line['y']) return null;
                else
                  return (
                    <line
                      key={line['x']}
                      x1={line['x']}
                      y1={lineStart}
                      x2={line['x']}
                      y2={line['y']}
                      stroke={'#ccc'}
                    ></line>
                  );
              })
            : null
          : null}
        <Line
          type='monotone'
          dataKey='score'
          stroke='url(#colorGradient)'
          strokeWidth={'0.9rem'}
          dot={false}
          animationDuration={0}
          strokeLinecap={'round'}
          activeDot={false}
          connectNulls={true}
          style={{ transform: 'translateY(3vh)', opacity: '0.25' }}
          filter='url(#lineShadow)'
        />
      </StyledLineChart>
    </ResponsiveContainer>
  );
};

export default CustomLineChart;
