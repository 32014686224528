import { styled, Box, Typography, TextField, IconButton } from '@mui/material';
import { BoxProps } from '@mui/system';
import ButtonContainer from 'components/button/Button';

export const LayoutFind = styled(Box)({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fafcff',
  padding: '0 2rem',
});

interface MobileProps extends BoxProps {
  is_mobile: number;
}

export const FindWrap = styled(Box)<MobileProps>(({ theme, is_mobile }) => {
  if (is_mobile == 1) {
    //! is_mobile
    return {
      position: 'relative',
      width: '100%',
      maxWidth: '42rem',
      height: '28rem',
      padding: '5rem 7rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.white.main,
      border: '1px solid',
      borderColor: theme.palette.gray_4.main,
      borderRadius: 4,

      //! scale 처리
      '@media (min-width: 1265px) and (max-width: 1905px)': {
        transform: 'scale(1.6)',
      },

      '@media (min-width: 961px) and (max-width: 1264px)': {
        transform: 'scale(1.4)',
      },

      '@media (min-width: 769px) and (max-width: 960px)': {
        transform: 'scale(1.8)',
      },

      '@media (min-width: 600px) and (max-width: 768px)': {
        transform: 'scale(1.5)',
      },

      '@media (min-width: 520px) and (max-width: 599px)': {
        transform: 'scale(1.1)',
      },
    };
  } else {
    return {
      position: 'relative',
      width: '100%',
      maxWidth: '42rem',
      height: '28rem',
      padding: '5rem 7rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.white.main,
      border: '1px solid',
      borderColor: theme.palette.gray_4.main,
      borderRadius: 4,

      //! scale 처리
      '@media (min-width: 1266px) and (max-width: 1905px)': {
        transform: 'scale(1.6)',
      },

      '@media (min-width: 962px) and (max-width: 1265px)': {
        transform: 'scale(1.4)',
      },

      '@media (min-width: 770px) and (max-width: 961px)': {
        transform: 'scale(1.3)',
      },
    };
  }
});

export const TitleTypography = styled(Typography)({
  fontSize: '1.25rem',
  fontWeight: 'bold',
  fontFamily: 'Apple SD Gothic Neo',
});

export const FindTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '.MuiFilledInput-root': {
    fontFamily: 'Apple SD Gothic Neo',
    backgroundColor: theme.palette.white.main,
    paddingRight: 0,
    '&.Mui-disabled': {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.white.main,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.white.main,
    },
    '.MuiFilledInput-input': {
      height: '3rem',
      padding: '0.25rem 0 0.25rem 0.5rem',
    },
  },
}));

export const FindBackButton = styled(IconButton)({
  position: 'absolute',
  top: '1rem',
  left: '1rem',
  svg: {
    width: '2rem',
    height: '2rem',
  },
});

export const FindButton = styled(ButtonContainer)({
  width: '100%',
  height: '3rem',
  fontFamily: 'Apple SD Gothic Neo',
});
