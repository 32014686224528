import { atom } from 'recoil';

interface UserAccountInfoType {
  account: string;
  accountnm: string;
  bankname: string;
  cost: string;
  limitDate: string;
  paymentId: number;
  paymentKind: string;
  productEndDate: string;
  productPrice: number;
  productStartDate: string;
  productTerm: number;
  productType: string;
  isJoined: boolean;
  defaultPrice: number;
  studentCount: number;
  product_name: string;
}

export type AccountInfoType = UserAccountInfoType | null;

export const accountInfoState = atom<AccountInfoType>({
  key: 'accountInfoState',
  default: null,
});
