import { ReactNode } from 'react';
import { atom } from 'recoil';
import InfoIcon from './InfoIcon';
import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import theme from '../../theme';

export const Title = styled(Box)({
  marginBottom: '8px',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.05rem',
});

export const Content = styled('span')({
  paddingLeft: '12px',
});

export interface CardContentTypes {
  id: string;
  title: { text: string | ReactNode };
  paragraph: { text: string | ReactNode }[];
}

export interface CardTypes {
  [key: string]: {
    [key: string]: CardContentTypes;
  };
}

export const PAYMENT_FEE_CARD_CONTENTS = {
  id: 'payment_payment_fee',
  title: {
    text: (
      <Title>
        <InfoIcon />
        이용료 납부
      </Title>
    ),
  },
  paragraph: [
    {
      text: (
        <Content>
          • 지금까지{' '}
          <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
            납부하신 결제내역
          </Box>
          을 조회할 수 있습니다.
        </Content>
      ),
    },
    {
      text: <Content>• 결제내역은 최근 청구된 순으로 표기됩니다.</Content>,
    },
    {
      text: (
        <Content>
          • 지출증빙 정보를 입력하신 경우,{' '}
          <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
            영수 계산서 확인
          </Box>
          이 가능합니다.
        </Content>
      ),
    },
  ],
};

export const EXPENDITURE_EVIDENCE_CARD_CONTENTS = {
  id: 'payment_expenditure_evidence',
  title: {
    // text: (
    //   <Title>
    //     <InfoIcon />
    //     세금계산서<Box sx={{ fontWeight: 400 }}> : 매월 1일 일괄 발행 (전월 이용료) /&nbsp;</Box>현금영수증
    //     <Box sx={{ fontWeight: 400 }}> : 매일 새벽 4시 일괄 발행</Box>
    //   </Title>
    // ),
    text: (
      <Title>
        <InfoIcon />
        지출증빙 정보
      </Title>
    ),
  },
  paragraph: [
    {
      text: (
        <Content>
          • 세금계산서 및 현금영수증 정보 중{' '}
          <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
            택 1 가능
          </Box>
          합니다.
        </Content>
      ),
      // text: <Content>• 세금계산서 및 현금영수증 정보 중 택 1 가능합니다.</Content>,
    },
    {
      text: (
        <Content>
          • 사업자등록증 상 명시된 내용을{' '}
          <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
            빠짐 없이 기재
          </Box>
          해주시기 바랍니다.
        </Content>
      ),
      // text: <Content>• 사업자등록증 상 명시된 내용을 빠짐 없이 기재해주시기 바랍니다.</Content>,
    },
    {
      text: (
        <Content>
          • 모든 항목은 필수입력 사항이며,{' '}
          <Box component={'span'} sx={{ color: '#e9121d' }}>
            등록된 지출증빙 정보는 추후 변경 불가
          </Box>
          합니다.
        </Content>
      ),
      // text: <Content>• 모든 항목은 필수입력 사항이며, 등록된 지출증빙 정보는 추후 변경 불가합니다.</Content>,
    },
    {
      text: <Content>• 기재된 정보는 서비스 이용기간 동안 이메일로 자동 발급됩니다.</Content>,
    },
    {
      text: (
        <Content>
          • 세금계산서는 전월 이용료의 내역으로,{' '}
          <Box component={'span'} sx={{ color: '#e9121d' }}>
            매월 1일 일괄 발행
          </Box>
          됩니다.
        </Content>
      ),
    },
    {
      text: (
        <Content>
          •{' '}
          <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
            현금영수증은
          </Box>{' '}
          정보 기입 후{' '}
          <Box component={'span'} sx={{ color: '#e9121d' }}>
            5일 이내 결제 건에 대해서만
          </Box>
          {', '}
          새벽 4시경 자동발급 됩니다. <br />
          <Box component={'span'} sx={{ color: '#9f9f9f' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(이후 월 사용료 결제건은 자동발행되며, 이전 결제건은 재발행 불가)
          </Box>
        </Content>
      ),
      // text: <Content>• 현금영수증은 정보 기입 후 5일 이내 결제 건에 대해서만, 새벽 4시경 자동발급 됩니다.</Content>,
    },
    // {
    //   text: <Content>(이후 월 사용료 결제건은 자동발행되며, 이전 결제건은 재발행 불가)</Content>,
    // },
  ],
};
