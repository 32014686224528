import { Box, Checkbox, Radio } from '@mui/material';
import {
  AccordionContainer,
  AccordionSummaryContainer,
  AccountAccordionDetail,
  CardAccordionDetail,
  CardAccountAccordionDetail,
  CustomFormControlLabel,
  FormControl,
  FormLabel,
  LoadingFilter,
  Modal,
  Wrapper,
} from './ModalPaymentMethodChange.Styles';

import { MdKeyboardArrowDown } from 'react-icons/md';
import { cardRegisterNoticeList, paymentChangeNoticeList } from 'utils/constants';
import theme from 'theme';
import { CardInfoType, ModalCardInfoContainer } from 'components/modal/ModalCardInfo/ModalCardInfo.Container';
import { AddCardInfoType } from './ModalPaymentMethodChange.Container';
import { getFormattedLastDayOfMonth } from 'utils/tools';
import { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal, Key } from 'react';
import { PaymentKindInfoType } from 'pages/main/Setup';

interface ModalPaymentMethodChangeVAProps {
  onClose: () => void;
  visible: boolean;
  paymentInfo: PaymentKindInfoType | undefined;
  expanded: string | false;
  handleChange: (panel: string) => void;
  handleCardInfoOpenClick: () => void;
  handleCardInfoCloseClick: () => void;
  isCardInfoModalOpen: boolean;
  handlePaymentButtonClick: (cardInfo: CardInfoType) => void;
  isCardInfoLoading: boolean;
  isChangePaymentLoading: boolean;
  addCardInfo: AddCardInfoType | undefined;
  isAgree: boolean;
  handleAgreeCheckBoxClick: () => void;
  onAddCardInfoSubmit: () => void;
}

const cardData = [
  { panelId: 'card', label: '결제카드 변경' },
  { panelId: 'autoPayment', label: '자동결제 해지' },
];

const accountData = [
  { panelId: 'account', label: '가상계좌' },
  { panelId: 'card', label: '카드결제' },
];

export function ModalPaymentMethodChangeUI({
  onClose,
  visible,
  paymentInfo,
  expanded,
  handleChange,
  handleCardInfoOpenClick,
  handleCardInfoCloseClick,
  isCardInfoModalOpen,
  handlePaymentButtonClick,
  isCardInfoLoading,
  isChangePaymentLoading,
  addCardInfo,
  isAgree,
  handleAgreeCheckBoxClick,
  onAddCardInfoSubmit,
}: ModalPaymentMethodChangeVAProps) {
  const isCardPay = paymentInfo && ['AC', 'CD'].includes(paymentInfo.paymentKind);

  return (
    <>
      <Modal onClose={onClose} open={visible}>
        <Wrapper>
          {isChangePaymentLoading && <LoadingFilter />}
          <FormControl>
            <FormLabel>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {isCardPay ? '결제정보 변경' : '결제수단 변경'}
              </Box>
            </FormLabel>
            {isCardPay ? (
              <>
                {cardData.map(({ panelId, label }) => (
                  <AccordionContainer
                    onChange={() => handleChange(panelId)}
                    expanded={expanded === panelId}
                    key={panelId}
                  >
                    <AccordionSummaryContainer
                      expandIcon={<MdKeyboardArrowDown color={expanded === panelId ? 'lightgray' : 'gray'} size={35} />}
                      aria-controls={`${panelId}-content`}
                      id={`${panelId}-header`}
                      sx={
                        expanded !== panelId && panelId === 'card' ? { borderBottom: '1px solid #e9ecef' } : undefined
                      }
                    >
                      <CustomFormControlLabel
                        control={<Radio checked={panelId === expanded} color='green' />}
                        label={label}
                      />
                    </AccordionSummaryContainer>
                    {panelId === 'card' ? (
                      <CardAccordionDetail>
                        <section className='method-detail-wrap' onClick={handleCardInfoOpenClick}>
                          <div>{`${paymentInfo?.paymentCompany} ${paymentInfo?.paymentNumber}`}</div>
                          <div style={{ color: 'red' }}>{`(클릭하여 카드정보를 변경해주세요.)`}</div>
                        </section>
                        <Box component={'ul'} className='notice-wrap'>
                          {paymentChangeNoticeList.map((list: any, index: Key | null | undefined) => (
                            <Box sx={{ display: 'flex', gap: '0.2rem' }} key={index}>
                              <Box sx={{ paddingTop: '3px', fontSize: '1rem' }} component={'span'}>
                                *
                              </Box>
                              <Box component={'li'} dangerouslySetInnerHTML={{ __html: list }} />
                            </Box>
                          ))}
                        </Box>
                      </CardAccordionDetail>
                    ) : (
                      <CardAccountAccordionDetail>
                        <div>자동결제를 정말 해지하시겠습니까?</div>
                        <div className='period-wrap'>
                          결제 잔여 기간<span>{`~ ${getFormattedLastDayOfMonth()}`}</span>
                        </div>
                        <div>
                          지금 해지하시면,{' '}
                          <span style={{ color: theme.palette.purple.main, fontWeight: '700' }}>
                            {`다음 결제일 ${new Date().getFullYear()}. ${
                              new Date().getMonth() + 2 === 13
                                ? '01'
                                : new Date().getMonth() + 2 < 10
                                ? '0' + new Date().getMonth() + 2
                                : new Date().getMonth() + 2
                            }. 01일`}
                          </span>
                          에<br />
                          전용 가상계좌로 청구될 예정입니다.
                        </div>
                        <button
                          disabled={isChangePaymentLoading}
                          onClick={onAddCardInfoSubmit}
                          className='payment-cancel-button'
                          type='button'
                        >
                          해지
                        </button>
                      </CardAccountAccordionDetail>
                    )}
                  </AccordionContainer>
                ))}
              </>
            ) : (
              <>
                {accountData.map(({ panelId, label }) => (
                  <AccordionContainer
                    onChange={() => handleChange(panelId)}
                    expanded={expanded === panelId}
                    key={panelId}
                  >
                    <AccordionSummaryContainer
                      expandIcon={<MdKeyboardArrowDown color={expanded === panelId ? 'lightgray' : 'gray'} size={35} />}
                      aria-controls={`${panelId}-content`}
                      id={`${panelId}-header`}
                      sx={
                        expanded !== panelId && panelId === 'account'
                          ? { borderBottom: '1px solid #e9ecef' }
                          : undefined
                      }
                    >
                      <CustomFormControlLabel
                        control={<Radio checked={panelId === expanded} color='green' />}
                        label={label}
                      />
                    </AccordionSummaryContainer>
                    {panelId === 'account' ? (
                      <AccountAccordionDetail>
                        <div>{`${paymentInfo?.accountBank} ${paymentInfo?.accountNumber}`}</div>
                        <div>{paymentInfo?.accountName}</div>
                      </AccountAccordionDetail>
                    ) : (
                      <AccountAccordionDetail className='card-register-wrapper'>
                        <div onClick={handleCardInfoOpenClick} className='card-register'>
                          {addCardInfo
                            ? `${addCardInfo.paymentCompany} ${addCardInfo.paymentNumber}`
                            : `+ 카드를 등록하세요`}
                        </div>
                        <div className='check-box-wrap' onClick={addCardInfo ? handleAgreeCheckBoxClick : undefined}>
                          <Checkbox checked={isAgree} size='small' color='green' />
                          <label style={{ cursor: 'pointer' }}>매월 자동 결제에 동의합니다.</label>
                        </div>
                        <button
                          onClick={isAgree ? onAddCardInfoSubmit : undefined}
                          className='payment-cancel-button'
                          type='button'
                          disabled={!isAgree || isChangePaymentLoading ? true : false}
                        >
                          확인
                        </button>
                        <Box component={'ul'} className='notice-wrap'>
                          {cardRegisterNoticeList.map(
                            (
                              list:
                                | string
                                | number
                                | boolean
                                | ReactElement<any, string | JSXElementConstructor<any>>
                                | ReactFragment
                                | ReactPortal
                                | null
                                | undefined,
                              index: Key | null | undefined,
                            ) => (
                              <Box sx={{ display: 'flex', gap: '0.2rem' }} key={index}>
                                <Box sx={{ paddingTop: '2px', fontSize: '1rem' }} component={'span'}>
                                  *
                                </Box>
                                <Box component={'li'}>{list}</Box>
                              </Box>
                            ),
                          )}
                        </Box>
                      </AccountAccordionDetail>
                    )}
                  </AccordionContainer>
                ))}
              </>
            )}
          </FormControl>
        </Wrapper>
      </Modal>
      {isCardInfoModalOpen && (
        <ModalCardInfoContainer
          onClose={handleCardInfoCloseClick}
          isLoading={isCardInfoLoading}
          handleCardPaymentButtonClick={(cardInfo: any) => handlePaymentButtonClick(cardInfo)}
          selectedProduct='month'
        />
      )}
    </>
  );
}
