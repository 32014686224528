import {
  Container,
  ListWrapper,
  RegisterWrapper,
  ProfileTitle,
  ProfileSubTitle,
  ProfileWrap,
  Profile,
  ProfileCircle,
  ProfileName,
  ProfileAddButton,
  FindBackButton,
  Form,
  RegisterButton,
} from './Profile.Styles';
import MultipleLevelSelection from 'components/common/MultipleLevelSelection/MultipleLevelSelection';
import { getCategoriesByParentId, Category } from 'utils/multi_level_selection';
import { FaUser } from 'react-icons/fa6';
import { HiPlus } from 'react-icons/hi';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';
import { InputLoginTextContainer } from 'components/login/InputLoginText/InputLoginText.Container';
import { ButtonGroupContainer } from 'components/common/ButtonGroup/ButtonGroup.Container';
import ModalLogoUpload from 'components/modal/ModalLogoUpload';
import ModalDefaultProfileSetting from 'components/modal/ModalDefaultProfileSetting';
import { Box } from '@mui/material';
import { ProfileType } from 'recoil/common/profiles';
import main_profille_mark from 'assets/images/main_profile.png';
import { autoHypenPhone } from 'utils/tools';
import { ModalStudySettingContainer } from 'components/modal/ModalStudySetting/ModalStudySetting.Container';
import profile_default_new from 'assets/images/default-profile/profile_default.png';

interface ProfileVAProps {
  mode: string;
  profiles: Array<ProfileType>;
  modalProfileVisible: boolean;
  onClickLogoutButton: () => void;
  onClickAddButton: () => void;
  onClickBackButton: () => void;
  onClickModifykBackButton: () => void;
  onClickName: (profile: ProfileType) => void;
  //* 등록
  name: string;
  onChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  buttonGroupContents: { id: number | string; label: string; check: boolean }[];
  changeButtonCheck: (id: number | string, key?: string) => false | undefined;
  setGrade: React.Dispatch<React.SetStateAction<Category | undefined>>;
  onClickRegisterButton: () => void;
  profileUpload: (img: File | string | null) => void;
  imageFileUrl: string | null;
  imageUrl: string;
  onClickProfileButton: () => void;
  onCloseUploadModal: () => void;
  clickDefaultProfile: (id: number) => void;
  defaultProfileImage: any;
  modifyType: string;
  gradeItem: Category | undefined;
  gradeItems: any;
  phone: string;
  pinCode: string;
  onChangePhone: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickDeleteButton: () => void;
  onClickLogin: (profile: ProfileType) => void;
  onChangePINCode: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>, mode: string) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>, mode: string) => void;
  top: string;
}

export function ProfileUI({
  mode,
  profiles,
  modalProfileVisible,
  onClickLogoutButton,
  onClickAddButton,
  onClickBackButton,
  onClickModifykBackButton,
  onClickName,
  name,
  onChangeName,
  buttonGroupContents,
  changeButtonCheck,
  setGrade,
  onClickRegisterButton,
  profileUpload,
  imageFileUrl,
  imageUrl,
  onClickProfileButton,
  onCloseUploadModal,
  clickDefaultProfile,
  defaultProfileImage,
  modifyType,
  gradeItem,
  gradeItems,
  phone,
  onChangePhone,
  onClickDeleteButton,
  onClickLogin,
  onFocus,
  onBlur,
  top,
  pinCode,
  onChangePINCode,
}: ProfileVAProps) {
  return (
    <Container>
      {mode == 'list' && (
        <FindBackButton onClick={onClickLogoutButton}>
          <HiOutlineArrowSmLeft />
        </FindBackButton>
      )}
      {/* 프로필 선택 */}
      {mode == 'list' ? (
        <ListWrapper>
          <ProfileTitle>학습 프로필 선택</ProfileTitle>
          <ProfileSubTitle>학부모님과 자녀까지 최대 3개 설정 가능해요.</ProfileSubTitle>
          <ProfileWrap>
            {profiles.map(profile => (
              <Profile key={profile.id}>
                <ProfileCircle onClick={() => onClickLogin(profile)}>
                  {profile.profile_type === 'default' ? (
                    profile.default_profile == '0' ? (
                      // <FaUser />
                      <Box sx={{ backgroundImage: `url(${profile_default_new})` }} className='default_profile' />
                    ) : (
                      <Box
                        className='user_profile'
                        sx={{ backgroundImage: `url(data:image/png;base64,${profile.profile})` }}
                      />
                    )
                  ) : (
                    <Box
                      className='user_profile'
                      sx={{ backgroundImage: `url(data:image/png;base64,${profile.profile})` }}
                    />
                  )}
                  {profile.type == 'T' ? (
                    <img className='main_profile_mark' src={main_profille_mark} alt='main_profile_mark' />
                  ) : null}
                </ProfileCircle>
                <ProfileName onClick={() => onClickName(profile)}>{`${profile.name} >`}</ProfileName>
              </Profile>
            ))}
            {profiles.length > 2 ? null : (
              <Profile>
                <ProfileAddButton onClick={onClickAddButton}>
                  <HiPlus />
                </ProfileAddButton>
                <ProfileName onClick={onClickAddButton}>프로필 추가</ProfileName>
              </Profile>
            )}
          </ProfileWrap>
        </ListWrapper>
      ) : mode == 'register' ? (
        <RegisterWrapper id='register-wrapper' sx={{ marginTop: `${top}` }}>
          <FindBackButton onClick={onClickBackButton}>
            <HiOutlineArrowSmLeft />
          </FindBackButton>
          <ProfileTitle>프로필 등록</ProfileTitle>
          <ProfileSubTitle>오래 기억되는 영어학습을 함께 할 학생을 등록해주세요.</ProfileSubTitle>
          <Profile sx={{ padding: '2rem 0' }}>
            <ProfileCircle onClick={onClickProfileButton} sx={{ marginBottom: '0' }}>
              {imageFileUrl ? (
                <Box className='user_profile' sx={{ backgroundImage: `url(${imageFileUrl})` }} />
              ) : imageUrl ? (
                <Box className='user_profile' sx={{ backgroundImage: `url(data:image/png;base64,${imageUrl})` }} />
              ) : defaultProfileImage ? (
                <Box sx={{ backgroundImage: `url(${defaultProfileImage})` }} className='user_profile' />
              ) : (
                // <FaUser />
                <Box sx={{ backgroundImage: `url(${profile_default_new})` }} className='default_profile' />
              )}
            </ProfileCircle>
          </Profile>
          <Form>
            <InputLoginTextContainer
              value={name}
              onChangeValue={onChangeName}
              onKeyDownEnter={() => {
                return;
              }}
              placeholder='이름'
              onFocus={onFocus}
              onBlur={onBlur}
            />
            <MultipleLevelSelection
              initialItems={getCategoriesByParentId(0, 0)}
              getItemKey={item => item.categoryId}
              getItemLabel={item => item.name}
              getNestedItems={item => getCategoriesByParentId(item.categoryId, 0)}
              hasNestedItems={(_, level) => level < 2}
              isEqual={(item, item2) => item?.categoryId === item2?.categoryId}
              placeholder='학년 선택'
              onChange={setGrade}
            ></MultipleLevelSelection>
            <InputLoginTextContainer
              value={pinCode}
              onChangeValue={onChangePINCode}
              onKeyDownEnter={() => {
                return;
              }}
              placeholder='PIN 번호를 입력해주세요(4~8자리)'
              onFocus={onFocus}
              onBlur={onBlur}
              type='pin'
            />
            <ButtonGroupContainer contents={buttonGroupContents} onCheck={changeButtonCheck} checkColor='purple_4' />
          </Form>
          <RegisterButton onClick={onClickRegisterButton}>등록</RegisterButton>
        </RegisterWrapper>
      ) : (
        <RegisterWrapper id='register-wrapper' sx={{ marginTop: `${top}` }}>
          <FindBackButton onClick={onClickModifykBackButton}>
            <HiOutlineArrowSmLeft />
          </FindBackButton>
          <ProfileTitle>프로필 수정</ProfileTitle>
          {/* 메인프로필은 별도 */}
          {modifyType == 'T' ? (
            <ProfileSubTitle>메인 프로필은 모든 사용자의 학습 결과를 알림톡으로 받을 수 있어요.</ProfileSubTitle>
          ) : (
            <ProfileSubTitle>오래 기억되는 영어학습을 함께 할 학생을 수정해주세요.</ProfileSubTitle>
          )}
          <Profile sx={{ padding: '2rem 0' }}>
            <ProfileCircle onClick={onClickProfileButton} sx={{ marginBottom: '0' }}>
              {modifyType == 'T' ? (
                <img className='main_profile_mark' src={main_profille_mark} alt='main_profile_mark' />
              ) : null}
              {imageFileUrl ? (
                <Box className='user_profile' sx={{ backgroundImage: `url(${imageFileUrl})` }} />
              ) : imageUrl ? (
                <Box className='user_profile' sx={{ backgroundImage: `url(data:image/png;base64,${imageUrl})` }} />
              ) : defaultProfileImage ? (
                <Box sx={{ backgroundImage: `url(${defaultProfileImage})` }} className='user_profile' />
              ) : (
                // <FaUser />
                <Box sx={{ backgroundImage: `url(${profile_default_new})` }} className='default_profile' />
              )}
            </ProfileCircle>
          </Profile>
          <Form>
            <InputLoginTextContainer
              value={name}
              onChangeValue={onChangeName}
              onKeyDownEnter={() => {
                return;
              }}
              placeholder='이름'
              onFocus={onFocus}
              onBlur={onBlur}
              disabled={['create-pin-code', 'modify-pin-code'].includes(mode)}
            />
            {/* 메인 프로필은 번호 Inpu / 서브 프로필은 MultiDropdown */}
            {modifyType == 'T' ? (
              <InputLoginTextContainer
                value={autoHypenPhone(phone)}
                onChangeValue={onChangePhone}
                onKeyDownEnter={() => {
                  return;
                }}
                placeholder='전화번호'
                onFocus={onFocus}
                onBlur={onBlur}
                type='phone'
                disabled={['create-pin-code', 'modify-pin-code'].includes(mode)}
              />
            ) : (
              <MultipleLevelSelection
                initialItems={getCategoriesByParentId(0, 0)}
                getItemKey={item => item.categoryId}
                getItemLabel={item => item.name}
                getNestedItems={item => getCategoriesByParentId(item.categoryId, 0)}
                hasNestedItems={(_, level) => level < 2}
                isEqual={(item, item2) => item?.categoryId === item2?.categoryId}
                placeholder='학년 선택'
                onChange={setGrade}
                curValue1={gradeItems}
                curValue2={gradeItem}
                disabled={['create-pin-code', 'modify-pin-code'].includes(mode)}
              />
            )}
            <InputLoginTextContainer
              value={!['create-pin-code', 'modify-pin-code'].includes(mode) ? pinCode.replace(/./g, '*') : pinCode}
              onChangeValue={onChangePINCode}
              onKeyDownEnter={() => {
                return;
              }}
              placeholder='PIN 번호를 입력해주세요(4~8자리)'
              onFocus={onFocus}
              onBlur={onBlur}
              type='pin'
              disabled={!['create-pin-code', 'modify-pin-code'].includes(mode)}
            />
            <ButtonGroupContainer
              disabled={['modify-pin-code'].includes(mode)}
              contents={buttonGroupContents}
              onCheck={changeButtonCheck}
              checkColor='purple_4'
            />
          </Form>
          {/* 서브 프로필만 */}
          {modifyType == 'S' ? <RegisterButton onClick={onClickDeleteButton}>삭제</RegisterButton> : null}
        </RegisterWrapper>
      )}
      <ModalLogoUpload
        visible={modalProfileVisible}
        onClose={onCloseUploadModal}
        uploadingCallback={() => {
          return;
        }}
        isDefault={false}
        profileUpload={profileUpload}
        clickDefaultProfile={clickDefaultProfile}
      />
      <ModalDefaultProfileSetting />
      <ModalStudySettingContainer />
    </Container>
  );
}
