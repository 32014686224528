import { ModalContext } from 'provider/ModalProvider';
import { useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { blockerData, BlockerDataType } from 'recoil/common/blocker';

export function useBlock() {
  const { navigator } = useContext(NavigationContext);
  const { modal_confirm } = useContext(ModalContext);
  const [blockerState, setBlockerState] = useRecoilState<BlockerDataType>(blockerData);

  useEffect(() => {
    if (!blockerState.when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof push>) => {
      const confrimCallback = () => {
        push(...args);
      };
      modal_confirm.openModalConfirm(
        'normal_reverse',
        blockerState.title,
        confrimCallback,
        blockerState.cancelCallback,
        blockerState.target,
      );
    };

    return () => {
      navigator.push = push;
    };
  }, [blockerState, setBlockerState]);
}
