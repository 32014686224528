import { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { learningState } from '../../recoil/model/learning';
import { loadingState, setPercent } from '../../recoil/common/loading';
import { voiceState, useVoice } from '../../recoil/common/voice';
import { routesState } from '../../recoil/common/routes';
import { pocketNoteState } from '../../recoil/model/pocket_note';
import { moduleSettingState } from '../../recoil/model/module_setting';
import { deviceState } from '../../recoil/common/device';
import { userState } from '../../recoil/model/user';
import { settingState } from '../../recoil/model/settings';
import { tutorialStateData, openTutorial } from '../../recoil/common/tutorial';
import { bookState } from '../../recoil/model/book';

import { ModalContext } from '../../provider/ModalProvider';

import { styled, Box, Button, IconButton, IconButtonProps, BoxProps } from '@mui/material';
import dayjs from 'dayjs';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { fetchGetApi, fetchPostApi, fetchPutApi } from '../../utils/api';

import MemorizeStepper from '../../components/common/Study/MemorizeNewStepper';
import ModalAddPocketNote from '../../components/modal/ModalAddPocketNote';
import ModalGrading from '../../components/modal/ModalGrading';
import ModalCounting from '../../components/modal/ModalCounting';

import { options } from '../../utils/setting_contents';
import { BASE_URL, IMAGE_DIR, MEMORIZE_LABEL } from '../../utils/constants';
import { CDN } from '../../utils/constants';
import {
  d_flex,
  d_flex_center,
  dir_column,
  d_flex_end,
  d_flex_space_evenly,
  d_flex_space_between,
} from '../../styles/common';
import { arrShuffle, getContentFoldername, getExampleFilename, setPartofSpeech } from '../../utils/tools';

import { AiOutlineLeft, AiOutlineRight, AiOutlineUp, AiOutlineDown } from 'react-icons/ai';
import { FiStar } from 'react-icons/fi';
import { Repeat } from '@mui/icons-material';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import AutoText from '../../components/text/AutoText';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';
import OXMark from '../../components/common/Study/OXMark';
import { modalSelectCardData } from 'recoil/common/modalSelectCard';

interface MemorizeBoxProps extends BoxProps {
  is_mobile: number;
}

const StyledMemorizeWrap = styled(Box)<MemorizeBoxProps>(props => ({
  width: '100%',
  height: 'calc(100% - 5rem)',
  backgroundColor: 'white',
  position: props.is_mobile == 1 ? 'relative' : 'inherit',
  '& *': {
    fontFamily: "'Apple SD Gothic Neo'",
    lineHeight: '1.20',
  },
}));

const StyledMemorizeBody = styled(Box)(props => ({
  width: '100%',
  display: 'block',
  position: 'relative',
  overflow: 'auto',
}));

const StyledMemorizeContentWrap = styled(Box)(props => ({
  width: '100%',
  height: 'calc(100% - 9.5rem)',
  position: 'relative',
  ...d_flex_center,
  ...dir_column,
}));

const StyledTopButtonBox = styled(Box)(props => ({
  width: '100%',
  height: '4.75rem',
  ...d_flex,
  alignItems: 'center',
  justifyContent: 'flex-start',
  ...dir_column,
}));

const StyledBottomButtonBox = styled(Box)(props => ({
  width: '100%',
  height: '4.75rem',
  ...d_flex,
  alignItems: 'center',
  justifyContent: 'flex-end',
  ...dir_column,
}));

const StyledLeftButtonBox = styled(Box)<MemorizeBoxProps>(props => ({
  position: 'absolute',
  top: props.is_mobile ? 'calc((100% - 4rem) / 2)' : '50%',
  left: '4.95%',
  transform: props.is_mobile ? 'translateY(-50%)' : 'translateY(50%)',
  ...d_flex_center,
}));

const StyledRightButtonBox = styled(Box)<MemorizeBoxProps>(props => ({
  position: 'absolute',
  top: props.is_mobile ? 'calc((100% - 4rem) / 2)' : '50%',
  right: '4.95%',
  transform: props.is_mobile ? 'translateY(-50%)' : 'translateY(50%)',
  ...d_flex_center,
}));

const StyledTextBox = styled(Box)(props => ({
  cursor: 'pointer',
  color: '#b3b7bf',
  fontSize: '1.25rem',
  fontWeight: '700',
}));

const StyledIconButton = styled(IconButton)(props => ({
  backgroundColor: 'transparent',
  width: '3rem',
  height: '3rem',
  padding: '0',
  svg: {
    color: '#b3b7bf',
    width: '2.5rem',
    height: '2.5rem',
    strokeWidth: '4rem',
  },
  '&.Mui-disabled > svg': {
    color: '#e8e9ec',
  },
}));
213;

interface ContentsBoxProps extends MemorizeBoxProps {
  is_dic: number;
  is_exam: number;
  is_spell?: number;
}

const StyledMemorizeContents = styled(Box)<ContentsBoxProps>(props => ({
  width: '70%',
  height: props.is_dic == 1 ? '200vh' : '100%',
  maxHeight: props.is_dic == 1 ? 'unset' : '100%',
  margin: props.is_mobile == 1 ? '4px 0 2px 0' : '4px 0',
  ...d_flex_center,
  ...dir_column,
  backgroundColor: '#fcfaff',
  borderRadius: '8px',
  position: 'relative',
  cursor: props.is_dic == 1 ? 'default' : 'pointer',
}));

const StyledLeftHeaderBox = styled(Box)(props => ({
  position: 'absolute',
  display: 'inline-flex',
  alignItems: 'center',
  top: '1.75vh',
  left: '1.75vh',
}));

const StyledRightHeaderBox = styled(Box)(props => ({
  position: 'absolute',
  top: '1.75vh',
  right: '1.75vh',
  display: 'inline-flex',
  alignItems: 'center',
}));

interface CustomSpeakButtonProps extends IconButtonProps {
  speak: number;
}

const CustomSpeakButton = styled(IconButton)<CustomSpeakButtonProps>(props => ({
  zIndex: 1,
  padding: '0.8vh',
  svg: {
    fill: props.speak == 1 ? props.theme.palette.purple.main : props.theme.palette.gray_2.main,
    width: '3.5vh',
    height: '3.5vh',
  },
}));

const CustomDiagonalLine = styled(Box)(props => ({
  width: '3.5vh',
  height: '2px',
  position: 'absolute',
  backgroundColor: props.theme.palette.red.main,
  transform: 'translateX(-0.25rem) rotate(-45deg)',
}));

interface CustomRepeatButtonProps extends IconButtonProps {
  repeat: number;
}

const CustomRepeatButton = styled(IconButton)<CustomRepeatButtonProps>(props => ({
  zIndex: 1,
  padding: '0.8vh',
  svg: {
    color: props.repeat == 1 ? props.theme.palette.purple.main : props.theme.palette.gray_2.main,
    width: '3.5vh',
    height: '3.5vh',
  },
}));

interface CustomBookmarkButtonProps extends IconButtonProps {
  bookmark: number;
}

const CustomBookmarkButton = styled(IconButton)<CustomBookmarkButtonProps>(props => ({
  zIndex: 1,
  padding: '0.8vh',
  svg: {
    color: props.bookmark == 1 ? '#f1bc5f' : props.theme.palette.purple.main,
    width: '3.5vh',
    height: '3.5vh',
    fill: props.bookmark == 1 ? '#f5ff1b' : 'none',
  },
}));

const StyledContentsBody = styled(Box)<ContentsBoxProps>(props => ({
  width: props.is_dic == 1 ? '100%' : 'calc(100% - 23.9vh - 24px)',
  height: props.is_dic == 1 ? '200vh' : 'calc(100% - 2rem)',
  position: 'relative',
  padding:
    props.is_dic == 1 ? '5rem 0 1.5rem 0' : props.is_exam == 1 ? '0' : props.is_mobile == 1 ? '1rem 0' : '2rem 0 0 0',
  ...d_flex_center,
}));

const StyledImageBox = styled(Box)<MemorizeBoxProps>(props => ({
  height: '100%',
  width: '100%',
  padding: props.is_mobile == 1 ? '0' : '3rem 0',
  ...d_flex_space_evenly,
  ...dir_column,
}));

const StyledContentsBottom = styled(Box)(props => ({
  ...d_flex_center,
  width: '100%',
  marginBottom: '1rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
}));

interface CustomProgressBoxProps extends BoxProps {
  progress: number;
}

const StyledProgressBox = styled(Box)<CustomProgressBoxProps>(props => ({
  height: '1rem',
  width: 'auto',
  backgroundColor: props.progress == 1 ? '#e3dfff' : 'none',
  flex: '1',
  marginLeft: '4px',
  marginRight: '4px',
  borderRadius: '4px',
}));

const StyledMemorizeBottom = styled(Box)(props => ({
  width: '100%',
  height: '4rem',
  ...d_flex,
}));

const StyledBottomLeftButton = styled(Button)(props => ({
  width: '50%',
  height: '100%',
  backgroundColor: props.disabled ? '#e3e3e3' : '#ff6041',
  color: 'white !important',
  fontSize: '1.5rem',
  fontWeight: '700',
  borderRadius: '0',
  paddingTop: '0.6rem',
  borderRight: props.disabled ? '1px solid #fff' : 'none',
  '&:hover': {
    backgroundColor: props.disabled ? '#e3e3e3' : '#ff6041',
  },
}));

const StyledBottomRightButton = styled(Button)(props => ({
  height: '100%',
  backgroundColor: props.disabled ? '#e3e3e3' : '#05d368',
  color: 'white !important',
  fontSize: '1.5rem',
  fontWeight: '700',
  borderRadius: '0',
  paddingTop: '0.6rem',
  '&:hover': {
    backgroundColor: props.disabled ? '#e3e3e3' : '#05d368',
  },
}));

const StyledSelectionWrap = styled(Box)(props => ({
  width: '70%',
  ...d_flex_space_between,
  flexWrap: 'wrap',
}));

interface SelectionBoxProps extends BoxProps {
  status: 'right' | 'wrong' | '';
  is_mobile: number;
}

const StyledSelectionBox = styled(Box)<SelectionBoxProps>(props => ({
  width: props.is_mobile == 1 ? 'calc(50% - 2px)' : 'calc(50% - 4px)',
  height: props.is_mobile == 1 ? '6.5vh' : '8vh',
  margin: props.is_mobile == 1 ? '2px 0' : '4px 0',
  cursor: 'pointer',
  backgroundColor: props.status == 'right' ? '#caf9ff' : props.status == 'wrong' ? '#ffe9e5' : '#f7f7f7',
  border:
    props.status == 'right' ? '1px solid #8abdde' : props.status == 'wrong' ? '1px solid #ffd5d1' : `1px solid #f1f1f1`,
  borderRadius: '1rem',
  ...d_flex_center,
  boxSizing: 'border-box',
  '& > span': {
    color: props.status == 'right' ? '#2973d0' : props.status == 'wrong' ? '#ff4c39' : '#6c6c6c',
  },
}));

const StyledDicIframeWrap = styled(Box)(props => ({
  width: '95%',
  height: '100%',
  position: 'relative',
}));

interface RecycleIconButtonProps extends IconButtonProps {
  is_mobile: number;
}

const StyeldRecycleIconButton = styled(IconButton)<RecycleIconButtonProps>(props => ({
  position: 'absolute',
  right: props.is_mobile == 1 ? 'calc(2.5rem + 16px)' : 'calc(9rem + 16px)',
  top: props.is_mobile == 1 ? '0' : '5.5rem',
  width: '2rem',
  height: '2rem',
  marginRight: '0.5rem',
  '& > svg': {
    width: '2rem',
    height: '2rem',
    transform: 'scaleX(-1) rotate(330deg)',
    color: '#ff6565',
  },
}));

const StyledSpellAutoText = styled(AutoText)(props => ({
  fontFamily: "'KCC-Ganpan' !important",
  fontWeight: '500',
  cursor: 'default',
  color: '#4620e9',
  textAlign: 'center',
}));

const StyledMeanAutoText = styled(AutoText)(props => ({
  fontWeight: '700 !important',
  cursor: 'default',
  color: '#4620e9',
  textAlign: 'center',
}));

function Memorize() {
  const navigate = useNavigate();

  const [tutorialState, setTutorialState] = useRecoilState(tutorialStateData);
  const { modal_confirm, modal_alert } = useContext(ModalContext);

  const { playEffectSound } = useContext(EffectSoundContext);

  const routesStateData = useRecoilValue<RoutesType>(routesState);
  const { root_path, child_path } = routesStateData;

  const [voiceStateData, setVoiceStateData] = useRecoilState<VoiceType>(voiceState);
  const { ready, playing } = voiceStateData;
  const paylingRef = useRef(playing);
  paylingRef.current = playing;
  const { setVoice, voicePlay, voiceStop, setAudioRate } = useVoice();

  const settingStateData = useRecoilValue<SettingsType>(settingState);
  const { enable_keyboard } = settingStateData;

  const userStateData = useRecoilValue<UserType>(userState);
  const { center_type } = userStateData;

  const modalSelectCard = useRecoilValue(modalSelectCardData);
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const resetLearningState = useResetRecoilState(learningState);
  const {
    resultsheet,
    show_modal,
    current_step,
    contents,
    current_page,
    mod,
    studytime,
    starttime,
    book_type,
    save_enabled,
    first_learning,
    relearning,
    reset_learning,
  } = learningStateData;

  const { visible: visibleTutorial, reopen: reopenTutorial } = tutorialState;
  const { visible: visibleModalSelectCard } = modalSelectCard;
  const reOpenTutorialRef = useRef(false);

  const visibleTutorialRef = useRef(visibleTutorial);
  visibleTutorialRef.current = visibleTutorial;
  const showModalRef = useRef(show_modal);
  showModalRef.current = show_modal;
  const currentStepRef = useRef(current_step);
  currentStepRef.current = current_step;
  const currentPageRef = useRef(current_page);
  currentPageRef.current = current_page;

  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;

  const [moduleSettingStateData, setModuleSettingStateData] = useRecoilState<ModuleSettings>(moduleSettingState);
  const { memorize_voice, memorize_book } = moduleSettingStateData;

  const maxVoiceCount = memorize_voice.count + 1;
  const [voiceCount, setVoiceCount] = useState<number>(0);
  const voiceCountRef = useRef(voiceCount);
  voiceCountRef.current = voiceCount;

  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { screen_width, screen_height, is_mobile, is_tablet, device_pause } = deviceStateData;

  const pocketNoteStateData = useRecoilValue<PocketNoteType>(pocketNoteState);
  const { note_contents } = pocketNoteStateData;

  const [content, setContent] = useState<ContentType>(contents[current_step]);
  const [disableMove, setDisableMove] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string>('');
  const [spell, setSpell] = useState('');
  const [mean, setMean] = useState('');
  const [example, setExample] = useState('');
  const [translate, setTranslate] = useState('');
  const [flashOn, setFlashOn] = useState<boolean>(true);
  const [flashReady, setFlashReady] = useState<boolean>(false);
  const [firstFlash, setFirstFlash] = useState<boolean>(true);
  const [prevStep, setPrevStep] = useState<boolean>(false);
  const [nextStep, setNextStep] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const memorizeCnt = useRef<number>(0);

  const settimeout1 = useRef<ReturnType<typeof setTimeout>[]>([]);
  const settimeout2 = useRef<ReturnType<typeof setTimeout>[]>([]);
  const settimeout3 = useRef<ReturnType<typeof setTimeout>[]>([]);

  const [visibleGrading, setVisibleGrading] = useState<boolean>(false);
  const [visibleAddPocketNote, setVisibleAddPocketNote] = useState<boolean>(false);

  const startX = useRef(0);
  const startY = useRef(0);

  const [beforeStep, setBeforeStep] = useState(0);
  const [beforePage, setBeforePage] = useState(0);
  const [animationDirection, setAnimationDirection] = useState('horizontal-right');

  const [pause, setPause] = useState(false);
  const pauseRef = useRef(pause);

  const [imgReady, setImgReady] = useState(false);
  const [exampleReady, setExampleReady] = useState<boolean>(false);
  const [countingModal, setCountingModal] = useState<{ visible: boolean; onClose: () => void; onCallback: () => void }>(
    {
      visible: false,
      onClose: () => {
        return;
      },
      onCallback: () => {
        return;
      },
    },
  );

  const [mobileResolution, setMobileResolution] = useState(false); // 모바일 해상도 여부

  const [exampleHTML, setExampleHTML] = useState('');
  const [translateHTML, setTranslateHTML] = useState('');
  const [nextDisable, setNextDisable] = useState<boolean>(learningStateData.tag == 'new');
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const [visibleOX, setVisibleOX] = useState<boolean>(false);

  const exampleVoiceRef = useRef<HTMLAudioElement | null>(null);

  const [hideBottom, setHideBottom] = useState(learningStateData.tag == 'new');

  const visibleOX_ref = useRef(false);
  const selection_ref = useRef<number>(-1);

  const stopVocie = (isStep?: boolean) => {
    voiceStop();
    if (mod[current_page].module == 1 && exampleVoiceRef.current) {
      exampleVoiceRef.current.pause();
      if (isStep) exampleVoiceRef.current = null;
    }
  };

  useEffect(() => {
    const setInit = async () => {
      let tmp_contents = [...contents];
      if (memorize_book.order == 1) {
        tmp_contents = arrShuffle(tmp_contents);
      }
      let sheet: ResultsheetType[] = [];
      for (let i = 0; i < tmp_contents.length; i++) {
        sheet.push({
          no: i + 1,
          word_id: tmp_contents[i].id,
          answer: null,
          rightanswer: tmp_contents[i].spell,
          question: tmp_contents[i].mean,
          memorize: false,
        });
      }

      if (learningStateData.tag == 'new') sheet = await setChoices(sheet);

      setContent(tmp_contents[0]);

      setLearningStateData(prevState => ({
        ...prevState,
        contents: tmp_contents,
        resultsheet: sheet,
      }));
    };

    if (resultsheet.length > 0) {
      for (let i = 0; i < resultsheet.length; i++) {
        if (resultsheet[i].memorize) memorizeCnt.current++;
      }
    } else {
      setInit();
    }

    setMobileResolution(screen_width < 768 || (is_mobile && !is_tablet));

    setPercent(setLoadingStateData, 100);

    checkExampleTTS();

    return () => {
      clearTimeout1();
      setVoiceStateData(() => ({
        element: undefined,
        playing: false,
        loading: false,
        error: false,
        ready: false,
        volume: 1,
      }));
      exampleVoiceRef.current = null;
    };
  }, []);

  const checkExampleTTS = async () => {
    for (let i = 0; i < contents.length; i++) {
      const example = contents[i].example;
      if (!example) continue;
      const gender = 0; // 남자 임시 고정
      const filename = getExampleFilename(example);
      const res = await fetchGetApi(`/etc/tts/example?filename=${filename}&gender=${gender}`);
      if (!res.result) {
        const postdata = {
          example: example,
          filename: filename,
          gender: gender,
        };
        await fetchPostApi(`/etc/tts/example`, postdata);
      }
    }
  };

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        const wrap = document.getElementById('contents-wrap');
        if (wrap) wrap.scrollTop = 0;
      }, 150);

      const box = document.getElementById('contents-box');
      if (beforeStep > current_step) {
        if (box) {
          box.style.transform = 'translateY(150%)';
          box.style.position = 'absolute';
          box.style.transition = 'opacity 300ms, transform 300ms, filter 300ms';
        }
      } else if (beforeStep < current_step) {
        if (box) {
          box.style.transform = 'translateY(-150%)';
          box.style.position = 'absolute';
          box.style.transition = 'opacity 300ms, transform 300ms, filter 300ms';
        }
      } else if (beforePage < current_page) {
        if (box) {
          box.style.transform = 'translateX(-100%)';
          box.style.position = 'absolute';
          box.style.transition = 'opacity 300ms, transform 300ms, filter 300ms';
        }
      } else if (beforePage > current_page) {
        if (box) {
          box.style.transform = 'translateX(100%)';
          box.style.position = 'absolute';
          box.style.transition = 'opacity 300ms, transform 300ms, filter 300ms';
        }
      }
    }

    setBeforePage(current_page);
    setBeforeStep(current_step);

    clearTimeout1();
    clearTimeout2();
    clearTimeout3();
    setPause(false);
    pauseRef.current = false;
    setDisableMove(true);
    setTimeout(() => {
      setDisableMove(false);
    }, 1000);
    setTimeout(() => {
      setFlashOn(true);
    }, 0);
    if (firstFlash) {
      setFirstFlash(false);
      settimeout2.current.push(
        setTimeout(() => {
          setFlashReady(true);
        }, 2000),
      );
    } else {
      settimeout2.current.push(
        setTimeout(() => {
          setFlashReady(true);
        }, 0),
      );
    }

    if (mod[current_page].module == 1) {
      if (resultsheet[current_step] && resultsheet[current_step].answer == resultsheet[current_step].rightanswer) {
        setSelectedIndex(resultsheet[current_step].answer as number);
        setNextDisable(false);
        settimeout3.current.push(
          setTimeout(() => {
            setHideBottom(false);
          }, 300),
        );
      } else {
        setSelectedIndex(null);
        setNextDisable(true);
        settimeout3.current.push(
          setTimeout(() => {
            setHideBottom(true);
          }, 300),
        );
      }
    } else {
      settimeout3.current.push(
        setTimeout(() => {
          setHideBottom(false);
        }, 300),
      );
    }
  }, [current_page, current_step]);

  useEffect(() => {
    if (!playing && ready && !show_modal && flashReady && !pause && !device_pause) {
      const timer = voiceCount == 0 ? 300 : 300 * (6 - memorize_voice.flash);
      if ([3, 4, 5].includes(mod[current_page].module)) {
        settimeout1.current.push(
          setTimeout(() => {
            contentVoicePlay();
          }, timer),
        );
      }
    }
  }, [playing, ready, show_modal, flashReady, pause, device_pause]);

  useEffect(() => {
    if (show_modal && memorize_voice.audio != voiceStateData.volume) {
      clearTimeout1();
      if (memorize_voice.audio == 0) {
        setVoiceStateData(prevState => ({
          ...prevState,
          volume: 0,
        }));
        if (mod[current_page].module == 1 && exampleVoiceRef.current) {
          exampleVoiceRef.current.volume = 0;
        }
      } else {
        setVoiceStateData(prevState => ({
          ...prevState,
          volume: 1,
        }));
        if (mod[current_page].module == 1 && exampleVoiceRef.current) {
          exampleVoiceRef.current.volume = 1;
        }
      }
    } else if (!show_modal) {
      setAudioRate(Number(options['memorize_voice']['speed'][memorize_voice.speed].value));
    }
  }, [show_modal]);

  useEffect(() => {
    if (ready) {
      setAudioRate(Number(options['memorize_voice']['speed'][memorize_voice.speed].value));
    }
  }, [ready]);

  useEffect(() => {
    if (visibleModalSelectCard) {
      reOpenTutorialRef.current = true;
    } else {
      if (visibleTutorialRef.current || mod[currentPageRef.current].module !== 1) {
        reOpenTutorialRef.current = reopenTutorial;
      }
    }
  }, [reopenTutorial, visibleModalSelectCard]);

  useEffect(() => {
    return () => {
      setTutorialState(prev => ({
        ...prev,
        reopen: false,
      }));
    };
  }, []);

  useEffect(() => {
    if (
      loaded &&
      flashReady &&
      mod[current_page].module == 1 &&
      (resultsheet[current_step].answer == null ||
        resultsheet[current_step].answer == resultsheet[current_step].rightanswer) &&
      exampleVoiceRef.current
    ) {
      if (visibleTutorial || visibleModalSelectCard) {
        // 이 부분에 걸리면 예문 음성 x
        if (!exampleVoiceRef.current.paused) {
          exampleVoiceRef.current.pause();
        }
      } else {
        if (reOpenTutorialRef.current) {
          reOpenTutorialRef.current = false;
          if (visibleOX_ref.current && selection_ref.current > -1) {
            onClickSelection(selection_ref.current, true);
          }
        } else {
          if (
            resultsheet[current_step].answer == null ||
            resultsheet[current_step].answer == resultsheet[current_step].rightanswer
          ) {
            if (!exampleVoiceRef.current.paused) {
              exampleVoiceRef.current.pause();
            }
            exampleVoiceRef.current.currentTime = 0;
            exampleVoiceRef.current.play();
          }
        }
      }
    }
  }, [flashReady, exampleReady, visibleTutorial, visibleModalSelectCard]);

  const clearTimeout1 = () => {
    for (let i = 0; i < settimeout1.current.length; i++) {
      clearTimeout(settimeout1.current[i]);
    }
    settimeout1.current = [];
  };

  const clearTimeout2 = () => {
    for (let i = 0; i < settimeout2.current.length; i++) {
      clearTimeout(settimeout2.current[i]);
    }
    settimeout2.current = [];
  };

  const clearTimeout3 = () => {
    for (let i = 0; i < settimeout3.current.length; i++) {
      clearTimeout(settimeout3.current[i]);
    }
    settimeout3.current = [];
  };

  const contentVoicePlay = () => {
    if (!loaded) return false;
    const before_page = current_page;
    const before_step = current_step;
    const before_count = voiceCountRef.current;
    clearTimeout1();
    if (!ready || memorizeCnt.current == contents.length || show_modal || pauseRef.current) return;
    if (voiceCountRef.current < maxVoiceCount) {
      if (voiceCountRef.current > 0) setFlashOn(false);
      settimeout1.current.push(
        setTimeout(() => {
          if (
            before_page == currentPageRef.current &&
            before_step == currentStepRef.current &&
            before_count == voiceCountRef.current &&
            !showModalRef.current &&
            !pauseRef.current
          ) {
            setFlashOn(true);
            voiceCountRef.current++;
            setVoiceCount(voiceCountRef.current);
            voicePlay();
          }
        }, 300),
      );
    } else {
      settimeout1.current.push(
        setTimeout(() => {
          if (
            before_page == currentPageRef.current &&
            before_step == currentStepRef.current &&
            before_count == voiceCountRef.current &&
            !showModalRef.current &&
            !pauseRef.current
          ) {
            if (memorize_book.auto == 1) {
              if (currentPageRef.current == mod.length - 1) {
                const visible = true;
                const onClose = () => {
                  setCountingModal(prev => ({
                    visible: false,
                    onCallback: () => {
                      return;
                    },
                    onClose: () => {
                      return;
                    },
                  }));
                };
                const onCallback = onClickAgain;

                setCountingModal({ visible, onClose, onCallback });
              } else {
                goNext();
              }
            }
          }
        }, 300),
      );
    }
  };

  useEffect(() => {
    if (percent == 100) {
      if (userStateData.show_tutorial && userStateData.show_tutorial.memorize) {
        //튜토리얼을 진행해야 한다면
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          openTutorial({ setTutorialState }, { reopen: false });
        }, 275);
        setTimeout(() => {
          setLoaded(true);
        }, 400);
      } else {
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          modal_alert.openModalAlert('memorize_start', undefined, undefined, () => {
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
          });
          setLoaded(true);
        }, 400);
      }
    }
  }, [percent]);

  useEffect(() => {
    if (show_modal) clearTimeout1();
    if (enable_keyboard) {
      bindKeyboard();
    }
    bindTouch();
    return () => {
      if (enable_keyboard) {
        unbindKeyboard();
      }
      unbindTouch();
    };
  }, [current_page, spell, show_modal, visibleOX, disableMove, pause]);

  const bindKeyboard = () => {
    if (enable_keyboard) document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const bindTouch = () => {
    document.addEventListener('touchstart', touchStart);
    document.addEventListener('touchend', touchEnd);
  };

  const unbindTouch = () => {
    document.removeEventListener('touchstart', touchStart);
    document.removeEventListener('touchend', touchEnd);
  };

  const keyboardDownEvent = (e: KeyboardEvent) => {
    const exceptModuleSentence =
      book_type === 'pocketnote' &&
      contents[current_step].type === 'sentence' &&
      ['이미지', '사전'].includes(MEMORIZE_LABEL[mod[current_page + 1].module as MemorizeIndex]);
    if (show_modal || !loaded || visibleOX) return false;
    const is_exam = mod[current_page].module == 1;

    if (e.keyCode == 39) {
      if (!exceptModuleSentence) goNext();
    } else if (e.keyCode == 37) {
      goPrev();
    } else if (e.keyCode == 38) {
      onClickVertical('up');
    } else if (e.keyCode == 40) {
      onClickVertical('down');
    } else if ((e.keyCode == 49 || e.keyCode == 97) && !show_modal) {
      if (is_exam && hideBottom) {
        onClickSelection(0);
      } else if (!disableMove) {
        onClickAgain();
      }
    } else if ((e.keyCode == 50 || e.keyCode == 98) && !show_modal) {
      if (is_exam && hideBottom) {
        onClickSelection(1);
      } else if (!disableMove) {
        onClickMemorize();
      }
    } else if ((e.keyCode == 51 || e.keyCode == 99) && !show_modal && is_exam) {
      onClickSelection(2);
    } else if ((e.keyCode == 52 || e.keyCode == 100) && !show_modal && is_exam) {
      onClickSelection(3);
    } else if (e.keyCode == 32) {
      onClickBody();
    }
  };

  const touchStart = (e: TouchEvent) => {
    if (show_modal || mod[current_page].module == 6) return false;
    startX.current = e.touches[0].pageX;
    startY.current = e.touches[0].pageY;
  };

  const touchEnd = (e: TouchEvent) => {
    if (show_modal || mod[current_page].module == 6) return false;
    const move_x =
      startX.current > e.changedTouches[0].pageX
        ? startX.current - e.changedTouches[0].pageX
        : e.changedTouches[0].pageX - startX.current;
    const move_y =
      startY.current > e.changedTouches[0].pageY
        ? startY.current - e.changedTouches[0].pageY
        : e.changedTouches[0].pageY - startY.current;

    if (move_x > move_y && move_x > screen_width / 10) {
      if (startX.current > e.changedTouches[0].pageX) {
        goNext();
      } else {
        goPrev();
      }
    } else if (move_y > move_x && move_y > screen_height / 10) {
      if (startY.current > e.changedTouches[0].pageY) {
        onClickVertical('down');
      } else {
        onClickVertical('up');
      }
    }
  };

  useEffect(() => {
    exampleVoiceRef.current?.pause();
    exampleVoiceRef.current = null;
    setContent(contents[current_step]);
    if (current_step == 0) setPrevStep(false);
    else setPrevStep(true);
    if (current_step == contents.length - 1) setNextStep(false);
    else setNextStep(true);
  }, [current_step]);

  useEffect(() => {
    if (content) {
      setExampleReady(false);
      setSpell(content['spell']);
      setMean(content['mean']);
      setExample(content['example'] ? content['example'] : '');
      setTranslate(content['translate'] ? content['translate'] : '');
      if (learningStateData.tag == 'new') setExampleTTS(content['example']);
    }
  }, [content]);

  const setExampleTTS = async (example?: string) => {
    if (example) {
      const gender = 0; // 추후 변경
      const filename = getExampleFilename(example);
      const path = filename.charAt(0).toLocaleLowerCase();
      const full_path = `examples/${gender}/${path}/${filename}.mp3`;
      const res = await fetchGetApi(`/etc/tts/example?filename=${filename}&gender=${gender}`);
      if (res.result) {
        const url = 'https://kr.object.ncloudstorage.com/longvoca-tts/' + full_path;
        exampleVoiceRef.current = new Audio(url);
        exampleVoiceRef.current.onloadedmetadata = () => setExampleReady(true);
        exampleVoiceRef.current.volume = memorize_voice.audio;
      } else {
        const postdata = {
          example: example,
          filename: filename,
          gender: gender,
        };
        await fetchPostApi(`/etc/tts/example`, postdata);
        await setExampleTTS(example);
      }
    } else {
      exampleVoiceRef.current = null;
    }
  };

  useEffect(() => {
    if (spell) {
      const voice = content['voice'] ? content['voice'] : '0.mp3';
      const image = content['image'] ? content['image'] : '0.jpg';
      const foldername = getContentFoldername(spell);
      setVoice(spell, voice);
      setImgSrc(`${CDN}${IMAGE_DIR}${foldername}/${image}`);
    }
  }, [spell]);

  useEffect(() => {
    const updatedExample = transExample();
    setExampleHTML(updatedExample);

    const updatedTranslate = transTranslate();
    setTranslateHTML(updatedTranslate);
  }, [nextDisable, example]);

  useEffect(() => {
    imgCheck();
  }, [imgSrc]);

  const imgCheck = () => {
    const img = new Image();

    img.onload = function () {
      setImgReady(true);
    };

    img.onerror = function () {
      setImgReady(false);
    };

    img.src = imgSrc;
  };

  useEffect(() => {
    if (mod[current_page].module == 6 && memorize_book.auto == 1) {
      settimeout1.current.push(
        setTimeout(() => {
          if (currentPageRef.current == mod.length - 1) {
            const visible = true;
            const onClose = () => {
              setCountingModal(prev => ({
                visible: false,
                onCallback: () => {
                  return;
                },
                onClose: () => {
                  return;
                },
              }));
            };
            const onCallback = onClickAgain;

            setCountingModal({ visible, onClose, onCallback });
          } else {
            goNext();
          }
        }, 10000),
      );
    }
  }, [current_page]);

  const getAllContents = async () => {
    const book_type =
      (userStateData.type < 20 || center_type == 'C') && learningStateData.book_type == 'schedules'
        ? 'schedules'
        : 'books';
    const all_contents =
      book_type == 'schedules'
        ? await fetchGetApi(
            `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${learningStateData.schedule_id}/words?example=true`,
          )
        : await fetchGetApi(
            `/customers/${userStateData.customer_id}/books/${learningStateData.schedule_id}/words?example=true`,
          );
    all_contents.data;
    return all_contents.data;
  };

  const setChoices = async (sheet: ResultsheetType[]) => {
    const all_contents = await getAllContents();
    const all_leng = all_contents.length;
    for (let i = 0; i < sheet.length; i++) {
      const tmp_spell = sheet[i].rightanswer as string;
      let tmp_choices: string[] = [];

      tmp_choices.push(tmp_spell);

      let cnt = 0;
      let ran_num = 0;
      // 같은 품사로 보기 채우기
      while (tmp_choices.length < 4) {
        ran_num = Math.floor(Math.random() * all_leng);
        if (all_contents[ran_num]) {
          const tmp_choice = all_contents[ran_num]['spell'];
          const tmp_answer = all_contents[ran_num]['mean'];
          const trim_choice = tmp_choice.trim();
          if (
            !tmp_choices.includes(tmp_choice) &&
            !tmp_choices.includes(trim_choice) &&
            content['mean'] != tmp_answer &&
            content['word_class'] == all_contents[ran_num]['word_class']
          ) {
            tmp_choices.push(tmp_choice);
          }
        }

        if (cnt > 1000) break;
        cnt += 1;
      }

      cnt = 0;

      // 같은 품사로 보기가 4개가 안되는 경우
      if (tmp_choices.length < 4) {
        while (tmp_choices.length < 4) {
          ran_num = Math.floor(Math.random() * all_leng);
          if (all_contents[ran_num]) {
            const tmp_choice = all_contents[ran_num]['spell'];
            const tmp_answer = all_contents[ran_num]['mean'];
            const trim_choice = tmp_choice.trim();
            if (
              !tmp_choices.includes(tmp_choice) &&
              !tmp_choices.includes(trim_choice) &&
              content['mean'] != tmp_answer
            ) {
              tmp_choices.push(tmp_choice);
            }
          }

          if (cnt > 1000) break;
          cnt += 1;
        }
      }

      tmp_choices = arrShuffle(tmp_choices);
      const rightanswer = tmp_choices.findIndex(choice => choice == sheet[i].rightanswer);
      sheet[i].rightanswer = rightanswer;
      sheet[i].choices = tmp_choices;
    }

    return sheet;
  };

  const dicIframe = () => {
    const dicUrl = `https://dic.daum.net/search.do?q=${spell}&dic=eng&search_first=N`;
    const frame = (
      <StyledDicIframeWrap>
        <Box
          id='dic_frame'
          component={'iframe'}
          sx={{ width: '100%', height: '100%', border: 'none', position: 'absolute' }}
          src={dicUrl}
        ></Box>
      </StyledDicIframeWrap>
    );
    return frame;
  };

  const exampleSpell = () => {
    if (example) {
      return (
        <Box
          sx={{
            ...d_flex_center,
            ...dir_column,
            whiteSpace: 'break-spaces',
            textAlign: 'center',
            visibility: flashOn ? 'visible' : 'hidden',
          }}
        >
          <Box
            component={'span'}
            sx={{
              fontWeight: '600',
              fontSize: is_mobile ? '4vh' : '4.5vh',
              color: '#6c6c6c',
              textAlign: 'center',
              lineHeight: '1.728',
            }}
            dangerouslySetInnerHTML={{
              __html: transSpell(),
            }}
          ></Box>
          <Box
            component={'span'}
            sx={{
              fontSize: is_mobile ? '3vh' : '3.3vh',
              color: '#6c6c6c',
              textAlign: 'center',
            }}
            dangerouslySetInnerHTML={{
              __html: transTranslate(),
            }}
          ></Box>
        </Box>
      );
    } else {
      return null;
    }
  };

  const transSpell = () => {
    if (example) {
      if (example.includes('{{') && example.includes('}}')) {
        return example.replace(/{{(.*?)}}/g, '<span style="color: #4620e9">$1</span>');
      } else {
        return example.replace(new RegExp(spell, 'ig'), '<span style="color: #4620e9">$&</span>');
      }
    } else {
      return '';
    }
  };

  const transExample = () => {
    if (example) {
      if (nextDisable) {
        const choices = resultsheet[current_step]?.choices;
        const length =
          choices && choices.length > 0
            ? Math.round(choices.reduce((total, choice) => total + choice.length, 0) / choices.length)
            : spell.length;
        const blank = '  '.repeat(length + 2);
        if (example.includes('{{') && example.includes('}}')) {
          return example.replace(
            /{{(.*?)}}/g,
            `<span style="color: #ECEAFF;background-color: #ECEAFF;border-radius: 8px;text-wrap:nowrap;">${blank}</span>`,
          );
        } else {
          const regex = new RegExp(spell, 'ig');
          const replacement = `<span style="color: #ECEAFF;background-color: #ECEAFF;border-radius: 8px;text-wrap:nowrap;">${blank}</span>`;
          return example.replace(regex, replacement);
        }
      } else {
        if (example.includes('{{') && example.includes('}}')) {
          return example.replace(/{{(.*?)}}/g, '<span style="color: #4620e9">$1</span>');
        } else {
          const regex = new RegExp(spell, 'ig');
          const replacement = '<span style="color: #4620e9; ">$&</span>';
          return example.replace(regex, replacement);
        }
      }
    } else {
      return '';
    }
  };

  const transTranslate = () => {
    if (translate) {
      return translate.replace(/{{(.*?)}}/g, '<span style="color: #4620e9; font-weight: 700;">$1</span>');
    } else {
      return '';
    }
  };

  const VoiceIcon = (bol: boolean) => {
    if (bol)
      return (
        <svg viewBox='0 0 43 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M18 3.50134L6.05859 14.5006L3.5 14.4998C2.2693 14.4998 0.5 14.3263 0.5 15.4998V25.4998C0.5 26.6736 2.2693 26.4998 3.5 26.4998H6L18 37.4998C19.4918 38.5666 21.0027 39.2778 21.0027 37.4998V3.50065C21.0027 1.72268 19.4545 2.43456 18 3.50134Z'
            fill='current'
          />
          <path
            d='M26.7745 15.3426C26.1462 14.8858 25.0992 14.8858 24.4712 15.3426C23.8429 15.7994 23.8429 16.5608 24.4712 17.0176C27.496 19.2171 27.496 22.7873 24.4712 24.9868C23.8429 25.4436 23.8429 26.205 24.4712 26.6616C24.7967 26.8985 25.2155 27 25.6111 27C26.0066 27 26.449 26.8817 26.7512 26.6447C31.079 23.5485 31.079 18.4727 26.7745 15.3426Z'
            fill='current'
          />
          <path
            d='M28.5026 8.49083C27.8325 9.14929 27.8325 10.2467 28.5026 10.9052C31.1094 13.4658 32.5496 16.8557 32.5496 20.4894C32.5496 24.123 31.1094 27.5129 28.5026 30.0735C27.8325 30.732 27.8325 31.8294 28.5026 32.4879C28.8501 32.829 29.297 33 29.7191 33C30.166 33 30.6128 32.8293 30.9356 32.5123C34.1879 29.3175 36 25.0498 36 20.5137C36 15.9777 34.2125 11.7343 30.9356 8.51522C30.2901 7.83238 29.1977 7.83238 28.5026 8.49083Z'
            fill='current'
          />
        </svg>
      );
    else
      return (
        <svg viewBox='0 0 43 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M18 3.50134L6.05859 14.5006L3.5 14.4998C2.2693 14.4998 0.5 14.3263 0.5 15.4998V25.4998C0.5 26.6736 2.2693 26.4998 3.5 26.4998H6L18 37.4998C19.4918 38.5666 21.0027 39.2778 21.0027 37.4998V3.50065C21.0027 1.72268 19.4545 2.43456 18 3.50134Z'
            fill='current'
          />
        </svg>
      );
  };

  const setCurrentStep = (step: number) => {
    stopVocie(true);
    setFlashReady(false);
    setVoiceCount(0);
    voiceCountRef.current = 0;
    voiceStop();
    if (step == current_step && current_page == 0) {
      const box = document.getElementById('contents-box');
      if (box) {
        clearTimeout1();
        clearTimeout2();
        setPause(false);
        pauseRef.current = false;
        setTimeout(() => {
          setFlashReady(true);
        }, 1);
        setTimeout(() => {
          setDisableMove(false);
        }, 1000);
        box.style.transform = 'translateY(-150%)';
        box.style.opacity = '0';
        box.style.filter = 'blur(5px)';
        box.style.transition = 'opacity 300ms, transform 300ms, filter 300ms';

        settimeout1.current.push(
          setTimeout(() => {
            box.style.transform = 'translateY(150%)';
            box.style.opacity = '0';
            box.style.filter = 'blur(5px)';
            box.style.transition = '';
            setTimeout(() => {
              box.style.transform = 'translateY(0%)';
              box.style.transition = 'opacity 300ms, transform 300ms, filter 300ms';
              box.style.opacity = '1';
              box.style.filter = 'blur(0)';
            }, 20);
          }, 140),
        );
      }
    } else {
      if (current_step > step) {
        setAnimationDirection('vertical-up');
      } else {
        setAnimationDirection('vertical-down');
      }
      setTimeout(() => {
        setVoiceStateData(prevState => ({
          ...prevState,
          element: undefined,
          playing: false,
          loading: false,
          error: false,
          ready: false,
        }));
        setLearningStateData(prevState => ({
          ...prevState,
          current_step: step,
          current_page: 0,
        }));
      }, 1);
    }
  };

  const setCurrentPage = (page: number) => {
    setFlashReady(false);
    setVoiceCount(0);
    voiceCountRef.current = 0;
    voiceStop();
    if (current_page > page) {
      setAnimationDirection('horizontal-left');
    } else {
      setAnimationDirection('horizontal-right');
    }
    stopVocie();
    setTimeout(() => {
      setLearningStateData(prevState => ({
        ...prevState,
        current_page: page,
      }));
    }, 1);
  };

  const animateShake = () => {
    const contents_box = document.getElementById('contents-box');
    if (contents_box) {
      if (contents_box.className.includes('shake-animation')) return false;
      contents_box.className = contents_box.className.replace('shake-animation', '') + ' shake-animation';
      setTimeout(() => {
        contents_box.className = contents_box.className.replace('shake-animation', '');
      }, 500);
      return false;
    }
  };

  const onClickVertical = (type: string) => {
    if (visibleOX || show_modal || disableMove) return false;

    if (type == 'up') {
      if (!prevStep) {
        if (mod[current_page].module == 1 && nextDisable) {
          return animateShake();
        } else {
          return false;
        }
      } else {
        setCurrentStep(current_step - 1);
      }
    } else if (type == 'down') {
      if (!nextStep) {
        if (mod[current_page].module == 1 && nextDisable) {
          return animateShake();
        } else {
          return false;
        }
      } else {
        setCurrentStep(current_step + 1);
      }
    }
  };

  const goNext = async () => {
    if (current_page == mod.length - 1 || nextDisable || visibleOX || disableMove) {
      if (nextDisable) {
        return animateShake();
      } else {
        return false;
      }
    }
    setCurrentPage(current_page + 1);
  };

  const goPrev = () => {
    if (current_page == 0 || visibleOX || show_modal || disableMove) {
      if (current_page == 0) {
        if (nextDisable) {
          return animateShake();
        }
      }
      return false;
    }
    setCurrentPage(current_page - 1);
  };

  const onClickAgain = async () => {
    if (visibleOX || show_modal || disableMove) return false;
    playEffectSound('show');
    let step = nextStep ? current_step + 1 : 0;
    let clear = true;
    for (let i = step; i < resultsheet.length; i++) {
      if (!resultsheet[i].memorize) {
        step = i;
        clear = false;
        break;
      }
    }
    if (clear) {
      for (let i = 0; i < current_step; i++) {
        if (!resultsheet[i].memorize) {
          step = i;
          break;
        }
      }
    }
    setCurrentStep(step);
  };

  const onClickMemorize = async () => {
    if (visibleOX || show_modal || disableMove) return false;
    stopVocie(true);
    if (userStateData.id > 5) {
      if (first_learning) {
        const prepare_record = await fetchPostApi(
          `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${learningStateData.schedule_id}/units/${learningStateData.unit_id}/records/${learningStateData.record_id}/modules`,
          { type: 'memorize', book_type: book_type },
        );
        if (prepare_record.result) {
          setLearningStateData(prevState => ({
            ...prevState,
            module_record_id: prepare_record.data.moduleRecordId,
            first_learning: false,
            save_enabled: true,
          }));
        }
      } else if (relearning) {
        const reset_record = await fetchPostApi(
          `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${learningStateData.schedule_id}/units/${learningStateData.unit_id}/records/${learningStateData.record_id}/modules/${learningStateData.module_record_id}`,
          { type: 'relearning', book_type: book_type },
        );

        if (reset_record.result) {
          setLearningStateData(prevState => ({
            ...prevState,
            module_record_id: reset_record.data.moduleRecordId,
            relearning: false,
            save_enabled: true,
          }));
        }
      } else if (reset_learning) {
        const reset_record = await fetchPostApi(
          `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${learningStateData.schedule_id}/units/${learningStateData.unit_id}/records/${learningStateData.record_id}/modules/${learningStateData.module_record_id}`,
          { type: 'reset', book_type: book_type },
        );

        if (reset_record.result) {
          setLearningStateData(prevState => ({
            ...prevState,
            reset_learning: false,
            save_enabled: true,
          }));
        }
      } else if (!save_enabled) {
        setLearningStateData(prevState => ({
          ...prevState,
          save_enabled: true,
        }));
      }
    }
    const sheet = JSON.parse(JSON.stringify(resultsheet));
    if (!sheet[current_step]['memorize']) {
      memorizeCnt.current++;
      sheet[current_step]['memorize'] = true;
      setLearningStateData(prevState => ({ ...prevState, resultsheet: sheet }));
    }
    if (memorizeCnt.current == contents.length) {
      setLearningStateData(prevState => ({
        ...prevState,
        studytime: studytime + dayjs().diff(starttime, 's'),
        show_modal: true,
      }));
      playEffectSound('correct');
      setVisibleGrading(true);
    } else {
      let step = current_step;
      for (let i = current_step; i < sheet.length; i++) {
        if (!sheet[i].memorize) {
          step = i;
          break;
        }
      }
      if (current_step == step) {
        for (let i = 0; i < current_step; i++) {
          if (!sheet[i].memorize) {
            step = i;
            break;
          }
        }
      }
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
      modal_alert.openModalAlert(
        'memorize_success',
        undefined,
        undefined,
        () => {
          setCurrentStep(step);
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: false,
          }));
        },
        1000,
      );
    }
  };

  const onClickBody = () => {
    if (mod[current_page].module == 1 && exampleVoiceRef.current) {
      if (!exampleVoiceRef.current.paused) {
        exampleVoiceRef.current.pause();
      }
      exampleVoiceRef.current.currentTime = 0;
      exampleVoiceRef.current.play();
    }
    if (mod[current_page].module == 6) return false;
    setPause(!pause);
    pauseRef.current = !pause;
    if (!pause) {
      clearTimeout1();
      setFlashOn(true);
    }
  };

  useEffect(() => {
    if (pause) {
      setFlashOn(true);
    } else if (loaded) {
      clearTimeout2();
      settimeout2.current.push(
        setTimeout(() => {
          setFlashReady(true);
        }, 1),
      );
    }
  }, [pause]);

  const toggleRepeat = (e: React.MouseEvent) => {
    e.stopPropagation();
    const newSettings = JSON.parse(JSON.stringify(moduleSettingStateData));
    setLearningStateData(prevState => ({
      ...prevState,
      show_modal: true,
    }));
    modal_alert.openModalAlert(
      memorize_book.auto == 1 ? 'memorize_auto_off' : 'memorize_auto_on',
      undefined,
      undefined,
      () => {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
        }));
      },
    );
    newSettings['memorize_book']['auto'] = memorize_book.auto == 1 ? 0 : 1;
    setModuleSettingStateData(() => ({
      ...newSettings,
    }));
    const settings: { [key: string]: { [key: string]: number } } = {
      memorize_book: newSettings['memorize_book'],
    };
    const postdata = {
      type: learningStateData.book_type,
      scheduleId: learningStateData.schedule_id,
      settings,
    };
    fetchPostApi(
      `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/settings?ver=2`,
      postdata,
    );
  };

  const toggleSpeak = (e: React.MouseEvent) => {
    e.stopPropagation();
    const newSettings = JSON.parse(JSON.stringify(moduleSettingStateData));
    setLearningStateData(prevState => ({
      ...prevState,
      show_modal: true,
    }));
    modal_alert.openModalAlert(
      memorize_voice.audio == 1 ? 'memorize_voice_off' : 'memorize_voice_on',
      undefined,
      undefined,
      () => {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
        }));
      },
    );
    newSettings['memorize_voice']['audio'] = memorize_voice.audio == 1 ? 0 : 1;
    setModuleSettingStateData(() => ({
      ...newSettings,
    }));
    const settings: { [key: string]: { [key: string]: number } } = {
      memorize_voice: newSettings['memorize_voice'],
    };
    const postdata = {
      type: learningStateData.book_type,
      scheduleId: learningStateData.schedule_id,
      settings,
    };
    fetchPostApi(
      `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/settings?ver=2`,
      postdata,
    );
  };

  const toggleBookmark = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!note_contents.some(word => word.spell == spell)) {
      setVisibleAddPocketNote(true);
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
    }
  };

  const memorizeRecycle = () => {
    modal_confirm.openModalConfirm(
      'normal',
      'recycle_memorize',
      () => {
        setVisibleGrading(false);
        setLoaded(false);
        setPercent(setLoadingStateData, 1);
        memorizeCnt.current = 0;
        const sheet: ResultsheetType[] = [];
        for (let i = 0; i < contents.length; i++) {
          sheet.push({
            no: i + 1,
            word_id: contents[i].id,
            answer: null,
            rightanswer: contents[i].spell,
            question: contents[i].mean,
            memorize: false,
          });
        }
        setLearningStateData(prevState => ({
          ...prevState,
          resultsheet: sheet,
          current_page: 0,
          current_step: 0,
          show_modal: false,
          save_enabled: false,
          relearning: true,
        }));
        setPercent(setLoadingStateData, 100);
      },
      () =>
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
        })),
    );
  };

  const sendResultsheet = async () => {
    setVisibleGrading(false);
    if (book_type == 'pocketnote') {
      navigate(`${BASE_URL}/${root_path}`);
      resetLearningState();
    } else {
      const learning_data = JSON.parse(JSON.stringify(learningStateData));
      learning_data.studytime = learning_data.studytime + dayjs().diff(learningStateData.starttime, 's');
      const result_res = await fetchPutApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${learningStateData.schedule_id}/units/${learningStateData.unit_id}/records/${learningStateData.record_id}/modules/${learningStateData.module_record_id}/result`,
        { ...learning_data },
      );
      if (result_res.result) {
        setTimeout(() => {
          navigate(`${BASE_URL}/${root_path}/${child_path}`, {
            state: {
              afterLearn: true,
            },
          });
          resetLearningState();
        }, 1);
      }
    }
  };

  const onClickSelection = async (idx: number, forced_start?: boolean) => {
    if (!forced_start && (!nextDisable || show_modal || visibleOX)) return false;
    setSelectedIndex(idx);
    selection_ref.current = idx;
    visibleOX_ref.current = true;
    if (resultsheet[current_step].rightanswer == idx) {
      const sheet = JSON.parse(JSON.stringify(resultsheet));
      sheet[current_step].answer = idx;
      setLearningStateData(prevState => ({ ...prevState, resultsheet: sheet, show_modal: true }));
      setVisibleOX(true);
      setNextDisable(false);
      if (exampleVoiceRef.current) {
        if (!exampleVoiceRef.current.paused) {
          exampleVoiceRef.current.pause();
        }
        exampleVoiceRef.current.currentTime = 0;
        exampleVoiceRef.current.onended = () => {
          setVisibleOX(false);
          visibleOX_ref.current = false;
          selection_ref.current = -1;
          setLearningStateData(prevState => ({ ...prevState, show_modal: false }));
          if (memorize_book.auto == 1) {
            if (currentPageRef.current == mod.length - 1) {
              const visible = true;
              const onClose = () => {
                setCountingModal(prev => ({
                  visible: false,
                  onCallback: () => {
                    return;
                  },
                  onClose: () => {
                    return;
                  },
                }));
              };
              const onCallback = onClickAgain;

              setCountingModal({ visible, onClose, onCallback });
            } else {
              setCurrentPage(current_page + 1);
            }
          }
          if (exampleVoiceRef.current) exampleVoiceRef.current.onended = null;
        };
        if (!forced_start) playEffectSound('correct');
        if (!forced_start) await new Promise(resolve => setTimeout(resolve, 1500));
        exampleVoiceRef.current.play();
      } else {
        setTimeout(() => {
          setVisibleOX(false);
          visibleOX_ref.current = false;
          selection_ref.current = -1;
          setLearningStateData(prevState => ({ ...prevState, show_modal: false }));
          if (memorize_book.auto == 1) {
            if (currentPageRef.current == mod.length - 1) {
              const visible = true;
              const onClose = () => {
                setCountingModal(prev => ({
                  visible: false,
                  onCallback: () => {
                    return;
                  },
                  onClose: () => {
                    return;
                  },
                }));
              };
              const onCallback = onClickAgain;

              setCountingModal({ visible, onClose, onCallback });
            } else {
              setCurrentPage(current_page + 1);
            }
          }
        }, 2000);
      }
    } else {
      setVisibleOX(true);
      if (exampleVoiceRef.current) {
        if (!exampleVoiceRef.current.paused) {
          exampleVoiceRef.current.pause();
        }
        exampleVoiceRef.current.currentTime = 0;
        exampleVoiceRef.current.onended = () => {
          setVisibleOX(false);
          visibleOX_ref.current = false;
          selection_ref.current = -1;
          setSelectedIndex(null);
          setLearningStateData(prevState => ({ ...prevState, show_modal: false }));
        };
        if (!forced_start) await playEffectSound('wrong');
        exampleVoiceRef.current.play();
      } else {
        setTimeout(() => {
          setVisibleOX(false);
          visibleOX_ref.current = false;
          selection_ref.current = -1;
          setSelectedIndex(null);
          setLearningStateData(prevState => ({ ...prevState, show_modal: false }));
        }, 2000);
      }
    }
  };

  const exampleSentence = () => {
    if (example) {
      return (
        <Box
          sx={{
            width: '100%',
            ...d_flex_center,
            ...dir_column,
            whiteSpace: 'break-spaces',
            textAlign: 'center',
          }}
        >
          <Box
            component={'span'}
            sx={{
              fontWeight: '600',
              fontSize: is_mobile ? '4vh' : '4.5vh',
              color: '#6c6c6c',
              textAlign: 'center',
              lineHeight: '1.728',
            }}
            dangerouslySetInnerHTML={{
              __html: exampleHTML,
            }}
          ></Box>
          <Box
            component={'span'}
            sx={{
              fontSize: is_mobile ? '3.15vh' : '3.6vh',
              fontWeight: '300',
              color: '#6c6c6c',
              textAlign: 'center',
            }}
            dangerouslySetInnerHTML={{
              __html: translateHTML,
            }}
          ></Box>
        </Box>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <MemorizeStepper
        next_disable={nextDisable}
        animateShake={animateShake}
        move_disable={nextDisable || disableMove || visibleOX}
        setCurrentStep={setCurrentStep}
        setCurrentPage={setCurrentPage}
      />
      <OXMark
        show={visibleOX}
        right={resultsheet[current_step] && resultsheet[current_step].answer == resultsheet[current_step].rightanswer}
      ></OXMark>
      <StyledMemorizeWrap is_mobile={mobileResolution ? 1 : 0}>
        {memorizeCnt.current > 0 ? (
          <StyeldRecycleIconButton is_mobile={mobileResolution ? 1 : 0} onClick={() => memorizeRecycle()} disableRipple>
            <ReplayCircleFilledIcon />
          </StyeldRecycleIconButton>
        ) : null}
        <StyledMemorizeBody id='contents-wrap' sx={{ height: 'calc(100% - 4rem)' }}>
          <StyledTopButtonBox>
            <StyledIconButton
              onClick={() => onClickVertical('up')}
              sx={{
                cursor: !prevStep || disableMove ? 'default' : 'pointer',
                svg: {
                  color: !prevStep || disableMove ? '#e8e9ec' : '#b3b7bf',
                },
              }}
              disableRipple
            >
              <AiOutlineUp />
            </StyledIconButton>
            {prevStep && !mobileResolution ? (
              <StyledTextBox
                onClick={() => onClickVertical('up')}
                component={'span'}
                sx={{
                  marginTop: '-0.5rem',
                  position: 'relative',
                  left: '-0.05rem',
                  cursor: !prevStep || disableMove ? 'default' : 'pointer',
                  color: !prevStep || disableMove ? '#e8e9ec' : '#b3b7bf',
                }}
              >
                이전
              </StyledTextBox>
            ) : !mobileResolution ? (
              <StyledTextBox component={'span'}>&nbsp;</StyledTextBox>
            ) : null}
          </StyledTopButtonBox>
          <SwitchTransition mode='in-out'>
            <CSSTransition key={`${current_page}-${current_step}`} classNames={animationDirection} timeout={300}>
              <StyledMemorizeContentWrap
                id='contents-box'
                sx={{
                  // justifyContent: mod[current_page].module == 6 ? 'flex-start' : 'center',
                  justifyContent: 'flex-start',
                  height: mod[current_page].module == 6 ? 'max-content' : 'calc(100% - 9.5rem)',
                }}
              >
                <StyledMemorizeContents
                  is_exam={mod[current_page].module == 1 ? 1 : 0}
                  is_dic={mod[current_page].module == 6 ? 1 : 0}
                  is_mobile={mobileResolution ? 1 : 0}
                  onClick={() => onClickBody()}
                >
                  <StyledLeftHeaderBox sx={{ justifyContent: 'flex-start' }}>
                    <CustomSpeakButton
                      disableRipple
                      onClick={(e: React.MouseEvent) => toggleSpeak(e)}
                      speak={memorize_voice.audio}
                    >
                      {VoiceIcon(memorize_voice.audio == 1 ? true : false)}
                      {memorize_voice.audio == 1 ? null : <CustomDiagonalLine />}
                    </CustomSpeakButton>
                    {book_type != 'pocketnote' ? (
                      <CustomRepeatButton
                        disableRipple
                        onClick={(e: React.MouseEvent) => toggleRepeat(e)}
                        repeat={memorize_book.auto}
                      >
                        <Repeat />
                      </CustomRepeatButton>
                    ) : null}
                  </StyledLeftHeaderBox>
                  <StyledRightHeaderBox sx={{ justifyContent: 'flex-end' }}>
                    {book_type == 'pocketnote' ? (
                      <CustomRepeatButton
                        disableRipple
                        onClick={(e: React.MouseEvent) => toggleRepeat(e)}
                        repeat={memorize_book.auto}
                      >
                        <Repeat />
                      </CustomRepeatButton>
                    ) : (
                      <CustomBookmarkButton
                        disableRipple
                        onClick={(e: React.MouseEvent) => toggleBookmark(e)}
                        bookmark={note_contents.some(word => word.spell == content.spell) ? 1 : 0}
                      >
                        <FiStar />
                      </CustomBookmarkButton>
                    )}
                  </StyledRightHeaderBox>

                  <StyledContentsBody
                    is_exam={mod[current_page].module == 1 ? 1 : 0}
                    is_dic={mod[current_page].module == 6 ? 1 : 0}
                    is_mobile={mobileResolution ? 1 : 0}
                  >
                    {(function () {
                      if (mod[current_page].module == 1) {
                        return exampleSentence();
                      } else if (mod[current_page].module == 4) {
                        return (
                          <StyledImageBox is_mobile={mobileResolution ? 1 : 0}>
                            <Box
                              sx={{
                                overflow: 'hidden',
                                visibility: flashOn ? 'visible' : 'hidden',
                              }}
                            >
                              <StyledSpellAutoText
                                maxFontSize={10}
                                minFontSize={8}
                                lineHeight={1}
                                fontSizeUnit={'vh'}
                                overflowY='visible'
                                sx={{
                                  letterSpacing: screen_width < 1024 ? '-1px' : '-3px',
                                  paddingBottom: '1vh',
                                }}
                              >
                                {spell}
                              </StyledSpellAutoText>
                            </Box>
                            {exampleSpell()}
                          </StyledImageBox>
                        );
                      } else if (mod[current_page].module == 3) {
                        return (
                          <StyledMeanAutoText
                            maxFontSize={8}
                            minFontSize={6.4}
                            lineHeight={1}
                            fontSizeUnit={'vh'}
                            sx={{
                              visibility: flashOn ? 'visible' : 'hidden',
                            }}
                          >
                            {mean}
                          </StyledMeanAutoText>
                        );
                      } else if (mod[current_page].module == 5) {
                        return (
                          <>
                            {flashOn ? (
                              <StyledImageBox is_mobile={mobileResolution ? 1 : 0}>
                                {imgReady ? (
                                  <Box
                                    sx={{
                                      width: '100%',
                                      height: '80%',
                                      position: 'relative',
                                      overflow: 'hidden',
                                      borderRadius: '1.5rem',
                                      '&:before': {
                                        content: '""',
                                        display: 'block',
                                      },
                                    }}
                                  >
                                    <Box
                                      component={'img'}
                                      src={imgSrc}
                                      sx={{
                                        width: 'auto',
                                        maxWidth: '100%',
                                        height: 'fit-content',
                                        maxHeight: '100%',
                                        objectFit: 'contain',
                                        borderRadius: '1.5rem',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        clipPath: 'inset(0 0 0 0 round 1.5rem)',
                                      }}
                                    ></Box>
                                  </Box>
                                ) : null}
                              </StyledImageBox>
                            ) : null}
                          </>
                        );
                      } else if (mod[current_page].module == 6) {
                        return dicIframe();
                      }
                    })()}
                  </StyledContentsBody>
                  {mod[current_page].module === 1 ? null : (
                    <StyledContentsBottom>
                      {(() => {
                        const arr = [];
                        for (let i = 1; i <= maxVoiceCount; i++) {
                          arr.push(
                            <StyledProgressBox
                              key={`memorize_progress_${current_step}_${i}`}
                              progress={voiceCount >= i ? 1 : 0}
                            />,
                          );
                        }
                        return arr;
                      })()}
                    </StyledContentsBottom>
                  )}
                </StyledMemorizeContents>
                {mod[current_page].module == 1 && loaded ? (
                  <StyledSelectionWrap>
                    {resultsheet[current_step].choices !== undefined ? (
                      <>
                        {resultsheet[current_step].choices?.map((choice, idx) => (
                          <StyledSelectionBox
                            sx={{
                              marginLeft: idx % 2 == 1 ? (mobileResolution ? '2px' : '4px') : '',
                              marginRight: idx % 2 == 0 ? (mobileResolution ? '2px' : '4px') : '',
                            }}
                            is_mobile={mobileResolution ? 1 : 0}
                            status={
                              selectedIndex == idx
                                ? resultsheet[current_step].rightanswer == selectedIndex
                                  ? 'right'
                                  : 'wrong'
                                : ''
                            }
                            key={`selection_${idx}`}
                            onClick={() => onClickSelection(idx)}
                          >
                            <Box component={'span'} sx={{ fontSize: mobileResolution ? '3.5vh' : '4vh' }}>
                              {choice}
                            </Box>
                          </StyledSelectionBox>
                        ))}
                      </>
                    ) : null}
                  </StyledSelectionWrap>
                ) : null}
              </StyledMemorizeContentWrap>
            </CSSTransition>
          </SwitchTransition>
          <StyledBottomButtonBox>
            {nextStep && !mobileResolution ? (
              <StyledTextBox
                onClick={() => onClickVertical('down')}
                component={'span'}
                sx={{
                  marginBottom: '-0.5rem',
                  position: 'relative',
                  left: '-0.05rem',
                  cursor: !nextStep || disableMove ? 'default' : 'pointer',
                  color: !nextStep || disableMove ? '#e8e9ec' : '#b3b7bf',
                }}
              >
                다음
              </StyledTextBox>
            ) : !mobileResolution ? (
              <StyledTextBox component={'span'}>&nbsp;</StyledTextBox>
            ) : null}
            <StyledIconButton onClick={() => onClickVertical('down')} disabled={!nextStep || disableMove} disableRipple>
              <AiOutlineDown />
            </StyledIconButton>
          </StyledBottomButtonBox>
        </StyledMemorizeBody>
        <StyledLeftButtonBox is_mobile={mobileResolution ? 1 : 0} onClick={() => goPrev()}>
          <StyledIconButton disabled={current_page == 0 || disableMove} disableRipple>
            <AiOutlineLeft />
          </StyledIconButton>
          {current_page > 0 && !mobileResolution ? (
            <StyledTextBox
              component={'span'}
              sx={{
                marginLeft: '-0.5rem',
                cursor: current_page == 0 || disableMove ? 'default' : 'pointer',
                color: current_page == 0 || disableMove ? '#e8e9ec' : '#b3b7bf',
              }}
            >
              {MEMORIZE_LABEL[mod[current_page - 1].module as MemorizeIndex]}
            </StyledTextBox>
          ) : null}
        </StyledLeftButtonBox>
        {book_type === 'pocketnote' && contents[current_step].type === 'sentence' ? (
          <StyledRightButtonBox
            is_mobile={mobileResolution ? 1 : 0}
            onClick={
              ['이미지', '사전'].includes(MEMORIZE_LABEL[mod[current_page + 1].module as MemorizeIndex])
                ? undefined
                : () => goNext()
            }
          >
            {current_page < mod.length - 1 && !mobileResolution ? (
              <StyledTextBox
                sx={{
                  marginRight: '-0.5rem',
                  cursor: current_page == mod.length - 1 || disableMove ? 'default' : 'pointer',
                  color: current_page == mod.length - 1 || disableMove ? '#e8e9ec' : '#b3b7bf',
                }}
                component={'span'}
              >
                {['이미지', '사전'].includes(MEMORIZE_LABEL[mod[current_page + 1].module as MemorizeIndex])
                  ? null
                  : MEMORIZE_LABEL[mod[current_page + 1].module as MemorizeIndex]}
              </StyledTextBox>
            ) : null}
            <StyledIconButton
              disabled={
                current_page == mod.length - 1 ||
                disableMove ||
                ['이미지', '사전'].includes(MEMORIZE_LABEL[mod[current_page + 1].module as MemorizeIndex])
              }
              disableRipple
            >
              <AiOutlineRight />
            </StyledIconButton>
          </StyledRightButtonBox>
        ) : (
          <StyledRightButtonBox is_mobile={mobileResolution ? 1 : 0} onClick={() => goNext()}>
            {current_page < mod.length - 1 && !mobileResolution ? (
              <StyledTextBox
                sx={{
                  marginRight: '-0.5rem',
                  cursor: current_page == mod.length - 1 || disableMove ? 'default' : 'pointer',
                  color: current_page == mod.length - 1 || disableMove ? '#e8e9ec' : '#b3b7bf',
                }}
                component={'span'}
              >
                {MEMORIZE_LABEL[mod[current_page + 1].module as MemorizeIndex]}
              </StyledTextBox>
            ) : null}
            <StyledIconButton disabled={current_page == mod.length - 1 || disableMove} disableRipple>
              <AiOutlineRight />
            </StyledIconButton>
          </StyledRightButtonBox>
        )}

        <StyledMemorizeBottom sx={{ visibility: hideBottom ? 'hidden' : 'visible' }}>
          {book_type != 'pocketnote' ? (
            <StyledBottomLeftButton
              onClick={() => onClickAgain()}
              disableRipple
              disabled={resultsheet[current_step]?.memorize}
            >
              다시 암기
            </StyledBottomLeftButton>
          ) : null}
          <StyledBottomRightButton
            sx={{ width: book_type == 'pocketnote' ? '100%' : '50%' }}
            onClick={() => onClickMemorize()}
            disableRipple
            disabled={resultsheet[current_step]?.memorize}
          >
            암기 완료
          </StyledBottomRightButton>
        </StyledMemorizeBottom>
        <ModalAddPocketNote
          visible={visibleAddPocketNote}
          onClose={() => {
            setVisibleAddPocketNote(false);
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
          }}
          content={[content]}
        />
        <ModalGrading
          visible={visibleGrading}
          onClose={() => sendResultsheet()}
          onCallback={() => memorizeRecycle()}
          type='memorize'
          studytime={Math.round(studytime / 60)}
        ></ModalGrading>
        <ModalCounting
          visible={countingModal.visible}
          onClose={countingModal.onClose}
          onCallback={countingModal.onCallback}
        />
      </StyledMemorizeWrap>
    </>
  );
}

export default Memorize;
