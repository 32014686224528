import {
  BirthDateInputWrap,
  CheckBoxWrapper,
  ContentWrapper,
  FormWrap,
  FormWrapper,
  InputTextStyle,
  LoadingFilter,
  ModalBackground,
  ModalCloseButton,
  ModalContainer,
  RegisterCardButton,
} from './ModalCardInfo.Styles';

import { Checkbox, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CardInfoType, checkboxData, promotionCheckboxData } from './ModalCardInfo.Container';
import { MdOutlineClose } from 'react-icons/md';

export interface ModalCardInfoProps {
  handleClose: () => void;
  cardNumber: string;
  handleCardNumberInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBirthDateInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleMonthChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  handleYearChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
  handleTermsButtonClick: (id: string) => void;
  selectedYear: string;
  selectedMonth: string;
  futureYears: number[];
  birthDate: string;
  checkedIds: string[];
  handleCheckboxChange: (id: string) => void;
  handleCardPaymentButtonClick: (cardInfo: CardInfoType) => void;
  checkBoxSelectAll: boolean;
  isLoading: boolean;
  currentPage: string;
  isCorporation: boolean;
  selectedProduct: string;
  installmentMonths: string;
  handleInstallmentMonthChange: (event: SelectChangeEvent<string>) => void;
  handlePromotionCheckboxChange: (id: string) => void;
  top: string;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const ModalCardInfoUI = ({
  handleClose,
  cardNumber,
  handleCardNumberInputChange,
  handleBirthDateInputChange,
  handleYearChange,
  handleMonthChange,
  selectedMonth,
  selectedYear,
  futureYears,
  birthDate,
  checkedIds,
  currentPage,
  handleCheckboxChange,
  handleCardPaymentButtonClick,
  handleTermsButtonClick,
  checkBoxSelectAll,
  isLoading,
  isCorporation,
  selectedProduct,
  installmentMonths,
  handleInstallmentMonthChange,
  handlePromotionCheckboxChange,
  top,
  onFocus,
  onBlur,
}: ModalCardInfoProps) => {
  const MenuProps = {
    PaperProps: {
      style: {
        height: 200,
      },
    },
  };

  const isMonthProduct = selectedProduct === 'month';
  const paymentButtonDisabled =
    selectedYear &&
    selectedMonth &&
    (isCorporation ? birthDate.length == 12 : birthDate.length == 6) &&
    cardNumber.length >= 18 &&
    checkBoxSelectAll
      ? false
      : true;

  return (
    <ModalBackground>
      <ModalContainer
        sx={{
          top,
        }}
      >
        <ModalCloseButton onClick={handleClose}>
          <MdOutlineClose />
        </ModalCloseButton>
        {currentPage === 'main' ? (
          <ContentWrapper>
            {isLoading && <LoadingFilter className={isMonthProduct ? 'month-product' : ''} />}
            <FormWrapper>
              <FormWrap>
                <span style={{ width: '5.6rem' }}>카드번호</span>
                <InputTextStyle
                  className='card-number'
                  placeholder='카드번호를 기입하세요.'
                  value={cardNumber}
                  onChange={handleCardNumberInputChange}
                />
              </FormWrap>
              <FormWrap>
                <span style={{ width: '6.7rem' }}>유효기간</span>
                <Select
                  displayEmpty
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  renderValue={(selected: string) => (selected ? selected : 'MM')}
                  sx={{
                    color: selectedMonth ? '' : '#cbcfda',
                    width: '50%',
                    height: '2.5rem',
                    borderRadius: '0',
                    '&:hover': {
                      '&& fieldset': {
                        border: '1px solid #c4c4c4',
                      },
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #c4c4c4',
                    },
                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid red',
                    },
                  }}
                  className='dropdown-year'
                  MenuProps={MenuProps}
                >
                  {Array.from({ length: 12 }, (_, index) => (
                    <MenuItem key={index + 1} value={String(index + 1)}>
                      {String(index + 1).padStart(2, '0')}
                    </MenuItem>
                  ))}
                </Select>
                <span style={{ marginLeft: '-0.5rem' }}>월</span>
                <Select
                  displayEmpty
                  onChange={handleYearChange}
                  value={selectedYear}
                  renderValue={(selected: string) => (selected ? selected : 'YY')}
                  sx={{
                    width: '50%',
                    height: '2.5rem',
                    borderRadius: '0',
                    color: selectedYear ? '' : '#cbcfda',
                    '&:hover': {
                      '&& fieldset': {
                        border: '1px solid #c4c4c4',
                      },
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid #c4c4c4',
                    },
                  }}
                  className='dropdown-year'
                  MenuProps={MenuProps}
                >
                  {futureYears.map(year => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
                <span style={{ marginLeft: '-0.5rem' }}>년</span>
              </FormWrap>
              <BirthDateInputWrap>
                <span style={{ width: '4.29rem' }}>{isCorporation ? '사업자번호' : '생년월일'}</span>
                <InputTextStyle
                  id={'brnOrBirth'}
                  placeholder={isCorporation ? '숫자 10자리' : 'YYMMDD'}
                  value={birthDate}
                  onChange={handleBirthDateInputChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  sx={{ width: isCorporation ? '79%' : '38%' }}
                />
                {!isCorporation && <div style={{ color: '#cbcbd7' }}>{`(주민등록번호 앞자리)`}</div>}
              </BirthDateInputWrap>
              {!isMonthProduct && (
                <>
                  <div key={checkboxData[0].id} className='check-box-corporation'>
                    <Checkbox
                      color='purple'
                      checked={checkedIds.includes(checkboxData[0].id)}
                      onChange={() => handleCheckboxChange(checkboxData[0].id)}
                    />
                    <span
                      onClick={() => handleCheckboxChange(checkboxData[0].id)}
                      style={{ marginRight: '0.1rem', cursor: 'pointer' }}
                    >
                      {checkboxData[0].label}
                    </span>
                    {checkboxData[0].notice && (
                      <span
                        onClick={() => handleTermsButtonClick(checkboxData[0].id)}
                        className='read-notice'
                      >{`${checkboxData[0].notice}`}</span>
                    )}
                  </div>
                  <FormWrap>
                    <span style={{ width: '5.6rem' }}>할부기간</span>
                    <Select
                      displayEmpty
                      value={installmentMonths}
                      onChange={handleInstallmentMonthChange}
                      renderValue={(selected: string) => (selected ? selected : '일시불')}
                      sx={{
                        width: '100%',
                        height: '2.5rem',
                        borderRadius: '0',
                        '&:hover': {
                          '&& fieldset': {
                            border: '1px solid #c4c4c4',
                          },
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid #c4c4c4',
                        },
                      }}
                      MenuProps={MenuProps}
                    >
                      {Array.from({ length: 12 }, (_, index) => (
                        <MenuItem key={index + 1} value={String(index + 1)}>
                          {index + 1 === 1 ? '일시불' : `${String(index + 1)}개월`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormWrap>
                </>
              )}
            </FormWrapper>
            <CheckBoxWrapper>
              {(isMonthProduct ? checkboxData : promotionCheckboxData).map((checkbox, index) => (
                <>
                  <div key={checkbox.id} className='check-box'>
                    <Checkbox
                      color='purple'
                      checked={checkedIds.includes(checkbox.id)}
                      onChange={
                        isMonthProduct
                          ? () => handleCheckboxChange(checkbox.id)
                          : () => handlePromotionCheckboxChange(checkbox.id)
                      }
                    />
                    <span
                      onClick={
                        isMonthProduct
                          ? () => handleCheckboxChange(checkbox.id)
                          : () => handlePromotionCheckboxChange(checkbox.id)
                      }
                      style={{ marginRight: '0.1rem', cursor: 'pointer' }}
                    >
                      {checkbox.label}
                    </span>
                    {checkbox.notice && (
                      <span
                        onClick={() => handleTermsButtonClick(checkbox.id)}
                        className='read-notice'
                      >{`${checkbox.notice}`}</span>
                    )}
                  </div>
                  {index === 0 ? (
                    <div style={{ width: '120%', border: '1px solid #e9e9e9', margin: '0.2rem 0 0.5rem 2.5rem' }} />
                  ) : null}
                </>
              ))}
            </CheckBoxWrapper>
            <RegisterCardButton
              onClick={
                checkBoxSelectAll && !isLoading
                  ? () =>
                      handleCardPaymentButtonClick({
                        cardNumber,
                        month: selectedMonth,
                        year: selectedYear,
                        birthDate,
                        installmentMonths,
                      })
                  : undefined
              }
              variant='contained'
              disabled={paymentButtonDisabled}
            >
              {isLoading ? '카드결제 등록 진행중.....' : '카드결제 등록'}
            </RegisterCardButton>
          </ContentWrapper>
        ) : (
          <div style={{ height: '100%', overflow: 'auto' }}>
            <div
              className='show-scrollbar'
              style={{ height: currentPage === 'eula' ? '36rem' : '100%', overflow: 'auto', marginBottom: '1rem' }}
            >
              <p
                style={{
                  height: 'max-content',
                  fontSize: '0.9rem',
                  color: 'gray',
                  padding: currentPage === 'eula' ? '0 1rem 0 1rem' : '',
                }}
                dangerouslySetInnerHTML={{
                  __html: checkboxData.filter(item => item.id === currentPage)[0].content as string,
                }}
              ></p>
            </div>
            <RegisterCardButton
              sx={{ marginTop: '1rem' }}
              variant='contained'
              onClick={() => handleTermsButtonClick('main')}
            >
              닫기
            </RegisterCardButton>
          </div>
        )}
      </ModalContainer>
    </ModalBackground>
  );
};
