import {
  LoadingBox,
  CircularProgressBottom,
  CircularProgressTop,
  LoadingLabel,
  DuplicateLabel,
} from './Loading.Styles';
import { circularProgressClasses, BoxProps } from '@mui/material';

export interface LoadingVAProps extends BoxProps {
  progress: number;
  duplicate: boolean;
  duplicateLoading: boolean;
}

export function LoadingUI({ duplicate, duplicateLoading, progress, sx }: LoadingVAProps) {
  return (
    <LoadingBox sx={sx}>
      <CircularProgressBottom variant='determinate' size={'9rem'} thickness={4} value={100} />
      <CircularProgressTop
        variant='indeterminate'
        disableShrink
        sx={{
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={'9rem'}
        thickness={4}
      />
      <LoadingLabel>{progress}%</LoadingLabel>
      {!duplicate && !duplicateLoading ? null : (
        <DuplicateLabel>
          {duplicate && duplicateLoading
            ? '중복 단어를 제거하여 생성중입니다.'
            : !duplicate && duplicateLoading
            ? '중복 단어를 복원하여 생성중입니다.'
            : ''}
        </DuplicateLabel>
      )}
    </LoadingBox>
  );
}
