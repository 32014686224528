export interface Category {
  categoryId: string;
  parentId: string;
  name: string;
}

let id = 0;

const P_lv1Categories = ['유아', '초등', '중등', '고등', '기타'];
const C_lv1Categories = ['학원', '교습소', '공부방', '학교', '기관'];
const P_lv2Categories = [
  ['5세', '6세', '7세'],
  ['1학년', '2학년', '3학년', '4학년', '5학년', '6학년'],
  ['1학년', '2학년', '3학년'],
  ['1학년', '2학년', '3학년'],
  ['재수생', '공무원 공부', '토익준비', '일반인'],
];
const C_lv2Categories = ['5명 미만', '10명~15명', '15명~30명', '30명~50명', '50명~100명', '100명~300명', '300명 이상'];

const generateCategories = (parentId: number | string, level: number, type: number): Category[] => {
  if (level == 1) {
    const categories =
      type == 0 ? JSON.parse(JSON.stringify(P_lv1Categories)) : JSON.parse(JSON.stringify(C_lv1Categories));

    return new Array(categories.length).fill(undefined).map((item, idx) => {
      const categoryId = id + 1;
      id += 1;
      return {
        categoryId: `${categoryId}`,
        name: `${categories[idx]}`,
        parentId: `${parentId}`,
      };
    });
  } else {
    if (type == 0) {
      const categories = JSON.parse(JSON.stringify(P_lv2Categories[Number(parentId) - 1]));
      return new Array(categories.length).fill(undefined).map((item, idx) => {
        const categoryId = id + 1;
        id += 1;
        return {
          categoryId: `${categoryId}`,
          name: `${categories[idx]}`,
          parentId: `${parentId}`,
        };
      });
    } else {
      const categories = JSON.parse(JSON.stringify(C_lv2Categories));
      return new Array(categories.length).fill(undefined).map((item, idx) => {
        const categoryId = id + 1;
        id += 1;
        return {
          categoryId: `${categoryId}`,
          name: `${categories[idx]}`,
          parentId: `${parentId}`,
        };
      });
    }
  }
};

const p_lv1Categories = generateCategories(0, 1, 0);

const p_lv2Categories = p_lv1Categories.map(({ categoryId }) => generateCategories(categoryId, 2, 0)).flat();

const p_categories: Category[] = [...p_lv1Categories, ...p_lv2Categories];

const c_lv1Categories = generateCategories(0, 1, 1);

const c_lv2Categories = c_lv1Categories.map(({ categoryId }) => generateCategories(categoryId, 2, 1)).flat();

const c_categories: Category[] = [...c_lv1Categories, ...c_lv2Categories];

export const getCategoriesByParentId = (parentId: string | number, type: number) => {
  if (type == 0) {
    return p_categories.filter(category => category.parentId === `${parentId}`);
  } else {
    return c_categories.filter(category => category.parentId === `${parentId}`);
  }
};

// export const getHierarchyCategories = (categories: Category[]) =>
//   categories.map((category) => ({
//     ...category,
//     children: getCategoriesByParentId(category.parentId, type),
//   }))

// export const getCategoriesMap = (
//   categories: Category[],
// ): Record<string, Category[]> =>
//   categories.reduce(
//     (prev, { categoryId }) => ({
//       ...prev,
//       [categoryId]: getCategoriesByParentId(categoryId, type),
//     }),
//     {},
//   )
