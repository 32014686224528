export {};
import { atom, SetterOrUpdater, useRecoilValue } from 'recoil';

export interface LoadingCircleSpinnerDataType {
  visible: boolean;
  ballCount: number;
  content: string;
  timeout?: { enter: number; exit: number };
  thumbnailUrl?: string;
  isSpeak?: boolean;
}

export const LoadingCircleSpinnerState = atom<LoadingCircleSpinnerDataType>({
  key: 'loadingCircleSpinner',
  default: {
    visible: false,
    ballCount: 8,
    content: '',
    thumbnailUrl: undefined,
    isSpeak: undefined,
  },
});

interface LoadingCircleSpinnerSetter {
  setLoadingCircleSpinner: SetterOrUpdater<LoadingCircleSpinnerDataType>;
}

export const openLoadingCircleSpinner = (
  { setLoadingCircleSpinner }: LoadingCircleSpinnerSetter,
  ballCount: number,
  content: string,
  timeout?: { enter: number; exit: number },
) => {
  setLoadingCircleSpinner(prevState => {
    return {
      ...prevState,
      visible: true,
      ballCount,
      content,
      timeout,
    };
  });
};

export const changeLoadingCircleSpinner = (
  { setLoadingCircleSpinner }: LoadingCircleSpinnerSetter,
  content: string,
  thumbnailUrl: string,
  isSpeak: boolean,
) => {
  setLoadingCircleSpinner(prevState => {
    return {
      ...prevState,
      content,
      thumbnailUrl,
      isSpeak,
    };
  });
};

export const closeLoadingCircleSpinner = (
  { setLoadingCircleSpinner }: LoadingCircleSpinnerSetter,
  timeout?: { enter: number; exit: number },
) => {
  setLoadingCircleSpinner(prevState => {
    return {
      ...prevState,
      visible: false,
      timeout,
      thumbnailUrl: undefined,
      isSpeak: undefined,
    };
  });
};
