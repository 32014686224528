import { FormControl, Button, Box, IconButton, IconButtonProps, imageListClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect, useContext } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { voiceState, useVoice } from '../../recoil/common/voice';
import Modal from '@mui/material/Modal';
import { CDN, IMAGE_DIR } from '../../utils/constants';
import { d_flex_center } from '../../styles/common';
import { FiStar } from 'react-icons/fi';
import { getContentFoldername } from '../../utils/tools';
import { pocketNoteState } from '../../recoil/model/pocket_note';
import ModalAddPocketNote from './ModalAddPocketNote';
import { deviceState } from '../../recoil/common/device';
import AutoText from '../text/AutoText';
import { userState } from '../../recoil/model/user';
import { ModalContext } from '../../provider/ModalProvider';
import { BackgroundSoundContext } from 'provider/BackgroundSoundProvider';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { toast_contents } from 'utils/modal_contents';

interface ModalWordDetailProps {
  visible: boolean;
  onClose: () => void;
  show_bookmark?: boolean;
  content: ContentType;
}

// style
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '50%',
  maxWidth: '80%',
  width: '70rem',
  height: 'auto',
  '@media(max-width: 1023px)': {
    width: '55rem',
  },
};

const StyledModalWrapper = styled(FormControl)(props => ({
  width: '100%',
  alignItems: 'center',
  backgroundColor: '#fff',
  '&:focus-visible': {
    outline: 'none',
  },
  borderRadius: '1rem',
}));

interface CustomIconButtonProps extends IconButtonProps {
  bookmark: number;
}

const CustomSpeakerButton = styled(IconButton)(props => ({
  zIndex: 1,
  position: 'absolute',
  top: '0.5rem',
  left: '0.5rem',
  '@media(max-width: 1904px)': {
    top: '0.4rem',
    left: '0.45rem',
  },
  '@media(max-width: 1264px)': {
    top: '0.2rem',
    left: '0.25rem',
  },
  '@media(max-width: 768px)': {
    top: '0.2rem',
    left: '0.3rem',
  },
  '@media(max-width: 667px)': {
    top: '0.2rem',
    left: '0.35rem',
  },
  svg: {
    color: props.theme.palette.purple.main,
    width: '2rem',
    height: '2rem',
    fill: props.theme.palette.purple.main,
  },
}));

const CustomBookmarkButton = styled(IconButton)<CustomIconButtonProps>(props => ({
  zIndex: 1,
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
  '@media(max-width: 1904px)': {
    top: '0.4rem',
    right: '0.4rem',
  },
  '@media(max-width: 1264px)': {
    top: '0.2rem',
    right: '0.2rem',
  },
  svg: {
    color: props.bookmark == 1 ? '#f1bc5f' : props.theme.palette.purple.main,
    width: '2rem',
    height: '2rem',
    fill: props.bookmark == 1 ? '#f5ff1b' : 'none',
  },
}));

const StyledModalBody = styled(Box)(props => ({
  padding: '0.525rem 1rem 0.5rem 1rem',
  ...d_flex_center,
  width: '100%',
  flexDirection: 'column',
}));

const StyledSpellAutoText = styled(AutoText)(props => ({
  fontFamily: "'KCC-Ganpan' !important",
  fontWeight: '500',
  color: 'var(--purple)',
  textAlign: 'center',
  whiteSpace: 'break-spaces',
  paddingBottom: '1vh',
}));

const StyledMeanAutoText = styled(AutoText)(props => ({
  width: '80%',
  color: 'var(--black-2)',
  textAlign: 'center',
  whiteSpace: 'break-spaces',
}));

const StyledModalUtil = styled(Box)(props => ({
  border: 'none',
  background: 'linear-gradient(#652ae9, #4620e9)',
  width: '100%',
  height: '60px',
  maxHeight: '10vh',
  ...d_flex_center,
  cursor: 'pointer',
  borderBottomLeftRadius: '1rem',
  borderBottomRightRadius: '1rem',
}));

const CheckButton = styled(Button)(props => ({
  width: '100%',
  height: '100%',
  fontSize: '1.2rem',
  color: 'var(--white)',
}));

function ModalWordDetail(props: ModalWordDetailProps) {
  const visible = props.visible;
  const onClose = props.onClose;
  const { muteBGM, upVolumeBGM } = useContext(BackgroundSoundContext);
  const [open, setOpen] = useState<boolean>(false);
  const show_bookmark = props.show_bookmark ? props.show_bookmark : false;
  const content = props.content;
  const [pocketNoteStateData, setPocketNoteState] = useRecoilState<PocketNoteType>(pocketNoteState);
  const { note_contents } = pocketNoteStateData;
  const [voiceStateData, setVoiceStateData] = useRecoilState<VoiceType>(voiceState);
  const { ready } = voiceStateData;
  const { setVoice, voicePlay } = useVoice();
  const [imgSrc, setImgSrc] = useState('');
  const [imgReady, setImgReady] = useState<boolean>(false);
  const [visibleAddPocketNote, setVisibleAddPocketNote] = useState<boolean>(false);
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { screen_width, is_mobile } = deviceStateData;
  const userStateData = useRecoilValue(userState);
  const { modal_alert } = useContext(ModalContext);
  const setToastBar = useSetRecoilState(toastBarState);

  // useEffect(() => {
  //   return () => {
  //     upVolumeBGM();
  //   };
  // }, []);

  useEffect(() => {
    if (visible) {
      const foldername = getContentFoldername(content.spell);
      setImgSrc(`${CDN}${IMAGE_DIR}${foldername}/${content.image ? content.image : '0.jpg'}`);
      setVoice(content.spell, content.voice ? content.voice : '0.mp3');
    }
    return () => {
      setImgSrc('');
      setVoiceStateData(prevState => ({
        element: undefined,
        playing: false,
        loading: false,
        error: false,
        ready: false,
        volume: 1,
      }));
      if (visible) {
        upVolumeBGM();
      }
    };
  }, [visible]);

  useEffect(() => {
    if (visible && ready) {
      muteBGM();
      wordPlay();
    }
  }, [visible, ready]);

  const imgCheck = () => {
    const img = new Image();

    img.onload = function () {
      setImgReady(true);
      setOpen(true);
    };

    img.onerror = function () {
      setImgReady(false);
      setOpen(true);
    };

    img.src = imgSrc;
  };

  useEffect(() => {
    if (imgSrc) imgCheck();
  }, [imgSrc]);

  const onClick = () => {
    setOpen(false);
    onClose();
  };

  const toggleBookmark = () => {
    if (userStateData.id < 6) {
      modal_alert.openModalAlert('temp_account');
      return false;
    }
    if (!note_contents.some(word => word.spell == content.spell)) setVisibleAddPocketNote(true);
  };

  const wordPlay = () => {
    voicePlay();
  };

  const VoiceIcon = () => {
    return (
      <svg viewBox='0 0 43 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M18 3.50134L6.05859 14.5006L3.5 14.4998C2.2693 14.4998 0.5 14.3263 0.5 15.4998V25.4998C0.5 26.6736 2.2693 26.4998 3.5 26.4998H6L18 37.4998C19.4918 38.5666 21.0027 39.2778 21.0027 37.4998V3.50065C21.0027 1.72268 19.4545 2.43456 18 3.50134Z'
          fill='current'
        />
        <path
          d='M26.7745 15.3426C26.1462 14.8858 25.0992 14.8858 24.4712 15.3426C23.8429 15.7994 23.8429 16.5608 24.4712 17.0176C27.496 19.2171 27.496 22.7873 24.4712 24.9868C23.8429 25.4436 23.8429 26.205 24.4712 26.6616C24.7967 26.8985 25.2155 27 25.6111 27C26.0066 27 26.449 26.8817 26.7512 26.6447C31.079 23.5485 31.079 18.4727 26.7745 15.3426Z'
          fill='current'
        />
        <path
          d='M28.5026 8.49083C27.8325 9.14929 27.8325 10.2467 28.5026 10.9052C31.1094 13.4658 32.5496 16.8557 32.5496 20.4894C32.5496 24.123 31.1094 27.5129 28.5026 30.0735C27.8325 30.732 27.8325 31.8294 28.5026 32.4879C28.8501 32.829 29.297 33 29.7191 33C30.166 33 30.6128 32.8293 30.9356 32.5123C34.1879 29.3175 36 25.0498 36 20.5137C36 15.9777 34.2125 11.7343 30.9356 8.51522C30.2901 7.83238 29.1977 7.83238 28.5026 8.49083Z'
          fill='current'
        />
      </svg>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClick}
      sx={{
        '& > .MuiBox-root': {
          '&:focus-visible': {
            outline: 'none',
          },
        },
      }}
    >
      <Box sx={style}>
        <StyledModalWrapper>
          <CustomSpeakerButton onClick={wordPlay} disableRipple>
            {<VoiceIcon />}
          </CustomSpeakerButton>
          {show_bookmark ? (
            <CustomBookmarkButton
              onClick={() => toggleBookmark()}
              bookmark={note_contents.some(word => word.spell == content.spell) ? 1 : 0}
              disableRipple
            >
              <FiStar />
            </CustomBookmarkButton>
          ) : null}
          <StyledModalBody>
            <Box sx={{ height: '50vh', width: '100%', maxHeight: '50vh', ...d_flex_center }}>
              {imgReady ? (
                <Box
                  sx={{
                    width: '50%',
                    height: '80%',
                    ...d_flex_center,
                    borderRight: 'thin solid #e3dfff',
                    paddingRight: '1rem',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: '80%',
                      position: 'relative',
                      overflow: 'hidden',
                      borderRadius: '1.5rem',
                      '&:before': {
                        content: '""',
                        display: 'block',
                      },
                    }}
                  >
                    <Box
                      component={'img'}
                      src={imgSrc}
                      sx={{
                        width: 'auto',
                        height: '100%',
                        objectFit: 'contain',
                        borderRadius: '1.5rem',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        clipPath: 'inset(0 0 0 0 round 1.5rem)',
                      }}
                    ></Box>
                  </Box>
                </Box>
              ) : null}
              <Box
                sx={{
                  width: imgReady ? '50%' : '100%',
                  ...d_flex_center,
                  flexDirection: 'column',
                  height: '80%',
                  color: 'var(--purple)',
                }}
              >
                <Box sx={{ overflow: 'hidden', width: '80%' }}>
                  <StyledSpellAutoText
                    lineHeight={1}
                    maxFontSize={7.425}
                    minFontSize={6.5}
                    fontSizeUnit='vh'
                    overflowY='visible'
                    sx={{
                      letterSpacing: screen_width > 1023 ? '-3px' : '-1px',
                    }}
                  >
                    {content.spell}
                  </StyledSpellAutoText>
                </Box>
                <br />
                <StyledMeanAutoText maxFontSize={3.6} minFontSize={3} fontSizeUnit='vh'>
                  {content.mean}
                </StyledMeanAutoText>
              </Box>
            </Box>
          </StyledModalBody>
          <StyledModalUtil onClick={onClick}>
            <CheckButton>확인</CheckButton>
          </StyledModalUtil>
        </StyledModalWrapper>
        <ModalAddPocketNote
          visible={visibleAddPocketNote}
          onClose={() => {
            setVisibleAddPocketNote(false);
          }}
          content={[content]}
        />
      </Box>
    </Modal>
  );
}

export default ModalWordDetail;
