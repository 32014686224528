import { Box, styled, Button } from '@mui/material';
import InputNormal from 'components/input/InputNormal';
import { ModalContext } from 'provider/ModalProvider';
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { deviceState } from 'recoil/common/device';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { userState } from 'recoil/model/user';
import { fetchPutApi } from 'utils/api';
import { KOREAN_REGEX } from 'utils/constants';
import { admin_info_form } from 'utils/form';
import { toast_contents } from 'utils/modal_contents';
import { formatEndDate, setLocaleDateString } from 'utils/tools';
import validate from 'utils/validation';

const RegistrationButton = styled(Button)({
  width: '10rem',
  marginTop: '2rem',
  backgroundColor: '#1BD05B',
  color: 'white',
  '&:hover': {
    backgroundColor: '#1BD05B',
  },
  '&.Mui-disabled': {
    '&:hover': {
      backgroundColor: '#E8E8E8',
    },
    color: 'white',
    backgroundColor: '#E8E8E8',
  },
});

declare let window: any;

export default function ChangeInfo({
  onFocus,
  onBlur,
}: {
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
}) {
  const { modal_confirm, modal_alert } = useContext(ModalContext);
  const [editAdminData, setEditAdminData] = useState<RegistrationDataType[]>(
    JSON.parse(JSON.stringify(admin_info_form)),
  );
  const [originAdminData, setOriginAdminData] = useState<RegistrationDataType[]>(
    JSON.parse(JSON.stringify(admin_info_form)),
  );
  const [userStateData, setUserStateData] = useRecoilState(userState);
  const { uid, name, isJoinEscape, product, product_name, customer_id, loginid, startdate, enddate, center_type } =
    userStateData;
  const [isUpdate, setIsUpdate] = useState(false);
  const setToastBar = useSetRecoilState(toastBarState);

  useEffect(() => {
    if (isUpdate) {
      setIsUpdate(false);
      return;
    }

    const tempAdminData = editAdminData.map((row: RegistrationDataType) => {
      if (row.key === 'product') {
        const product_start_date = startdate ? startdate.replace(/\//g, '-') : '';
        const product_end_date =
          product === 'F' || (isJoinEscape && enddate != '')
            ? formatEndDate(enddate)
            : setLocaleDateString(new Date(enddate), '-');
        const period = startdate && enddate ? `(${product_start_date} ~ ${product_end_date})` : undefined;
        row.period = period;
      } else if (row.key === 'name') {
        row.value = name;
      } else if (row.key === 'accountId') {
        row.value = loginid;
      } else if (row.key === 'password') {
        row.value = '';
      }
      return {
        ...row,
      };
    });

    setOriginAdminData(JSON.parse(JSON.stringify(tempAdminData)));
    setEditAdminData(JSON.parse(JSON.stringify(tempAdminData)));
  }, [isUpdate, name]);

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const tempAdminData = [...editAdminData];

    const id = event.target.id;
    const value = event.target.value;

    const index = tempAdminData.findIndex((row: RegistrationDataType) => row.key === id);

    if (id === 'password') {
      if (value === '') {
        tempAdminData[index].error = false;
      } else {
        const isKorean = KOREAN_REGEX.test(value);
        const valiRes = validate.password(value);
        tempAdminData[index].error = !valiRes;

        if (isKorean) {
          tempAdminData[index].errorText = '한글이 포함되었습니다.';
        } else if (value.length < 8) {
          tempAdminData[index].errorText = '최소 8자 이상입니다.';
          tempAdminData[index].error = true;
        } else {
          tempAdminData[index].errorText = '영문 소문자, 숫자, 특수문자 등으로 조합하세요.';
        }
      }
    }

    tempAdminData[index].value = value;

    setEditAdminData(tempAdminData);
  };

  const compareRegiDatasWithOrigin = useCallback(
    (
      originAdminData: RegistrationDataType[],
      newRegiDatas: RegistrationDataType[],
      type: 'add' | 'update' | 'error' | 'check',
    ): boolean => {
      let require_num = 0;
      let comp_require_num = 0;
      let edit_num = 0;
      let error_num = 0;

      for (let i = 0; i < originAdminData.length; i++) {
        if (originAdminData[i].required) require_num++;
        if (originAdminData[i].required && newRegiDatas[i].value && newRegiDatas[i].value.length > 0)
          comp_require_num++;
        if (originAdminData[i].value != newRegiDatas[i].value) edit_num++;
        if (newRegiDatas[i].error && newRegiDatas[i].type !== 'select') error_num++;
      }

      if (require_num == comp_require_num) {
        if (edit_num > 0) {
          if (error_num > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          if (type === 'update') {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
    },
    [],
  );

  const checkError = useMemo(() => {
    for (let i = 0; i < editAdminData.length; i++) {
      if (editAdminData[i].type !== 'select') {
        const check = editAdminData[i].error;
        if (check) return true;
      }
    }
    return false;
  }, [editAdminData]);

  const regibtnDisabled = useMemo(() => {
    return compareRegiDatasWithOrigin(originAdminData, editAdminData, 'update') || checkError;
  }, [editAdminData, checkError]);

  const sendChangeInfo = async () => {
    modal_confirm.closeModalConfirm();
    const nameIndex = editAdminData.findIndex(row => row.key === 'name');
    const passwordIndex = editAdminData.findIndex(row => row.key === 'password');

    const changeInfo = {
      id: uid,
      name: editAdminData[nameIndex].value,
      ...(editAdminData[passwordIndex].value !== '' && {
        password: editAdminData[passwordIndex].value,
      }),
    };

    const res = await fetchPutApi(`/customers/${customer_id}`, changeInfo);
    if (res.result === true) {
      console.log(editAdminData[nameIndex].value);
      setUserStateData(prev => ({
        ...prev,
        name: editAdminData[nameIndex].value,
      }));
      //* B2C toast로 분기처리
      if (center_type == 'A' || center_type == 'B') {
        modal_alert.openModalAlert('success_modify', undefined, '관리자 정보가');
      } else {
        openToastBar({ setToastBar }, toast_contents.info.success_modify_info, '', 2000);
      }
      setIsUpdate(true);
    }
  };

  const handleSubmit = () => {
    modal_confirm.openModalConfirm('normal', 'update_contents', sendChangeInfo, undefined, '관리자 정보를');
  };

  const makeEl = (row: RegistrationDataType) => {
    return (
      <InputNormal
        id={row.key}
        title={row.title}
        disable={row.disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        value={row.value}
        onChange={handleChangeInput}
        period={row.period}
        product={product_name}
        {...(row.type === 'password' && {
          type: 'password',
          placeholder: '변경 시 기재해주세요.',
          errorText: row.errorText,
          error: row.error,
        })}
        sx={{
          width: '100%',
          position: 'relative',
        }}
        inputSx={{
          '&.Mui-disabled.MuiInputBase-colorGray_1': {
            '& fieldset': {
              borderColor: 'var(--gray-2) !important',
            },
          },
          '&:hover fieldset': {
            borderColor: 'var(--gray-2) !important',
          },
          '& fieldset': {
            borderColor: 'var(--gray-2) !important',
          },
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        paddingTop: {
          md: '0.5rem',
        },
        justifyItems: 'center',
      }}
    >
      {editAdminData.map(row => {
        return (
          <Box
            key={row.key}
            {...(row.type === 'password' && { position: 'relative' })}
            sx={{
              marginBottom: '1.5rem',
              width: '50%',
            }}
          >
            {makeEl(row)}
          </Box>
        );
      })}
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
        }}
      >
        <RegistrationButton disabled={regibtnDisabled} onClick={handleSubmit}>
          수정
        </RegistrationButton>
      </Box>
    </Box>
  );
}
