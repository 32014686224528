import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ModalProductIntendUI } from './ModalProductIntend.Presenter';
import { useRecoilState, useSetRecoilState, useResetRecoilState } from 'recoil';
import {
  ModalProductIntendType,
  closeModalProductIntend,
  modalProductIntendState,
} from 'recoil/common/modalProductIntend';
import {
  ModalCardInfoDataType,
  modalCardInfoState,
  openModalCardInfo,
  resetModalCardInfo,
} from 'recoil/common/modalCardInfo';
import { resetUserState, userState } from 'recoil/model/user';
import dayjs from 'dayjs';
import {
  BASE_URL,
  PERSONAL_PRODUCT_PRICES,
  PRESIDENT_OF_CENTER_TYPE,
  TEMP_STUDENTS_ACCOUNT_IDS,
} from 'utils/constants';
import {
  closeLoadingCircleSpinner,
  LoadingCircleSpinnerState,
  openLoadingCircleSpinner,
} from 'recoil/common/loadingCircleSpinner';
import { useNavigate } from 'react-router';
import { fetchPostApi } from 'utils/api';
import { setUserInFireStoreToNull } from 'utils/firebase';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { learningState } from 'recoil/model/learning';
import { bookState } from 'recoil/model/book';
import { pocketNoteState } from 'recoil/model/pocket_note';
import { modalHiddenMissionState } from 'recoil/common/modalHiddenMission';
import { hiddenMissionState } from 'recoil/common/mission';
import { BackgroundSoundContext } from 'provider/BackgroundSoundProvider';

export function ModalProductIntendContainer() {
  const navigate = useNavigate();
  const setLoadingCircleSpinner = useSetRecoilState(LoadingCircleSpinnerState);
  const setToastBar = useSetRecoilState(toastBarState);
  const { resetBGM } = useContext(BackgroundSoundContext);
  const [userStateData, setUserStateData] = useRecoilState(userState);
  const { personal_product, enddate, intend_product, customer_id } = userStateData;
  const [modalProductIntend, setModalProductIntend] = useRecoilState<ModalProductIntendType>(modalProductIntendState);
  const [modalCardInfo, setModalCardInfo] = useRecoilState<ModalCardInfoDataType>(modalCardInfoState);
  const { isRegister, cardNumber, year, cardMonth, birthDate, installmentMonths } = modalCardInfo;
  const [paymentKind, setPaymentKind] = useState(0);
  const [isAccountPaymentProcessing, setIsAccountPaymentProcessing] = useState(false);
  const [isCardPaymentProcessing, setIsCardPaymentProcessing] = useState(false);

  const resetLearningState = useResetRecoilState(learningState);
  const resetBookState = useResetRecoilState(bookState);
  const resetPocketNoteState = useResetRecoilState(pocketNoteState);
  const resetModalMissionState = useResetRecoilState(modalHiddenMissionState);
  const resetHiddenMissionState = useResetRecoilState(hiddenMissionState);

  const resetRecoilState = () => {
    resetUserState({ setUserStateData });
    resetLearningState();
    resetBookState();
    resetPocketNoteState();
    resetModalMissionState();
    resetHiddenMissionState();
    resetBGM();
  };

  useEffect(() => {
    if (!modalProductIntend.visible) {
      //! 초기화
      setPaymentKind(0);
    } else {
      setIsAccountPaymentProcessing(false);
      setIsCardPaymentProcessing(false);
    }
  }, [modalProductIntend.visible]);

  const onClose = useCallback(async () => {
    if (modalProductIntend.buttons && intend_product) {
      // * 1일자 상품 유도 모달 오픈 안하다록 처리
      const url = `/customers/${customer_id}/intendproduct`;
      await fetchPostApi(url, {});
      setUserStateData(prev => ({
        ...prev,
        intend_product: false,
      }));
    }

    closeModalProductIntend({ setModalProductIntend });
    resetModalCardInfo({ setModalCardInfo });
  }, [modalProductIntend.buttons, intend_product, customer_id]);

  const currentClass = useMemo(() => {
    let return_str = '';
    switch (personal_product) {
      case 'V003Y':
        return_str = '기초어휘';
        break;
      case 'G003Y':
        return_str = '핵심문법';
        break;
      case 'S003Y':
        return_str = '실전회화';
        break;
    }
    return return_str;
  }, [personal_product, modalProductIntend.visible]);

  const difference_in_duration = useMemo(() => {
    // * enddate에서 현재날 차감
    const today = dayjs();
    const dayjs_enddate = dayjs(enddate);

    const differenceInMonths = dayjs_enddate.diff(today, 'month');
    const remainingDays = dayjs_enddate.diff(today.add(differenceInMonths, 'month'), 'day');

    return [differenceInMonths, remainingDays];
  }, [enddate, modalProductIntend.visible]);

  const diff_kor = useMemo(() => {
    const space = difference_in_duration[0] > 0 && difference_in_duration[1] > 0;
    return `${difference_in_duration[0] > 0 ? `${difference_in_duration[0]}개월` : ''}${space ? ' ' : ''}${
      difference_in_duration[1] > 0 ? `${difference_in_duration[1]}일` : ''
    }`;
  }, [difference_in_duration]);

  //! 기간 차이나는 만큼 결제금액 구하기 > 진행중...
  const difference_in_price = useMemo(() => {
    const cur_price = PERSONAL_PRODUCT_PRICES[personal_product] ? PERSONAL_PRODUCT_PRICES[personal_product] : 0;
    const all_price = PERSONAL_PRODUCT_PRICES['A003Y'];

    let differencePrice = all_price - cur_price;

    if (difference_in_duration[0] > 0) {
      // * 남은 월단위로만 계산
      differencePrice = (differencePrice / 12) * difference_in_duration[0];
    } else {
      // * 남은 일수로만 계산
      differencePrice = (differencePrice / 365) * difference_in_duration[1];
    }

    return differencePrice;
  }, [personal_product, difference_in_duration]);

  const price_sub_text = useMemo(() => {
    let return_str = '';
    if (difference_in_duration[0] > 0 && difference_in_duration[1] > 0) {
      return_str = `(${difference_in_duration[0]}개월 이용료 분 / ${difference_in_duration[1]}일은 무료)`;
    } else if (difference_in_duration[0] > 0 && difference_in_duration[1] == 0) {
      return_str = `(${difference_in_duration[0]}개월 이용료 분)`;
    } else if (difference_in_duration[0] == 0 && difference_in_duration[1] > 0) {
      return_str = `(${difference_in_duration[1]}일 분)`;
    }

    return return_str;
  }, [difference_in_duration]);

  const onClickCardInfo = () => {
    if (userStateData.customer_id) {
      openModalCardInfo({ setModalCardInfo }, 2);
    }
  };

  const onLogout = () => {
    if (userStateData.type != PRESIDENT_OF_CENTER_TYPE && !TEMP_STUDENTS_ACCOUNT_IDS.includes(userStateData.id)) {
      setUserInFireStoreToNull(userStateData.id);
    }
    resetRecoilState();
    window.sessionStorage.removeItem('user');
    window.sessionStorage.removeItem('authToken');
    window.sessionStorage.removeItem('refreshToken');
    setTimeout(() => {
      navigate(`${BASE_URL}/login`, { state: paymentKind == 0 ? 'oa_pay' : 'card_pay' });
    }, 0);
  };

  const postPaymentInfo = async () => {
    if (paymentKind == 0 || (paymentKind == 1 && isRegister)) {
      const payment_kind = paymentKind == 0 ? 'OA' : 'CD';
      const api_path = '/diffproduct';

      if (paymentKind == 0) {
        // * 전용계좌 결제
        const postPaymentInfo = {
          paymentKind: payment_kind,
          product: 'A003Y',
          price: difference_in_price,
          endDate: dayjs(enddate).format('YYYY-MM-DD 23:59:59'),
        };

        try {
          onClose();
          openLoadingCircleSpinner({ setLoadingCircleSpinner }, 8, '오래영어 첫 클래스 수강을 준비중입니다!', {
            enter: 0,
            exit: 0,
          });
          const res = await fetchPostApi(
            `/customers/${userStateData.customer_id}/payments${api_path}`,
            postPaymentInfo,
          );
          if (res.result) {
            if (!res.errorcode) {
              closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
              onLogout();
            }
          } else {
            if (res.errorcode || res.message) {
              openToastBar({ setToastBar }, res.message, 'red', 2000);
              console.error(res.message);
              closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
            }
          }
        } catch (error) {
          console.log('전용계좌 결제 에러');
          console.error(error);
          closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
          openToastBar({ setToastBar }, '결제에 실패했습니다.', 'red', 2000);
        }
      } else {
        // *  카드 결제
        let selectedYear = year.toString();
        selectedYear = selectedYear.length === 4 ? selectedYear.substring(2) : selectedYear;
        const postCardPaymentInfo = {
          paymentKind: payment_kind,
          product: 'A003Y',
          price: difference_in_price,
          cardno: cardNumber,
          year: selectedYear,
          month: cardMonth,
          payerNumber: birthDate,
          installmentCount: installmentMonths,
          endDate: dayjs(enddate).format('YYYY-MM-DD 23:59:59'),
        };

        try {
          onClose();
          openLoadingCircleSpinner({ setLoadingCircleSpinner }, 8, '오래영어 첫 클래스 수강을 준비중입니다!', {
            enter: 0,
            exit: 0,
          });

          const res = await fetchPostApi(
            `/customers/${userStateData.customer_id}/payments${api_path}`,
            postCardPaymentInfo,
          );

          if (res.result) {
            if (!res.errorcode) {
              closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
              //! 로그아웃 처리 > 기존 오래영어 관리자의 카드결제 참고
              onLogout();
            }
          } else {
            if (res.errorcode || res.message) {
              openToastBar({ setToastBar }, res.message, 'red', 2000);
              console.error(res.message);
              closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
            }
          }
        } catch (error) {
          console.log('카드 결제 에러');
          console.error(error);
          closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 0, exit: 0 });
          openToastBar({ setToastBar }, '결제에 실패했습니다.', 'red', 2000);
        }
      }
    }
  };

  const props = {
    visible: modalProductIntend.visible,
    onClose,
    enddate,
    diff_duration: diff_kor,
    diff_price: difference_in_price,
    price_sub_text,
    currentClass,
    buttons: modalProductIntend.buttons,
    paymentKind,
    setPaymentKind,
    onClickCardInfo,
    isRegister,
    cardNumber,
    isAccountPaymentProcessing,
    isCardPaymentProcessing,
    postPaymentInfo,
  };

  return <ModalProductIntendUI {...props} />;
}
