import styled from '@emotion/styled';
import { Box, Button, ButtonGroup, InputLabel, Slider } from '@mui/material';
import theme from 'theme';

export const Wrapper = styled(Box)({
  width: '100%',
  height: '100%',
});

export const Header = styled(Box)({
  width: '100%',
  height: '3rem',
  backgroundColor: '#f5f5f5',
  borderBottom: '1px solid #edeeef',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  color: 'rgba(0,0,0,.6)',
  '& > div:last-of-type': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
});

export const ContentWrap = styled(Box)(props => ({
  height: 'calc(100% - 6.75rem)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  borderLeft: '3px solid #eef0f1',
}));

export const SettingBoxWrap = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  padding: '1rem 1rem 3.25rem 1rem',
  display: 'flex',
  '& > div:last-of-type': {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    display: 'flex',
  },
}));

export const SettingBox = styled(Box)(props => ({
  width: '100%',
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

// export const SettingInput = styled(OutlinedInput)(props => ({
//   fontSize: '1.1rem',
//   width: '100%',
//   height: '2.5rem',
//   outline: 'none',
//   borderRadius: 0,
//   '&.Mui-disabled.MuiInputBase-colorGray_1': {
//     backgroundColor: '#f5f5f5',
//     '& input': {
//       WebkitTextFillColor: 'var(--gray_1)',
//     },
//     '& fieldset': {
//       borderColor: 'var(--gray-2) !important',
//     },
//   },
//   '& input': {
//     cursor: 'auto',
//     padding: '0.5rem 1rem',

//     '&::placeholder': {
//       color: '#c7c7c7 !important',
//       transition: 'none',
//       fontWeight: '300',
//       opacity: '1',
//       fontSize: '1rem',
//     },
//     '&:disabled': {
//       backgroundColor: '#f5f5f5 !important',
//       cursor: 'default',
//     },
//   },

//   '&:hover': {
//     'input, fieldset': {
//       borderColor: 'var(--gray-2)',
//     },
//   },
//   '& fieldset': {
//     borderColor: 'var(--gray-2) !important',
//     legend: {
//       outline: 'none',
//     },
//   },
// }));

export const SettingLabel = styled(InputLabel)({
  width: '100%',
  height: '2.25rem',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: '500',
  color: '#505050',
  marginTop: '0.5rem',
});

export const SettingButtonGroup = styled(ButtonGroup)(props => ({
  width: '100%',
  margin: 'auto',
  display: 'flex',
  borderRadius: 0,

  'button.MuiButtonGroup-grouped': {
    minWidth: '0px',
  },
}));

export const SettingButton = styled(Button)(props => ({
  width: '100%',
  textAlign: 'center',
  border:
    props.color == 'purple_5' ? `1px solid ${theme.palette.purple_5.contrastText} !important` : '1px solid #dbdbdb',
  boxShadow: 'none',
  '&:active, &:hover': {
    border:
      props.color == 'purple_5' ? `1px solid ${theme.palette.purple_5.contrastText} !important` : '1px solid #dbdbdb',
    boxShadow: 'none',
  },
  '& + button': {
    borderLeftColor: props.color == 'purple_5' ? `${theme.palette.purple_5.contrastText} !important` : '',
  },
  fontSize: '1rem',
  borderRadius: '0px !important',
  padding: '5px 15px !important',
  '&.MuiButton-outlined': {
    color: '#dbdbdb',
    backgroundColor: '#FFFFFF',
    border: '1px solid #dbdbdb',
    '&:hover': {
      border: '1px solid #dbdbdb',
    },
  },
  '&.MuiButtonGroup-grouped:not(:last-of-type)': {
    borderRightColor: '#dbdbdb',
    '&:hover': {
      borderRightColor: '#dbdbdb',
    },
  },
}));

export const CheckboxWrap = styled(Box)(props => ({
  width: '100%',
  height: '15vh',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'space-evenly',
  borderTop: '3px solid #edeeef',
  padding: '1rem',
}));

export const CheckboxBox = styled(Box)(props => ({
  width: '50%',
  height: '50%',
  display: 'flex',
  alignItems: 'center',
  '& > span': {
    padding: '0',
  },
  '& > div': {
    cursor: 'pointer',
  },
}));

export const Footer = styled(Box)({
  width: '100%',
  height: '3.75rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const WordPrintButton = styled(Button)(props => ({
  width: '100%',
  height: '100%',
  color: 'white',
  backgroundColor: theme.palette.purple.main,
  borderTop: '3px solid #edeeef',
  whiteSpace: 'nowrap',
  lineHeight: '1.435rem',
  borderRadius: 0,
  fontWeight: 'bold',
  fontSize: '1rem',
  '&:hover': {
    color: 'white',
    backgroundColor: theme.palette.purple.main,
  },
}));

export const Line = styled(Box)({
  width: '3px',
  height: '100%',
  backgroundColor: '#eef0f1',
});

export const CustomSlider = styled(Slider)({
  width: '95%',
  padding: '1.19rem 0',
  ' .MuiSlider-valueLabel': {
    top: '2.45rem',
    backgroundColor: 'transparent',
    color: theme.palette.purple.main,
  },
});
