import {
  Modal,
  Wrapper,
  FormControl,
  FormLabel,
  SubTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from './ModalRadio.Styles';
import { DropdownNormalContainer } from 'components/common/DropdownNormal/DropdownNormal.Container';
import { Box } from '@mui/material';
import React, { Fragment } from 'react';

export interface ModalRadioContentsType {
  id: number | string;
  name: string | JSX.Element;
  selectedDropdownContent?: { id: number | string | null; content: string };
  dropdownContents?: { id: number | string | null; content: string }[];

  // input 관련 옵션
  visibleInput?: boolean;
  placeholder?: string;
  inputValue?: string;
}

export interface ModalRadioVAProps {
  id: string;
  visible: boolean;
  title: string;
  subTitle?: string;
  curValue: ModalRadioContentsType;
  contents: ModalRadioContentsType[];
  onClickButton: () => void;
  checkValue: boolean;
  onChangeRadio: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  onClickDropdownItem: (idx: number) => void;
  onClickSetTempBook: () => void;
}

export const ModalRadioUI = React.memo(function ModalRadioUI({
  id,
  visible,
  title,
  subTitle,
  curValue,
  contents,
  onClickButton,
  checkValue,
  onChangeRadio,
  onCancel,
  onClickDropdownItem,
  onClickSetTempBook,
}: ModalRadioVAProps) {
  const isClassEdit = title === '수업 시작시간';

  return (
    <Modal
      open={visible}
      onClose={onCancel}
      sx={{ zIndex: ['배포 횟수', '배포 간격'].includes(title) ? '1301' : '1300' }}
    >
      <Wrapper sx={{ boxShadow: 24 }}>
        <FormControl>
          <FormLabel>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              {title}
              <SubTitle radio_id={id} subtitle={subTitle}>
                {subTitle}
              </SubTitle>
            </Box>
          </FormLabel>
          <RadioGroup onChange={onChangeRadio} value={curValue.id}>
            {contents.map(content => {
              return (
                <Fragment key={`modal_radio_${content.id}`}>
                  <FormControlLabel
                    style={{ pointerEvents: 'none' }}
                    value={content.id}
                    label={content.name ? content.name : ''}
                    labelPlacement='start'
                    control={<Radio style={{ pointerEvents: 'none' }} disableRipple={true} color='green' />}
                    sx={{ span: { pointerEvents: 'auto' } }}
                  />
                  {content.dropdownContents && curValue.id === content.id ? (
                    <DropdownNormalContainer
                      className='radio'
                      color={curValue.selectedDropdownContent ? 'black' : 'gray'}
                      dropdownContents={content.dropdownContents}
                      title={
                        curValue.selectedDropdownContent
                          ? curValue.selectedDropdownContent.content
                          : '클래스를 선택해주세요.'
                      }
                      onClickItem={onClickDropdownItem}
                    />
                  ) : null}
                </Fragment>
              );
            })}
          </RadioGroup>

          {isClassEdit ? null : (
            <Button onClick={onClickButton} disableRipple>
              {checkValue ? (
                <Box component={'span'} sx={{ color: 'green.main' }}>
                  확인
                </Box>
              ) : (
                <Box component={'span'} sx={{ color: 'black.main' }}>
                  취소
                </Box>
              )}
            </Button>
          )}
        </FormControl>
      </Wrapper>
    </Modal>
  );
});
