import { FaThumbsUp } from 'react-icons/fa';
import { HiCheck } from 'react-icons/hi';
import { fetchGetApi } from './api';
import dayjs from 'dayjs';
import { PathTypes, PATH_VALIDATE_TYPE } from './constants';

// 크기 정할 때 사용하는 함수 -> 0 부터 1까지의 값을 넣어서 % 값 리턴

// 1보다 큰 수일 경우 해당 값 리턴 -> 해당값은 px로 MUI에서 인식
export function transform(value: number) {
  return value <= 1 ? `${value * 100}%` : value;
}

// 수업 날짜 한글로 출력
export function setStudyDayText(nums: number[] | []) {
  const days: string[] = ['일', '월', '화', '수', '목', '금', '토'];
  const res = [];

  const tmp_nums: number[] = nums;
  const zero_idx = tmp_nums.indexOf(0);
  if (zero_idx >= 0) {
    tmp_nums.splice(zero_idx, 1);
    tmp_nums.push(0);
  }

  for (const x of tmp_nums) {
    res.push(days[x]);
  }

  return res.join(', ');
}

export function autoHypenPhone(str: string) {
  str = str.replace(/[^0-9]/g, '');
  let tmp = '';
  if (str.length < 4) {
    return str;
  } else if (str.length < 7) {
    tmp += str.substring(0, 3);
    tmp += '-';
    tmp += str.substring(3);
    return tmp;
  } else if (str.length < 11) {
    tmp += str.substring(0, 3);
    tmp += '-';
    tmp += str.substring(3, 6);
    tmp += '-';
    tmp += str.substring(6);
    return tmp;
  } else {
    tmp += str.substring(0, 3);
    tmp += '-';
    tmp += str.substring(3, 7);
    tmp += '-';
    tmp += str.substring(7);
    return tmp;
  }
}

// 0000년 00월 00일
export function setLocaleDateString(date: Date | null, type: string): string {
  let new_date: Date | null;
  if (date !== null) {
    new_date = date;
  } else {
    new_date = new Date();
  }
  const y = new_date.getFullYear();
  const m = new_date.getMonth() + 1;
  const d = new_date.getDate();

  let res = '';

  if (type == 'kor') {
    res = `${y}년 ${Number(m) < 10 ? '0' + m : m}월 ${Number(d) < 10 ? '0' + d : d}일`;
  } else {
    res = `${y}${type}${Number(m) < 10 ? '0' + m : m}${type}${Number(d) < 10 ? '0' + d : d}`;
  }

  return res;
}

// path validation
// 뒤에 가변적인 params가 들어올 때 2중 검증 필요 -> 추후 필요예상시 도입

interface PathType {
  [key: string]: { [key: string]: { [key: string]: { [key: string]: object } } };
}

const paths: PathType = {
  schedule: {
    learningschedule: {},
    learningbook: {},
  },
  report: {
    lookup: {
      result: {},
    },
    period: {},
    month: {},
  },
  leaderboard: {
    board: {},
    record: {},
  },
  pocketnote: {
    learning: {},
    checkup: {},
  },
  leveltest: {
    leveltest: {},
    report: {},
  },
  manager: {
    notice: {},
    notification: {},
    manual: {},
    qna: {},
    settings: {},
  },
  book: {
    study: {
      learning: {},
      result: {},
    },
    school: {
      learning: {},
      result: {},
    },
  },
  recent: { learning: {}, result: {} },
  quiz: {},
  test: {},
};

export function validationPath(currentUrl: string): boolean {
  try {
    const current_url_array = currentUrl.split('/');
    const current_url_depth = current_url_array.length - 1;
    if (currentUrl === '/') return true;
    let target: { [key: string]: any } = {};
    for (let idx = 1; idx <= current_url_depth; idx++) {
      if (idx == 1) {
        target = paths[current_url_array[idx]];
      } else {
        target = target[current_url_array[idx]];
      }
    }

    if (target) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

//
// 머문시간 구하는 함수 (stay_time)
export function calculateStayTime(start_time: string, end_time: string): string {
  const start_time_array = start_time.split(':');
  const end_time_array = end_time.split(':');
  // 정규식 이용해 먼저 시간인지 확인 아니면 바로 '-'
  const time_format = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
  if (time_format.test(start_time) && time_format.test(end_time)) {
    if (start_time_array.length === 2 && end_time_array.length === 2) {
      if (
        Number(start_time_array[0]) > Number(end_time_array[0]) ||
        (Number(start_time_array[0]) === Number(end_time_array[0]) &&
          Number(start_time_array[1]) > Number(end_time_array[1]))
      ) {
        return '-';
      } else {
        const stay_time_array = end_time_array.map(
          (item, index) => Number(end_time_array[index]) - Number(start_time_array[index]),
        );
        if (stay_time_array[1] < 0) {
          stay_time_array[0] = stay_time_array[0] - 1;
          if (stay_time_array[0] === 0) return `${60 + stay_time_array[1]}분`;
          else return `${stay_time_array[0]}시간 ${60 + stay_time_array[1]}분`;
        } else if (stay_time_array[1] === 0) {
          if (stay_time_array[0] === 0) return `${stay_time_array[1]}분`;
          else return `${stay_time_array[0]}시간`;
        } else {
          if (stay_time_array[0] === 0) return `${stay_time_array[1]}분`;
          else return `${stay_time_array[0]}시간 ${stay_time_array[1]}분`;
        }
      }
    } else {
      return '-';
    }
  } else {
    return '-';
  }
}

export function getIdRCheckday(rcheckday: string) {
  const rcheckday_arr = rcheckday.split(', ').filter(item => item);

  const days: { [key: string]: string } = {
    일: '0',
    월: '1',
    화: '2',
    수: '3',
    목: '4',
    금: '5',
    토: '6',
  };

  return rcheckday_arr.map(item => days[item]).toString();
}

export function makeMinuteContents() {
  const minute_array = [];
  let id = 0;
  for (let i = 0; i <= 59; i++) {
    if (i % 5 != 0) continue;
    const obj = { id: 0, label: '' };
    obj.id = id;
    if (i < 10) obj.label = `0${i}분`;
    else obj.label = `${i}분`;
    minute_array.push(obj);
    id++;
  }
  return minute_array;
}

export function makeHourContents() {
  const hour_array = [];
  for (let i = 0; i < 24; i++) {
    const obj = { id: 0, label: '' };
    obj.id = i;
    if (i < 10) {
      obj.label = `0${i}시`;
    } else {
      obj.label = `${i}시`;
    }
    hour_array.push(obj);
  }
  return hour_array;
}

export function checkTableQueries(table_query: TableQueryType) {
  const { target, sort, page, max } = table_query;

  if (target == '' && sort == '' && page == 1 && max == 10) {
    return false;
  } else {
    return true;
  }
}

export function makeYearMonth(date: string): string {
  const date_array = date.split('-');
  return `${date_array[0]}년 ${date_array[1]}월`;
}

export function getYearArray(date: string): string[] {
  const year_arr = [];
  const start_year = +date.split('-')[0];
  for (let i = new Date().getFullYear(); i >= start_year; i--) {
    year_arr.push(String(i) + '년');
  }
  return year_arr;
}

export function getMonthArray(date: string, year: string | number): string[] {
  const month_arr = [];
  let max_month: number;
  let start_month: number;
  const start_year = +date.split('-')[0];
  const current_year = +year;
  if (current_year == start_year) {
    start_month = +date.split('-')[1];
    if (current_year == new Date().getFullYear()) {
      max_month = new Date().getMonth() + 1;
      for (let i = max_month; i >= start_month; i--) {
        month_arr.push(`${i < 10 ? '0' + i + '월' : i + '월'}`);
      }
    } else {
      for (let i = 12; i >= start_month; i--) {
        month_arr.push(`${i < 10 ? '0' + i + '월' : i + '월'}`);
      }
    }
  } else {
    if (current_year == new Date().getFullYear()) max_month = new Date().getMonth() + 1;
    else max_month = 12;
    for (let i = max_month; i >= 1; i--) {
      month_arr.push(`${i < 10 ? '0' + i + '월' : i + '월'}`);
    }
  }
  return month_arr;
}

export function makeFontSize(
  font_size_obj: { [key: string]: boolean },
  default_font_size: number,
  property: string,
  font?: boolean,
): { [key: string]: string } {
  let new_font_size = '';
  const font_obj: { [key: string]: string } = {};
  for (const font_size in font_size_obj) {
    if (font_size_obj[font_size]) {
      new_font_size = font_size;
      break;
    }
  }
  if (new_font_size == 'default') {
    font_obj[property] = default_font_size + `${font ? 'rem' : '%'}`;
  } else if (new_font_size == 'small') {
    font_obj[property] = default_font_size * 0.8 + `${font ? 'rem' : '%'}`;
  } else {
    font_obj[property] = default_font_size * 1.2 + `${font ? 'rem' : '%'}`;
  }
  return font_obj;
}

export const validatePassword = (value: string): boolean => {
  let status = false;
  status = validateKorean(value);
  if (status) return false;
  else {
    const passwordPattern = /^[a-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/;
    status = passwordPattern.test(value);
    if (status) {
      status = false;
      const p_num = value.search(/[0-9]/g);
      const p_spc = value.search(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]+/g);
      const p_alp = value.search(/[a-z]/g);

      if (p_num > -1 && p_spc > -1) status = true;
      if (p_num > -1 && p_alp > -1) status = true;
      if (p_spc > -1 && p_alp > -1) status = true;
      if (p_num && p_spc > -1 && p_alp > -1) status = true;
      return status;
    } else {
      return status;
    }
  }
};

export const validateKorean = (value: string): boolean => {
  const koreanPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
  if (koreanPattern.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const makeCommaAsThousandSeparator = (value: string | number): string => {
  return value.toLocaleString('ko-KR');
};

export function getContentFoldername(spell: string) {
  let foldername;
  foldername = spell.toLowerCase();
  foldername = foldername.replace("'", '');
  foldername = foldername.trim();
  foldername = foldername.replace(/ +/g, ' ');
  foldername = foldername.replace(/\s+/g, '_');
  foldername = foldername.replace(/[^0-9a-z_.]/g, '');
  return foldername;
}

export function getExampleFilename(spell: string) {
  let filename;
  filename = spell.toLowerCase();
  filename = filename.replace("'", '');
  filename = filename.replace(/[^0-9a-z]/gi, ' ');
  filename = filename.replace(/ +|\s+/g, ' ');
  filename = filename.trim();
  filename = filename.replace(/\s+/gi, '_');
  return filename;
}

export function getContentLecturename(spell: string) {
  if (spell == "Let's") {
    return 'lets_0';
  } else if (spell == "Don't") {
    return 'dont_0';
  } else if (spell == 'all day(long)') {
    return 'all_day_long_0';
  } else if (spell == 'be from ~') {
    return 'be_from_0';
  } else if (spell == 'Mr.') {
    return 'mr_0';
  } else if (spell == 'Mrs.') {
    return 'mrs_0';
  } else if (spell == 'Nice to meet you.') {
    return 'nice_to_meet_you_0';
  } else if (spell == 'Of course.') {
    return 'of_course_0';
  } else if (spell == 'really?') {
    return 'really_0';
  } else if (spell == 'Sure.') {
    return 'sure_0';
  } else if (spell == "You're welcome") {
    return 'youre_welcome_0';
  } else if (spell == 'Miss') {
    return 'ms';
  } else if (spell == 'be famous for ~') {
    return 'be_famous_for_';
  } else if (spell == 'have~in common') {
    return 'have__in_common';
  } else if (spell == 'City Hall') {
    return '_city_hall';
  } else if (spell == 'keep ... in mind') {
    return 'keep__in_mind';
  } else if (spell == 'not .... anymore') {
    return 'not__anymore';
  } else if (spell == 'keep -ing') {
    return 'keep__ing';
  } else if (spell == 'by -ing') {
    return 'by__ing';
  } else if (spell == 'would like to ~') {
    return 'would_like_to_';
  } else if (spell == 'go for it!') {
    return 'go_for_it_';
  } else if (spell == 'there is (are)') {
    return 'there_is_are_';
  } else if (spell == 'get + (비교급)') {
    return 'get_';
  } else if (spell == 'have ~ in common') {
    return 'have___in_common';
  } else if (spell == 'not ~ at all') {
    return 'not__at_all';
  } else if (spell == 'turn into') {
    return 'turn_into_';
  } else if (spell == 'try to') {
    return 'try_to_';
  } else if (spell == 'used to') {
    return 'used_to_';
  } else if (spell == 'be used to') {
    return 'be_used_to_';
  } else if (spell == 'so_that') {
    return 'so_that_';
  } else if (spell == 'be able to~') {
    return 'be_able_to_';
  } else if (spell == 'be supposed to ~') {
    return 'be_supposed_to_';
  } else if (spell == 'get to') {
    return 'get_to_';
  } else if (spell == 'make sure ~') {
    return 'make_sure_';
  } else if (spell == 'Not at all') {
    return 'not_at_all_';
  } else if (spell == 'right-hand') {
    return 'right__hand';
  } else if (spell == 'watch out!') {
    return 'watch_out_';
  } else if (spell == 'do ~ a favor') {
    return 'do__a_favor';
  } else if (spell == 'end up -ing') {
    return 'end_up__ing';
  } else if (spell == 'keep ~ in mind') {
    return 'keep___in_mind';
  } else if (spell == 'National Park') {
    return '_national_park';
  } else if (spell == 'Act') {
    return '_act';
  } else if (spell == '명령문 ~, and') {
    return '_and';
  } else if (spell == 'Scene') {
    return '_scene';
  } else if (spell == 'well done') {
    return 'well__done';
  }
  let filename;
  filename = spell.toLowerCase();
  filename = filename.replace("'", '');
  filename = filename.replace(/[^0-9a-z]/gi, ' ');
  filename = filename.replace(/ +|\s+/g, ' ');
  filename = filename.trim();
  filename = filename.replace(/\s+/gi, '_');
  return filename;
}

export function arrShuffle(array: any[]) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

export function reverseString(str: string): string {
  return str.split('').reverse().join('');
}

export function shuffleDictation(array: any[], spell: string) {
  const new_array = [...array];
  let currentIndex = new_array.length,
    temporaryValue,
    randomIndex;

  while (0 != currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = new_array[currentIndex];
    new_array[currentIndex] = new_array[randomIndex];
    new_array[randomIndex] = temporaryValue;

    if (currentIndex == 0) {
      const char_set = new Set();
      new_array.forEach(v => {
        char_set.add(v.char);
      });
      const char_set_string = Array.from(char_set).join('');

      let processing_spell = '';
      let previousChar = '';

      for (let i = 0; i < spell.length; i++) {
        const currentChar = spell[i];

        if (currentChar != previousChar) {
          processing_spell += currentChar;
          previousChar = currentChar;
        }
      }

      const reverse_chart_set_string = reverseString(char_set_string);

      if (processing_spell.length == 2 && char_set_string.includes(processing_spell)) {
        // 선택 가능한 철자 버튼이 두개인 경우 역방향만 가능
        currentIndex = new_array.length;
      } else if (
        processing_spell.length >= 3 &&
        (char_set_string.includes(processing_spell) || reverse_chart_set_string.includes(processing_spell))
      ) {
        // 선택 가능한 철자 버튼이 3개 이상인 경우 정방향, 역방향 X
        currentIndex = new_array.length;
      }
    }
  }

  return new_array;
}

function shuffleArray(array: string[]) {
  const newArray = array.slice(); // 원본 배열 복사
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]]; // 요소 교환
  }
  return newArray;
}

export function shuffleChip(array: string[], rightanswer: string) {
  let shuffledSentence = '';
  let shuffledArray;
  do {
    shuffledArray = shuffleArray(array);
    shuffledSentence = shuffledArray.join(' ');
  } while (shuffledSentence === rightanswer);
  return shuffledArray;
}

export function korToEng(char: string) {
  const trans_arr: { [key: string]: string } = {
    ㅂ: 'q',
    ㅃ: 'q',
    ㅈ: 'w',
    ㅉ: 'w',
    ㄷ: 'e',
    ㄸ: 'e',
    ㄱ: 'r',
    ㄲ: 'r',
    ㅅ: 't',
    ㅆ: 't',
    ㅛ: 'y',
    ㅕ: 'u',
    ㅑ: 'i',
    ㅐ: 'o',
    ㅒ: 'o',
    ㅔ: 'p',
    ㅖ: 'p',
    ㅁ: 'a',
    ㄴ: 's',
    ㅇ: 'd',
    ㄹ: 'f',
    ㅎ: 'g',
    ㅗ: 'h',
    ㅓ: 'j',
    ㅏ: 'k',
    ㅣ: 'l',
    ㅋ: 'z',
    ㅌ: 'x',
    ㅊ: 'c',
    ㅍ: 'v',
    ㅠ: 'b',
    ㅜ: 'n',
    ㅡ: 'm',
  };
  const trans_char = trans_arr[char];
  if (trans_char) {
    return trans_char;
  } else {
    return char;
  }
}

export const setPartofSpeech = (ps: string) => {
  switch (ps) {
    case 'nou':
      return 'n.';
    case 'pro':
      return 'pron.';
    case 'ver':
      return 'v.';
    case 'adj':
      return 'adj.';
    case 'adv':
      return 'adv.';
    case 'pre':
      return 'prep.';
    case 'con':
      return 'conj.';
    case 'int':
      return 'interj.';
    case 'art':
      return 'arti.';
    case 'det':
      return 'dete.';
  }
};

export const setKorofSpeech = (ps: string) => {
  switch (ps) {
    case 'nou':
      return '명사';
    case 'pro':
      return '대명사';
    case 'ver':
      return '동사';
    case 'adj':
      return '형용사';
    case 'adv':
      return '부사';
    case 'pre':
      return '전치사';
    case 'con':
      return '접속사';
    case 'int':
      return '감탄사';
    case 'art':
      return '관사';
    case 'det':
      return '한정사';
  }
};

export const setDayToNumber = (day: string): number => {
  switch (day) {
    case '일':
      return 0;
    case '월':
      return 1;
    case '화':
      return 2;
    case '수':
      return 3;
    case '목':
      return 4;
    case '금':
      return 5;
    default:
      return 6;
  }
};

export const setModeToKor = (mode: string): string => {
  switch (mode) {
    case 'basic':
    case 'grammar_b':
      return '기본학습';
    case 'advanced':
    case 'grammar_d':
      return '심화학습';
    case 'speak':
      return '회화학습';
    case 'grammar_t':
      return '문법학습';
    default:
      return '';
  }
};

export const setTextShadow = ({
  color,
  sizeShadow,
  directionShadow,
}: {
  color: string;
  directionShadow: string;
  sizeShadow: number;
}): string => {
  let textShadow = '';

  for (let i = 0, len = sizeShadow; i < len; i++) {
    switch (directionShadow) {
      case 'top':
        textShadow += `0 -${i}px 0 ${color},`;
        break;
      case 'right':
        textShadow += `${i}px 0 0 ${color},`;
        break;
      case 'bottom':
        textShadow += `0 ${i}px 0 ${color},`;
        break;
      case 'left':
        textShadow += `-${i}px 0 0 ${color},`;
        break;
      case 'top-left':
        textShadow += `-${i}px -${i}px 0 ${color},`;
        break;
      case 'top-right':
        textShadow += `${i}px -${i}px 0 ${color},`;
        break;
      case 'bottom-left':
        textShadow += `-${i}px ${i}px 0 ${color},`;
        break;
      case 'bottom-right':
        textShadow += `${i}px ${i}px 0 ${color},`;
        break;
      default:
        textShadow += `${i}px ${i}px 0 ${color},`;
        break;
    }
  }

  textShadow = textShadow.slice(0, -1);
  return textShadow;
};

export const setTabNameToKor = (tab_name: string) => {
  const tab_name_list: { [key: string]: string } = {
    longvoca: '오래보카',
    textbook: '영어 교과서',
    customer: '만든 교재',
    exam: '수능/모의고사',
  };

  return tab_name_list[tab_name];
};

export const makeGradeLabel = (score: number) => {
  if (score == 100) {
    return 'PERFECT';
  } else if (score >= 91) {
    return 'EXCELLENT';
  } else if (score >= 81) {
    return 'BRAVO';
  } else if (score >= 71) {
    return 'GOOD JOB';
  } else if (score >= 61) {
    return 'GO FOR IT';
  } else {
    return 'TRY HARDER';
  }
};

export const makeGradeKorLabel = (score: number) => {
  if (score == 100) {
    return '완벽합니다!';
  } else if (score >= 91) {
    return '아주 잘했어요!';
  } else if (score >= 81) {
    return '잘 하고 있어요!';
  } else if (score >= 71) {
    return '잘 하고 있어요!';
  } else if (score >= 61) {
    return '좀 더 노력해보세요!';
  } else {
    return '좀 더 분발하세요!';
  }
};

export const makeGradeIcon = (score: number) => {
  if (score > 70) {
    return <FaThumbsUp style={{ width: '3.25rem', height: '3.25rem' }} strokeWidth={'0'} />;
  } else {
    return <HiCheck strokeWidth={'2'} />;
  }
};

export const makeEndModuleModalProps = (resultsheet: ResultsheetType[]) => {
  const total_question_count = resultsheet.length;
  let right_count = 0;
  resultsheet.forEach(result => {
    if (result.answer == result.rightanswer) {
      right_count += 1;
    } else if (result.answer == '333') {
      right_count += 0.5;
    }
  });

  const score = (right_count / total_question_count) * 100;

  const label = makeGradeLabel(score);
  const icon = makeGradeIcon(score);
  const kor_label = makeGradeKorLabel(score);

  return { label, icon, score, kor_label };
};

export const shuffleWordBingo = (array: WordbingoCardType[]): WordbingoCardType[] => {
  if (array.length == 1) return array;
  const new_array = [...array];
  const strikeOut: WordbingoCardType[] = [];
  let not_reverse = false;

  while (new_array.length) {
    const lastIdx = new_array.length - 1;
    const roll = Math.floor(Math.random() * lastIdx);

    if (roll >= 0) {
      const temp = new_array[lastIdx];
      new_array[lastIdx] = new_array[roll];
      new_array[roll] = temp;
    }

    const card = new_array.pop();
    if (card) {
      strikeOut.unshift(card);
    }

    // 안에 요소가 3개 이상인 경우, 역방향으로 안되게 추가 검증
    if (array.length >= 3 && strikeOut.length == array.length) {
      const reverse_array = [...array].reverse();
      for (let i = 0; i < reverse_array.length; i++) {
        if (reverse_array[i].id !== strikeOut[i].id) {
          not_reverse = true;
          break;
        }
      }
    } else if (array.length == 2 && strikeOut.length == array.length) {
      not_reverse = true;
    }
  }

  if (not_reverse) {
    return strikeOut;
  } else {
    return shuffleWordBingo(array);
  }
};

export const setModalTitle = ({ title, subTitle, target }: { title: string; subTitle?: string; target?: string }) => {
  let tmp_title = title;
  let tmp_sub_title = subTitle;
  if (target) {
    tmp_title = title.replace('target', target);
    if (tmp_sub_title) {
      tmp_sub_title = tmp_sub_title.replace('target', target);
      return `<span>${tmp_title}</span>${tmp_sub_title ? `<span class="sub-title">${tmp_sub_title}</span>` : ''}`;
    }
  } else if (tmp_sub_title) {
    return `<span>${tmp_title}</span>${tmp_sub_title ? `<span class="sub-title">${tmp_sub_title}</span>` : ''}`;
  }
  return tmp_title;
};

export const imgPreload = (images: string[]) => {
  return images.map(src => {
    const img = new Image();
    img.src = src;
    return img;
  });
};

export const koreanReplace = (str: string) => {
  const korean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/gi;
  let res = str;
  if (korean.test(res)) {
    res = res.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/gi, (v, i) => {
      return '';
    });
  }
  return res;
};

export const convertKorToGrade = (str: string) => {
  let grade_code = '';
  const match = str.match(/\(([^)]+)\)/);

  if (match) {
    const valueInParentheses = match[1];
    if (str.includes('유아')) {
      grade_code += 'd';

      switch (valueInParentheses) {
        case '5세':
          grade_code += '1';
          break;
        case '6세':
          grade_code += '2';
          break;
        case '7세':
          grade_code += '3';
          break;
      }
    } else if (str.includes('초등')) {
      grade_code += 'a';
    } else if (str.includes('중등')) {
      grade_code += 'b';
    } else if (str.includes('고등')) {
      grade_code += 'c';
    } else {
      grade_code += 'e';
      //* 기타
      switch (valueInParentheses) {
        case '재수생':
          grade_code += '1';
          break;
        case '공무원 공부':
          grade_code += '2';
          break;
        case '토익준비':
          grade_code += '3';
          break;
        case '일반인':
          grade_code += '4';
          break;
      }
    }

    if (grade_code && ['a', 'b', 'c'].includes(grade_code)) {
      switch (valueInParentheses) {
        case '1학년':
          grade_code += '1';
          break;
        case '2학년':
          grade_code += '2';
          break;
        case '3학년':
          grade_code += '3';
          break;
        case '4학년':
          grade_code += '4';
          break;
        case '5학년':
          grade_code += '5';
          break;
        case '6학년':
          grade_code += '6';
          break;
      }
    }
  }

  return grade_code;
};

export const convertGradeToKor = (str: string) => {
  const category_code = str[0];
  const content_code = str[1];
  let categoryId = 0;
  let name = '';
  let content_name = '';
  let contentId = 0;
  switch (category_code) {
    case 'd':
      categoryId = 1;
      name = '유아';
      switch (content_code) {
        case '1':
          content_name = '5세';
          contentId = 6;
          break;
        case '2':
          content_name = '6세';
          contentId = 7;
          break;
        case '3':
          content_name = '7세';
          contentId = 8;
          break;
      }
      break;
    case 'a':
      categoryId = 2;
      name = '초등';
      switch (content_code) {
        case '1':
          content_name = '1학년';
          contentId = 9;
          break;
        case '2':
          content_name = '2학년';
          contentId = 10;
          break;
        case '3':
          content_name = '3학년';
          contentId = 11;
          break;
        case '4':
          content_name = '4학년';
          contentId = 12;
          break;
        case '5':
          content_name = '5학년';
          contentId = 13;
          break;
        case '6':
          content_name = '6학년';
          contentId = 14;
          break;
      }
      break;
    case 'b':
      categoryId = 3;
      name = '중등';
      switch (content_code) {
        case '1':
          content_name = '1학년';
          contentId = 15;
          break;
        case '2':
          content_name = '2학년';
          contentId = 16;
          break;
        case '3':
          content_name = '3학년';
          contentId = 17;
          break;
      }
      break;
    case 'c':
      categoryId = 4;
      name = '고등';
      switch (content_code) {
        case '1':
          content_name = '1학년';
          contentId = 18;
          break;
        case '2':
          content_name = '2학년';
          contentId = 19;
          break;
        case '3':
          content_name = '3학년';
          contentId = 20;
          break;
      }
      break;
    case 'e':
      categoryId = 5;
      name = '기타';
      switch (content_code) {
        case '1':
          content_name = '재수생';
          contentId = 21;
          break;
        case '2':
          content_name = '공무원 공부';
          contentId = 22;
          break;
        case '3':
          content_name = '토익준비';
          contentId = 23;
          break;
        case '4':
          content_name = '일반인';
          contentId = 24;
          break;
      }
      break;
  }

  const parent_obj = {
    categoryId,
    name,
    parentId: '0',
  };

  const son_obj = {
    categoryId: contentId,
    name: content_name,
    parentId: categoryId,
  };

  const value_obj = {
    categoryId: contentId,
    name: `${name} (${content_name})`,
    parentId: categoryId,
  };

  return {
    gradeValue: value_obj,
    gradeItem: son_obj,
    gradeItemsValue: {
      1: parent_obj,
      2: son_obj,
    },
  };
};

export function formatDate(date: Date | null) {
  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}

export const formatUnixTime = (unixTime: number) => {
  const date = new Date(unixTime * 1000);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export function convertSubject(subject: string) {
  switch (subject) {
    case 'listening':
      return '듣기';
    case 'writing':
      return '쓰기';
    case 'speaking':
      return '말하기';
    case 'reading':
      return '읽기';
    default:
      return subject;
  }
}

export const addUsersInClass = async (center_id: number, item: TableItemsType) => {
  // 한 번만 요청 될 수 있도록 details.students가 없는 경우로 조건 잡음
  if (item && item.id && item.details && !item.details.student) {
    const class_id = item.id;
    try {
      const res: GetStudentsInClassResType = await fetchGetApi(`/customers/${center_id}/classes/${class_id}/students`);

      let new_deatils: { [key: string]: string } = {};
      if (res.data.results.length) {
        const rows = res.data.results;
        for (let i = 0, leng = rows.length; i < leng; i++) {
          if (i > 0) {
            new_deatils = { ...new_deatils, [`s${i}`]: `${rows[i].name}/${rows[i].accountId}` };
          } else {
            if (item.details && item.details['sub_teachers']) {
              new_deatils = {
                teacher: String(item.details['teacher']),
                sub_teachers: String(item.details['sub_teachers']),
                student: `${rows[i].name}/${rows[i].accountId}`,
              };
            } else {
              new_deatils = {
                teacher: String(item.details['teacher']),
                student: `${rows[i].name}/${rows[i].accountId}`,
              };
            }
          }
        }
        item.details = new_deatils;
      } else {
        new_deatils = { ...item.details, student: '등록된 학생이 없습니다.' };
        item.details = new_deatils;
      }
    } catch (error) {
      console.log(error);
    }
  }
};

// 선생님 관리 페이지에서 테이블 row 클릭 시, 선생님의 클래스 정보 요청 하는 api
export const addClassesInTeacher = async (center_id: number, item: TableItemsType) => {
  if (item && item.teacher_id && item.details && item.details.rname === null) {
    const teacher_id = item.teacher_id;
    try {
      const res: GetClassesInTeacher = await fetchGetApi(
        `/customers/${center_id}/accounts/teachers/${teacher_id}/classes`,
      );

      let new_details: { class_name: string; sub_class_name?: string };

      if (res.data.class_name) {
        new_details = { class_name: res.data.class_name };
      } else {
        new_details = { class_name: '배정된 클래스가 없습니다.' };
      }

      if (res.data.sub_class_name) {
        new_details = { class_name: new_details.class_name, sub_class_name: res.data.sub_class_name };
      }
      item.details = new_details;
    } catch (error) {
      console.log(error);
    }
  }
};

// * 학습 목표 가공처리 > 학습 목표 모달에 사용할 데이터
export const processingStudyTarget = (study_target: StudyTarget | null, product: string) => {
  if (study_target) {
    const { study_domains, study_days, study_start_time } = study_target;
    const domain_array = study_domains.split(',');
    const day_array = study_days.split(',');
    const study_start_hour = study_start_time.substring(0, 2);
    const study_start_minute = study_start_time.substring(2, 4);

    const domainButtonGroup: {
      id: number;
      label: string;
      check: boolean;
      disabled: boolean;
    }[] = [
      {
        id: 1,
        label: '어휘',
        check: false,
        key: 'longvoca',
        disabled: false,
      },
      {
        id: 2,
        label: '내신',
        check: false,
        key: 'school',
        disabled: false,
      },
      {
        id: 3,
        label: '문법',
        check: false,
        key: 'grammar',
        disabled: false,
      },
      {
        id: 4,
        label: '회화',
        check: false,
        key: 'speak',
        disabled: false,
      },
    ]
      .map(item => {
        if (product == 'G003Y') {
          if (item.label != '문법') {
            item.disabled = true;
            return {
              ...item,
            };
          } else {
            return {
              ...item,
            };
          }
        } else if (product == 'S003Y') {
          if (item.label != '회화') {
            item.disabled = true;
            return {
              ...item,
            };
          } else {
            return {
              ...item,
            };
          }
        } else if (product == 'V003Y') {
          if (item.label != '어휘' && item.label != '내신') {
            item.disabled = true;
            return {
              ...item,
            };
          } else {
            return {
              ...item,
            };
          }
        } else {
          return { ...item };
        }
      })
      .map(item => {
        let check = false;
        if (domain_array.includes(item.key)) check = true;

        return {
          id: item.id,
          label: item.label,
          check,
          disabled: item.disabled,
        };
      });

    const dayButtonGroup = [
      {
        id: 1,
        label: '월',
        check: false,
      },
      {
        id: 2,
        label: '화',
        check: false,
      },
      {
        id: 3,
        label: '수',
        check: false,
      },
      {
        id: 4,
        label: '목',
        check: false,
      },
      {
        id: 5,
        label: '금',
        check: false,
      },
      {
        id: 6,
        label: '토',
        check: false,
      },
      {
        id: 7,
        label: '일',
        check: false,
      },
    ].map(item => {
      let check = false;
      if (day_array.includes(String(item.id))) check = true;
      else if (day_array.includes('0') && item.id == 7) check = true;

      return {
        ...item,
        check,
      };
    });

    let id = 0;
    if (Number(study_start_hour) == 0) {
      id = 1;
    } else {
      id = 1 + 2 * Number(study_start_hour);
    }

    if (Number(study_start_minute) == 30) {
      id += 1;
    }

    const study_start_time_content = `${study_start_hour}시 ${study_start_minute}분`;

    const alimTime = { id, content: study_start_time_content };

    return {
      domainButtonGroup,
      dayButtonGroup,
      alimTime,
    };
  } else {
    return undefined;
  }
};

export const freeFormatEndDate = (enddate: string) => {
  const regex = new RegExp(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/g, 'i');

  if (regex.test(enddate)) {
    const dayjs_enddate = dayjs(enddate);
    return dayjs_enddate.format('YYYY-MM-DD일 HH:ss분 ');
  } else {
    return enddate;
  }
};

export const formatEndDate = (enddate: string) => {
  //! dayjs로 포맷팅하는 로직으로 바꾸기
  const regex = new RegExp(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/g, 'i');
  if (regex.test(enddate)) {
    const date_enddate = new Date(enddate);

    return setLocaleDateString(date_enddate, '-');
  } else {
    return enddate;
  }
};

// 1개월 가격
export function calculateProPayment(originFee: number) {
  const currentDate = dayjs();
  const lastDay = currentDate.endOf('month').date();

  const calcDay = lastDay - currentDate.date() + 1;
  const calcFee = Math.floor((originFee / lastDay) * calcDay);

  const truncatedCalcFee = Math.floor(calcFee / 10) * 10;

  return truncatedCalcFee;
}

// 상품분석 (가입 모달 오픈할 때 활용)
export function separateProduct(code: string) {
  let isSelectedProduct = 0;
  let isSelectedDuration = 0;
  switch (code[0]) {
    case 'G':
      isSelectedProduct = 0;
      break;
    case 'A':
      isSelectedProduct = 1;
      break;
    case 'V':
      isSelectedProduct = 2;
      break;
    case 'S':
      isSelectedProduct = 3;
      break;
  }

  switch (code[code.length - 1]) {
    case 'M':
      isSelectedDuration = 0;
      break;
    case 'H':
      isSelectedDuration = 1;
      break;
    case 'Y':
      isSelectedDuration = 2;
      break;
  }

  return {
    isSelectedProduct,
    isSelectedDuration,
  };
}

export function validateUseRecoilPath(
  path: PathTypes,
  pathValidateArr: PATH_VALIDATE_TYPE,
  recoilState: any,
): { [key: string]: any } | undefined {
  const { rootPath, childPath } = path;

  if (childPath) {
    const isValidate = pathValidateArr[rootPath].validateChildPath.includes(childPath);

    if (isValidate) {
      const state = recoilState[rootPath][childPath];
      return {
        ...state,
      };
    } else {
      return undefined;
    }
  } else {
    const tmp_child_path = '';
    if (rootPath) {
      const isValidate = pathValidateArr[rootPath].validateChildPath.includes(tmp_child_path);
      if (isValidate && recoilState[rootPath]) {
        const state = recoilState[rootPath]['root'];
        return {
          ...state,
        };
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }
}

export const convertPaymentFailMessage = (message: string) => {
  switch (message) {
    case '유효기간경과':
      return '유효기간이 일치하지 않습니다.\r다시 입력해주세요.';
    case '생년월일 불일치':
      return '생년월일이 일치하지 않습니다.\r생년월일 3회 입력 오류시 온라인결제가 제한됩니다.\r다시 입력해주세요.';
    case '카드번호 오류':
      return '카드번호가 일치하지 않습니다.\r다시 입력해주세요.';
    case '비밀번호횟수초과':
      return '입력 오류 횟수 초과로 입력이 제한됩니다.\r카드사 측에 문의해주세요.';
    case '잔액부족':
      return '카드잔액이 부족합니다.\r다른 카드 결제 또는 카드 잔액을 확인해주세요.';
    case '할부개월수 오류':
      return '할부 개월수 오류입니다.\r다시 입력해주세요.';
    case '해외카드 미사용 가맹점':
      return '카드결제에 실패했습니다.\r카드 정보를 다시 확인해주세요.';
    default:
      return '카드결제에 실패했습니다.\r잠시 후 다시 시도해주세요.';
  }
};

export const convertRegisterCardFailMessage = (message: string) => {
  switch (message) {
    case '유효기간경과':
      return '유효기간이 일치하지 않습니다.\r다시 입력해주세요.';
    case '생년월일 불일치':
      return '생년월일이 일치하지 않습니다.\r생년월일 3회 입력 오류시 온라인결제가 제한됩니다.\r다시 입력해주세요.';
    case '카드번호 오류':
      return '카드번호가 일치하지 않습니다.\r다시 입력해주세요.';
    case '비밀번호횟수초과':
      return '입력 오류 횟수 초과로 입력이 제한됩니다.\r카드사 측에 문의해주세요.';
    case '잔액부족':
      return '카드잔액이 부족합니다.\r다른 카드 결제 또는 카드 잔액을 확인해주세요.';
    case '할부개월수 오류':
      return '할부 개월수 오류입니다.\r다시 입력해주세요.';
    case '해외카드 미사용 가맹점':
      return '카드 등록에 실패했습니다.\r카드 정보를 다시 확인해주세요.';
    default:
      return '카드 등록에 실패했습니다.\r잠시 후 다시 시도해주세요.';
  }
};

export function makeUri(
  start: string,
  queries: { target?: string; sort?: string; page?: number; max?: number; searchKeyword?: string },
) {
  const { target, sort, page, max, searchKeyword } = queries;
  const items = [];
  if (target) items.push(`sortKey=${target}`);
  if (sort) items.push(`sortValue=${sort}`);
  if (page) items.push(`page=${page}`);
  if (max) items.push(`limit=${max}`);
  if (searchKeyword) items.push(`search=${escapeSpecialCharacter(searchKeyword)}`);
  // 현재 필요x (일단 남겨둠)
  const uri = start + '?' + items.join('&');
  return uri;
}

export const escapeSpecialCharacter = (keyword: string) => {
  const encode_keyword: string = encodeURIComponent(keyword);
  return encode_keyword;
};

export function getFormattedLastDayOfMonth() {
  const currentDate = dayjs();
  const nextMonth = currentDate.add(1, 'month').startOf('month');
  const lastDayOfMonth = nextMonth.subtract(1, 'day');
  const formattedDate = lastDayOfMonth.format('YYYY. MM. DD');
  return formattedDate;
}

export function autoHyphenCard(str: string) {
  str = str.replace(/[^0-9]/g, '');

  let tmp = '';

  for (let i = 0; i < str.length; i += 4) {
    tmp += str.substring(i, i + 4);
    tmp += '-';
  }

  return tmp.slice(0, tmp.length - 1);
}

export function autoHyphenBusinessNumber(str: string) {
  str = str.replace(/[^0-9]/g, '');

  let tmp = '';

  for (let i = 0; i < str.length; i++) {
    if (i === 3 || i === 5) {
      tmp += '-';
    }
    tmp += str[i];
  }

  return tmp;
}

export function autoBrn(businessNumber: string) {
  return businessNumber
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, '$1-$2-$3')
    .replace(/(\-{1,2})$/g, '');
}

// 공지사항 타입 설정
export const getKorNotice = (type: number) => {
  let res = { type: '', label: '' };
  switch (type) {
    case 1:
      res = { type: 'color #2b8ad1', label: '전체공지' };
      break;
    case 2:
      res = { type: 'color #2b8ad1', label: '수업공지' };
      break;
    case 3:
      res = { type: 'color #2b8ad1', label: '반별공지' };
      break;
    case 21:
      res = { type: 'color #2b8ad1', label: '공지사항' };
  }
  return res;
};

export const setCalendarHeightAuto = (direction?: 'previous' | 'next') => {
  setTimeout(() => {
    let max_height = 0;
    const class_el_array = document.getElementsByClassName('MuiDayPicker-monthContainer');
    if (class_el_array.length) {
      for (let i = 0; i < class_el_array.length; i++) {
        const class_el = class_el_array.item(i);
        if (class_el) {
          const month_height = (class_el as HTMLElement).clientHeight;
          if (direction) {
            if (i == 0) {
              max_height = month_height;
            }
          } else {
            if (class_el) {
              if (max_height < month_height) {
                max_height = month_height;
              }
            }
          }
        }
      }

      const calendar_body_el = document.querySelector('.PrivatePickersSlideTransition-root');

      if (calendar_body_el) {
        (calendar_body_el as HTMLElement).style.height = `${max_height + 2}px`;
      }
    }
  }, 0);
};

export const changeCalendarWeekdayName = (dayOfWeek: string) => {
  switch (dayOfWeek) {
    case '일':
      return 'Sun';
    case '월':
      return 'Mon';
    case '화':
      return 'Tue';
    case '수':
      return 'Wed';
    case '목':
      return 'Thu';
    case '금':
      return 'Fri';
    case '토':
      return 'Sat';
    default:
      return '';
  }
};

export const convertProductName = (product: string) => {
  switch (product) {
    case '5+1 혜택상품':
      return '학기 상품';
    case '6+6 혜택상품':
      return '1년 무제한 상품';
    default:
      return '월 정기상품';
  }
};

export const convertLabelToPaymentKind = (label: string) => {
  switch (label) {
    case 'VA':
      return '가상계좌';
    case 'OA':
      return '전용계좌';
    case 'AC':
      return '카드결제';
    case 'CD':
      return '키인결제';
    default:
      return '';
  }
};

declare let window: any;
export const isMobileValidate = () => {
  const isMobileApp = window?.cordova?.platformId === 'ios' || window?.cordova?.platformId === 'android';
  const isIOSSafari = /iP(ad|hone|od).+Version\/[\d\.]+.*Safari/i.test(navigator.userAgent);
  const isAndroidMobile = /Android/i.test(navigator.userAgent);
  console.log('isMobileApp : ', isMobileApp);
  console.log('isIOSSafari : ', isIOSSafari);
  console.log('isAndroidMobile : ', isAndroidMobile);
  return isMobileApp || isIOSSafari || isAndroidMobile;
};

export const getPersonalProductPackageAndPeriod = (productType: string) => {
  const period = productType.includes('H') ? 'H' : productType.includes('M') ? 'M' : 'Y';
  if (['A003Y', 'A003H', 'A003M'].includes(productType)) {
    return {
      package: 'all',
      period: period,
    };
  } else if (['S003Y', 'S003H', 'S003M'].includes(productType)) {
    return {
      package: 'speak',
      period: period,
    };
  } else if (['V003Y', 'V003H', 'V003M'].includes(productType)) {
    return {
      package: 'voca',
      period: period,
    };
  } else {
    return {
      package: 'grammar',
      period: period,
    };
  }
};

// 요소의 위치 및 크기 구하기 (px)
export const getElementRect = (id: string, position?: 'top' | 'left' | 'right' | 'bottom' | 'width' | 'height') => {
  const target = document.getElementById(id);
  const clientRect = target?.getBoundingClientRect();

  if (!clientRect) return null;

  switch (position) {
    case 'top':
      return clientRect.top;
    case 'left':
      return clientRect.left;
    case 'right':
      return clientRect.right;
    case 'bottom':
      return clientRect.bottom;
    case 'width':
      return clientRect.width;
    case 'height':
      return clientRect.height;
    default:
      return clientRect.top;
  }
};
