import { SearchBar } from './SearchBar.Styles';
export { SearchIcon, XIcon } from './SearchBar.Styles';
import { SxProps, Theme } from '@mui/material';
import React from 'react';

export interface SearchBarVAProps {
  icon: React.ReactNode;
  value: string;
  placeholder?: string;
  sx?: SxProps<Theme>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  onBlur: () => void;
}

export const SearchBarUI = React.memo(function SearchBarUI(props: SearchBarVAProps) {
  return <SearchBar endAdornment={props.icon} {...props} inputProps={{ id: 'searchbar_default' }} />;
});
