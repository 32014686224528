import { DropdownNormalUI, DropdownNormalVAProps } from './DropdownNormal.Presenter';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { dropdownNormalData, DropdownNormalDataType } from 'recoil/common/dropdownNormal';
import React from 'react';
import { SxProps } from '@mui/system';

export interface DropdownContainerProps
  extends Pick<
    DropdownNormalVAProps,
    'dropdownContents' | 'type' | 'disabled' | 'title' | 'endIcon' | 'color' | 'className' | 'variant'
  > {
  onClickItem: (idx: number, initDrdopownTitle: () => void) => void;
  checkCount?: number;
  dropdownKey?: string;
  useRecoil?: boolean;
  isDisabled?: boolean;
}

export const DropdownNormalContainer = React.memo(function DropdownNormalContainer({
  dropdownContents,
  type,
  disabled,
  title,
  endIcon,
  color,
  className,
  onClickItem,
  checkCount,
  variant,
  dropdownKey,
  isDisabled,
}: DropdownContainerProps) {
  const [dropdownNormal, setDropdownNormal] = useRecoilState<DropdownNormalDataType>(dropdownNormalData);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    return () => {
      setDropdownNormal(() => ({ normal: { title: '' }, circle: { title: '' } }));
    };
  }, []);

  const open = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);

  const onClickDropdownButton = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!isDisabled) {
        setAnchorEl(event.currentTarget);
      }
    },
    [dropdownKey, isDisabled],
  );

  const onCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const initDropdownTitle = useCallback(() => {
    if (title) {
      if (type) {
        setDropdownNormal(prev => ({ ...prev, [type]: { title } }));
      } else {
        setDropdownNormal(prev => ({ ...prev, normal: { title } }));
      }
    }
  }, []);

  const onClickMenu = useCallback(
    (idx: number) => {
      onClickItem(idx, initDropdownTitle);
      let title = `${dropdownContents[idx].content}`;
      title = title.replace('_require', '');
      title = title.replace('::disabled', '');
      title = title.replace('::forbidden', '');

      setAnchorEl(null);
    },
    [dropdownContents, title, onClickItem],
  );

  const setMenuDisabled = useCallback(
    (text: string): boolean => {
      if (text.indexOf('::disabled') >= 0) {
        if (checkCount == 1) {
          return false;
        } else {
          return true;
        }
      } else if (text.indexOf('::forbidden') >= 0) {
        return true;
      } else {
        return false;
      }
    },
    [checkCount],
  );

  const dropdownTitle = useMemo(() => {
    return title?.replace('시 ', ':').replace('분', '');
  }, [dropdownNormal, title]);

  const props = {
    dropdownContents,
    open,
    anchorEl,
    disabled,
    dropdownTitle,
    title,
    type,
    onClickDropdownButton,
    onClickMenu,
    onCloseMenu,
    setMenuDisabled,
    endIcon,
    color,
    className,
    variant,
  };

  return <DropdownNormalUI {...props} />;
});
