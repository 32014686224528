import { atom, SetterOrUpdater } from 'recoil';
import image_setting_1 from 'assets/images/image_setting_1.png';
import image_setting_2 from 'assets/images/image_setting_2.png';
import method_setting_1 from 'assets/images/method_setting_1.png';
import method_setting_2 from 'assets/images/method_setting_2.png';
import wrongNote_setting_1 from 'assets/images/wrongNote_setting_1.png';
import wrongNote_setting_2 from 'assets/images/wrongNote_setting_2.png';
import testMethod_setting_1 from 'assets/images/testMethod_setting_1.png';
import testMethod_setting_2 from 'assets/images/testMethod_setting_2.png';
import print_setting_1 from 'assets/images/print_setting_1.png';
import print_type_setting_3 from 'assets/images/print_type_setting_3.png';
import print_type_setting_4 from 'assets/images/print_type_setting_4.png';
import course_setting_1 from 'assets/images/course_setting_1_v3.png';
import course_setting_2 from 'assets/images/course_setting_2_v3.png';
import join_setting_1 from 'assets/images/join_setting_1.png';
import join_setting_2 from 'assets/images/join_setting_2.png';

import {
  image_content_1,
  image_content_2,
  method_content_1,
  method_content_2,
  wrongNote_content_1,
  wrongNote_content_2,
  testMethod_content_1,
  testMethod_content_2,
  print_content_1,
  print_type_content_1,
  print_type_content_2,
  course_type_content_1,
  course_type_content_2,
  join_type_content_1,
  join_type_content_2,
} from 'utils/constants';

export interface ModalStudySettingContentType {
  src: string;
  content: string;
  target?: string;
}

export interface ModalStudySettingDataType {
  visible: boolean;
  type:
    | 'image'
    | 'method'
    | 'wrongNote'
    | 'testMethod'
    | 'print'
    | 'printType'
    | 'courseType_1'
    | 'courseType_2'
    | 'joinType';
  title: string;
  contents: ModalStudySettingContentType[];
  onSuccess: (idx: number) => void;
  value?: number;
  onClose?: () => void;
  previousPath?: string;
}

export const modalStudySettingData = atom<ModalStudySettingDataType>({
  key: 'modalStudySetting',
  default: {
    visible: false,
    type: 'image',
    title: '',
    contents: [],
    onSuccess: (idx: number) => {
      return;
    },
    onClose: () => {
      return;
    },
    previousPath: '/manager',
  },
});

interface ModalStudySettingSetter {
  setModalStudySetting: SetterOrUpdater<ModalStudySettingDataType>;
}

export const openModalStudySetting = (
  { setModalStudySetting }: ModalStudySettingSetter,
  type:
    | 'image'
    | 'method'
    | 'wrongNote'
    | 'testMethod'
    | 'print'
    | 'printType'
    | 'courseType_1'
    | 'courseType_2'
    | 'joinType',
  onSuccess: (idx: number) => void,
  custom_title?: string,
  value?: number,
  onClose?: () => void,
) => {
  // type에 따라 title image, content 세팅

  let title = '';
  let contents: ModalStudySettingContentType[] = [];
  if (type == 'image') {
    title = '<b>이미지 학습 방식</b>을 선택해주세요!';
    contents = [
      {
        src: image_setting_1,
        content: image_content_1,
        target: '연상 학습이',
      },
      {
        src: image_setting_2,
        content: image_content_2,
        target: '일반 학습이',
      },
    ];
  } else if (type == 'method') {
    title = '<b>단계별 학습 방식</b>을 선택해주세요!';
    contents = [
      {
        src: method_setting_1,
        content: method_content_1,
        target: '자율 학습으로',
      },
      {
        src: method_setting_2,
        content: method_content_2,
        target: '단계별 학습으로',
      },
    ];
  } else if (type == 'wrongNote') {
    title = '<b>오답 학습 생성여부</b>를 선택해주세요!';
    contents = [
      {
        src: wrongNote_setting_1,
        content: wrongNote_content_1,
        target: '자동 생성으로',
      },
      {
        src: wrongNote_setting_2,
        content: wrongNote_content_2,
        target: '사용 안함으로',
      },
    ];
  } else if (type == 'testMethod') {
    title = custom_title ? custom_title : '<b>평가 출제방식</b>을 선택해주세요!';
    contents = [
      {
        src: testMethod_setting_1,
        content: testMethod_content_1,
      },
      {
        src: testMethod_setting_2,
        content: testMethod_content_2,
      },
    ];
  } else if (type == 'print') {
    title = '<b>교재 출력방식</b>을 선택해주세요!';
    contents = [
      {
        src: print_setting_1,
        content: print_content_1,
      },
    ];
  } else if (type == 'printType') {
    title = '<b>교재 출력방식</b>을 선택해주세요!';
    contents = [
      {
        src: print_type_setting_3,
        content: print_type_content_1,
      },
      {
        src: print_type_setting_4,
        content: print_type_content_2,
      },
    ];
  } else if (type == 'courseType_1') {
    title = '배포하실 <b>학습 컨텐츠를 선택</b>해주세요!';
    contents = [
      {
        src: course_setting_1,
        content: course_type_content_1,
      },
      {
        src: course_setting_2,
        content: course_type_content_2,
      },
    ];
  } else if (type == 'courseType_2') {
    title = '출력하실 <b>학습 컨텐츠를 선택</b>해주세요!';
    contents = [
      {
        src: course_setting_1,
        content: course_type_content_1,
      },
      {
        src: course_setting_2,
        content: course_type_content_2,
      },
    ];
  } else if (type == 'joinType') {
    title = '<b>오래영어 학습 안내</b>';
    contents = [
      {
        src: join_setting_1,
        content: join_type_content_1,
      },
      {
        src: join_setting_2,
        content: join_type_content_2,
      },
    ];
  }

  setModalStudySetting(prev => {
    return {
      visible: true,
      type,
      title,
      contents,
      onSuccess,
      value,
      onClose,
      previousPath: prev.previousPath,
    };
  });
};

export const closeModalStudySetting = ({ setModalStudySetting }: ModalStudySettingSetter) => {
  setModalStudySetting(prev => {
    return {
      visible: false,
      type: 'image',
      title: '',
      contents: [],
      onSuccess: (idx: number) => {
        return;
      },
      value: 0,
      previousPath: prev.previousPath,
    };
  });
};
