import { ReactNode } from 'react';
import { atom } from 'recoil';
import InfoIcon from '../../components/card/InfoIcon';
import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import theme from '../../theme';

export const Title = styled(Box)({
  mb: '20px',
  display: 'flex',
  alignItems: 'center',
});

export const Content = styled('span')({
  paddingLeft: '12px',
});

export interface CardContentTypes {
  id: string;
  title: { text: string | ReactNode };
  paragraph: { text: string | ReactNode }[];
}

export interface CardTypes {
  [key: string]: {
    [key: string]: CardContentTypes;
  };
}

export const cardState = atom<CardTypes>({
  key: 'cardState',
  default: {
    student: {
      root: {
        id: 'student_teacher',
        title: {
          text: (
            <Title>
              <InfoIcon></InfoIcon>
              선생님 관리
            </Title>
          ),
        },
        paragraph: [
          {
            text: (
              <Content>
                •{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  대표 관리자, 부원장, 실장
                </Box>
                의 권한은{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  모든 데이터 조회가 가능
                </Box>
                합니다.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                •{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  관리자는 최대 10명
                </Box>
                까지 등록 가능합니다. (관리자 계정으로는 학습 불가)
              </Content>
            ),
          },
          {
            text: (
              <Content>
                • 클래스의 부담당 설정은{' '}
                <Box
                  component={'span'}
                  sx={{
                    color: theme.palette.card_blue.main,
                  }}
                >
                  <Link
                    id='card-link-to-class'
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    to='/student/class'
                  >
                    클래스 관리
                  </Link>{' '}
                  {'>'} 관리자 추가
                </Box>
                를 통해 가능합니다.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                •{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  관리자는 각 배정된 클래스만
                </Box>{' '}
                관리할 수 있습니다.
              </Content>
            ),
          },
          {
            text: <Content sx={{ pl: '20px' }}>(레포트 및 평가 기록 등 본인 클래스에 소속된 학생만 조회 가능)</Content>,
          },
        ],
      },
      teacher: {
        id: 'student_teacher',
        title: {
          text: (
            <Title>
              <InfoIcon></InfoIcon>
              선생님 관리
            </Title>
          ),
        },
        paragraph: [
          {
            text: (
              <Content>
                •{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  대표 관리자, 부원장, 실장
                </Box>
                의 권한은{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  모든 데이터 조회가 가능
                </Box>
                합니다.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                •{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  관리자는 최대 10명
                </Box>
                까지 등록 가능합니다. (관리자 계정으로는 학습 불가)
              </Content>
            ),
          },
          {
            text: (
              <Content>
                • 클래스의 부담당 설정은{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  <Link
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    to='/student/class'
                    id='card-link-to-class'
                  >
                    클래스 관리
                  </Link>{' '}
                  {'>'} 관리자 추가
                </Box>
                를 통해 가능합니다.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                •{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  관리자는 각 배정된 클래스만
                </Box>{' '}
                관리할 수 있습니다.
              </Content>
            ),
          },
          {
            text: <Content sx={{ pl: '20px' }}>(레포트 및 평가 기록 등 본인 클래스에 소속된 학생만 조회 가능)</Content>,
          },
        ],
      },
      list: {
        id: 'student_list',
        title: {
          text: (
            <Title>
              <InfoIcon></InfoIcon>
              학생 목록
            </Title>
          ),
        },
        paragraph: [
          {
            text: <Content>• 학생은 재원생, 휴원생, 퇴원생으로 분류하여 관리합니다.</Content>,
          },
          {
            text: (
              <Content>
                • 재원생 선택 후,{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  편집을 통해 휴원/퇴원 처리
                </Box>{' '}
                가능합니다.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                •{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  학년은 매년 1월 1일
                </Box>{' '}
                한 학년씩{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  자동 상향
                </Box>
                됩니다.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                • 학생 계정{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  비밀번호는 정보 수정을 통해 변경 가능
                </Box>
                합니다.
              </Content>
            ),
          },
          {
            text: (
              <Content sx={{ pl: '20px' }}>
                (해당 관리자 화면에 암호 노출을 방지하였으며, 정보 수정으로 재설정 권장)
              </Content>
            ),
          },
        ],
      },
      listexcel: {
        id: 'student_listexcel',
        title: {
          text: (
            <Title>
              <InfoIcon></InfoIcon>
              학생 일괄등록
            </Title>
          ),
        },
        paragraph: [
          {
            text: (
              <Content>
                •{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  엑셀 양식 다운로드
                </Box>
                를 클릭하여 파일을 다운로드 받으세요.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                •{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  필수 입력사항
                </Box>{' '}
                : 클래스, 학년, 이름, 아이디, 비밀번호, 학부모 연락처
              </Content>
            ),
          },
          {
            text: <Content>• 클래스 : 등록된 클래스를 선택하여 기재 필요.</Content>,
          },
          {
            text: <Content>• 학년 : 학년 셀을 선택하여 기재 필요. ex) 중1, 초등6, 1학년 등으로 기재 불가</Content>,
          },
          {
            text: <Content>• 아이디 : 타 가맹점에서 사용중으로 중복 표기될 수 있으며, 재수정하여 기재 필요.</Content>,
          },
          {
            text: <Content>• 비밀번호 : 최소 8자 이상 영문 소문자, 숫자, 특수문자 등 으로 조합.</Content>,
          },
        ],
      },
    },
    lesson: {
      booklist: {
        id: 'lesson_booklist',
        title: {
          text: (
            <Title>
              <InfoIcon />
              교재 목록
            </Title>
          ),
        },
        paragraph: [
          {
            text: (
              <Content>
                • 학습에 필요하신 교재가 있으시다면{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  <a
                    href='https://pf.kakao.com/_VvCxkxj/chat'
                    target='_blank'
                    rel='noreferrer'
                    style={{ color: theme.palette.card_blue.main, textDecoration: 'underline' }}
                  >
                    카카오톡 채널
                  </a>
                </Box>
                을 통해 무료 공유 가능합니다.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                • 공유받은 교재와{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  <Link
                    id='card-link-to-create-book'
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    to='/lesson/bookmaking'
                  >
                    교재 생성
                  </Link>{' '}
                </Box>
                메뉴를 통해서 만들어진 교재 목록입니다.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                • 생성된 교재는{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  <Link
                    id='card-link-to-deploy-book'
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    to='/lesson/bookdistribution'
                  >
                    교재 배포
                  </Link>{' '}
                </Box>
                를 통해서 학생에게 스케쥴 설정해주세요.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                •{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  폴더를 생성
                </Box>
                하여 분류별로 교재를 관리할 수 있습니다.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                • 교재를 선택하시면{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  배포된 인원을 확인
                </Box>
                할 수 있으며,{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  편집을 통해 컨텐츠 수정
                </Box>
                도 가능합니다.
              </Content>
            ),
          },
        ],
      },
    },
    test: {
      testlist: {
        id: 'lesson_testlist',
        title: {
          text: (
            <Title>
              <InfoIcon />
              평가 목록
            </Title>
          ),
        },
        paragraph: [
          {
            text: (
              <Content>
                •{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  <Link
                    id='card-link-to-testmaking'
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    to='/test/testmaking'
                  >
                    평가 생성
                  </Link>{' '}
                </Box>
                메뉴를 통해서 만들어진 평가 목록입니다.
              </Content>
            ),
          },
          {
            text: <Content>• 폴더를 생성하여 분류별로 평가를 관리할 수 있습니다.</Content>,
          },
          {
            text: <Content>• 제출 현황은 출제된 학생의 제출 여부를 실시간으로 확인할 수 있습니다.</Content>,
          },
          {
            text: (
              <Content>
                • 상세조회를 통해,{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  평가 및 인원 정보를 확인
                </Box>
                하세요.
              </Content>
            ),
          },
        ],
      },
    },
    setting: {
      root: {
        id: 'setting_noticement',
        title: {
          text: (
            <Title>
              <InfoIcon />
              공지사항
            </Title>
          ),
        },
        paragraph: [
          {
            text: (
              <Content>
                • 관리자가 업로드한{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  공지사항은 학습앱에서도 조회
                </Box>{' '}
                가능합니다.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                • 학생에게 전달하고자 하는 내용이 있다면,{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  기록하여 소통
                </Box>
                해보세요.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                •{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  본사에서만 안내되는 업데이트 소식
                </Box>
                도 별도로 안내해드릴게요.
              </Content>
            ),
          },
        ],
      },
      noticement: {
        id: 'setting_noticement',
        title: {
          text: (
            <Title>
              <InfoIcon />
              공지사항
            </Title>
          ),
        },
        paragraph: [
          {
            text: (
              <Content>
                • 관리자가 업로드한{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  공지사항은 학습앱에서도 조회
                </Box>{' '}
                가능합니다.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                • 학생에게 전달하고자 하는 내용이 있다면,{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  기록하여 소통
                </Box>
                해보세요.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                •{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  본사에서만 안내되는 업데이트 소식
                </Box>
                도 별도로 안내해드릴게요.
              </Content>
            ),
          },
        ],
      },
      paymentview: {
        id: 'setting_paymentview',
        title: {
          text: (
            <Title>
              <InfoIcon />
              이용료 조회
            </Title>
          ),
        },
        paragraph: [
          {
            text: (
              <Content>
                • 이용료 산정 기준은{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  매월 1일 ~ 말일 기준
                </Box>
                이며,
              </Content>
            ),
          },
          {
            text: (
              <Content>
                •{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  3일 이상 학습 로그인 이력
                </Box>
                이 있거나,
              </Content>
            ),
          },
          {
            text: (
              <Content>
                • 유형별 학습과 관계 없이,{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  학습량이 {'"총 5회 이상"'}인 경우
                </Box>{' '}
                당월 학습량으로 간주됩니다.
              </Content>
            ),
          },
          {
            text: (
              <Content>
                • 재원생에 등록된 학생이 있더라도{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  상위 학습처리 기준이 넘지 않으면
                </Box>{' '}
                초과분은 발생하지 않습니다.
              </Content>
            ),
          },
          {
            text: (
              <Content sx={{ pl: '24px' }}>
                (사용량 관련 문의는 고객센터 및 담당자를 통해 문의해주셔도 좋습니다.){' '}
              </Content>
            ),
          },
        ],
      },
      admininfo: {
        id: 'setting_admininfo',
        title: {
          text: (
            <Title>
              <InfoIcon />
              로고 설정
            </Title>
          ),
        },
        paragraph: [
          {
            text: <Content>• 로고를 직접 등록해주세요.</Content>,
          },
          {
            text: (
              <Content>
                • 등록된 로고는{' '}
                <Box component={'span'} sx={{ color: theme.palette.card_blue.main }}>
                  교재, 문제은행, 레포트 출력 시 출력물에 자동 삽입
                </Box>
                됩니다.
              </Content>
            ),
          },
          {
            text: <Content>• 학생모드 중 어휘 퍼즐에도 해당 로고가 삽입됩니다.</Content>,
          },
        ],
      },
    },
  },
});
