import { SetterOrUpdater, atom } from 'recoil';
import { fetchGetApi } from 'utils/api';

export const userState = atom<UserType>({
  key: 'userState',
  default: {
    center_type: '',
    customer_id: 0,
    center_name: '',
    id: 0,
    logo: '',
    name: '',
    profile: '',
    profile_type: 'default',
    product: '',
    type: 11,
    class: [],
    pass: null,
    postdate: '',
    show_tutorial: {
      advanced: false,
      memorize: false,
      basic: false,
      speak: false,
    },
    default_profile: '',
    study_target: null,
    is_promotion: 0,
    startdate: '',
    enddate: '',
    personal_product: '',
    product_name: '',
    isJoinEscape: null,
    recomm_code: 0,
    code: '',
    scholarshipBenefit: false,
    scholarshipStudyCnt: 0,
    grade: '',
    loginid: '',
    isMainProfile: 'N',
    intend_product: null,
    printCount: 0,
    studyAlim: 'N',
    speak_study_level: null,
  },
});

interface UserStateSetter {
  setUserStateData: SetterOrUpdater<UserType>;
}

export const resetUserState = ({ setUserStateData }: UserStateSetter) => {
  setUserStateData({
    center_type: '',
    customer_id: 0,
    center_name: '',
    id: 0,
    logo: '',
    name: '',
    profile: '',
    profile_type: 'default',
    product: '',
    type: 11,
    class: [],
    pass: false,
    postdate: '',
    show_tutorial: {
      memorize: false,
      basic: false,
      advanced: false,
      speak: false,
    },
    default_profile: '',
    study_target: null,
    is_promotion: 0,
    startdate: '',
    enddate: '',
    personal_product: '',
    product_name: '',
    isJoinEscape: null,
    recomm_code: 0,
    code: '',
    scholarshipBenefit: false,
    scholarshipStudyCnt: 0,
    grade: '',
    loginid: '',
    isMainProfile: 'N',
    intend_product: null,
    printCount: 0,
    studyAlim: 'N',
    speak_study_level: null,
  });
};

export const getPrintCount = async (userStateData: UserType, setUserStateData: SetterOrUpdater<UserType>) => {
  if (!userStateData) return;
  const res = await fetchGetApi(`/customers/${userStateData?.customer_id}/prints/count`);
  setUserStateData(prevState => ({ ...prevState, printCount: res.data }));
  return res.data;
};
