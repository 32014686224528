import { styled, Box } from '@mui/material';
export { Modal, Wrapper } from 'components/modal/ModalTestPrintSetting/ModalTestPrintSetting.Styles';

export const OriginalDeco = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  marginRight: '0.5rem',
  borderLeft: `1px dotted ${theme.palette.gray_1.main}`,
  borderBottom: `1px dotted ${theme.palette.gray_1.main}`,
  transform: 'translateY(-50%)',
  width: '0.65rem',
  height: '0.65rem',
}));

export const Wrap = styled(Box)(props => ({
  height: 'auto',
  borderRadius: '6px',
  overflow: 'hidden',
}));

export const Header = styled(Box)(props => ({
  width: '100%',
  height: '3.5rem',
  padding: '0 1rem',
  fontSize: '1.1rem',
  background: 'linear-gradient(#652ae9, #4620e9)',
  color: props.theme.palette.white.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '.sub-title': {
    fontSize: '0.875rem',
    marginLeft: '0.25rem',
  },
}));

export const HeaderIconWrap = styled(Box)(props => ({
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: props.theme.palette.white.main,
  color: props.theme.palette.purple.main,
  svg: {
    width: '1.7rem',
    height: '1.7rem',
    strokeWidth: 30,
    marginRight: '-1px',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Body = styled(Box)(props => ({
  width: '55rem',
  height: '30rem',
  backgroundColor: props.theme.palette.white.main,
  overflowY: 'auto',
  padding: '0 2rem 1rem 2rem',
}));

export const BookBox = styled(Box)({
  minHeight: '3.4rem',
  color: '#9e52ff',
  display: 'grid',
  gridTemplateColumns: '30px 1fr',
  alignItems: 'center',
  borderBottom: '1px solid #ececec',
});

export const UnitBox = styled(Box)({
  minHeight: '3.4rem',
  display: 'grid',
  color: '#b3b3b3',
  gridTemplateColumns: '30px 1fr',
  alignItems: 'center',
  borderBottom: '1px solid #ececec',
  '& > div': {
    display: 'flex',
  },
  '& > div:first-of-type': {
    justifyContent: 'center',
  },
  '& > div:last-of-type': {
    alignItems: 'center',
    '& > div': {
      marginRight: '0.35rem',
    },
    '& > svg': {
      cursor: 'pointer',
    },
  },
});

export const Deco = styled(OriginalDeco)({
  width: '0.75rem',
  height: '0.75rem',
  minHeight: '10px',
  minWidth: '10px',
});
