import { styled, Box, RadioGroup as MuiRadioGroup, FormControlLabel } from '@mui/material';

interface WrapperProps {
  length?: string;
}

export const Wrapper = styled(Box)<WrapperProps>(props => {
  const tax_invoie_style =
    props.length === '6'
      ? {
          '&:nth-of-type(4)': {
            gap: '0.5rem',
            '& > div': {
              '&:first-of-type': {
                width: '60%',
              },
              '&:nth-of-type(2)': {
                width: '20%',
                marginTop: '2.25rem',
              },
              '&:nth-of-type(3)': {
                width: '20%',
                marginTop: '1.65rem',
                button: {
                  width: '100%',
                  height: '2.6375rem',
                },
              },
            },
          },
        }
      : {};
  return {
    height: props.theme.breakpoints.up('md') ? 'calc(100% - 3rem)' : 'calc(100% - 1rem)',
    overflow: 'auto',
    paddingTop: '0.8rem',
    '& > div:not(.MuiCard-root)': {
      paddingBottom: '1.5rem',
      '&:first-of-type': {
        paddingBottom: '0.8rem',
      },
      '&:nth-of-type(2)': {
        paddingTop: '0',
        [props.theme.breakpoints.up('xxl')]: {
          width: '70%',
          maxWidth: '850px',
        },
        [props.theme.breakpoints.between('xl', 'xxl')]: {
          width: '80%',
          maxWidth: '850px',
        },
        [props.theme.breakpoints.between('md', 'xl')]: {
          width: '80%',
          maxWidth: '625px',
        },
        [props.theme.breakpoints.down('md')]: {
          width: '100%',
          maxWidth: '520px',
        },
        '& > div': {
          gap: '1rem',

          ...tax_invoie_style,
          '&:nth-of-type(6)': {
            marginTop: '-2rem',
          },
        },
        label: {
          margin: '0',
        },
      },
      '& > button': {
        margin: '0',
        width: '9rem',
      },
    },
  };
});

export const RadioGroup = styled(MuiRadioGroup)(props => ({
  height: 'auto',
  margin: '0 auto',
  [props.theme.breakpoints.up('xxl')]: {
    width: '70%',
    maxWidth: '850px',
  },
  [props.theme.breakpoints.between('xl', 'xxl')]: {
    width: '80%',
    maxWidth: '850px',
  },
  [props.theme.breakpoints.between('md', 'xl')]: {
    width: '80%',
    maxWidth: '625px',
  },
  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    maxWidth: '520px',
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const RadioLabel = styled(FormControlLabel)({
  width: '50%',
  height: 'auto',
  marginLeft: '0',
  marginRight: '0',
  '& .MuiTypography-root': {
    fontSize: '1rem',
  },
  '& span': {
    paddingLeft: '0',
  },
});
