import { Box, IconButton, Tab, Tabs } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useMemo, useRef, useState } from 'react';
import ChangeInfo from './ChangeInfo';
import PaymentInfo from './Payment';
import { ExpenditureEvidenceContainer } from './ExpenditureEvidence/ExpenditureEvidence.Container';
import { fetchGetApi } from 'utils/api';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userState } from 'recoil/model/user';
import { deviceState } from 'recoil/common/device';

interface MainSetupProps {
  close: () => void;
}

export interface PaymentKindInfoType {
  paymentKind: string;
  accountBank?: string;
  accountName?: string;
  accountNumber?: string;
  paymentCompany?: string;
  paymentNumber?: string;
  unpaid?: string;
}
declare let window: any;

export default function MainSetup({ close }: MainSetupProps) {
  const tabValue = ['정보 수정', '이용료 납부', '지출증빙 정보'];
  const [top, setTop] = useState('0px');
  const [selectTabIndex, setSelectTabIndex] = useState<number>(0);
  const [isPaymentKindEmpty, setIsPaymentKindEmpty] = useState(false);
  const [paymentKindInfo, setPaymentKindInfo] = useState<PaymentKindInfoType>();
  const [userStateData] = useRecoilState(userState);
  const { customer_id } = userStateData;
  const [externalKeyboard, setExternalKeyboard] = useState<boolean>(false);
  const deviceStateData = useRecoilValue(deviceState);
  const { is_mobile, platform } = deviceStateData;
  const scrollableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getPaymentKind();
  }, []);

  useEffect(() => {
    if (is_mobile) {
      if (typeof window.ExternalKeyboard == 'function') window.ExternalKeyboard.checkOnce();
      if (window.ExternalKeyboard.isUsed) setExternalKeyboard(true);
    }
  }, [is_mobile]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectTabIndex(newValue);
  };

  const getPaymentKind = async () => {
    const res = await fetchGetApi(`/customers/${725}/payments/paymentkind`);
    setIsPaymentKindEmpty(res.result);
    if (res.result) {
      setPaymentKindInfo(res.data);
    }
  };

  const isCardPay = useMemo(() => {
    return paymentKindInfo && ['AC', 'CD'].includes(paymentKindInfo.paymentKind);
  }, [paymentKindInfo]);

  const setDetailEl = () => {
    if (selectTabIndex === 0) {
      return <ChangeInfo onFocus={onFocus} onBlur={onBlur} />;
    }

    if (selectTabIndex === 1) {
      return (
        <PaymentInfo
          paymentKindInfo={paymentKindInfo}
          isPaymentKindEmpty={isPaymentKindEmpty}
          isCardPay={isCardPay}
          getPaymentKind={getPaymentKind}
        />
      );
    }

    if (selectTabIndex === 2) {
      return <ExpenditureEvidenceContainer onFocus={onFocus} onBlur={onBlur} reference={scrollableRef} />;
    }
  };

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const id = e.target.id;
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({
        top: 0,
      });
    }
    if (!is_mobile || externalKeyboard) return false;
    if (platform == 'android') {
      let size = '0px';
      if (id === 'name') {
        size = '-19vh';
      } else if (id === 'password') {
        size = '-49vh';
      } else if (['brn', 'corporationName'].includes(id)) {
        size = '-16vh';
      } else if (['representativeTax', 'emailTax'].includes(id)) {
        size = '-30vh';
      } else if (['businessCondition', 'businessItem'].includes(id)) {
        size = '-44vh';
      } else if (id === 'detailAddress') {
        size = '-61vh';
      } else if (id === 'representativeCash') {
        size = '-30vh';
      } else if (id === 'emailCash') {
        size = '-43vh';
      } else if (id === 'phone') {
        size = '-57vh';
      }
      if (scrollableRef.current) {
        scrollableRef.current.scrollTo({
          top: 0,
        });
      }
      setTop(size);
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform == 'android') {
      setTop('0px');
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        paddingTop: '0.5rem',
        paddingX: '1rem',
        top,
      }}
    >
      <IconButton
        onClick={() => {
          setSelectTabIndex(0);
          close();
        }}
        sx={{
          position: 'absolute',
          zIndex: 1,
          right: '0.5rem',
          top: '0.5rem',
        }}
        aria-label='close'
      >
        <CloseIcon />
      </IconButton>
      <Tabs
        value={selectTabIndex}
        onChange={handleTabChange}
        aria-label='secondary tabs example'
        sx={{
          minHeight: '3rem',
          '.MuiTabs-scroller': {
            '.MuiTabs-flexContainer': {
              '.MuiButtonBase-root': {
                position: 'relative',
                top: '1px',
                fontSize: '1rem',
                padding: '0.25rem 0.75rem',
                minHeight: '3rem',
                letterSpacing: '-1px',
                '&.Mui-selected': {
                  color: 'rgb(27, 208, 91)',
                },
              },
            },
            '.MuiTabs-indicator': {
              backgroundColor: 'rgb(27, 208, 91)',
            },
          },
          borderBottom: '1px solid #e5e5e5',
          marginBottom: '0.5rem',
        }}
      >
        {tabValue.map((item, idx) => (
          <Tab
            value={idx}
            label={item}
            key={idx}
            sx={{
              color: '#dbdbdb',
              '&.Mui-selected': {
                color: 'rgb(27, 208, 91)',
              },
            }}
          />
        ))}
      </Tabs>
      {setDetailEl()}
    </Box>
  );
}
