import React from 'react';
import { styled, Box, Modal as MuiModal, Radio, Snackbar, Alert } from '@mui/material';
import { display, height, maxWidth } from '@mui/system';

interface StyledToastBarProps {
  backgroundColor?: string | undefined;
}
export const StyledToastBar = styled(Alert)<StyledToastBarProps>(({ backgroundColor }) => {
  return {
    display: 'flex',
    zIndex: 5000,
    width: 'auto',
    height: '2.7rem',
    backgroundColor: backgroundColor ? backgroundColor : '#404040', // 기본값을 hex 형식으로 설정
    borderRadius: '4rem',
    color: 'white',
    padding: '0.25rem 1rem',
    '& > .MuiAlert-message': {
      padding: '0.5rem 0',
    },

    '@media (min-width: 1266px) and (max-width: 1905px)': {
      transform: 'scale(1.4) !important',
    },

    '@media (min-width: 962px) and (max-width: 1265px)': {
      transform: 'scale(1.3) !important',
    },

    '@media (min-width: 770px) and (max-width: 961px)': {
      transform: 'scale(1.3) !important',
    },

    '@media (max-width: 769px)': {
      transform: 'scale(1.2) !important',
    },
  };
});
