import React, { useState, useEffect, useMemo, useCallback, useContext, useRef } from 'react';
import { styled, Box } from '@mui/material';
import PocketNoteTableHead from './PocketNoteTableHead';
import PocketNoteTableBody from './PocketNoteTableBody';
import ModalFolderEdit from '../../components/modal/ModalFolderEdit';
import ModalSelect from '../../components/modal/ModalSelect';
import Button from '../../components/button/Button';
import { loadingState, setPercent } from '../../recoil/common/loading';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { ImFolderOpen } from 'react-icons/im';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { ModalContext } from '../../provider/ModalProvider';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { FaTimes } from 'react-icons/fa';
import finger from '../../assets/images/pocketnote/finger.png';
import { deviceState } from '../../recoil/common/device';
import { fetchPostApi } from '../../utils/api';
import { userState } from '../../recoil/model/user';
import { moduleSettingState } from '../../recoil/model/module_setting';
import { learningState } from '../../recoil/model/learning';
import { useNavigate } from 'react-router';
import { routesState } from '../../recoil/common/routes';
import { BASE_URL } from '../../utils/constants';
import dayjs from 'dayjs';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { toast_contents } from 'utils/modal_contents';

const Table = styled(Box)(props => ({
  borderLeft: '1px solid #ededef',
  borderRight: '1px solid #ededef',
  borderBottom: `2px solid ${props.theme.palette.purple.main}`,
  height: 'calc(100% - 7rem - 3px)',
  marginBottom: '0.625rem',
  position: 'relative',
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
    borderBottom: `1px solid ${props.theme.palette.purple.main}`,
  },
}));

const ButtonWrap = styled(Box)(props => ({
  height: '3.25rem',
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '5.85fr 1px 5.5fr',
  alignItems: 'center',
  '& > div': {
    height: '100%',
    '& > button': {
      height: '100%',
      '&.Mui-disabled': {
        backgroundColor: `${props.theme.palette.gray_6.main} !important`,
      },
    },
  },
}));

const DeleteWordButton = styled(Button)({
  borderRadius: '0.5rem',
  minHeight: '0',
  height: '100%',
  backgroundColor: '#fff0ee',
  color: '#ff5726',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#fff0ee',
    color: '#ff5726',
  },
  svg: {
    color: '#ff5726',
    marginRight: '0.5rem',
  },
  '&.Mui-disabled': {
    svg: {
      color: '#fff',
    },
  },
});
const AddFolerButton = styled(Button)({
  borderRadius: '0.5rem',
  marginLeft: '0.5rem',
  backgroundColor: '#fff5ce',
  boxShadow: 'none',
  color: '#a77709',
  '&:hover': {
    backgroundColor: '#fff5ce',
    color: '#a77709',
  },
  svg: {
    color: '#ffe230',
    marginRight: '0.5rem',
  },
  '&.Mui-disabled': {
    svg: {
      color: '#fff',
    },
  },
});

const SelectWordCountButton = styled(Button)(props => ({
  cursor: 'default',
  minWidth: '0',
  borderRadius: '0.5rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  color: props.theme.palette.purple.main,
  backgroundColor: props.theme.palette.light_purple_3.main,
  '&:hover': {
    backgroundColor: props.theme.palette.light_purple_3.main,
  },
  '.count': {
    fontWeight: '600',
    color: ' #ff6f00',
  },
}));
const StudyMemorizeButton = styled(Button)({
  minWidth: '0',
  borderRadius: '1.5rem',
  backgroundColor: '#ff9423',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#ff9423',
    color: '#fff',
  },
  '&.Mui-disabled': {
    color: '#fff',
    backgroundColor: 'rgb(232, 232, 232)',
  },
});
const StudyCheckupButton = styled(Button)({
  minWidth: '0',
  borderRadius: '1.5rem',
  backgroundColor: '#1bd05b',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#1bd05b',
    color: '#fff',
  },
  '&.Mui-disabled': {
    color: '#fff',
    backgroundColor: 'rgb(232, 232, 232)',
  },
});

const Line = styled(Box)({
  height: '65% !important',
  backgroundColor: '#ededef',
  '@media(max-width: 1023px)': {
    backgroundColor: '#f0f0f0',
  },
});

const BookMark = styled(Box)<{ direction: 'left' | 'right' }>(props => ({
  height: 'calc(100% - 3.875rem)',
  position: 'absolute',
  top: '3.65rem',
  left: props.direction === 'left' ? '3.7rem' : 'calc((100% - 3.7rem) / 2 + 3.85rem)',
  transition: 'left .3s cubic-bezier(.25,.8,.5,1)',
  width: 'calc((100% - 4.4rem - 2px) / 2)',
  display: 'grid',
  gridTemplateColumns: '1fr 0.07fr',
  borderRadius: '1.5rem',
  overflow: 'hidden',
  '& > div': {
    '&:first-of-type': {
      cursor: 'grab',
      backgroundColor: '#feecb7',
      // boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px',
      borderBottomRightRadius: '1.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '100%',
      padding: '0 2rem',
      svg: {
        cursor: 'pointer',
        color: props.theme.palette.purple.main,
      },
      '& > div': {
        width: 'calc(100% - 64px)',
        paddingTop: '11.1vh',
        '&  > div': {
          '&:first-of-type': {
            height: '10vh',
            backgroundImage: `url(${finger})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: '50%',
          },
          '&:last-of-type': {
            padding: '2vh 0',
            color: '#d4a952',
            textAlign: 'center',
            fontSize: '1.2rem',
          },
        },
      },
    },
    '&:last-of-type': {
      cursor: 'pointer',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '& > div': {
        '&:first-of-type': {
          paddingTop: '2vh',
          display: 'flex',
          justifyContent: 'center',
          height: '30%',
          backgroundColor: '#feecb7',
          borderBottomRightRadius: '1.5rem',
          // boxShadow: 'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px',
          svg: {
            color: '#d8ab74',
          },
        },
        '&:last-of-type': {
          height: '70%',
        },
      },
    },
  },
}));

export interface PocketNoteTableFieldType {
  isNote?: boolean;
  label: string;
}

export interface PocketNoteTableItemType {
  [key: string]: any;
}

export interface PocketNoteTableProps {
  tableFields: PocketNoteTableFieldType[];
  table_items: PocketNoteTableItemType[];
  isSelectedNote: boolean;
  bookMark: boolean;
  onChangeFolderName: (folderName: string, id?: number) => Promise<void>;
  deleteNote: (id: number) => Promise<void>;
  deleteWords: (ids: number[]) => Promise<void>;
  selectNote: (noteId: number) => void;
  getNoteList: (sortKey?: string, sortValue?: string) => Promise<void>;
  changeBookMark: (value: boolean) => void;
}

function PocketNoteTable({
  tableFields,
  table_items,
  isSelectedNote,
  bookMark,
  onChangeFolderName,
  deleteNote,
  deleteWords,
  selectNote,
  getNoteList,
  changeBookMark,
}: PocketNoteTableProps) {
  const { modal_confirm, modal_alert } = useContext(ModalContext);
  const navigate = useNavigate();
  const [routesStateData, setRoutesStateData] = useRecoilState<RoutesType>(routesState);
  const { root_path } = routesStateData;
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const [moduleSettingStateData, setModuleSettingStateData] = useRecoilState<ModuleSettings>(moduleSettingState);
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const setLoadingStateData = useSetRecoilState<LoadingType>(loadingState);
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { screen_width, screen_height, is_mobile } = deviceStateData;
  const [tableQuery, setTableQuery] = useState<{ target: string; sort: string }>({
    target: '',
    sort: '',
  });
  const [tableItems, setTableItems] = useState<PocketNoteTableItemType[]>([]);
  const [modalFolder, setModalFolder] = useState<ModalFolderDataType>({
    visible: false,
    data: {
      type: 'create',
    },
  });
  const [modalSelectContents, setModalSelectContents] = useState<ModalSelectDataType>({
    visible: false,
    data: {
      modal_id: '',
      title: '',
      subtitle: '',
      type: '',
      contents: [],
    },
    currentInput: [],
  });
  const setToastBar = useSetRecoilState(toastBarState);
  const [checkedWords, setCheckedWords] = useState<any[]>([]);
  const [bookMarkDirection, setBookMarkDirection] = useState<'left' | 'right'>('left');
  const { center_type } = userStateData;

  const checkWordButtonDisabled = useMemo(() => {
    if (checkedWords.length) {
      return false;
    } else {
      return true;
    }
  }, [checkedWords]);

  const AddFolerButtonDisabled = useMemo(() => {
    return isSelectedNote ? true : false;
  }, [isSelectedNote]);

  const clickAddFoldrButton = useCallback(() => {
    setModalFolder({
      visible: true,
      data: {
        type: 'create',
      },
    });
  }, []);

  const onCloseModalFolderEdit = useCallback(() => {
    setModalFolder(prev => ({
      ...prev,
      visible: false,
    }));
  }, []);

  const onCloseModalSelect = useCallback((tmp?: boolean) => {
    if (tmp) {
      setModalSelectContents(prev => ({ ...prev, visible: false }));
    } else {
      setModalSelectContents({
        visible: false,
        data: {
          modal_id: '',
          title: '',
          subtitle: '',
          type: '',
          contents: [],
        },
        currentInput: [],
      });
    }
  }, []);

  const onSuccessModalSelect = useCallback((value: ModalSelectContentsType[]) => {
    if (value.length && value[0]) {
      if (value[0].name == '수정') {
        // 수정
        const item = value[0].item;
        if (item) {
          setModalFolder({
            visible: true,
            data: {
              type: 'modify',
              origin: item.name,
              id: item,
            },
          });
        }
      } else {
        // 삭제
        const id = value[0].item;
        modal_confirm.openModalConfirm('normal', 'delete_note', () => deleteNote(id));
      }
    }
  }, []);

  const onClickSettingBox = useCallback((e: any, item: any) => {
    e.stopPropagation();
    setModalSelectContents({
      visible: true,
      data: {
        modal_id: '',
        title: '폴더 설정',
        subtitle: '',
        type: 'radio',
        contents: [
          { id: 'modify', name: '수정', item },
          { id: 'delete', name: '삭제', item },
        ],
      },
      currentInput: [item],
    });
  }, []);

  const onClickAllCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (bookMark) {
        e.preventDefault();
        //* B2C toast로 분기처리
        if (center_type == 'A' || center_type == 'B') {
          modal_alert.openModalAlert('pocketnote_bookmark_check');
        } else {
          openToastBar({ setToastBar }, toast_contents.error.pocketnote_bookmark_check, 'red', 2000);
        }

        return;
      }
      const checked = e.target.checked;
      if (checked) {
        const new_checked_words: any[] = [];
        tableItems.forEach(tableItem => {
          if (!tableItem.isFolder) {
            new_checked_words.push(tableItem);
          }
        });
        setCheckedWords(new_checked_words);
      } else {
        setCheckedWords([]);
      }
    },
    [tableItems, bookMark],
  );

  const onRowClicked = useCallback(
    (item: any) => {
      if (bookMark) {
        //* B2C toast로 분기처리
        if (center_type == 'A' || center_type == 'B') {
          modal_alert.openModalAlert('pocketnote_bookmark_check');
        } else {
          openToastBar({ setToastBar }, toast_contents.error.pocketnote_bookmark_check, 'red', 2000);
        }
        return;
      }
      if (isSelectedNote) {
        if (item.isFolder) {
          // 뒤로가기 클릭 시,
          setPercent(setLoadingStateData, 1);
          selectNote(0);
          setCheckedWords([]);
        } else {
          // 단어 클릭 시,
          setCheckedWords(prev => {
            const check_word_idx = prev.findIndex(word => word._id == item.tableItem._id);
            if (check_word_idx != -1) {
              prev.splice(check_word_idx, 1);
            } else {
              prev.push({ ...item.tableItem });
            }
            return [...prev];
          });
        }
      } else {
        // 노트 클릭 시,
        selectNote(item.id);
      }
    },
    [isSelectedNote, selectNote, tableItems, bookMark],
  );

  const checked_ids = useMemo(() => {
    return checkedWords.map(word => word._id);
  }, [checkedWords]);

  const onClickWordsDeleteButton = useCallback(() => {
    const callback_fn = () => {
      setCheckedWords([]);
      deleteWords(checked_ids);
    };
    modal_confirm.openModalConfirm('normal', 'delete_contents', callback_fn, undefined, '단어는');
  }, [deleteWords, checked_ids]);

  useEffect(() => {
    setTableItems([...table_items]);
    setPercent(setLoadingStateData, 100);
  }, [table_items]);

  const allCheck = useMemo(() => {
    return checkedWords.length == tableItems.length - 1 && tableItems.length > 1;
  }, [checkedWords, tableItems]);

  const sortingMethod = useCallback(
    (sortKey: string) => {
      let sort_value: string;
      if (sortKey == tableQuery.target) {
        sort_value = tableQuery.sort == 'desc' ? 'asc' : 'desc';
      } else {
        sort_value = 'asc';
      }
      if (isSelectedNote) {
        const back = tableItems.shift();
        const new_table_items = [
          ...tableItems.sort((a, b) => {
            if (a[sortKey] < b[sortKey]) return sort_value == 'asc' ? -1 : 1;
            else if (a[sortKey] > b[sortKey]) return sort_value == 'asc' ? 1 : -1;
            return 0;
          }),
        ];
        new_table_items.unshift({ ...back });
        setTableItems(new_table_items);
        setTableQuery({ target: sortKey, sort: sort_value });
      } else {
        getNoteList(sortKey, sort_value);
        setTableQuery({ target: sortKey, sort: sort_value });
      }
    },
    [isSelectedNote, tableItems, tableQuery],
  );

  const prevX = useRef(0);
  const moveBookMarkDirection = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    try {
      if (e.buttons === 1) {
        const xDir = prevX.current <= e.pageX ? 'right' : 'left';
        prevX.current = e.pageX;

        setBookMarkDirection(xDir);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const startX = useRef(0);
  const touchStart = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    try {
      startX.current = e.touches[0].pageX;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const touchEnd = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      const move_x =
        startX.current > e.changedTouches[0].pageX
          ? startX.current - e.changedTouches[0].pageX
          : e.changedTouches[0].pageX - startX.current;

      if (move_x > screen_width / 10) {
        let direction: 'left' | 'right';
        if (startX.current > e.changedTouches[0].pageX) {
          direction = 'left';
        } else {
          direction = 'right';
        }
        setBookMarkDirection(direction);
      }
    },
    [screen_width],
  );

  const clickBookMarkDirection = useCallback((direction: 'left' | 'right') => {
    setBookMarkDirection(direction);
  }, []);

  const onClickMemorize = async () => {
    if (['S003Y', 'S003DY', 'S003TY'].includes(userStateData.product)) {
      // !toast 추후 확인 예정
      modal_alert.openModalAlert(
        userStateData.type == 11 ? 'personal_product_student_alert' : 'personal_product_alert',
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        true,
      );
      return false;
    }
    setPercent(setLoadingStateData, 33, '포켓노트 암기학습이 시작됩니다.');
    const spells = checkedWords.map(word => word.spell);

    const examples = await fetchPostApi(`/etc/examples`, { words: spells });
    if (examples.result) {
      setPercent(setLoadingStateData, 67);

      const contents = checkedWords.map(word => {
        const spell = word.spell;
        const content = examples.data[spell];

        return {
          ...word,
          ...content,
        };
      });

      const memorize_mod: ModType[] = [];

      let modules = '';
      if (moduleSettingStateData.memorize_normal_stage.spell == 1) modules += '4';
      if (moduleSettingStateData.memorize_normal_stage.mean == 1) modules += '3';
      if (moduleSettingStateData.memorize_normal_stage.image == 1) modules += '5';
      if (moduleSettingStateData.memorize_normal_stage.dictionary == 1) modules += '6';
      for (let i = 0; i < modules.length; i++) {
        memorize_mod.push({
          module: +modules[i],
          seq: i,
        });
      }
      setLearningStateData(prevState => ({
        ...prevState,
        status: true,
        starttime: dayjs().format(),
        studytime: 0,
        learning_type: 2,
        current_page: 0,
        current_step: 0,
        modules: modules,
        record_id: 0,
        mod: memorize_mod,
        schedule_id: null,
        schedule_name: '',
        book_type: 'pocketnote',
        tab_name: '',
        unit_id: 0,
        unit_name: '',
        contents: contents,
        resultsheet: [],
        current_module_settings: moduleSettingStateData,
        first_alert: true,
      }));

      navigate(`${BASE_URL}/${root_path}/learning`);
    } else {
      setPercent(setLoadingStateData, 67);

      const memorize_mod: ModType[] = [];

      let modules = '';
      if (moduleSettingStateData.memorize_new_stage.spell == 1) modules += '4';
      if (moduleSettingStateData.memorize_new_stage.mean == 1) modules += '3';
      if (moduleSettingStateData.memorize_new_stage.image == 1) modules += '5';
      if (moduleSettingStateData.memorize_new_stage.dictionary == 1) modules += '6';
      for (let i = 0; i < modules.length; i++) {
        memorize_mod.push({
          module: +modules[i],
          seq: i,
        });
      }
      setLearningStateData(prevState => ({
        ...prevState,
        status: true,
        starttime: dayjs().format(),
        studytime: 0,
        learning_type: 2,
        current_page: 0,
        current_step: 0,
        modules: modules,
        record_id: 0,
        mod: memorize_mod,
        schedule_id: null,
        schedule_name: '',
        book_type: 'pocketnote',
        tab_name: '',
        unit_id: 0,
        unit_name: '',
        contents: checkedWords,
        resultsheet: [],
        current_module_settings: moduleSettingStateData,
        first_alert: true,
      }));

      navigate(`${BASE_URL}/${root_path}/learning`);
    }
  };

  const onClickCheckup = () => {
    // modal_alert.openModalAlert('ready_service');
    openToastBar({ setToastBar }, toast_contents.info.ready_service, '', 2000);
  };

  return (
    <>
      <Table>
        <PocketNoteTableHead
          tableFields={tableFields}
          checkedWrodListCount={checkedWords.length}
          wordListLength={tableItems.length}
          onClickAllCheckbox={onClickAllCheckbox}
          tableQuery={tableQuery}
          sortingMethod={sortingMethod}
        />
        <PocketNoteTableBody
          tableItems={tableItems}
          isSelectedNote={isSelectedNote}
          onRowClicked={onRowClicked}
          onClickSettingBox={onClickSettingBox}
          checked_ids={checked_ids}
          allCheck={allCheck}
        />
        {bookMark ? (
          <BookMark
            direction={bookMarkDirection}
            onMouseMove={(e: React.MouseEvent<HTMLDivElement>) => moveBookMarkDirection(e)}
            onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => touchStart(e)}
            onTouchEnd={(e: React.TouchEvent<HTMLDivElement>) => touchEnd(e)}
          >
            <Box>
              <HiChevronLeft size={32} onClick={() => clickBookMarkDirection('left')} />
              <Box>
                <Box></Box>
                <Box>좌우 스크롤 하여 암기하세요.</Box>
              </Box>
              <HiChevronRight size={32} onClick={() => clickBookMarkDirection('right')} />
            </Box>
            <Box>
              <Box>
                <FaTimes size={16} onClick={() => changeBookMark(false)} />
              </Box>
              <Box></Box>
            </Box>
          </BookMark>
        ) : null}
      </Table>
      <ButtonWrap>
        <Box>
          <DeleteWordButton variant='contained' disabled={checkWordButtonDisabled} onClick={onClickWordsDeleteButton}>
            <RiDeleteBin6Fill size={16} />
            항목 삭제
          </DeleteWordButton>
          <AddFolerButton variant='contained' disabled={AddFolerButtonDisabled} onClick={clickAddFoldrButton}>
            <ImFolderOpen size={16} />
            폴더 추가
          </AddFolerButton>
        </Box>
        <Line />
        <Box
          sx={{
            paddingLeft: '1rem',
            display: 'grid',
            justifyContent: 'flex-end',
            gridTemplateColumns: '15rem 19.5rem',
            gap: '1rem',
            button: { fontSize: '1.025rem' },
          }}
        >
          <SelectWordCountButton>
            선택 문항 수 &nbsp;
            <Box className='count'>{checkedWords.length}</Box>개
          </SelectWordCountButton>
          <StudyMemorizeButton onClick={onClickMemorize} disabled={checkWordButtonDisabled}>
            복습 학습
          </StudyMemorizeButton>
          {/* <StudyCheckupButton onClick={onClickCheckup} disabled={checkWordButtonDisabled}>
            체크업 학습
          </StudyCheckupButton> */}
        </Box>
      </ButtonWrap>
      <ModalFolderEdit {...modalFolder} onChange={onChangeFolderName} onClick={onCloseModalFolderEdit} />
      <ModalSelect {...modalSelectContents} onClick={onCloseModalSelect} onChange={onSuccessModalSelect} />
    </>
  );
}

export default React.memo(PocketNoteTable);
