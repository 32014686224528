import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { TestPrintUI } from './TestPrint.Presenter';
import { useReactToPrint } from 'react-to-print';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userState, getPrintCount } from 'recoil/model/user';

import { fetchPostApi, fetchPutApi } from 'utils/api';
import {
  closeModalTestPrintSetting,
  modalTestPrintSettingData,
  openModalTestPrintSetting,
} from 'recoil/common/modalTestPrintSetting';
import { ModalContext } from 'provider/ModalProvider';

interface TestPrintContainerProps {
  testSetting: TestPrintSettingType;
  testPrintType: number;
  testWordList: TestPrintWordType[];
  example: TestExampleType;
  onClickBackButton: () => void;
}

export const TestPrintContainer = React.memo(function PrintContainer({
  testSetting,
  testWordList,
  testPrintType,
  example,
  onClickBackButton,
}: TestPrintContainerProps) {
  const { modal_confirm, modal_alert } = useContext(ModalContext);
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);

  const [modalTestPrintSetting, setModalTestPrintSetting] = useRecoilState(modalTestPrintSettingData);

  const [originalTestWordList, setOriginalTestWordList] = useState<TestPrintWordType[]>(testWordList);
  const [selectWordList, setSelectWordList] = useState<TestPrintWordType[]>([]);
  const [title, setTitle] = useState('WORD TEST');
  const [printSetting, setPrintSetting] = useState<PrintSettingType>({
    type: testPrintType,
    sort: 0,
    fontsize: 1,
    line: 1,
    column: 1,
  });
  const [testPrintSetting, setTestPrintSetting] = useState<TestPrintSettingType>(testSetting);
  const [printInfo, setPrintInfo] = useState<PrintInfoType>({
    logo: true,
    pagenum: true,
    cname: true,
    answer: false,
  });
  const printRef = useRef<any>(null);

  useEffect(() => {
    if (
      printSetting.type == 0 &&
      (selectWordList.length === 0 ||
        !selectWordList.every(item => originalTestWordList.some(originalItem => originalItem.id === item.id)))
    ) {
      const test_count =
        testPrintSetting.mtos.multi +
        testPrintSetting.mtos.short +
        testPrintSetting.stom.multi +
        testPrintSetting.stom.short;
      const copyTestWordList = JSON.parse(JSON.stringify(originalTestWordList)) as TestPrintWordType[];

      for (let i = copyTestWordList.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [copyTestWordList[i], copyTestWordList[j]] = [copyTestWordList[j], copyTestWordList[i]];
      }
      const select_word_list = [...copyTestWordList.slice(0, test_count)].sort((a, b) =>
        a.grade.localeCompare(b.grade),
      );

      let currentIndex = 0;

      ['stom', 'mtos'].forEach((category: string) => {
        ['multi', 'short'].forEach((type: string, index: number) => {
          const count = testPrintSetting[category as 'stom' | 'mtos'][type as 'multi' | 'short'];
          for (let i = 0; i < count; i++) {
            if (currentIndex < select_word_list.length) {
              select_word_list[currentIndex].type = `${category}${index + 1}`;
              if (type == 'multi') {
                const spell = select_word_list[currentIndex].spell;
                const mean = select_word_list[currentIndex].mean;
                const book_id = select_word_list[currentIndex].book_id;

                const examples: string[] = [];
                let limit = 0;

                while (examples.length < 3 && limit < 1000) {
                  const randIdx = Math.floor(Math.random() * example[book_id].length);
                  if (spell == example[book_id][randIdx].spell) continue;
                  if (category == 'stom') {
                    examples.push(example[book_id][randIdx].mean);
                  } else {
                    examples.push(example[book_id][randIdx].spell);
                  }
                  limit++;
                }
                select_word_list[currentIndex].example = [category == 'stom' ? mean : spell, ...examples];
              }
              currentIndex++;
            }
          }
        });
      });
      setSelectWordList(select_word_list);
    }
  }, [originalTestWordList, testPrintSetting]);

  const onClickWordPrint = useCallback(async () => {
    if (userStateData.product == 'F' || (userStateData.isJoinEscape && userStateData.enddate != '')) {
      const printCount = await getPrintCount(userStateData, setUserStateData);
      if (printCount < 1) {
        modal_alert.openModalAlert('free_print_exhausted');
      } else {
        if (printRef.current) {
          handlePrint();
        }
      }
    } else {
      if (printRef.current) {
        handlePrint();
      }
    }
  }, [testWordList, title]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    removeAfterPrint: true,
    documentTitle: title,
    onAfterPrint: async () => {
      // 여기서 프린트 횟수 차감하는 api 요청
      try {
        if (userStateData.product == 'F' || (userStateData.isJoinEscape && userStateData.enddate != '')) {
          const minus_print_count_uri = `/customers/${userStateData.customer_id}/prints`;
          const res = await fetchPostApi(minus_print_count_uri, {});
          if (res.result) {
            let rest_print_count = await getPrintCount(userStateData, setUserStateData);
            if (rest_print_count == 0) {
              rest_print_count = '0';
            }
            modal_alert.openModalAlert('rest_print_count', undefined, rest_print_count);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const onChangePrintSetting = useCallback(
    (key: string, value: number) => {
      if (printSetting[key] != value) {
        if (key == 'column' && value == 2) {
          if (printSetting.type == 1) {
            modal_alert.openModalAlert('use_three_print');
          } else {
            modal_alert.openModalAlert('do_not_three_print');
            return;
          }
        }
        if (key == 'type' && value == 0 && printSetting.column == 2) {
          setPrintSetting({
            ...printSetting,
            column: 1,
            [key]: value,
          });
        } else {
          setPrintSetting({
            ...printSetting,
            [key]: value,
          });
        }
      }
    },
    [printSetting],
  );

  const onChangePrintInfo = useCallback(
    (key: string, value: boolean) => {
      console.log(printInfo, key, value);
      if (printInfo[key] != value) {
        setPrintInfo({
          ...printInfo,
          [key]: value,
        });
      }
    },
    [printInfo],
  );

  const onChangeTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 20) {
      setTitle(e.target.value);
    }
  }, []);

  const onClickPrintType = useCallback(() => {
    if (printSetting.type == 1) {
      openModalTestPrintSetting(
        { setModalTestPrintSetting },
        originalTestWordList.length,
        (testSetting: TestPrintSettingType) => {
          setTestPrintSetting(testSetting);
          setSelectWordList([]);
          onChangePrintSetting('type', 0);
          closeModalTestPrintSetting({ setModalTestPrintSetting });
        },
      );
    }
  }, [printSetting, originalTestWordList]);

  const props = {
    title,
    printSetting,
    printInfo,
    printRef,
    originalTestWordList,
    setOriginalTestWordList,
    selectWordList,
    onClickPrintType,
    onClickBackButton,
    onClickWordPrint,
    onChangePrintSetting,
    onChangePrintInfo,
    onChangeTitle,
  };

  return <TestPrintUI {...props} />;
});
