import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PrintWordlistUI } from './PrintWordlist.Presenter';
import { ModalContext } from 'provider/ModalProvider';

export interface PrintWordlistContainerProps {
  originalTestWordList: TestPrintWordType[];
  setOriginalTestWordList: React.Dispatch<React.SetStateAction<TestPrintWordType[]>>;
  selectWordList: TestPrintWordType[];
  printSetting: PrintSettingType;
}

export const PrintWordlistContainer = React.memo(function PrintWordlistContainer({
  originalTestWordList,
  setOriginalTestWordList,
  selectWordList,
  printSetting,
}: PrintWordlistContainerProps) {
  const { modal_confirm, modal_alert } = useContext(ModalContext);
  const [printWordList, setPrintWordList] = useState<TestPrintWordType[]>([]);
  const [checkCount, setCheckCount] = useState(0);
  const [lastClickWord, setLastClickWord] = useState<{
    active: boolean;
    idx: number;
  } | null>(null);

  useEffect(() => {
    if (originalTestWordList.length > 0) {
      const tmpPrintWordList = JSON.parse(JSON.stringify(originalTestWordList)) as TestPrintWordType[];
      for (let i = 0; i < tmpPrintWordList.length; i++) {
        tmpPrintWordList[i].idx = i;
        if (selectWordList.length > 0 && printSetting.type == 0) {
          const is_selected = selectWordList.some(word => word.id === tmpPrintWordList[i].id);
          tmpPrintWordList[i].disabled = !is_selected;
        } else {
          tmpPrintWordList[i].disabled = false;
        }
      }
      setPrintWordList(tmpPrintWordList);
      setLastClickWord(null);
    }
  }, [originalTestWordList, selectWordList, printSetting.type]);

  const wordCount = useMemo(() => {
    return printWordList.length;
  }, [printWordList]);

  const onClickCheckbox = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, active: boolean, idx: number) => {
      if (printSetting.type == 0) {
        e.preventDefault();
        return;
      }
      const click_word = {
        active,
        idx: idx ? idx : 0,
      };
      setLastClickWord(click_word);
      if (e.shiftKey && lastClickWord) {
        if (lastClickWord.idx == click_word.idx) {
          onClickWord(idx);
        } else if (lastClickWord.idx > click_word.idx) {
          onClickWords(click_word, lastClickWord, lastClickWord.active);
        } else {
          onClickWords(lastClickWord, click_word, lastClickWord.active);
        }
      } else {
        onClickWord(idx);
      }
    },
    [printWordList, lastClickWord],
  );

  const onClickWord = useCallback(
    (idx: number) => {
      const updatePrintWordList = JSON.parse(JSON.stringify(printWordList)) as TestPrintWordType[];
      updatePrintWordList[idx].active = updatePrintWordList[idx].active ? false : true;

      const activeCount = updatePrintWordList.filter(word => word.active).length;

      setPrintWordList(updatePrintWordList);
      setCheckCount(activeCount);
    },
    [printWordList],
  );

  const onClickWords = useCallback(
    (firstWord: { active: boolean; idx: number }, lastWord: { active: boolean; idx: number }, active: boolean) => {
      const updatedPrintWordList = printWordList;

      for (let i = firstWord.idx; i <= lastWord.idx; i++) {
        updatedPrintWordList[i].active = !active;
      }

      const activeCount = printWordList.filter(word => word.active).length;

      setPrintWordList(updatedPrintWordList);
      setCheckCount(activeCount);
    },
    [printWordList],
  );

  const onClickWordDelete = useCallback(() => {
    if (checkCount > 0) {
      if (checkCount == wordCount) {
        modal_alert.openModalAlert('do_not_print_word_delete');
      } else {
        modal_confirm.openModalConfirm(
          'normal_reverse',
          'delete_contents_2',
          () => {
            return;
          },
          () => {
            let updatePrintWordList = JSON.parse(JSON.stringify(printWordList)) as TestPrintWordType[];

            updatePrintWordList = updatePrintWordList.filter(word => !word.active);
            modal_alert.openModalAlert('success_delete', undefined, '선택된 단어가');
            setOriginalTestWordList([...updatePrintWordList]);
            setLastClickWord(null);
          },
          '선택 단어를 목록에서',
        );
      }
    }
  }, [originalTestWordList, printWordList, checkCount]);

  const props = {
    wordCount,
    checkCount,
    printSetting,
    printWordList,
    onClickCheckbox,
    onClickWordDelete,
  };
  return <PrintWordlistUI {...props} />;
});
