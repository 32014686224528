import { Routes, Route, useLocation, useNavigate } from 'react-router';
import { Suspense, lazy, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { userState } from 'recoil/model/user';
import { BookPrintContainer } from './BookPrint/BookPrint.Container';

const Notice = lazy(() => import('./Notice/Notice.Container'));
const Notification = lazy(() => import('./Notification'));
const Qna = lazy(() => import('./Qna'));
const Settings = lazy(() => import('./Settings'));
const Chart = lazy(() => import('./Chart'));

function ScheduleIndex() {
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const navigate = useNavigate();
  const location = useLocation();

  //프로필, center_type별 조건부 라우팅
  useEffect(() => {
    if (userStateData.type < 20) {
      if (
        location.pathname === '/manager/bookprint' ||
        location.pathname === '/manager/chart' ||
        location.pathname === '/manager/notice' ||
        location.pathname === '/manager'
      ) {
        navigate('/manager/notification', { replace: true });
      }
      return;
    }
    if (userStateData.type >= 20 && location.pathname === '/manager') {
      navigate('/manager/chart', { replace: true });
    }

    // 기존 관리자 계정에서 성적 분석, 출력 진입 시 공지 페이지로 이동
    if (userStateData.center_type !== 'C') {
      if (location.pathname === '/manager/chart' || location.pathname === '/manager/bookprint') {
        navigate('/manager/notice', { replace: true });
      }
    }
  }, [userStateData.type, location.pathname, navigate]);

  // 조건부 라우트 렌더링
  const renderProtectedRoutes = () => {
    if (userStateData.center_type === 'C') {
      return (
        <>
          <Route path='/chart' element={<Chart />} />
          <Route path='/bookprint' element={<BookPrintContainer />} />
        </>
      );
    }
    return null;
  };

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path='/notice' element={<Notice />} />
        <Route path='/notification' element={<Notification />} />
        <Route path='/qna' element={<Qna />} />
        <Route path='/settings' element={<Settings />} />
        {renderProtectedRoutes()}
      </Routes>
    </Suspense>
  );
}

export default ScheduleIndex;
