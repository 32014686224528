import { atom, SetterOrUpdater } from 'recoil';

export interface ModalTargetSettingType {
  visible: boolean;
  isMain: boolean;
  step: number;
  domainButtonGroup: Array<{ id: number; label: string; check: boolean; disabled: boolean }>;
  dayButtonGroup: Array<{ id: number; label: string; check: boolean }>;
  alimTime: { id: number; content: string };
}

export const modalTargetSettingState = atom<ModalTargetSettingType>({
  key: 'modalTargetSetting',
  default: {
    visible: false,
    isMain: false,
    step: 1,
    domainButtonGroup: [
      {
        id: 1,
        label: '어휘',
        check: false,
        disabled: false,
      },
      {
        id: 2,
        label: '내신',
        check: false,
        disabled: false,
      },
      {
        id: 3,
        label: '문법',
        check: false,
        disabled: false,
      },
      {
        id: 4,
        label: '회화',
        check: false,
        disabled: false,
      },
    ],
    dayButtonGroup: [
      {
        id: 1,
        label: '월',
        check: false,
      },
      {
        id: 2,
        label: '화',
        check: false,
      },
      {
        id: 3,
        label: '수',
        check: false,
      },
      {
        id: 4,
        label: '목',
        check: false,
      },
      {
        id: 5,
        label: '금',
        check: false,
      },
      {
        id: 6,
        label: '토',
        check: false,
      },
      {
        id: 7,
        label: '일',
        check: false,
      },
    ],
    alimTime: { id: 0, content: '' },
  },
});

interface ModalTargetSettingSetter {
  setModalTargetSetting: SetterOrUpdater<ModalTargetSettingType>;
}

export const openModalTargetSetting = (
  { setModalTargetSetting }: ModalTargetSettingSetter,
  options: Omit<ModalTargetSettingType, 'visible' | 'domainButtonGroup' | 'dayButtonGroup' | 'alimTime'>,
  origin?: {
    domainButtonGroup?: Array<{ id: number; label: string; check: boolean; disabled: boolean }>;
    dayButtonGroup?: Array<{ id: number; label: string; check: boolean }>;
    alimTime?: { id: number; content: string };
  },
) => {
  setModalTargetSetting(prev => ({
    ...prev,
    visible: true,
    ...options,
    ...origin,
  }));
};

export const closeModalTargetSetting = ({ setModalTargetSetting }: ModalTargetSettingSetter) => {
  setModalTargetSetting({
    visible: false,
    isMain: false,
    step: 1,
    domainButtonGroup: [
      {
        id: 1,
        label: '어휘',
        check: false,
        disabled: false,
      },
      {
        id: 2,
        label: '내신',
        check: false,
        disabled: false,
      },
      {
        id: 3,
        label: '문법',
        check: false,
        disabled: false,
      },
      {
        id: 4,
        label: '회화',
        check: false,
        disabled: false,
      },
    ],
    dayButtonGroup: [
      {
        id: 1,
        label: '월',
        check: false,
      },
      {
        id: 2,
        label: '화',
        check: false,
      },
      {
        id: 3,
        label: '수',
        check: false,
      },
      {
        id: 4,
        label: '목',
        check: false,
      },
      {
        id: 5,
        label: '금',
        check: false,
      },
      {
        id: 6,
        label: '토',
        check: false,
      },
      {
        id: 7,
        label: '일',
        check: false,
      },
    ],
    alimTime: { id: 0, content: '' },
  });
};
