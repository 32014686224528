import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WordWorksheetUI } from './WordWorksheet.Presenter';
import { useRecoilValue } from 'recoil';
import { deviceState } from 'recoil/common/device';
import { userState } from 'recoil/model/user';

interface WordWorksheetContainerProps {
  title: string;
  printSetting: PrintSettingType;
  printInfo: PrintInfoType;
  originalTestWordList: TestPrintWordType[];
  printRef: React.MutableRefObject<any>;
}

export const WordWorksheetContainer = React.memo(function WordWorksheetContainer({
  title,
  printSetting,
  printInfo,
  originalTestWordList,
  printRef,
}: WordWorksheetContainerProps) {
  const userStateData = useRecoilValue<UserType>(userState);
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { screen_width, screen_height } = deviceStateData;
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [printWordList, setPrintWordList] = useState<TestPrintWordType[]>([]);
  const [worksheetKey, setWorksheetKey] = useState(0);
  const totalPageRef = useRef(0);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef<any>(null);
  const [firstWord, setFirstWord] = useState('');

  useEffect(() => {
    setScale();
  }, [screen_width, screen_height, isFullscreen]);

  useEffect(() => {
    setTitle();
  }, [title]);

  useEffect(() => {
    const current_sheet = $('.worksheet').eq(currentPage);
    current_sheet.removeClass('hidden');
    if (currentPage < maxPage)
      $('.worksheet')
        .eq(currentPage + 1)
        .addClass('hidden');
    if (currentPage > 0)
      $('.worksheet')
        .eq(currentPage - 1)
        .addClass('hidden');
  }, [currentPage]);

  useEffect(() => {
    if (originalTestWordList.length > 0) {
      let sortedWordList;

      switch (printSetting.sort) {
        case 0:
          // grade 기준으로 정렬
          sortedWordList = [...originalTestWordList].sort((a, b) => a.grade.localeCompare(b.grade));
          break;
        case 1:
          // 무작위로 정렬
          sortedWordList = [...originalTestWordList];
          for (let i = sortedWordList.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [sortedWordList[i], sortedWordList[j]] = [sortedWordList[j], sortedWordList[i]];
          }
          break;
        case 2:
          // spell 기준으로 정렬
          sortedWordList = [...originalTestWordList].sort((a, b) => a.spell.localeCompare(b.spell));
          break;
        default:
          sortedWordList = [...originalTestWordList];
      }
      setPrintWordList(sortedWordList);
      setWorksheetKey(prev => prev + 1);
    }
  }, [originalTestWordList, printSetting.sort]);

  useEffect(() => {
    if (printWordList.length > 0) {
      setWorksheetKey(prev => prev + 1);
    }
  }, [printSetting.column, printSetting.fontsize, printSetting.line]);

  useEffect(() => {
    if (printWordList.length > 0 && worksheetKey > 0) {
      setProgress(0);
      setLoading(true);
      setCurrentPage(-1);
      setTimeout(() => {
        init();
      }, 1);
    }
  }, [worksheetKey]);

  useEffect(() => {
    if (printInfo.logo) {
      $('.worksheet-logo').removeClass('invisible');
    } else {
      $('.worksheet-logo').addClass('invisible');
    }
    if (printInfo.pagenum) {
      $('.worksheet-pagenum').removeClass('invisible');
    } else {
      $('.worksheet-pagenum').addClass('invisible');
    }
    if (printInfo.cname) {
      $('.worksheet-cname').removeClass('invisible');
    } else {
      $('.worksheet-cname').addClass('invisible');
    }
  }, [printInfo]);

  const buildNewsletter = () => {
    if ($(`#words`).contents().length > 0) {
      const worksheet = $('.worksheet-template')
        .clone()
        .removeClass('worksheet-template')
        .addClass('worksheet pagebreak')
        .addClass(`page-${totalPageRef.current}`);

      $('.print-wrap').append(worksheet);
      totalPageRef.current++;

      $('.worksheet:last .worksheet-footer .worksheet-pagenum').text('- ' + totalPageRef.current + ' -');

      $('#words').columnize({
        l_rate: 50,
        r_rate: 50,
        buildOnce: true,
        columns: printSetting.column + 1,
        target: '.worksheet:last .worksheet-contents',
        overflow: {
          height: 860,
          id: '#words',
          doneFunc: function () {
            $('.worksheet:last').addClass('hidden');
          },
        },
      });
    }
  };

  const init = useCallback(async () => {
    console.log('init');
    const prev_contents = $('.worksheet');
    if (prev_contents.length > 0) {
      prev_contents.remove();
    }
    totalPageRef.current = 0;

    let limit = 0;
    while ($('#words').contents().length > 0 && $('.worksheet') && limit < 1000) {
      buildNewsletter();
      limit++;
    }

    setMaxPage(totalPageRef.current - 1);
    $('.column').addClass('h-100');
    setTimeout(() => {
      setLoading(false);
      if (firstWord) {
        const classNames = document.getElementById(firstWord)?.parentElement?.parentElement?.parentElement?.className;
        const classList = classNames && classNames.includes(' ') ? classNames.split(' ') : false;

        let page = 0;
        if (classList) {
          for (const className of classList) {
            if (className.includes('page-')) {
              page = Number(className.replace('page-', ''));
            }
          }
        }

        setCurrentPage(page);
      } else {
        setCurrentPage(0);
      }
      setTimeout(renderEnd, 10);
    }, 100);
  }, [printWordList, printSetting]);

  const renderEnd = () => {
    const worksheet = $('.worksheet:not(.hidden)');

    if (worksheet.length > 0) {
      setScale();
    } else {
      setTimeout(renderEnd, 10);
    }
  };

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  const onChangeProgress = async (value: number) => {
    await delay(50);
    setProgress(value);
  };

  const onClickPageMove = useCallback(
    (direction: 'prev' | 'next') => {
      if (direction == 'prev') {
        setCurrentPage(prev => prev - 1);
        const first_word = document
          .getElementsByClassName(`page-${currentPage - 1}`)[0]
          .getElementsByClassName('dontsplit')[0].id;
        setFirstWord(first_word);
      } else if (direction == 'next') {
        setCurrentPage(prev => prev + 1);
        const first_word = document
          .getElementsByClassName(`page-${currentPage + 1}`)[0]
          .getElementsByClassName('dontsplit')[0].id;
        setFirstWord(first_word);
      }
    },
    [currentPage],
  );

  const onClickFullscreen = useCallback(() => {
    setIsFullscreen(prev => !prev);
  }, [isFullscreen]);

  const setScale = useCallback(() => {
    const element = document.getElementById('worksheet-container');

    if (element) {
      setScrollWidth(element.offsetWidth - element.clientWidth);
      setScrollHeight(element.offsetHeight - element.clientHeight);
    } else {
      setScrollWidth(0);
      setScrollHeight(0);
    }

    if (isFullscreen) {
      containerRef.current.style.transform = '';
    } else {
      const x = containerRef.current.clientWidth / printRef.current.clientWidth;
      const y = containerRef.current.clientHeight / printRef.current.clientHeight;
      if (x > y) containerRef.current.style.transform = `scale(${y})`;
      else containerRef.current.style.transform = `scale(${x})`;
    }
  }, [screen_width, screen_height, isFullscreen]);

  const setTitle = useCallback(() => {
    const title_element = $('.title > div');
    if (title) {
      title_element.css('color', 'inherit');
      title_element.text(title);
    } else {
      title_element.css('color', 'rgb(172, 125, 255)');
      title_element.text('제목을 입력하세요.');
    }
  }, [title]);

  const props = {
    worksheetKey,
    currentPage,
    maxPage,
    loading,
    progress,
    scrollWidth,
    scrollHeight,
    isFullscreen,
    printSetting,
    printWordList,
    userStateData,
    printRef,
    containerRef,
    onClickPageMove,
    onClickFullscreen,
  };

  return <WordWorksheetUI {...props} />;
});
