import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { ModalStudySettingUI } from './ModalStudySetting.Presenter';
import { useCallback, useContext } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  ModalStudySettingDataType,
  modalStudySettingData,
  closeModalStudySetting,
} from 'recoil/common/modalStudySetting';
import React from 'react';
import { ModalContext } from 'provider/ModalProvider';
import { useNavigate } from 'react-router-dom';
import { isMobileValidate } from 'utils/tools';

export const ModalStudySettingContainer = React.memo(function ModalStudySettingContainer() {
  const navigate = useNavigate();
  const setToastBar = useSetRecoilState(toastBarState);
  const [modalStudySetting, setModalStudySetting] = useRecoilState<ModalStudySettingDataType>(modalStudySettingData);
  const { modal_alert } = useContext(ModalContext);

  const onClose = useCallback(
    // 모달 닫을 때 직전 path로 이동
    (isCalled?: string) => {
      if (isCalled === 'Y' || modalStudySetting.type === 'courseType_2') {
        navigate(`${modalStudySetting.previousPath}`, { replace: true });
      } else if (modalStudySetting.type == 'joinType') {
        closeModalStudySetting({ setModalStudySetting });
      }
    },
    [modalStudySetting],
  );

  const onClickClose = useCallback(() => {
    closeModalStudySetting({ setModalStudySetting });
    if (modalStudySetting.type == 'printType' || modalStudySetting.type == 'courseType_2') onClose('Y');
  }, [modalStudySetting.type]);

  const onSuccess = useCallback(
    (idx: number) => {
      if (isMobileValidate() && modalStudySetting.type != 'joinType') {
        openToastBar({ setToastBar }, '단어 시험지 출력은 PC에서만 가능합니다.', 'red', 2000);
        return;
      }
      modalStudySetting.onSuccess(idx);
      if (['image', 'method', 'wrongNote', 'testMethod'].includes(modalStudySetting.type)) {
        const type = modalStudySetting.type;
        let type_to_kor = '';
        if (type == 'image') {
          type_to_kor = '이미지 ';
        } else if (type == 'method') {
          type_to_kor = '학습 방식이 ';
        } else {
          type_to_kor = '오답 학습이 ';
        }

        modal_alert.openModalAlert('success_choice', undefined, type_to_kor + modalStudySetting.contents[idx].target);
      }
    },
    [modalStudySetting],
  );

  const props_obj = {
    visible: modalStudySetting.visible,
    title: modalStudySetting.title,
    contents: modalStudySetting.contents,
    value: modalStudySetting.value,
    type: modalStudySetting.type,
    onSuccess,
    onClose,
    onClickClose,
  };

  return <ModalStudySettingUI {...props_obj} />;
});
