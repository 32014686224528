import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, setDoc, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth';
declare let window: any;

const BASE_URL =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? process.env.REACT_APP_REAL_HOMEPAGE
    : process.env.REACT_APP_DEV_HOMEPAGE;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// firebaseConfig 정보로 firebase 시작
const app = initializeApp(firebaseConfig);

// firebase 익명 로그인 -> uid 뽑기위해
const auth = getAuth();
signInAnonymously(auth).catch(error => {
  const errorCode = error.code;
  const errorMessage = error.message;
});

onAuthStateChanged(auth, user => {
  if (user) {
    window.FB_UID = user.uid;
  } else {
    window.FB_UID = undefined;
  }
});

// firebase의 firestore 인스턴스를 변수에 저장
const collection_name =
  process.env.REACT_APP_NODE_ENV != 'development'
    ? (process.env.REACT_APP_REAL_FIRESTORE_DB_NAME as string)
    : (process.env.REACT_APP_DEV_FIRESTORE_DB_NAME as string);
const db = getFirestore(app);

// users collection 의 user doc 조회 함수
export async function getUserInFireStore(accountId: number) {
  const user_ref = doc(db, collection_name, String(accountId));
  const user_snapshot = await getDoc(user_ref);

  if (user_snapshot.exists()) {
    return user_snapshot.data();
  } else {
    //  최초 로그인
    return null;
  }
}

// firestore랑 sync 맞추는 함수 짜야함
export function bindUserDocs(accountId: number, success_cb: () => void) {
  const user_ref = doc(db, collection_name, String(accountId));
  return onSnapshot(user_ref, { includeMetadataChanges: true }, doc => {
    const data = doc.data() as UserDocumentType;

    if (window.FB_UID !== undefined && !!data.fb_uid && data.fb_uid !== window.FB_UID) {
      success_cb();
    }
  });
}

// users collection 내에 user 추가
export async function setUserInFireStore(accountId: number, data: UserDocumentType, success_cb: () => void) {
  console.log('setUserInFireStore');
  const user_ref = doc(db, collection_name, String(accountId));
  await setDoc(user_ref, data, { merge: true }).then(res => {
    window.FB_UNSCRIBE = bindUserDocs(accountId, success_cb);
  });
}

export async function updateUserInFireStore(accountId: number, data: UserDocumentType) {
  const { fb_uid, login_time } = data;
  if (fb_uid && login_time) {
    const user_ref = doc(db, collection_name, String(accountId));
    await setDoc(user_ref, data, { merge: true });
  }
}

// users collection 내에 user의 uid 초기화
export async function setUserInFireStoreToNull(accountId: number, isExit?: boolean) {
  // if (isExit) {
  //   const xmlhttp = new XMLHttpRequest();
  //   xmlhttp.open('POST', `${BASE_URL}api/firebase`, false);
  //   const data = JSON.stringify({
  //     accountId,
  //   });
  //   xmlhttp.setRequestHeader('Content-Type', 'application/json');
  //   xmlhttp.send(data);
  // } else {
  // }
  await fetch(`${BASE_URL}/api/firebase`, {
    method: 'POST',
    keepalive: true,
    body: JSON.stringify({
      accountId,
    }),
  });
}

// 로그인 시, 사용할 firebase 모듈
export async function checkDuplicateLogin(accountId: number, success_cb: () => void) {
  const userDoc = await getUserInFireStore(accountId);
  const data: UserDocumentType = {
    fb_uid: window.FB_UID,
    login_time: new Date().getTime(),
  };
  if (userDoc) {
    const user_document = userDoc as UserDocumentType;
    // 로그인 검증 로직
    if (user_document.fb_uid && user_document.login_time !== null) {
      //  중복 로그인 검증 로직 필요
      if (user_document.fb_uid == window.FB_UID) {
        // 같은 기기에서 접속
        delete data.fb_uid;
        // await setUserInFireStore(accountId, data, success_cb);
        return 'END';
      } else {
        // 다른 기기에서 접속
        let sub_time = 0;
        // 로그인 시간이 저장되어 있고, 시간 차이가 6시간 이상인 경우 확인 팝업 없이 로그인
        if (data.login_time && data.login_time !== null && data.login_time > 100000) {
          sub_time = new Date().getTime() - data.login_time;
          if (sub_time > 21600000) {
            // await setUserInFireStore(accountId, data, success_cb);
            return 'END';
          } else {
            // 중복 로그인 확인 팝업
            return 'DUP';
          }
        } else {
          // await setUserInFireStore(accountId, data, success_cb);
          return 'END';
        }
      }
    } else {
      // 중복 로그인 상태 아님
      // await setUserInFireStore(accountId, data, success_cb);
      return 'END';
    }
  } else {
    // 최초 로그인
    // await setUserInFireStore(accountId, data, success_cb);
    return 'END';
  }
}
