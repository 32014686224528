import { atom, SetterOrUpdater } from 'recoil';

export interface ModalProductIntendType {
  visible: boolean;
  buttons: boolean;
}

export const modalProductIntendState = atom<ModalProductIntendType>({
  key: 'modalProdductIntend',
  default: {
    visible: false,
    buttons: false,
  },
});

interface ModalProductIntendSetter {
  setModalProductIntend: SetterOrUpdater<ModalProductIntendType>;
}

export const openModalProductIntend = ({ setModalProductIntend }: ModalProductIntendSetter) => {
  setModalProductIntend(prev => ({
    ...prev,
    visible: true,
  }));
};

export const openModalProductIntend2 = ({ setModalProductIntend }: ModalProductIntendSetter) => {
  setModalProductIntend(prev => ({
    ...prev,
    visible: true,
    buttons: true,
  }));
};

export const closeModalProductIntend = ({ setModalProductIntend }: ModalProductIntendSetter) => {
  setModalProductIntend({
    visible: false,
    buttons: false,
  });
};
