import styled from '@emotion/styled';
import { Box } from '@mui/material';
import theme from 'theme';
import { TfiArrowLeft } from 'react-icons/tfi';

export const Wrapper = styled(Box)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: 'white',
  zIndex: '2',
});

export const Container = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',
  backgroundColor: 'white',
  flexDirection: 'column',
});

export const Header = styled(Box)({
  width: '100%',
  height: '5rem',
  position: 'absolute',
  top: '-5.5rem',
  display: 'flex',
  justifyContent: 'flex-end',
  backgroundColor: 'white',
  borderBottom: '1px solid #efefef',
});

export const IconWrap = styled(Box)(props => {
  return {
    // [theme.breakpoints.up('xxl')]: {
    //   width: `calc(18px + 2.75rem + (calc(100px - 2.75rem) / 2))`,
    // },

    // [theme.breakpoints.down('xxl')]: {
    //   width: `calc(18px + 2.75rem + (calc(90px - 2.75rem) / 2))`,
    // },

    // [theme.breakpoints.down('xl')]: {
    //   width: `calc(18px + 2.75rem + (calc(72.5px - 2.75rem) / 2))`,
    // },

    // [theme.breakpoints.down('md')]: {
    //   width: `calc(18px + 2.75rem + (calc(65px - 2.75rem) / 2))`,
    // },

    // [theme.breakpoints.down('sm')]: {
    //   width: `calc(18px + 2.75rem + (calc(60px - 2.75rem) / 2))`,
    // },

    height: '100%',
    display: 'flex',
    alignItems: 'center',
  };
});

export const BackIconBox = styled(Box)({
  backgroundColor: '#f5f5f5',
  borderRadius: '50%',
  border: 'none',
  width: '2.75rem',
  height: '2.75rem',
  padding: '0',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const BackIcon = styled(TfiArrowLeft)({
  color: '#9f9f9f',
  strokeWidth: '1.5px',
  width: '1.35rem',
  height: '1.35rem',
});

export const Body = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
});

export const FirstWrap = styled(Box)({
  width: 'calc(25% - 1.5px + 3px)',
  height: '100%',
});

export const SecondWrap = styled(Box)({
  width: 'calc(45% - 3px)',
  height: '100%',
});

export const ThirdWrap = styled(Box)({
  width: 'calc(30% - 1.5px + 3px)',
  height: '100%',
});
