import { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { learningState } from '../../recoil/model/learning';
import { deviceState } from '../../recoil/common/device';
import { loadingState, setPercent } from '../../recoil/common/loading';
import { useVoice, voiceState } from '../../recoil/common/voice';
import { settingState } from '../../recoil/model/settings';
import { userState } from '../../recoil/model/user';
// import { tutorialStateData, openTutorial } from '../../recoil/common/tutorial';

import { ModalContext } from '../../provider/ModalProvider';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';

import ModalGrading from '../../components/modal/ModalGrading';
import ModalManual from 'components/modal/ModalManual';

import { fetchFileApi, fetchGetApi, fetchPostApi } from '../../utils/api';

import { styled, Box, IconButton, tooltipClasses, Tooltip, TooltipProps, ClickAwayListener } from '@mui/material';
import 'assets/js/recorder';

import { dir_column, d_flex_center } from 'styles/common';
import { getExampleFilename, korToEng } from 'utils/tools';
import getSentenceScore from 'utils/get_score';

import canvas_bg from 'assets/images/learning/wave-bg.png';
import Button from 'components/button/Button';
import { FaSquareFull } from 'react-icons/fa';
import { toast_contents } from 'utils/modal_contents';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';

declare let window: any;
declare let navigator: any;
declare let webkitSpeechRecognition: any;
declare let Recorder: any;

interface WebkitSpeechRecognitionProps {
  setHtml: React.Dispatch<React.SetStateAction<string | null>>;
  finishCallback: (resultsheet: ResultsheetType[]) => void;
  progress?: number;
  onClickReplayButton?: (isForce?: boolean) => void;
  // recognition: React.MutableRefObject<any>;
  // permissionStatus: React.MutableRefObject<PermissionStatus | null>;
  // permission_init: React.MutableRefObject<boolean>;
  // permission: React.MutableRefObject<boolean>;
  // is_set: React.MutableRefObject<boolean>;
}

const StyledWebkitSpeechWrap = styled(Box)(props => ({
  width: '96%',
  height: '100%',
  position: 'relative',
  ...d_flex_center,
  ...dir_column,
}));

const StyledMicButton = styled(IconButton)(props => ({
  position: 'absolute',
  width: '14vh',
  height: '14vh',
  padding: '2vh',
  backgroundColor: '#ff3722',
  borderColor: '#ff3722',
  zIndex: '30',
}));

const StyledCountButton = styled(IconButton)(props => ({
  position: 'absolute',
  width: '14vh',
  height: '14vh',
  padding: '2vh',
  backgroundColor: '#ff3722',
  borderColor: '#ff3722',
  zIndex: '30',
}));

const StyledCount = styled('span')({
  positon: 'absolute',
  fontSize: '5rem',
  fontWeight: '600',
  color: '#fff',
  zIndex: '32',
  '@media (max-width: 1903px)': {
    fontSize: '5rem',
  },

  '@media (max-width: 1263px)': {
    fontSize: '5rem',
  },
  '@media (max-width: 1024px)': {
    fontSize: '4rem',
  },
  '@media (max-width: 767px)': {
    fontSize: '3rem',
  },
});

const PulseBox = styled('span')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '7vh',
  height: '7vh',
  backgroundColor: '#ff4c41',
  borderRadius: '50%',

  '@keyframes pulse': {
    '0%': {
      transform: 'translate(-50%, -50%) scale(1.2)',
    },
    '50%': {
      transform: 'translate(-50%, -50%) scale(1.7)',
    },
    '100%': {
      transform: 'translate(-50%, -50%) scale(1.2)',
    },
  },

  animation: 'pulse 1s cubic-bezier(0.4, 0, 0.2, 1) 2.5 normal forwards',
});

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} disableHoverListener />
))(({ theme, placement }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#3f3f3f',
    transition: 'none !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    transition: '0.05s !important',
    marginTop: '1rem !important',
    backgroundColor: '#3f3f3f',
    fontSize: '1rem',
    textAlign: 'center',
    maxWidth: 'unset',
    lineHeight: '1',
    padding: '0.5rem 1rem',
    borderRadius: '0.5rem',
    top: '0.5vh',
    left: '0.125rem',
    '& > div': {
      fontSize: '1.1rem',
    },
  },
}));

const StopIconButton = styled(IconButton)(props => ({
  backgroundColor: 'rgb(0,0,0,0.3)',
  position: 'absolute',
  top: '2vh',
  right: '2vh',
  zIndex: '30',
  padding: '1.5vh',
  '& > svg': {
    width: '1.2rem',
    height: '1.2rem',
    color: '#FFFFFF',
  },
}));

function WebkitSpeechRecognition(props: WebkitSpeechRecognitionProps) {
  const finishCallback = props.finishCallback;
  const setHtml = props.setHtml;
  const { modal_alert } = useContext(ModalContext);

  // const setTutorialState = useSetRecoilState(tutorialStateData);
  const userStateData = useRecoilValue<UserType>(userState);
  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const { mod, current_step, contents, current_page, show_modal, resultsheet } = learningStateData;

  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { screen_width, screen_height } = deviceStateData;

  const [retryCnt, setRetryCnt] = useState<number>(1);
  const voiceStateData = useRecoilValue<VoiceType>(voiceState);
  const { ready, element } = voiceStateData;
  const { setVoice, voicePlay, voiceStop } = useVoice();

  const settingStateData = useRecoilValue(settingState);
  const { check_type, enable_keyboard, is_fullscreen } = settingStateData;
  const setToastBar = useSetRecoilState(toastBarState);

  const { playEffectSound } = useContext(EffectSoundContext);

  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;

  const [content, setContent] = useState<ContentType>(contents[current_step]);
  const [answer, setAnswer] = useState<string>('');
  const [rightanswer, setRightanswer] = useState<string>('');
  const rightanswerRef = useRef<string>('');
  const [question, setQuestion] = useState<string>('');

  const [canvWidth, setCanvWidth] = useState<number>(screen_width * 0.45);
  const [canvHeight, setCanvHeight] = useState<number>(screen_height * 0.28);

  const [nextDisable, setNextDisable] = useState<boolean>(false);
  const [visibleGrading, setVisibleGrading] = useState<boolean>(false);

  const [modalType, setModalType] = useState<
    'correct_recording' | 'good_recording' | 'wrong_recording' | 'fail_recording'
  >('fail_recording');

  const [readyNext, setReadyNext] = useState<boolean>(false);
  // const [goResult, setGoResult] = useState<boolean>(false);

  const isScoring = useRef<boolean>(false);

  const ctx = useRef<CanvasRenderingContext2D | null>(null);

  const audioRef = useRef<HTMLAudioElement>(new Audio());

  const audio_context = useRef<AudioContext | undefined>(undefined);
  const source_node = useRef<any>(null);
  const javascript_node = useRef<any>(null);
  const audio_stream = useRef<any>(null);
  const recorder = useRef<any>(null);
  const final_transcript = useRef<string>('');
  const prev_final_transcript = useRef<string>('');
  const recstatus = useRef<boolean>(false);
  const checkstatus = useRef<boolean>(false);
  const playstatus = useRef<boolean>(false);

  const [noSpeech, setNoSpeech] = useState<boolean>(false);

  const language = 'en-US';

  interface SpectData {
    num: number;
    wave_data: any;
  }

  const spect_data = useRef<SpectData | null>(null);
  const spect_num = useRef<number>(0);

  const record_cnt = useRef<number>(0);

  const getusermedia = useRef<any>(null);

  const settimeout1 = useRef<ReturnType<typeof setTimeout>[]>([]);
  const settimeout2 = useRef<ReturnType<typeof setTimeout>[]>([]);
  const settimeout3 = useRef<ReturnType<typeof setTimeout>[]>([]);

  const recognition = useRef<any>(null);
  const permissionStatus = useRef<PermissionStatus | null>(null);
  const permission_init = useRef<boolean>(false);
  const permission = useRef<boolean>(false);
  const is_set = useRef<boolean>(false);
  // const recognition = props.recognition;
  // const permissionStatus = props.permissionStatus;
  // const permission_init = props.permission_init;
  // const permission = props.permission;
  // const is_set = props.is_set;

  const rec_blob = useRef<any>(null);

  // const passed = useRef<boolean>(true);
  const prev_x = useRef<number>(0);
  const prev_val = useRef<number>(0);

  const [keyboardStop, setKeyboardStop] = useState(false);

  const onSoundPlayRef = useRef<() => Promise<void>>();
  const onPlayEffectSoundPlayRef = useRef<() => Promise<void>>();
  const onAnswerSoundPlayRef = useRef<() => Promise<void>>();
  const onAnswerSoundStopRef = useRef<() => Promise<void>>();

  const [showReady, setShowReady] = useState(false);
  const [status, setStatus] = useState('prompt');
  const [openTooltip, setOpenTooltip] = useState(false);
  const [modalManualVisible, setModalManualVisible] = useState(false);
  const [waitingCount, setWaitingCount] = useState(3);
  const waitingCount_ref = useRef(3);
  waitingCount_ref.current = waitingCount;
  const isMicUnConnected = useRef<boolean | null>(null);
  const { center_type } = userStateData;

  const useTimer = useMemo(() => {
    return true;
  }, []);

  const checkExampleTTS = async () => {
    for (let i = 0; i < contents.length; i++) {
      const sentence = contents[i].sentence;
      if (!sentence) continue;
      const gender = 0; // 남자 임시 고정
      const filename = getExampleFilename(sentence);
      const res = await fetchGetApi(`/etc/tts/example?filename=${filename}&gender=${gender}&ver=2`);
      if (!res.result) {
        const postdata = {
          example: sentence,
          filename: filename,
          gender: gender,
        };
        await fetchPostApi(`/etc/tts/example`, postdata);
      }
    }
  };

  useEffect(() => {
    if (!('webkitSpeechRecognition' in window) || !('AudioContext' in window)) {
      return;
    }
    try {
      audio_context.current = new AudioContext();
    } catch (e) {
      console.error(e);
    }
    setPermission();

    if (mod[current_page].resultsheet && mod[current_page].resultsheet != undefined) {
      const resultsheet_tmp = mod[current_page].resultsheet as ResultsheetType[];
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: resultsheet_tmp,
      }));
    } else {
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: [],
      }));
    }

    window.rec_array = new Array(0);
    window.URL = window.URL || window.webkitURL;
    window.navigator.getUserMedia =
      navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
    window.paddInterval = [];

    checkExampleTTS();

    // setPercent(setLoadingStateData, 100);

    return () => {
      audioRef.current.onloadedmetadata = null;
      audioRef.current.onended = null;
      if (audioRef.current.played) audioRef.current.pause();
      voiceStop();
      clearTimeout1();
      clearTimeout2();
      if (recognition.current?.onend) recognition.current.onend = null;
      if (recognition.current?.onerror) recognition.current.onerror = null;
      if (recognition.current?.onspeechend) recognition.current.onspeechend = null;
      if (recognition.current?.onresult) recognition.current.onresult = null;
      if (recognition.current?.stop) recognition.current.stop();
      if (recognition.current?.abort) recognition.current.abort();
      if (recorder.current && recorder.current.stop) recorder.current.stop();
      if (recorder.current) recorder.current.close();
      if (source_node.current) source_node.current.disconnect();
      if (permissionStatus.current?.onchange) permissionStatus.current.onchange = null;
      permissionStatus.current = null;
      recognition.current = null;
      recorder.current = null;
      if (javascript_node.current?.onaudioprocess) javascript_node.current.onaudioprocess = null;
      if (javascript_node.current) javascript_node.current.disconnect();
      if (audio_context.current) {
        audio_context.current.close().then(() => {
          console.log('AudioContext is closed');
        });
      }
      audio_context.current = undefined;
      if (audio_stream.current) {
        audio_stream.current.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, []);

  useEffect(() => {
    if (!nextDisable && !visibleGrading && percent == 0) setInit();
  }, [is_fullscreen]);

  // useEffect(() => {
  //   if (percent == 100) {
  //     if (userStateData.show_tutorial && userStateData.show_tutorial.speak) {
  //       // 튜토리얼을 진행해야 한다면
  //       setTimeout(() => {
  //         setLearningStateData(prevState => ({
  //           ...prevState,
  //           show_modal: true,
  //         }));
  //         openTutorial({ setTutorialState }, { reopen: false });
  //       }, 275);
  //     } else {
  //       setTimeout(() => {
  //         setLearningStateData(prevState => ({
  //           ...prevState,
  //           show_modal: true,
  //         }));
  //         modal_alert.openModalAlert('pc_speaking_start', undefined, undefined, () => {
  //           setLearningStateData(prevState => ({
  //             ...prevState,
  //             show_modal: false,
  //           }));
  //         });
  //       }, 400);
  //     }
  //   }
  // }, [percent]);

  useEffect(() => {
    setContent(contents[current_step]);
  }, [current_step]);

  useEffect(() => {
    if (readyNext) {
      setReadyNext(false);

      clearTimeout1();
      clearTimeout2();

      goNext();
    }
  }, [readyNext]);

  useEffect(() => {
    const sentence = content['sentence'] ? content['sentence'] : '';

    setRightanswer(sentence);
    rightanswerRef.current = sentence;
    setModalType('fail_recording');
    setQuestion(sentence);
    setInit();
    setRetryCnt(1);

    const gender = 0;
    const filename = getExampleFilename(sentence);
    const path = filename.charAt(0).toLocaleLowerCase();
    const full_path = `examples/${gender}/${path}/${filename}.mp3`;
    setVoice(sentence, full_path, false);
  }, [content]);

  useEffect(() => {
    if (enable_keyboard) bindKeyboard();
    return () => {
      if (enable_keyboard) unbindKeyboard();
    };
  }, [answer, ready, show_modal, visibleGrading, nextDisable, keyboardStop]);

  useEffect(() => {
    if (props.progress && props.progress === 100) {
      onClickMicButton();
    }
  }, [props.progress]);

  const bindKeyboard = () => {
    document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const setInit = () => {
    if (recorder.current) recorder.current.close();
    clearTimeout2();
    recorder.current = null;
    audioRef.current = new Audio();

    window.setTimeout(() => {
      setCanvWidth(screen_width * 0.45);
      setCanvHeight(screen_height * 0.28);
    }, 0);

    if (!permission.current) {
      setPermission();
    }
    const canvas = document.getElementById('recode-canvas') as HTMLCanvasElement;
    ctx.current = canvas.getContext('2d');
    ctx.current?.clearRect(0, 0, canvWidth, canvHeight);
    prev_x.current = 0;
    prev_val.current = 300;
    // passed.current = true;

    clearPaddInterval();
    window.rec_array = new Array(0);

    final_transcript.current = '';
    prev_final_transcript.current = '';
  };

  // 여백 채운 녹음선 초기화
  const clearPaddInterval = () => {
    for (let i = 0; i < window.paddInterval.length; i++) {
      window.clearInterval(window.paddInterval[i]);
    }
    window.paddInterval = [];
  };

  // 녹음 준비 및 실행
  const setStudy = () => {
    if (!recognition.current) setUserMedia();
    if (recognition.current.stop) recognition.current.stop();
    if (recorder.current) recorder.current.close();
    recorder.current = null;
    recstatus.current = false;
    checkstatus.current = false;
    javascript_node.current.onaudioprocess = null;
    recognition.current.grammar = rightanswer;
    recognition.current.start();
    record_cnt.current += 1;
  };

  const spectToWave = () => {
    if (spect_data.current && spect_data.current.num > 0) {
      const val = spect_data.current.wave_data;
      clearTimeout1();
      const rec_duration = 60; // 파장 시간 고정
      const ratio = Math.floor((val.length * rec_duration) / canvWidth); // 4 sec
      const cnt = Math.floor(canvWidth / rec_duration);
      const val_arr = [];
      const sum_arr = [];
      let pass = false;

      for (let j = 0; j < cnt; j++) {
        const start = j * ratio;
        const end = start + ratio;
        let min = 0;
        let max = 0;
        let sum = 0;

        for (let i = start; i < end; i++) {
          min = val[i] < min ? val[i] : min;
          max = val[i] > max ? val[i] : max;
          sum = ((max - min) / 3) * 2;
        }
        sum_arr.push(sum);

        val_arr[j] = Math.round(canvHeight - sum * canvHeight) - 20;
      }
      const delaylimit = Math.round(canvWidth * 0.08);
      const pass_cut = Math.round(canvHeight / 90);
      let sum_avg = 0;
      for (let b = 0; b < sum_arr.length; b++) {
        sum_avg += sum_arr[b] * canvHeight;
      }
      sum_avg = Math.round(sum_avg / sum_arr.length);

      if (prev_x.current < delaylimit) {
        // passed.current = false;
        pass = true;
      } else {
        // if (passed.current) {
        //   pass = true;
        // } else {
        if (pass_cut >= sum_avg) {
          pass = false;
        } else {
          pass = true;
          // passed.current = true;
        }
        // }
      }
      if (pass) {
        clearTimeout3();
        setNoSpeech(false);
      } else if (settimeout3.current.length == 0) {
        settimeout3.current.push(
          setTimeout(() => {
            setNoSpeech(true);
          }, 2000),
        );
      }
      waveform(val_arr, 'spect_data');
    }
  };

  const waveform = (val_arr: number[], type: string) => {
    if (ctx.current) {
      ctx.current.lineWidth = 2;
      ctx.current.strokeStyle = '#69e5ff';
      ctx.current.lineCap = 'round';
      ctx.current.lineJoin = 'round';
      ctx.current.shadowBlur = 20;
      ctx.current.shadowColor = 'rgba(105, 229, 255, 1.0)';
      ctx.current.beginPath();
      for (let z = 0; z < val_arr.length; z++) {
        window.rec_array.push(val_arr[z]);
      }

      if (prev_x.current > canvWidth) {
        for (let y = 0; y < val_arr.length; y++) {
          window.rec_array.shift();
        }

        ctx.current.clearRect(0, 0, canvWidth, canvHeight);
        let _x = 0;
        let _v = 0;

        for (let i = 0, leng = window.rec_array.length; i < leng; i++) {
          ctx.current.moveTo(_x - 1, _v);
          ctx.current.lineTo(_x, window.rec_array[i]);
          _x++;
          _v = window.rec_array[i];
        }

        ctx.current.stroke();
      } else {
        for (let i = 0; i < val_arr.length; i++) {
          ctx.current.moveTo(prev_x.current - 1, prev_val.current);
          ctx.current.lineTo(prev_x.current, val_arr[i]);
          prev_x.current++;
          prev_val.current = val_arr[i];
        }
        ctx.current.stroke();
      }
    }
  };

  const onClickMicButton = async () => {
    const videoElement: HTMLVideoElement | null = document.querySelector('#speak-video-element');
    if (videoElement && !videoElement.paused) {
      videoElement.pause();
    }
    await checkMicrophoneExists();
    // await setUserMedia();

    if (isMicUnConnected.current) {
      //* B2C toast로 분기처리
      if (center_type == 'A' || center_type == 'B') {
        modal_alert.openModalAlert('mic_not_found');
      } else {
        openToastBar({ setToastBar }, toast_contents.error.mic_not_found, 'red', 2000);
      }

      return;
    } else {
      // timer 옵션에 따라 다르게 처리
      startRecord(useTimer ? true : false);
    }
  };

  // 녹음 시작
  const startRecord = async (isCount?: boolean) => {
    if (nextDisable || visibleGrading) return false;
    if (permission.current === false) {
      if (status == 'denied') {
        setOpenTooltip(true);
      } else {
        //* B2C toast로 분기처리
        if (center_type == 'A' || center_type == 'B') {
          modal_alert.openModalAlert('mic_permisson_failed', 'white');
        } else {
          // 안내 문구가 길어 2500ms로 설정
          openToastBar({ setToastBar }, toast_contents.error.mic_permisson_failed, 'red', 2500);
        }
      }
      return false;
    }
    setNextDisable(true);
    setKeyboardStop(true);
    setLearningStateData(prevState => ({
      ...prevState,
      modal_disable: true,
    }));

    if (isCount) {
      if (waitingCount_ref.current === 0) {
        setWaitingCount(3);
      }
    }

    if (isCount) {
      playEffectSound('speaking_count_1');
      // 3초 카운트 다운 이후에 start 예정
      countDownInterval.current.push(
        setInterval(async () => {
          if (waitingCount_ref.current == 3) {
            playEffectSound('speaking_count_1');
          }
          if (waitingCount_ref.current == 1) {
            clearCountDownInterval();
          }

          setWaitingCount(prev => prev - 1);
          if (waitingCount_ref.current == 2) {
            await playEffectSound('speaking_count_2');
            setStudy();
          }
        }, 1000),
      );
    } else {
      await playEffectSound('speaking_rec');
      setStudy();
    }
  };

  const setPermission = () => {
    window.navigator.permissions.query({ name: 'microphone' as PermissionName }).then((status: PermissionStatus) => {
      setStatus(status.state);
      switch (status.state) {
        case 'prompt':
          permission.current = false;
          permission_init.current = true;
          break;
        case 'denied':
          permission.current = false;
          break;
        case 'granted':
          permission.current = true;
          break;
        default:
          break;
      }
      setUserMedia();
      permissionStatus.current = status as PermissionStatus;
      permissionStatus.current.onchange = (res: Event) => {
        const target_status = res.target as PermissionStatus;
        setStatus(target_status.state);
        switch (target_status.state) {
          case 'denied':
            permission.current = false;
            permission_init.current = false;
            break;
          case 'granted':
          case 'prompt':
            permission.current = true;
            setUserMedia();
            break;
          default:
            break;
        }
      };
    });
  };

  const setUserMedia = async () => {
    if (is_set.current) return;

    try {
      getusermedia.current = await window.navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream: any) => {
          if (audio_context.current == undefined) return;
          isMicUnConnected.current = false;
          const sample_size = 2048;
          audio_stream.current = stream;
          source_node.current = audio_context.current.createMediaStreamSource(audio_stream.current);

          // wave data ##
          javascript_node.current = audio_context.current.createScriptProcessor(sample_size, 1, 1);

          source_node.current.connect(javascript_node.current);
          javascript_node.current.connect(audio_context.current.destination);
        })
        .catch((e: DOMException) => {
          if (e.message === 'Requested device not found' || e.name === 'NotFoundError') {
            isMicUnConnected.current = true;
          }

          window.console.error(e);
          return false;
        });
    } catch (e) {
      permission.current = false;
      alert('webkitGetUserMedia threw exception:' + e);
      return false;
    }

    if (!permission.current && process.env.REACT_APP_NODE_ENV != 'development') return false;
    is_set.current = true;

    recognition.current = new webkitSpeechRecognition();
    recognition.current.continuous = true;
    recognition.current.maxAlternatives = 12; // 5
    recognition.current.interimResults = true;
    recognition.current.lang = language;
    recognition.current.onstart = function () {
      recorder.current = new Recorder(source_node.current, {
        workerPath: '/js/recorderWorker.js',
      });
      recorder.current.record();
      recstatus.current = true;

      javascript_node.current.onaudioprocess = function (e: any) {
        const wave_data = e.inputBuffer.getChannelData(0);
        spect_num.current++;
        spect_data.current = {
          num: spect_num.current,
          wave_data: wave_data,
        };
        spectToWave();
      };

      settimeout2.current.push(
        setTimeout(() => {
          clearTimeout1();
          javascript_node.current.onaudioprocess = null;
          recstatus.current = false;
          recorder.current.stop();
          recognition.current.stop();
          checkstatus.current = false;
          setModalType('fail_recording');
          getScore('   ');
          setLearningStateData(prevState => ({
            ...prevState,
            modal_disable: false,
          }));
          final_transcript.current = '   ';
        }, 10000),
      );
    };

    recognition.current.onerror = function (event: any, msg: any, tt: any) {
      if (recognition.current.stop) recognition.current.stop();
      javascript_node.current.onaudioprocess = null;
      recstatus.current = false;
      checkstatus.current = false;
      playstatus.current = false;
    };

    recognition.current.onend = function (event: any) {
      recstatus.current = false;
      if (false == checkstatus.current) {
        if (recognition.current.stop) recognition.current.stop();
        javascript_node.current.onaudioprocess = null;
      }

      setLearningStateData(prevState => ({
        ...prevState,
        modal_disable: false,
      }));
    };

    recognition.current.onspeechend = function (event: any) {
      recstatus.current = false;
      if (false == checkstatus.current) {
        if (recognition.current.stop) recognition.current.stop();
        javascript_node.current.onaudioprocess = null;
      }
      if (final_transcript.current === '   ') {
        setLearningStateData(prevState => ({
          ...prevState,
          modal_disable: false,
        }));
      }
    };

    recognition.current.onresult = function (event: any) {
      let interim_transcript = '';
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          final_transcript.current = '';
          clearTimeout2();
          let best;
          if (event.results[i].length > 0) {
            for (let j = 0; j < event.results[i].length; j++) {
              if (best) {
                if (best.score < getSentenceScore(event.results[i][j].transcript, rightanswerRef.current).score) {
                  best = event.results[i][j];
                  best.score = getSentenceScore(best.transcript, rightanswerRef.current).score;
                  if (best.score == 100) break;
                }
              } else {
                best = event.results[i][j];
                best.score = getSentenceScore(best.transcript, rightanswerRef.current).score;
                if (best.score == 100) break;
              }
            }
          }
          final_transcript.current += best.transcript;
          if ('' != final_transcript.current && final_transcript.current) {
            prev_final_transcript.current = final_transcript.current;
          }
        } else {
          interim_transcript += event.results[i][0].transcript;
        }
      }

      // update the web page
      if (final_transcript.current.length > 0) {
        rec_blob.current = null;
        clearTimeout1();
        clearTimeout2();

        recognition.current.stop();
        javascript_node.current.onaudioprocess = null;
        recstatus.current = false;

        let user = final_transcript.current;

        if (user.length > 0) {
          user = user.charAt(0).toUpperCase() + user.slice(1);
        }

        recorder.current.stop();

        checkstatus.current = false;
        recorder &&
          recorder.current.exportWAV(function (s: any) {
            audioRef.current.onloadedmetadata = () => {
              getScore(user);
              console.log('callBack');
            };
            audioRef.current.src = window.URL.createObjectURL(s);
            rec_blob.current = s; // 녹음 소스 저장
            console.log(s);
          });
      }
    };
    return true;
  };

  async function checkMicrophoneExists() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const microphones = devices.filter((device: any) => device.kind === 'audioinput');
      if (microphones.length === 0) {
        is_set.current = false;
        isMicUnConnected.current = true;
      } else {
        if (javascript_node.current == null) {
          is_set.current = false;
          await setUserMedia();
          isMicUnConnected.current = false;
        } else {
          isMicUnConnected.current = false;
          setUserMedia();
        }
      }
    } catch (error) {
      is_set.current = false;
      console.error('err', error);
      return false;
    }
  }

  const keyboardDownEvent = (evt: KeyboardEvent) => {
    if (show_modal) {
      evt.preventDefault();
      return false;
    }

    const key = korToEng(evt.key);

    if (key == 'Enter' && !keyboardStop) {
      evt.preventDefault();
      // onClickMicButton 메서드 들어갈 자리

      onClickMicButton();
      return false;
    }
  };

  const goNext = async () => {
    clearTimeout3();
    setNoSpeech(false);
    if (check_type) {
      if (audioRef.current.src) {
        onSoundPlayRef.current = async () => {
          playEffectSound(modalType == 'correct_recording' || modalType == 'good_recording' ? 'correct' : 'wrong');
          await new Promise(resolve => setTimeout(resolve, 1200));
          await voicePlay();
        };
        onPlayEffectSoundPlayRef.current = async () => {
          await playEffectSound(
            modalType == 'correct_recording' || modalType == 'good_recording' ? 'correct' : 'wrong',
          );
        };
        onAnswerSoundPlayRef.current = async () => {
          await voicePlay();
        };
        onAnswerSoundStopRef.current = async () => {
          voiceStop();
        };

        // await new Promise(resolve => {
        //   audioRef.current.onended = resolve;
        //   audioRef.current.play();
        // });
      } else {
        onSoundPlayRef.current = async () => {
          playEffectSound(modalType == 'correct_recording' || modalType == 'good_recording' ? 'correct' : 'wrong');
          await new Promise(resolve => setTimeout(resolve, 1200));
          await voicePlay();
        };
        onPlayEffectSoundPlayRef.current = async () => {
          await playEffectSound(
            modalType == 'correct_recording' || modalType == 'good_recording' ? 'correct' : 'wrong',
          );
        };
        onAnswerSoundPlayRef.current = async () => {
          await voicePlay();
        };
        onAnswerSoundStopRef.current = async () => {
          voiceStop();
        };

        await voicePlay();
      }

      setLearningStateData(prevState => ({
        ...prevState,
        modal_disable: false,
      }));

      if (show_modal) {
        setShowReady(true);
      } else {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: true,
        }));

        setVisibleGrading(true);
      }
    } else {
      // if (audioRef.current.src) {
      //   await new Promise(resolve => {
      //     audioRef.current.onended = resolve;
      //     audioRef.current.play();
      //   });
      // } else {
      // }
      await voicePlay();

      setLearningStateData(prevState => ({
        ...prevState,
        modal_disable: false,
      }));
      goNextStep();
    }
  };

  useEffect(() => {
    if (!show_modal && showReady) {
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: true,
      }));
      setVisibleGrading(true);
      setShowReady(false);
    }
  }, [showReady, show_modal]);

  const saveRecBlob = () => {
    if (!rec_blob.current) {
      if (final_transcript.current !== '   ') console.log('error 1');
      else console.log('error 2');
      return;
    }
    const file = new FileReader();

    file.onerror = e => {
      window.console.error('error 3', e);
    };

    file.onloadend = async e => {
      const fd = new FormData();
      const filename = `${learningStateData.book_type}-${userStateData.id}-${learningStateData.schedule_id}-${learningStateData.record_id}-${current_step}`;
      if (file.result) {
        const audioBlob = new Blob([file.result], { type: 'audio/wav' });

        fd.append('data', audioBlob, filename);
      }
      const save_wav_res = await fetchFileApi(`/etc/speaking/wav`, fd);
    };

    file.readAsArrayBuffer(rec_blob.current);
  };

  const getScore = (res: string) => {
    console.log('getScore', res);
    if (isScoring.current) return false;
    isScoring.current = true;
    clearTimeout2();
    const result = getSentenceScore(res, rightanswerRef.current);
    const score = result.score;

    const is_included_red = result.html.includes(`<span class='c_red'>`);

    if (score >= 80) {
      if (is_included_red) {
        setModalType('good_recording');
      } else {
        setModalType('correct_recording');
      }
    } else if (score >= 40) {
      setModalType('good_recording');
    } else if (retryCnt > 0) {
      setModalType('fail_recording');
    } else {
      setModalType('wrong_recording');
    }

    if (res.length > 0) {
      res = res.charAt(0).toUpperCase() + res.slice(1);
    }

    setHtml(result.html);

    setAnswer(res);
    setReadyNext(true);
  };

  const clearTimeout1 = () => {
    for (let i = 0; i < settimeout1.current.length; i++) {
      clearTimeout(settimeout1.current[i]);
    }
    settimeout1.current = [];
  };

  const clearTimeout2 = () => {
    for (let i = 0; i < settimeout2.current.length; i++) {
      clearTimeout(settimeout2.current[i]);
    }
    settimeout2.current = [];
  };

  const clearTimeout3 = () => {
    for (let i = 0; i < settimeout3.current.length; i++) {
      clearTimeout(settimeout3.current[i]);
    }
    settimeout3.current = [];
  };

  const countDownInterval = useRef<ReturnType<typeof setInterval>[]>([]);
  const clearCountDownInterval = () => {
    for (let i = 0; i < countDownInterval.current.length; i++) {
      clearInterval(countDownInterval.current[i]);
    }
    countDownInterval.current = [];
  };

  const goNextStep = async (modal_type?: string) => {
    if (!isScoring.current) return false;
    isScoring.current = false;
    setNextDisable(false);
    if (modalType == 'fail_recording' && retryCnt > 0) {
      setVisibleGrading(false);
      setInit();
      setRetryCnt(retryCnt - 1);
      setHtml(null);
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: false,
      }));
      if (props.onClickReplayButton) {
        props.onClickReplayButton(true);
      }
    } else if (modal_type == 'good_recording') {
      // 재녹음 카운트 차감 없이 재시도

      setVisibleGrading(false);
      setInit();
      setHtml(null);
      setLearningStateData(prevState => ({
        ...prevState,
        show_modal: false,
      }));

      if (props.onClickReplayButton) {
        props.onClickReplayButton(true);
      }
    } else {
      goNextProcess();
    }
  };

  const goNextProcess = () => {
    setVisibleGrading(false);
    saveRecBlob();
    onSoundPlayRef.current = undefined;
    onPlayEffectSoundPlayRef.current = undefined;
    onAnswerSoundPlayRef.current = undefined;
    onAnswerSoundStopRef.current = undefined;
    const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheet));
    resultsheet_tmp[current_step] = {
      no: current_step + 1,
      sentence_id: content['sentence_id'],
      answer: answer,
      rightanswer: content['sentence'],
      question: content['translate'],
      type: 'sentence',
    };
    if (current_step == contents.length - 1) {
      setTimeout(() => {
        setLearningStateData(prevState => ({
          ...prevState,
          resultsheet: resultsheet_tmp,
        }));

        finishCallback(resultsheet_tmp);
      }, 0);
    } else {
      setTimeout(() => {
        setKeyboardStop(false);
      }, 1000);
      setTimeout(() => {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
          current_step: current_step + 1,
          resultsheet: resultsheet_tmp,
        }));
      }, 0);
    }
  };

  const onClickStop = () => {
    clearTimeout2();
    setNoSpeech(false);
    javascript_node.current.onaudioprocess = null;
    recstatus.current = false;
    recorder.current.stop();
    recognition.current.stop();
    checkstatus.current = false;
    setInit();
    setNextDisable(false);
    setWaitingCount(3);
  };

  // useEffect(() => {
  //   if (goResult) {
  //     finishCallback();
  //   }
  // }, [goResult]);

  const IconMic = () => {
    return (
      <svg
        width='120'
        height='120'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        viewBox='0 0 120 120'
      >
        <g>
          <g>
            <path
              d='m60,72.5l0,0c-10.625,0 -18.75,-8.125 -18.75,-18.75l0,-25c0,-10 8.125,-18.75 18.75,-18.75l0,0c10,0 18.75,8.125 18.75,18.75l0,25c0,10 -8.75,18.75 -18.75,18.75z'
              fill='#fff'
            />
            <path
              d='m85,41.25l0,15.625c0,11.875 -11.25,21.875 -23.75,21.875l-2.5,0c-12.5,0 -23.75,-10 -23.75,-21.875l0,-15.625c-3.75,0 -6.25,2.5 -6.25,6.25l0,9.375c0,13.75 11.25,25.625 25,27.5l0,13.125c-18.75,0 -15.625,12.5 -15.625,12.5l43.75,0c0,0 3.125,-12.5 -15.625,-12.5l0,-13.125c13.75,-2.5 25,-13.75 25,-27.5l0,-9.375c0,-3.75 -2.5,-6.25 -6.25,-6.25z'
              fill='#fff'
            />
          </g>
        </g>
      </svg>
    );
  };

  const onClickStatus = () => {
    window.navigator.permissions.query({ name: 'microphone' as PermissionName }).then((status: PermissionStatus) => {
      setStatus(status.state);
      if (status.state == 'granted') return;
      is_set.current = false;
      switch (status.state) {
        case 'prompt':
          permission.current = false;
          permission_init.current = true;
          setUserMedia();
          break;
        case 'denied':
          permission.current = false;
          closeTooltip();
          setModalManualVisible(true);
          break;
        default:
          break;
      }

      permissionStatus.current = status as PermissionStatus;
    });
  };

  const closeTooltip = () => {
    setOpenTooltip(false);
  };

  const onCloseModalManual = () => {
    setModalManualVisible(false);
  };

  const onCloseRetry = () => {
    goNextStep();
  };

  return (
    <StyledWebkitSpeechWrap>
      <Box
        sx={{
          width: canvWidth,
          height: canvHeight,
          position: 'absolute',
        }}
      >
        <Button
          sx={{
            backgroundColor:
              status == 'granted'
                ? '#00ea5b !important'
                : status == 'prompt'
                ? '#103c5b !important'
                : '#ff4337 !important',
            color: 'white !important',
            margin: '2vh',
            padding: '1vh 2vh',
            borderRadius: '1vh',
            zIndex: '30',
          }}
          onClick={() => onClickStatus()}
          disableRipple
        >
          <StyledTooltip title='클릭하여 마이크 설정을 완료해주세요.' placement='bottom-start' open={openTooltip}>
            <span>
              {status == 'granted' ? '마이크 연결 완료' : status == 'prompt' ? '마이크 최초 설정' : '마이크 설정 필요'}
            </span>
          </StyledTooltip>
        </Button>

        {noSpeech ? (
          <StopIconButton disableRipple onClick={onClickStop}>
            <FaSquareFull />
          </StopIconButton>
        ) : null}
      </Box>

      {nextDisable || visibleGrading ? (
        waitingCount > 0 && useTimer ? (
          <StyledCountButton disableRipple>
            <StyledCount>{waitingCount}</StyledCount>
            <PulseBox></PulseBox>
          </StyledCountButton>
        ) : null
      ) : (
        <ClickAwayListener onClickAway={closeTooltip}>
          <StyledMicButton onClick={onClickMicButton} disableRipple>
            {<IconMic />}
          </StyledMicButton>
        </ClickAwayListener>
      )}

      <canvas
        id='recode-canvas'
        width={canvWidth}
        height={canvHeight}
        style={{
          zIndex: '20',
          overflowClipMargin: 'content-box',
          overflow: 'clip',
          background: `linear-gradient(rgb(90, 180, 255 , 0.5), rgb(9, 10, 66, 0.5)), url(${canvas_bg}) repeat 10px 10px`,
          borderRadius: '1.2rem',
          aspectRatio: `auto ${canvWidth} / ${canvHeight}`,
          position: 'absolute',
        }}
      ></canvas>
      {visibleGrading ? (
        <ModalGrading
          onSoundPlay={onSoundPlayRef.current}
          onPlayEffectSoundPlay={onPlayEffectSoundPlayRef.current}
          onAnswerSoundPlay={onAnswerSoundPlayRef.current}
          onAnswerSoundStop={onAnswerSoundStopRef.current}
          visible={visibleGrading}
          onClose={(modal_type?: string) => goNextStep(modal_type)}
          rightanswer={rightanswer}
          answer={answer}
          question={question}
          content={content}
          type={modalType}
          max_record_cnt={1}
          record_cnt={retryCnt}
          isSpeak={true}
        />
      ) : null}
      <ModalManual visible={modalManualVisible} onClose={onCloseModalManual} />
    </StyledWebkitSpeechWrap>
  );
}

export default WebkitSpeechRecognition;
