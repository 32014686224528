import { NumberToEmoji } from 'components/common/NumberToEmoji';
import { Box, styled } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { modalManual2State, closeModalManual2 } from 'recoil/common/modalManual2';
import { deviceState } from 'recoil/common/device';
import { useCallback } from 'react';
import { BoxProps } from '@mui/system';

const Container = styled(Box)({
  background: 'rgba(0, 0, 0, 0.5)',
  zIndex: '1000',
  overflowX: 'hidden',
  overflowY: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  inset: '0',
  position: 'fixed',
  '&  *': {
    fontFamily: 'Apple SD Gothic Neo',
  },
});

interface MobileProps extends BoxProps {
  is_mobile: number;
}

const Wrapper = styled(Box)<MobileProps>(props => {
  if (props.is_mobile == 1) {
    return {
      minWidth: '18.75rem',
      backgroundColor: '#fff',
      borderRadius: '0.5rem',
      position: 'relative',
      top: '1.75rem',

      transform: 'scale(1.1)',

      '@media (min-width: 1266px) and (max-width: 1905px)': {
        transform: 'scale(1.6)',
      },

      '@media (orientation: portrait)': {
        '@media (min-width: 961px) and (max-width: 1264px)': {
          transform: 'scale(2.2)',
        },

        '@media (min-width: 961px) and (max-width: 1264px) and (max-height: 799px)': {
          transform: 'scale(1.4)',
        },

        '@media (min-width: 769px) and (max-width: 960px)': {
          transform: 'scale(2.5)',
        },

        '@media (min-width: 600px) and (max-width: 768px)': {
          transform: 'scale(2.5)',
        },

        '@media (min-width: 520px) and (max-width: 599px)': {
          transform: 'scale(1.5)',
        },

        '@media (min-width: 390px) and (max-width: 519px)': {
          transform: 'scale(1.5)',
        },

        '@media (max-width: 389px)': {
          transform: 'scale(1.4)',
        },
      },
    };
  } else {
    return {
      minWidth: '18.75rem',
      backgroundColor: '#fff',
      borderRadius: '0.5rem',
      position: 'relative',
      top: '1.75rem',

      transform: 'scale(1.1)',

      '@media (min-width: 1266px) and (max-width: 1905px)': {
        transform: 'scale(1.6)',
      },

      '@media (min-width: 962px) and (max-width: 1265px)': {
        transform: 'scale(1.4)',
      },

      '@media (min-width: 770px) and (max-width: 961px)': {
        transform: 'scale(1.35)',
      },

      '@media (max-width: 769px)': {
        transform: 'scale(1.15)',
      },
    };
  }
});

const TopWrap = styled(Box)({
  padding: '1.5rem',
});

const Title = styled('p')({
  color: 'rgb(75, 85, 99)',
  fontWeight: '600',
  marginBottom: '0.25rem',
});

const SubTitle = styled('p')({
  color: 'rgb(156, 163, 175)',
  fontWeight: '600',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  cursor: 'pointer',
  marginTop: '0.75rem',
  marginBottom: '1rem',
});

const ContentBox = styled(Box)({
  width: '18.75rem',
  background: 'rgb(249, 246, 255)',
  padding: '1rem',
  borderRadius: '0.5rem',
  marginBottom: '0.5rem',
  '&:last-of-type': {
    marginBottom: '0',
  },
  '& > div': {
    display: 'flex',
    marginBottom: '0.25rem',
    '& > span': {
      '&:last-of-type': {
        color: 'rgba(83, 21, 255, 0.8)',
        fontWeight: '600',
        textAlign: 'center',
        marginLeft: '0.25rem',
      },
    },
  },
  '& > p': {
    color: 'rgb(156, 163, 175)',
    fontWeight: '300',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
});

const BottomWrap = styled(Box)({
  display: 'flex',
  height: '3.125rem',
  borderBottomLeftRadius: '0.5rem',
  borderBottomRightRadius: '0.5rem',
});

const BoxButton = styled(Box)({
  position: 'relative',
  width: '50%',
  background: 'rgb(240, 240, 240)',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
});

export function ModalManual2() {
  const [modalManual2, setModalManual2] = useRecoilState(modalManual2State);
  const { title, subTitle, contents, callback, buttonLabel } = modalManual2;
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { is_mobile } = deviceStateData;

  const onClose = useCallback(() => {
    closeModalManual2({ setModalManual2 });
  }, []);

  if (modalManual2.visible)
    return (
      <Container>
        <Wrapper is_mobile={is_mobile ? 1 : 0}>
          <TopWrap>
            <Title>{title}</Title>
            <SubTitle>{subTitle}</SubTitle>
            {contents.map((item, idx) => {
              return (
                <ContentBox key={item.title}>
                  <div>
                    <NumberToEmoji word={`${idx + 1}`} colorCode='#5c3dff' />
                    <span>{item.title}</span>
                  </div>
                  {item.content.map(content => {
                    return <p key={content}>{content}</p>;
                  })}
                </ContentBox>
              );
            })}
          </TopWrap>
          <BottomWrap>
            <BoxButton sx={{ borderBottomLeftRadius: '0.5rem' }} onClick={onClose}>
              <p style={{ color: 'rgb(156, 163, 175)', fontWeight: '700' }}>닫기</p>
            </BoxButton>
            <BoxButton sx={{ background: 'rgb(90, 20, 219)', borderBottomRightRadius: '0.5rem' }} onClick={callback}>
              <p style={{ color: '#fff', fontWeight: '700' }}>{buttonLabel}</p>
            </BoxButton>
          </BottomWrap>
        </Wrapper>
      </Container>
    );
  else return null;
}
