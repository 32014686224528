import { Box, Checkbox } from '@mui/material';
import React, { Fragment } from 'react';
import {
  ContentWrap,
  ContentsBox,
  Footer,
  Header,
  SwitchBox,
  WordBox,
  WordDeleteButton,
  Wrapper,
  Line,
} from './PrintWordlist.Styles';
import { SwitchContainer } from 'components/common/Switch/Switch.Container';

export interface PrintWordlistVAProps {
  wordCount: number;
  checkCount: number;
  printBookList: PrintBookListType;
  duplicate: boolean;
  onChangeDuplicate: () => void;
  onClickCheckbox: (
    e: React.MouseEvent<HTMLDivElement>,
    active: boolean,
    book_id: number,
    unit_id: number,
    word_id: number | string,
    seq: number,
    idx?: number,
  ) => void;
  onClickWordDelete: () => void;
  printMethod?: string;
}

export const PrintWordlistUI = React.memo(function PrintWordlistUI({
  wordCount,
  checkCount,
  printBookList,
  duplicate,
  onChangeDuplicate,
  onClickCheckbox,
  onClickWordDelete,
  printMethod,
}: PrintWordlistVAProps) {
  return (
    <Wrapper>
      <Header>
        <Box>
          <Box />
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box component={'span'}>
              {printMethod && printMethod == 'wrongSentence' ? '문장' : '단어'} 목록&nbsp;
              <Box component={'b'} style={{ color: '#f44336' }}>
                {wordCount}
              </Box>
            </Box>
          </Box>
          <SwitchBox>
            <SwitchContainer checked={duplicate} onChange={onChangeDuplicate} small={true} />
            <Box
              component={'span'}
              onClick={onChangeDuplicate}
              sx={{
                cursor: 'pointer',
                marginRight: '0.25rem',
              }}
            >
              &nbsp;&nbsp;중복&nbsp;&nbsp;
              <Box component={'b'}></Box>
            </Box>
          </SwitchBox>
        </Box>
        <Line />
      </Header>
      <ContentWrap>
        <Box>
          {Object.values(printBookList).map((printBook: PrintBookType, idx1: number) => (
            <Fragment key={`books_${printBook.id}_${idx1}`}>
              {Object.values(printBook.units).map((printUnit: PrintUnitType, idx2: number) => (
                <Fragment key={`units_${printUnit.id}_${idx2}`}>
                  {printUnit.words.map((word: PrintWordType, idx3: number) => (
                    <ContentsBox
                      onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                        onClickCheckbox(
                          e,
                          word.active ? true : false,
                          printBook.id,
                          printUnit.id,
                          word.id,
                          word.seq,
                          word.idx,
                        )
                      }
                      active={`${word.active}`}
                      key={`words_${word.id}_${idx3}`}
                    >
                      <Checkbox sx={{ position: 'absolute' }} checked={word.active ? true : false} color='primary' />
                      <WordBox>{word.spell}</WordBox>
                    </ContentsBox>
                  ))}
                </Fragment>
              ))}
            </Fragment>
          ))}
        </Box>
      </ContentWrap>
      <Footer>
        <WordDeleteButton onClick={onClickWordDelete} disabled={checkCount > 0 ? false : true}>
          목록 삭제
        </WordDeleteButton>
      </Footer>
    </Wrapper>
  );
});
