import { ButtonGroup, Button } from './ButtonGroup.Styles';
import { Box, ButtonProps } from '@mui/material';
import React from 'react';
import theme from '../../../theme';

export interface RequiredProps {
  contents: { id: number | string; label: string; check: boolean; onClick?: () => void; count?: number }[];
  onCheck: (id: number | string, contentsKey?: string) => void;
  disabled?: boolean;
}

export interface UnrequiredProps {
  checkColor: ButtonProps['color'];
  unCheckColor: ButtonProps['color'];
  offColor?: ButtonProps['color'];
  isForm?: boolean;
}

interface ButtonGroupVAProps extends RequiredProps, UnrequiredProps {
  contentsKey?: string;
}

export const ButtonGroupUI = React.memo(function ButtonGroupUI({
  contents,
  onCheck,
  checkColor,
  unCheckColor,
  offColor,
  contentsKey,
  disabled,
}: ButtonGroupVAProps) {
  return (
    <ButtonGroup>
      {contents.map((content, cIdx) => {
        return (
          <Button
            disabled={disabled}
            key={`button_group_${content.id}`}
            className={content.check ? 'check-o' : 'check-x'}
            variant={content.check ? 'contained' : 'outlined'}
            checkcolor={checkColor}
            color={
              content.check ? (contents.length === 2 && offColor && cIdx === 1 ? offColor : checkColor) : unCheckColor
            }
            onClick={() => {
              onCheck(content.id, contentsKey);
              if (content.onClick) {
                content.onClick();
              }
            }}
            sx={{
              width: `calc(${100 / contents.length}%)`,
              '&:active, &:hover': {
                border:
                  content.check && checkColor == 'purple_4'
                    ? `1px solid ${theme.palette.purple_4.contrastText} !important`
                    : 'none',
              },
              border:
                content.check && checkColor == 'purple_4'
                  ? `1px solid ${theme.palette.purple_4.contrastText} !important`
                  : 'none',
              '& + button': {
                borderLeftColor:
                  content.check && checkColor == 'purple_4' ? `${theme.palette.purple_4.contrastText} !important` : '',
              },
              fontWeight: content.check ? '500' : '',
              '&.Mui-disabled.check-o': {
                backgroundColor: 'rgb(239,239,239)',
                color: '#a4a4a4',
              },
              '&.Mui-disabled.check-x': {
                color: '#a4a4a4',
              },
            }}
          >
            {content.label}
            {content.count !== undefined ? (
              content.check ? (
                <Box component={'span'} sx={{ color: 'rgb(255, 255, 81)' }}>
                  &nbsp;{content.count}명
                </Box>
              ) : (
                <Box component={'span'} sx={{ color: '#FFA500' }}>
                  &nbsp;{content.count}명
                </Box>
              )
            ) : null}
          </Button>
        );
      })}
    </ButtonGroup>
  );
});
