import {
  styled,
  Box,
  Tooltip as MuiTooltip,
  tooltipClasses,
  TooltipProps,
  BoxProps,
  Modal as MuiModal,
} from '@mui/material';

export const Modal = styled(MuiModal)({
  backgroundColor: 'rgba(33, 33, 33, 0.46)',
  transition: '0.2s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms !important',
  '& > .MuiBox-root': {
    '&:focus-visible': {
      outline: 'none',
    },
  },
});

export const Wrapper = styled(Box)({
  borderRadius: '4px',
  overflow: 'hidden',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  boxShadow: 'none',
});
export const Wrap = styled(Box)(props => ({
  height: 'auto',
  borderRadius: '1.5rem',
  overflow: 'hidden',
  backgroundColor: 'transparent',
}));

export const Header = styled(Box)(props => ({
  width: '100%',
  height: '4rem',
  padding: '0 1rem',
  fontSize: '1.2rem',
  background: props.theme.palette.purple.main,
  color: props.theme.palette.white.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '.sub-title': {
    fontSize: '0.875rem',
    marginLeft: '0.25rem',
  },
}));

export const HeaderIconWrap = styled(Box)(props => ({
  right: '1rem',
  top: '1rem',
  position: 'absolute',
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: props.theme.palette.white.main,
  color: props.theme.palette.purple.main,
  svg: {
    width: '1.7rem',
    height: '1.7rem',
    strokeWidth: 30,
    marginRight: '-1px',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Body = styled(Box)(props => ({
  width: '40rem',
  height: '20rem',
  backgroundColor: props.theme.palette.white.main,
  display: 'flex',
  padding: '2.75rem',
}));

export const SelectBox = styled(Box)({
  width: '100%',
  padding: '1.3rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& > div': { width: '100%', cursor: 'default' },
});

export const SelectWordCounter = styled(Box)(props => ({
  display: 'inline-block',
  borderRadius: '1rem',
  backgroundColor: '#f2f3f5',
  padding: '0.35rem 1rem',
  '& > span': {
    color: props.theme.palette.purple.main,
    fontWeight: 'bold',
  },
}));

export const RemainWordCounter = styled(Box)(props => ({
  display: 'inline-block',
  padding: '0.35rem 1rem',
  color: props.theme.palette.grey[500],
  fontWeight: 'bold',
}));

interface CounterInputBoxProps extends BoxProps {
  disabled: boolean;
}

export const CounterInputBox = styled(Box)<CounterInputBoxProps>(props => ({
  '& > input': {
    width: '4rem',
    border: props.disabled ? '1px solid #c9c9c9' : '1px solid #9c9c9c',
    borderRadius: 0,
    lineHeight: 2.5,
    textAlign: 'right',
    paddingRight: '0.6rem',
    '&:focus-visible': {
      outline: 'none',
    },
  },
}));

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow classes={{ popper: className }} disableHoverListener />
))(({ placement }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#3f3f3f',
    left: placement === 'top-start' ? '1.5rem !important' : '0px !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    cursor: 'default',
    backgroundColor: '#3f3f3f',
    fontSize: '1rem',
    maxWidth: 'unset',
  },
}));

export const Footer = styled(Box)(props => ({
  width: '100%',
  height: '3.75rem',
  backgroundColor: props.theme.palette.white.main,
  display: 'flex',
  '& > button': {
    width: '100%',
    height: '100%',
  },
}));
