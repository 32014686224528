import { atom, SetterOrUpdater } from 'recoil';

export interface ModalBillType {
  visible: boolean;
}

export const modalBillState = atom<ModalBillType>({
  key: 'modalBill',
  default: {
    visible: false,
  },
});

interface ModalBillSetter {
  setModalBill: SetterOrUpdater<ModalBillType>;
}

export const openModalBill = ({ setModalBill }: ModalBillSetter) => {
  setModalBill(prev => ({
    ...prev,
    visible: true,
  }));
};

export const closeModalBill = ({ setModalBill }: ModalBillSetter) => {
  setModalBill(prev => ({
    ...prev,
    visible: false,
  }));
};
