import { atom, SetterOrUpdater } from 'recoil';
import { ProfileType } from './profiles';

export interface ModalInputPinType {
  visible: boolean;
  step: number;
  profile: ProfileType | null;
}

export const modalInputPinState = atom<ModalInputPinType>({
  key: 'modalInputPin',
  default: {
    visible: false,
    step: 1,
    profile: null,
  },
});

interface ModalInputPinSetter {
  setModalInputPin: SetterOrUpdater<ModalInputPinType>;
}

export const openModalInputPin = (
  { setModalInputPin }: ModalInputPinSetter,
  options: Omit<ModalInputPinType, 'visible' | 'step'>,
) => {
  setModalInputPin(prev => ({
    ...prev,
    visible: true,
    ...options,
  }));
};

export const closeModalInputPin = ({ setModalInputPin }: ModalInputPinSetter) => {
  setModalInputPin(prev => ({
    ...prev,
    visible: false,
    step: 1,
    profile: null,
  }));
};
