import { atom, SetterOrUpdater } from 'recoil';

export interface ModalTestPrintSettingDataType {
  visible: boolean;
  wordCount: number;
  onSuccess: (setting: TestPrintSettingType) => void;
}

export const modalTestPrintSettingData = atom<ModalTestPrintSettingDataType>({
  key: 'modalTestPrintSetting',
  default: {
    visible: false,
    wordCount: 0,
    onSuccess: (setting: TestPrintSettingType) => {
      return;
    },
  },
});

interface ModalTestPrintSettingSetter {
  setModalTestPrintSetting: SetterOrUpdater<ModalTestPrintSettingDataType>;
}

export const openModalTestPrintSetting = (
  { setModalTestPrintSetting }: ModalTestPrintSettingSetter,
  wordCount: number,
  onSuccess: (setting: TestPrintSettingType) => void,
) => {
  setModalTestPrintSetting({
    visible: true,
    wordCount,
    onSuccess,
  });
};

export const closeModalTestPrintSetting = ({ setModalTestPrintSetting }: ModalTestPrintSettingSetter) => {
  setModalTestPrintSetting({
    visible: false,
    wordCount: 0,
    onSuccess: (setting: TestPrintSettingType) => {
      return;
    },
  });
};
