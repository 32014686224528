import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { PrintUI } from './Print.Presenter';
import { useReactToPrint } from 'react-to-print';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userState, getPrintCount } from 'recoil/model/user';
import { fetchPostApi } from 'utils/api';
import { ModalContext } from 'provider/ModalProvider';
import { P_SPEAK_PRODUCT_CODES } from 'utils/constants';

interface PrintContainerProps {
  printBookList: PrintBookListType;
  type: number;
  onClickBackButton: () => void;
  printMethod?: string;
}

export const PrintContainer = React.memo(function PrintContainer({
  printBookList,
  type,
  onClickBackButton,
  printMethod,
}: PrintContainerProps) {
  const { modal_confirm, modal_alert } = useContext(ModalContext);
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const [originalWordList, setOriginalWordList] = useState<PrintBookListType>(printBookList);
  const [printWordList, setPrintWordList] = useState<PrintBookListType>({});
  const [title, setTitle] = useState('WORD LIST');
  const [printSetting, setPrintSetting] = useState<PrintSettingType>({
    lang: 0,
    sort: 0,
    fontsize: 1,
    line: 1,
    column: type == 2 ? 0 : printMethod == 'wrongSentence' ? 0 : 1,
    repeat: 1,
    width: 50,
  });
  const [printInfo, setPrintInfo] = useState<PrintInfoType>({
    logo: true,
    pagenum: true,
    cname: true,
  });
  const [duplicate, setDuplicate] = useState(false);
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const printRef = useRef<any>(null);

  useEffect(() => {
    if (printMethod === 'wrongWord') {
      setTitle('WORD TEST');
    } else if (printMethod === 'wrongSentence') {
      setTitle('SENTENCE TEST');
    } else {
      setTitle('WORD LIST');
    }
  }, [printMethod]);

  useEffect(() => {
    if (duplicate) {
      const updatedPrintWordList = JSON.parse(JSON.stringify(originalWordList)) as PrintBookListType;

      for (const printBookKey in updatedPrintWordList) {
        const printBook = updatedPrintWordList[printBookKey];
        for (const unitKey in printBook.units) {
          const unit = printBook.units[unitKey];
          const uniqueWords: PrintWordType[] = [];

          unit.words.forEach(word => {
            const isDuplicate = uniqueWords.some(uniqueWord => uniqueWord.spell === word.spell);
            if (!isDuplicate) {
              uniqueWords.push(word);
            }
          });

          unit.words = uniqueWords;
        }
      }
      setPrintWordList(updatedPrintWordList);
    } else {
      setPrintWordList(JSON.parse(JSON.stringify(printBookList)) as PrintBookListType);
    }
  }, [originalWordList, duplicate]);

  const endLoading = useCallback(() => {
    setDuplicateLoading(false);
  }, []);

  const onClickWordPrint = useCallback(async () => {
    if (userStateData.product == 'F' || (userStateData.isJoinEscape && userStateData.enddate != '')) {
      const printCount = await getPrintCount(userStateData, setUserStateData);
      if (printCount < 1) {
        modal_alert.openModalAlert('free_print_exhausted');
      } else {
        if (printRef.current) {
          handlePrint();
        }
      }
    } else {
      // ! 매뉴얼 얼럿 처리 필요
      // 개인 유료로객 (실전회화) 계정은 교재출력만 안되게 세팅!!
      if (userStateData.center_type == 'B' && P_SPEAK_PRODUCT_CODES.includes(userStateData.product)) {
        if (!(printMethod && ['wrongWord', 'wrongSentence'].includes(printMethod))) {
          // openManualModalAlert({ setManualModalAlert });
          return;
        }
      }

      if (printRef.current) {
        handlePrint();
      }
    }
  }, [printWordList, title]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    removeAfterPrint: true,
    documentTitle: title,
    onAfterPrint: async () => {
      // 여기서 프린트 횟수 차감하는 api 요청
      try {
        if (userStateData.product == 'F' || (userStateData.isJoinEscape && userStateData.enddate != '')) {
          const minus_print_count_uri = `/customers/${userStateData.customer_id}/prints`;
          const res = await fetchPostApi(minus_print_count_uri, {});
          if (res.result) {
            let rest_print_count = await getPrintCount(userStateData, setUserStateData);
            if (rest_print_count == 0) {
              rest_print_count = '0';
            }
            modal_alert.openModalAlert('rest_print_count', undefined, rest_print_count);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const onChangePrintSetting = useCallback(
    (key: string, value: number) => {
      if (printSetting[key] != value) {
        setPrintSetting({
          ...printSetting,
          [key]: value,
        });
        if (key == 'column' && value == 2) {
          modal_alert.openModalAlert('use_three_print');
        }
      }
    },
    [printSetting],
  );

  const onChangePrintInfo = useCallback(
    (key: string, value: boolean) => {
      console.log(printInfo, key, value);
      if (printInfo[key] != value) {
        setPrintInfo({
          ...printInfo,
          [key]: value,
        });
      }
    },
    [printInfo],
  );

  const onChangeTitle = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 20) {
      setTitle(e.target.value);
    }
  }, []);

  const onChangeDuplicate = useCallback(() => {
    if (!duplicate) {
      modal_confirm.openModalConfirm('normal', 'print_delete_duplicate', () => {
        let removedCount = 0;
        for (const printBookKey in printWordList) {
          const printBook = printWordList[printBookKey];
          for (const unitKey in printBook.units) {
            const unit = printBook.units[unitKey];
            const uniqueWords: PrintWordType[] = [];

            // words 배열에서 중복을 제거하여 uniqueWords 배열에 단어 추가
            unit.words.forEach(word => {
              const isDuplicate = uniqueWords.some(uniqueWord => uniqueWord.spell === word.spell);
              if (!isDuplicate) {
                uniqueWords.push(word);
              }
            });

            removedCount += unit.words.length - uniqueWords.length;

            unit.words = uniqueWords;
          }
        }

        let isDuplicate = false;
        if (removedCount > 0) {
          modal_alert.openModalAlert('success_deduplication', undefined, `${removedCount}`);
          isDuplicate = true;
        } else {
          modal_alert.openModalAlert('none_duplicate_content');
        }
        setDuplicate(isDuplicate);
        setDuplicateLoading(isDuplicate);
      });
    } else {
      modal_confirm.openModalConfirm('normal', 'not_deduplicate', () => {
        setDuplicate(false);
        setDuplicateLoading(true);
      });
    }
  }, [printWordList, duplicate]);

  const onChangeCommitted = useCallback(
    (event: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => {
      const cur_width = printSetting.width;

      if (typeof value == 'number' && value !== cur_width) {
        setPrintSetting(prev => ({
          ...prev,
          width: value,
        }));
      }
    },
    [printSetting],
  );

  const props = {
    duplicate,
    originalWordList,
    printWordList,
    type,
    title,
    printSetting,
    printInfo,
    printRef,
    setOriginalWordList,
    duplicateLoading,
    onChangeDuplicate,
    onClickBackButton,
    onClickWordPrint,
    onChangePrintSetting,
    onChangePrintInfo,
    onChangeTitle,
    endLoading,
    printMethod,
    onChangeCommitted,
  };

  return <PrintUI {...props} />;
});
