import { useState, useEffect, useRef, useContext } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { learningState } from '../../recoil/model/learning';
import { loadingState, setPercent } from '../../recoil/common/loading';
import { useVoice, voiceState } from '../../recoil/common/voice';
import { settingState } from '../../recoil/model/settings';
import { userState } from '../../recoil/model/user';

import { ModalContext } from '../../provider/ModalProvider';

import { styled, Box, BoxProps, IconButton, LinearProgress, linearProgressClasses } from '@mui/material';

import ModalGrading from '../../components/modal/ModalGrading';

import { fetchGetApi } from '../../utils/api';

import { options } from '../../utils/setting_contents';
import { IMAGE_DIR } from '../../utils/constants';
import { CDN } from '../../utils/constants';
import { dir_column, d_flex, d_flex_center, d_flex_start } from '../../styles/common';
import { getContentFoldername, arrShuffle, makeEndModuleModalProps } from '../../utils/tools';

import { AiOutlineRight } from 'react-icons/ai';
import AutoText, { AutoTextProps } from '../../components/text/AutoText';
import ModalEndModule from '../../components/modal/ModalEndModule';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';
import { deviceState } from '../../recoil/common/device';
import { tutorialStateData, openTutorial } from '../../recoil/common/tutorial';

interface PopquizProps {
  sendResultsheet: () => void;
}

interface PopquizBoxProps extends BoxProps {
  status: 'selected' | 'right' | 'wrong' | '';
}

const StyledPopquizWrap = styled(Box)(props => ({
  width: '100%',
  height: 'calc(100% - 5rem)',
  position: 'relative',
  backgroundColor: 'white',
  ...d_flex_center,
  ...dir_column,
  '& *': { fontFamily: "'Apple SD Gothic Neo'", lineHeight: '1.20' },
}));

const StyledIconButton = styled(IconButton)(props => ({
  position: 'absolute',
  top: 'calc((100% - 4rem) / 2)',
  transform: 'translateY(-50%)',
  backgroundColor: 'white',
  width: '3rem',
  height: '3rem',
  padding: '0',
  svg: {
    color: '#b3b7bf',
    width: '2.5rem',
    height: '2.5rem',
    strokeWidth: '4rem',
  },
  '&.Mui-disabled > svg': {
    color: '#e8e9ec',
  },
}));

const StyledPopquizBody = styled(Box)(props => ({
  height: 'calc(100% - 4rem)',
  width: '100%',
  ...d_flex,
}));

const StyledQuestionWrap = styled(Box)(props => ({
  width: '50%',
  height: '100%',
  ...d_flex_center,
}));

const StyledQuestionAutoText = styled(AutoText)(props => ({
  fontWeight: '700',
  textAlign: 'center',
  color: props.theme.palette.purple.main,
}));

const StyledQuestionImageBox = styled(Box)(props => ({
  ...d_flex_start,
  ...dir_column,
}));

const StyledSelectionWrap = styled(Box)(props => ({
  width: '50%',
  height: '100%',
  backgroundColor: '#fcfaff',
  ...d_flex_center,
  ...dir_column,
}));

const StyledSelectionBox = styled(Box)<PopquizBoxProps>(props => ({
  width: '70%',
  height: '9vh',
  margin: '2vh auto',
  cursor: 'pointer',
  backgroundColor:
    props.status == 'selected'
      ? '#daffd7'
      : props.status == 'wrong'
      ? '#ffe9e5'
      : props.status == 'right'
      ? '#caf9ff'
      : props.theme.palette.white.main,
  border:
    props.status == 'selected'
      ? '1px solid #b6eabb'
      : props.status == 'wrong'
      ? '1px solid #ffd5d1'
      : props.status == 'right'
      ? '1px solid #8abdde'
      : '1px solid #e8dcfe',
  borderRadius: '1rem',
  ...d_flex_center,
}));

interface SelectionAutoTextProps extends AutoTextProps {
  status: 'selected' | 'right' | 'wrong' | '';
}

const StyledSelectionAutoText = styled(AutoText)<SelectionAutoTextProps>(props => ({
  width: '90%',
  fontWeight: '700',
  color:
    props.status == 'selected'
      ? '#0fd527'
      : props.status == 'wrong'
      ? '#ff4c39'
      : props.status == 'right'
      ? '#2973d0'
      : props.theme.palette.purple.main,
}));

const StyledPopquizBottom = styled(Box)(props => ({
  width: '96%',
  height: '4rem',
  borderTop: '1px solid #e0e3e5',
  ...d_flex_center,
  '@media(max-width: 1023px)': {
    borderColor: '#f5f5f5',
  },
}));

const StyledLinearProgress = styled(LinearProgress)(props => ({
  height: '2rem',
  width: '100%',
  borderRadius: '2rem',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#f5f5f5',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '2rem',
    transition: props.value == 100 ? 'none' : 'transform .2s linear',
    backgroundImage: props.value ? `linear-gradient(to right, #1fda72 ${100 - props.value}%, #39d5f3 100%)` : null,
  },
}));

function Popquiz(props: PopquizProps) {
  const sendResultsheet = props.sendResultsheet;
  const setTutorialState = useSetRecoilState(tutorialStateData);

  const { modal_alert } = useContext(ModalContext);

  const { playEffectSound } = useContext(EffectSoundContext);

  const userStateData = useRecoilValue<UserType>(userState);
  const { center_type } = userStateData;

  const [learningStateData, setLearningStateData] = useRecoilState<LearningType>(learningState);
  const {
    mod,
    current_step,
    contents,
    current_page,
    show_modal,
    resultsheet,
    current_module_settings,
    use_image,
    learning_type,
  } = learningStateData;

  const settingStateData = useRecoilValue<SettingsType>(settingState);
  const { enable_keyboard, check_type } = settingStateData;

  const [loadingStateData, setLoadingStateData] = useRecoilState<LoadingType>(loadingState);
  const { percent } = loadingStateData;

  const deviceStateData = useRecoilValue(deviceState);
  const { device_pause } = deviceStateData;

  const setVoiceStateData = useSetRecoilState<VoiceType>(voiceState);
  const { setVoice, voicePlay, voiceStop } = useVoice();
  const { popquiz } = current_module_settings;
  const maxRetry = popquiz.retry;
  const [retryCnt, setRetryCnt] = useState<number>(maxRetry);
  const timeout = Number(options['popquiz']['time'][popquiz.time].value);

  const [type, setType] = useState<number>(0); // 0: 한->영, 1: 영->한
  const [allContents, setAllContents] = useState<ContentType[]>([]);
  const [content, setContent] = useState<ContentType>(contents[current_step]);
  const [rightanswer, setRightanswer] = useState<number>(0);
  const [question, setQuestion] = useState<string>('');
  const [choices, setChoices] = useState<string[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string>('');
  const [imgReady, setImgReady] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [wrongIndex, setWrongIndex] = useState<number | null>(null);

  const settimeout1 = useRef<ReturnType<typeof setTimeout>[]>([]);

  const [right, setRight] = useState<boolean>(false);
  const [next, setNext] = useState<boolean>(false);

  const [visibleGrading, setVisibleGrading] = useState<boolean>(false);

  const [progress, setProgress] = useState<number>(100);

  const [endModuleModal, setEndModuleModal] = useState<{
    visible: boolean;
    icon: JSX.Element;
    label: string;
    kor_label: string;
    score: number;
  }>({
    visible: false,
    icon: <></>,
    label: '',
    kor_label: '',
    score: 0,
  });

  const nextCallback = () => {
    sendResultsheet();
  };

  const [keyboardStop, setKeyboardStop] = useState(false);

  const onSoundPlayRef = useRef<() => Promise<void>>();
  const onPlayEffectSoundPlayRef = useRef<() => Promise<void>>();
  const onAnswerSoundPlayRef = useRef<() => Promise<void>>();
  const onAnswerSoundStopRef = useRef<() => Promise<void>>();

  useEffect(() => {
    getAllContents();

    if (mod[current_page].resultsheet && mod[current_page].resultsheet != undefined) {
      const resultsheet_tmp = mod[current_page].resultsheet as ResultsheetType[];
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: resultsheet_tmp,
      }));
    } else {
      setLearningStateData(prevState => ({
        ...prevState,
        resultsheet: [],
      }));
    }

    setPercent(setLoadingStateData, 100);

    return () => {
      unbindKeyboard();
    };
  }, []);

  useEffect(() => {
    if (next) {
      goNext();
    }
  }, [next]);

  useEffect(() => {
    if (enable_keyboard) bindKeyboard();
    return () => {
      if (enable_keyboard) unbindKeyboard();
    };
  }, [show_modal, choices, selectedIndex, retryCnt, visibleGrading, keyboardStop]);

  useEffect(() => {
    if (show_modal || !loaded || endModuleModal.visible || device_pause) return;
    if (timeout > 0) {
      if (progress > 0) {
        const tick = setTimeout(() => {
          setProgress(progress - 1);
        }, timeout * 10);

        return () => clearTimeout(tick);
      } else {
        const tick = setTimeout(() => {
          setNext(true);
        }, 200 + timeout * 10);

        return () => clearTimeout(tick);
      }
    }
  }, [show_modal, progress, endModuleModal, device_pause]);

  useEffect(() => {
    if (percent == 100) {
      if (userStateData.show_tutorial && userStateData.show_tutorial.basic && learning_type != 5) {
        //  튜토리얼을 진행해야 한다면
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          openTutorial({ setTutorialState }, { reopen: false });
        }, 275);
        setTimeout(() => {
          setLoaded(true);
        }, 400);
      } else {
        setTimeout(() => {
          setLearningStateData(prevState => ({
            ...prevState,
            show_modal: true,
          }));
          modal_alert.openModalAlert('popquiz_start', undefined, type == 0 ? '단어를 선택' : '뜻을 선택', () => {
            setLearningStateData(prevState => ({
              ...prevState,
              show_modal: false,
            }));
          });
          setLoaded(true);
        }, 400);
      }
    }
  }, [percent]);

  useEffect(() => {
    setVisibleGrading(false);
    setContent(contents[current_step]);
  }, [current_step]);

  useEffect(() => {
    setVoiceStateData(() => ({
      element: undefined,
      playing: false,
      loading: false,
      error: false,
      ready: false,
      volume: 1,
    }));
    setChoice();
    setQuestions();
  }, [content, loaded]);

  useEffect(() => {
    setChoice();
  }, [allContents]);

  useEffect(() => {
    imgCheck();
  }, [imgSrc]);

  const bindKeyboard = () => {
    document.addEventListener('keydown', keyboardDownEvent);
  };

  const unbindKeyboard = () => {
    document.removeEventListener('keydown', keyboardDownEvent);
  };

  const keyboardDownEvent = (e: KeyboardEvent) => {
    if (!show_modal) {
      if (e.keyCode == 49 || e.keyCode == 97) {
        setSelectedIndex(0);
      } else if (e.keyCode == 50 || e.keyCode == 98) {
        setSelectedIndex(1);
      } else if (e.keyCode == 51 || e.keyCode == 99) {
        setSelectedIndex(2);
      } else if (e.keyCode == 52 || e.keyCode == 100) {
        setSelectedIndex(3);
      } else if (e.keyCode == 13 && !keyboardStop) {
        goNext();
      }
    }
  };

  const setChoice = () => {
    let tmp_choices: string[] = [];
    if (type == 0) {
      tmp_choices.push(content['spell']);

      let cnt = 0;
      const all_leng = allContents.length;
      let ran_num = 0;
      while (tmp_choices.length < 4) {
        ran_num = Math.floor(Math.random() * all_leng);
        if (allContents[ran_num]) {
          const tmp_choice = allContents[ran_num]['spell'];
          const tmp_answer = allContents[ran_num]['mean'];
          const trim_choice = tmp_choice.trim();
          if (
            !tmp_choices.includes(tmp_choice) &&
            !tmp_choices.includes(trim_choice) &&
            content['mean'] != tmp_answer
          ) {
            tmp_choices.push(tmp_choice);
          }
        }

        if (cnt > 1000) break;
        cnt += 1;
      }
    } else if (type == 1) {
      tmp_choices.push(content['mean']);

      let cnt = 0;
      const all_leng = allContents.length;
      let ran_num = 0;
      while (tmp_choices.length < 4) {
        ran_num = Math.floor(Math.random() * all_leng);
        if (allContents[ran_num]) {
          const tmp_choice = allContents[ran_num]['mean'];
          const tmp_answer = allContents[ran_num]['spell'];
          const trim_choice = tmp_choice.trim();
          if (
            !tmp_choices.includes(tmp_choice) &&
            !tmp_choices.includes(trim_choice) &&
            content['spell'] != tmp_answer
          ) {
            tmp_choices.push(tmp_choice);
          }
        }

        if (cnt > 1000) break;
        cnt += 1;
      }
    }

    tmp_choices = arrShuffle(tmp_choices);

    setRightanswer(
      type == 0
        ? tmp_choices.findIndex(choice => choice == content['spell']) + 1
        : tmp_choices.findIndex(choice => choice == content['mean']) + 1,
    );
    setVisibleGrading(false);
    setChoices([...tmp_choices]);
  };

  const setQuestions = () => {
    const voice = content['voice'] ? content['voice'] : '0.mp3';
    const image = content['image'] ? content['image'] : '0.jpg';
    const foldername = getContentFoldername(content['spell']);
    setVoice(content['spell'], voice);
    if (use_image == 1) setImgSrc(`${CDN}${IMAGE_DIR}${foldername}/${image}`);

    if (type == 0) {
      setQuestion(content['mean']);
    } else if (type == 1) {
      setQuestion(content['spell']);
    }
  };

  const getAllContents = async () => {
    const book_type =
      (userStateData.type < 20 || center_type == 'C') && learningStateData.book_type == 'schedules'
        ? 'schedules'
        : 'books';
    const all_contents =
      book_type == 'schedules'
        ? await fetchGetApi(
            `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/${learningStateData.schedule_id}/words`,
          )
        : await fetchGetApi(`/customers/${userStateData.customer_id}/books/${learningStateData.schedule_id}/words`);
    all_contents.data;

    setAllContents([...all_contents.data]);
  };

  const imgCheck = () => {
    const img = new Image();

    img.onload = function () {
      setImgReady(true);
    };

    img.onerror = function () {
      setImgReady(false);
    };

    img.src = imgSrc;
  };

  const onClickSelection = (idx: number) => {
    setSelectedIndex(idx);
  };

  const goNext = async () => {
    let right = false;
    setNext(false);
    if (typeof selectedIndex == 'number' && selectedIndex + 1 == rightanswer) {
      right = true;
      setRight(right);
    } else {
      setRight(right);
      if (retryCnt > 0 && ((timeout > 0 && progress > 0) || timeout == 0)) {
        playEffectSound('wrong');
        setRetryCnt(retryCnt - 1);
        setWrongIndex(selectedIndex);
        settimeout1.current.push(
          setTimeout(() => {
            setWrongIndex(null);
          }, 2000),
        );
        return;
      }
    }

    setLearningStateData(prevState => ({
      ...prevState,
      show_modal: true,
    }));

    if (check_type) {
      onSoundPlayRef.current = async () => {
        playEffectSound(right ? 'correct' : 'wrong');
        await new Promise(resolve => setTimeout(resolve, 1500));
        await voicePlay();
      };
      onPlayEffectSoundPlayRef.current = async () => {
        await playEffectSound(right ? 'correct' : 'wrong');
      };
      onAnswerSoundPlayRef.current = async () => {
        await voicePlay();
      };
      onAnswerSoundStopRef.current = async () => {
        voiceStop();
      };
      setVisibleGrading(true);
    } else {
      setTimeout(() => {
        goNextProcess();
      }, 2000);
    }
  };

  const goNextProcess = async () => {
    onSoundPlayRef.current = undefined;
    onPlayEffectSoundPlayRef.current = undefined;
    onAnswerSoundPlayRef.current = undefined;
    onAnswerSoundStopRef.current = undefined;
    setKeyboardStop(true);
    setWrongIndex(null);
    for (let i = 0; i < settimeout1.current.length; i++) {
      clearTimeout(settimeout1.current[i]);
    }
    settimeout1.current = [];
    setRetryCnt(maxRetry);
    const resultsheet_tmp = JSON.parse(JSON.stringify(resultsheet));
    resultsheet_tmp[current_step] = {
      no: current_step + 1,
      word_id: content['id'],
      answer: selectedIndex != null ? selectedIndex + 1 : null,
      rightanswer: rightanswer,
      question: question,
      choices: choices,
    };
    if (current_step == contents.length - 1) {
      setVisibleGrading(false);
      setSelectedIndex(null);
      setTimeout(() => {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
          resultsheet: resultsheet_tmp,
        }));

        const { label, icon, score, kor_label } = makeEndModuleModalProps(resultsheet_tmp);
        setEndModuleModal({ visible: true, icon, label, score, kor_label });
      }, 0);
    } else {
      setProgress(100);
      setSelectedIndex(null);
      setTimeout(() => {
        setKeyboardStop(false);
      }, 1000);
      setTimeout(() => {
        setLearningStateData(prevState => ({
          ...prevState,
          show_modal: false,
          current_step: current_step + 1,
          resultsheet: resultsheet_tmp,
        }));
      }, 0);
    }
  };

  return (
    <StyledPopquizWrap>
      <StyledIconButton onClick={() => goNext()} sx={{ right: '16px' }} disableRipple>
        <AiOutlineRight />
      </StyledIconButton>
      <StyledPopquizBody>
        <StyledQuestionWrap>
          {imgReady ? (
            <StyledQuestionImageBox sx={{ width: '70%' }}>
              <img
                style={{ height: '30vh', width: 'auto', maxWidth: '90%', borderRadius: '1rem' }}
                src={imgSrc}
                alt={content['spell']}
              />
              <Box sx={{ height: '3vh' }} />
              <StyledQuestionAutoText fontSizeUnit='vh' maxFontSize={5} minFontSize={4}>
                {question}
              </StyledQuestionAutoText>
            </StyledQuestionImageBox>
          ) : (
            <StyledQuestionAutoText fontSizeUnit='vh' sx={{ width: '70%' }} maxFontSize={5} minFontSize={4}>
              {question}
            </StyledQuestionAutoText>
          )}
        </StyledQuestionWrap>
        <StyledSelectionWrap>
          {choices.map((choice, idx) => (
            <StyledSelectionBox
              status={
                wrongIndex == idx
                  ? 'wrong'
                  : selectedIndex == idx
                  ? visibleGrading
                    ? right
                      ? 'right'
                      : 'wrong'
                    : 'selected'
                  : ''
              }
              key={`popquiz_selection_${idx}`}
              onClick={() => onClickSelection(idx)}
            >
              <StyledSelectionAutoText
                minFontSize={3}
                maxFontSize={4}
                fontSizeUnit='vh'
                status={
                  wrongIndex == idx
                    ? 'wrong'
                    : selectedIndex == idx
                    ? visibleGrading
                      ? right
                        ? 'right'
                        : 'wrong'
                      : 'selected'
                    : ''
                }
              >
                {choice}
              </StyledSelectionAutoText>
            </StyledSelectionBox>
          ))}
        </StyledSelectionWrap>
      </StyledPopquizBody>
      <StyledPopquizBottom>
        {timeout > 0 ? <StyledLinearProgress key={current_step} variant={'determinate'} value={progress} /> : null}
      </StyledPopquizBottom>
      <ModalGrading
        visible={visibleGrading}
        onSoundPlay={onSoundPlayRef.current}
        onPlayEffectSoundPlay={onPlayEffectSoundPlayRef.current}
        onAnswerSoundPlay={onAnswerSoundPlayRef.current}
        onAnswerSoundStop={onAnswerSoundStopRef.current}
        onClose={() => goNextProcess()}
        rightanswer={type == 0 ? content['spell'] : content['mean']}
        question={question}
        content={content}
        type={right ? 'right_answer' : 'wrong_answer1'}
      />
      <ModalEndModule
        visible={endModuleModal.visible}
        label={endModuleModal.label}
        kor_label={endModuleModal.kor_label}
        icon={endModuleModal.icon}
        score={endModuleModal.score}
        nextCallback={nextCallback}
      />
    </StyledPopquizWrap>
  );
}

export default Popquiz;
