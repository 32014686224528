import { ProfileUI } from './Profile.Presenter';
import { useState, useCallback, useMemo, useContext, useRef, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { resetUserState, userState } from '../../recoil/model/user';
import { profilesState, ProfileType, resetProfilesState, setProfilePageMode } from 'recoil/common/profiles';
import { Category } from 'utils/multi_level_selection';
import { fetchFileApi, fetchGetApi, fetchDeleteApi, fetchPostApi, fetchPutApi, fetchSuperPostApi2 } from 'utils/api';
import { convertKorToGrade, convertGradeToKor, autoHypenPhone } from 'utils/tools';
import { deviceState } from 'recoil/common/device';
import profile_01 from 'assets/images/default-profile/profile_01.png';
import profile_02 from 'assets/images/default-profile/profile_02.png';
import profile_03 from 'assets/images/default-profile/profile_03.png';
import profile_04 from 'assets/images/default-profile/profile_04.png';
import profile_05 from 'assets/images/default-profile/profile_05.png';
import profile_06 from 'assets/images/default-profile/profile_06.png';
import profile_07 from 'assets/images/default-profile/profile_07.png';
import profile_08 from 'assets/images/default-profile/profile_08.png';
import { ModalContext } from 'provider/ModalProvider';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { modalManual2State, openModalManual2, closeModalManual2 } from 'recoil/common/modalManual2';
import { modalLectureApplyState, openModalLectureApply } from 'recoil/common/modalLectureApply';
import dayjs from 'dayjs';
import { modalInputPinState, ModalInputPinType, openModalInputPin } from 'recoil/common/modalInputPin';
import { useNavigate } from 'react-router';
import {
  LoadingCircleSpinnerDataType,
  LoadingCircleSpinnerState,
  openLoadingCircleSpinner,
  closeLoadingCircleSpinner,
} from 'recoil/common/loadingCircleSpinner';
import {
  ModalServiceNoticeType,
  modalServiceNoticeState,
  openModalServiceNotice,
} from 'recoil/common/modalServiceNotice';
import { ModalStudyStopType, modalStudyStopState, openModalStudyStop } from 'recoil/common/modalStudyStop';
import validate from 'utils/validation';
import { accountInfoState, AccountInfoType } from 'recoil/model/accountInfo';
import { getCookie, setCookie } from 'utils/cookie';
import { openModalBill, ModalBillType, modalBillState } from 'recoil/common/modalbill';
import {
  modalStudySettingData,
  openModalStudySetting,
  ModalStudySettingDataType,
  closeModalStudySetting,
} from 'recoil/common/modalStudySetting';
import { learningState } from 'recoil/model/learning';
import { bookState } from 'recoil/model/book';
import { pocketNoteState } from 'recoil/model/pocket_note';
import { modalHiddenMissionState } from 'recoil/common/modalHiddenMission';
import { hiddenMissionState } from 'recoil/common/mission';
import { BackgroundSoundContext } from 'provider/BackgroundSoundProvider';
import { BASE_URL } from 'utils/constants';

import {
  modalDefaultProfileSettingState,
  openModalDefaultProfileSetting,
} from 'recoil/common/modalDefaultProfileSetting';

declare let window: any;

export function ProfileContainer() {
  const navigate = useNavigate();
  const setModalDefaultProfileSetting = useSetRecoilState(modalDefaultProfileSettingState);
  const { modal_confirm } = useContext(ModalContext);
  const [accountInfo, setAccountInfo] = useRecoilState<AccountInfoType>(accountInfoState);
  const setModalServiceNotice = useSetRecoilState<ModalServiceNoticeType>(modalServiceNoticeState);
  const setModalStudyStop = useSetRecoilState<ModalStudyStopType>(modalStudyStopState);
  const setModalInputPin = useSetRecoilState<ModalInputPinType>(modalInputPinState);
  const setToastBar = useSetRecoilState(toastBarState);
  const setModalManual2 = useSetRecoilState(modalManual2State);
  const setModalLectureApply = useSetRecoilState(modalLectureApplyState);
  const setLoadingCircleSpinner = useSetRecoilState<LoadingCircleSpinnerDataType>(LoadingCircleSpinnerState);
  const setModalStudySetting = useSetRecoilState<ModalStudySettingDataType>(modalStudySettingData);
  const setModalBill = useSetRecoilState<ModalBillType>(modalBillState);
  const setUserStateData = useSetRecoilState(userState);
  const deviceStateData = useRecoilValue(deviceState);
  const { is_mobile, platform } = deviceStateData;
  const [profilesStateData, setProfileStateData] = useRecoilState(profilesState);
  const [modalProfileVisible, setModalProfileVisible] = useState<boolean>(false);
  const {
    customer_id,
    free_enddate,
    product_type,
    profiles,
    mode,
    modify_pin_profile,
    isStop,
    isWithDrawal,
    admin_real_stop,
    isBlocked,
    isJoinEscape,
  } = profilesStateData;
  const { resetBGM } = useContext(BackgroundSoundContext);

  //* 등록 / 수정시 state
  const [name, setName] = useState<string>('');
  const [grade, setGrade] = useState<Category>();
  const [gradeItem, setGradeItem] = useState<any>();
  const [gradeItems, setGradeItems] = useState<any>();
  const [buttonGroupContents, setButtonGroupContents] = useState<
    { id: number | string; label: string; check: boolean }[]
  >([
    {
      id: 0,
      label: '학습알림 ON',
      check: false,
    },
    {
      id: 1,
      label: '학습알림 OFF',
      check: false,
    },
  ]);
  const [imageUrl, setImageUrl] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [defaultProfile, setDefaultProfile] = useState<number>(0);
  const [modifyType, setModifyType] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [accountId, setAccountId] = useState<number>(0);
  const [top, setTop] = useState<string>('0px');
  const [externalKeyboard, setExternalKeyboard] = useState<boolean>(false);
  const [pinCode, setPinCode] = useState<string>('');
  const origin_default_profile = useRef<number>(0);
  const origin_image_url = useRef<string>('');
  const mode_ref = useRef<string>(mode);
  mode_ref.current = mode;

  const resetLearningState = useResetRecoilState(learningState);
  const resetBookState = useResetRecoilState(bookState);
  const resetPocketNoteState = useResetRecoilState(pocketNoteState);
  const resetModalMissionState = useResetRecoilState(modalHiddenMissionState);
  const resetHiddenMissionState = useResetRecoilState(hiddenMissionState);

  const resetRecoilState = () => {
    resetUserState({ setUserStateData });
    resetLearningState();
    resetBookState();
    resetPocketNoteState();
    resetModalMissionState();
    resetHiddenMissionState();
    resetBGM();
  };

  const isStopOrisWidthdrawal = useMemo(() => {
    return isStop || isWithDrawal;
  }, [isStop, isWithDrawal]);

  const isFirstProuctNonPayment = useMemo(() => {
    return (accountInfo && !accountInfo.isJoined) || isBlocked;
  }, [accountInfo, isBlocked]);

  const changeFree = useCallback(async () => {
    const params = new URLSearchParams({
      customer_id: `${customer_id}`,
      stage: '1',
    });

    try {
      const url = `/longvoca/customers/changefree`;

      const res = await fetchSuperPostApi2(url, params);

      if (res.result) {
        //! 로그아웃 처리
        resetRecoilState();
        window.sessionStorage.removeItem('user');
        window.sessionStorage.removeItem('authToken');
        window.sessionStorage.removeItem('refreshToken');
        setTimeout(() => {
          closeLoadingCircleSpinner({ setLoadingCircleSpinner });
          window.screen.orientation.lock('portrait');
          navigate(`${BASE_URL}/login`);
          resetProfilesState({ setProfileStateData });
          openToastBar({ setToastBar }, '무료체험 시작을 위해 재로그인 해주세요', 'red', 2000);
        }, 0);
      }
    } catch (error) {
      console.log(error);
    }
  }, [customer_id]);

  const validateRecommcode = async () => {
    if (!profilesStateData.recommCode) return;

    //! 추천인 코드 유효성 및 헤택 검증
    const url = `/etc/recommcode/check?recommCode=${profilesStateData.recommCode}`;
    const res = await fetchGetApi(url);
    const return_obj = {
      applyRecomm: false,
      recommCode: '',
      applyBenefit: '',
    };
    if (res.result) {
      if (profilesStateData.product_type == 'A003Y' || profilesStateData.product_type == 'F') {
        return_obj.applyRecomm = true;
        return_obj.recommCode = profilesStateData.recommCode;
        return_obj.applyBenefit = res.data.benefit;

        return return_obj;
      }
    } else {
      return return_obj;
    }
  };

  const openisJoinEscapePayment = useCallback(async () => {
    let options = undefined;
    //! 즉시가입 1단계 예외 처리 필요
    if (profilesStateData.recommCode && isJoinEscape) {
      const return_recomm = await validateRecommcode();

      if (return_recomm) {
        const { applyBenefit, applyRecomm, recommCode } = return_recomm;
        options = {
          step: 1,
          isSelectedDuration: 2,
          isSelectedProduct: 1,
          applyBenefit,
          applyRecomm,
          recommCode,
        };
      }
    }

    openModalLectureApply(
      { setModalLectureApply },
      '오래영어 클래스 수강신청',
      undefined,
      { enter: 350, exit: 195 },
      options,
    );
  }, [validateRecommcode, profilesStateData.recommCode, profilesStateData.product_type, isJoinEscape]);

  const modalStudySettingCallback = useCallback(
    async (idx: number) => {
      closeModalStudySetting({ setModalStudySetting });

      if (idx == 0) {
        // * 7일 무료체험
        openLoadingCircleSpinner({ setLoadingCircleSpinner }, 8, '무료체험 전환중입니다.');
        await changeFree();
      } else {
        // * 즉시 수강신청
        await openisJoinEscapePayment();
      }
    },
    [changeFree],
  );

  const openModalStudySettingFn = useCallback(() => {
    openLoadingCircleSpinner({ setLoadingCircleSpinner }, 8, '회원님께만 제공되는 혜택을 확인 중입니다.');
    setTimeout(() => {
      closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 225, exit: 350 });
      openModalStudySetting({ setModalStudySetting }, 'joinType', modalStudySettingCallback);
    }, 500);
  }, [modalStudySettingCallback]);

  const getPaymentInfo = async () => {
    try {
      const res = await fetchGetApi(`/customers/${customer_id}/payments/unpaid`);
      const today = new Date();
      const expirationDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
      expirationDate.setMilliseconds(999);
      const isServiceStopDate = admin_real_stop; // 8일 이후 서비스 중단

      if (res.data) {
        if (res.data.isJoined && !getCookie(`modalShownToday_${customer_id}`)) {
          setAccountInfo(res.data);
          openModalServiceNotice({ setModalServiceNotice });
          if (!isServiceStopDate) {
            setCookie(`modalShownToday_${customer_id}`, 'true', { expires: expirationDate });
          }
        } else {
          setAccountInfo(res.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setProfilePageMode({ setProfileStateData }, 'list');
    getPaymentInfo();
  }, []);

  useEffect(() => {
    if (mode_ref.current == 'modify-pin-code' && modify_pin_profile) {
      //* 수정 관련 세팅 진행
      const { name, phone, study_alim, grade, default_profile, id } = modify_pin_profile;
      if (modify_pin_profile.type == 'T') {
        //* 메인 프로필
        setPhone(phone);
      } else {
        //* 서브 프로필 > name, grade ,study_alim
        const return_grade = convertGradeToKor(grade as string) as any;
        setGrade(return_grade.gradeValue);
        setGradeItem(return_grade.gradeItem);
        setGradeItems(return_grade.gradeItemsValue);
      }

      setAccountId(id);
      setImageUrl(
        +default_profile == 0 && modify_pin_profile.profile_type == 'default' ? '' : modify_pin_profile.profile,
      );
      origin_image_url.current =
        +default_profile == 0 && modify_pin_profile.profile_type == 'default' ? '' : modify_pin_profile.profile;
      setDefaultProfile(+default_profile);
      origin_default_profile.current = +default_profile;
      setName(name);
      setButtonGroupContents(prev => {
        return prev.map((item, idx) => ({
          ...item,
          check: study_alim == 1 && idx == 0 ? true : study_alim == 0 && idx == 1 ? true : false,
        }));
      });
      setModifyType(modify_pin_profile.type);
    }
  }, [modify_pin_profile]);

  const openAddClassModal = useCallback(() => {
    closeModalManual2({ setModalManual2 });
    openLoadingCircleSpinner({ setLoadingCircleSpinner }, 8, '체험회원 대상 수강 신청이 진행중입니다.');
    setTimeout(async () => {
      closeLoadingCircleSpinner({ setLoadingCircleSpinner }, { enter: 225, exit: 350 });

      let options = undefined;
      //! 즉시가입 1단계 예외 처리 필요
      if (profilesStateData.recommCode && profilesStateData.product_type == 'F') {
        const return_recomm = await validateRecommcode();

        if (return_recomm) {
          const { applyBenefit, applyRecomm, recommCode } = return_recomm;
          options = {
            step: 1,
            isSelectedDuration: 2,
            isSelectedProduct: 1,
            applyBenefit,
            applyRecomm,
            recommCode,
          };
        }
      }

      openModalLectureApply(
        { setModalLectureApply },
        '오래영어 클래스 수강신청',
        undefined,
        { enter: 350, exit: 195 },
        options,
      );
    }, 500);
  }, [profilesStateData, validateRecommcode]);

  const freeEndModalOpen = useCallback(() => {
    const options = {
      title: '무료체험 기간이 모두 만료되었어요.',
      subTitle: '아래 내용을 통해 학습 클래스를 수강 신청해주세요.',
      contents: [
        {
          title: '올인원 클래스 최대 64% 장학금 지원',
          content: [
            '단 한번이라도 체험을 해보신 회원분께는',
            '얼리버드, 패키지 등 최대 64% 장학금 지원 혜택을',
            '제공해드리고 있어요. 지금 수강 신청해보세요!',
          ],
        },
        {
          title: '추가로 체험해볼 수 있는 방법은 없나요?',
          content: ['죄송하게도 체험 연장은 불가합니다.', '고객센터 or 배정된 학습 담당자에게 문의해주세요.'],
        },
      ],
      buttonLabel: '수강 신청',
      callback: openAddClassModal,
    };

    openModalManual2({ setModalManual2 }, options);
  }, []);

  const endFree = useCallback(() => {
    if (product_type == 'F' && typeof free_enddate == 'string') {
      const today = dayjs();
      const freeEndDayjs = dayjs(free_enddate);
      if (today.isAfter(freeEndDayjs)) return true;
    }
  }, [free_enddate, product_type]);

  useEffect(() => {
    const isEnd = endFree();
    if (isEnd) {
      freeEndModalOpen();
    }
  }, []);

  const gradeCode = useMemo(() => {
    if (grade) {
      return convertKorToGrade(grade.name);
    } else {
      return null;
    }
  }, [grade]);

  const studyAlim = useMemo(() => {
    const check_button = buttonGroupContents.find(item => item.check);
    if (check_button) {
      return check_button.id;
    } else {
      return null;
    }
  }, [buttonGroupContents]);

  const defaultProfileImage = useMemo(() => {
    switch (defaultProfile) {
      case 1:
        return profile_01;
      case 2:
        return profile_02;
      case 3:
        return profile_03;
      case 4:
        return profile_04;
      case 5:
        return profile_05;
      case 6:
        return profile_06;
      case 7:
        return profile_07;
      case 8:
        return profile_08;
      default:
        return null;
    }
  }, [defaultProfile]);

  const imageFileUrl = useMemo(() => {
    if (imageFile) {
      return window.URL.createObjectURL(imageFile as Blob);
    }

    return null;
  }, [imageFile]);

  const onClickAddButton = useCallback(() => {
    if (isStopOrisWidthdrawal) {
      // * 서비스 중단 모달 띄우기
      openModalStudyStop({ setModalStudyStop });
      return;
    }

    if (isFirstProuctNonPayment) {
      // * 계산서 모달 띄우기
      openModalBill({ setModalBill });
      return;
    }

    if (isJoinEscape && profilesStateData.product_type != 'F') {
      // * 즉시가입 이탈자 모달 띄우기
      openModalStudySettingFn();
      return;
    }

    if (admin_real_stop) {
      // * 미납 8일자 이후 모달 띄우기
      openModalServiceNotice({ setModalServiceNotice });
      return;
    }

    const isEnd = endFree();
    if (isEnd) {
      //* 매뉴얼 모달 띄우기
      freeEndModalOpen();
      return;
    }

    if (product_type == 'F' && profiles.length == 2) {
      openToastBar({ setToastBar }, '클래스 수강 후 추가 등록 가능합니다.', 'red', 2000);
      return;
    }
    setPinCode('');
    setProfilePageMode({ setProfileStateData }, 'register');
  }, [
    profiles,
    product_type,
    isStopOrisWidthdrawal,
    isFirstProuctNonPayment,
    isJoinEscape,
    openModalStudySettingFn,
    profilesStateData.product_type,
    admin_real_stop,
  ]);

  const onClickBackButton = useCallback(async () => {
    await getProfiles();
    resetRegisterForm();
    setProfilePageMode({ setProfileStateData }, 'list');
  }, []);

  const resetRegisterForm = useCallback(() => {
    setName('');
    setPhone('');
    setGrade(undefined);
    setGradeItems(undefined);
    setButtonGroupContents([
      {
        id: 0,
        label: '학습알림 ON',
        check: false,
      },
      {
        id: 1,
        label: '학습알림 OFF',
        check: false,
      },
    ]);
    setImageFile(null);
    setImageUrl('');
    setDefaultProfile(0);
    setAccountId(0);
    setPinCode('');
    origin_default_profile.current = 0;
    origin_image_url.current = '';
  }, [endFree]);

  const onClickModifykBackButton = useCallback(async () => {
    // * 수정 처리
    try {
      if (mode == 'modify') {
        if (!name) {
          openToastBar({ setToastBar }, '이름은 필수 입력 항목입니다.', 'red', 2000);
          return;
        }

        if (modifyType == 'T') {
          if (!phone) {
            openToastBar({ setToastBar }, '전화번호는 필수 입력 항목입니다.', 'red', 2000);
            return;
          }

          // * 메인 프로필 수정 > name, phone, study_alim
          if (name && validate.phone(phone) && studyAlim != null) {
            const form_data = new FormData();
            form_data.append('name', name);
            form_data.append('phone', phone.replace(/[-]/g, ''));
            form_data.append('study_alim', studyAlim == 0 ? '1' : '0');

            if (imageFile) {
              form_data.append('profile', imageFile);
            } else if (imageUrl && imageUrl != origin_image_url.current) {
              form_data.append('profile', imageUrl);
            } else if (origin_default_profile.current != defaultProfile) {
              form_data.append('default_profile', String(defaultProfile));
            }

            const update_res = await fetchFileApi(`/customers/${customer_id}/profile/${accountId}/T`, form_data);

            if (update_res.result) {
              // * 초기화
              await getProfiles();
              resetRegisterForm();
              setProfilePageMode({ setProfileStateData }, 'list');
              openToastBar({ setToastBar }, '프로필이 수정되었습니다.', '#3f3f3f', 2000);
            } else {
              openToastBar({ setToastBar }, '프로필이 수정되지 않았습니다.', 'red', 2000);
            }
          }
        } else if (modifyType == 'S') {
          // * 서브 프로필 수정 > name, grade, study_alim
          if (name && gradeCode != null && studyAlim != null) {
            const form_data = new FormData();
            form_data.append('name', name);
            form_data.append('grade', gradeCode);
            form_data.append('study_alim', studyAlim == 0 ? '1' : '0');
            if (imageFile) {
              form_data.append('profile', imageFile);
            } else if (imageUrl && imageUrl != origin_image_url.current) {
              form_data.append('profile', imageUrl);
            } else if (origin_default_profile.current != defaultProfile) {
              form_data.append('default_profile', String(defaultProfile));
            }

            const update_res = await fetchFileApi(`/customers/${customer_id}/profile/${accountId}/S`, form_data);
            if (update_res.result) {
              // * 초기화
              await getProfiles();
              resetRegisterForm();
              setProfilePageMode({ setProfileStateData }, 'list');
              openToastBar({ setToastBar }, '프로필이 수정되었습니다.', '#3f3f3f', 2000);
            } else {
              openToastBar({ setToastBar }, '프로필이 수정되지 않았습니다.', 'red', 2000);
            }
          }
        }
      } else if (['create-pin-code', 'modify-pin-code'].includes(mode)) {
        if (pinCode) {
          // * PIN번호 검증
          if (/^\d{0,8}$/.test(pinCode) && pinCode.length >= 4 && pinCode.length <= 8) {
            //! PIN번호 업데이트 요청
            const args = {
              pin_code: pinCode,
              study_alim: studyAlim === 1 ? 0 : 1,
            };

            const url = `/customers/${customer_id}/profile/${accountId}/code`;

            const res = mode == 'create-pin-code' ? await fetchPostApi(url, args) : await fetchPutApi(url, args);

            if (res.result) {
              await getProfiles();
              resetRegisterForm();
              setProfilePageMode({ setProfileStateData }, 'list');
              openToastBar(
                { setToastBar },
                mode == 'create-pin-code' ? 'PIN 번호가 등록되었습니다.' : 'PIN 번호가 수정되었습니다.',
                '#3f3f3f',
                2000,
              );
            } else {
              openToastBar({ setToastBar }, '오류가 발생했습니다.', 'red', 2000);
            }
          } else {
            openToastBar({ setToastBar }, 'PIN 번호는 필수 입력 항목입니다.', 'red', 2000);
          }
        } else {
          // * PIN 번호 등로 유도 openToastBar
          openToastBar({ setToastBar }, 'PIN 번호는 필수 입력 항목입니다.', 'red', 2000);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [name, grade, gradeCode, phone, studyAlim, defaultProfile, imageFile, imageUrl, accountId, pinCode, mode]);

  const onClickName = useCallback(
    (profile: ProfileType) => {
      if (isStopOrisWidthdrawal) {
        // * 서비스 중단 모달 띄우기
        openModalStudyStop({ setModalStudyStop });
        return;
      }

      if (isFirstProuctNonPayment) {
        // * 계산서 모달 띄우기
        openModalBill({ setModalBill });
        return;
      }

      if (isJoinEscape && profilesStateData.product_type != 'F') {
        // * 즉시가입 이탈자 모달 띄우기
        openModalStudySettingFn();
        return;
      }

      if (admin_real_stop) {
        // * 미납 8일자 이후 모달 띄우기
        openModalServiceNotice({ setModalServiceNotice });
        return;
      }

      const isEnd = endFree();
      if (isEnd) {
        //* 매뉴얼 모달 띄우기
        freeEndModalOpen();
        return;
      }

      setProfilePageMode({ setProfileStateData }, 'modify');
      const { name, phone, study_alim, grade, default_profile, id, pin_code } = profile;
      if (profile.type == 'T') {
        //* 메인 프로필 > name, phone, study_alim
        setPhone(phone);
      } else {
        //* 서브 프로필 > name, grade ,study_alim
        const return_grade = convertGradeToKor(grade as string) as any;
        setGrade(return_grade.gradeValue);
        setGradeItem(return_grade.gradeItem);
        setGradeItems(return_grade.gradeItemsValue);
      }
      setPinCode(pin_code ? pin_code : '');
      setAccountId(id);
      setImageUrl(+default_profile == 0 && profile.profile_type == 'default' ? '' : profile.profile);
      origin_image_url.current = +default_profile == 0 && profile.profile_type == 'default' ? '' : profile.profile;
      setDefaultProfile(+default_profile);
      origin_default_profile.current = +default_profile;
      setName(name);
      setButtonGroupContents(prev => {
        return prev.map((item, idx) => ({
          ...item,
          check: study_alim == 1 && idx == 0 ? true : study_alim == 0 && idx == 1 ? true : false,
        }));
      });
      setModifyType(profile.type);
    },
    [
      endFree,
      isStopOrisWidthdrawal,
      isFirstProuctNonPayment,
      isJoinEscape,
      openModalStudySettingFn,
      profilesStateData.product_type,
      admin_real_stop,
    ],
  );

  //* 등록 / 수정 관련 함수
  const onChangeName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const new_value = e.target.value;
      if (new_value.length > 15) return;
      setName(new_value);
    },
    [name],
  );

  const onChangePhone = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replace(/[-]/g, '');
    value = autoHypenPhone(value);
    if (value.length > 13) return;
    setPhone(value);
  }, []);

  const changeButtonCheck = useCallback(
    (id: number | string, key?: string) => {
      const numberId = Number(id);

      if (buttonGroupContents[numberId].check) {
        return false;
      }

      const updatedButtonGroupContents = buttonGroupContents.map((item, index) => {
        if (index === numberId) {
          return { ...item, check: true };
        } else {
          return { ...item, check: false };
        }
      });
      setButtonGroupContents(updatedButtonGroupContents);
    },
    [buttonGroupContents],
  );

  const onClickProfileButton = useCallback(() => {
    if (['create-pin-code', 'modify-pin-code'].includes(mode)) {
      openToastBar({ setToastBar }, 'PIN 번호는 필수 입력 항목입니다.', 'red', 2000);
    } else {
      openModalDefaultProfileSetting({ setModalDefaultProfileSetting, arbitrary: true, clickDefaultProfile });
    }
  }, [mode]);

  const profileUpload = useCallback(
    (img: File | string | null) => {
      if (is_mobile && typeof img != 'string' && img != null) {
        setImageFile(img);
        setImageUrl('');
      } else if (typeof img == 'string') {
        setImageUrl(img);
        setImageFile(null);
      }
      setDefaultProfile(0);
    },
    [is_mobile],
  );

  const clickDefaultProfile = useCallback((id: number) => {
    setDefaultProfile(id);
    setImageUrl('');
    setImageFile(null);
  }, []);

  const onCloseUploadModal = useCallback(() => {
    setModalProfileVisible(false);
  }, []);

  const isClickRegiBtn = useRef(false);
  const onClickRegisterButton = useCallback(async () => {
    if (isClickRegiBtn.current) return;

    const validation_pinCode = /^\d{0,8}$/.test(pinCode) && pinCode.length >= 4 && pinCode.length <= 8;

    if (name.trim() && gradeCode != null && studyAlim != null && validation_pinCode) {
      isClickRegiBtn.current = true;
      const form_data = new FormData();

      form_data.append('name', name.trim());
      form_data.append('grade', gradeCode);
      form_data.append('pin_code', pinCode);
      form_data.append('study_alim', studyAlim == 0 ? '1' : '0');
      form_data.append('default_profile', String(defaultProfile));
      if (imageFile) {
        form_data.append('profile', imageFile);
      } else if (imageUrl) {
        form_data.append('profile', imageUrl);
      }

      await fetchFileApi(`/customers/${customer_id}/profile`, form_data);
      await getProfiles();
      setProfilePageMode({ setProfileStateData }, 'list');
      openToastBar({ setToastBar }, '프로필이 등록되었습니다.', '#3f3f3f', 2000);
      setTimeout(() => {
        isClickRegiBtn.current = false;
      }, 10);
    } else {
      openToastBar({ setToastBar }, '항목들을 모두 입력해주세요.', 'red', 2000);
    }
  }, [name, grade, studyAlim, defaultProfile, imageFile, imageUrl, pinCode]);

  const getProfiles = async () => {
    try {
      const res = await fetchGetApi(`/customers/${customer_id}/profile`);
      if (res.result) {
        setProfileStateData(prev => ({
          ...prev,
          profiles: res.data.profiles,
          mode: 'list',
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const delteProfile = useCallback(async () => {
    try {
      const del_res = await fetchDeleteApi(`/customers/${customer_id}/profile/${accountId}`);
      if (del_res.result) {
        await getProfiles();
        resetRegisterForm();
        setProfilePageMode({ setProfileStateData }, 'list');
        openToastBar({ setToastBar }, '프로필이 삭제되었습니다.', '#3f3f3f', 2000);
      } else {
        openToastBar({ setToastBar }, '프로필이 삭제 되지 않았습니다.', 'red', 2000);
      }
    } catch (error) {
      console.error(error);
    }
  }, [accountId, customer_id]);

  const onClickDeleteButton = useCallback(() => {
    modal_confirm.openModalConfirm(
      'normal_reverse',
      'delete_profile',
      () => {
        return;
      },
      delteProfile,
    );
  }, [delteProfile]);

  const onClickLogin = useCallback(
    async (profile: ProfileType) => {
      if (isStopOrisWidthdrawal) {
        // * 서비스 중단 모달 띄우기
        openModalStudyStop({ setModalStudyStop });
        return;
      }

      if (isFirstProuctNonPayment) {
        // * 계산서 모달 띄우기
        openModalBill({ setModalBill });
        return;
      }

      if (isJoinEscape && profilesStateData.product_type != 'F') {
        // * 즉시가입 이탈자 모달 띄우기
        openModalStudySettingFn();
        return;
      }

      if (admin_real_stop) {
        // * 미납 8일자 이후 모달 띄우기
        openModalServiceNotice({ setModalServiceNotice });
        return;
      }

      const isEnd = endFree();
      if (isEnd) {
        //* 매뉴얼 모달 띄우기
        freeEndModalOpen();
        return;
      }

      if (profile.pin_code == null) {
        //* 핀번호 없는 경우 프로필 수정 페이지로 이동
        setProfilePageMode({ setProfileStateData }, 'create-pin-code');
        const { name, phone, study_alim, grade, default_profile, id } = profile;
        if (profile.type == 'T') {
          //* 메인 프로필 > name, phone, study_alim
          setPhone(phone);
        } else {
          //* 서브 프로필 > name, grade ,study_alim
          const return_grade = convertGradeToKor(grade as string) as any;
          setGrade(return_grade.gradeValue);
          setGradeItem(return_grade.gradeItem);
          setGradeItems(return_grade.gradeItemsValue);
        }
        setAccountId(id);
        setImageUrl(+default_profile == 0 && profile.profile_type == 'default' ? '' : profile.profile);
        origin_image_url.current = +default_profile == 0 && profile.profile_type == 'default' ? '' : profile.profile;
        setDefaultProfile(+default_profile);
        origin_default_profile.current = +default_profile;
        setName(name);
        setButtonGroupContents(prev => {
          return prev.map((item, idx) => ({
            ...item,
            check: study_alim == 1 && idx == 0 ? true : study_alim == 0 && idx == 1 ? true : false,
          }));
        });
        setModifyType(profile.type);
        openToastBar({ setToastBar }, 'PIN번호를 입력해주세요.', '#3f3f3f', 2000);
      } else {
        //* 핀번호 있으면 입력 모달 open
        openModalInputPin({ setModalInputPin }, { profile });
      }
    },
    [
      endFree,
      customer_id,
      isStopOrisWidthdrawal,
      isFirstProuctNonPayment,
      isJoinEscape,
      openModalStudySettingFn,
      profilesStateData.product_type,
      admin_real_stop,
    ],
  );

  useEffect(() => {
    if (profilesStateData.customer_id == 0) {
      navigate('/');
    }
  }, [profilesStateData]);

  useEffect(() => {
    if (is_mobile) {
      if (typeof window.ExternalKeyboard == 'function') window.ExternalKeyboard.checkOnce();
      if (window.ExternalKeyboard.isUsed) setExternalKeyboard(true);
    }
  }, [is_mobile]);

  const onFocus = (e: React.FocusEvent<HTMLInputElement>, type: string) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform == 'android') {
      if (type == 'phone') {
        setTop('-50vh');
      } else if (type == 'pin') {
        setTop('-73vh');
      } else {
        setTop('-38vh');
      }
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>, type: string) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform == 'android') {
      setTop('0px');
    }
  };

  const onChangePINCode = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (inputValue.length > 8) {
      return;
    }

    if (/^\d*$/.test(inputValue)) {
      setPinCode(inputValue);
    } else {
      openToastBar({ setToastBar }, '숫자만 입력해주세요.', 'red', 2000);
    }
  }, []);

  const onClickLogoutButton = () => {
    modal_confirm.openModalConfirm('normal', 'b2c_logout', onLogout, modal_confirm.closeModalConfirm);
  };

  const onLogout = () => {
    resetRecoilState();
    resetProfilesState({ setProfileStateData });

    window.sessionStorage.removeItem('user');
    window.sessionStorage.removeItem('authToken');
    window.sessionStorage.removeItem('refreshToken');
    setTimeout(() => {
      window.screen.orientation.lock('portrait');
      navigate(`${BASE_URL}/login`);
    }, 0);
  };

  const props = {
    mode,
    modalProfileVisible,
    profiles,
    onClickLogoutButton,
    onClickAddButton,
    onClickBackButton,
    onClickModifykBackButton,
    onClickName,
    name,
    onChangeName,
    buttonGroupContents,
    changeButtonCheck,
    setGrade,
    onClickProfileButton,
    profileUpload,
    imageFileUrl,
    imageUrl,
    onClickRegisterButton,
    onCloseUploadModal,
    clickDefaultProfile,
    defaultProfileImage,
    modifyType,
    phone,
    gradeItem,
    gradeItems,
    onChangePhone,
    onClickDeleteButton,
    onClickLogin,
    onFocus,
    onBlur,
    top,
    pinCode,
    onChangePINCode,
  };

  return <ProfileUI {...props} />;
}
