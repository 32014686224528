import { styled, Box, CircularProgress } from '@mui/material';

export const LoadingBox = styled(Box)(props => ({
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  left: 0,
  top: 0,
  backgroundColor: 'rgba(33,33,33,.46)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 999999,
}));

export const CircularProgressBottom = styled(CircularProgress)(props => ({
  color: props.theme.palette.grey[props.theme.palette.mode === 'light' ? 300 : 800],
}));

export const CircularProgressTop = styled(CircularProgress)(props => ({
  // color: props.theme.palette.mode === 'light' ? props.theme.palette.green.main : props.theme.palette.yellow_green.main,
  color: props.theme.palette.purple.main,
  animationDuration: '650ms',
  position: 'absolute',
}));

export const LoadingLabel = styled(Box)(props => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
  fontSize: '1.35rem',
  fontWeight: 'bold',
  position: 'absolute',
}));

export const DuplicateLabel = styled(Box)({
  position: 'absolute',
  top: '62%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#fff',
  fontSize: '1.35rem',
  fontWeight: 'bold',
});
