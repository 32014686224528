import dayjs from 'dayjs';
import { atom, SetterOrUpdater, useRecoilValue } from 'recoil';
const monthes = [1, 6, 12];

const prices: { full: number; earlybird: number; pkg: number }[][] = [
  // 핵심문법
  [
    { full: 45000, earlybird: 0, pkg: 0 },
    { full: 270000, earlybird: 20000, pkg: 20000 },
    { full: 540000, earlybird: 201000, pkg: 100000 },
  ],
  // 올인원
  [
    { full: 89000, earlybird: 0, pkg: 0 },
    { full: 534000, earlybird: 40000, pkg: 40000 },
    { full: 1068000, earlybird: 379000, pkg: 300000 },
  ],
  // 기초어휘
  [
    { full: 38000, earlybird: 0, pkg: 0 },
    { full: 228000, earlybird: 20000, pkg: 20000 },
    { full: 456000, earlybird: 157000, pkg: 100000 },
  ],
  // 실전회화
  [
    { full: 31000, earlybird: 0, pkg: 0 },
    { full: 186000, earlybird: 20000, pkg: 20000 },
    { full: 372000, earlybird: 113000, pkg: 100000 },
  ],
];

export interface ModalLectureApplyDataType {
  step: number; //단계 (1단계 : 수강신청, 2단계:결제)
  visible: boolean; //모달창
  title: string; //모달 제목
  lectureClass: string; //수강 클래스
  recommCode: string; //추천인
  applyBenefit: string; //혜택 코드
  minusPrice: number; // 혜택 금액
  month: number; //혜택 기간
  addMonth: number; //추가 혜택 기간
  applyRecomm: boolean; //추천 유무
  benefitPrice: number; //혜택 금액
  onClose: () => void;
  cost: number;
  full: number;
  earlybird: number;
  pkg: number;
  scholarshipDisCountPrice: number; //장학생 할인
  isSelectedDuration: number; //기간선택
  isSelectedProduct: number; //상품선택 (수강 클래스)
  timeout?: { enter: number; exit: number };
}

export const modalLectureApplyState = atom<ModalLectureApplyDataType>({
  key: 'modalLectureApplyState',
  default: {
    step: 1, //처음 1단계
    visible: false,
    title: '', //모달 제목
    lectureClass: '올인원', //수강 클래스
    recommCode: '', //추천인
    applyBenefit: '',
    minusPrice: 0, // 혜택 금액
    month: 0, //혜택 기간
    addMonth: 0,
    applyRecomm: false,
    benefitPrice: 0,
    cost: 0,
    full: 0,
    earlybird: 0,
    pkg: 0,
    isSelectedDuration: 2,
    isSelectedProduct: 1,
    onClose: () => {
      return;
    },
    scholarshipDisCountPrice: 0, //장학생 할인
  },
});

interface ModalLectureApplySetter {
  setModalLectureApply: SetterOrUpdater<ModalLectureApplyDataType>;
}

// 추가 혜택 기간
export const addMonth = ({ setModalLectureApply }: ModalLectureApplySetter) => {
  let month_to_add = 0;
  setModalLectureApply(prevState => {
    if (prevState.applyBenefit.includes('AP')) {
      const string_benefit = prevState.applyBenefit.replace('AP', '');
      let period_unit = 0;

      switch (string_benefit[1]) {
        case 'Y':
          period_unit = 12;
          break;
        case 'M':
          period_unit = 1;
          break;
      }
      month_to_add = +string_benefit[0] * period_unit;
    }

    return {
      ...prevState,
      addMonth: month_to_add,
    };
  });
  return month_to_add;
};

type ModalLectreApplyOptions = {
  step: number;
  isSelectedDuration: number;
  isSelectedProduct: number;
  applyRecomm?: boolean;
  applyBenefit?: string;
  recommCode?: string;
};

export const openModalLectureApply = (
  { setModalLectureApply }: ModalLectureApplySetter,
  title: string,
  scholarshipBenefit?: boolean,
  timeout?: { enter: number; exit: number },
  options?: ModalLectreApplyOptions,
) => {
  let month_to_add = 0;
  setModalLectureApply(prevState => {
    const product = options ? options.isSelectedProduct : 1;
    const duration = options ? options.isSelectedDuration : 2;
    const applyBenefit = prevState.applyBenefit
      ? prevState.applyBenefit
      : options && options.applyBenefit
      ? options.applyBenefit
      : '';
    const applyRecomm = prevState.applyRecomm
      ? prevState.applyRecomm
      : options && options.applyRecomm
      ? options.applyRecomm
      : false;
    const scholarshipDisCountPrice =
      prevState.scholarshipDisCountPrice && prevState.isSelectedDuration == 2 && prevState.isSelectedProduct == 1
        ? prevState.scholarshipDisCountPrice
        : scholarshipBenefit
        ? 30000
        : 0;

    const { full, earlybird, pkg } = prices[product][duration];
    const month = monthes[duration];

    let number_benefit = 0;
    if (applyBenefit.includes('MP')) {
      const string_benefit = applyBenefit.replace('MP', '');
      number_benefit = Number(string_benefit.replace('T', ''));

      switch (string_benefit[string_benefit.length - 1]) {
        case 'T':
          number_benefit = number_benefit * 1000;
          break;
      }
    }

    if (applyBenefit.includes('AP')) {
      const string_benefit = applyBenefit.replace('AP', '');
      let period_unit = 0;

      switch (string_benefit[1]) {
        case 'Y':
          period_unit = 12;
          break;
        case 'M':
          period_unit = 1;
          break;
      }
      month_to_add = +string_benefit[0] * period_unit;
    }

    const cost = full - earlybird - pkg - number_benefit - scholarshipDisCountPrice;
    const benefitFactor = applyRecomm ? monthes[duration] + month_to_add : monthes[duration];
    const benefitPrice = Math.round(cost / benefitFactor);

    let return_obj = {
      ...prevState,
      title,
      visible: true,
      full,
      earlybird,
      month,
      pkg,
      cost,
      benefitPrice,
      minusPrice: number_benefit,
      timeout,
      addMonth: month_to_add,
      scholarshipDisCountPrice,
    };

    if (options) {
      return_obj = {
        ...return_obj,
        ...options,
      };
    }

    return return_obj;
  });
};

export const closeModalLectureApply = (
  { setModalLectureApply }: ModalLectureApplySetter,
  timeout?: { enter: number; exit: number },
) => {
  setModalLectureApply(() => ({
    step: 1,
    visible: false,
    title: '',
    lectureClass: '',
    recommCode: '',
    applyBenefit: '',
    applyRecomm: false,
    benefitPrice: 0,
    minusPrice: 0,
    month: 0,
    addMonth: 0,
    cost: 0,
    full: 0,
    earlybird: 0,
    pkg: 0,
    isSelectedDuration: 2,
    isSelectedProduct: 1,
    onClose: () => {
      return;
    },
    scholarshipDisCountPrice: 0,
    timeout,
  }));
};

//상품, 기간을 토대로 정가, 얼리버드 할인, 패키지할인, 지불금액, 혜택금액 계산
export const handlePricingAndBenefits = (
  { setModalLectureApply }: ModalLectureApplySetter,
  product: number,
  duration: number,
) => {
  setModalLectureApply(prevState => {
    const { scholarshipDisCountPrice } = prevState;
    const { full, earlybird, pkg } = prices[product][duration];
    const month = monthes[duration];

    let scholar_ship_discount_price = 0;
    let number_benefit = 0;
    if (prevState.applyBenefit.includes('MP')) {
      const string_benefit = prevState.applyBenefit.replace('MP', '');
      number_benefit = Number(string_benefit.replace('T', ''));

      switch (string_benefit[string_benefit.length - 1]) {
        case 'T':
          number_benefit = number_benefit * 1000;
          break;
      }
    }

    if (prevState.isSelectedDuration == 2 && prevState.isSelectedProduct == 1) {
      scholar_ship_discount_price = scholarshipDisCountPrice;
    }

    const cost = full - earlybird - pkg - number_benefit - scholar_ship_discount_price;
    const benefitFactor = prevState.applyRecomm ? monthes[duration] + prevState.addMonth : monthes[duration];
    const benefitPrice = Math.round(cost / benefitFactor);

    return {
      ...prevState,
      full,
      earlybird,
      month,
      pkg,
      cost,
      benefitPrice,
      minusPrice: number_benefit,
    };
  });
};

//기간 선택 setter
export const setIsSelectedDuration = (
  { setModalLectureApply }: ModalLectureApplySetter,
  isSelectedDuration: number,
) => {
  setModalLectureApply(prevState => {
    return {
      ...prevState,
      isSelectedDuration,
    };
  });
};
//상품 선택 setter
export const setIsSelectedProduct = ({ setModalLectureApply }: ModalLectureApplySetter, isSelectedProduct: number) => {
  setModalLectureApply(prevState => {
    return {
      ...prevState,
      isSelectedProduct,
    };
  });
};
//추천 코드 setter
export const setRecommCode = ({ setModalLectureApply }: ModalLectureApplySetter, recommCode: string) => {
  setModalLectureApply(prevState => {
    return {
      ...prevState,
      recommCode,
    };
  });
};
