import { styled, Box } from '@mui/material';
import TabsCustom, { TabsContentsType } from '../../components/pocketNote/TabsCutsom';

import PocketNoteTable, { PocketNoteTableItemType } from '../../components/pocketNote/PocketNoteTable';
import InnerSwitch from '../../components/switch/InnerSwitch';
import { useMemo, useCallback, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { pocket_note_fields_note, pocket_note_fields_words } from '../../utils/table_fields';
import { fetchPostApi, fetchGetApi, fetchDeleteApi, fetchPutApi } from '../../utils/api';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { userState } from '../../recoil/model/user';
import { loadingState, setPercent } from '../../recoil/common/loading';
import { oneTimeModalState } from '../../recoil/common/oneTimeModal';
import { ModalContext } from '../../provider/ModalProvider';
import { pocketNoteState } from '../../recoil/model/pocket_note';
import { moduleSettingState } from '../../recoil/model/module_setting';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { toast_contents } from 'utils/modal_contents';

const Wrapper = styled(Box)({
  width: '100%',
  height: '100%',
  backgroundColor: '#fff',
  padding: '0.625rem',
});

const Wrap = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#fff',
  padding: '0.625rem 0.625rem 0.625rem 0.625rem',
  position: 'relative',
  boxShadow: props.theme.palette.boxShadow.main,
}));

function Pocketnote() {
  const location = useLocation();
  const oneTimeModal = useRecoilValue(oneTimeModalState);
  const setLoadingStateData = useSetRecoilState<LoadingType>(loadingState);
  const { modal_alert, modal_check } = useContext(ModalContext);
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const [tabValue, setTabValue] = useState('포켓 노트');
  const [tabContents, setTabContent] = useState<TabsContentsType[]>([
    {
      id: 1,
      label: '포켓 노트',
      count: -1,
    },
    // {
    //   id: 2,
    //   label: '체크업 노트',
    //   count: -1,
    // },
  ]);
  const [tableItems, setTableItems] = useState<PocketNoteTableItemType[]>([]);
  const [selectedNoteId, setSelectedNoteId] = useState(0);
  const [bookMark, setBookMark] = useState(false);
  const [pocketNoteStateData, setPocketNoteStateData] = useRecoilState(pocketNoteState);
  const [moduleSettingStateData, setModuleSettingStateData] = useRecoilState(moduleSettingState);
  const { center_type } = userStateData;
  const setToastBar = useSetRecoilState(toastBarState);

  const modal_key = useMemo(() => {
    if (tabValue == '포켓 노트') {
      return 'pocketnote';
    } else {
      return 'checkupnote';
    }
  }, [tabValue]);

  useEffect(() => {
    getModuleSettings();
    return () => {
      modal_check.closeModalCheck();
    };
  }, []);

  const getModuleSettings = async () => {
    const url = `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/settings?type=pocketnote&ver=2`;
    // url += '&tag=hot'; 추후 hot 으로 변경
    const res = await fetchGetApi(url);
    if (res.result) {
      setModuleSettingStateData(prevState => ({
        ...prevState,
        ...res.data,
      }));
    }
  };

  useEffect(() => {
    if (oneTimeModal[modal_key]) {
      modal_check.openModalCheck(modal_key, undefined, modal_key);
    }
  }, [tabValue, modal_key]);

  const isSelectedNote = useMemo(() => {
    return selectedNoteId ? true : false;
  }, [selectedNoteId]);

  useEffect(() => {
    if (selectedNoteId) {
      // 노트 내 단어 목록 요청
      setPercent(setLoadingStateData, 1);
      getWordsList();
    } else {
      // 노트 목록 요청

      getNoteList();
    }
  }, [selectedNoteId]);

  const getNoteList = useCallback(async (sortKey?: string, sortValue?: string) => {
    let uri = `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/pocketnotes?ver=2`;
    if (sortKey && sortValue) {
      uri += `&sortKey=${sortKey}&sortValue=${sortValue}`;
    }

    const res = await fetchGetApi(uri);
    if (res.result) {
      setTableItems(res.data);
      setTabContent(prev => {
        return prev.map((item, idx) => {
          if (idx == 0) {
            return {
              ...item,
              count: -1,
            };
          } else {
            return {
              ...item,
            };
          }
        });
      });

      if (location.state) {
        //* B2C toast로 분기처리
        if (location.state === 'add_folder') {
          if (center_type == 'A' || center_type == 'B') {
            modal_alert.openModalAlert('success_create', undefined, '폴더가');
          } else {
            openToastBar({ setToastBar }, toast_contents.info.success_create_folder, '', 2000);
          }
        } else if (location.state === 'delete_folder') {
          if (center_type == 'A' || center_type == 'B') {
            modal_alert.openModalAlert('success_delete', undefined, '폴더가');
          } else {
            openToastBar({ setToastBar }, toast_contents.info.success_delete_folder, '', 2000);
          }
        } else if (location.state === 'modify_folder') {
          if (center_type == 'A' || center_type == 'B') {
            modal_alert.openModalAlert('success_modify', undefined, '폴더가');
          } else {
            openToastBar({ setToastBar }, toast_contents.info.success_modify_folder, '', 2000);
          }
        }

        location.state = null;
        window.history.replaceState({}, '');
      }
    }
  }, []);

  const getWordsList = useCallback(async () => {
    const res = await fetchGetApi(
      `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/pocketnotes/${selectedNoteId}/words?ver=2`,
    );

    if (res.result) {
      const new_table_items = res.data.map((item: any) => ({ ...item, checked: false }));
      const selected_note = tableItems.find(tableItem => tableItem.id == selectedNoteId);

      if (selected_note) {
        new_table_items.unshift({ isFolder: true, spell: selected_note.name, id: selectedNoteId });
        setTableItems(new_table_items);
        setTabContent(prev => {
          return prev.map((item, idx) => {
            if (idx == 0) {
              return {
                ...item,
                count: new_table_items.length - 1,
              };
            } else {
              return {
                ...item,
              };
            }
          });
        });
      }
    }
  }, [selectedNoteId, tableItems]);

  const getNoteWords = async () => {
    const res = await fetchGetApi(
      `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/pocketnotes/words`,
    );

    if (res.result) {
      setPocketNoteStateData(prevState => ({ note_contents: [...res.data.words] }));
    }
  };

  const tableFields = useMemo(() => {
    if (selectedNoteId) {
      return pocket_note_fields_words;
    } else {
      return pocket_note_fields_note;
    }
  }, [selectedNoteId]);

  const tabsCustomProps = useMemo(() => {
    return {
      tabValue,
      tabContents,
    };
  }, [tabValue, tabContents]);

  const selectNote = useCallback((noteId: number) => {
    if (noteId) {
      setSelectedNoteId(noteId);
    } else {
      setSelectedNoteId(0);
    }
  }, []);

  const onChangeFolderName = useCallback(async (folderName: string, id?: number, type?: string) => {
    if (type == 'create') {
      // 생성
      const res = await fetchPostApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/pocketnotes`,
        {
          name: folderName,
        },
      );
      if (res.result) {
        getNoteList();
        location.state = 'add_folder';
      }
    } else {
      // 수정
      const res = await fetchPutApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/pocketnotes/${id}`,
        {
          name: folderName,
        },
      );

      if (res.result) {
        getNoteList();
        location.state = 'modify_folder';
      }
    }
  }, []);

  const deleteNote = useCallback(async (id: number) => {
    const res = await fetchDeleteApi(
      `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/pocketnotes/${id}`,
    );
    if (res.result) {
      getNoteList();
      getNoteWords();
      location.state = 'delete_folder';
    }
  }, []);

  const deleteWords = useCallback(
    async (ids: number[]) => {
      const res = await fetchDeleteApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/pocketnotes/${selectedNoteId}/words`,
        { ids },
      );
      if (res.result) {
        getWordsList();
        getNoteWords();
        location.state = 'delete_words';
      }
    },
    [selectedNoteId],
  );

  const changeBookMark = useCallback((value: boolean) => {
    setBookMark(value);
  }, []);

  const pocketNoteTableProps = useMemo(() => {
    return {
      tableFields,
      table_items: tableItems,
      isSelectedNote,
      bookMark,
      onChangeFolderName,
      deleteNote,
      selectNote,
      deleteWords,
      getNoteList,
      changeBookMark,
    };
  }, [
    tableFields,
    tableItems,
    isSelectedNote,
    bookMark,
    onChangeFolderName,
    deleteNote,
    selectNote,
    deleteWords,
    getNoteList,
    changeBookMark,
  ]);

  return (
    <Wrapper>
      <Wrap>
        <TabsCustom {...tabsCustomProps} />
        <PocketNoteTable {...pocketNoteTableProps} />
        {selectedNoteId ? (
          <Box
            sx={{
              position: 'absolute',
              right: '0.85rem',
              top: '1.125rem',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => changeBookMark(!bookMark)}
          >
            <Box sx={{ marginRight: '0.5rem', color: bookMark ? '#000' : '#BFC7CF', fontSize: '1.1rem' }}>책갈피</Box>
            <InnerSwitch checked={bookMark} onChange={() => changeBookMark(!bookMark)} />
          </Box>
        ) : null}
      </Wrap>
    </Wrapper>
  );
}

export default Pocketnote;
