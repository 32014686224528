import { styled, Box } from '@mui/material';
import { Personal } from 'components/common/PolicyPersonal';
import { Use } from 'components/common/PolicyUse';
import { useRecoilState, useRecoilValue } from 'recoil';
import { modalTabState, closeModalTab } from 'recoil/common/modaltab';
import { deviceState } from 'recoil/common/device';
import { useCallback } from 'react';
import { BoxProps } from '@mui/system';

interface MobileProps extends BoxProps {
  is_mobile: number;
}

const Container = styled(Box)<MobileProps>(props => ({
  transitionDuration: '300ms',
  transitionProperty: 'opacity',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  opacity: '1',
  paddingLeft: props.is_mobile == 1 ? '3rem' : '1.25rem',
  paddingRight: props.is_mobile == 1 ? '3rem' : '1.25rem',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  display: 'flex',
  zIndex: '50',
  inset: '0px',
  position: 'fixed',
}));

const Background = styled(Box)({
  opacity: '0.5',
  backgroundColor: '#000',
  zIndex: '-10',
  inset: '0px',
  position: 'fixed',
});

const Wrapper = styled(Box)({
  backgroundColor: '#fff',
  borderRadius: '0.375rem',
  overflowY: 'hidden',
  maxWidth: '900px',
  width: '100vw',
});

const ButtonWrap = styled(Box)({
  paddingTop: '1rem',
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
});

const Button = styled('button')(props => ({
  padding: '0.5rem 1rem',
  border: '1px solid rgb(229, 231, 235)',
  borderBottom: 'none',
  borderTopLeftRadius: '0.375rem',
  borderTopRightRadius: '0.375rem',
  cursor: 'pointer',
  fontSize: '1rem',
  lineHeight: '1.5',

  color: props.className == 'active' ? '#fff' : '#000',
  backgroundColor: props.className == 'active' ? 'rgb(70, 32, 233)' : 'transparent',
  borderColor: props.className == 'active' ? 'rgb(70, 32, 233)' : 'rgb(229, 231, 235)',
  '&:active': {
    borderStyle: 'solid',
    borderBottom: 'none',
  },
}));

const ContentWrap = styled(Box)({
  paddingBottom: '0.5rem',
  borderTopWidth: '1px',
  overflowY: 'scroll',
  height: '450px',
  marginLeft: '1.5rem',
  borderTop: '1px solid rgb(229, 231, 235)',
});

const ContentTitle = styled('h3')({
  fontWeight: '600',
  fontSize: '1.125rem',
  lineHeight: '1.75rem',
  textAlign: 'center',
  padding: '1.25rem',
  magin: '0',
  fontFamily: 'Apple SD Gothic Neo',
});

const BottomWrap = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxHeight: '55px',
  height: '3rem',
});

const CloseButton = styled('button')({
  border: 'none',
  transitionDuration: '200ms',
  transitionProperty: 'all',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  color: '#fff',
  fontWeight: '700',
  backgroundColor: 'rgb(70, 32, 233)',
  width: '100%',
  fontSize: '1rem',
  cursor: 'pointer',
  fontFamily: 'Apple SD Gothic Neo',
  maxHeight: '55px',
  height: '3rem',
  borderBottomLeftRadius: '0.375rem',
  borderBottomRightRadius: '0.375rem',
});

export const ModalTab = () => {
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { is_mobile } = deviceStateData;
  const [modalTab, setModalTab] = useRecoilState(modalTabState);
  const { visible, tabNumber } = modalTab;

  const onClose = useCallback(() => {
    closeModalTab({ setModalTab });
  }, []);

  const onClickTabButton = useCallback((tab: number) => {
    setModalTab(prev => ({
      ...prev,
      tabNumber: tab,
    }));
  }, []);

  if (visible)
    return (
      <Container is_mobile={is_mobile ? 1 : 0}>
        <Background onClick={onClose} />
        <Wrapper>
          <div style={{ height: '55.25vh' }}>
            <ButtonWrap sx={{ height: '3.5625rem' }}>
              <Button onClick={() => onClickTabButton(1)} className={tabNumber == 1 ? 'active' : ''}>
                개인정보취급방침
              </Button>
              <Button onClick={() => onClickTabButton(2)} className={tabNumber == 2 ? 'active' : ''}>
                이용약관
              </Button>
              <Button onClick={() => onClickTabButton(3)} className={tabNumber == 3 ? 'active' : ''}>
                이메일 무단수집 거부
              </Button>
            </ButtonWrap>
            <ContentWrap sx={{ height: 'calc(100% - 3.5625rem)' }}>
              <div>
                {tabNumber == 1 ? (
                  <>
                    {/* 개인정보취급방침 */}
                    <ContentTitle>개인정보취급방침</ContentTitle>
                    <Personal />
                  </>
                ) : tabNumber == 2 ? (
                  <>
                    {/* 이용약관 */}
                    <ContentTitle>이용약관</ContentTitle>
                    <Use />
                  </>
                ) : (
                  <>
                    {/* 이메일 무단수집 거부 */}
                    <ContentTitle>이메일 무단수집 거부</ContentTitle>
                    <p style={{ fontSize: '0.875rem', textAlign: 'center', paddingLeft: '1.25rem', fontWeight: '300' }}>
                      본 사이트에서 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여
                      무단으로 수집되는 것을 거부하며,
                      <br /> 이를 위반 시 정부통신망법에 의해 형사 처벌됨을 유념하여 주시기 바랍니다.
                    </p>
                  </>
                )}
              </div>
            </ContentWrap>
          </div>
          <BottomWrap sx={{ position: 'relative', zIndex: '100' }}>
            <CloseButton onClick={onClose}>닫기</CloseButton>
          </BottomWrap>
        </Wrapper>
      </Container>
    );
  else return null;
};
