import { styled, OutlinedInput } from '@mui/material';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { ImSearch } from 'react-icons/im';

export const SearchBar = styled(OutlinedInput)(({ theme }) => ({
  width: '13rem',
  height: '2.25rem',
  backgroundColor: '#f2f3f5',
  borderRadius: '1.125rem',
  input: {
    padding: '1.03125rem 0.875rem 1.0625rem 0.875rem',
    '&::placeholder': {
      fontSize: '0.9rem',
      paddingBottom: '1rem',
      opacity: '1',
      color: '#9d9e9f',
      fontWeight: '500',
    },
  },
  '&:hover': {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'var(--gray-4)',
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderRadius: '1.125rem',
  },

  '&.modal_searchbar': {
    backgroundColor: '#fff',

    svg: {
      color: '#c9c9c9',
      '&.active': {
        cursor: 'pointer',
        color: '#000',
      },
    },

    input: {
      lineHeight: '1.5',
      '&::placeholder': {
        color: '#c9c9c9',
      },
    },
  },
}));

export const XIcon = styled(IoCloseCircleSharp)(({ theme }) => ({
  width: '2.75rem',
  height: '2.75rem',
  color: '#c7c7c7',
  cursor: 'pointer',
  padding: '0.5rem',
  marginRight: '-0.5rem',
}));

export const SearchIcon = styled(ImSearch)(({ theme }) => ({
  width: '2.28rem',
  height: '2.2rem',
  color: 'var(--balck)',
  padding: '0.5rem',
  marginRight: '-0.5rem',

  '&.active': {
    cursor: 'pointer',
    color: 'var(--green)',
  },
}));
