/*
퍼블리싱 완료

남은 부분
1. 인증 버튼 클릭 메서드
2. 아이디 조회 버튼 클릭 메서드
3. 인증시간 흘러가는 로직
4. 인증 버튼 클릭 후, disabled 처리 로직
*/
import { useState, useMemo, useCallback, useEffect, useContext } from 'react';
import { FindPWUI, FindPWVAProps } from './FindPW.Presenter';
import { BASE_URL, FIND_TEXT_FIELD_PLACEHOLDER_LIST } from 'utils/constants';
import { autoHypenPhone } from 'utils/tools';
import { fetchPostApi, fetchRawPostApi } from 'utils/api';
import ButtonContainer from 'components/button/Button';
import { Typography } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import validation from 'utils/validation';
import { ModalContext } from 'provider/ModalProvider';
import { deviceState } from 'recoil/common/device';
import { useNavigate } from 'react-router';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { toast_contents } from 'utils/modal_contents';

declare let window: any;

export function FindPWContainer() {
  const navigate = useNavigate();
  const { modal_alert } = useContext(ModalContext);
  const deviceStateData = useRecoilValue<DeviceType>(deviceState);
  const { is_mobile, platform } = deviceStateData;
  const [contents, setContents] = useState<{ [key: string]: string }>({ phone: '', cert_num: '' });
  const [inputDisabled, setInputDisabled] = useState(false);
  const [hash, setHash] = useState('');
  const [certSeconds, setCertSeconds] = useState(0);
  const [certTimeKor, setCertTimeKor] = useState('');
  const [certTimeInterval, setCertTimeInterval] = useState<null | NodeJS.Timeout>(null);
  const [top, setTop] = useState<string>('0px');
  const [externalKeyboard, setExternalKeyboard] = useState<boolean>(false);

  const setToastBar = useSetRecoilState(toastBarState);

  const onClickBackButton = useCallback(() => {
    navigate(`${BASE_URL}/login`);
  }, []);

  const navigateToLoginWithState = useCallback(() => {
    navigate(`${BASE_URL}/login`, { state: { fromFindPW: true } });
  }, []);

  const setCertTimer = useCallback(() => {
    const seconds = 180 - certSeconds;

    if (seconds != 0) {
      setCertTimeKor(`${Math.floor(seconds / 60)}분 ${seconds % 60}초`);
    } else {
      clearFindContents();
      setCertTimeKor('시간 종료');
    }
  }, [certSeconds]);

  const clearFindContents = useCallback(() => {
    if (certTimeInterval) {
      clearInterval(certTimeInterval);
    }
    setCertTimeInterval(null);
    setCertSeconds(0);
  }, [certTimeInterval]);

  useEffect(() => {
    if (certSeconds != 0) {
      setCertTimer();
    }
  }, [certSeconds]);

  const placeholderList = useMemo(() => {
    return FIND_TEXT_FIELD_PLACEHOLDER_LIST;
  }, []);

  const handleFindInput = useCallback(
    (value: string, key: string) => {
      // 수정 필요
      let new_value = value;
      if (key == 'phone' && value.length > 13) {
        return;
      } else {
        if (key == 'phone') {
          new_value = autoHypenPhone(value);
        }
      }

      const tmp_contents = { ...contents };
      tmp_contents[key] = new_value;
      setContents({ ...tmp_contents });
    },
    [contents],
  );

  const findButtonDisabled = useMemo(() => {
    let find_button_disabled = false;
    if (contents['phone'].length > 0) {
      if (contents['cert_num'].length == 0) {
        find_button_disabled = true;
      }
    } else {
      find_button_disabled = true;
    }
    return find_button_disabled;
  }, [contents]);

  const certButtonDisabled = useMemo(() => {
    let cert_button_disabled = false;
    if (!(contents['phone'].length > 0)) {
      cert_button_disabled = true;
    }

    return cert_button_disabled;
  }, [contents]);

  // 입력 필드는 숫자만 허용하며, 필수 값 누락 시 버튼이 비활성화되므로 관련 alert은 toast로 대체하지 않음.
  const makeErrorMessage = useCallback(() => {
    let error_message = '';
    for (const [key, val] of Object.entries(contents)) {
      if (key != 'cert_num') {
        if (val.length == 0) {
          error_message = '모든 정보를 입력해주세요.';
        }
        if (key == 'uphone') {
          const vali_phone = validation.phone(val);
          if (!vali_phone) {
            error_message = '올바른 휴대폰 번호를 입력해주세요.\r\n(숫자만 입력해주세요.)';
          }
        }
      }
    }
    return error_message;
  }, [contents]);

  const checkUser = useCallback(async () => {
    if (certTimeKor == '시간 종료') {
      // modal_alert.openModalAlert('certification_time_over', undefined, undefined, () => {
      //   onClickBackButton();
      // });

      //* B2C toast로 변경
      openToastBar({ setToastBar }, toast_contents.info.certification_time_over, '', 2000);
      setTimeout(() => {
        onClickBackButton();
      }, 2000);

      return;
    }

    const error_message = makeErrorMessage();
    if (error_message.length > 0) {
      modal_alert.openModalAlert('empty', undefined, error_message);
    }

    const info: { [key: string]: string } = {
      phone: contents['phone'].replace(/[-]/g, ''),
    };

    try {
      const res = await fetchRawPostApi(`/customers/postcert2`, info);
      if (res.status == 404) {
        // modal_alert.openModalAlert('failed_send_certification_number');
        openToastBar({ setToastBar }, toast_contents.error.failed_send_certification_number, 'red', 2000);
        return;
      } else {
        const response: any = await res.json();
        // modal_alert.openModalAlert('success_send_certification_number', undefined, undefined, () => {
        //   if (certTimeInterval) {
        //     clearInterval(certTimeInterval);
        //   }

        //   setInputDisabled(true);
        //   setHash(response.data);
        //   let i = 0;
        //   setCertTimeInterval(
        //     setInterval(() => {
        //       i++;
        //       setCertSeconds(i);
        //     }, 1000),
        //   );
        // });

        //* B2C toast로 변경
        openToastBar({ setToastBar }, toast_contents.info.success_send_certification_number, '', 2000);
        // 타이머 진행 중 다시 인증 버튼 눌렀을 때 이전 타이머 초기화
        if (certTimeInterval) {
          clearInterval(certTimeInterval);
        }

        setInputDisabled(true);
        setHash(response.data);
        let i = 0;
        setCertTimeInterval(
          setInterval(() => {
            i++;
            setCertSeconds(i);
          }, 1000),
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [contents, certTimeKor, onClickBackButton]);

  const setEndAdornment = useCallback(
    (key: string) => {
      // 수정 필요
      if (key == 'phone') {
        return (
          <ButtonContainer
            color='purple'
            variant='contained'
            disabled={certButtonDisabled}
            onClick={checkUser}
            sx={{ fontFamily: 'Apple SD Gothic Neo' }}
          >
            인증
          </ButtonContainer>
        );
      } else if (key == 'cert_num' && certTimeKor != '') {
        return (
          <Typography
            sx={{ width: '5rem', paddingRight: '0.25rem', textAlign: 'right', fontFamily: 'Apple SD Gothic Neo' }}
          >
            {certTimeKor}
          </Typography>
        );
      }
    },
    [certTimeKor, certButtonDisabled, checkUser],
  );

  const clickFindButton = useCallback(async () => {
    if (certTimeKor == '시간 종료') {
      // modal_alert.openModalAlert('certification_time_over', undefined, undefined, () => {
      //   onClickBackButton();
      // });
      // return;
      openToastBar({ setToastBar }, toast_contents.info.certification_time_over, '', 2000);
      setTimeout(() => {
        onClickBackButton();
      }, 2000);

      return;
    }

    const error_message = makeErrorMessage();
    if (error_message.length > 0) {
      modal_alert.openModalAlert('empty', undefined, error_message);
      return;
    }

    const args: { [key: string]: string } = {
      phone: contents['phone'].replace(/[-]/g, ''),
      cert_num: contents['cert_num'],
      hash,
    };

    //* B2C toast로 변경
    try {
      const res: any = await fetchPostApi(`/customers/accounts/find/pw2`, args);
      if (res.result == false) {
        // modal_alert.openModalAlert('failed_certification');
        openToastBar({ setToastBar }, toast_contents.error.failed_certification, 'red', 2000);
      } else if (res.data) {
        // modal_alert.openModalAlert('success_cerfication_password', undefined, undefined, () => {
        //   clearFindContents();
        //   onClickBackButton();
        // });

        // 비밀번호 찾기 성공 시 로그인 페이지로 이동 후 토스트 노출
        clearFindContents();
        navigateToLoginWithState();
      } else {
        // modal_alert.openModalAlert('error_post_failed', undefined, undefined, () => {
        //   clearFindContents();
        //   onClickBackButton();
        // });
        openToastBar({ setToastBar }, toast_contents.error.error_post_failed, 'red', 2000);
      }
    } catch (error) {
      console.log(error);
    }
  }, [contents, certTimeKor, hash, clearFindContents]);

  useEffect(() => {
    if (is_mobile) {
      if (typeof window.ExternalKeyboard == 'function') window.ExternalKeyboard.checkOnce();
      if (window.ExternalKeyboard.isUsed) setExternalKeyboard(true);
    }
  }, [is_mobile]);

  const onFocus = (e: React.FocusEvent<HTMLInputElement>, type: string) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform == 'android') {
      if (type == 'phone') {
        setTop('-18vh');
      } else if (type == 'cert_num') {
        setTop('-45vh');
      }
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform == 'android') setTop('0px');
  };

  const props_obj = {
    onClickBackButton,
    contents,
    placeholderList,
    handleFindInput,
    setEndAdornment,
    findButtonDisabled,
    inputDisabled,
    clickFindButton,
    top,
    onFocus,
    onBlur,
    is_mobile,
  };

  return <FindPWUI {...props_obj} />;
}
