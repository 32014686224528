import { atom, SetterOrUpdater } from 'recoil';

export interface CardInfoType {
  cardNumber: string;
  cardMonth: string;
  year: string;
  birthDate: string;
  isCorporation: boolean;
  isRegister: boolean;
  duration: number;
  installmentMonths?: string;
}

export interface ModalCardInfoDataType extends CardInfoType {
  visible: boolean;
}

export const modalCardInfoState = atom<ModalCardInfoDataType>({
  key: 'modalCardInfoState',
  default: {
    visible: false,
    isRegister: false,
    isCorporation: false,
    cardNumber: '',
    cardMonth: '',
    year: '',
    birthDate: '',
    installmentMonths: '',
    duration: 0,
  },
});

interface ModalCardInfoSetter {
  setModalCardInfo: SetterOrUpdater<ModalCardInfoDataType>;
}

export const openModalCardInfo = ({ setModalCardInfo }: ModalCardInfoSetter, duration: number) => {
  setModalCardInfo(prev => ({
    ...prev,
    visible: true,
    duration,
  }));
};

export const closeModalCardInfo = ({ setModalCardInfo }: ModalCardInfoSetter, options: Partial<CardInfoType>) => {
  setModalCardInfo(prev => ({
    ...prev,
    visible: false,
    ...options,
  }));
};

export const resetModalCardInfo = ({ setModalCardInfo }: ModalCardInfoSetter) => {
  setModalCardInfo({
    visible: false,
    cardNumber: '',
    isCorporation: false,
    birthDate: '',
    year: '',
    cardMonth: '',
    installmentMonths: '',
    isRegister: false,
    duration: 0,
  });
};

// export const openModalCardInfo = ({
//   setModalCardInfo,
// }: ModalCardInfoSetter) => {
//   setModalCardInfo((prev) => ({
//     ...prev,
//     visible: true,
//   }))
// }

// export const closeModalCardInfo = ({
//   setModalCardInfo,
// }: ModalCardInfoSetter) => {
//   setModalCardInfo({
//     visible: false,
//     isRegister: false,
//     cardNumber: '',
//     month: '',
//     year: '',
//     birthDate: '',
//     installmentMonths: '1',
//     isCorporation: false,
//   })
// }
