import { useCallback, useEffect, useMemo, useState } from 'react';
import { ModalCardInfoUI } from './ModalCardInfo.Presenter';
import { SelectChangeEvent } from '@mui/material';
import { CARD_PAYMENT_TERMS_1, CARD_PAYMENT_TERMS_2, CARD_PAYMENT_TERMS_3 } from 'utils/constants';
import { autoHyphenBusinessNumber, autoHyphenCard } from 'utils/autoHyphen';
import { useRecoilState, useRecoilValue } from 'recoil';
import { closeModalCardInfo, modalCardInfoState, resetModalCardInfo } from 'recoil/common/modalCardInfo';
import { deviceState } from 'recoil/common/device';

export const checkboxData = [
  { id: 'corporation', label: '법인카드 결제' },
  {
    id: 'eula',
    label: '전자금융거래 이용약관',
    notice: '보기 >',
    content: CARD_PAYMENT_TERMS_1,
  },
  {
    id: 'privacy',
    label: '개인정보수집 및 이용동의',
    notice: '보기 >',
    content: CARD_PAYMENT_TERMS_2,
  },
  {
    id: 'thirdParty',
    label: '개인정보 제 3자 제공동의',
    notice: '보기 >',
    content: CARD_PAYMENT_TERMS_3,
  },
];

declare let window: any;

export const ModalCardInfoContainer = () => {
  const [modalCardInfo, setModalCardInfo] = useRecoilState(modalCardInfoState);
  const { visible } = modalCardInfo;
  const deviceStateData = useRecoilValue(deviceState);
  const { is_mobile, platform } = deviceStateData;
  const currentYear = new Date().getFullYear();
  const futureYears = Array.from({ length: 11 }, (_, index) => currentYear + index).sort((a, b) => b - a);
  const [currentPage, setCurrentPage] = useState('main');
  const [cardNumber, setCardNumber] = useState('');
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const [isCorporation, setIsCorporation] = useState(false);
  const [birthDate, setBirthDate] = useState('');
  const [year, setYear] = useState('');
  const [cardMonth, setCardMonth] = useState('');
  const [installmentMonths, setInstallmentMonths] = useState<string | undefined>('1');
  const [checkBoxSelectAll, setCheckBoxSelectAll] = useState(false);
  const [top, setTop] = useState<string>('0px');
  const [externalKeyboard, setExternalKeyboard] = useState<boolean>(false);

  useEffect(() => {
    if (modalCardInfo.visible) {
      if (is_mobile) {
        if (typeof window.ExternalKeyboard == 'function') window.ExternalKeyboard.checkOnce();
        if (window.ExternalKeyboard.isUsed) setExternalKeyboard(true);
      }
    }
  }, [modalCardInfo.visible]);

  const promotionCheckboxData = useMemo(() => {
    const checkbox_arr = [
      { id: 'agreement', label: '전체 동의' },
      {
        id: 'eula',
        label: '전자금융거래 이용약관',
        notice: '보기 >',
        content: CARD_PAYMENT_TERMS_1,
      },
      {
        id: 'privacy',
        label: '개인정보수집 및 이용동의',
        notice: '보기 >',
        content: CARD_PAYMENT_TERMS_2,
      },
      {
        id: 'thirdParty',
        label: '개인정보 제 3자 제공동의',
        notice: '보기 >',
        content: CARD_PAYMENT_TERMS_3,
      },
    ];

    if (modalCardInfo.duration == 0) {
      checkbox_arr.shift();
      return checkbox_arr;
    } else {
      return checkbox_arr;
    }
  }, [modalCardInfo.duration]);

  const resetState = useCallback(() => {
    setCardNumber('');
    setCheckedIds([]);
    setIsCorporation(false);
    setBirthDate('');
    setYear('');
    setCardMonth('');
    setInstallmentMonths('1');
    setCheckBoxSelectAll(false);
    setCurrentPage('main');
  }, []);

  useEffect(() => {
    setCurrentPage('main');
  }, []);

  useEffect(() => {
    if (modalCardInfo.visible) {
      if (modalCardInfo.isRegister) {
        setCheckedIds(promotionCheckboxData.map(checkbox => checkbox.id));
      }
    }
  }, [modalCardInfo.visible, modalCardInfo.isRegister]);

  useEffect(() => {
    if (checkedIds.includes('agreement')) {
      setCheckBoxSelectAll(true);
    } else {
      setCheckBoxSelectAll(false);
    }
  }, [checkedIds]);

  useEffect(() => {
    setBirthDate('');
  }, [isCorporation]);

  useEffect(() => {
    if (!modalCardInfo.isRegister) {
      resetState();
    }
  }, [modalCardInfo.isRegister]);

  const handleTermsButtonClick = (id: string) => {
    if (id !== 'corporation') {
      setCurrentPage(id);
    }
  };

  const handleInstallmentMonthChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setInstallmentMonths(value);
  };

  const handleCheckboxChange = (id: string) => {
    if (id === 'corporation') {
      if (checkedIds.includes('corporation')) {
        setIsCorporation(false);
        setCheckedIds(checkedIds.filter(checkedId => checkedId !== 'corporation'));
      } else {
        setIsCorporation(true);
        setCheckedIds([...checkedIds, 'corporation']);
      }
    } else {
      let newCheckStates: string[];

      if (checkedIds.includes(id)) {
        newCheckStates = checkedIds.filter(checkedId => checkedId !== id);
      } else {
        newCheckStates = [...checkedIds, id];
      }

      setCheckedIds(newCheckStates);

      const otherCheckboxIds = checkboxData.map(data => data.id).filter(dataId => dataId !== 'corporation');
      const allOtherSelected = otherCheckboxIds.every(otherId => newCheckStates.includes(otherId));

      setCheckBoxSelectAll(allOtherSelected);
    }
  };

  const handlePromotionCheckboxChange = (id: string) => {
    if (id === 'agreement') {
      if (checkedIds.includes('agreement')) {
        const newCheckStates: string[] = checkedIds.filter(dataId => dataId == 'corporation');
        setCheckedIds([...newCheckStates]);
      } else {
        setCheckedIds([
          ...checkedIds.filter(dataId => dataId == 'corporation'),
          ...promotionCheckboxData.map(checkbox => checkbox.id),
        ]);
      }
    } else {
      let newCheckStates: string[];

      if (checkedIds.includes(id)) {
        newCheckStates = checkedIds.filter(checkedId => checkedId !== id);
        if (newCheckStates.includes('agreement')) {
          newCheckStates = newCheckStates.filter((checkedId: string) => checkedId !== 'agreement');
        }
      } else {
        newCheckStates = [...checkedIds, id];
        const otherCheckboxIds = promotionCheckboxData.map(cbox => cbox.id).filter(cboxId => cboxId !== 'agreement');

        if (!otherCheckboxIds.some(cboxId => !newCheckStates.includes(cboxId))) {
          newCheckStates.push('agreement');
        }
      }
      setCheckedIds(newCheckStates);
    }
  };

  const handleClose = () => {
    if (modalCardInfo.isRegister) {
      const cardInfos = returnDiffCardInfo();

      setYear(modalCardInfo.year);
      setCardMonth(modalCardInfo.cardMonth);
      setInstallmentMonths(modalCardInfo.installmentMonths);

      closeModalCardInfo(
        { setModalCardInfo },
        {
          year: modalCardInfo.year,
          cardMonth: modalCardInfo.cardMonth,
          installmentMonths: modalCardInfo.installmentMonths,
          isRegister: true,
          ...cardInfos,
        },
      );
      setCurrentPage('main');
    } else {
      resetModalCardInfo({ setModalCardInfo });
      resetState();
    }
  };

  const returnDiffCardInfo = () => {
    const return_obj = {
      cardNumber: cardNumber,
      birthDate: birthDate,
      isCorporation: isCorporation,
    };

    if (cardNumber.replace(/-/g, '').length < 16) {
      return_obj.cardNumber = modalCardInfo.cardNumber;
    }

    if (isCorporation != modalCardInfo.isCorporation) {
      return_obj.birthDate = modalCardInfo.birthDate;
      return_obj.isCorporation = modalCardInfo.isCorporation;
    } else {
      if (isCorporation) {
        //* 사업자 번호 검증
        if (birthDate.replace(/-/g, '').length < 10) {
          return_obj.birthDate = modalCardInfo.birthDate;
          return_obj.isCorporation = modalCardInfo.isCorporation;
        }
      } else {
        //* 생년월일 검증
        if (birthDate.length < 6) {
          return_obj.birthDate = modalCardInfo.birthDate;
          return_obj.isCorporation = modalCardInfo.isCorporation;
        }
      }
    }

    setCardNumber(return_obj.cardNumber);
    setIsCorporation(return_obj.isCorporation);
    setTimeout(() => {
      setBirthDate(return_obj.birthDate);
    });
    return return_obj;
  };

  const handleCardNumberInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    if (value.length < 20) {
      setCardNumber(autoHyphenCard(value));
    }
  };

  const handleMonthChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const value = event.target.value;
    setCardMonth(value.padStart(2, '0'));
  };

  const handleYearChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const value = event.target.value;
    setYear(value);
  };

  const handleBirthDateInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim().replace(/[^0-9]/g, '');

    if (isCorporation) {
      if (value.length < 11) setBirthDate(autoHyphenBusinessNumber(value));
    } else {
      if (value.length < 7) setBirthDate(value);
    }
  };

  const handleCardPaymentButtonClick = () => {
    closeModalCardInfo(
      { setModalCardInfo },
      {
        cardNumber,
        birthDate,
        year,
        cardMonth,
        isCorporation,
        installmentMonths,
        isRegister: true,
      },
    );

    setCurrentPage('main');
  };

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform == 'android') {
      setTop('-0vh');
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!is_mobile || externalKeyboard) return false;
    if (platform == 'android') {
      setTop('0px');
    }
  };

  const props = {
    duration: modalCardInfo.duration,
    visible,
    handleClose,
    handleCardNumberInputChange,
    handleBirthDateInputChange,
    cardNumber,
    handleMonthChange,
    handleYearChange,
    selectedMonth: cardMonth,
    selectedYear: year,
    futureYears,
    birthDate,
    checkedIds,
    handleCheckboxChange,
    handleCardPaymentButtonClick,
    checkBoxSelectAll,
    handleTermsButtonClick,
    currentPage,
    isCorporation,
    installmentMonths,
    handleInstallmentMonthChange,
    handlePromotionCheckboxChange,
    promotionCheckboxData,
    onFocus,
    onBlur,
    top,
  };

  return <ModalCardInfoUI {...props} />;
};
