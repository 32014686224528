import { Root } from './Switch.Styles';
import SwitchUnstyled from '@mui/base/SwitchUnstyled';
import { SxProps, Theme } from '@mui/material';
import React from 'react';

export interface SwitchVAProps {
  checked: boolean;
  onChange: () => void;
  small?: boolean;
  sx?: SxProps<Theme>;
}

export const SwitchUI = React.memo(function SwitchUI({ checked, onChange, small, sx }: SwitchVAProps) {
  const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
  return (
    <SwitchUnstyled
      checked={checked}
      onChange={onChange}
      className={small ? 'small' : ''}
      component={Root}
      sx={sx}
      {...label}
    />
  );
});

// style 변경시 참고
/* 
{
  '.MuiSwitch-track': { background: '#000 !important' },
  '.MuiSwitch-thumb': { background: '#f00 !important' },
} 
*/
