import { ExpenditureEvidenceUI } from './ExpenditureEvidence.Presenter';
import { useMemo, useCallback, useState, useEffect, useContext } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { fetchGetApi, fetchPostApi } from 'utils/api';
import { tax_invoice_form, cash_receipt_form } from 'utils/form';
import { autoHypenPhone, autoBrn } from 'utils/tools';
import { EXPENDITURE_EVIDENCE_CARD_CONTENTS } from 'components/card/InformationCard';
import { userState } from 'recoil/model/user';
import { ModalContext } from 'provider/ModalProvider';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';
import { toast_contents } from 'utils/modal_contents';

export interface PopbillDataType {
  brn: string;
  businessAddress: string;
  detailAddress: string;
  zipCode: string;
  businessCondition: string;
  businessItem: string;
  email: string;
  representative: string;
  corporationName: string;
  tradeType: string;
  phone: string;
  invoiceType: string;
}

// 세금계산서 interface
export type TaxPopbillDataType = Omit<PopbillDataType, 'tradeType' | 'phone'>;

// 현금영수증 interface
export type CashPopbillDataType = Pick<PopbillDataType, 'tradeType' | 'representative' | 'email' | 'phone'>;

export function ExpenditureEvidenceContainer({
  onFocus,
  onBlur,
  reference,
}: {
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  reference: any;
}) {
  const [userStateData] = useRecoilState(userState);
  const { modal_alert, modal_confirm } = useContext(ModalContext);
  const { customer_id, center_type } = userStateData;

  const setToastBar = useSetRecoilState(toastBarState);

  const [receiptType, setReceiptType] = useState<'taxInvoice' | 'cashReceipts' | 'cardUsage' | ''>('');
  const [type, setType] = useState<'add' | 'update' | 'check' | 'error'>('add');

  const [isLoadingGetPopbill, setIsLoadingGetPopbill] = useState(false);
  const [popbillData, setPopbillData] = useState<any>();
  const pageId = 'evidence';

  useEffect(() => {
    getPopbill();
  }, []);

  const getCardUsageUri = useMemo(() => {
    return `/customers/${customer_id}/payments/cardusage`;
  }, []);

  const getPopbillUri = useMemo(() => {
    return `/customers/${customer_id}/popbill`;
  }, []);

  const getCardUsage = async () => {
    const res = await fetchGetApi(getCardUsageUri);
    if (res.result) {
      setReceiptType('cardUsage');
    } else {
      setReceiptType('taxInvoice');
    }
  };

  const getPopbill = async () => {
    setIsLoadingGetPopbill(true);
    const res = await fetchGetApi(getPopbillUri);
    if (res.result && res.data) {
      setPopbillData(res.data);
      setType('update');
      if (res.data.invoiceType === 'tax') {
        setReceiptType('taxInvoice');
      } else {
        setReceiptType('cashReceipts');
      }
    } else {
      getCardUsage();
    }
    setIsLoadingGetPopbill(false);
  };

  // 현금영수증 라디오 버튼 클릭 시, api 요청 하는 코드
  const getFiveDaysPaymentUri = useMemo(() => {
    return `/customers/${customer_id}/payments/fivedays`;
  }, []);

  const getFiveDaysPayment = async () => {
    const res = await fetchGetApi(getFiveDaysPaymentUri);
    if (res.data === 0) {
      modal_confirm.openModalConfirm(
        'only_ok',
        'do_not_have_history_for_publication',
        () => modal_confirm.closeModalConfirm(),
        undefined,
      );
    }
  };

  const originRegiContainer = useMemo(() => {
    if (receiptType === 'taxInvoice') {
      if (type === 'update' && popbillData) {
        return tax_invoice_form.map(row => {
          const new_row = row.map(input => {
            const key = input.key;
            const old_value = popbillData[key];
            if (key === 'brn') {
              return {
                ...input,
                value: `${autoBrn(old_value)}`,
              };
            }
            return {
              ...input,
              value: `${old_value}`,
            };
          });
          return new_row;
        });
      } else {
        // type === 'add'
        return tax_invoice_form;
      }
    } else if (receiptType === 'cardUsage') {
      return tax_invoice_form.map(row => {
        const new_row = row.map(input => {
          return {
            ...input,
            disabled: true,
          };
        });
        return new_row;
      });
    } else {
      // cashReceipts
      if (type === 'update' && popbillData) {
        return cash_receipt_form.map(row => {
          const new_row = row.map(input => {
            const key = input.key;
            const type = input.type;
            const old_value = popbillData[key];
            if (type === 'select' && input.modalContents) {
              const find_modal_item = input.modalContents.find(modalContent => {
                return modalContent.name === old_value;
              });
              if (find_modal_item) {
                return {
                  ...input,
                  value: `${find_modal_item.name}`,
                  id: `${find_modal_item.id}`,
                };
              } else {
                return {
                  ...input,
                };
              }
            } else {
              if (key === 'phone') {
                return {
                  ...input,
                  value: `${autoHypenPhone(old_value)}`,
                };
              }
              return {
                ...input,
                value: `${old_value}`,
              };
            }
          });
          return new_row;
        });
      } else {
        // type === 'add'
        return cash_receipt_form;
      }
    }
  }, [receiptType, type, popbillData]);

  const onSubmit = useCallback(
    async (data: RegistrationDatasType) => {
      let popbill_data: TaxPopbillDataType | CashPopbillDataType;
      let invoiceType = ``;
      const originData = popbillData;
      if (receiptType === 'taxInvoice') {
        invoiceType = 'tax';
        // 세금 계산서
        popbill_data = {
          brn: data?.brn?.value ?? undefined,
          corporationName: data?.corporationName?.value ?? undefined,
          representative: data?.representative?.value ?? undefined,
          email: data?.email?.value ?? undefined,
          businessCondition: data?.businessCondition?.value ?? undefined,
          businessItem: data?.businessItem?.value ?? undefined,
          businessAddress: data?.businessAddress?.value ?? undefined,
          zipCode: data?.zipCode?.value ?? undefined,
          detailAddress: data?.detailAddress?.value ?? undefined,
        } as TaxPopbillDataType;
        if (originData) {
          if (!popbill_data.brn && originData.brn) {
            popbill_data.brn = originData.brn;
          }
          if (!popbill_data.corporationName && originData.corporationName) {
            popbill_data.corporationName = originData.corporationName;
          }

          if (!popbill_data.representative && originData.representative) {
            popbill_data.representative = originData.representative;
          }
          if (!popbill_data.email && originData.email) {
            popbill_data.email = originData.email;
          }
          if (!popbill_data.businessCondition && originData.businessCondition) {
            popbill_data.businessCondition = originData.businessCondition;
          }
          if (!popbill_data.businessItem && originData.businessItem) {
            popbill_data.businessItem = originData.businessItem;
          }
          if (!popbill_data.businessAddress && originData.businessAddress) {
            popbill_data.businessAddress = originData.businessAddress;
          }
          if (!popbill_data.zipCode && originData.zipCode) {
            popbill_data.zipCode = originData.zipCode;
          }
          if (!popbill_data.detailAddress && originData.detailAddress) {
            popbill_data.detailAddress = originData.detailAddress;
          }
        }
      } else {
        invoiceType = 'cash';
        // 현금 영수증
        popbill_data = {
          tradeType: data?.tradeType?.value ?? undefined,
          representative: data?.representative?.value ?? undefined,
          email: data?.email?.value ?? undefined,
          phone: data?.phone?.value ?? undefined,
        } as CashPopbillDataType;
        if (originData) {
          if (!popbill_data.tradeType && originData.tradeType) {
            popbill_data.tradeType = originData.tradeType;
          }
          if (!popbill_data.representative && originData.representative) {
            popbill_data.representative = originData.representative;
          }
          if (!popbill_data.email && originData.email) {
            popbill_data.email = originData.email;
          }
          if (!popbill_data.phone && originData.phone) {
            popbill_data.phone = originData.phone;
          }
        }
      }
      const uri = `/customers/${customer_id}/popbill/${invoiceType}`;
      const res = await fetchPostApi(uri, popbill_data);
      if (res.result) {
        getPopbill();
        //* B2C toast로 분기처리
        if (center_type == 'A' || center_type == 'B') {
          modal_alert.openModalAlert('empty', undefined, '지출증빙 정보가 등록변경되었습니다.');
        } else {
          openToastBar({ setToastBar }, toast_contents.info.success_modify_receipt, '', 2000);
        }
      }
    },
    [receiptType],
  );

  const radioArray = useMemo(() => {
    return [
      {
        value: 'taxInvoice',
        label: '세금 계산서',
      },
      {
        value: 'cashReceipts',
        label: '현금 영수증',
      },
    ];
  }, []);

  const onChangeRadio = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value as 'taxInvoice' | 'cashReceipts';
      if (type === 'update') {
        //* B2C toast로 분기처리
        if (value === receiptType) {
          return;
        } else {
          if (center_type == 'A' || center_type == 'B') {
            modal_alert.openModalAlert('empty', undefined, '등록된 지출증빙 정보는 변경이 불가합니다.');
          } else {
            openToastBar({ setToastBar }, toast_contents.info.registration_receipt, '', 2000);
          }
          return;
        }
      }

      if (receiptType == 'cardUsage') {
        const alert_callback = () => {
          if (value === 'cashReceipts') {
            getFiveDaysPayment();
          }
          setReceiptType(value);
        };
        modal_alert.openModalAlert(
          'empty',
          undefined,
          '최초 결제 이후의 청구건은\r\n기입한 정보로 지출증빙 처리됩니다.',
          alert_callback,
        );
      } else {
        if (value === 'cashReceipts') {
          getFiveDaysPayment();
        }
        setReceiptType(value);
      }
    },
    [receiptType],
  );

  // card
  const card_content = useMemo(() => {
    return EXPENDITURE_EVIDENCE_CARD_CONTENTS;
  }, []);

  const registration_props = {
    originRegiContainer,
    type,
    receiptType,
    radioArray,
    onSubmit,
    onChangeRadio,
    directContent: card_content,
    isLoading: isLoadingGetPopbill,
    pageId,
    onBlur,
    onFocus,
    reference,
  };
  return <ExpenditureEvidenceUI {...registration_props} />;
}
