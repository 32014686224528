import {
  ContentWrap,
  ContentWrapper,
  DashedLine,
  ModalBackground,
  ModalContainer,
  NoticeContentWrap,
  RegisterCardButton,
  Title,
  Box,
} from './ModalStudyStop.Styles';

interface ModalStudyStopVAProps {
  visible: boolean;
  clickCheckButton: () => void;
}

export function ModalStudyStopUI({ visible, clickCheckButton }: ModalStudyStopVAProps) {
  if (visible) {
    return (
      <ModalBackground>
        <ModalContainer>
          <ContentWrapper>
            <ContentWrap>
              <Title>서비스 이용 안내</Title>
              <DashedLine />
              <NoticeContentWrap
                sx={{
                  '& > div': {
                    display: 'block',
                    width: '100%',
                    textAlign: 'center',
                    marginLeft: '0',
                    lineHeight: '1',
                  },
                }}
              >
                <div>현재 관리자 모드는</div>
                <div>
                  사용자 요청에 의해{' '}
                  <Box component={'span'} sx={{ color: '#e9121d' }}>
                    사용중지
                  </Box>{' '}
                  상태입니다.
                </div>
                <div style={{ padding: '0.25rem 0' }}></div>
                <div>재사용을 희망할 경우, 고객센터(1661-5655)</div>
                <div>혹은 카카오톡 채널로 문의해주세요.</div>
              </NoticeContentWrap>
            </ContentWrap>
            <RegisterCardButton onClick={clickCheckButton} variant='contained'>
              확인
            </RegisterCardButton>
          </ContentWrapper>
        </ModalContainer>
      </ModalBackground>
    );
  } else {
    return null;
  }
}
