import { styled, Box } from '@mui/material';
import { ButtonContainer } from '../Button/Button.Container';

export const ModalBackground = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '30',
});

export const ModalContainer = styled(Box)(props => ({
  backgroundColor: props.theme.palette.white.main,
  position: 'relative',
  width: '30rem',
  borderRadius: '0.3rem',
}));

export const ContentWrapper = styled(Box)(props => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const ContentWrap = styled(Box)(props => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '0 2rem',
  marginBottom: '1rem',
}));

export const Title = styled(Box)(props => ({
  fontSize: '1.2rem',
  fontWeight: '900',
  color: props.theme.palette.purple.main,
  paddingTop: '1rem',
  textAlign: 'center',
  marginBottom: '0.5rem',
  width: '100%',
  letterSpacing: '-1px',
}));

export const NoticeContentWrap = styled(Box)(props => ({
  width: '100%',
  height: '10rem',
  background: '#f5f5f5',
  padding: '1rem',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '0.5rem',
  borderRadius: '2rem',
  margin: '0.5rem',
  marginTop: '1.2rem',
  fontWeight: '700',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    marginLeft: '3rem',
    '& .info-title': {
      color: 'gray',
    },
  },
}));

export const CardPayNoticeWrap = styled(Box)(props => ({
  width: '100%',
  height: '10rem',
  background: props.theme.palette.gray_4.main,
  color: 'gray',
  padding: '1rem',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '0.3rem',
  borderRadius: '2rem',
  margin: '0.5rem',
  marginTop: '1.2rem',
  fontWeight: '700',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const RegisterCardButton = styled(ButtonContainer)(props => ({
  background: 'linear-gradient(#652ae9, #4620e9)',
  '&:hover': {
    background: 'linear-gradient(#652ae9, #4620e9)',
  },
  width: '100%',
  height: '4rem',
  borderRadius: '0 0 0.2rem 0.3rem',
}));

export const DashedLine = styled(Box)({
  width: '100%',
  borderBlockEnd: 'none',
  border: '1px dashed #eaeaea',
  marginBottom: '0.1rem',
});
