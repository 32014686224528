import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { userState } from '../../recoil/model/user';
import { routesState } from '../../recoil/common/routes';
import { ModalContext } from '../../provider/ModalProvider';
import { BASE_URL } from '../../utils/constants';
import theme from '../../theme';
import { toast_contents } from 'utils/modal_contents';

import { styled, Box, Typography } from '@mui/material';
import { d_flex_center, dir_column, d_flex_start, d_flex_space_between, d_flex_end } from '../../styles/common';
import Button from '../button/Button';
import InputNormal from '../input/InputNormal';
import DropdownList from '../dropdown/DropdownList';

import default_profile from '../../assets/images/default_img.png';
import logo from '../../assets/images/logo.png';
import { HiUser } from 'react-icons/hi';
import { EffectSoundContext } from '../../provider/EffectSoundProvider';
import profile_default_new from 'assets/images/default-profile/profile_default.png';
import exit_icon from '../../assets/images/exit_icon_black.png';
import { openToastBar, toastBarState } from 'recoil/common/toastBar';

interface ListMenuType {
  [key: string]: { path: string; label: string; root?: boolean }[];
}

const StyledHeaderWrapper = styled(Box)(props => ({
  height: '6.5rem',
  padding: '1rem 1rem 1rem 2rem',
  width: 'calc(100% - 4.25rem)',
  userSelect: 'none',
  display: 'flex',
}));

const StyledAnchorWrap = styled(Box)(props => ({
  width: '100%',
  height: '4.5rem',
}));

const StyledAnchorWBox = styled(Box)(props => ({
  width: '100%',
  height: '4.5rem',
  backgroundColor: props.theme.palette.light_yellow_2.main,
  borderRadius: '1rem',
  ...d_flex_space_between,
}));

const InputBox = styled(Box)(props => ({
  ...d_flex_start,
  marginLeft: '1rem',
  minWidth: '20vw',
  '& input': {
    color: '#888 !important',
    fontWeight: '500',
    backgroundColor: props.theme.palette.white.main,
  },
}));

const StyledInputNormal = styled(InputNormal)(props => ({
  ' .MuiInputBase-root.': {
    height: '2.5rem',
    fontSize: '1rem !important',
    marginBottom: 0,
    borderColor: 'var(--white) !important',
  },
}));

const InfoBox = styled(Box)(props => ({
  ...d_flex_end,
  height: '4rem',
  marginRight: '1rem',
}));

const ProfileBox = styled(Box)(props => ({
  width: '4rem',
  height: '4rem',
  background: props.theme.palette.white.main,
  border: '1px solid #f7e9d4',
  borderRadius: '50%',
  ...d_flex_center,
  marginRight: '1rem',
  position: 'relative',
  overflow: 'hidden',
  '.default_icon': {
    width: '70%',
    height: '70%',
    color: props.theme.palette.gray_3.main,
  },
  '.edit_icon': {
    cursor: 'pointer',
    position: 'absolute',
    bottom: '0rem',
    right: '0rem',
    width: '2.25rem',
    height: '2.25rem',
    color: props.theme.palette.white.main,
    backgroundColor: props.theme.palette.gray_2.main,
    strokeWidth: 0.5,
    zIndex: 1,
  },

  '.user_profile': {
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
  },

  '.default_profile': {
    width: '57%',
    AspectRatio: '1',
    marginBottom: '0.2rem',
  },
}));

const StyledIconButton = styled(Button)(props => ({
  display: 'block',
  width: '3rem',
  height: '3rem',
  lineHeight: '1.75',
  minWidth: '0',
  backgroundImage: `url(${exit_icon})`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  padding: '0',
}));

const Line = styled(Box)({
  height: '2.5rem',
  width: '1px',
  marginLeft: '1rem',
  marginRight: '0.35rem',
  backgroundColor: '#f0e4c9',
});

function ListHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const [routesStateData, setRoutesStateData] = useRecoilState<RoutesType>(routesState);
  const { root_path, child_path } = routesStateData;
  const [userStateData, setUserStateData] = useRecoilState<UserType>(userState);
  const { modal_confirm, modal_alert } = useContext(ModalContext);
  const { playEffectSound } = useContext(EffectSoundContext);
  const setToastBar = useSetRecoilState(toastBarState);

  const [profileSrc, setProfileSrc] = useState('');

  // 메뉴 아이템
  const listmenu_items: ListMenuType = {
    schedule: [
      { path: 'learningschedule', label: '학습 스케쥴', root: true },
      { path: 'learningbook', label: '스케쥴 교재' },
    ],
    report: [
      { path: 'lookup', label: '학습 결과', root: true },
      { path: 'period', label: '레포트 기간분석' },
      { path: 'month', label: '레포트 월별분석' },
    ],
    leaderboard: [
      { path: 'board', label: '리더보드', root: true },
      { path: 'record', label: '수상이력' },
    ],
    leveltest: [{ path: 'report', label: '레벨 테스트 조회', root: true }],
    manager: [
      { path: 'chart', label: '학습 성적분석' },
      { path: 'bookprint', label: '단어 시험지출력' },
      { path: 'notice', label: '업데이트 공지', root: true },
      { path: 'notification', label: '학습 알림센터' },
      { path: 'manual', label: '사용자 매뉴얼' },
      { path: 'qna', label: '자주 묻는 질문' },
      { path: 'settings', label: '학습설정' },
    ],
  };

  useEffect(() => {
    if (userStateData.profile) {
      setProfileSrc(`data:image/png;base64,${userStateData.profile}`);
    } else {
      setProfileSrc('');
    }
  }, [userStateData]);

  const dropdown_content =
    child_path == 'settings'
      ? [
          {
            id: 1,
            label: '나가기',
          },
        ]
      : [
          {
            id: 0,
            label: '학습설정',
          },
          // {
          //   id: 1,
          //   label: '나가기',
          // },
        ];

  const onClickItem = (id: number, type?: string, index?: number) => {
    if (userStateData.id < 6) {
      modal_alert.openModalAlert('temp_account');
      return false;
    }
    movePage(id);
  };

  const onClickExit = () => {
    playEffectSound('click');
    navigate(`${BASE_URL}/`);
  };

  const moveSettings = () => {
    modal_confirm.openModalConfirm(
      'normal',
      'move_page',
      () => {
        navigate(`${BASE_URL}/manager/settings`);
      },
      undefined,
      '학습설정으로',
    );
  };

  const movePage = (id: number) => {
    switch (id) {
      case 0:
        moveSettings();
        break;
      case 1:
        onClickExit();
        break;
    }
  };

  return (
    <StyledHeaderWrapper>
      <StyledAnchorWrap>
        <StyledAnchorWBox>
          <InputBox>
            {root_path && listmenu_items[root_path] ? (
              <StyledInputNormal
                value={
                  child_path
                    ? listmenu_items[root_path].find(item => item.path == child_path)?.label
                    : listmenu_items[root_path].find(item => item.root)?.label
                }
                readOnly
              />
            ) : null}
          </InputBox>
          <InfoBox>
            <ProfileBox>
              {userStateData.profile_type == 'default' ? (
                userStateData.default_profile == '0' ? (
                  <Box component='img' src={profile_default_new} alt='default_profile' className='default_profile' />
                ) : (
                  <Box sx={{ backgroundImage: `url(${profileSrc})` }} className='user_profile' />
                )
              ) : (
                <Box sx={{ backgroundImage: `url(${profileSrc})` }} className='user_profile' />
              )}
            </ProfileBox>
            <DropdownList contents={dropdown_content} title={userStateData.name} onClickItem={onClickItem} />
            <Line />
            <StyledIconButton onClick={onClickExit} disableFocusRipple disableRipple disableTouchRipple />
          </InfoBox>
        </StyledAnchorWBox>
      </StyledAnchorWrap>
    </StyledHeaderWrapper>
  );
}

export default ListHeader;
