import { styled, Box } from '@mui/material';
export { Modal, Wrapper } from '../ModalTestPrintSetting/ModalTestPrintSetting.Styles';

export const Wrap = styled(Box)(props => ({
  height: 'auto',
  borderRadius: '1.5rem',
  overflow: 'hidden',
  backgroundColor: 'transparent',
}));

export const Header = styled(Box)(props => ({
  width: '100%',
  height: '4rem',
  padding: '0 1rem',
  fontSize: '1.2rem',
  background: props.theme.palette.purple_6.main,
  color: props.theme.palette.white.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '.sub-title': {
    fontSize: '0.875rem',
    marginLeft: '0.25rem',
  },
}));

export const HeaderIconWrap = styled(Box)(props => ({
  right: '1rem',
  position: 'absolute',
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: props.theme.palette.white.main,
  color: props.theme.palette.purple_6.main,
  svg: {
    width: '1.7rem',
    height: '1.7rem',
    strokeWidth: 30,
    marginRight: '-1px',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Body = styled(Box)(props => ({
  width: '51rem',
  height: '30rem',
  backgroundColor: props.theme.palette.white.main,
  display: 'flex',
  gap: '2rem',
  padding: '2.75rem',
  '&.selected': {
    '& > .selected': {
      border: '4px solid #f6a21f',
    },
    // '& > :not(.selected)': {
    //   opacity: '0.4',
    // },
  },
}));

export const ChoiceCard = styled(Box)(props => ({
  width: 'calc(50% - 0.5rem)',
  height: '100%',
  borderRadius: '1rem',
  // boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px;',
  boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
  cursor: 'pointer',

  '& > div': {
    '&:first-of-type': {
      borderTopLeftRadius: '1rem',
      borderTopRightRadius: '1rem',
      backgroundColor: '#f6f4fd',
      height: '52.5%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      img: {
        maxHeight: '100%',
        maxWidth: '90%',
      },
    },
    '&:last-of-type': {
      height: '47.5%',
      paddingLeft: '1.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      '& > h3': {
        // fontFamily: 'NanumSquareRound !important',
        fontSize: '1.2rem',
        lineHeight: '2',
        span: {
          // fontFamily: 'NanumSquareRound !important',
          color: props.theme.palette.purple.main,
          '&.highlight': {
            display: 'inline-block',
            position: 'relative',
            '& > div': {
              display: 'inline-block',
              position: 'relative',
              zIndex: '1',
            },
          },
          '&.highlight:after': {
            width: '100%',
            content: '""',
            height: '0.6rem',
            display: 'inline-block',
            background: '#fffcb9',
            position: 'absolute',
            bottom: '0',
            left: '0',
            transform: 'translateY(-100%)',
          },
        },
      },
      '& > p': {
        fontSize: '1.1rem',
        color: '#8e8f8e',
        fontWeight: '300',
        span: {
          color: '#000',
        },
      },
    },
  },
}));
