import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import ModalProvider from './provider/ModalProvider';
import TableProvider from './provider/TableProvider';
import EffectSoundContextProvider from './provider/EffectSoundProvider';
import BackgroundSoundContextProvider from './provider/BackgroundSoundProvider';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { deviceState } from './recoil/common/device';
import { Box } from '@mui/material';
import theme from './theme';

import { BASE_URL, PRESIDENT_OF_CENTER_TYPE, TEMP_STUDENTS_ACCOUNT_IDS } from './utils/constants';

import { Suspense, useEffect, useRef, useState } from 'react';
import Loading from './components/loading/Loading';
import { userState } from './recoil/model/user';
import { pocketNoteState } from './recoil/model/pocket_note';
import { settingState } from './recoil/model/settings';
import { moduleSettingState } from './recoil/model/module_setting';
import { default_settings } from './utils/user_tools';
import { openModalCheck2, modalChec2kData } from './recoil/common/modalCheck2';
import { ModalRadioContainer } from 'components/modal/ModalRadio/ModalRadio.Container';
import { fetchGetApi } from './utils/api';
import FontLoader from './components/common/FontLoader/FontLoader';
import { getAuth, signInAnonymously, onAuthStateChanged } from 'firebase/auth';
import { setUserInFireStore, setUserInFireStoreToNull } from './utils/firebase';

import LayoutLogin from './components/layout/LayoutLogin';
import LayoutDefault from './components/layout/LayoutDefault';

import MobileDetect from 'mobile-detect';
import { ProfileContainer } from 'pages/profile/Profile.Container';
import { ToastBarContainer } from 'components/common/ToastBar/ToastBarContainer';
import { ModalLectureApplyContainer } from 'components/common/ModalLectureApply/ModalLectureApplyContainer';
import { ModalManual2 } from 'components/modal/ModalManual2';
import { LoadingCircleSpinnerContainer } from 'components/common/LoadingCircleSpinner/LoadingCircleSpinnerContainer';
import { ModalTargetSettingContainer } from 'components/common/ModalTargetSetting/ModalTargetSetting.Container';
import { ModalProductIntendContainer } from 'components/common/ModalProductIntend/ModalProductIntend.Container';
import { ModalInputPin } from 'components/modal/ModalInputPin';
import { ModalServicePaymentNoticeContainer } from 'components/common/ModalServicePaymentNotice/ModalServicePaymentNotice.Container';
import { ModalStudyStopContainer } from 'components/common/ModalStudyStop/ModalStudyStop.Container';
import { ModalBillContainer } from 'components/common/ModalBill/ModalBill.Container';

declare let window: any;
declare global {
  let $: any;
}

function App() {
  const setModalCheck2 = useSetRecoilState(modalChec2kData);
  const setDeviceStateData = useSetRecoilState<DeviceType>(deviceState);
  const userStateData = useRecoilValue<UserType>(userState);
  const { pass, id, type } = userStateData;
  const setPocketNoteStateData = useSetRecoilState<PocketNoteType>(pocketNoteState);
  const [settingStateData, setSettingStateData] = useRecoilState<SettingsType>(settingState);
  const setModuleSettingStateData = useSetRecoilState<ModuleSettings>(moduleSettingState);
  const [fontLoaded, setFontLoaded] = useState(false);

  const accountIdRef = useRef(id);
  const userTypeRef = useRef(type);
  accountIdRef.current = id;
  userTypeRef.current = type;

  useEffect(() => {
    if (!(window?.cordova?.platformId === 'ios' || window?.cordova?.platformId === 'android')) {
      const isIOSSafari = /iP(ad|hone|od).+Version\/[\d\.]+.*Safari/i.test(navigator.userAgent);
      const isAndroidMobile = /Android/i.test(navigator.userAgent);

      if (isAndroidMobile) {
        window.location.replace('https://play.google.com/store/apps/details?id=kr.co.longvoca');
      } else if (isIOSSafari) {
        window.location.replace('https://apps.apple.com/app/id6451292167');
      }
    }

    document.fonts.ready.then(fontFaceSet => {
      setFontLoaded(true);
    });
    window.defaultStatus = '';
    window.status = '';
    document.addEventListener('deviceready', onDeviceReady, false); // 코르도바 연동
    window.addEventListener('keydown', toggleFullScreen);
    window.addEventListener('fullscreenchange', handleFullscreenChange);
    window.addEventListener('resize', handleResize);
    if ('webkitSpeechRecognition' in window) {
      window.URL = window.URL || window.webkitURL;
      const navi: any = window.navigator;
      navi.getUserMedia = navi.getUserMedia || navi.webkitGetUserMedia || navi.mozGetUserMedia || navi.msGetUserMedia;
    }

    window.addEventListener('contextmenu', onRightClick);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('deviceready', onDeviceReady); // 코르도바 연동
      window.removeEventListener('keydown', toggleFullScreen);
      window.removeEventListener('fullscreenchange', handleFullscreenChange);
      window.removeEventListener('contextmenu', onRightClick);
    };
  }, []);

  const unloadRef = useRef(false);
  const beforeunloadLogin = () => {
    unloadRef.current = true;
  };

  const unloadApp = () => {
    if (window.FB_UNSCRIBE) {
      window.FB_UNSCRIBE();
    }

    if (
      accountIdRef.current &&
      userTypeRef.current != PRESIDENT_OF_CENTER_TYPE &&
      !TEMP_STUDENTS_ACCOUNT_IDS.includes(accountIdRef.current)
    ) {
      if (document.visibilityState !== 'visible') {
        if (window?.cordova?.platformId === 'ios' || window?.cordova?.platformId === 'android') {
          if (window.sessionStorage.getItem('user')) {
            setUserInFireStoreToNull(accountIdRef.current, true);
          }
        } else {
          if (window.sessionStorage.getItem('user')) {
            if (JSON.parse(window.sessionStorage.getItem('user')).id !== 0) {
              if (unloadRef.current) setUserInFireStoreToNull(accountIdRef.current, true);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (window?.cordova?.platformId === 'ios' || window?.cordova?.platformId === 'android') {
      window.removeEventListener('visibilitychange', unloadApp);
    } else {
      window.addEventListener('beforeunload', beforeunloadLogin);
      window.addEventListener('visibilitychange', unloadApp);
    }

    return () => {
      window.addEventListener('beforeunload', beforeunloadLogin);
      window.removeEventListener('visibilitychange', unloadApp);
    };
  }, []);

  const success_cb = () => {
    openModalCheck2({ setModalCheck2 }, 'duplicate_logout');
  };
  useEffect(() => {
    console.log('pass :', pass);
    if (pass) {
      getPocketNote();
      getSettings();
      const auth = getAuth();
      signInAnonymously(auth).catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
      onAuthStateChanged(auth, user => {
        if (user) {
          window.FB_UID = user.uid;
          const data = {
            fb_uid: window.FB_UID,
            login_time: new Date().getTime(),
          };
          if (
            accountIdRef.current !== 0 &&
            userTypeRef.current != PRESIDENT_OF_CENTER_TYPE &&
            !TEMP_STUDENTS_ACCOUNT_IDS.includes(accountIdRef.current)
          ) {
            setUserInFireStore(userStateData.id, data, success_cb);
          }
        } else {
          window.FB_UID = undefined;
        }
      });
    } else if (pass == false) {
      //  로그아웃 시
      if (window.FB_UNSCRIBE) {
        window.FB_UNSCRIBE();
      }
    }
  }, [pass]);

  // 모바일앱 구동 시
  const onDeviceReady = () => {
    if ('StatusBar' in window) window.StatusBar.hide();
    if (!(window?.cordova?.platformId === 'ios' || window?.cordova?.platformId === 'android')) {
      return false;
    }

    if (window.cordova.platformId === 'ios') {
      setSafePadding();
    }

    window.navigationbar.setUp(true);
    window.navigationbar.hideNavigationBar();

    let recognition_available = false;
    // 음성인식 가능여부 확인
    if (window.plugins.speechRecognition) {
      window.plugins.speechRecognition.isRecognitionAvailable(
        (res: boolean) => {
          recognition_available = res;
        },
        (e: Error) => {
          window.console.error('ERR:' + e);
        },
      );
    }
    // 음성인식 권한 확인
    window.plugins.speechRecognition.hasPermission(
      (res: boolean) => {
        window.console.log('permission:' + res);
      },
      (e: Error) => {
        window.console.log('permission err:' + e);
      },
    );

    // 음성인식 권한 요청
    window.plugins.speechRecognition.requestPermission();

    // 블루투스 키보드 등 외부 키보드 연동 시
    if (typeof window.ExternalKeyboard == 'function') {
      window.ExternalKeyboard.checkOnce();
    }

    // 모바일 백버튼 라우터 정리
    document.addEventListener(
      'backbutton',
      event => {
        event.preventDefault();

        console.log(event);
      },
      false,
    );

    document.addEventListener('pause', onPause, false);
    document.addEventListener('resume', onResume, false);

    const md = new MobileDetect(navigator.userAgent);

    let is_tablet = md.tablet() ? true : false;

    if (
      navigator.userAgent.toLowerCase().indexOf('mac os x') !== -1 &&
      navigator.userAgent.toLowerCase().indexOf('iphone') == -1
    ) {
      if (navigator.maxTouchPoints > 0) {
        is_tablet = true;
      }
    }

    setDeviceStateData(prevState => ({
      ...prevState,
      is_mobile: true,
      is_tablet: is_tablet,
      recognition_available: recognition_available,
      platform: window.cordova.platformId,
    }));
  };

  const onRightClick = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const handleResize = () => {
    setDeviceStateData(prevState => ({
      ...prevState,
      screen_width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      screen_height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
    }));
    if (window?.cordova?.platformId === 'ios') {
      document.addEventListener(
        'DOMContentLoaded',
        () => {
          setSafePadding();
        },
        false,
      );

      document.addEventListener(
        'orientationchange',
        () => {
          setSafePadding();
        },
        false,
      );

      window.onorientationchange = setSafePadding;
    }
    console.log(
      'width :',
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      ' / height :',
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
    );
  };

  const setSafePadding = () => {
    const html = document.documentElement;
    if (window?.cordova?.platformId === 'android') {
      if (window.orientation === 90) {
        // 왼쪽
        if (html.classList.contains('android-minus-90')) {
          html.classList.replace('android-minus-90', 'android-plus-90');
        } else {
          html.classList.add('android-plus-90');
        }
      } else if (window.orientation === -90) {
        // 오른쪽
        if (html.classList.contains('android-plus-90')) {
          html.classList.replace('android-plus-90', 'android-minus-90');
        } else {
          html.classList.add('android-minus-90');
        }
      }
    } else if (window?.cordova?.platformId === 'ios') {
      if (window.orientation === 90) {
        // 왼쪽
        if (html.classList.contains('ios-minus-90')) {
          html.classList.replace('ios-minus-90', 'ios-plus-90');
        } else {
          html.classList.add('ios-plus-90');
        }
      } else if (window.orientation === -90) {
        // 오른쪽
        if (html.classList.contains('ios-plus-90')) {
          html.classList.replace('ios-plus-90', 'ios-minus-90');
        } else {
          html.classList.add('ios-minus-90');
        }
      }
    }
  };

  const getPocketNote = async () => {
    if (userStateData.id < 6) return false;
    const res = await fetchGetApi(
      `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/pocketnotes/words`,
    );

    if (res.result) {
      setPocketNoteStateData(prevState => ({ note_contents: [...res.data.words] }));
    }
  };

  const getSettings = async () => {
    if (userStateData.id > 5) {
      const settings_res = await fetchGetApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/settings`,
      );
      if (settings_res.result) {
        setSettingStateData(prevState => ({ ...prevState, ...settings_res.data }));
      } else {
        setSettingStateData(prevState => ({ ...prevState, ...default_settings }));
      }

      const moddule_settings_res = await fetchGetApi(
        `/customers/${userStateData.customer_id}/accounts/${userStateData.id}/schedules/settings?ver=2`,
      );

      if (moddule_settings_res.result)
        setModuleSettingStateData(prevState => ({
          ...prevState,
          ...moddule_settings_res.data,
        }));
    } else {
      setSettingStateData(prevState => ({ ...prevState, ...default_settings }));
    }
  };

  const handleFullscreenChange = () => {
    if (!document.fullscreenElement) {
      setSettingStateData(prevState => ({
        ...prevState,
        is_fullscreen: false,
      }));
    } else {
      setSettingStateData(prevState => ({
        ...prevState,
        is_fullscreen: true,
      }));
    }
  };

  const toggleFullScreen = (e: KeyboardEvent) => {
    if (e.keyCode === 122) {
      e.preventDefault();
      if (settingStateData.is_fullscreen) {
        document.exitFullscreen().catch(err => {
          console.error(err);
        });
      } else {
        document.documentElement.requestFullscreen().catch(err => {
          console.error(err);
        });
      }
    }
  };

  const onPause = () => {
    setDeviceStateData(prevState => ({
      ...prevState,
      device_pause: true,
    }));
    if (
      accountIdRef.current !== 0 &&
      userTypeRef.current != PRESIDENT_OF_CENTER_TYPE &&
      !TEMP_STUDENTS_ACCOUNT_IDS.includes(accountIdRef.current)
    ) {
      if (window.FB_UNSCRIBE) {
        window.FB_UNSCRIBE();
      }
      if (window.sessionStorage.getItem('user')) {
        setUserInFireStoreToNull(accountIdRef.current);
      }
    }
  };

  const onResume = () => {
    setDeviceStateData(prevState => ({
      ...prevState,
      device_pause: false,
    }));
    if (
      accountIdRef.current &&
      userTypeRef.current != PRESIDENT_OF_CENTER_TYPE &&
      !TEMP_STUDENTS_ACCOUNT_IDS.includes(accountIdRef.current)
    ) {
      const uid_data = {
        fb_uid: window.FB_UID,
        login_time: new Date().getTime(),
      };
      if (accountIdRef.current && window.sessionStorage.getItem('user')) {
        setUserInFireStore(accountIdRef.current, uid_data, success_cb);
      }
    }
  };

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <ThemeProvider theme={theme}>
        <TableProvider>
          <EffectSoundContextProvider>
            <BackgroundSoundContextProvider>
              <ModalProvider>
                {fontLoaded ? (
                  <Suspense fallback={<Loading isLogo={true} />}>
                    <Routes>
                      <Route path={`${BASE_URL}/profile`} element={<ProfileContainer />} />
                      <Route path={`${BASE_URL}/*`} element={<LayoutDefault />} />
                      <Route path={`${BASE_URL}/login/*`} element={<LayoutLogin />} />
                    </Routes>
                  </Suspense>
                ) : (
                  <>
                    <Loading isLogo={true} />
                    <FontLoader />
                  </>
                )}
                <ModalInputPin />
              </ModalProvider>
              <ModalManual2 />
              <ToastBarContainer />
              <ModalRadioContainer />
              <LoadingCircleSpinnerContainer />
              <ModalLectureApplyContainer />
              <ModalTargetSettingContainer />
              <ModalProductIntendContainer />
              <ModalServicePaymentNoticeContainer />
              <ModalStudyStopContainer />
              <ModalBillContainer />
            </BackgroundSoundContextProvider>
          </EffectSoundContextProvider>
        </TableProvider>
      </ThemeProvider>
    </Box>
  );
}

export default App;
