import { OutlinedInput, FormHelperText as MuiFormHelperText, styled } from '@mui/material';
import { CSSProperties } from 'react';

export const Input = styled(OutlinedInput)(props => ({
  fontSize: '1rem',
  width: '100%',
  height: '2.5rem',
  marginBottom: '0.5rem',
  outline: 'none',
  '&.Mui-disabled.MuiInputBase-colorGray_1': {
    backgroundColor: '#f5f5f5',
    '& input': {
      WebkitTextFillColor: 'var(--gray_1)',
    },
    '& fieldset': {
      borderColor: 'var(--gray-2) !important',
    },
  },
  '&.Mui-error ': {
    '& fieldset': {
      borderColor: 'var(--gray-2) !important',
    },
  },
  '&.Mui-focused': {
    fieldset: {
      borderWidth: '1px !important',
    },
  },
  '& input': {
    cursor: `pointer`,
    padding: '0.5rem 1rem 0.435rem',

    '&::placeholder': {
      color: '#c7c7c7 !important',
      transition: 'none',
      fontWeight: '300',
      opacity: '1',
    },
    '&:disabled': {
      backgroundColor: '#f5f5f5 !important',
      cursor: 'default',
    },
  },

  '&.dot_password': {
    input: {
      WebkitTextSecurity: 'disc',
    },
  },

  '&:hover': {
    'input, fieldset': {
      borderColor: 'var(--gray-2)',
    },
  },
  '& fieldset': {
    borderColor: 'var(--gray-2) !important',
    legend: {
      outline: 'none',
    },
  },

  '.password_icon': {
    position: 'absolute',
    right: '0.9rem',
    width: '1.4rem',
    height: '1.4rem',
    cursor: 'pointer',
    color: '#bdbbbc',
  },
}));

export const ErrorText = styled(MuiFormHelperText)(() => {
  const top_bottom_position = { top: 'calc(2.25rem / 3)' };
  return {
    position: 'absolute',
    right: '0',
    color: 'var(--red)',
    fontSize: '0.85rem',
    ...top_bottom_position,
  };
});

export const edit_triangle = {
  content: '',
  position: 'absolute',
  right: 0,
  bottom: '0.5rem',
  width: 0,
  height: 0,
  borderLeft: '7px solid transparent',
  borderRight: '7px solid #c2c2c2',
  borderTop: '7px solid transparent',
  borderBottom: '7px solid #c2c2c2',
  borderBottomRightRadius: '4px',
  marginBottom: '1px',
  cursor: 'pointer',
  zIndex: 1,
} as CSSProperties;
