import { TabWrapper, TabWrap, TabList } from './TabsNormal.Styles';
import { TabContext } from '@mui/lab';
import { Tab, SxProps, Theme } from '@mui/material';
import { NOT_USE_TAB } from 'utils/constants';
import React from 'react';

export interface TabsNormalVAProps {
  tabValue: string;
  contents: string[];
  sx?: SxProps<Theme>;
  onChangeTabList: (event: React.SyntheticEvent, newValue: string) => void;
}

export const TabsNormalUI = React.memo(function TabsNormalUI({
  tabValue,
  contents,
  sx,
  onChangeTabList,
}: TabsNormalVAProps) {
  return (
    <TabWrapper sx={sx}>
      <TabContext value={tabValue}>
        <TabWrap>
          <TabList onChange={onChangeTabList}>
            {contents.map((content, idx) => {
              const disabled = content.includes('::disabled');
              const value = content.replace('::disabled', '');
              return <Tab label={value} value={value} key={`tab_${idx}`} tabIndex={NOT_USE_TAB} disabled={disabled} />;
            })}
          </TabList>
        </TabWrap>
      </TabContext>
    </TabWrapper>
  );
});
