import { CardWrapper, CardContent } from './Card.Styles';
import { Box, SxProps, Theme } from '@mui/material';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import React, { ReactNode, useEffect, useState } from 'react';

export interface CardVAProps {
  contents: {
    id: string;
    title: { text: string | ReactNode };
    paragraph: { text: string | ReactNode }[];
  };
  sx?: SxProps<Theme>;
  options?: ReactNode;
}

export const CardUI = React.memo(function CardUI({ contents, sx, options }: CardVAProps) {
  const card_open = localStorage.getItem('card_open');
  const card_open_obj = card_open ? JSON.parse(card_open) : {};
  const { id, title, paragraph } = contents;
  const [open, setOpen] = useState<null | boolean>(card_open_obj[id] ? card_open_obj[id] : null);

  useEffect(() => {
    let init = false;
    let tmp: { [key: string]: boolean } = {};
    if (card_open) {
      if (card_open_obj) {
        if (card_open_obj[id] !== undefined) {
          setOpen(card_open_obj[id]);
          return;
        } else {
          init = true;
          tmp = {
            ...card_open_obj,
            [id]: true,
          };
        }
      }
    } else {
      tmp[id] = true;
      init = true;
    }

    if (init) {
      setOpen(true);
      localStorage.setItem('card_open', JSON.stringify(tmp));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (open === null) return;
    if (card_open) {
      if (card_open_obj) {
        card_open_obj[id] = open;

        localStorage.setItem('card_open', JSON.stringify(card_open_obj));
      } else {
        const tmp_open = {
          [id]: open,
        };

        localStorage.setItem('card_open', JSON.stringify(tmp_open));
      }
    }
  }, [open]);

  return (
    <CardWrapper variant='outlined' sx={sx} className={open ? 'open' : 'close'}>
      <CardContent>
        <Box component={'h4'} sx={{ mb: '8px', display: 'flex', alignItems: 'center' }}>
          {title.text}
        </Box>
        {open ? (
          <>
            <Box component={'ul'} sx={{ pl: 0, lineHeight: '1.5' }}>
              {paragraph.map((line, idx) => (
                <Box component={'li'} key={`card_li_${idx}`}>
                  {line.text}
                </Box>
              ))}{' '}
            </Box>
            {options ? options : null}
          </>
        ) : null}
      </CardContent>
      <Box className='open_button' onClick={() => setOpen(!open)}>
        {open ? <BiChevronUp /> : <BiChevronDown />}
      </Box>
    </CardWrapper>
  );
});
