import { Button } from './Button.Styles';
import { ButtonProps, SxProps, Theme } from '@mui/material';
import React from 'react';

export interface ButtonVAProps extends ButtonProps {
  sx?: SxProps<Theme>;
}

export const ButtonUI = React.memo(function ButtonUI(props: ButtonVAProps) {
  const children = props.children;
  return <Button {...props}>{children}</Button>;
});
