import { styled, Box, Modal as MuiModal, Radio, Button } from '@mui/material';
import { BoxProps, height } from '@mui/system';

interface MarginProps {
  height: string;
}
export const Margin = styled(Box)<MarginProps>(({ height }) => {
  return {
    height: height ? `${height}rem` : '',
  };
});

export const Modal = styled(MuiModal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '1300',
});

export const ModalBox = styled(Box)({
  transform: 'scale(0.88)',
  width: '64rem',
  height: '45.5rem',
  backgroundColor: 'white',
  borderRadius: '1.5rem',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  letterSpacing: '-0.0266rem',

  '@media (max-width: 769px)': {
    transform: 'scale(0.725)',
  },
});

export const ModalHeader = styled(Box)({
  transform: 'scale(1.002)',
  width: '100%',
  height: '3.5rem',
  backgroundColor: '#6b20e9',
  color: 'white',
  padding: '0px 1rem',
  borderRadius: '1.5rem 1.5rem 0 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.2rem',
  textAlign: 'center',
  position: 'absolute',
  top: '-5%',
});

export const ModalHeaderIconWrap = styled(Box)(() => ({
  right: '1rem',
  position: 'absolute',
  width: '2rem',
  height: '2rem',
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: 'white',
  color: 'rgb(107, 32, 233)',
  svg: {
    width: '1.6rem',
    height: '1.6rem',
    strokeWidth: 30,
    marginRight: '-1px',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@media  (max-width: 959px)': {
    width: '2.75rem',
    height: '2.75rem',
    svg: {
      width: '2.65rem',
      height: '2.65rem',
    },
  },
  '@media (max-width: 767px)': {
    width: '2.25rem',
    height: '2.25rem',
    svg: {
      width: '2rem',
      height: '2rem',
    },
  },
}));

export const ModalContentWrapper = styled(Box)({
  padding: '4.2rem 2.35rem 3rem 2.35rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '7%',
});

export const ModalStepOneContentLeftSection = styled(Box)({
  width: '50%',
  // height: '100%',
  display: 'flex',
  flexDirection: 'column',
  letterSpacing: '0.109rem',
  textAlign: 'left',
});

export const ModalContentLeftDurationWrapper = styled(Box)({
  gap: '0.5rem 1.2rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  display: 'flex',
});

interface ModalContentSelect {
  isSelected: boolean;
}

export const ModalContentLeftDuratioBox = styled(Box)<ModalContentSelect>(props => {
  const { isSelected } = props;
  return {
    fontSize: '1.44rem',
    width: '100%',
    display: 'flex',
    alignItems: isSelected ? 'center' : '',
    justifyContent: 'center',
    position: 'relative',
    border: isSelected ? '2px solid rgb(85, 0, 255)' : '1px solid rgb(198, 198, 198)',
    margin: isSelected ? '0px' : '0.0625rem',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    boxSizing: 'content-box',
  };
});
export const ModalContentBoxHeadline = styled(Box)<ModalContentSelect>(props => {
  const { isSelected } = props;
  return {
    backgroundColor: isSelected ? 'rgb(85, 0, 255)' : 'rgb(198, 198, 198)',
    position: 'absolute',
    left: '50%',
    top: '0px',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    borderRadius: '2rem',
    fontSize: '0.85rem',
    padding: '0.35rem 0px',
    minWidth: '4.2051rem',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    letterSpacing: '-0.0625rem',
  };
});

export const ModalContentLeftProductWrapper = styled(Box)({
  gap: '0.5rem 1.2rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  display: 'grid',
  gridTemplateRows: 'repeat(2,minmax(0,1fr))',
  gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
});

export const ModalContentLeftProductBox = styled(Box)<ModalContentSelect>(props => {
  const { isSelected } = props;
  return {
    fontSize: '1.34rem',
    fontWeight: '600',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    border: isSelected ? '2px solid rgb(85, 0, 255)' : '1px solid rgb(198, 198, 198)',
    margin: isSelected ? '0px' : '0.0625rem',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    boxSizing: 'content-box',
    minHeight: '5.5rem',
    height: '8.934rem',
    flexDirection: 'column',
    '& img': {
      width: '28%',
      margin: '0.3rem 0px',
      maxWidth: '100%',
      height: 'auto',
      display: 'block',
      verticalalign: 'middle',
    },
  };
});

export const RecommInputBox = styled(Box)(props => ({
  marginTop: '1rem',
  display: 'flex',
  backgroundColor: '#f4f4f4',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '0.5rem',
  padding: '0 0.5rem 0 1.25rem',
  '& > input': {
    width: '100%',
    color: '#757575',
    backgroundColor: 'transparent',
    margin: '0.65rem 0',
    '&::placeholder': {
      color: '#d6d6d6',
    },
  },
}));

export const ModalStepOneContentRightSection = styled(Box)({
  width: '42%',
  // height: '43.7rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const ModalContentRightWrapper = styled(Box)({
  paddingTop: '0.65rem',
  flexWrap: 'wrap',

  display: 'flex',
});

export const ModalContentRightPriceBox = styled(Box)({
  height: '2.8rem',
  letterSpacing: '-0.08125rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  display: 'flex',
});

export const ModalContentRightLine = styled(Box)({
  width: '100%',
  alignItems: 'center',
  marginBottom: '0.188rem',
});

export const DetailDeco = styled(Box)({
  width: '0.625rem',
  height: '0.625rem',
  marginTop: '0.25rem',
  display: 'inline-block',
  borderLeft: '1px solid #bababa',
  borderBottom: '1px solid #bababa',
  transform: 'translateY(-50%)',
});

export const ModalStepTwoContentWrapper = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '1.25rem',
});

interface ModalStepTwoContentBoxProps {
  backgroundColor?: string; // 선택적 속성으로 정의
  borderBottom?: string;
}

export const ModalStepTwoContentBox = styled(Box)<ModalStepTwoContentBoxProps>(({ backgroundColor }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '11.5rem',
    borderWidth: '0.063rem',
    borderColor: 'rgb(229, 231, 235)',
    borderStyle: 'solid',
    backgroundColor: backgroundColor ? `${backgroundColor}` : '#f7f8f9',
  };
});

export const ModalStepTwoContentTextBox = styled(Box)<ModalStepTwoContentTextProps>(({ borderBottom }) => {
  return {
    display: 'flex',
    width: '100%',
    maxWidth: '33.25rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: borderBottom ? '0.0625rem solid rgb(229, 231, 235)' : undefined,
  };
});

export const ModalStepTwoContentTextBoxTwo = styled(Box)<ModalStepTwoContentTextProps>(({ borderBottom }) => {
  return {
    display: 'flex',
    width: '100%',
    maxWidth: '33.25rem',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: borderBottom ? '0.0625rem solid rgb(229, 231, 235)' : undefined,
    padding: '1rem 0 1rem 0',

    '@media (min-width: 1266px) and (max-width: 1905px)': {
      '& > div': {
        position: 'relative',
        inset: borderBottom ? '0.2rem 0 0 0' : '-0.2rem 0 0 0',
      },
    },

    '@media (min-width: 962px) and (max-width: 1265px)': {
      '& > div': {
        position: 'relative',
        inset: borderBottom ? '0.2rem 0 0 0' : '-0.25rem 0 0 0',
      },
    },

    '@media (min-width: 770px) and (max-width: 961px)': {
      '& > div': {
        position: 'relative',
        inset: borderBottom ? '0.65rem 0 0 0' : '-0.5rem 0 0 0',
      },
    },

    '@media (max-width: 769px)': {
      '& > div': {
        position: 'relative',
        inset: borderBottom ? '0.5rem 0 0 0' : '-0.4rem 0 0 0',
      },
    },
  };
});

interface ModalStepTwoContentTextProps {
  radioValue?: number;
  color?: string;
  fontSize?: string;
  isPaymentKind?: boolean;
}

export const ModalStepTwoContentTextLeft = styled(Box)<ModalStepTwoContentTextProps>(({ color }) => {
  return {
    fontSize: '1.25rem',
    textAlign: 'right',
    width: '25%',
    fontWeight: '500',
    padding: '0.5rem 1rem',
    color: color || 'inherit',
    whiteSpace: 'nowrap',
  };
});

export const ModalStepTwoContentTextRight = styled(Box)<ModalStepTwoContentTextProps>(({ color }) => {
  return {
    fontSize: '1.25rem',
    textAlign: 'left',
    width: '75%',
    color: color ? `${color}` : 'black',
    fontWeight: '600',
    padding: '0.5rem 1rem',
    whiteSpace: 'nowrap',
  };
});

export const StyledPaymentKindRadio = styled(Radio)<ModalStepTwoContentTextProps>(({ radioValue }) => {
  return {
    color: '#777777',
    '&.Mui-checked': {
      color: '#04d14a',
    },
  };
});

export const ModalStepTwoContentFormTextLeftBox = styled(Box)<ModalStepTwoContentTextProps>(({ isPaymentKind }) => {
  return {
    fontSize: '1.25rem',
    textAlign: 'right',
    width: '25%',
    fontWeight: '500',
    padding: '0.5rem 1rem',
    color: isPaymentKind ? '#04d14a' : '#777777',
    whiteSpace: 'nowrap',
  };
});

export const ModalStepTwoContentFormTextRightBox = styled(Box)<ModalStepTwoContentTextProps>(({ color }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.25rem',
    textAlign: 'left',
    width: '75%',
    color: color ? `${color}` : 'black',
    fontWeight: '600',
    padding: '0.5rem 1rem',
    whiteSpace: 'nowrap',
  };
});

interface PaymentButtonProps {
  joinComp: boolean;
  paymentKind: number;
  isRegister: boolean;
  isPayment?: boolean;
}
export const ColorButton = styled(Button)<PaymentButtonProps>(({ joinComp, paymentKind, isRegister, isPayment }) => {
  const isClickable = !joinComp && (paymentKind === 0 || (paymentKind === 1 && isRegister));

  return {
    cursor: isPayment ? 'default' : isClickable ? 'pointer' : 'default',
    fontSize: '1rem',
    width: '11%',
    color: 'white',
    fontWeight: '600',
    backgroundColor: isPayment ? '#856cef' : isClickable ? '#5500ff' : '#f6f6f6',
    '&:hover': {
      backgroundColor: isPayment ? '#856cef' : isClickable ? '#4806cb' : '#f6f6f6', // 클릭 가능할 때만 hover 색상 변경
    },
  };
});
export const Line = styled(Box)(({ height: string }) => {
  return {
    width: '100%',
    height: height ? `${height}` : '0.7rem',
    borderBottom: '0.0625rem solid rgb(229, 231, 235)',
  };
});

// .speech-bubble {
// 	position: relative;
// 	background: #00aabb;
// 	border-radius: .4em;
// }

// .speech-bubble:after {
// 	content: '';
// 	position: absolute;
// 	left: 0;
// 	top: 50%;
// 	width: 0;
// 	height: 0;
// 	border: 20px solid transparent;
// 	border-right-color: #00aabb;
// 	border-left: 0;
// 	border-bottom: 0;
// 	margin-top: -10px;
// 	margin-left: -20px;
// }

export const CommentBox = styled(Box)({
  color: '#895e00',
  backgroundColor: '#ffe65c',
  display: 'inline-flex',
  padding: '0.4rem 1.1rem',
  borderRadius: '0.5rem',
  position: 'relative',
  '&::before': {
    content: '""',
    top: '50%', // top을 50%로 변경
    left: '-1.5rem',
    bottom: '-2rem',
    transform: 'translateY(-50%),',
    zIndex: '3',
    position: 'absolute',
    border: '1rem solid transparent',
    borderLeft: '1rem solid #ffe65c', // 아래쪽 테두리 색상 설정
  },
});

export const SpeechBubble = styled(Box)({
  fontSize: '0.95rem',
  color: '#895e00',
  fontWeight: '600',
  backgroundColor: '#ffe65c',
  display: 'inline-flex',
  position: 'relative',
  borderRadius: '0.3em',
  padding: '0.3rem 0.9rem',
  marginLeft: '0.7rem',
  // 필요에 따라 패딩 추가
  '&::after': {
    content: '""',
    position: 'absolute',
    left: '0',
    top: '50%',
    width: 0,
    height: 0,
    border: '0.95rem solid transparent',
    borderRightColor: '#ffe65c',
    borderLeft: 0,
    borderTop: 0,
    marginTop: '-0.325rem',
    marginLeft: '-0.5rem',
  },
});

interface JoinButtonProps extends BoxProps {
  isScholarShip: boolean;
}

export const JoinButton = styled(Box)<JoinButtonProps>(({ isScholarShip }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '0.5rem',
  padding: '0.5rem 0',
  position: 'relative',
  height: isScholarShip ? 'calc(5.9375rem)' : 'calc(7.7rem)',
  marginTop: isScholarShip ? '0rem' : '1rem',
  marginBottom: '-1rem',
  background: 'linear-gradient(90deg, rgba(123,115,255,1) 0%, rgba(30,187,255,1) 100%)',
  cursor: 'pointer',

  '@media (min-width: 1266px) and (max-width: 1905px)': {
    height: isScholarShip ? 'calc(5.9375rem)' : '7.9rem',
  },

  '@media (min-width: 962px) and (max-width: 1265px)': {
    height: isScholarShip ? '6.25rem' : '8rem',
  },

  '@media (min-width: 770px) and (max-width: 961px)': {
    height: isScholarShip ? '6.7rem' : '8rem',
  },

  '@media (max-width: 769px)': {
    height: isScholarShip ? '6.5rem' : '8rem',
  },
}));

export const LeftTilte = styled(Box)<JoinButtonProps>(({ isScholarShip }) => ({
  fontSize: '1.34rem',
  letterSpacing: '-0.1094rem',
  fontWeight: '700',
  marginTop: '1.4rem',

  '@media (min-width: 1266px) and (max-width: 1905px)': {
    marginTop: '1.2em',
  },

  '@media (min-width: 962px) and (max-width: 1265px)': {
    marginTop: '1.2rem',
  },

  '@media (min-width: 770px) and (max-width: 961px)': {
    marginTop: isScholarShip ? '1.35rem' : '0.9rem',
  },

  '@media (max-width: 769px)': {
    height: isScholarShip ? '1.75rem' : '1.2rem',
  },
}));
