import { styled, Box, CircularProgress } from '@mui/material';

export const LoadingLogoWrap = styled(Box)({
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  zIndex: '99999',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  left: '0',
  top: '0',
});

export const Logo = styled('img')({
  height: '7.2vh',
});

export const LoadingBox = styled(Box)(props => {
  return {
    width: '100%',
    height: 'calc(100vh - 4rem - 2.5rem)',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
});

export const CircularProgressBottom = styled(CircularProgress)(props => ({
  color: props.theme.palette.grey[props.theme.palette.mode === 'light' ? 300 : 800],
}));

export const CircularProgressTop = styled(CircularProgress)(props => ({
  color: props.theme.palette.purple.main,
  animationDuration: '650ms',
  position: 'absolute',
}));

export const LoadingLabel = styled(Box)(props => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: props.theme.palette.gray_1.main,
  fontSize: '1.35rem',
  fontWeight: 'bold',
  position: 'absolute',
}));
